import { tsr } from "~/utils/client";
import { DEFAULT_PAGE_SIZE, getNextPageParam } from "~/utils/pagination";

export const useChannelMembers = (channelId: string) => {
  const {
    data,
    isLoading,
    isRefetching,
    refetch,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = tsr.chat.getChannelMembers.useInfiniteQuery({
    queryKey: ["channel", channelId, "members"],
    queryData: ({ pageParam = { skip: 0, take: DEFAULT_PAGE_SIZE } }) => ({
      params: { channelId },
      query: {
        skip: pageParam.skip,
        take: pageParam.take,
      },
    }),
    initialPageParam: { skip: 0, take: DEFAULT_PAGE_SIZE },
    getNextPageParam,
  });

  return {
    data,
    isLoading,
    isRefetching,
    refetch,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
};
