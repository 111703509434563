import {
  OutOfAreaActivationDeployment,
  OutOfAreaActivationRole,
} from "@ses-mams/api-contract";
import { useMemo } from "react";

export const useSelectableOutOfAreaRoles = (
  allRoles?: Array<OutOfAreaActivationRole>,
  deployments?: Array<OutOfAreaActivationDeployment>
) => {
  return useMemo(() => {
    // If there are roles selected in the deployments, then show those roles only. Otherwise show all roles.
    if (!deployments?.length || !allRoles) {
      return allRoles ?? [];
    }

    // Removes duplicate roles across the deployments
    const deploymentRolesSet = new Set(
      deployments.flatMap(deployment => deployment.roles).map(({ id }) => id)
    );

    return deploymentRolesSet.size
      ? allRoles.filter(role => deploymentRolesSet.has(role.id))
      : allRoles;
  }, [allRoles, deployments]);
};
