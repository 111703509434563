import { forwardRef } from "react";
import {
  Stack as MUIStack,
  StackProps as MUIStackProps,
  useTheme,
} from "@mui/material";
import { BorderTones } from "@ses-mams/react-utils";

import { BoxProps } from "../box";
import { useBoxStyles } from "../box/useBoxStyles";
import { Divider } from "../divider";
import { Spacings } from "../theme";
import { mapResponsiveKeyToValue, ResponsiveValue } from "../theme/utils";

type MUIStackValidProps = Pick<MUIStackProps, "children" | "sx">;

export type StackProps = MUIStackValidProps &
  BoxProps & {
    gap?: ResponsiveValue<Spacings>;
    rowGap?: ResponsiveValue<Spacings>;
    columnGap?: ResponsiveValue<Spacings>;
    dividers?: boolean;
    dividerTone?: BorderTones;
  };

export const Stack = forwardRef<HTMLDivElement, StackProps>(
  (
    {
      gap,
      rowGap,
      columnGap,
      dividers,
      dividerTone = "standard",
      direction,
      sx = {},
      children,
      ...props
    },
    forwardedRef
  ) => {
    const {
      tokens: { spacing },
    } = useTheme();

    const styles = useBoxStyles(props);

    return (
      <MUIStack
        ref={forwardedRef}
        gap={mapResponsiveKeyToValue(gap, spacing)}
        rowGap={mapResponsiveKeyToValue(rowGap, spacing)}
        columnGap={mapResponsiveKeyToValue(columnGap, spacing)}
        direction={direction}
        useFlexGap
        // divider doesn't support responsive direction value
        // for more information, look at https://github.com/mui/material-ui/issues/29908
        divider={
          dividers &&
          (typeof direction === "string" ||
            typeof direction === "undefined") ? (
            <Divider direction={direction} tone={dividerTone} />
          ) : undefined
        }
        sx={[styles, ...(Array.isArray(sx) ? sx : [sx])]}
      >
        {children}
      </MUIStack>
    );
  }
);
