import { AuditLog } from "@ses-mams/api-contract";

import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerProps,
} from "~/components/ui/drawer";
import { AuditDetails } from "./AuditDetails";

type AuditDetailsDrawerProps = Omit<DrawerProps, "children"> & {
  auditLog: AuditLog | null;
};

export const AuditDetailsDrawer = ({
  auditLog,
  onClose,
  ...rest
}: AuditDetailsDrawerProps) => {
  if (!auditLog) {
    return null;
  }

  return (
    <Drawer {...rest} onClose={onClose}>
      <DrawerHeader onClose={onClose}>Audit Log Details</DrawerHeader>
      <DrawerContent>
        <AuditDetails auditLog={auditLog} />
      </DrawerContent>
    </Drawer>
  );
};
