import { Unit } from "@ses-mams/api-contract";
import { useCallback, useState } from "react";
import { SelectUnitsDrawer } from "~/components/common/selectUnitsList";
import { EditListSection } from "~/components/common/editListSection";
import { UnitListItem } from "~/components/common/unit";

type EditUnitsListProps = {
  selectedUnits: Array<Unit>;
  onChangeSelectedUnits: (units: Array<Unit>) => void;
};

export const EditUnitsList = ({
  selectedUnits,
  onChangeSelectedUnits,
}: EditUnitsListProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onDone = useCallback(
    (units: Array<Unit>) => {
      onChangeSelectedUnits(units);
      setIsDrawerOpen(false);
    },
    [onChangeSelectedUnits]
  );

  return (
    <>
      <EditListSection
        title="Units"
        onButtonClick={() => setIsDrawerOpen(true)}
      >
        {selectedUnits.map(unit => (
          <UnitListItem key={unit.id} unit={unit} />
        ))}
      </EditListSection>

      <SelectUnitsDrawer
        open={isDrawerOpen}
        units={selectedUnits}
        onClose={() => setIsDrawerOpen(false)}
        onDone={onDone}
      />
    </>
  );
};
