import { OutOfAreaActivationAvailabilityStatusSchema } from "@ses-mams/api-contract";
import { endOfDay, startOfDay } from "date-fns";
import { z } from "zod";

const availabilityBlocksFormSchema = z.object({
  availabilityBlocks: z
    .array(
      z
        .object({
          start: z.date(),
          end: z.date(),
          availabilityStatus: OutOfAreaActivationAvailabilityStatusSchema,
          conditionalReason: z
            .string()
            .trim()
            .max(200, "The maximum length is 200 characters")
            .optional(),
        })
        .refine(
          ({ start, end }) => {
            if (+end < +start) {
              return false;
            }

            return true;
          },
          { message: "End must be after start", path: ["end"] }
        )
        .refine(
          ({ availabilityStatus, conditionalReason }) => {
            if (availabilityStatus === "Conditional" && !conditionalReason) {
              return false;
            }

            return true;
          },
          {
            message: "Conditional reason is required",
            path: ["conditionalReason"],
          }
        )
    )
    .min(1, "Must have at least 1 availability block")
    .superRefine((val, ctx) => {
      // Ensure there are no overlapping schedule items
      for (let i = 0; i < val.length - 1; i += 1) {
        const current = val[i];
        const next = val[i + 1];

        if (+(current?.end as Date) >= +(next?.start as Date)) {
          ctx.addIssue({
            code: "custom",
            message: "Must end before the time of the next block",
            path: [i, "end"],
          });
          ctx.addIssue({
            code: "custom",
            message: "Must start after the time of the previous block",
            path: [i + 1, "start"],
          });
        }
      }
    }),
});

export type AvailabilityBlocksFormSchema = z.infer<
  typeof availabilityBlocksFormSchema
>;

export const getAvailabilityBlocksFormSchema = (
  minStart?: Date,
  maxEnd?: Date
) =>
  availabilityBlocksFormSchema.superRefine(({ availabilityBlocks }, ctx) => {
    if (minStart && maxEnd && availabilityBlocks.length) {
      if (+(availabilityBlocks[0]?.start as Date) < +startOfDay(minStart)) {
        ctx.addIssue({
          code: "custom",
          message: "Must not be before the start time of the request",
          path: ["availabilityBlocks", 0, "start"],
        });
      }

      if (
        +(availabilityBlocks[availabilityBlocks.length - 1]?.end as Date) >
        +endOfDay(maxEnd)
      ) {
        ctx.addIssue({
          code: "custom",
          message: "Must not be after the end time of the request",
          path: ["availabilityBlocks", availabilityBlocks.length - 1, "end"],
        });
      }
    }
  });
