import { useMemo } from "react";

import { Member } from "@ses-mams/api-contract";

import emptyListImageUrl from "~/assets/emptyList.png";
import { EmptyState } from "~/components/common/emptyState";
import { Stack } from "~/components/ui/stack";
import { Spinner } from "~/components/ui/spinner";
import { Box } from "~/components/ui/box";
import { InfiniteList } from "~/components/ui/list";
import { useMembersListSearch } from "../hooks";

export type MemberListRenderProps = {
  renderItem: (member: Member) => React.ReactElement;
};

type MembersListSearchProps = Pick<
  ReturnType<typeof useMembersListSearch>,
  | "data"
  | "searchParams"
  | "isLoading"
  | "isRefetching"
  | "hasNextPage"
  | "fetchNextPage"
>;

type MemberListProps = MembersListSearchProps & MemberListRenderProps;

export const MembersList = ({
  data,
  searchParams,
  isLoading,
  isRefetching,
  hasNextPage,
  fetchNextPage,
  renderItem,
}: MemberListProps) => {
  const joinedSearchValue = useMemo(() => {
    const suggestionNamesArray = searchParams.searchSuggestions.map(
      s => s.name
    );

    return [...suggestionNamesArray, searchParams.searchValue.trim()]
      .filter(x => x)
      .join(" & ");
  }, [searchParams.searchValue, searchParams.searchSuggestions]);

  if (isLoading || isRefetching) {
    return (
      <Stack justify="center" align="center" sx={{ height: "100%" }}>
        <Spinner />
      </Stack>
    );
  }

  return (
    <InfiniteList
      data={data}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      renderItem={renderItem}
      listEmptyElement={
        <Box display="flex" justify="center" sx={{ height: "100%" }}>
          {joinedSearchValue.length > 0 ? (
            <EmptyState
              image={<img src={emptyListImageUrl} />}
              title="No members found"
              description="There are no members associated with your search. Please try another value."
            />
          ) : (
            <EmptyState
              image={<img src={emptyListImageUrl} />}
              title="No members"
              description="There are no members."
            />
          )}
        </Box>
      }
      keyExtractor={item => item.id}
    />
  );
};
