import { Theme, Typography, useTheme, TypographyProps } from "@mui/material";
import { ForegroundTones } from "@ses-mams/react-utils";

export type TextProps = Pick<TypographyProps, "display" | "noWrap" | "sx"> & {
  align?: TypographyProps["textAlign"];
  as?: TypographyProps["component"];
  tone?: ForegroundTones;
  size?: keyof Theme["tokens"]["typography"]["text"];
  weight?: keyof Theme["tokens"]["typography"]["fontWeight"];
  children: React.ReactNode;
};

export const Text = ({
  align,
  as = "span",
  tone,
  size,
  weight,
  sx,
  ...props
}: TextProps) => {
  const styles = useTextStyles({ tone, size, weight });

  return (
    <Typography
      {...props}
      component={as}
      textAlign={align}
      sx={[styles, ...(Array.isArray(sx) ? sx : [sx])]}
    />
  );
};

export const useTextStyles = ({
  tone = "primary",
  size = "standard",
  weight = "regular",
}: Pick<TextProps, "tone" | "size" | "weight">) => {
  const {
    tokens: {
      colors: { foreground },
      typography: { text, fontWeight },
    },
  } = useTheme();

  return {
    ...text[size],
    fontWeight: fontWeight[weight],
    color: foreground[tone],
  };
};
