import { useCallback } from "react";
import {
  useConfirmationDialog,
  ConfirmationDialogProps,
} from "~/components/ui/confirmationDialog";

type Params = {
  type: "activation" | "activity";
};

export const useShowConflictConfirmationDialog = ({ type }: Params) => {
  const { showConfirmationDialog } = useConfirmationDialog();

  const showConflictConfirmationDialog = useCallback(
    ({
      onConfirm,
      text = `You are already committed to a request that conflicts with this ${type}. Please confirm that you will cancel your current commitment and commit to this request.`,
    }: {
      onConfirm: ConfirmationDialogProps["onConfirm"];
      text?: ConfirmationDialogProps["text"];
    }) =>
      showConfirmationDialog({
        onConfirm,
        title: "Confirm commitment",
        text,
      }),
    [showConfirmationDialog, type]
  );

  return {
    showConflictConfirmationDialog,
  };
};
