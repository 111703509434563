import { Role } from "@ses-mams/api-contract";

import { Badge } from "~/components/ui/badge";
import { Stack } from "~/components/ui/stack";

export const RolesList = ({
  roles,
  direction,
  active,
}: {
  roles: Role[];
  direction: "row" | "column";
  active?: boolean;
}) => (
  <Stack gap="small" direction={direction}>
    {roles.map(role => (
      <Badge key={role} tone={active ? "positive" : "muted"} size="medium">
        {role}
      </Badge>
    ))}
  </Stack>
);
