import { Heading } from "~/components/ui/heading";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

type Props = {
  image?: React.ReactNode;
  title: string;
  description: React.ReactNode;
  action?: React.ReactNode;
};

export const EmptyState = ({
  image = null,
  title,
  description,
  action = null,
}: Props) => {
  return (
    <Stack align="center" justify="center" gap="large">
      {image}
      <Stack gap="medium">
        <Heading level="4" align="center">
          {title}
        </Heading>
        <Text align="center" tone="secondary">
          {description}
        </Text>
      </Stack>
      {action}
    </Stack>
  );
};
