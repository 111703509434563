import { ActivationRequestMember } from "@ses-mams/api-contract";
import { useCallback, useState } from "react";
import { Button } from "~/components/ui/button";
import { DrawerFooter } from "~/components/ui/drawer";
import { Stack } from "~/components/ui/stack";
import { useDeactivateMembers, useActivateMutations } from "../../../hooks";
import { RequestActivationDialog } from "./RequestActivationDialog";

type ManageActivationMembersFooterProps = {
  activationId: string;
  isClosed: boolean;
  isFutureActivation: boolean;
  selectedForActivation: Array<ActivationRequestMember>;
  selectedForDeactivation: Array<ActivationRequestMember>;
  clearSelectedMembers: () => void;
};

export const ManageActivationMembersFooter = ({
  activationId,
  isClosed,
  isFutureActivation,
  selectedForActivation,
  selectedForDeactivation,
  clearSelectedMembers,
}: ManageActivationMembersFooterProps) => {
  const [isRequestActivationDialogOpen, setIsRequestActivationDialogOpen] =
    useState(false);

  const { activate, isLoading } = useActivateMutations(
    activationId,
    selectedForActivation,
    clearSelectedMembers
  );

  const handleActivatePress = useCallback(async () => {
    if (!selectedForActivation.length) {
      return;
    }

    if (selectedForActivation.some(m => m.activatedToAnotherRequest)) {
      setIsRequestActivationDialogOpen(true);
      return;
    }

    await activate();
  }, [selectedForActivation, activate]);

  const { handleConfirmDeactivateMembers, isPending: isDeactivatingMembers } =
    useDeactivateMembers({
      activationId,
      confirmationText: `Please confirm that you'd like to ${
        isFutureActivation ? "uncommit" : "deactivate"
      } ${
        selectedForDeactivation.length === 1 ? "this member" : "these members"
      }`,
      onSuccess: clearSelectedMembers,
    });

  const handleDeactivatePress = useCallback(() => {
    if (!selectedForDeactivation.length) {
      return;
    }

    handleConfirmDeactivateMembers(selectedForDeactivation.map(m => m.id));
  }, [selectedForDeactivation]);

  const verb = isFutureActivation ? "Uncommit" : "Deactivate";
  const deactivateLabel = `${verb}${
    !isClosed && selectedForDeactivation.length
      ? ` (${selectedForDeactivation.length})`
      : ""
  }`;
  const activateLabel = `Activate${
    !isClosed && selectedForActivation.length
      ? ` (${selectedForActivation.length})`
      : ""
  }`;

  return (
    <>
      <DrawerFooter>
        <Stack direction="row" gap="medium">
          <Button
            fullWidth
            variant="destructive"
            busy={isDeactivatingMembers}
            disabled={isClosed || !selectedForDeactivation.length}
            onClick={handleDeactivatePress}
          >
            {deactivateLabel}
          </Button>
          <Button
            fullWidth
            busy={isLoading}
            disabled={isClosed || !selectedForActivation.length}
            onClick={handleActivatePress}
          >
            {activateLabel}
          </Button>
        </Stack>
      </DrawerFooter>

      <RequestActivationDialog
        open={isRequestActivationDialogOpen}
        numberOfSelectedMembers={selectedForActivation.length}
        isFutureActivation={isFutureActivation}
        onClose={() => setIsRequestActivationDialogOpen(false)}
        onConfirm={() => {
          setIsRequestActivationDialogOpen(false);
          activate();
        }}
      />
    </>
  );
};
