import { tsr } from "~/utils/client";

export const useOutOfAreaApprovalQuery = (requestId = "") => {
  return tsr.outOfAreaActivationApprovals.get.useQuery({
    queryKey: ["out-of-area-activation-approvals-v2", { requestId }],
    queryData: {
      params: {
        requestId,
      },
    },
    enabled: Boolean(requestId),
  });
};
