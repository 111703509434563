import { Card } from "~/components/ui/card";
import { AvailabilityReportHeader } from "./AvailabilityReportHeader";
import { AggregatedAvailabilityReport } from "./AggregatedAvailabilityReport";

export const AvailabilityReport = () => {
  return (
    <Card
      dividers
      dividerTone="neutral"
      sx={{ backgroundColor: "transparent" }}
    >
      <AvailabilityReportHeader />
      <AggregatedAvailabilityReport />
    </Card>
  );
};
