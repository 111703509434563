import { ActivityScheduleFormSchema } from "@ses-mams/validation";
import { Control, Controller, useWatch } from "react-hook-form";
import { Box } from "~/components/ui/box";
import { DateField } from "~/components/ui/dateField";
import { IntegerField } from "~/components/ui/integerField";
import { SelectField, SelectItem } from "~/components/ui/selectField";
import { Text } from "~/components/ui/text";

type ScheduleEndFields = {
  schedule: Pick<
    ActivityScheduleFormSchema["schedule"],
    "endMode" | "endCount" | "endDate"
  >;
};

type ActivityScheduleEndFieldsProps<T extends ScheduleEndFields> = {
  isEdit?: boolean;
  control: Control<T>;
};

export const ActivityScheduleEndFields = <T extends ScheduleEndFields>({
  isEdit,
  control: _control,
}: ActivityScheduleEndFieldsProps<T>) => {
  const control = _control as unknown as Control<ScheduleEndFields>;

  const watchEndMode = useWatch({ name: "schedule.endMode", control });

  return (
    <>
      <Controller
        name="schedule.endMode"
        control={control}
        render={({ field, fieldState }) => (
          <SelectField
            disabled={isEdit}
            label="Stop Repeating"
            placeholder="Select..."
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            errorMessage={fieldState.error?.message}
          >
            {SCHEDULE_END_OPTIONS.map(option => (
              <SelectItem key={option} value={option}>
                {option}
              </SelectItem>
            ))}
          </SelectField>
        )}
      />

      {watchEndMode === "On Date" && (
        <Controller
          shouldUnregister
          name="schedule.endDate"
          control={control}
          render={({ field, fieldState }) => (
            <DateField
              label="Stop Repeating Date"
              value={field.value ?? null}
              onChange={field.onChange}
              onBlur={field.onBlur}
              errorMessage={fieldState.error?.message}
            />
          )}
        />
      )}

      {watchEndMode === "After" && (
        <Controller
          shouldUnregister
          name="schedule.endCount"
          control={control}
          render={({ field, fieldState }) => (
            <IntegerField
              label="Stop Repeating After"
              placeholder="Amount"
              value={field.value ?? undefined}
              onValueChange={v => {
                field.onChange(v ?? null);
              }}
              onBlur={field.onBlur}
              errorMessage={fieldState.error?.message}
              endAdornment={
                <Box paddingX="medium" sx={{ width: "70%" }}>
                  <Text align="center">occurrence(s)</Text>
                </Box>
              }
            />
          )}
        />
      )}
    </>
  );
};

const SCHEDULE_END_OPTIONS = ["On Date", "After"];
