import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { endOfDay, startOfDay } from "date-fns";
import { z } from "zod";
import { Row, Stack } from "~/components/ui/stack";
import { AllUnitsSelector } from "~/components/common/allUnitsSelector";
import { Button } from "~/components/ui/button";
import { Text } from "~/components/ui/text";
import {
  Popover,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "~/components/ui/popover";
import { useCallback, useState } from "react";
import { FilterOutlineIcon } from "~/components/ui/icon";
import { DateTimePicker } from "~/components/ui/dateTimePicker";
import { TextField } from "~/components/ui/textField";
import { Divider } from "~/components/ui/divider";
import { SelectField, SelectItem } from "~/components/ui/selectField";
import { AuditActions } from "@ses-mams/validation";
import {
  ooaaAuditFiltersFormSchema,
  OoaaAuditLogsFilters,
} from "~/pages/audit/components/OoaaAuditLogsFilters";

const filtersFormSchema = z
  .intersection(
    z.object({
      unit: z
        .object({
          name: z.string(),
          id: z.string(),
        })
        .nullable()
        .optional(),
      minRequestCreatedAt: z.date().nullable().optional(),
      maxRequestCreatedAt: z.date().nullable().optional(),
      actions: z.array(z.string()).optional(),
      activationId: z.string().optional(),
      activationName: z.string().optional(),
      activityId: z.string().optional(),
      activityName: z.string().optional(),
      ooaaActivationId: z.string().optional(),
      ooaaActivationName: z.string().optional(),
      memberId: z.string().optional(),
      memberName: z.string().optional(),
      details: z.string().optional(),
    }),
    ooaaAuditFiltersFormSchema
  )
  .superRefine((val, ctx) => {
    if (
      val.minRequestCreatedAt &&
      val.maxRequestCreatedAt &&
      startOfDay(val.minRequestCreatedAt) > endOfDay(val.maxRequestCreatedAt)
    ) {
      ctx.addIssue({
        code: "custom",
        message: "Start date must be before or equal to End date",
        path: ["minRequestCreatedAt"],
      });
      ctx.addIssue({
        code: "custom",
        message: "End date must be after or equal to Start date",
        path: ["maxRequestCreatedAt"],
      });
    }
  });

export type FiltersFormSchema = z.infer<typeof filtersFormSchema>;

type FiltersFormProps = {
  isDisabled?: boolean;
  onSetFilters: (formData: FiltersFormSchema) => void;
};

export const AuditLogsFilters = ({
  isDisabled,
  onSetFilters,
}: FiltersFormProps) => {
  const [open, setOpen] = useState(false);

  const formMethods = useForm<FiltersFormSchema>({
    mode: "onBlur",
    resolver: zodResolver(filtersFormSchema),
  });
  const { control, handleSubmit, reset, register } = formMethods;

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleApplyFilters = useCallback((value: FiltersFormSchema) => {
    handleClose();
    onSetFilters(value);
  }, []);

  return (
    <PopoverRoot open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        <Button variant="secondary" startIcon={FilterOutlineIcon}>
          Filter
        </Button>
      </PopoverTrigger>

      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <PopoverContent>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(handleApplyFilters)}>
              <Row justify="space-between" align="center">
                <Text size="xlarge" weight="semi">
                  Filters
                </Text>
                <Button
                  variant="link"
                  onClick={() => {
                    reset({
                      actions: [],
                      unit: null,
                      minRequestCreatedAt: null,
                      maxRequestCreatedAt: null,
                      activationId: "",
                      activationName: "",
                      activityId: "",
                      activityName: "",
                      ooaaActivationId: "",
                      ooaaActivationName: "",
                      memberId: "",
                      memberName: "",
                      levelOfOoaaRequest: null,
                      ooaaRequestActivatedForId: null,
                      details: "",
                    });
                  }}
                >
                  Clear Filters
                </Button>
              </Row>
              <Stack gap="large" marginTop="large">
                <Controller
                  name="unit"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <AllUnitsSelector
                      label="Unit"
                      selectedUnitId={value?.id}
                      setSelectedUnit={onChange}
                      disabled={isDisabled}
                    />
                  )}
                />

                <Stack gap="xsmall">
                  <Text weight="medium">
                    Activity/Request/OOAA created date
                  </Text>
                  <Row align="center" justify="space-between" gap="large">
                    <Controller
                      name="minRequestCreatedAt"
                      control={control}
                      render={({ field: { onChange, value }, fieldState }) => (
                        <Stack>
                          <DateTimePicker
                            mode="date"
                            format="dd/MM/yyyy"
                            value={value ?? null}
                            onChange={onChange}
                          />
                          {fieldState.error ? (
                            <Text tone="critical">
                              {fieldState.error.message}
                            </Text>
                          ) : null}
                        </Stack>
                      )}
                    />
                    <Text>to</Text>{" "}
                    <Controller
                      name="maxRequestCreatedAt"
                      control={control}
                      render={({ field: { onChange, value }, fieldState }) => (
                        <Stack>
                          <DateTimePicker
                            mode="date"
                            format="dd/MM/yyyy"
                            value={value ?? null}
                            onChange={onChange}
                          />
                          {fieldState.error ? (
                            <Text tone="critical">
                              {fieldState.error.message}
                            </Text>
                          ) : null}
                        </Stack>
                      )}
                    />
                  </Row>
                </Stack>

                <Controller
                  name="actions"
                  control={control}
                  render={({ field: { value, onChange }, fieldState }) => (
                    <SelectField
                      label="Action"
                      multiple
                      placeholder="Select action(s)"
                      value={value || []}
                      onChange={onChange}
                      errorMessage={fieldState.error?.message}
                    >
                      {Object.values(AuditActions).map(action => (
                        <SelectItem key={action} value={action}>
                          {action}
                        </SelectItem>
                      ))}
                    </SelectField>
                  )}
                />

                <TextField
                  {...register("activationId")}
                  label="Request ID"
                  placeholder="Enter ID"
                />

                <TextField
                  {...register("activationName")}
                  label="Request Title"
                  placeholder="Enter title"
                />

                <TextField
                  {...register("activityId")}
                  label="Activity ID"
                  placeholder="Enter ID"
                />

                <TextField
                  {...register("activityName")}
                  label="Activity Title"
                  placeholder="Enter title"
                />

                <TextField
                  {...register("ooaaActivationId")}
                  label="OOAA Request ID"
                  placeholder="Enter ID"
                />

                <TextField
                  {...register("ooaaActivationName")}
                  label="OOAA Request Title"
                  placeholder="Enter title"
                />

                <Row gap="small">
                  <TextField
                    {...register("memberId")}
                    label="Member ID"
                    placeholder="Enter ID"
                  />

                  <TextField
                    {...register("memberName")}
                    label="Member Name"
                    placeholder="Enter name"
                  />
                </Row>

                <OoaaAuditLogsFilters />

                <TextField
                  {...register("details")}
                  label="Details"
                  placeholder="Enter details"
                />
                <Divider />
                <Row align="center" gap="small">
                  <Button variant="secondary" onClick={handleClose} fullWidth>
                    Close
                  </Button>
                  <Button type="submit" variant="primary" fullWidth>
                    Search
                  </Button>
                </Row>
              </Stack>
            </form>
          </FormProvider>
        </PopoverContent>
      </Popover>
    </PopoverRoot>
  );
};
