import { useCallback, useMemo, useState } from "react";
import { ActivityMember } from "@ses-mams/api-contract";
import { ActivityMembersSectionKeys } from "../utils";

export const useActivityMemberSelectedState = () => {
  const [selectAllState, setSelectAllState] = useState<
    Record<ActivityMembersSectionKeys, boolean>
  >(INITIAL_SELECT_ALL_STATE);

  const [selectedMembers, setSelectedMembers] = useState<Array<ActivityMember>>(
    []
  );

  const selectedGroups = useMemo(() => {
    const selectedMemberIds: Set<string> = new Set();
    const membersToConfirm: Array<ActivityMember> = [];

    selectedMembers.forEach(m => {
      if (m.status !== "AttendanceConfirmed") {
        membersToConfirm.push(m);
      }

      selectedMemberIds.add(m.member.id);
    });

    return {
      selectedMemberIds,
      selectedForConfirmation: membersToConfirm,
    };
  }, [selectedMembers]);

  const clearSelectedMembers = useCallback(() => {
    setSelectedMembers([]);
    setSelectAllState(INITIAL_SELECT_ALL_STATE);
  }, []);

  return {
    ...selectedGroups,
    selectAllState,
    setSelectAllState,
    setSelectedMembers,
    clearSelectedMembers,
  };
};

const INITIAL_SELECT_ALL_STATE = {
  Confirmed: false,
  Available: false,
  Conditional: false,
  Unavailable: false,
  Unset: false,
  Removed: false,
};
