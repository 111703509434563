import { BoxStyleProps, useBoxStyles } from "./useBoxStyles";

export const useBoxProps = <Props>({
  align,
  alignSelf,
  background,
  basis,
  border,
  borderBottom,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderBottomWidth,
  borderLeft,
  borderLeftWidth,
  borderRadius,
  borderRight,
  borderRightWidth,
  borderTop,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderTopWidth,
  borderWidth,
  bottom,
  direction,
  flex,
  grow,
  height,
  justify,
  left,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY,
  opacity,
  overflow,
  overflowX,
  overflowY,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY,
  position,
  right,
  shadow,
  shrink,
  top,
  width,
  wrap,
  zIndex,
  ...boxProps
}: BoxStyleProps & Props) => {
  const boxStyles = useBoxStyles({
    align,
    alignSelf,
    background,
    basis,
    border,
    borderBottom,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderBottomWidth,
    borderLeft,
    borderLeftWidth,
    borderRadius,
    borderRight,
    borderRightWidth,
    borderTop,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderTopWidth,
    borderWidth,
    bottom,
    direction,
    flex,
    grow,
    height,
    justify,
    left,
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    marginX,
    marginY,
    opacity,
    overflow,
    overflowX,
    overflowY,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingX,
    paddingY,
    position,
    right,
    shadow,
    shrink,
    top,
    width,
    wrap,
    zIndex,
  });

  return { boxStyles, boxProps };
};
