import { useEffect, useMemo } from "react";
import { UnitSelector } from "../unitSelector";
import { useAuth } from "~/context/auth";
import { useAvailabilityUnit } from "~/context/unit/AvailabilityUnitContextProvider";

export const MemberAvailabilityUnitSelector = () => {
  const { selectedUnit, setSelectedUnit } = useAvailabilityUnit();

  const { member } = useAuth();

  const memberUnits = useMemo(() => {
    return member?.units.map(({ unit }) => unit) ?? [];
  }, [member]);

  useEffect(() => {
    if (!selectedUnit && memberUnits.length) {
      setSelectedUnit(memberUnits[0]);
    }
  }, [selectedUnit, memberUnits]);

  return (
    <UnitSelector
      isLoading={!selectedUnit}
      units={memberUnits}
      selectedUnit={selectedUnit}
      setSelectedUnit={unit => {
        setSelectedUnit(unit);
      }}
    />
  );
};
