import { BackButton } from "~/components/common/navigation";
import { useParams } from "react-router-dom";
import { Stack } from "~/components/ui/stack";
import { Map } from "~/components/ui/map";
import { Box } from "~/components/ui/box";
import { tsr } from "~/utils/client";

import { toLatLng } from "~/utils/latLng";
import { PinMarker } from "../shared";
import { useMemo, useState } from "react";

const REFRESH_INTERVAL = 10000; // 10 seconds

export const ActivationMapPage = () => {
  const params = useParams();
  const activationId = params.activationId as string;

  const [selectedPin, setSelectedPin] = useState<string>();

  const { data } = tsr.activations.getActivationPointsOfInterest.useQuery({
    queryKey: ["activations", activationId, "pointsOfInterest"],
    queryData: {
      params: {
        activationId,
      },
    },
    refetchInterval: REFRESH_INTERVAL,
  });

  const pointsOfInterest = data?.body;
  const center = useMemo(
    () => pointsOfInterest && toLatLng(pointsOfInterest.activation),
    [
      pointsOfInterest?.activation.latitude,
      pointsOfInterest?.activation.longitude,
    ]
  );

  return (
    <Stack sx={{ height: "100%" }} gap="large">
      <BackButton />
      <Box flex={1}>
        <Map center={center}>
          {pointsOfInterest && (
            <>
              <PinMarker
                type="event"
                coords={pointsOfInterest.activation}
                address={pointsOfInterest.activation.location}
                selected={selectedPin === "event"}
                setSelected={() => setSelectedPin("event")}
              />
              <PinMarker
                type="venue"
                coords={pointsOfInterest.unit}
                address={pointsOfInterest.unit.address}
                selected={selectedPin === "venue"}
                setSelected={() => setSelectedPin("venue")}
              />
              {pointsOfInterest.members.map((member, idx) => (
                <PinMarker
                  key={member.id}
                  type="member"
                  coords={member}
                  member={member}
                  selected={selectedPin === `member${idx}`}
                  setSelected={() => setSelectedPin(`member${idx}`)}
                />
              ))}
            </>
          )}
        </Map>
      </Box>
    </Stack>
  );
};
