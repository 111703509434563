import { addHours, startOfDay } from "date-fns";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { Group } from "@ses-mams/api-contract";
import {
  formatDateForServer,
  useAvailabilityWeekSelector,
  useUpdateEffect,
} from "@ses-mams/react-utils";

import { tsr } from "~/utils/client";
import { useSelectedUnit } from "~/context/unit/SelectedUnitContextProvider";

export enum AvailabilityViewMode {
  AGGREGATED,
  INDIVIDUAL,
}

type AvailabilityReportContextType = {
  unitId: string;
  groups: Group[];
  isLoadingGroups?: boolean;
  startDate: string;
  endDate: string;
  selectedDay: Date;
  firstDayOfTheWeek: Date;
  lastDayOfTheWeek: Date;
  availabilityViewMode: AvailabilityViewMode;
  setAvailabilityViewMode: (viewMode: AvailabilityViewMode) => void;
  goToPreviousWeek: () => void;
  goToNextWeek: () => void;
  goToToday: () => void;
  setSelectedDay: (day: Date) => void;
};

const AvailabilityReportContext = createContext<AvailabilityReportContextType>({
  unitId: "",
  groups: [],
  startDate: "",
  endDate: "",
  firstDayOfTheWeek: new Date(),
  lastDayOfTheWeek: new Date(),
  selectedDay: new Date(),
  availabilityViewMode: AvailabilityViewMode.AGGREGATED,
  setAvailabilityViewMode: () => null,
  goToPreviousWeek: () => null,
  goToNextWeek: () => null,
  goToToday: () => null,
  setSelectedDay: () => null,
});

export const AvailabilityReportContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const { selectedUnit } = useSelectedUnit();
  const unitId = selectedUnit?.id || "";
  const {
    firstDayOfTheWeek,
    lastDayOfTheWeek,
    goToPreviousWeek,
    goToNextWeek,
    goToCurrentWeek,
  } = useAvailabilityWeekSelector();

  const [availabilityViewMode, setAvailabilityViewMode] = useState(
    AvailabilityViewMode.AGGREGATED
  );

  const [selectedDay, setSelectedDay] = useState(firstDayOfTheWeek);

  const { startDate, endDate } = useMemo(
    () => ({
      startDate: formatDateForServer(selectedDay),
      endDate: formatDateForServer(addHours(selectedDay, 24)),
    }),
    [selectedDay]
  );

  useUpdateEffect(() => {
    // Update the selected day when the report week changes
    setSelectedDay(firstDayOfTheWeek);
  }, [firstDayOfTheWeek]);

  const { data: groupData, isLoading: isLoadingGroups } =
    tsr.units.listGroups.useQuery({
      queryKey: ["groups", { unitId }, true],
      queryData: {
        params: { unitId },
        query: { reportableOnly: true },
      },
      enabled: !!unitId,
    });

  const groups = groupData?.body ?? [];

  const goToToday = useCallback(() => {
    goToCurrentWeek();
    setSelectedDay(startOfDay(new Date()));
  }, []);

  const value = useMemo(
    () => ({
      unitId,
      groups,
      isLoadingGroups,
      firstDayOfTheWeek,
      lastDayOfTheWeek,
      selectedDay,
      setSelectedDay,
      startDate,
      endDate,
      availabilityViewMode,
      setAvailabilityViewMode,
      goToPreviousWeek,
      goToNextWeek,
      goToToday,
    }),
    [
      unitId,
      groups,
      isLoadingGroups,
      firstDayOfTheWeek,
      lastDayOfTheWeek,
      selectedDay,
      setSelectedDay,
      startDate,
      endDate,
      availabilityViewMode,
      setAvailabilityViewMode,
      goToPreviousWeek,
      goToNextWeek,
      goToToday,
    ]
  );

  return (
    <AvailabilityReportContext.Provider value={value}>
      {children}
    </AvailabilityReportContext.Provider>
  );
};

export const useAvailabilityReportContext = () => {
  const ctx = useContext(AvailabilityReportContext);

  if (!ctx) {
    throw new Error(
      "Must be called within <AvailabilityReportContextProvider>"
    );
  }

  return ctx;
};
