import React from "react";
import { Button } from "~/components/ui/button";
import { FilterOutlineIcon } from "~/components/ui/icon";
import {
  Popover,
  PopoverContent,
  PopoverProps,
  PopoverRoot,
  PopoverTrigger,
} from "~/components/ui/popover";

type FilterPopoverProps = Pick<PopoverProps, "sx"> & {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  children: React.ReactNode;
};

export const FilterPopover = ({
  open,
  onOpenChange,
  sx,
  children,
}: FilterPopoverProps) => {
  return (
    <PopoverRoot open={open} onOpenChange={onOpenChange}>
      <PopoverTrigger>
        <Button variant="secondary" startIcon={FilterOutlineIcon}>
          Filter
        </Button>
      </PopoverTrigger>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={sx}
      >
        <PopoverContent sx={{ minWidth: 300 }}>{children}</PopoverContent>
      </Popover>
    </PopoverRoot>
  );
};
