import { Stack } from "~/components/ui/stack";
import { ScheduleGrid } from "./ScheduleGrid";
import { ScheduleGuide } from "./ScheduleGuide";
import { ScheduleItem } from "@ses-mams/api-contract";
import { ScheduleAvailability } from "@ses-mams/react-utils";

type ViewScheduleGridProps = {
  schedule: ScheduleItem[];
  availability: ScheduleAvailability;
};

export const ViewScheduleGrid = ({
  availability,
  schedule,
}: ViewScheduleGridProps) => {
  return (
    <Stack direction="row" overflowX="auto" sx={{ height: "100%" }}>
      <ScheduleGuide schedule={schedule} />
      <ScheduleGrid availability={availability} />
    </Stack>
  );
};
