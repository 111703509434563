import { SearchMembersFilter, Suggestion } from "@ses-mams/api-contract";

import { formatSuggestionsToSearchFilters } from "./memberSuggestions";

export type SelectAllMembersQuery = Pick<
  SearchMembersFilter,
  | "query"
  | "exclude"
  | "currentAvailabilityStatusTime"
  | "requiredAvailabilityStart"
  | "requiredAvailabilityEnd"
> & {
  suggestions: Suggestion[];
};

export const formatSelectAllMembersQuery = (
  selectAllMembersQuery?: SelectAllMembersQuery
) => {
  if (selectAllMembersQuery && Object.keys(selectAllMembersQuery).length > 0) {
    const { suggestions = [], ...others } = selectAllMembersQuery;
    return {
      ...others,
      ...formatSuggestionsToSearchFilters(suggestions),
    };
  }

  return undefined;
};
