import { createFilledIcon } from "../createIcon";

export const EyeFilledIcon = createFilledIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0004 18.5984C17.9409 18.6919 20.5608 14.3669 21.3383 12.7305C21.525 12.3376 21.5287 11.8895 21.3492 11.4933C20.5957 9.82992 18.0236 5.3999 12.0004 5.3999C5.98994 5.3999 3.41596 9.81115 2.65636 11.4827C2.47325 11.8856 2.47934 12.3399 2.67542 12.7367C3.46875 14.3422 6.09236 18.5054 12.0004 18.5984ZM12.0004 16.0625C14.3795 16.0625 16.3081 14.2562 16.3081 12.028C16.3081 9.79979 14.3795 7.9935 12.0004 7.9935C9.6213 7.9935 7.6927 9.79979 7.6927 12.028C7.6927 14.2562 9.6213 16.0625 12.0004 16.0625Z"
    />
    <path d="M14.4004 12.028C14.4004 13.2694 13.3259 14.2758 12.0004 14.2758C10.6749 14.2758 9.60039 13.2694 9.60039 12.028C9.60039 10.7866 10.6749 9.78019 12.0004 9.78019C13.3259 9.78019 14.4004 10.7866 14.4004 12.028Z" />
  </>,
  "EyeFilledIcon"
);
