import { Member, OutOfAreaActivationRequest } from "@ses-mams/api-contract";
import { useCallback } from "react";
import { useArraysIntersect } from "./useArraysIntersect";

export const useGetCanViewOutOfAreaActivationRequestHistory = (
  member: Member | undefined
) => {
  const hasCoordinatorOrApproverRole = useArraysIntersect(
    [
      "OperationalCoordinator",
      "OOAACoordinator",
      "Administrator",
      "SystemAdministrator",
      "UnitApprover",
      "ClusterApprover",
      "ZoneApprover",
    ],
    member?.roles ?? []
  );

  const getCanViewOutOfAreaActivationRequestHistory = useCallback(
    (request?: Pick<OutOfAreaActivationRequest, "member">) => {
      if (!member || !request) {
        return false;
      }

      if (member.id === request.member.id) {
        return true;
      }

      return hasCoordinatorOrApproverRole;
    },
    [hasCoordinatorOrApproverRole, member]
  );

  return {
    getCanViewOutOfAreaActivationRequestHistory,
  };
};
