import React, { useCallback, useMemo } from "react";
import { Member } from "@ses-mams/api-contract";
import {
  SelectAllMembersQuery,
  formatSelectAllMembersQuery,
} from "@ses-mams/react-utils";
import { InfiniteList } from "~/components/ui/list";
import { MemberListItem } from "~/components/common/searchMembersList";
import { tsr } from "~/utils/client";
import { DEFAULT_PAGE_SIZE, getNextPageParam } from "~/utils/pagination";

type SelectedMembersListProp = {
  showAvailabilityStatus?: boolean;
  selectAllMembersQuery?: SelectAllMembersQuery;
  members?: Array<Member>;
  renderItem?: (member: Member) => React.ReactElement;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
};

export const SelectedMembersList = ({
  showAvailabilityStatus,
  selectAllMembersQuery,
  members,
  renderItem: renderItemProp,
  hasNextPage = false,
  fetchNextPage = () => null,
}: SelectedMembersListProp) => {
  const searchQuery = formatSelectAllMembersQuery(selectAllMembersQuery);
  const hasSearchQuery = Boolean(searchQuery);

  const {
    data: memberListData,
    hasNextPage: memberSearchHasNextPage,
    fetchNextPage: memberSearchFetchNextPage,
  } = tsr.members.search.useInfiniteQuery({
    queryKey: [
      "members-search",
      searchQuery?.query,
      searchQuery?.capabilityIds,
      searchQuery?.unitIds,
      searchQuery?.clusterIds,
      searchQuery?.zoneIds,
      searchQuery?.exclude,
      searchQuery?.groupIds,
      searchQuery?.contactGroupIds,
      searchQuery?.currentAvailabilityStatusTime,
      searchQuery?.requiredAvailabilityStart,
      searchQuery?.requiredAvailabilityEnd,
    ],
    queryData: ({ pageParam = { skip: 0, take: DEFAULT_PAGE_SIZE } }) => ({
      query: {
        ...searchQuery,
        currentAvailabilityStatusTime:
          searchQuery?.currentAvailabilityStatusTime
            ? searchQuery?.currentAvailabilityStatusTime
            : undefined,
        skip: pageParam.skip,
        take: pageParam.take,
      },
    }),
    initialPageParam: { skip: 0, take: DEFAULT_PAGE_SIZE },
    getNextPageParam,
    enabled: hasSearchQuery,
  });

  const renderItem = useCallback(
    (item: Member) => {
      if (renderItemProp) {
        return renderItemProp(item);
      }

      return (
        <MemberListItem
          member={item}
          showAvailabilityStatus={showAvailabilityStatus}
        />
      );
    },
    [renderItemProp, showAvailabilityStatus]
  );

  const memberData = useMemo(() => {
    return hasSearchQuery
      ? (memberListData?.pages || []).flatMap(page =>
          page.status === 200 ? page.body.items : []
        )
      : members;
  }, [memberListData, members, hasSearchQuery]) as Array<Member>;

  return (
    <InfiniteList
      data={memberData}
      renderItem={renderItem}
      {...(hasSearchQuery
        ? {
            hasNextPage: memberSearchHasNextPage,
            fetchNextPage: memberSearchFetchNextPage,
          }
        : {
            hasNextPage,
            fetchNextPage,
          })}
      keyExtractor={(item, index) => `${item.id}-${index}`}
    />
  );
};
