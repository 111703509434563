import { ButtonBase } from "@mui/material";
import { ContactGroup, Group } from "@ses-mams/api-contract";
import emptyListImageUrl from "~/assets/emptyList.png";
import { ContactGroupListItem } from "~/components/common/contactGroup";
import { EmptyState } from "~/components/common/emptyState";
import { GroupListItem } from "~/components/common/group";
import { Box } from "~/components/ui/box";
import { Checkbox } from "~/components/ui/checkbox";
import { SectionHeader } from "~/components/ui/list";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { tsr } from "~/utils/client";

export type SelectUnitGroupsListProps = {
  groups: Array<Group>;
  contactGroups: Array<ContactGroup>;
  unitId: string;
  setGroups: React.Dispatch<React.SetStateAction<Array<Group>>>;
  setContactGroups: React.Dispatch<React.SetStateAction<Array<ContactGroup>>>;
};

export const SelectUnitGroupsList = ({
  groups,
  contactGroups,
  setGroups,
  setContactGroups,
  unitId,
}: SelectUnitGroupsListProps) => {
  const { data: groupsData, isLoading: isLoadingGroups } =
    tsr.units.listGroups.useQuery({
      queryKey: ["groups", { unitId }],
      queryData: {
        params: { unitId },
      },
    });

  const { data: contactGroupsData } = tsr.contactGroups.list.useQuery({
    queryKey: ["contact-groups", { unitId }],
    queryData: {
      params: { unitId },
    },
  });

  return (
    <>
      <Stack gap="small">
        <SectionHeader>Groups</SectionHeader>

        {isLoadingGroups ? (
          <Box display="flex" grow={1} justify="center" paddingY="large">
            <Spinner />
          </Box>
        ) : (
          <Stack>
            {groupsData?.body?.length ? (
              groupsData.body.map(group => {
                const isChecked = groups.some(g => g.id === group.id);

                return (
                  <ButtonBase
                    key={group.id}
                    role="checkbox"
                    aria-label={group.name}
                    aria-checked={isChecked}
                    onClick={() => {
                      if (isChecked) {
                        setGroups(prev => prev.filter(g => g.id !== group.id));
                        return;
                      }

                      setGroups(prev => [...prev, group]);
                    }}
                  >
                    <GroupListItem
                      group={group}
                      startAdornment={<Checkbox checked={isChecked} />}
                    />
                  </ButtonBase>
                );
              })
            ) : (
              <EmptyState
                image={<img src={emptyListImageUrl} />}
                title="No groups added"
                description="You haven't created any groups yet."
              />
            )}
          </Stack>
        )}
      </Stack>

      {contactGroupsData?.body?.length ? (
        <Stack gap="small">
          <SectionHeader>Beacon Contact Groups</SectionHeader>

          <Stack>
            {contactGroupsData.body.map(contactGroup => {
              const isChecked = contactGroups.some(
                cg => cg.id === contactGroup.id
              );

              return (
                <ButtonBase
                  key={contactGroup.id}
                  role="checkbox"
                  aria-label={contactGroup.name}
                  aria-checked={isChecked}
                  onClick={() => {
                    if (isChecked) {
                      setContactGroups(prev =>
                        prev.filter(cg => cg.id !== contactGroup.id)
                      );
                      return;
                    }

                    setContactGroups(prev => [...prev, contactGroup]);
                  }}
                >
                  <ContactGroupListItem
                    contactGroup={contactGroup}
                    startAdornment={<Checkbox checked={isChecked} />}
                  />
                </ButtonBase>
              );
            })}
          </Stack>
        </Stack>
      ) : null}
    </>
  );
};
