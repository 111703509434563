import { forwardRef, useCallback } from "react";
import { FormControlLabel } from "@mui/material";
import { Text } from "~/components/ui/text";
import { Checkbox, CheckboxProps } from "./Checkbox";
import { useCheckboxGroupContext } from "./CheckboxGroupContext";

export type CheckboxFieldProps = CheckboxProps & {
  label: string | React.ReactNode;
};

export const CheckboxField = forwardRef<HTMLInputElement, CheckboxFieldProps>(
  (
    { label, sx, onChange, disabled, checked, value, ...checkboxProps },
    forwardedRef
  ) => {
    const ctx = useCheckboxGroupContext();

    const isDisabled = disabled || ctx?.disabled;
    const isChecked = Boolean(
      checked || (value && ctx?.value?.includes(value as string | number))
    );

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>, nextChecked: boolean) => {
        onChange?.(event, nextChecked);

        ctx?.onValueChange?.(
          (nextChecked
            ? [...ctx.value, value]
            : ctx.value.filter(item => item !== value)) as Array<
            string | number
          >
        );
      },
      [onChange, value, ctx.value, ctx.onValueChange]
    );

    return (
      <FormControlLabel
        control={
          <Checkbox
            {...checkboxProps}
            ref={forwardedRef}
            disabled={isDisabled}
            checked={isChecked}
            value={value}
            onChange={handleChange}
          />
        }
        label={
          typeof label === "string" ? (
            <Text weight="medium">{label}</Text>
          ) : (
            label
          )
        }
        sx={[
          theme => ({
            marginLeft: 0,
            gap: theme.tokens.spacing.small,
            ":disabled": {
              cursor: "not-allowed",
            },
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      />
    );
  }
);
