import { useCallback } from "react";

import {
  InfiniteSectionList,
  RenderSectionHeaderParams,
} from "~/components/ui/list";

import emptyListImageUrl from "~/assets/emptyList.png";
import { Text } from "~/components/ui/text";
import { Box } from "~/components/ui/box";
import { Row, Stack } from "~/components/ui/stack";
import { Spinner } from "~/components/ui/spinner";
import { EmptyState } from "~/components/common/emptyState";
import { Broadcast } from "@ses-mams/api-contract";
import {
  formatDateTime,
  useBroadcastListSections,
} from "@ses-mams/react-utils";
import { tsr } from "~/utils/client";
import { DEFAULT_PAGE_SIZE, getNextPageParam } from "~/utils/pagination";
import { RequestCard } from "~/pages/requests/shared";
import { Badge } from "~/components/ui/badge";

export const BroadcastList = () => {
  const {
    isLoading,
    data: _data,
    fetchNextPage,
    hasNextPage,
  } = tsr.broadcasts.list.useInfiniteQuery({
    queryKey: ["broadcasts", "list"],
    queryData: ({ pageParam = { skip: 0, take: DEFAULT_PAGE_SIZE } }) => ({
      query: {
        skip: pageParam.skip,
        take: pageParam.take,
      },
    }),
    initialPageParam: { skip: 0, take: DEFAULT_PAGE_SIZE },
    getNextPageParam,
  });

  const sections = useBroadcastListSections(_data?.pages);

  const renderSectionHeader = useCallback(
    ({ title }: RenderSectionHeaderParams<Broadcast>) => {
      return (
        <Box
          background="surface"
          position="sticky"
          paddingY="small"
          sx={{ top: 0, zIndex: 10 }}
        >
          <Text weight="medium" size="large">
            {title}
          </Text>
        </Box>
      );
    },
    []
  );

  const renderItem = ({
    id,
    title,
    start,
    end,
    visibility,
    timing,
  }: Broadcast) => (
    <Box display="flex" paddingBottom="medium" sx={{ width: "100%" }}>
      <RequestCard to={`/admin/broadcast/${id}`}>
        <Stack align="start" gap="medium">
          <Text weight="medium" tone={"primary"} size="large">
            {title || "Untitled"}
          </Text>
          {timing === "SendLater" && start && (
            <Text size="standard" weight="regular">
              {`Scheduled for: ${formatDateTime(new Date(start))}`}
            </Text>
          )}
          {end && (
            <Text size="standard" weight="regular">
              {`Ends on: ${formatDateTime(new Date(end))}`}
            </Text>
          )}
          <Row gap="small">
            {visibility === "ShowInApp" || visibility === "ShowInAppAndWeb" ? (
              <Badge size="small" tone="infoMuted">
                App
              </Badge>
            ) : null}
            {visibility === "ShowInWeb" || visibility === "ShowInAppAndWeb" ? (
              <Badge size="small" tone="accentSecondaryMuted">
                Web
              </Badge>
            ) : null}
          </Row>
        </Stack>
      </RequestCard>
    </Box>
  );

  const keyExtractor = useCallback(
    (item: Broadcast) => (item as Broadcast)?.id,
    []
  );

  return (
    <Stack gap="medium">
      {isLoading ? (
        <Stack align="center">
          <Spinner />
        </Stack>
      ) : (
        <InfiniteSectionList
          sections={sections}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          renderItem={renderItem}
          renderSectionHeader={renderSectionHeader}
          listItemDivider={false}
          listEmptyElement={
            isLoading ? null : (
              <Box display="flex" justify="center" sx={{ height: "100%" }}>
                <EmptyState
                  image={<img src={emptyListImageUrl} />}
                  title="No messages"
                  description="There are no broadcast messages found."
                />
              </Box>
            )
          }
          keyExtractor={keyExtractor}
        />
      )}
    </Stack>
  );
};
