import {
  formatMemberFullName,
  useAvailabilitySelectedWeek,
} from "@ses-mams/react-utils";
import { useCallback, useState } from "react";
import { useEditAvailability } from "./hooks/useEditAvailability";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { AvailabilityStatus } from "@ses-mams/api-contract";
import { useToast } from "~/components/ui/toast";
import { captureException } from "@sentry/react";
import { Stack } from "~/components/ui/stack";
import { Heading } from "~/components/ui/heading";
import { Button } from "~/components/ui/button";
import { Box } from "~/components/ui/box";
import { AvailabilityWeekSelector } from "~/components/common/availabilitySelector";
import { SetAvailabilityDrawer } from "./components/common/SetAvailabilityDrawer";
import { GridCellHeightContextProvider } from "./context/gridCellHeightContext";
import { Spinner } from "~/components/ui/spinner";
import { EditAvailabilityHourlyGrid } from "./components/hourly/EditAvailabilityHourlyGrid";
import { useOtherMemberAvailabilityData } from "./hooks/useOtherMemberAvailabilityData";
import { OtherMemberAvailabilityLayout } from "./components/common/OtherMemberAvailabilityLayout";

export const EditOtherMemberAvailabilityPage = () => {
  const params = useParams();
  const memberId = params.memberId as string;

  const [searchParams] = useSearchParams();
  const unitId = searchParams.get("unitId") ?? undefined;

  const {
    firstDayOfTheWeek,
    lastDayOfTheWeek,
    goToNextWeek,
    goToPreviousWeek,
  } = useAvailabilitySelectedWeek();
  const [showAvailabilityDrawer, setShowAvailabiliityDrawer] = useState(false);

  const [selectedValue, setSelectedValue] = useState<{
    status: AvailabilityStatus | null;
    emergenciesOnly?: boolean;
    conditionalReason?: string;
  }>({ status: null });

  const {
    availabilityToUpdate,
    handleEditAvailability,
    hasSelectedCells,
    handleCellSelectionStatusChanged,
  } = useEditAvailability();

  const { isLoading, hourlyAvailability, member, mutate, isMutating } =
    useOtherMemberAvailabilityData({
      memberId,
      startOfTheWeek: firstDayOfTheWeek,
      endOfTheWeek: lastDayOfTheWeek,
      unitId,
    });

  const navigate = useNavigate();

  const { addToast } = useToast();

  const handleOnDone = useCallback(async () => {
    if (hasSelectedCells) {
      setShowAvailabiliityDrawer(true);
      return;
    }

    try {
      await mutate(availabilityToUpdate.current);
      navigate({
        pathname: `/availability/member/${memberId}`,
        search: createSearchParams({
          unitId: unitId ?? "",
        }).toString(),
      });
    } catch (error) {
      captureException(error);
      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [hasSelectedCells, mutate]);

  return (
    <OtherMemberAvailabilityLayout isEdit={true}>
      <Stack direction="column" gap="medium" sx={{ height: "100%" }}>
        <Stack direction="row" justify="space-between" align="center">
          {!!member && (
            <Heading level="1">{formatMemberFullName(member)}</Heading>
          )}
          <Button onClick={handleOnDone} disabled={isMutating}>
            {hasSelectedCells ? "Next" : "Done"}
          </Button>
        </Stack>

        <Box>
          <Stack
            direction={{
              xs: "column-reverse",
              md: "row",
            }}
            gap="medium"
            justify="space-between"
            align="center"
            padding="medium"
            sx={{
              width: "100%",
            }}
          >
            <Stack
              gap="medium"
              justify="space-between"
              grow={1}
              direction={{
                xs: "column",
                md: "row",
              }}
              sx={{ width: "100%" }}
            >
              <AvailabilityWeekSelector
                lastDayOfTheWeek={lastDayOfTheWeek}
                firstDayOfTheWeek={firstDayOfTheWeek}
                onGoToNextWeek={goToNextWeek}
                onGoToPreviousWeek={goToPreviousWeek}
              />
            </Stack>
          </Stack>

          <GridCellHeightContextProvider numberOfScheduleItems={0}>
            {isLoading ? (
              <Box
                display="flex"
                justify="center"
                align="center"
                sx={{ height: "100vh", width: "100%" }}
              >
                <Spinner />
              </Box>
            ) : (
              <EditAvailabilityHourlyGrid
                availability={hourlyAvailability}
                onAvailabilityEdited={handleEditAvailability}
                firstDayOfTheWeek={firstDayOfTheWeek}
                lastDayOfTheWeek={lastDayOfTheWeek}
                selectedValue={selectedValue}
                onCellSelectionStatusChanged={handleCellSelectionStatusChanged}
              />
            )}
          </GridCellHeightContextProvider>
        </Box>
      </Stack>
      <SetAvailabilityDrawer
        open={showAvailabilityDrawer}
        onClose={() => {
          setShowAvailabiliityDrawer(false);
        }}
        onDone={setSelectedValue}
      />
    </OtherMemberAvailabilityLayout>
  );
};
