import {
  FormControl,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import { forwardRef } from "react";
import { useId } from "~/hooks/useId";
import { Label } from "./Label";
import { HelperText } from "./HelperText";
import { Input } from "./Input";

export type TextFieldProps = Pick<
  MuiTextFieldProps,
  | "id"
  | "defaultValue"
  | "value"
  | "disabled"
  | "placeholder"
  | "multiline"
  | "rows"
  | "onChange"
  | "onBlur"
> & {
  label: string;
  hideLabel?: boolean;
  errorMessage?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
};

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      hideLabel,
      label,
      errorMessage,
      disabled,
      startAdornment,
      endAdornment,
      ...inputProps
    },
    forwardedRef
  ) => {
    const id = useId(inputProps.id);
    const helperTextId = useId();
    const isInvalid = Boolean(errorMessage);

    return (
      <FormControl variant="standard" fullWidth>
        <Label htmlFor={id} disabled={disabled} hideLabel={hideLabel}>
          {label}
        </Label>

        <Input
          {...inputProps}
          ref={forwardedRef}
          fullWidth
          id={id}
          error={isInvalid}
          disabled={disabled}
          hideLabel={hideLabel}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          aria-describedby={isInvalid ? helperTextId : undefined}
        />
        {errorMessage && (
          <HelperText id={helperTextId} role="alert">
            {errorMessage}
          </HelperText>
        )}
      </FormControl>
    );
  }
);
