import {
  FormControl,
  RadioGroupProps as MuiRadioGroupProps,
  RadioGroup,
} from "@mui/material";
import { HelperText, Label } from "~/components/ui/textField";
import { Stack } from "~/components/ui/stack";
import { useId } from "~/hooks/useId";
import { RadioGroupFieldItemProps } from "./RadioGroupFieldItem";

type ChildType = React.ReactElement<RadioGroupFieldItemProps>;

type RadioGroupFieldProps = Pick<
  MuiRadioGroupProps,
  "value" | "onChange" | "onBlur" | "id"
> & {
  label: string;
  disabled?: boolean;
  hideLabel?: boolean;
  errorMessage?: string;
  children: ChildType | Array<ChildType>;
};

export const RadioGroupField = ({
  id: _id,
  label,
  onBlur,
  onChange,
  errorMessage,
  disabled,
  hideLabel,
  value,
  children,
}: RadioGroupFieldProps) => {
  const id = useId(_id);
  const helperTextId = useId();
  const isInvalid = Boolean(errorMessage);

  return (
    <Stack>
      <Label htmlFor={id} disabled={disabled} hideLabel={hideLabel}>
        {label}
      </Label>
      <FormControl variant="standard" fullWidth disabled={disabled}>
        <RadioGroup
          id={id}
          aria-describedby={isInvalid ? helperTextId : undefined}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          sx={theme => ({ gap: theme.tokens.spacing.small })}
        >
          {children}
        </RadioGroup>
        {errorMessage && (
          <HelperText id={helperTextId} role="alert">
            {errorMessage}
          </HelperText>
        )}
      </FormControl>
    </Stack>
  );
};
