type WithChildren<T = unknown> = T & {
  children: React.ReactNode;
};

type ComposeProvidersProps = WithChildren<{
  providers: React.ComponentType<WithChildren>[];
}>;

export const ComposeProviders = ({
  providers = [],
  children,
}: ComposeProvidersProps) => {
  return (
    <>
      {providers.reduceRight((acc, Provider) => {
        return <Provider>{acc}</Provider>;
      }, children)}
    </>
  );
};
