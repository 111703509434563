import { Outlet } from "react-router-dom";

import { Box } from "~/components/ui/box";
import { Stack } from "~/components/ui/stack";
import { Header } from "~/components/common/header";
import { UnreadMessagesBanner } from "../broadcast";

export const PageLayout = () => (
  <Stack direction="column" sx={{ height: "100vh" }}>
    <Header />
    <UnreadMessagesBanner />
    <Box
      flex={1}
      padding="large"
      sx={{
        margin: "0 auto",
        maxWidth: "1600px",
        width: "100%",
        display: "flex",
      }}
    >
      <main style={{ width: "100%" }}>
        <Outlet />
      </main>
    </Box>
  </Stack>
);
