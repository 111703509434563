import { Container } from "@mui/material";

import { Heading } from "~/components/ui/heading";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { tsr } from "~/utils/client";
import { MyScheduleForm } from "./components/MyScheduleForm";

export const MySchedulePage = () => {
  const { data } = tsr.schedule.get.useQuery({ queryKey: ["schedule"] });

  if (!data?.body) {
    return <Spinner />;
  }

  return (
    <Container maxWidth="sm">
      <Stack gap="medium">
        <Heading level="1">My Schedule</Heading>
        <Text>
          Choose your standard working day, e.g. before work, after work,
          during, etc. This allows you to quickly set your availability based on
          your schedule.
        </Text>
        <MyScheduleForm currentSchedule={data.body} />
      </Stack>
    </Container>
  );
};
