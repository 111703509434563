import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerProps,
} from "~/components/ui/drawer";
import { useOutOfAreaActivationRequestHistoryDetails } from "~/pages/requests/hooks";
import { Box } from "~/components/ui/box";
import { Spinner } from "~/components/ui/spinner";
import { OOAARequestHistory } from "../OOAARequestHistory";
import { Stack } from "~/components/ui/stack";
import { ArrowTopRightOnSquareOutlineIcon } from "~/components/ui/icon";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material";

type OOAARequestHistoryDrawerProps = Omit<DrawerProps, "children"> & {
  requestId: string;
};

export const OOAARequestHistoryDrawer = ({
  requestId,
  ...props
}: OOAARequestHistoryDrawerProps) => {
  const { isLoading, data } =
    useOutOfAreaActivationRequestHistoryDetails(requestId);

  const {
    tokens: {
      colors: { foreground },
    },
  } = useTheme();

  const history = data?.body?.history;
  const request = data?.body?.request;

  return (
    <Drawer {...props}>
      <DrawerHeader {...props}>
        <Link
          to={`history?requestId=${requestId}`}
          style={{ color: foreground.action, textDecoration: "none" }}
        >
          <Stack direction="row" gap="small" align="center">
            History
            <ArrowTopRightOnSquareOutlineIcon size="xxsmall" tone="action" />
          </Stack>
        </Link>
      </DrawerHeader>

      <DrawerContent>
        {isLoading || !history || !request ? (
          <Box display="flex" grow={1} justify="center">
            <Spinner />
          </Box>
        ) : (
          <OOAARequestHistory history={history} request={request} />
        )}
      </DrawerContent>
    </Drawer>
  );
};
