import { initContract } from "@ts-rest/core";
import { z } from "zod";
import { ApiErrorSchema } from "./common";

const c = initContract();

export const ContactGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const contactGroupsContract = c.router({
  get: {
    method: "GET",
    path: "/units/:unitId/contact-groups/:id",
    pathParams: z.object({
      unitId: z.string(),
      id: z.string(),
    }),
    responses: {
      200: ContactGroupSchema,
      404: ApiErrorSchema,
    },
    summary: "Get the details of a contact group",
  },
  list: {
    method: "GET",
    path: "/units/:unitId/contact-groups",
    pathParams: z.object({
      unitId: z.string(),
    }),
    responses: {
      200: z.array(ContactGroupSchema),
    },
    summary: "Get all contact groups associated to a unit",
  },
});

export type ContactGroup = z.infer<typeof ContactGroupSchema>;
