import { ActivityFormSchema } from "@ses-mams/validation";
import { UnitField } from "../../shared";
import { useFormContext } from "react-hook-form";
import { Stack } from "~/components/ui/stack";
import { EventForm } from "~/components/common/eventForm";
import { Divider } from "~/components/ui/divider";
import { Button } from "~/components/ui/button";
import { Heading } from "~/components/ui/heading";

type Props = {
  isEditing: boolean;
  onSubmit: (formData: ActivityFormSchema) => void;
  children?: React.ReactNode;
};

export const ActivityForm = ({ isEditing, onSubmit, children }: Props) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext<ActivityFormSchema>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="xlarge">
        <Stack direction="row" justify="space-between" paddingBottom="xlarge">
          <Heading level="1">{isEditing ? "Edit" : "New"} Activity</Heading>
          <Button type="submit" busy={isSubmitting}>
            Done
          </Button>
        </Stack>
        <Divider />
        <UnitField label="Activity for Unit" disabled={isEditing} />
        <EventForm type="Activity" />
        {children}
      </Stack>
    </form>
  );
};
