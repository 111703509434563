import { useCallback, useMemo, useState } from "react";

import { Suggestion } from "@ses-mams/api-contract";
import {
  formatSuggestionsToSearchFilters,
  getTotalCountFromPages,
  useDebouncedSearch,
  useLatest,
  useUpdateEffect,
} from "@ses-mams/react-utils";

import { tsr } from "~/utils/client";
import { DEFAULT_PAGE_SIZE, getNextPageParam } from "~/utils/pagination";

export type RequiredAvailabilityWindow = {
  start: string;
  end: string;
};

type UseMembersListSearchParams = {
  defaultFilterSuggestions?: Suggestion[];
  requiredAvailabilityWindow?: RequiredAvailabilityWindow;
  currentAvailabilityStatusTime?: string;
  exclude?: {
    memberIds?: Array<string>;
    channelId?: string;
    activationId?: string;
    outOfAreaActivationId?: string;
    activityId?: string;
    activityScheduleId?: string;
  };
};

export function useMembersListSearch({
  defaultFilterSuggestions = [],
  exclude,
  requiredAvailabilityWindow,
  currentAvailabilityStatusTime,
}: UseMembersListSearchParams) {
  const [query, setQuery] = useState("");

  const [searchSuggestions, setSearchSuggestions] = useState<Suggestion[]>(
    defaultFilterSuggestions
  );
  const [isEditingSearchValue, setIsEditingSearchValue] = useState(false);

  const { searchValue, setSearchValue, debouncedSearchValue } =
    useDebouncedSearch("");

  const searchParams = useMemo(
    () => ({ searchSuggestions, searchValue: debouncedSearchValue }),
    [searchSuggestions, debouncedSearchValue]
  );

  // NOTE: using JSON.stringify for the deps array to avoid an infinite loop in case
  // the consumer of this hook passes a new reference for defaultFilterSuggestions on
  // every render. All we care about is whether or not the array values themselves
  // have changed, which might happen when navigating back and forth between routes
  // that render member lists for different units/groups
  useUpdateEffect(() => {
    setSearchSuggestions(defaultFilterSuggestions);
  }, [JSON.stringify(defaultFilterSuggestions)]);

  const clearSearchValues = useCallback(() => {
    setSearchValue("");
    setQuery("");
    setSearchSuggestions([]);
    setIsEditingSearchValue(false);
  }, []);

  const handleSetSearchSuggestions = useCallback(
    (suggestions: Suggestion[]) => {
      setSearchSuggestions(suggestions);
      setIsEditingSearchValue(false);
    },
    []
  );

  // Ensure we always have the value during the handleSearchInputKeyUp
  const latestSearchValue = useLatest(searchValue);

  const handleSearchInputKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        return;
      }

      if (e.key === "Backspace" && latestSearchValue.current === "") {
        setQuery("");
        setSearchSuggestions(suggestions => suggestions.slice(0, -1));
        setIsEditingSearchValue(false);
      } else {
        setIsEditingSearchValue(true);
      }
    },
    []
  );

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      // Prevent unintentionally submitting a form
      e.stopPropagation();
      setQuery(searchValue);
      setIsEditingSearchValue(false);
    },
    [searchValue]
  );

  const {
    capabilityIds,
    unitIds,
    clusterIds,
    zoneIds,
    groupIds,
    contactGroupIds,
  } = useMemo(
    () => formatSuggestionsToSearchFilters(searchSuggestions),
    [searchSuggestions]
  );

  const {
    data: memberListData,
    isLoading,
    isRefetching,
    refetch,
    hasNextPage,
    fetchNextPage,
  } = tsr.members.search.useInfiniteQuery({
    queryKey: [
      "members-search",
      query,
      capabilityIds,
      unitIds,
      clusterIds,
      zoneIds,
      groupIds,
      contactGroupIds,
      currentAvailabilityStatusTime,
      requiredAvailabilityWindow?.start,
      requiredAvailabilityWindow?.end,
    ],
    queryData: ({ pageParam = { skip: 0, take: DEFAULT_PAGE_SIZE } }) => ({
      query: {
        query,
        skip: pageParam.skip,
        take: pageParam.take,
        capabilityIds,
        unitIds,
        clusterIds,
        zoneIds,
        exclude,
        groupIds,
        contactGroupIds,
        currentAvailabilityStatusTime: currentAvailabilityStatusTime
          ? currentAvailabilityStatusTime
          : undefined,
        requiredAvailabilityStart: requiredAvailabilityWindow?.start,
        requiredAvailabilityEnd: requiredAvailabilityWindow?.end,
      },
    }),
    initialPageParam: { skip: 0, take: DEFAULT_PAGE_SIZE },
    getNextPageParam,
  });

  const { data: suggestionsData, isFetching: isFetchingSuggestions } =
    tsr.members.listSuggestions.useQuery({
      queryKey: ["members-suggestions", debouncedSearchValue, capabilityIds],
      queryData: {
        query: {
          query: debouncedSearchValue,
          excludeCapabilityIds: capabilityIds,
        },
      },
      enabled: Boolean(debouncedSearchValue),
    });

  const items = (memberListData?.pages || []).flatMap(page =>
    page.status === 200 ? page.body.items : []
  );

  const totalCount = getTotalCountFromPages(memberListData?.pages);

  return {
    suggestions: suggestionsData?.body ?? [],
    searchSuggestions,
    setSearchSuggestions: handleSetSearchSuggestions,
    searchValue,
    searchParams,
    setSearchValue,
    latestSearchValue,
    clearSearchValues,
    handleSearchInputKeyUp,
    isEditingSearchValue,
    isLoading,
    isRefetching,
    hasNextPage,
    data: items,
    totalCount,
    refetch,
    fetchNextPage,
    handleSubmit,
    shouldShowList: !isEditingSearchValue && !isFetchingSuggestions,
  };
}
