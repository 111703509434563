import { startOfMinute } from "date-fns";
import { useCallback, useRef } from "react";
import { combineDateTimeValues } from "@ses-mams/react-utils";
import { FormControl } from "@mui/material";
import { isValid24HourTime } from "@ses-mams/validation";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { TimeField } from "~/components/ui/timeField";
import { HelperText, Label } from "~/components/ui/textField";
import { DateTimePicker } from "~/components/ui/dateTimePicker";
import { useId } from "~/hooks/useId";

type DateTimeValue = {
  date?: Date;
  time?: string;
};

type DateTimeFieldProps = {
  value?: DateTimeValue;
  onValueChange: (value?: DateTimeValue) => void;
  onBlur?: () => void;
  errorMessage?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  label: string;
};

export const DateTimeField = ({
  errorMessage,
  disabled,
  hideLabel,
  label,
  value,
  onValueChange,
  onBlur,
}: DateTimeFieldProps) => {
  const id = useId();

  const internalTimeFieldRef = useRef<HTMLInputElement>(null);

  const handleDateValueChange = useCallback(
    (v: Date | null) => {
      const { time } = value ?? {};

      if (v && time && isValid24HourTime(time)) {
        const next = startOfMinute(
          combineDateTimeValues({ date: v, time: time })
        );
        onValueChange({ date: next, time });
      } else {
        onValueChange({ date: v ?? undefined, time });
      }

      // Only blur the field if a date was selected
      if (!v) {
        return;
      }

      onBlur?.();

      // Not sure why a timeout is needed, but without it the TimeField blurs immediately after
      setTimeout(() => {
        internalTimeFieldRef.current?.focus();
      }, 400);
    },
    [onValueChange, onBlur, value]
  );

  const handleTimeValueChange = useCallback(
    (v: string) => {
      const { date } = value ?? {};

      if (date && isValid24HourTime(v)) {
        const next = startOfMinute(combineDateTimeValues({ date, time: v }));
        onValueChange({ date: next, time: v });
      } else {
        onValueChange({ date, time: v });
      }
    },
    [onValueChange, value]
  );

  return (
    <FormControl variant="standard" fullWidth>
      <Label id={id} disabled={disabled} hideLabel={hideLabel}>
        {label}
      </Label>
      <Stack marginTop="xlarge" direction="row" gap="medium" align="center">
        <DateTimePicker
          aria-labelledby={id}
          value={value?.date ?? null}
          onChange={handleDateValueChange}
          disabled={disabled}
          format="MMM d, yyyy"
          mode="date"
          slotProps={{
            textField: {
              fullWidth: true,
            },
          }}
        />
        <Text size="large">at</Text>
        <TimeField
          ref={internalTimeFieldRef}
          aria-labelledby={id}
          value={value?.time ?? ""}
          onChange={handleTimeValueChange}
          onBlur={onBlur}
          disabled={disabled}
          label="Time"
          hideLabel
        />
      </Stack>

      {errorMessage && <HelperText role="alert">{errorMessage}</HelperText>}
    </FormControl>
  );
};
