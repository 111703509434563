import { tsr } from "~/utils/client";

export const useOutOfAreaActivationRequestDetails = (activationId: string) => {
  return tsr.outOfAreaActivationRequests.get.useQuery({
    queryKey: ["out-of-area-activation-requests", "details", { activationId }],
    queryData: {
      params: {
        activationId,
      },
    },
  });
};
