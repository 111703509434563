import { zodResolver } from "@hookform/resolvers/zod";
import { captureException } from "@sentry/react";
import { OutOfAreaActivationAvailabilityStatus } from "@ses-mams/api-contract";
import { formatDateForServer, useUpdateEffect } from "@ses-mams/react-utils";
import {
  OutOfAreaActivationApprovalFormSchema,
  outOfAreaActivationApprovalFormSchema,
} from "@ses-mams/validation";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "~/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerProps,
} from "~/components/ui/drawer";
import { TextField } from "~/components/ui/textField";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";

type ApprovalParams = {
  requestIds: Array<string>;
  roleIds?: Array<string>;
  availabilityBlocks?: Array<{
    start: Date;
    end: Date;
    availabilityStatus: OutOfAreaActivationAvailabilityStatus;
    conditionalReason?: string;
  }>;
};

type ApproveCommentsDrawerProps = Omit<DrawerProps, "children"> &
  ApprovalParams & {
    onSuccess?: () => void;
  };

export const ApproveCommentsDrawer = ({
  requestIds,
  roleIds,
  availabilityBlocks,
  open,
  onClose,
  onSuccess,
}: ApproveCommentsDrawerProps) => {
  const { formState, register, reset, handleSubmit } =
    useForm<OutOfAreaActivationApprovalFormSchema>({
      resolver: zodResolver(outOfAreaActivationApprovalFormSchema),
      mode: "onBlur",
    });

  useUpdateEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  const { approve, isPending } = useApproveApprovals({
    requestIds,
    roleIds,
    availabilityBlocks,
    onSuccess: useCallback(() => {
      onClose();
      onSuccess?.();
    }, [onSuccess, onClose]),
  });

  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerHeader onClose={onClose}>Approval Comment (optional)</DrawerHeader>
      <form
        onSubmit={handleSubmit(approve)}
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <DrawerContent>
          <TextField
            {...register("comments")}
            label="Comments"
            hideLabel
            multiline
            rows={4}
            placeholder="Enter comments"
            errorMessage={formState.errors?.comments?.message}
          />
        </DrawerContent>

        <DrawerFooter>
          <Button type="submit" fullWidth busy={isPending}>
            Done
          </Button>
        </DrawerFooter>
      </form>
    </Drawer>
  );
};

type Params = ApprovalParams & {
  onSuccess: () => void;
};

const useApproveApprovals = ({
  requestIds,
  roleIds,
  availabilityBlocks,
  onSuccess,
}: Params) => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } =
    tsr.outOfAreaActivationApprovals.approve.useMutation();

  const approve = useCallback(
    async ({ comments }: OutOfAreaActivationApprovalFormSchema) => {
      try {
        await mutateAsync({
          body: {
            comments,
            requestIds,
            overrideRoleIds: roleIds
              ? roleIds.filter(id => id.trim().length > 0)
              : undefined,
            overrideAvailabilityBlocks: availabilityBlocks
              ? availabilityBlocks.map(block => ({
                  ...block,
                  start: formatDateForServer(block.start),
                  end: formatDateForServer(block.end),
                }))
              : undefined,
          },
        });

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ["out-of-area-activation-approvals-v2"],
          }),
          queryClient.invalidateQueries({
            queryKey: ["out-of-area-activation-requests"],
          }),
        ]);

        navigate("/out-of-area-activation-approvals");

        onSuccess();
      } catch (error) {
        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    [requestIds, roleIds, availabilityBlocks, onSuccess]
  );

  return {
    isPending,
    approve,
  };
};
