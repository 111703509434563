import { createContext, useContext } from "react";
import { useJsApiLoader } from "@react-google-maps/api";

const GOOGLE_PLACES_API_KEY = import.meta.env.VITE_GOOGLE_PLACES_API_KEY;
const libraries = ["places" as const];

type GoogleMapsContextType = {
  isGoogleMapsApiLoaded: boolean;
};

const GoogleMapsContext = createContext<GoogleMapsContextType>({
  isGoogleMapsApiLoaded: false,
});

export const GoogleMapsContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const { isLoaded: isGoogleMapsApiLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_PLACES_API_KEY,
    libraries,
  });

  return (
    <GoogleMapsContext.Provider value={{ isGoogleMapsApiLoaded }}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

export const useGoogleMaps = () => {
  const ctx = useContext(GoogleMapsContext);

  if (!ctx) {
    throw new Error("Must be called within <GoogleMapsContextProvider />");
  }

  return ctx;
};
