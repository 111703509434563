import { useCallback } from "react";

import { Group, Member, Suggestion, Unit } from "@ses-mams/api-contract";

import { Stack } from "~/components/ui/stack";

import { useMembersListSearch } from "./hooks";
import { MembersList } from "./components/MembersList";
import { SearchMemberInput } from "./components/SearchMemberInput";
import { ShowMemberCapabilitiesToggle } from "../showMemberCapabilitiesToggle";
import { MemberListItemDetailsButton } from "./components/MemberListItemDetailsButton";

type SearchMembersListProps = {
  renderItem?: (item: Member) => React.ReactElement;
  unit?: Unit;
  group?: Group;
};

export const SearchMembersList = ({
  renderItem: renderItemProp,
  unit,
  group,
}: SearchMembersListProps) => {
  const {
    searchSuggestions,
    setSearchSuggestions,
    searchValue,
    searchParams,
    suggestions,
    setSearchValue,
    handleSearchInputKeyUp,
    isLoading,
    isRefetching,
    data,
    hasNextPage,
    fetchNextPage,
    handleSubmit,
    isEditingSearchValue,
    shouldShowList,
  } = useMembersListSearch({
    defaultFilterSuggestions: [
      ...(unit ? [{ id: unit.id, name: unit.name, type: "unit" }] : []),
      ...(group ? [{ id: group.id, name: group.name, type: "group" }] : []),
    ] as Suggestion[],
  });

  const renderItem = useCallback(
    (item: Member) => {
      if (renderItemProp) {
        return renderItemProp(item);
      }

      return <MemberListItemDetailsButton member={item} />;
    },
    [renderItemProp]
  );

  return (
    <Stack direction="column" gap="large" sx={{ height: "100%" }}>
      <SearchMemberInput
        onKeyUp={handleSearchInputKeyUp}
        searchSuggestions={searchSuggestions}
        searchValue={searchValue}
        setSearchSuggestions={setSearchSuggestions}
        setSearchValue={setSearchValue}
        suggestions={suggestions}
        isEditingSearchValue={isEditingSearchValue}
        onSubmit={handleSubmit}
      />
      {shouldShowList && (
        <>
          <Stack align="start" gap="large">
            <ShowMemberCapabilitiesToggle />
          </Stack>
          <MembersList
            data={data}
            searchParams={searchParams}
            isLoading={isLoading}
            isRefetching={isRefetching}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            renderItem={renderItem}
          />
        </>
      )}
    </Stack>
  );
};
