import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { ApiErrorSchema } from "./common";

const c = initContract();

export const BroadcastAcknowledgementSchema = z.object({
  id: z.string(),
  memberId: z.string(),
  broadcastId: z.string(),
});

export const broadcastAcknowledgementsContract = c.router({
  acknowledge: {
    method: "PATCH",
    path: "/broadcasts/:broadcastId/acknowlegements/acknowledge",
    pathParams: z.object({
      broadcastId: z.string(),
    }),
    body: null,
    responses: {
      200: BroadcastAcknowledgementSchema,
      400: ApiErrorSchema,
      403: ApiErrorSchema,
      404: ApiErrorSchema,
    },
    summary: "Member acknowledges a broadcast message",
  },
});
