import { BorderTones } from "@ses-mams/react-utils";
import { Box } from "~/components/ui/box";
import { CheckCircleFilledIcon } from "~/components/ui/icon";

type CellIndicatorProps = {
  disabled?: boolean;
  selected?: boolean;
  tone?: BorderTones;
};

export const CellIndicator = ({
  disabled,
  selected,
  tone,
}: CellIndicatorProps) => {
  if (disabled) {
    return null;
  }

  if (selected) {
    return <CheckCircleFilledIcon tone="action" />;
  }

  return (
    <Box
      borderWidth="large"
      borderRadius="full"
      border={tone}
      sx={{ height: 20, width: 20 }}
    />
  );
};
