import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AvailabilityStatus } from "@ses-mams/api-contract";
import { useUpdateEffect } from "@ses-mams/react-utils";
import {
  ConditionalReasonFormSchema,
  conditionalReasonFormSchema,
} from "@ses-mams/validation";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/components/ui/dialog";
import { TextField } from "~/components/ui/textField";
import { DrawerProps } from "~/components/ui/drawer";
import { Button } from "~/components/ui/button";
import { AvailabilityStatusButton } from "./AvailabilityStatusButton";

const DEFAULT_AVAILABILITY_STATUSES: Array<AvailabilityStatus> = [
  "ImmediatelyAvailable",
  "Available",
  "Conditional",
  "Unavailable",
];

type ArrayContent<T> = T extends Array<infer R> ? R : T;

type AvailabilityStatusResponseButtonsProps<
  T extends Array<AvailabilityStatus>,
> = {
  onStatusSelected: (
    status: ArrayContent<T>,
    conditionalReason?: string
  ) => Promise<void>;
  options?: T;
};

export const AvailabilityStatusResponseButtons = <
  T extends Array<AvailabilityStatus>,
>({
  onStatusSelected,
  options: _options,
}: AvailabilityStatusResponseButtonsProps<T>) => {
  const options = _options ?? DEFAULT_AVAILABILITY_STATUSES;

  const [isConditionalReasonDialogOpen, setIsConditionalReasonDialogOpen] =
    useState(false);

  const updateAvailabilityStatus = useCallback(
    async (status: AvailabilityStatus, conditionalReason?: string) => {
      await onStatusSelected(status as ArrayContent<T>, conditionalReason);
      setIsConditionalReasonDialogOpen(false);
    },
    [onStatusSelected]
  );

  return (
    <>
      {options.map(option => (
        <AvailabilityStatusButton
          status={option}
          key={option}
          onClick={() => {
            if (option === "Conditional") {
              setIsConditionalReasonDialogOpen(true);
              return;
            }

            updateAvailabilityStatus(option);
          }}
        />
      ))}

      <ConditionalReasonDialog
        open={isConditionalReasonDialogOpen}
        onClose={() => setIsConditionalReasonDialogOpen(false)}
        updateAvailabilityStatus={updateAvailabilityStatus}
      />
    </>
  );
};

const ConditionalReasonDialog = ({
  open,
  onClose,
  updateAvailabilityStatus,
}: Pick<DrawerProps, "open" | "onClose"> & {
  updateAvailabilityStatus: (
    status: AvailabilityStatus,
    conditionalReason?: string
  ) => void;
}) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<ConditionalReasonFormSchema>({
    resolver: zodResolver(conditionalReasonFormSchema),
    mode: "onBlur",
  });

  const onSubmit = useCallback(
    (formData: ConditionalReasonFormSchema) => {
      // We can only enter this dialog if the status is Conditional
      updateAvailabilityStatus("Conditional", formData.conditionalReason);
    },
    [updateAvailabilityStatus]
  );

  useUpdateEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader onClose={onClose}>Enter conditional reason</DialogHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextField
            {...register("conditionalReason")}
            label="Conditional reason"
            errorMessage={errors.conditionalReason?.message}
          />
        </DialogContent>
        <DialogFooter>
          <Button type="submit">Done</Button>
        </DialogFooter>
      </form>
    </Dialog>
  );
};
