import { FormProvider, useForm } from "react-hook-form";
import { OutOfAreaActivationForm } from "./OutOfAreaActivationForm";
import {
  OutOfAreaActivationFormSchema,
  outOfAreaFormSchema,
} from "@ses-mams/validation";
import { zodResolver } from "@hookform/resolvers/zod";

export const AddOutOfAreaActivationForm = () => {
  const formMethods = useForm<OutOfAreaActivationFormSchema>({
    mode: "onChange",
    resolver: zodResolver(outOfAreaFormSchema),
  });

  return (
    <FormProvider {...formMethods}>
      <OutOfAreaActivationForm />
    </FormProvider>
  );
};
