import { ContactGroup } from "@ses-mams/api-contract";

import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

type ContactGroupListItemProps = {
  contactGroup: ContactGroup;
  startAdornment?: React.ReactNode;
};

export const ContactGroupListItem = ({
  contactGroup,
  startAdornment,
}: ContactGroupListItemProps) => {
  return (
    <Stack flex={1} direction="row" justify="space-between" align="center">
      <Stack direction="row" gap="medium" paddingY="medium" align="center">
        {startAdornment}

        <Text size="large" weight="medium">
          {contactGroup.name}
        </Text>
      </Stack>

      {/* TODO: link to contact group members */}
    </Stack>
  );
};
