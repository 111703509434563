import { Suggestion } from "@ses-mams/api-contract";

import { Chip } from "~/components/ui/chip";
import { Text } from "~/components/ui/text";

type Props = {
  suggestion: Suggestion;
};

export const SuggestionChip = ({ suggestion }: Props) => (
  <Chip background="info">
    <Text tone="inverted">{suggestion.name}</Text>
  </Chip>
);
