import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * Return a single requestId if there is only 1 in the param array, otherwise return undefined.
 */
export const useMaybeRequestIdSearchParam = () => {
  const [searchParams] = useSearchParams();

  const requestIds = searchParams.get("requestIds") as string;

  return useMemo(() => {
    const requestIdArray = requestIds.split(",");

    if (requestIdArray.length > 1) {
      return undefined;
    }

    return requestIdArray[0];
  }, [requestIds]);
};
