// this doesn't *really* belong in a validation package, but its the only package we have at the moment shared between web and cloud

export enum AuditActions {
  ActivityAmended = "Activity amended",
  ActivityCreated = "Activity created",
  ActivityDeleted = "Activity deleted",
  MemberActivatedOnOperationalRequest = "Member activated on Operational Request",
  OperationalRequestCreated = "Operational Request created",
  OperationalRequestAmended = "Operational Request amended",
  OperationalRequestClosed = "Operational Request closed",
  MemberRespondedToAvailabilityRequestForAnOperationalRequest = "Member responded to availability request for an Operational Request",
  MembersCommittedToAnActivity = "Members committed to an Activity",
  MemberAvailabilityUpdatedByAnotherMember = "Member Availability updated by another member",
  MemberAvailabilityUpdatedBySelf = "Member Availability updated by self",
  MemberDeactivatedOnOperationalRequest = "Member deactivated on Operational Request",
  MemberRespondedToActivityRequest = "Member responded to a commitment request", // Note: "commitment request" is terminology requested by SES, however it is actually referring to an Activity request.
  MemberRemovedFromActivityRequest = "Member removed from commitment request", // Note: "commitment request" is terminology requested by SES, however it is actually referring to an Activity request.
  MembersUncommittedToAnActivity = "Members uncommitted to an Activity",
  GroupCreated = "Group created",
  GroupAmended = "Group amended",
  GroupDeleted = "Group deleted",
  GroupSortSequenceModified = "Sort Sequence modified",
  GroupAvailabilityBelowThreshold = "Availability level is below threshold",
  GroupAvailabilityRestored = "Availability level is restored",
  MessageChannelCreated = "Message channel created",
  MessageChannelArchived = "Message channel archived",
  MemberAddedToMessageChannel = "Member added to Message channel",
  MemberDeletedMessageFromMessageChannel = "Member deleted message from Message channel",
  MemberRemovedFromMessageChannel = "Member removed from Message channel",
  MemberTrackingOptIn = "Member opted-in for GPS tracking",
  MemberTrackingOptOut = "Member opted-out for GPS tracking",
  MemberLoggedOutRemotely = "Member logged out remotely",
  MemberPermissionsUpdated = "Member permissions updated",
  OoaOperationalRequestCreated = "OOAA Operational Request created",
  OoaOperationalRequestAmended = "OOAA Operational Request amended",
  OoaOperationalRequestClosed = "OOAA Operational Request closed",
  MemberRespondedToOoaOperationalRequest = "Member responded to an OOAA Operational Request",
  MemberAvailabilityForAnOoaOperationalRequestAmended = "Member availability for an OOAA Operational Request amended",
  MemberActivatedOnOoaOperationalRequest = "Member activated on OOAA Operational Request",
  MemberDeactivatedOnOoaOperationalRequest = "Member deactivated on OOAA Operational Request",
  MemberWithdrawnFromOoaOperationalRequest = "Member self withdrawn from OOA Operational Request",
  UnitApprovalOnOoaRequestMembers = "Unit Approval on OOAA Operational Request",
  ClusterApprovalOnOoaRequestMembers = "Cluster Approval on OOAA Operational Request",
  ZoneApprovalOnOoaRequestMembers = "Zone Approval on OOAA Operational Request",
  UnitApprovalDeclinedOnOoaRequestMembers = "Unit Approval declined on OOAA Operational Request",
  ClusterApprovalDeclinedOnOoaRequestMembers = "Cluster Approval declined on OOAA Operational Request",
  ZoneApprovalDeclinedOnOoaRequestMembers = "Zone Approval declined on OOAA Operational Request",
  UnitApprovalUndoneOnOoaRequestMember = "Unit approval/decline undone on OOAA Operational Request",
  ClusterApprovalUndoneOnOoaRequestMember = "Cluster approval/decline undone on OOAA Operational Request",
  ZoneApprovalUndoneOnOoaRequestMember = "Zone approval/decline undone on OOAA Operational Request",
  MembersRemovedFromOoaOperationalRequest = "Members removed from OOAA Operational Request",
  PreApprovedMembersActivatedToOoaOperationalRequest = "Pre-Approved member activated to OOAA Operational Request",
  RecurringActivityCreated = "Recurring Activity created",
  RecurringActivityAmended = "Recurring Activity amended",
  RecurringActivityArchived = "Recurring Activity archived",
  MembersRemovedFromRecurringActivity = "Members removed from Recurring Activity",
  MemberLoggedIn = "Member logged in",
  MemberLoggedOut = "Member self-logged out",
  OperationalRequestBulkClosure = "Operational Request Bulk Closure",
  OoaOperationalRequestBulkClosure = "OOAA Operational Request Bulk Closure",
  ActivityRequestBulkClosure = "Activity Request Bulk Closure",
  CreateBroadcastMessage = "Create a Broadcast Message",
  SaveDraftBroadcastMessage = "Save Draft Broadcast Message",
  EditDraftBroadcastMessage = "Edit Draft Broadcast Message",
  DeleteDraftBroadcastMessage = "Delete Draft Broadcast Message",
  BroadcastDraftMessage = "Broadcast Draft Message",
  EndBroadcastMessage = "End Broadcast Message",
}
