import { useParams } from "react-router-dom";
import { BroadcastDetailsPage } from "./BroadcastDetailsPage";
import { tsr } from "~/utils/client";
import { Stack } from "~/components/ui/stack";
import { Spinner } from "~/components/ui/spinner";

export const ViewBroadcastPage = () => {
  const { broadcastId } = useParams();

  const { data, isLoading } = tsr.broadcasts.get.useQuery({
    queryKey: ["broadcasts", broadcastId],
    queryData: {
      params: {
        id: broadcastId as string,
      },
    },
  });

  return isLoading ? (
    <Stack align="center">
      <Spinner />
    </Stack>
  ) : !data ? null : (
    <BroadcastDetailsPage existingBroadcast={data.body} />
  );
};
