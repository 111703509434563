import { useCallback } from "react";

import { useShowMemberCapabilitiesContext } from "@ses-mams/react-utils";

import { CheckboxField } from "~/components/ui/checkbox";

export const ShowMemberCapabilitiesToggle = () => {
  const { showMemberCapabilities, setShowMemberCapabilities } =
    useShowMemberCapabilitiesContext();

  const handleClick = useCallback(() => {
    setShowMemberCapabilities(
      prevShowMemberCapabilities => !prevShowMemberCapabilities
    );
  }, [setShowMemberCapabilities]);

  return (
    <CheckboxField
      label="Show Member Capabilities"
      checked={showMemberCapabilities}
      onClick={handleClick}
    />
  );
};
