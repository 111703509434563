import { initContract } from "@ts-rest/core";

const c = initContract();

export const healthContract = c.router({
  get: {
    method: "GET",
    path: "/health",
    responses: {
      200: null,
    },
    summary: "Health check",
  },
});
