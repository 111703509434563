import { CircularProgress } from "@mui/material";
import React from "react";

import { Box } from "~/components/ui/box";
import { InfiniteScroll } from "~/components/ui/infiniteScroll";
import { Text } from "~/components/ui/text";
import { RenderSectionHeaderParams, SectionList } from "./SectionList";

type InfiniteSectionListProps<T> = {
  sections: { title: string; data: T[] }[];
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  renderItem: (item: T) => React.ReactElement | null;
  renderSectionHeader?: (
    params: RenderSectionHeaderParams<T>
  ) => React.ReactElement | null;
  keyExtractor: (item: T, index: number) => string | number;
  listItemDivider?: boolean;
  listEmptyElement?: React.ReactNode;
};

export const InfiniteSectionList = <T,>({
  sections,
  fetchNextPage,
  hasNextPage = false,
  renderItem,
  renderSectionHeader,
  keyExtractor,
  listItemDivider = true,
  listEmptyElement,
}: InfiniteSectionListProps<T>) => (
  <InfiniteScroll
    loadMore={fetchNextPage}
    hasMore={hasNextPage}
    loader={
      <Box display="flex" align="center" justify="center">
        <CircularProgress />
      </Box>
    }
    endMessage={
      hasNextPage ? undefined : (
        <Text as="p" align="center" tone="secondary">
          No more results
        </Text>
      )
    }
  >
    <SectionList
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      sections={sections}
      listItemDivider={listItemDivider}
      listEmptyElement={listEmptyElement}
      renderSectionHeader={renderSectionHeader}
    />
  </InfiniteScroll>
);
