import { IconButton } from "@mui/material";
import { Divider } from "~/components/ui/divider";
import { Stack } from "~/components/ui/stack";
import { XOutlineIcon } from "~/components/ui/icon";
import { Heading } from "~/components/ui/heading";

type DrawerHeaderProps = {
  onClose: () => void;
  children: React.ReactNode;
};

export const DrawerHeader = ({ children, onClose }: DrawerHeaderProps) => {
  return (
    <Stack gap="small" paddingX="large" paddingTop="small">
      <Stack direction="row" justify="space-between" align="center">
        <Heading level="3">{children}</Heading>

        <IconButton aria-label="Close" onClick={onClose}>
          <XOutlineIcon />
        </IconButton>
      </Stack>

      <Divider direction="column" />
    </Stack>
  );
};
