import { OutOfAreaActivationRequestStatus } from "@ses-mams/api-contract";
import { useMemo } from "react";

export const useOutOfAreaActivationMemberListSections = <
  T extends { request: { status: OutOfAreaActivationRequestStatus } },
>(
  items?: Array<T>
) => {
  return useMemo(() => {
    if (!items) {
      return [];
    }

    const dataMap: Record<OutOfAreaActivationMembersSectionKeys, Array<T>> = {
      Activated: [],
      "Supported-Recommended for Activation": [],
      Rejected: [],
      "Pending Zone Approval": [],
      "Pending Cluster Approval": [],
      "Pending Unit Approval": [],
      "Awaiting Response": [],
      Deactivated: [],
    };

    items.forEach(item => {
      const status = item.request.status;
      switch (status) {
        case "Activated":
          dataMap.Activated.push(item);
          break;
        case "ActivationPending":
          dataMap["Supported-Recommended for Activation"].push(item);
          break;
        case "ZoneApprovalPending":
          dataMap["Pending Zone Approval"].push(item);
          break;
        case "ClusterApprovalPending":
          dataMap["Pending Cluster Approval"].push(item);
          break;
        case "UnitApprovalPending":
          dataMap["Pending Unit Approval"].push(item);
          break;
        case "AvailabilityRequested":
          dataMap["Awaiting Response"].push(item);
          break;
        case "ZoneApprovalDeclined":
        case "ClusterApprovalDeclined":
        case "UnitApprovalDeclined":
          dataMap.Rejected.push(item);
          break;
        case "Deactivated":
        default:
          dataMap.Deactivated.push(item);
          break;
      }
    });

    return Object.keys(dataMap).flatMap(key => {
      const typedKey = key as OutOfAreaActivationMembersSectionKeys;
      const sectionData = dataMap[typedKey];

      if (!sectionData.length) {
        return [];
      }

      return [
        {
          title: typedKey,
          data: sectionData,
        },
      ];
    });
  }, [items]);
};

export type OutOfAreaActivationMembersSectionKeys =
  | "Activated"
  | "Supported-Recommended for Activation"
  | "Pending Zone Approval"
  | "Pending Cluster Approval"
  | "Pending Unit Approval"
  | "Awaiting Response"
  | "Rejected"
  | "Deactivated";
