import { Button } from "~/components/ui/button";
import { useCallback } from "react";
import { tsr } from "~/utils/client";

type Props = {
  activationId: string;
  status: "approved" | "activated";
};

export const DownloadMembersCsvButton = ({ activationId, status }: Props) => {
  const { mutateAsync: downloadCsv, isPending } =
    tsr.outOfAreaActivationRequests.downloadMembersCsv.useMutation();

  const handleDownload = useCallback(async () => {
    const {
      body: { downloadUrl },
    } = await downloadCsv({
      params: {
        activationId,
      },
      body: {
        status,
      },
    });

    window.location.href = downloadUrl;
  }, []);

  return (
    <Button variant="tertiary" onClick={handleDownload} busy={isPending}>
      Download {status === "approved" ? "Approved" : "Activated"} Members CSV
    </Button>
  );
};
