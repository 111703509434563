import { Box as MUIBox, BoxProps as MUIBoxProps } from "@mui/material";
import { forwardRef } from "react";

import { useBoxProps } from "./useBoxProps";
import { BoxStyleProps } from "./useBoxStyles";

type MUIBoxValidProps = Pick<MUIBoxProps, "children" | "display" | "sx">;

export type BoxProps = BoxStyleProps & MUIBoxValidProps;

export const Box = forwardRef<HTMLDivElement, BoxProps>(
  ({ sx = {}, ...props }, ref) => {
    const { boxProps, boxStyles } = useBoxProps<BoxProps>(props);

    return (
      <MUIBox
        ref={ref}
        sx={[boxStyles, ...(Array.isArray(sx) ? sx : [sx])]}
        {...boxProps}
      />
    );
  }
);
