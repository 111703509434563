import { Member } from "@ses-mams/api-contract";
import { useTransformPagedDataToItems } from "@ses-mams/react-utils";
import { ButtonBase, Container, styled } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useChatContext } from "stream-chat-react";
import { useParams } from "react-router-dom";
import { Heading } from "~/components/ui/heading";
import { InfiniteList } from "~/components/ui/list";
import { MemberListItem } from "~/components/common/searchMembersList";
import { useConfirmationDialog } from "~/components/ui/confirmationDialog";
import { RemoveCircleFilledIcon } from "~/components/ui/icon";
import { Spinner } from "~/components/ui/spinner";
import { Box } from "~/components/ui/box";
import { Stack } from "~/components/ui/stack";
import { Card } from "~/components/ui/card";
import { Button } from "~/components/ui/button";
import { BackButton } from "~/components/common/navigation";
import { MemberDetailsDrawer } from "~/components/common/memberDetails";
import { ChannelMuteNotifications } from "./components/MuteChannelNotifications";
import { useChannelMembers } from "./hooks/useChannelMembers";
import { useChannelMutations } from "./hooks/useChannelMutations";

export const ChannelDetailsPage = () => {
  const channelId = useParams().channelId as string;

  const { channel, setActiveChannel, client } = useChatContext();

  const [memberDetailsId, setMemberDetailsId] = useState<string>();

  useEffect(() => {
    if (channelId !== channel?.id) {
      (async () => {
        const [c] = await client.queryChannels({ id: channelId });

        setActiveChannel(c);
      })();
    }
  }, [channel, channelId]);

  const { showConfirmationDialog } = useConfirmationDialog();

  const {
    capabilities,
    canUpdateMembers,
    leaveChannel,
    deleteChannel,
    removeMember,
    isRemovingMember,
  } = useChannelMutations();

  const { data, hasNextPage, fetchNextPage } = useChannelMembers(channelId);

  const renderItem = useCallback(
    (item: Member) => {
      return (
        <Pressable role="button" onClick={() => setMemberDetailsId(item.id)}>
          <MemberListItem
            member={item}
            endAdornment={
              canUpdateMembers && (
                <ButtonBase
                  aria-label={`Remove ${item.firstName}`}
                  disabled={isRemovingMember}
                  onClick={e => {
                    e.stopPropagation();

                    showConfirmationDialog({
                      text: `Please confirm that you'd like to remove ${item.firstName} from this channel.`,
                      onConfirm: () => removeMember(item.id),
                    });
                  }}
                >
                  <RemoveCircleFilledIcon tone="critical" />
                </ButtonBase>
              )
            }
          />
        </Pressable>
      );
    },
    [isRemovingMember]
  );

  const members = useTransformPagedDataToItems(data);

  if (!channel) {
    return (
      <Box display="flex" justify="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <Container maxWidth="xs">
      <Stack gap="xlarge">
        <BackButton />

        <Heading>{channel.data?.name}</Heading>

        <Card gap="large" padding="large">
          {canUpdateMembers && (
            <Button variant="link" href="members/edit">
              Add Members
            </Button>
          )}

          <ChannelMuteNotifications channelId={channelId} />

          {capabilities.canLeaveChannel && (
            <Button
              variant="destructive"
              onClick={() =>
                showConfirmationDialog({
                  text: "You will no longer be able to view or send messages in this channel.",
                  onConfirm: leaveChannel,
                })
              }
            >
              Leave Channel
            </Button>
          )}

          {capabilities.canDeleteChannel && (
            <Button
              variant="destructive"
              onClick={() =>
                showConfirmationDialog({
                  text: "All members will no longer be able to view or send messages in this channel.",
                  onConfirm: deleteChannel,
                })
              }
            >
              Archive Channel
            </Button>
          )}
        </Card>

        <InfiniteList
          data={members}
          renderItem={renderItem}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          keyExtractor={item => item.id}
        />
      </Stack>

      {memberDetailsId && (
        <MemberDetailsDrawer
          open={Boolean(memberDetailsId)}
          memberId={memberDetailsId}
          onClose={() => setMemberDetailsId(undefined)}
        />
      )}
    </Container>
  );
};

/**
 * Due to HTML semantics, we can't have a <button> nested inside another <button.
 * Therefore, use a a11y equivalent element to handle the click event
 */
const Pressable = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  cursor: "pointer",
  ":hover": {
    backgroundColor: theme.tokens.colors.background.surfaceMuted,
  },
  ":active": {
    backgroundColor: theme.tokens.colors.background.muted,
  },
}));
