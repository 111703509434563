import { tsr } from "~/utils/client";

export const useGroupsQuery = (unitId: string) => {
  const { data, isLoading } = tsr.units.listGroups.useQuery({
    queryKey: ["groups", { unitId }],
    queryData: {
      params: {
        unitId,
      },
    },
  });

  const groups = data?.body ?? [];

  return {
    isLoading,
    groups,
  };
};
