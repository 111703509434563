import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  ApiErrorSchema,
  MemberSummarySchema,
  PaginationQueryParamsSchema,
  ServerDateSchema,
} from "./common";
import {
  OutOfAreaActivationLevelSchema,
  OutOfAreaActivationSchema,
  OutOfAreaActivationSummarySchema,
  OutOfAreaActivationRequestStatusSchema,
  OutOfAreaActivationRequestSchema,
  OutOfAreaTypeSchema,
  OutOfAreaActivationAvailabilityStatusSchema,
  OutOfAreaActivationRequestAvailabilityBlockSchema,
} from "./outOfAreaActivation";
import { OutOfAreaActivationRoleSchema } from "./outOfAreaActivationRole";

const c = initContract();

export const OutOfAreaActivationAvailabilityInputSchema = z.object({
  start: ServerDateSchema,
  end: ServerDateSchema,
  availabilityStatus: OutOfAreaActivationAvailabilityStatusSchema,
  conditionalReason: z.string().optional(),
});

const OutOfAreaActivationRequestHistoryActionSchema = z.enum([
  "Applied",
  "Approved",
  "Declined",
  "Activated",
  "Deactivated",
  "Withdrawn",
]);

const OutOfAreaActivationRequestDetailsSchema = z.object({
  request: OutOfAreaActivationRequestSchema.nullable(),
  activation: OutOfAreaActivationSchema,
  canRespond: z.boolean(),
  canWithdraw: z.boolean(),
});

export const OutOfAreaActivationRequestHistoryLevelSchema = z.enum([
  "State",
  "Zone",
  "Cluster",
  "Unit",
]);

export const OutOfAreaActivationRequestHistorySchema = z.object({
  id: z.string(),
  createdAt: z.string().datetime(),
  action: OutOfAreaActivationRequestHistoryActionSchema,
  level: OutOfAreaActivationRequestHistoryLevelSchema.optional(),
  comments: z.string().optional(),
  roles: z.array(OutOfAreaActivationRoleSchema),
  availabilityBlocks: z.array(
    OutOfAreaActivationRequestAvailabilityBlockSchema
  ),
  commander: MemberSummarySchema.optional(),
});

const OutOfAreaActivationRequestHistoryDetailsSchema = z.object({
  history: z.array(OutOfAreaActivationRequestHistorySchema),
  request: OutOfAreaActivationRequestSchema,
});

const OutOfAreaActivationRequestWithStatusCountSchema = z.object({
  id: z.string().nullable(),
  activation: OutOfAreaActivationSummarySchema,
  status: OutOfAreaActivationRequestStatusSchema.nullable(),
  start: ServerDateSchema.nullable(),
  end: ServerDateSchema.nullable(),
});

export const outOfAreaActivationRequestContract = c.router({
  get: {
    method: "GET",
    path: "/out-of-area-activation-requests/:activationId",
    pathParams: z.object({
      activationId: z.string(),
    }),
    responses: {
      200: OutOfAreaActivationRequestDetailsSchema,
      404: ApiErrorSchema,
    },
    summary:
      "Get the details of an Out Of Area Activation. If the current member has been added to the activation, return the details of the corresponding OutOfAreaActivationRequest",
  },
  list: {
    method: "GET",
    path: "/out-of-area-activation-requests",
    query: PaginationQueryParamsSchema.extend({
      query: z.coerce.string().optional(),
      types: z.array(OutOfAreaTypeSchema).optional(),
      startDate: ServerDateSchema.optional(),
      endDate: ServerDateSchema.optional(),
      archived: z.boolean().optional(),
      level: OutOfAreaActivationLevelSchema.optional(),
      unitId: z.coerce.string().optional(),
      clusterId: z.coerce.string().optional(),
      zoneId: z.coerce.string().optional(),
    }),
    responses: {
      200: z.object({
        items: z.array(OutOfAreaActivationRequestWithStatusCountSchema),
      }),
    },
    summary: "Find/List out of area activation requests with status counts",
  },
  respond: {
    method: "POST",
    path: "/out-of-area-activation-requests/:requestId/respond",
    pathParams: z.object({
      requestId: z.string(),
    }),
    body: z.object({
      roleIds: z.array(z.string()).optional(),
      availabilityBlocks: z.array(OutOfAreaActivationAvailabilityInputSchema),
    }),
    responses: {
      200: OutOfAreaActivationRequestDetailsSchema,
      403: ApiErrorSchema,
    },
    summary: "Respond to an out of area activation request",
  },
  getHistoryDetails: {
    method: "GET",
    path: "/out-of-area-activation-requests/:requestId/history",
    pathParams: z.object({
      requestId: z.string(),
    }),
    responses: {
      200: OutOfAreaActivationRequestHistoryDetailsSchema,
      404: ApiErrorSchema,
    },
    summary: "Get the history details of an activation request.",
  },
  amend: {
    method: "POST",
    path: "/out-of-area-activation-requests/:requestId/amend",
    pathParams: z.object({
      requestId: z.string(),
    }),
    body: z.object({
      roleIds: z.array(z.string()).optional(),
      availabilityBlocks: z.array(OutOfAreaActivationAvailabilityInputSchema),
    }),
    responses: {
      200: OutOfAreaActivationRequestDetailsSchema,
      403: ApiErrorSchema,
    },
    summary:
      "Amend the availability selection of an out of area activation request.",
  },
  withdraw: {
    method: "POST",
    path: "/out-of-area-activation-requests/:requestId/withdraw",
    pathParams: z.object({
      requestId: z.string(),
    }),
    body: null,
    responses: {
      200: OutOfAreaActivationRequestDetailsSchema,
      403: ApiErrorSchema,
    },
  },
  downloadMembersCsv: {
    method: "POST",
    path: "/out-of-area-activation-requests/:activationId/downloadMembers",
    pathParams: z.object({
      activationId: z.string(),
    }),
    body: z.object({
      status: z.enum(["approved", "activated"]),
    }),
    responses: {
      200: z.object({
        downloadUrl: z.string(),
      }),
    },
  },
});

export type OutOfAreaActivationAvailabilityStatus = z.infer<
  typeof OutOfAreaActivationAvailabilityStatusSchema
>;

export type OutOfAreaActivationRequestWithStatusCount = z.infer<
  typeof OutOfAreaActivationRequestWithStatusCountSchema
>;

export type OutOfAreaActivationRequest = z.infer<
  typeof OutOfAreaActivationRequestSchema
>;

export type OutOfAreaActivationRequestDetails = z.infer<
  typeof OutOfAreaActivationRequestDetailsSchema
>;

export type OutOfAreaActivationRequestHistory = z.infer<
  typeof OutOfAreaActivationRequestHistorySchema
>;

export type OutOfAreaActivationRequestHistoryDetails = z.infer<
  typeof OutOfAreaActivationRequestHistoryDetailsSchema
>;
