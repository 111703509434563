import { createTheme } from "@mui/material";

import InterMediumWoff2 from "./fonts/Inter-Medium.woff2";
import InterRegularWoff2 from "./fonts/Inter-Regular.woff2";
import InterSemiBoldWoff2 from "./fonts/Inter-SemiBold.woff2";
import { tokens } from "./tokens";

declare module "@mui/material/styles" {
  interface Theme {
    tokens: typeof tokens;
  }

  interface ThemeOptions {
    tokens?: typeof tokens;
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: "Inter, system-ui, Avenir, Helvetica, Arial, sans-serif",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-display: swap;
          font-family: Inter;
          font-weight: 400;
          src: local('Inter-Regular'), url(${InterRegularWoff2}) format('woff2');
        }
        @font-face {
          font-display: swap;
          font-family: Inter;
          font-weight: 500;
          src: local('Inter-Medium'), url(${InterMediumWoff2}) format('woff2');
        }
        @font-face {
          font-display: swap;
          font-family: Inter;
          font-weight: 600;
          src: local('Inter-SemiBold'), url(${InterSemiBoldWoff2}) format('woff2');
        }
      `,
    },
  },
  spacing: 1,
  shape: {
    borderRadius: 1,
  },
  tokens,
});
