import { createContext, useContext } from "react";
import type { ToastProps } from "./ToastContextProvider";

export type ToastOptions = Omit<ToastProps, "onDismiss"> & {
  id?: string;
};

type ToastContextType = {
  addToast: (options: ToastOptions) => string | number;
  removeToast: (id: string | number) => void;
};

export const ToastContext = createContext<ToastContextType>({
  addToast: () => "",
  removeToast: () => null,
});

export const useToast = () => {
  const ctx = useContext(ToastContext);

  if (!ctx) {
    throw new Error("Must be called within <ToastProvider />");
  }

  return ctx;
};
