import { Row, Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { AvailabilityStatusButton, ChangeResponsePopover } from "../../shared";
import { useCallback, useState } from "react";
import { ActivationRequest, MemberStatus } from "@ses-mams/api-contract";
import { useMutateActivationStatus } from "../../hooks";
import { Spinner } from "~/components/ui/spinner";
import { PopoverRoot, PopoverTrigger } from "~/components/ui/popover";

export const ActivationStatusSelector = ({
  activationRequest,
  selectedStatus,
}: {
  activationRequest: ActivationRequest;
  selectedStatus: MemberStatus | "Unavailable" | null;
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const { mutateActivationStatus, isLoading } = useMutateActivationStatus();

  const onStatusClicked = useCallback(
    async (status: MemberStatus | "Unavailable") => {
      mutateActivationStatus({
        requestId: activationRequest.id as string,
        status,
      });
      setPopoverOpen(false);
    },
    [mutateActivationStatus]
  );

  return (
    <Row justify={"space-between"} align={"center"}>
      {isLoading ? (
        <Spinner />
      ) : selectedStatus ? (
        <PopoverRoot open={popoverOpen} onOpenChange={setPopoverOpen}>
          <Text size="large" weight="medium">
            Your Status
          </Text>
          <>
            <PopoverTrigger>
              <AvailabilityStatusButton
                status={selectedStatus}
                isSelected={true}
              />
            </PopoverTrigger>
            <ChangeResponsePopover>
              <AvailabilityStatusButton
                status={"GoingToHQ"}
                onClick={() => onStatusClicked("GoingToHQ")}
              />
              <AvailabilityStatusButton
                status={"GoingToJob"}
                onClick={() => onStatusClicked("GoingToJob")}
              />
            </ChangeResponsePopover>
          </>
        </PopoverRoot>
      ) : (
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap="small"
          sx={{ width: "100%" }}
        >
          <AvailabilityStatusButton
            isSelected={selectedStatus === "GoingToHQ"}
            onClick={() => onStatusClicked("GoingToHQ")}
            status="GoingToHQ"
          />
          <AvailabilityStatusButton
            isSelected={selectedStatus === "GoingToJob"}
            onClick={() => onStatusClicked("GoingToJob")}
            status="GoingToJob"
          />
          <AvailabilityStatusButton
            isSelected={selectedStatus === "Unavailable"}
            onClick={() => onStatusClicked("Unavailable")}
            status="Unavailable"
          />
        </Stack>
      )}
    </Row>
  );
};
