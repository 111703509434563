import { addHours, startOfDay } from "date-fns";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { Group } from "@ses-mams/api-contract";
import {
  formatDateForServer,
  useAvailabilityWeekSelector,
  useUpdateEffect,
} from "@ses-mams/react-utils";

import { tsr } from "~/utils/client";
import { useSelectedUnit } from "~/context/unit/SelectedUnitContextProvider";

export enum AvailabilityViewMode {
  AGGREGATED,
  INDIVIDUAL,
}

type AvailabilityReportingContextType = {
  unitId: string;
  groups: Group[];
  isLoadingGroups?: boolean;
  startDate: string;
  endDate: string;
  selectedDay: Date;
  firstDayOfTheWeek: Date;
  lastDayOfTheWeek: Date;
  selectedCollection: string;
  selectedCapability?: string;
  selectedMemberType: string;
  goToPreviousWeek: () => void;
  goToNextWeek: () => void;
  goToToday: () => void;
  setSelectedDay: (day: Date) => void;
  setSelectedCollection: (id: string) => void;
  setSelectedCapability: (id?: string | undefined) => void;
  setSelectedMemberType: (id: string) => void;
};

const AvailabilityReportingContext =
  createContext<AvailabilityReportingContextType>({
    unitId: "",
    groups: [],
    startDate: "",
    endDate: "",
    firstDayOfTheWeek: new Date(),
    lastDayOfTheWeek: new Date(),
    selectedDay: new Date(),
    selectedCollection: "",
    selectedCapability: "",
    selectedMemberType: "",
    goToPreviousWeek: () => null,
    goToNextWeek: () => null,
    goToToday: () => null,
    setSelectedDay: () => null,
    setSelectedCollection: () => null,
    setSelectedCapability: () => null,
    setSelectedMemberType: () => null,
  });

export const AvailabilityReportingContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const { selectedUnit } = useSelectedUnit();
  const unitId = selectedUnit?.id || "";
  const {
    firstDayOfTheWeek,
    lastDayOfTheWeek,
    goToPreviousWeek,
    goToNextWeek,
    goToCurrentWeek,
  } = useAvailabilityWeekSelector();

  const [selectedCollection, setSelectedCollection] = useState("::state");
  const [selectedCapability, setSelectedCapability] = useState<
    string | undefined
  >();
  const [selectedMemberType, setSelectedMemberType] = useState("Any");

  const [selectedDay, setSelectedDay] = useState(firstDayOfTheWeek);

  const { startDate, endDate } = useMemo(
    () => ({
      startDate: formatDateForServer(selectedDay),
      endDate: formatDateForServer(addHours(selectedDay, 24)),
    }),
    [selectedDay]
  );

  useUpdateEffect(() => {
    // Update the selected day when the report week changes
    setSelectedDay(firstDayOfTheWeek);
  }, [firstDayOfTheWeek]);

  const { data: groupData, isLoading: isLoadingGroups } =
    tsr.units.listGroups.useQuery({
      queryKey: ["groups", { unitId }, true],
      queryData: {
        params: { unitId },
        query: { reportableOnly: true },
      },
      enabled: !!unitId,
    });

  const groups = groupData?.body ?? [];

  const goToToday = useCallback(() => {
    goToCurrentWeek();
    setSelectedDay(startOfDay(new Date()));
  }, []);

  const value = useMemo(
    () => ({
      unitId,
      groups,
      isLoadingGroups,
      firstDayOfTheWeek,
      lastDayOfTheWeek,
      selectedDay,
      setSelectedDay,
      startDate,
      endDate,
      selectedCollection,
      setSelectedCollection,
      selectedCapability,
      setSelectedCapability,
      selectedMemberType,
      setSelectedMemberType,
      goToPreviousWeek,
      goToNextWeek,
      goToToday,
    }),
    [
      unitId,
      groups,
      isLoadingGroups,
      firstDayOfTheWeek,
      lastDayOfTheWeek,
      selectedDay,
      setSelectedDay,
      startDate,
      endDate,
      selectedCollection,
      setSelectedCollection,
      selectedCapability,
      setSelectedCapability,
      selectedMemberType,
      setSelectedMemberType,
      goToPreviousWeek,
      goToNextWeek,
      goToToday,
    ]
  );

  return (
    <AvailabilityReportingContext.Provider value={value}>
      {children}
    </AvailabilityReportingContext.Provider>
  );
};

export const useAvailabilityReportingContext = () => {
  const ctx = useContext(AvailabilityReportingContext);

  if (!ctx) {
    throw new Error(
      "Must be called within <AvailabilityReportingContextProvider>"
    );
  }

  return ctx;
};
