import { Button } from "~/components/ui/button";
import { Row } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

type FilterPopoverHeaderProps = {
  onClear: () => void;
};

export const FilterPopoverHeader = ({ onClear }: FilterPopoverHeaderProps) => {
  return (
    <Row
      justify="space-between"
      align="center"
      paddingBottom="small"
      marginBottom="large"
      borderBottom="standard"
      borderBottomWidth="medium"
    >
      <Text size="large" weight="medium">
        Filters
      </Text>
      <Button variant="link" onClick={onClear}>
        Reset Filters
      </Button>
    </Row>
  );
};
