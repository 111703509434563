import { createContext, useContext } from "react";

type CheckboxGroupContextProps = {
  disabled?: boolean;
  value: Array<string | number>;
  onValueChange: (value: Array<string | number>) => void;
};

export const CheckboxGroupContext = createContext<CheckboxGroupContextProps>({
  value: [],
  onValueChange: () => null,
});

export const useCheckboxGroupContext = () => {
  const ctx = useContext(CheckboxGroupContext);

  return ctx;
};
