import { Container } from "@mui/material";
import { Stack } from "~/components/ui/stack";
import { Button } from "~/components/ui/button";
import { Heading } from "~/components/ui/heading";
import { ChannelList } from "./components/ChannelList";
import { ChannelMessages } from "./components/ChannelMessages";

import "stream-chat-react/dist/css/v2/index.css";
import "./styles/stream-overrides.css";

export const MessagesPage = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        height: "calc(100vh - 64px - 32px)",
      }}
    >
      <Stack gap="xlarge" sx={{ height: "100%" }}>
        <Stack direction="row" justify="space-between">
          <Heading level="1">Messages</Heading>

          <Button href="/channels/new">New Channel</Button>
        </Stack>

        <Stack
          direction="row"
          gap="large"
          overflowY="hidden"
          sx={{ height: "100%" }}
        >
          <ChannelList />

          <ChannelMessages />
        </Stack>
      </Stack>
    </Container>
  );
};
