import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useCallback, useState } from "react";

import { MemberAdminSummary } from "@ses-mams/api-contract";

import { useOverrideRolesOnMember } from "~/components/common/searchUsersList/hooks";
import { Button } from "~/components/ui/button";

export const ResetRolesButton = ({
  member,
}: {
  member: MemberAdminSummary;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const { overrideRoles, isPending } = useOverrideRolesOnMember();

  const handleClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const handleReset = useCallback(async () => {
    await overrideRoles(member.id, null);
    handleClose();
  }, []);

  if (!member.rolesOverride) {
    return null;
  }

  return (
    <>
      <Button variant="tertiary" onClick={() => setDialogOpen(true)}>
        Reset roles
      </Button>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>Reset roles?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will remove the roles override and reset the member back to
            their synced roles
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="destructive" onClick={handleReset} busy={isPending}>
            Reset
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
