import { Row, Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { useCallback, useState } from "react";
import {
  ActivityAvailabilityStatus,
  ActivityRequest,
} from "@ses-mams/api-contract";
import { Spinner } from "~/components/ui/spinner";
import { PopoverRoot, PopoverTrigger } from "~/components/ui/popover";
import { MemberConditionalReason } from "~/components/common/memberStatus";
import {
  AvailabilityStatusButton,
  AvailabilityStatusResponseButtons,
  ChangeResponsePopover,
} from "../../shared";
import { useMutateActivityAvailabilityStatus } from "../../hooks";

export const ActivityRequestResponsesSection = ({
  activityRequest,
}: {
  activityRequest: ActivityRequest;
}) => {
  const { request } = activityRequest || {};
  const { mutateAvailabilityStatus, isLoading } =
    useMutateActivityAvailabilityStatus();
  const [popoverOpen, setPopoverOpen] = useState(false);

  const requestId = request?.id || "";

  const handleStatusSelected = useCallback(
    async (status: ActivityAvailabilityStatus, conditionalReason?: string) => {
      await mutateAvailabilityStatus({
        requestId,
        status,
        conditionalReason,
        force: false,
      });

      setPopoverOpen(false);
    },
    [mutateAvailabilityStatus, requestId]
  );

  if (!request?.status || request.status === "Removed") {
    return null;
  }

  if (isLoading) {
    return (
      <Row align="center" justify="center">
        <Spinner />
      </Row>
    );
  }

  const statusButtons = (
    <AvailabilityStatusResponseButtons
      onStatusSelected={handleStatusSelected}
      options={["Available", "Conditional", "Unavailable"]}
    />
  );

  return (
    <Row justify={"space-between"} align={"center"}>
      {request.availabilityStatus ? (
        <PopoverRoot open={popoverOpen} onOpenChange={setPopoverOpen}>
          <Stack gap="xsmall">
            <Text size="large" weight="medium">
              Your Reply
            </Text>
            <MemberConditionalReason
              conditionalReason={request.conditionalReason}
            />
          </Stack>
          <PopoverTrigger>
            <AvailabilityStatusButton
              status={request?.availabilityStatus}
              isSelected
            />
          </PopoverTrigger>
          <ChangeResponsePopover>{statusButtons}</ChangeResponsePopover>
        </PopoverRoot>
      ) : (
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap="small"
          sx={{ width: "100%" }}
        >
          {statusButtons}
        </Stack>
      )}
    </Row>
  );
};
