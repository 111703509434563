import { Container } from "@mui/material";
import { captureException } from "@sentry/react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ChannelFormSchema } from "@ses-mams/validation";
import {
  SelectAllMembersQuery,
  formatSelectAllMembersQuery,
} from "@ses-mams/react-utils";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";
import { EditChannelForm } from "./components/EditChannelForm";

export const NewChannelPage = () => {
  const navigate = useNavigate();

  const { mutateAsync, isPending } = tsr.chat.createChannel.useMutation();

  const { addToast } = useToast();

  const onSubmit = useCallback(
    async (
      data: ChannelFormSchema,
      selectAllMembersQuery?: SelectAllMembersQuery
    ) => {
      try {
        await mutateAsync({
          body: {
            name: data.name,
            memberIds: data.members.map(m => m.id),
            groupIds: data.groups.map(g => g.id),
            contactGroupIds: data.contactGroups.map(cg => cg.id),
            selectAllMembersQuery: formatSelectAllMembersQuery(
              selectAllMembersQuery
            ),
          },
        });

        navigate("/messages");
      } catch (error) {
        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    []
  );

  return (
    <Container maxWidth="sm">
      <EditChannelForm onSubmit={onSubmit} busy={isPending} />
    </Container>
  );
};
