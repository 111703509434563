import { useTheme } from "@mui/material";
import {
  DateTimePicker as MUIDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps,
  DatePicker as MUIDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from "@mui/x-date-pickers";

type DateTimePickerProps<T extends Date> =
  | ({ mode: "datetime" } & MuiDateTimePickerProps<T>)
  | ({ mode: "date" } & MuiDatePickerProps<T>);

export const DateTimePicker = <T extends Date>({
  mode = "datetime",
  ...props
}: DateTimePickerProps<T>) => {
  const style = useDateTimePickerStyle();

  if (mode === "datetime") {
    return (
      <MUIDateTimePicker {...(props as MuiDateTimePickerProps<T>)} sx={style} />
    );
  }

  return <MUIDatePicker {...(props as MuiDatePickerProps<T>)} sx={style} />;
};

const useDateTimePickerStyle = () => {
  const {
    tokens: { border, spacing },
  } = useTheme();
  return {
    ".MuiInputLabel-root": {
      marginTop: -5,

      "&[data-shrink=true]": {
        marginTop: 0,
      },
    },
    ".MuiInputBase-root": {
      borderRadius: border.radius.large,
    },
    ".MuiInputBase-input": {
      padding: `${spacing.medium}px ${spacing.large}px`,
    },
  };
};
