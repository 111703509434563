import { zodResolver } from "@hookform/resolvers/zod";
import { useState, useCallback } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  OUT_OF_AREA_ACTIVATION_LEVEL_OPTIONS,
  RequestListFiltersSchema,
  requestListFiltersSchema,
} from "@ses-mams/validation";
import {
  DEFAULT_REQUEST_LIST_FILTERS,
  useRequestListFiltersContext,
  useUpdateEffect,
} from "@ses-mams/react-utils";
import { Row, Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import {
  FilterPopover,
  FilterPopoverFooter,
  FilterPopoverHeader,
} from "~/components/common/filterPopover";
import { CheckboxField } from "~/components/ui/checkbox";
import { DateTimePicker } from "~/components/ui/dateTimePicker";
import { OutOfAreaActivationLevelForm } from "~/components/common/outOfAreaActivationLevel";

type RequestFiltersProps = {
  isFilteringOOAARequests?: boolean;
  isFilteringUnitRequests?: boolean;
  timeLabel: string;
};

export const RequestFilters = ({
  isFilteringOOAARequests,
  isFilteringUnitRequests,
  timeLabel,
}: RequestFiltersProps) => {
  const ctx = useRequestListFiltersContext();
  const { filters, setFilters } = isFilteringUnitRequests
    ? {
        filters: ctx.unitFilters,
        setFilters: ctx.setUnitFilters,
      }
    : {
        filters: ctx.filters,
        setFilters: ctx.setFilters,
      };

  const [open, setOpen] = useState(false);

  const { control, handleSubmit, reset, setValue } =
    useForm<RequestListFiltersSchema>({
      resolver: zodResolver(requestListFiltersSchema),
      mode: "onBlur",
      defaultValues: filters,
    });

  const handleClear = useCallback(
    () => reset(DEFAULT_REQUEST_LIST_FILTERS),
    []
  );

  const handleOpenChange = useCallback(
    (nextIsOpen: boolean) => {
      setOpen(nextIsOpen);

      if (!nextIsOpen) {
        reset(filters);
      }
    },
    [filters]
  );

  const apply = useCallback(
    (formData: RequestListFiltersSchema) => {
      setFilters({
        urgentRequests: formData?.urgentRequests ?? false,
        nonUrgentRequests: formData?.nonUrgentRequests ?? false,
        archivedOnly: formData?.archivedOnly ?? false,
        activationTimeRange: {
          start: formData.activationTimeRange?.start ?? null,
          end: formData.activationTimeRange?.end ?? null,
        },
        level: formData.level ?? null,
        unit: formData.unit ?? null,
        cluster: formData.cluster ?? null,
        zone: formData.zone ?? null,
      });
      setOpen(false);
    },
    [setFilters]
  );

  const watchActivationLevelValue = useWatch({
    control,
    name: "level",
  });

  useUpdateEffect(() => {
    // reset unit, cluster and zone when activation level changes
    setValue("unit", undefined);
    setValue("cluster", undefined);
    setValue("zone", undefined);
  }, [watchActivationLevelValue]);

  return (
    <FilterPopover open={open} onOpenChange={handleOpenChange}>
      <form onSubmit={handleSubmit(apply)}>
        <FilterPopoverHeader onClear={handleClear} />

        <Stack gap="medium" marginTop="small">
          <Controller
            name="urgentRequests"
            control={control}
            render={({ field }) => (
              <CheckboxField
                label="Show urgent requests"
                checked={field.value ?? false}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          />

          <Controller
            name="nonUrgentRequests"
            control={control}
            render={({ field }) => (
              <CheckboxField
                label="Show non-urgent requests"
                checked={field.value ?? false}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          />

          <Text weight="medium">{timeLabel}</Text>
          <Row align="center" justify="space-between" gap="large">
            <Controller
              name="activationTimeRange.start"
              control={control}
              render={({ field: { onChange, value }, fieldState }) => (
                <Stack>
                  <DateTimePicker
                    mode="date"
                    label="Start date"
                    format="dd/MM/yyyy"
                    value={value ?? null}
                    onChange={onChange}
                  />
                  {fieldState.error ? (
                    <Text tone="critical">{fieldState.error.message}</Text>
                  ) : null}
                </Stack>
              )}
            />
            <Text>to</Text>{" "}
            <Controller
              name="activationTimeRange.end"
              control={control}
              render={({ field: { onChange, value }, fieldState }) => (
                <Stack>
                  <DateTimePicker
                    mode="date"
                    label="End date"
                    format="dd/MM/yyyy"
                    value={value ?? null}
                    onChange={onChange}
                  />
                  {fieldState.error ? (
                    <Text tone="critical">{fieldState.error.message}</Text>
                  ) : null}
                </Stack>
              )}
            />
          </Row>

          <Controller
            name="archivedOnly"
            control={control}
            render={({ field }) => (
              <CheckboxField
                label="Show only archived requests"
                checked={field.value ?? false}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          />

          {isFilteringOOAARequests && (
            <OutOfAreaActivationLevelForm
              control={control}
              setValue={setValue}
              levelOptions={OUT_OF_AREA_ACTIVATION_LEVEL_OPTIONS}
            />
          )}
        </Stack>

        <FilterPopoverFooter onClose={() => handleOpenChange(false)} />
      </form>
    </FilterPopover>
  );
};
