/*
 * These ARE NOT Availability Statuses. They are simple counts available in AggregatedUnitAvailabilityPeriod that come
 * from the report query and have no direct connection to AvailabilityStatus. They happen to be called similar things.
 * The wrong thing to do is hack the shape of AvailabilityStatus so it contains "unset", "committed" and pass the thing
 * around like it is an AvailabilityStatus.
 *
 * It would be possible move these states to the backend this implementation is cleaner than it was before but not
 * ideal.
 */
import {
  Availability,
  AvailabilityReportStatus,
  AvailabilityStatus,
} from "@ses-mams/api-contract";
import { BackgroundTones, BorderTones } from "../ui";
import { Permutation } from "./ts-utils";

const toAvailabilityReportStatus = (
  availability?: Availability | null
): AvailabilityReportStatus => {
  if (!availability) {
    return "Unset";
  }

  if (availability.committed) {
    return "Committed";
  }

  if (!availability.status) {
    return "Unset";
  }

  return availability.status;
};

const availabilityReportStatusToAvailabilityStatusSearchParams = (
  status?: AvailabilityReportStatus
): { status?: AvailabilityStatus | null; committed: boolean } => {
  if (status === "Committed") {
    return { status: undefined, committed: true };
  }

  if (status === "Unset") {
    return { status: null, committed: false };
  }

  return { status, committed: false };
};

const AVAILABILITY_REPORT_STATUS_TO_BACKGROUND_TONE_MAP = {
  ImmediatelyAvailable: "positive",
  Available: "positiveLow",
  Conditional: "caution",
  Unavailable: "critical",
  Committed: "info",
  Unset: "muted",
} as const satisfies Record<AvailabilityReportStatus, BackgroundTones>;

const AVAILABILITY_REPORT_STATUS_TO_BADGE_TONE_MAP = {
  ImmediatelyAvailable: "positive",
  Available: "positiveLow",
  Conditional: "caution",
  Unavailable: "critical",
  Committed: "info",
  Unset: "muted",
} as const satisfies Record<AvailabilityReportStatus, BackgroundTones>;

const AVAILABILITY_REPORT_STATUS_TO_BORDER_TONE_MAP = {
  ImmediatelyAvailable: "positive",
  Available: "positiveLow",
  Conditional: "caution",
  Unavailable: "critical",
  Committed: "info",
  Unset: "muted",
} as const satisfies Record<AvailabilityReportStatus, BorderTones>;

const AVAILABILITY_REPORT_STATUS_SORT_ORDER: Permutation<
  AvailabilityReportStatus | undefined
> = [
  "ImmediatelyAvailable",
  "Available",
  "Conditional",
  "Unavailable",
  "Unset",
  "Committed",
  undefined,
];

export {
  toAvailabilityReportStatus,
  availabilityReportStatusToAvailabilityStatusSearchParams,
  AVAILABILITY_REPORT_STATUS_TO_BACKGROUND_TONE_MAP,
  AVAILABILITY_REPORT_STATUS_TO_BADGE_TONE_MAP,
  AVAILABILITY_REPORT_STATUS_TO_BORDER_TONE_MAP,
  AVAILABILITY_REPORT_STATUS_SORT_ORDER,
};
