import {
  AvailabilityReportStatus,
  IndividualUnitAvailability,
} from "@ses-mams/api-contract";
import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import {
  AVAILABILITY_REPORT_STATUS_SORT_ORDER,
  toAvailabilityReportStatus,
} from "../utils";

export const useIndividualAvailabilityReport = (
  items?: Array<IndividualUnitAvailability>
) => {
  const [markerHours, setMarkerHours] = useState(new Date().getHours());

  const markerLabel = useMemo(
    () => format(new Date().setHours(markerHours), "HH:'00"),
    [markerHours]
  );

  const getSortedItemsByAvailabilityAtHour = useCallback(
    (
      items: Array<IndividualUnitAvailability> | undefined = [],
      hour: number
    ) => {
      const memberIdToStatusMap = new Map<string, AvailabilityReportStatus>();

      items.forEach(a => {
        a.availability.find(availability => {
          const h = new Date(availability.start).getHours();

          if (h === hour) {
            memberIdToStatusMap.set(
              a.member.id,
              toAvailabilityReportStatus(availability)
            );
            return true;
          }

          return false;
        });
      });

      return [...items].sort((a, b) => {
        const ae = memberIdToStatusMap.get(a.member.id);
        const be = memberIdToStatusMap.get(b.member.id);

        return (
          AVAILABILITY_REPORT_STATUS_SORT_ORDER.indexOf(ae) -
          AVAILABILITY_REPORT_STATUS_SORT_ORDER.indexOf(be)
        );
      });
    },
    []
  );

  const [sortedItems, setSortedItems] = useState(() =>
    getSortedItemsByAvailabilityAtHour(items, markerHours)
  );

  // Initialise the sorted items when the data changes.
  useEffect(() => {
    if (!items) {
      return;
    }
    setSortedItems(getSortedItemsByAvailabilityAtHour(items, markerHours));
    // Intentionally omit markerHours from dependency array as the items will be re-sorted when drag ends.
  }, [items]);

  return {
    markerHours,
    setMarkerHours,
    markerLabel,
    sortedItems,
    setSortedItems,
    getSortedItemsByAvailabilityAtHour,
  };
};
