import { Member, MemberStatus } from "@ses-mams/api-contract";
import {
  AVAILABILITY_REPORT_STATUS_TO_BACKGROUND_TONE_MAP,
  formatMemberFullName,
  formatMemberStatusLabel,
  toAvailabilityReportStatus,
  useShowMemberCapabilitiesContext,
} from "@ses-mams/react-utils";

import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { Box } from "~/components/ui/box";
import { CapabilityBadge } from "~/components/common/capability";
import { MemberAvailabilityStatusBadge } from "~/components/common/memberStatus";

export type MemberListItemProps = {
  member: Member;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  memberStatus?: MemberStatus | null;
  showAvailabilityStatus?: boolean;
  children?: React.ReactNode;
};

export const MemberListItem = ({
  member,
  startAdornment,
  endAdornment,
  memberStatus,
  showAvailabilityStatus,
  children,
}: MemberListItemProps) => {
  const { showMemberCapabilities } = useShowMemberCapabilitiesContext();

  const conditionalReason = member?.availability?.conditionalReason;
  const availabilityReportStatus = toAvailabilityReportStatus(
    member?.availability
  );
  const backgroundTone =
    AVAILABILITY_REPORT_STATUS_TO_BACKGROUND_TONE_MAP[availabilityReportStatus];

  return (
    <Stack
      flex={1}
      direction="row"
      justify="space-between"
      gap="small"
      paddingY="large"
    >
      {showAvailabilityStatus && (
        <Box
          display="flex"
          background={backgroundTone}
          borderRadius="xsmall"
          sx={{ width: 3, minHeight: "100%" }}
        />
      )}

      {startAdornment}

      <Stack flex={1} gap="small" align="start" justify="center">
        <Text size="large" weight="medium">
          {formatMemberFullName(member)}
        </Text>

        {showMemberCapabilities && (
          <Stack direction="row" gap="small" wrap>
            {member.capabilities.map(capability => (
              <CapabilityBadge
                capability={capability}
                key={`${member.id}-${capability.id}`}
              />
            ))}
          </Stack>
        )}

        {memberStatus && (
          <Text size={"standard"}>{formatMemberStatusLabel(memberStatus)}</Text>
        )}

        {showAvailabilityStatus ? (
          <MemberAvailabilityStatusBadge availability={member.availability} />
        ) : null}

        {showAvailabilityStatus && conditionalReason ? (
          <Text size="large">{`"${conditionalReason}"`}</Text>
        ) : null}

        {children}
      </Stack>

      {endAdornment}
    </Stack>
  );
};
