import {
  formatDateForServer,
  useOutOfAreaActivationMemberListSections,
  useOutOfAreaMembersListFilters,
  useTransformPagedDataToItems,
} from "@ses-mams/react-utils";
import { tsr } from "~/utils/client";
import { LARGE_PAGE_SIZE, getNextLargePageParam } from "~/utils/pagination";

export const useOutOfAreaActivationMembersData = (
  activationId: string,
  enabled?: boolean
) => {
  const { filters } = useOutOfAreaMembersListFilters();

  const [collectionId, level] = (filters?.collection || "").split("::");

  const { data, isLoading, isRefetching, refetch, fetchNextPage, hasNextPage } =
    tsr.outOfAreaActivations.getMembers.useInfiniteQuery({
      queryKey: [
        "out-of-area-activation",
        { activationId },
        "members-v2",
        filters.activatedStatus,
        filters.approvedStatus,
        filters.rejectedStatus,
        filters.zonePendingStatus,
        filters.clusterPendingStatus,
        filters.unitPendingStatus,
        filters.awaitingStatus,
        filters.statusUpdatedAt,
        filters.collection,
        filters.name,
        filters.roles.map(role => role.id),
      ],
      queryData: ({ pageParam }) => ({
        params: {
          activationId,
        },
        query: {
          roles: filters?.roles.map(role => role.id),
          activatedStatus: filters?.activatedStatus,
          approvedStatus: filters?.approvedStatus,
          rejectedStatus: filters?.rejectedStatus,
          zonePendingStatus: filters?.zonePendingStatus,
          clusterPendingStatus: filters?.clusterPendingStatus,
          unitPendingStatus: filters?.unitPendingStatus,
          awaitingStatus: filters?.awaitingStatus,
          unitId: level === "unit" ? collectionId : undefined,
          clusterId: level === "cluster" ? collectionId : undefined,
          zoneId: level === "zone" ? collectionId : undefined,
          name: filters?.name,
          statusUpdatedAt: filters?.statusUpdatedAt
            ? formatDateForServer(filters.statusUpdatedAt)
            : undefined,
          skip: pageParam?.skip ?? 0,
          take: pageParam?.take ?? LARGE_PAGE_SIZE,
        },
      }),
      initialPageParam: { skip: 0, take: LARGE_PAGE_SIZE },
      getNextPageParam: getNextLargePageParam,
      enabled,
    });

  const items = useTransformPagedDataToItems(data);

  const sections = useOutOfAreaActivationMemberListSections(items);

  return {
    isLoading,
    isRefetching,
    refetch,
    sections,
    fetchNextPage,
    hasNextPage,
  };
};
