import { useCallback } from "react";
import { ButtonBase } from "@mui/material";
import { Cluster } from "@ses-mams/api-contract";
import { useDebouncedSearch } from "@ses-mams/react-utils";
import emptyListImageUrl from "~/assets/emptyList.png";
import { SearchInput } from "~/components/ui/searchInput";
import { Stack } from "~/components/ui/stack";
import { InfiniteList } from "~/components/ui/list";
import { Checkbox } from "~/components/ui/checkbox";
import { EmptyState } from "~/components/common/emptyState";
import { ClusterListItem } from "~/components/common/cluster";
import { useClusterListSearch } from "./useClusterListSearch";

type SearchClusterListProps = {
  clusters: Array<Cluster>;
  removeCluster: (cluster: Cluster) => void;
  appendCluster: (cluster: Cluster) => void;
};

export const SelectClustersList = ({
  clusters,
  removeCluster,
  appendCluster,
}: SearchClusterListProps) => {
  const { searchValue, setSearchValue, debouncedSearchValue } =
    useDebouncedSearch("");

  const { data, isLoading, isRefetching, hasNextPage, fetchNextPage } =
    useClusterListSearch(debouncedSearchValue);

  const renderItem = useCallback(
    (item: Cluster) => {
      const isChecked = clusters.some(({ id }) => id === item.id);

      return (
        <ButtonBase
          role="checkbox"
          aria-checked={isChecked}
          onClick={() =>
            isChecked ? removeCluster(item) : appendCluster(item)
          }
        >
          <ClusterListItem
            startAdornment={<Checkbox checked={isChecked} />}
            cluster={item}
          />
        </ButtonBase>
      );
    },
    [clusters]
  );

  const isRefreshing = isLoading || isRefetching;

  return (
    <Stack flex={1} gap="large">
      <SearchInput
        placeholder="Search"
        value={searchValue}
        onValueChange={setSearchValue}
      />

      <InfiniteList
        keyExtractor={item => item.id}
        data={data}
        renderItem={renderItem}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        listEmptyElement={
          isRefreshing ? null : (
            <EmptyState
              image={<img src={emptyListImageUrl} />}
              title="No clusters found"
              description="There are no clusters associated with your search. Please try another value."
            />
          )
        }
      />
    </Stack>
  );
};
