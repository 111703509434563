import {
  ActivationRequestWithAvailabilityCount,
  ActivationRequestStatus,
} from "@ses-mams/api-contract";
import { useMemo } from "react";

export const useActivationRequestListSections = (
  pages?: Array<{
    status: number;
    body: { items: Array<ActivationRequestWithAvailabilityCount> };
  }>,
  archived?: boolean
) => {
  return useMemo(() => {
    const sectionMap: Record<
      OperationalStatusHeading,
      ActivationRequestWithAvailabilityCount[]
    > = {
      // insertion order of these keys must match the endpoint's ORDER BY
      Activated: [],
      "Activation Requested": [],
      Available: [],
      "Availability Requested": [],
      Open: [],
      Declined: [],
      Deactivated: [],
      Archived: [],
    };

    (pages || []).flatMap(page => {
      if (page.status !== 200) {
        return;
      }

      if (archived) {
        sectionMap.Archived.push(...page.body.items);
        return;
      }

      page.body.items.forEach(item => {
        const statusLabel =
          item.availabilityStatus === "Unavailable"
            ? "Declined"
            : activationHeadingMap[item?.status] || "Open";

        sectionMap[statusLabel].push(item);
      });
    });

    return Object.keys(sectionMap).flatMap(key => {
      const typedKey = key as keyof typeof sectionMap;
      const keyedData = sectionMap[typedKey];

      if (!keyedData.length) {
        return [];
      }

      return [
        {
          title: typedKey,
          data: keyedData,
        },
      ];
    });
  }, [pages, archived]);
};

type OperationalStatusHeading =
  | "Activated"
  | "Activation Requested"
  | "Available"
  | "Availability Requested"
  | "Open"
  | "Declined"
  | "Deactivated"
  | "Archived";

const activationHeadingMap = {
  ActivationAccepted: "Activated",
  ActivationRequested: "Activation Requested",
  AvailabilitySubmitted: "Available",
  AvailabilityRequested: "Availability Requested",
  ActivationDeclined: "Declined",
  Deactivated: "Deactivated",
} as const satisfies Record<ActivationRequestStatus, OperationalStatusHeading>;
