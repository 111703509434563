import { ZodType, z } from "zod";

// A regexp that validates yyy-mm-ddThh:mm without a timezone.
export const ServerDateSchema = z
  .string()
  .regex(
    /^\d{4}-(0[1-9]|1[0-2])-([0-2][0-9]|3[01])T([01][0-9]|2[0-3]):[0-5][0-9]$/,
    "Dates must be specified in local time using yyyy-mm-ddThh:mm"
  );

export const AvailabilityStatusSchema = z.enum([
  "ImmediatelyAvailable",
  "Available",
  "Conditional",
  "Unavailable",
]);

export const SearchSuggestionTypeSchema = z.enum([
  "capability",
  "unit",
  "cluster",
  "zone",
  "beacon group",
]);

export type AvailabilityStatus = z.infer<typeof AvailabilityStatusSchema>;

/**
 * This is a synthetic type that is used when interacting with availability reports. AvailabilityStatus is a
 * status on the Availability Type.
 */
export const AvailabilityReportStatusSchema = z.enum([
  "ImmediatelyAvailable",
  "Available",
  "Conditional",
  "Unavailable",
  "Committed",
  "Unset",
]);

export type AvailabilityReportStatus = z.infer<
  typeof AvailabilityReportStatusSchema
>;

export const ApiErrorSchema = z.object({
  status: z.string().optional(),
  code: z.string().optional(),
  message: z.string(),
});

export type ApiErrorResponse = z.infer<typeof ApiErrorSchema>;

export const ApiMessageSchema = z.object({
  status: z.string().optional(),
  message: z.string(),
});

export type ApiResponse = z.infer<typeof ApiMessageSchema>;

export const PaginationQueryParamsSchema = z.object({
  skip: z.coerce.number().optional().default(0),
  take: z.coerce.number().max(1000).optional().default(100),
});

export type PaginationQueryParams = z.infer<typeof PaginationQueryParamsSchema>;

export const getPaginationResponseSchema = <T>(itemSchema: ZodType<T>) =>
  z.object({
    totalCount: z.number(),
    items: z.array(itemSchema),
  });

export const CapabilitySchema = z.object({
  id: z.string(),
  name: z.string(),
  label: z.string(),
});

export const SuggestionSchema = z.object({
  id: z.string(),
  name: z.string(),
  label: z.string().optional(),
  type: SearchSuggestionTypeSchema,
});

export const MemberTypeSchema = z.enum(["Volunteer", "Staff"]);

export const MemberStatusSchema = z.enum(["GoingToHQ", "GoingToJob"]);

export const MemberSummarySchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  preferredName: z.string().optional(),
  type: MemberTypeSchema,
});

export const UnitSummarySchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().nullable(),
  address: z.string().nullable(),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
});

export const UnitSchema = UnitSummarySchema.extend({
  contactPoint: MemberSummarySchema.nullable(),
});

export const UnitWithClusterSchema = UnitSchema.extend({
  cluster: z
    .object({
      name: z.string(),
      code: z.string().nullable(),
      zone: z.object({
        name: z.string(),
        code: z.string().nullable(),
      }),
    })
    .nullable(),
});

export const AvailabilitySchema = z.object({
  start: ServerDateSchema,
  end: ServerDateSchema,
  committed: z.boolean(),
  status: AvailabilityStatusSchema.nullable(),
  conditionalReason: z.string().nullable(),
  emergenciesOnly: z.boolean().nullable(),
});

// https://github.com/ts-rest/ts-rest/issues/290#issuecomment-1658983510
// Workaround for problem with Zod handling and validation of number as string in an array.
export const ArrayOfNumberStringSchema = z
  .array(z.string().or(z.number()).transform(String))
  .or(z.number().transform((v: number) => [String(v)]));

export const SearchMembersFilterSchema = z.object({
  query: z.coerce.string().trim().optional(),
  unitIds: ArrayOfNumberStringSchema.optional(),
  capabilityIds: z.array(z.coerce.string()).optional(),
  zoneIds: ArrayOfNumberStringSchema.optional(),
  clusterIds: ArrayOfNumberStringSchema.optional(),
  groupIds: z.array(z.coerce.string()).optional(),
  contactGroupIds: ArrayOfNumberStringSchema.optional(),
  exclude: z
    .object({
      channelId: z.string().optional(),
      activationId: z.string().optional(),
      outOfAreaActivationId: z.string().optional(),
      activityId: z.string().optional(),
      activityScheduleId: z.string().optional(),
      memberIds: ArrayOfNumberStringSchema.optional(),
    })
    .optional(),
  requiredAvailabilityStart: ServerDateSchema.optional(),
  requiredAvailabilityEnd: ServerDateSchema.optional(),
  currentAvailabilityStatusTime: ServerDateSchema.optional(),
});

export type Availability = z.infer<typeof AvailabilitySchema>;
export type Capability = z.infer<typeof CapabilitySchema>;
export type Suggestion = z.infer<typeof SuggestionSchema>;
export type MemberStatus = z.infer<typeof MemberStatusSchema>;
export type MemberSummary = z.infer<typeof MemberSummarySchema>;
export type MemberType = z.infer<typeof MemberTypeSchema>;
export type Unit = z.infer<typeof UnitSchema>;
export type SearchMembersFilter = z.infer<typeof SearchMembersFilterSchema>;
