import { startOfDay } from "date-fns";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { captureException } from "@sentry/react";
import { ContactGroup, Group, Member } from "@ses-mams/api-contract";
import {
  SelectAllMembersQuery,
  formatDateForServer,
  formatSelectAllMembersQuery,
  formatTwentyFourHourTime,
} from "@ses-mams/react-utils";
import {
  ActivityScheduleFormSchema,
  activityScheduleForm,
  convertDurationToMinutes,
} from "@ses-mams/validation";
import {
  EventForm,
  useDefaultUserUnitAndAddress,
} from "~/components/common/eventForm";
import { EditGroupList } from "~/components/common/editGroupList";
import { EditMemberList } from "~/components/common/editMemberList";
import { SelectField, SelectItem } from "~/components/ui/selectField";
import { Stack } from "~/components/ui/stack";
import { Divider } from "~/components/ui/divider";
import { Button } from "~/components/ui/button";
import { Heading } from "~/components/ui/heading";
import { useToast } from "~/components/ui/toast";
import { IntegerField } from "~/components/ui/integerField";
import { Box } from "~/components/ui/box";
import { Text } from "~/components/ui/text";
import { CheckboxField, CheckboxGroupField } from "~/components/ui/checkbox";
import { tsr } from "~/utils/client";
import { UnitField } from "../../shared";
import { ActivityScheduleEndFields } from "./ActivityScheduleEndFields";
import { RemindMembersField } from "./RemindMembersField";

export const AddActivityScheduleForm = () => {
  const navigate = useNavigate();

  const { addToast } = useToast();

  const [members, setMembers] = useState<Array<Member>>([]);
  const [groups, setGroups] = useState<Array<Group>>([]);
  const [contactGroups, setContactGroups] = useState<Array<ContactGroup>>([]);
  const [selectAllMembersQuery, setSelectAllMembersQuery] =
    useState<SelectAllMembersQuery>();

  const defaultStart = new Date();
  const defaultUserUnitAndAddress = useDefaultUserUnitAndAddress();

  const formMethods = useForm<ActivityScheduleFormSchema>({
    resolver: zodResolver(activityScheduleForm),
    defaultValues: {
      ...defaultUserUnitAndAddress,
      schedule: {
        period: "Daily",
        monthlyRepeatOnMode: "Day of Month",
        nth: { value: 1, dayOfWeek: "MON" },
        endMode: "On Date",
      },
      start: {
        date: defaultStart,
        time: formatTwentyFourHourTime(defaultStart),
      },
    },
    reValidateMode: "onBlur",
  });

  const watchSelectedUnit = formMethods.watch("unit");
  const watchPeriod = formMethods.watch("schedule.period");
  const watchMonthlyRepeatOnMode = formMethods.watch(
    "schedule.monthlyRepeatOnMode"
  );

  const handleChangeSelectedGroups = useCallback(
    (nextGroups: Array<Group>, nextContactGroups: Array<ContactGroup>) => {
      setGroups(nextGroups);
      setContactGroups(nextContactGroups);
    },
    []
  );

  const { mutateAsync, isPending } = tsr.activitySchedules.create.useMutation();

  const onSubmit = useCallback(
    async ({ schedule, ...data }: ActivityScheduleFormSchema) => {
      try {
        const { unit, ...rest } = data;

        await mutateAsync({
          body: {
            ...rest,
            unitId: unit.id,
            address: data.location,
            initialStart: formatDateForServer(data.start.date),
            initialEnd: formatDateForServer(data.end.date),
            recipientGroupIds: groups.map(g => g.id),
            recipientContactGroupIds: contactGroups.map(cg => cg.id),
            recipientMemberIds: members.map(m => m.id),
            selectAllMembersQuery: formatSelectAllMembersQuery(
              selectAllMembersQuery
            ),
            schedule: {
              ...schedule,
              every: schedule.every as number,
              nth:
                schedule.period === "Monthly" &&
                schedule.monthlyRepeatOnMode === "The..."
                  ? schedule.nth
                  : undefined,
              endCount: schedule.endCount ?? undefined,
              endDate: schedule.endDate
                ? formatDateForServer(startOfDay(schedule.endDate))
                : undefined,
              reminderDuration: convertDurationToMinutes({
                value: schedule.reminderDurationBeforeStart?.value,
                unit: schedule.reminderDurationBeforeStart?.unit,
              }),
            },
          },
        });

        navigate("/requests?tab=activity");
      } catch (error) {
        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    [members, groups, contactGroups]
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Stack gap="xlarge" paddingBottom="xxlarge">
          <Stack direction="row" justify="space-between">
            <Heading level="1">New Schedule</Heading>
            <Button type="submit" busy={isPending}>
              Done
            </Button>
          </Stack>
          <Divider />
          <UnitField label="Activity for Unit" />
          <EventForm type="Activity" />

          <Controller
            name="schedule.period"
            control={formMethods.control}
            render={({ field, fieldState }) => (
              <SelectField
                label="Repeat"
                placeholder="Select..."
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                errorMessage={fieldState.error?.message}
              >
                {SCHEDULE_PERIOD_OPTIONS.map(option => (
                  <SelectItem key={option} value={option}>
                    {option}
                  </SelectItem>
                ))}
              </SelectField>
            )}
          />

          {watchPeriod === "Daily" && (
            <Controller
              shouldUnregister
              name="schedule.every"
              control={formMethods.control}
              render={({ field, fieldState }) => (
                <IntegerField
                  label="Repeat Every"
                  placeholder="Amount"
                  value={field.value}
                  onValueChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={fieldState.error?.message}
                  endAdornment={
                    <Box paddingX="medium" sx={{ width: "70%" }}>
                      <Text align="center">Day(s)</Text>
                    </Box>
                  }
                />
              )}
            />
          )}

          {watchPeriod === "Weekly" && (
            <>
              <Controller
                shouldUnregister
                name="schedule.every"
                control={formMethods.control}
                render={({ field, fieldState }) => (
                  <IntegerField
                    label="Repeat Every"
                    placeholder="Amount"
                    value={field.value}
                    onValueChange={field.onChange}
                    onBlur={field.onBlur}
                    errorMessage={fieldState.error?.message}
                    endAdornment={
                      <Box paddingX="medium" sx={{ width: "70%" }}>
                        <Text align="center">Week(s)</Text>
                      </Box>
                    }
                  />
                )}
              />

              <Controller
                shouldUnregister
                name="schedule.daysOfWeek"
                control={formMethods.control}
                render={({ field, fieldState }) => (
                  <CheckboxGroupField
                    label="Repeat On"
                    value={field.value}
                    onValueChange={field.onChange}
                    errorMessage={fieldState.error?.message}
                  >
                    <CheckboxField value="MON" label="Monday" />
                    <CheckboxField value="TUE" label="Tuesday" />
                    <CheckboxField value="WED" label="Wednesday" />
                    <CheckboxField value="THU" label="Thursday" />
                    <CheckboxField value="FRI" label="Friday" />
                    <CheckboxField value="SAT" label="Saturday" />
                    <CheckboxField value="SUN" label="Sunday" />
                  </CheckboxGroupField>
                )}
              />
            </>
          )}

          {watchPeriod === "Monthly" && (
            <>
              <Controller
                name="schedule.monthlyRepeatOnMode"
                control={formMethods.control}
                render={({ field, fieldState }) => (
                  <SelectField
                    label="Repeat on"
                    placeholder="Select..."
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    errorMessage={fieldState.error?.message}
                  >
                    {SCHEDULE_MONTHLY_REPEAT_ON_MODE_OPTIONS.map(option => (
                      <SelectItem key={option} value={option}>
                        {option}
                      </SelectItem>
                    ))}
                  </SelectField>
                )}
              />

              {watchMonthlyRepeatOnMode === "Day of Month" ? (
                <Stack gap="xsmall">
                  <Text size="large" weight="medium">
                    Repeat on Day
                  </Text>
                  <Stack direction="row" gap="small" align="start">
                    <Controller
                      shouldUnregister
                      name="schedule.dayOfMonth"
                      control={formMethods.control}
                      render={({
                        field: dayOfMonthField,
                        fieldState: dayOfMonthFieldState,
                      }) => (
                        <Box flex={1}>
                          <IntegerField
                            hideLabel
                            label="Day of month"
                            placeholder="Day"
                            value={dayOfMonthField.value}
                            onValueChange={dayOfMonthField.onChange}
                            onBlur={dayOfMonthField.onBlur}
                            errorMessage={dayOfMonthFieldState.error?.message}
                            endAdornment={
                              <Box paddingLeft="medium" sx={{ minWidth: 68 }}>
                                <Text align="center">of every</Text>
                              </Box>
                            }
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      shouldUnregister
                      name="schedule.every"
                      control={formMethods.control}
                      render={({
                        field: everyField,
                        fieldState: everyFieldState,
                      }) => (
                        <Box flex={1}>
                          <IntegerField
                            hideLabel
                            label="Every"
                            placeholder="Amount"
                            value={everyField.value}
                            onValueChange={everyField.onChange}
                            onBlur={everyField.onBlur}
                            errorMessage={everyFieldState.error?.message}
                            endAdornment={
                              <Box paddingLeft="medium">
                                <Text align="center">month(s)</Text>
                              </Box>
                            }
                          />
                        </Box>
                      )}
                    />
                  </Stack>
                </Stack>
              ) : (
                <>
                  <Controller
                    shouldUnregister
                    defaultValue={1}
                    name="schedule.nth.value"
                    control={formMethods.control}
                    render={({ field, fieldState }) => (
                      <SelectField
                        hideLabel
                        label="Repeat on occurrence"
                        placeholder="Select..."
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        errorMessage={fieldState.error?.message}
                      >
                        {SCHEDULE_MONTHLY_REPEAT_NTH_OPTIONS.map(option => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectField>
                    )}
                  />

                  <Controller
                    shouldUnregister
                    name="schedule.nth.dayOfWeek"
                    control={formMethods.control}
                    render={({ field, fieldState }) => (
                      <SelectField
                        hideLabel
                        label="Repeat on day of week"
                        placeholder="Select..."
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        errorMessage={fieldState.error?.message}
                      >
                        {SCHEDULE_MONTHLY_REPEAT_NTH_DAY_OF_WEEK_OPTIONS.map(
                          option => (
                            <SelectItem key={option.value} value={option.value}>
                              {option.label}
                            </SelectItem>
                          )
                        )}
                      </SelectField>
                    )}
                  />

                  <Controller
                    shouldUnregister
                    name="schedule.every"
                    control={formMethods.control}
                    render={({ field }) => (
                      <IntegerField
                        hideLabel
                        label="Every"
                        placeholder="Amount"
                        value={field.value}
                        onValueChange={field.onChange}
                        onBlur={field.onBlur}
                        startAdornment={
                          <Box paddingRight="medium" sx={{ minWidth: 68 }}>
                            <Text align="center">of every</Text>
                          </Box>
                        }
                        endAdornment={
                          <Box paddingX="medium">
                            <Text align="center">month(s)</Text>
                          </Box>
                        }
                      />
                    )}
                  />
                </>
              )}
            </>
          )}

          <ActivityScheduleEndFields control={formMethods.control} />

          <RemindMembersField
            control={formMethods.control}
            setValue={formMethods.setValue}
          />

          <EditGroupList
            selectedGroups={groups}
            selectedContactGroups={contactGroups}
            unitId={watchSelectedUnit.id}
            onChangeSelectedGroups={handleChangeSelectedGroups}
          />
          <EditMemberList
            showAvailabilityStatus
            selectedMembers={members}
            onChangeSelectedMembers={setMembers}
            isLoadingMembers={false}
            unitId={watchSelectedUnit.id}
            selectAllMembersQuery={selectAllMembersQuery}
            setSelectAllMembersQuery={setSelectAllMembersQuery}
          />
        </Stack>
      </form>
    </FormProvider>
  );
};

const SCHEDULE_PERIOD_OPTIONS = ["Daily", "Weekly", "Monthly"];

const SCHEDULE_MONTHLY_REPEAT_ON_MODE_OPTIONS = ["Day of Month", "The..."];

const SCHEDULE_MONTHLY_REPEAT_NTH_OPTIONS = [
  { label: "First", value: 1 },
  { label: "Second", value: 2 },
  { label: "Third", value: 3 },
  { label: "Fourth", value: 4 },
];

const SCHEDULE_MONTHLY_REPEAT_NTH_DAY_OF_WEEK_OPTIONS = [
  { label: "Monday", value: "MON" },
  { label: "Tuesday", value: "TUE" },
  { label: "Wednesday", value: "WED" },
  { label: "Thursday", value: "THU" },
  { label: "Friday", value: "FRI" },
  { label: "Saturday", value: "SAT" },
  { label: "Sunday", value: "SUN" },
];
