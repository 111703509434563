import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from "@mui/material";

type IconButtonProps = Pick<
  MuiIconButtonProps,
  "children" | "disabled" | "onClick" | "aria-label"
> & {
  size: "small" | "medium" | "large";
};

export const IconButton = ({ children, size, ...props }: IconButtonProps) => {
  return (
    <MuiIconButton size={size} {...props}>
      {children}
    </MuiIconButton>
  );
};
