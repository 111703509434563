import { OutOfAreaActivationRequestStatus } from "@ses-mams/api-contract";
import {
  formatOutOfAreaRequestStatus,
  getOutOfAreaActivationRequestStatusBadgeTone,
} from "@ses-mams/react-utils";
import { Badge } from "~/components/ui/badge";

type OutOfAreaActivationRequestStatusBadgeProps = {
  status: OutOfAreaActivationRequestStatus;
  archived?: boolean;
};

export const OutOfAreaActivationRequestStatusBadge = ({
  status,
  archived,
}: OutOfAreaActivationRequestStatusBadgeProps) => {
  return (
    <Badge
      tone={getOutOfAreaActivationRequestStatusBadgeTone(status)}
      size="medium"
    >
      {formatOutOfAreaRequestStatus(status, archived)}
    </Badge>
  );
};
