import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Unit } from "@ses-mams/api-contract";
import { useAuth } from "~/context/auth";

type SelectedUnitContextType = {
  selectedUnit?: Unit;
  setSelectedUnit: (unit: Unit) => void;
  memberBelongsToSelectedUnit: boolean;
};

const SelectedUnitContext = createContext<SelectedUnitContextType>({
  setSelectedUnit: () => null,
  memberBelongsToSelectedUnit: false,
});

function SelectedUnitContextProvider(props: React.PropsWithChildren) {
  const { member } = useAuth();
  const [selectedUnit, setSelectedUnit] = useState<Unit>();

  useEffect(() => {
    if (member && !selectedUnit) {
      const defaultUnit = member.units[0].unit;
      if (!defaultUnit) {
        throw new Error("Bad data: Member does not belong to any units");
      }

      setSelectedUnit(defaultUnit);
    }
  }, [member, selectedUnit]);

  const value = useMemo(
    () => ({
      selectedUnit,
      setSelectedUnit,
      memberBelongsToSelectedUnit:
        member?.units?.some(u => u.unit.id === selectedUnit?.id) ?? false,
    }),
    [selectedUnit, member]
  );

  return (
    <SelectedUnitContext.Provider value={value}>
      {props.children}
    </SelectedUnitContext.Provider>
  );
}

function useSelectedUnit() {
  const ctx = useContext(SelectedUnitContext);

  if (!ctx) {
    throw new Error("Must be called within <SelectedUnitContextProvider />");
  }

  return ctx;
}

export { useSelectedUnit, SelectedUnitContextProvider };
