import { Unit } from "@ses-mams/api-contract";
import { useArrayState } from "@ses-mams/react-utils";
import { useCallback } from "react";
import { Button } from "~/components/ui/button";
import { CheckboxField } from "~/components/ui/checkbox";
import { Heading } from "~/components/ui/heading";
import { Popover, PopoverClose, PopoverContent } from "~/components/ui/popover";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { useAuth } from "~/context/auth";

type ChannelFilterPopoverProps = {
  setOpen: (open: boolean) => void;
  selectedUnits: Array<Unit>;
  onApplyClick: (units: Array<Unit>) => void;
};

export const ChannelFilterPopover = ({
  setOpen,
  selectedUnits,
  onApplyClick,
}: ChannelFilterPopoverProps) => {
  const units = useAuth()?.member?.units ?? [];

  const [internalSelectedUnits, { append, remove, clear, setValue }] =
    useArrayState(selectedUnits);

  const handleClose = useCallback(() => {
    setOpen(false);
    setValue(selectedUnits);
  }, [selectedUnits]);

  const handleApply = useCallback(() => {
    setOpen(false);
    onApplyClick(internalSelectedUnits);
  }, [internalSelectedUnits, onApplyClick]);

  return (
    <Popover>
      <PopoverContent>
        <Stack gap="small" dividers>
          <Stack
            direction="row"
            gap="small"
            justify="space-between"
            align="center"
          >
            <Heading level="4">Filters</Heading>

            <Button variant="link" onClick={clear}>
              Clear Filters
            </Button>
          </Stack>

          <Stack gap="xsmall">
            <Text weight="medium" size="large">
              Units
            </Text>

            <Stack direction="row" gap="medium" wrap paddingBottom="small">
              {units.map(({ unit }) => {
                const isChecked = internalSelectedUnits.some(
                  u => unit?.id === u.id
                );

                return (
                  <CheckboxField
                    key={unit.id}
                    label={unit.name}
                    checked={isChecked}
                    onChange={() => (isChecked ? remove(unit) : append(unit))}
                    sx={theme => ({
                      borderStyle: "solid",
                      borderRadius: theme.tokens.border.radius.medium,
                      borderWidth: theme.tokens.border.width.medium,
                      borderColor:
                        theme.tokens.border.color[
                          isChecked ? "accent" : "standard"
                        ],
                      padding: theme.tokens.spacing.xsmall,
                      marginRight: 0,
                    })}
                  />
                );
              })}
            </Stack>

            <Stack direction="row" gap="small" align="center">
              <PopoverClose>
                <Button variant="secondary" fullWidth onClick={handleClose}>
                  Close
                </Button>
              </PopoverClose>

              <Button variant="primary" fullWidth onClick={handleApply}>
                Apply
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </PopoverContent>
    </Popover>
  );
};
