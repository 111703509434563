import { ActivationPointsOfInterest } from "@ses-mams/api-contract";
import { Text } from "~/components/ui/text";
import {
  formatMemberFullName,
  formatTwentyFourHourTime,
} from "@ses-mams/react-utils";
import { Button } from "~/components/ui/button";
import { useAuth } from "~/context/auth";
import { useSendMessageToMember } from "~/components/common/chat";
import { Stack } from "~/components/ui/stack";
import { Divider } from "~/components/ui/divider";

type Props = {
  member: ActivationPointsOfInterest["members"][0];
};
export const SelectedMemberDetails = ({ member }: Props) => {
  const { member: currentMember } = useAuth();

  const { sendMessage, isLoadingMessageChannel } = useSendMessageToMember();

  return (
    <>
      <Stack gap="xxsmall" sx={{ minWidth: 200 }}>
        <Text size="large" weight="semi">
          {formatMemberFullName(member)}
        </Text>
        {member.eta && (
          <Text>ETA: {formatTwentyFourHourTime(new Date(member.eta))}</Text>
        )}
      </Stack>
      {currentMember && currentMember.id !== member.id && (
        <Stack paddingTop="small" gap="small">
          <Divider />
          <Button
            variant="secondary"
            busy={isLoadingMessageChannel}
            onClick={() => {
              sendMessage(member.id);
            }}
          >
            Send Message
          </Button>
        </Stack>
      )}
    </>
  );
};
