import { ScheduleGridCell } from "./ScheduleGridCell";
import { AvailabilityGridHeader } from "../common/AvailabilityGridHeader";
import {
  HourlyAvailability,
  ScheduleAvailability,
} from "@ses-mams/react-utils";
import { Row, Stack } from "~/components/ui/stack";

type ScheduleGridProps = {
  availability: ScheduleAvailability;
  onAvailabilityEdited?: (editedAvailability: HourlyAvailability) => void;
  onDateClick?: (date: Date) => void;
  selectedCells?: { date: Date; scheduleItemId: string }[];
  onCellClick?: (date: Date, scheduleItemId: string) => void;
};

export const ScheduleGrid = ({
  availability,
  onCellClick,
  onDateClick,
  selectedCells,
}: ScheduleGridProps) => {
  return (
    <Stack flex={1} direction="column">
      <AvailabilityGridHeader
        availability={availability}
        onDatePress={onDateClick}
      />
      <Row sx={{ height: "100%" }}>
        {availability.map(({ date, scheduleItems }) => {
          return (
            <Stack key={date.toISOString()} sx={{ height: "100%", flex: 1 }}>
              {scheduleItems.map(item => {
                const isSelected =
                  selectedCells?.some(
                    selectedCell =>
                      +selectedCell.date === +date &&
                      selectedCell.scheduleItemId === item.scheduleItem.id
                  ) ?? false;
                return (
                  <ScheduleGridCell
                    date={date}
                    scheduleItemId={item.scheduleItem.id}
                    slots={item.slots}
                    isSelected={isSelected}
                    onCellClick={onCellClick}
                    key={`${date}-${item.scheduleItem.id}`}
                  />
                );
              })}
            </Stack>
          );
        })}
      </Row>
    </Stack>
  );
};
