import { FormControlLabel } from "@mui/material";
import { Radio } from "./Radio";

export type RadioGroupFieldItemProps = {
  value: string;
  label: string;
};

export const RadioGroupFieldItem = ({
  value,
  label,
}: RadioGroupFieldItemProps) => {
  return (
    <FormControlLabel
      control={<Radio />}
      key={value}
      label={label}
      value={value}
    />
  );
};
