import { Button } from "~/components/ui/button";

type NavigateToButtonProps = {
  coordinate: {
    latitude: number;
    longitude: number;
  };
  address?: string | null;
};

export const NavigateToButton = ({
  coordinate,
  address,
}: NavigateToButtonProps) => {
  const latLng = `${coordinate.latitude},${coordinate.longitude}`;
  const encodedAddress = address ? encodeURIComponent(address) : null;

  return (
    <Button
      variant="tertiary"
      fullWidth
      href={`https://www.google.com/maps/dir/?api=1&destination=${
        encodedAddress ?? latLng
      }`}
      target="_blank"
    >
      Navigate to
    </Button>
  );
};
