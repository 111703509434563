import { Container } from "@mui/material";
import { EditActivityForm } from "./components";
import { Stack } from "~/components/ui/stack";
import { useParams } from "react-router-dom";
import { Box } from "~/components/ui/box";
import { Spinner } from "~/components/ui/spinner";
import { tsr } from "~/utils/client";
import { BackButton } from "~/components/common/navigation";

export const EditActivityPage = () => {
  const params = useParams();
  const activityId = params.activityId as string;

  const { data, isLoading } = tsr.activities.get.useQuery({
    queryKey: ["activities", activityId],
    queryData: {
      params: {
        id: activityId,
      },
    },
  });

  return (
    <Container maxWidth="sm">
      <Stack gap="xlarge">
        <BackButton />
        {isLoading || !data?.body ? (
          <Box display="flex" align="center" justify="center">
            <Spinner />
          </Box>
        ) : (
          <EditActivityForm activity={data.body} />
        )}
      </Stack>
    </Container>
  );
};
