import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { ApiMessageSchema } from "./common";

const c = initContract();

const syncContract = c.router({
  push: {
    method: "POST",
    path: "/sync/push",
    body: z.object({
      type: z.enum(["organisation", "contact-group", "member", "job"]),
      data: z.string(),
    }),
    responses: {
      200: ApiMessageSchema.optional(),
    },
    summary: "Posts the organization structure to be synchronized",
    headers: z.object({
      Authorization: z.string().optional(),
    }),
  },
  capabilityUpdate: {
    method: "POST",
    path: "/sync/member-capabilities",
    body: z.object({
      memberId: z.string(),
      capabilities: z.array(
        z.object({
          id: z.string(),
          code: z.string(),
          name: z.string(),
        })
      ),
    }),
    responses: {
      200: ApiMessageSchema.optional(),
    },
    summary: "Posts member capability updates",
  },
});

export { syncContract };
