import { Button } from "~/components/ui/button";
import { DrawerFooter } from "~/components/ui/drawer";
import { Stack } from "~/components/ui/stack";
import { useRemoveActivityScheduleMembers } from "~/pages/requests/hooks";

type ManageActivityScheduleMembersFooterProps = {
  scheduleId: string;
  isClosed: boolean;
  selectedMemberIds: Array<string>;
  onSuccess: () => void;
  onAddMembersClick: () => void;
};

export const ManageActivityScheduleMembersFooter = ({
  scheduleId,
  isClosed,
  selectedMemberIds,
  onSuccess,
  onAddMembersClick,
}: ManageActivityScheduleMembersFooterProps) => {
  const { handleConfirmRemoveMembers, isPending } =
    useRemoveActivityScheduleMembers({
      scheduleId,
      confirmationText: `Please confirm that you'd like to remove ${
        selectedMemberIds.length > 1 ? "these members." : "this member."
      }`,
      onSuccess,
    });

  const isRemoveDisabled = isClosed || !selectedMemberIds.length;

  return (
    <DrawerFooter>
      <Stack direction="row" gap="medium">
        <Button
          fullWidth
          variant="destructive"
          busy={isPending}
          disabled={isRemoveDisabled}
          onClick={() => handleConfirmRemoveMembers(selectedMemberIds)}
        >
          {`Remove${!isRemoveDisabled ? ` (${selectedMemberIds.length})` : ""}`}
        </Button>
        <Button fullWidth disabled={isClosed} onClick={onAddMembersClick}>
          Add Members
        </Button>
      </Stack>
    </DrawerFooter>
  );
};
