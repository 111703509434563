import { ButtonBase } from "@mui/material";
import { OutOfAreaActivationRole } from "@ses-mams/api-contract";
import { formatOutOfAreaActivationRoleCategory } from "@ses-mams/react-utils";
import { Checkbox } from "~/components/ui/checkbox";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { useOutOfAreaActivationRolesQuery } from "~/hooks/useOutOfAreaActivationRolesQuery";

type SelectOutOfAreaRolesListProps = {
  roles: Array<OutOfAreaActivationRole>;
  removeRole: (role: OutOfAreaActivationRole) => void;
  appendRole: (role: OutOfAreaActivationRole) => void;
};

export const SelectOutOfAreaRolesList = ({
  roles,
  removeRole,
  appendRole,
}: SelectOutOfAreaRolesListProps) => {
  const { data } = useOutOfAreaActivationRolesQuery();

  return (
    <Stack>
      {(data?.body ?? []).map(role => {
        const isChecked = roles.some(({ id }) => id === role.id);

        return (
          <ButtonBase
            key={role.id}
            role="checkbox"
            aria-label={role.name}
            aria-checked={isChecked}
            onClick={() => (isChecked ? removeRole(role) : appendRole(role))}
          >
            <Stack
              direction="row"
              paddingY="medium"
              justify="space-between"
              align="center"
              gap="medium"
              sx={{ width: "100%" }}
            >
              <Stack flex={1} gap="medium" direction="row" align="center">
                <Checkbox checked={isChecked} />

                <Text align="start" size="large" weight="medium">
                  {role.name}
                </Text>
              </Stack>

              <Text tone="secondary" weight="medium">
                {formatOutOfAreaActivationRoleCategory(role.category)}
              </Text>
            </Stack>
          </ButtonBase>
        );
      })}
    </Stack>
  );
};
