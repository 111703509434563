import { useMemo } from "react";
import { startOfDay, endOfDay } from "date-fns";

import {
  formatDateForServer,
  useDebouncedSearch,
  useOutOfAreaActivationRequestListSections,
  useRequestListFiltersContext,
  useTransformPagedDataToItems,
} from "@ses-mams/react-utils";

import { tsr } from "~/utils/client";
import { DEFAULT_PAGE_SIZE, getNextPageParam } from "~/utils/pagination";

export const useOutOfAreaActivationListData = () => {
  const { searchValue, setSearchValue, debouncedSearchValue } =
    useDebouncedSearch("");

  const { filters } = useRequestListFiltersContext();

  const {
    urgentRequests,
    nonUrgentRequests,
    activationTimeRange,
    level,
    archivedOnly: archived,
    cluster,
    unit,
    zone,
  } = filters;

  const clusterId = cluster?.id;
  const unitId = unit?.id;
  const zoneId = zone?.id;

  const selectedTypes = useMemo(
    () => [
      ...(urgentRequests ? ["Urgent" as const] : []),
      ...(nonUrgentRequests ? ["NotUrgent" as const] : []),
    ],
    [urgentRequests, nonUrgentRequests]
  );

  const {
    isLoading,
    isRefetching,
    isFetchingNextPage,
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
  } = tsr.outOfAreaActivationRequests.list.useInfiniteQuery({
    queryKey: [
      "out-of-area-activation-requests",
      "list",
      debouncedSearchValue,
      selectedTypes,
      activationTimeRange.start,
      activationTimeRange.end,
      archived,
      level,
      clusterId,
      unitId,
      zoneId,
    ],
    queryData: ({ pageParam }) => ({
      query: {
        skip: pageParam?.skip ?? 0,
        take: pageParam?.take ?? DEFAULT_PAGE_SIZE,
        query: debouncedSearchValue,
        types: selectedTypes,
        startDate: activationTimeRange.start
          ? formatDateForServer(startOfDay(activationTimeRange.start))
          : undefined,
        endDate: activationTimeRange.end
          ? formatDateForServer(endOfDay(activationTimeRange.end))
          : undefined,
        archived,
        level: level ? level : undefined,
        clusterId,
        unitId,
        zoneId,
      },
    }),
    initialPageParam: { skip: 0, take: DEFAULT_PAGE_SIZE },
    getNextPageParam,
  });

  const sections = useOutOfAreaActivationRequestListSections(
    data?.pages,
    archived
  );

  const items = useTransformPagedDataToItems(data);

  return {
    data: items,
    sections,
    isLoading,
    isRefetching,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
    hasNextPage,
    filters: {
      searchValue,
      setSearchValue,
      archived,
    },
  };
};
