import {
  ActivationRequestWithAvailabilityCount,
  AvailabilityStatus,
  MemberStatus,
} from "@ses-mams/api-contract";

import { Row, Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { formatDateTime } from "@ses-mams/react-utils";
import { Badge } from "~/components/ui/badge";
import { BadgeTones } from "~/components/ui/badge/Badge";
import { AvailabilityReportCell } from "~/components/common/availabilityReportCell";
import { RequestCard } from "../../../shared";
import { Checkbox } from "~/components/ui/checkbox";

export const OperationalListItem = ({
  item,
  shouldDisplayStatus,
  isBulkSelecting,
  selectedIds,
  onBulkSelect,
}: {
  item: ActivationRequestWithAvailabilityCount;
  shouldDisplayStatus: boolean;
  isBulkSelecting: boolean;
  selectedIds: Set<string>;
  onBulkSelect: (id: string) => void;
}) => {
  const {
    activation: { type, title, unit, start, end, id },
    availabilityCounts: {
      activated,
      immediatelyAvailable,
      available,
      conditional,
      unavailable,
      unset,
    },
  } = item;

  const selected = selectedIds.has(id);

  return (
    <RequestCard
      to={`/requests/activations/${id}`}
      onClick={isBulkSelecting ? () => onBulkSelect(id) : undefined}
      selected={selected}
    >
      <Row sx={{ width: "100%" }} justify="space-between" align={"center"}>
        <Stack align="start" gap="medium">
          {type === "Urgent" ? (
            <Badge size="small" tone="criticalMuted">
              Urgent
            </Badge>
          ) : null}
          <Text
            weight="medium"
            tone={type === "Urgent" ? "critical" : "primary"}
          >
            {title}
          </Text>

          {start && (
            <Text size="standard" weight="regular">
              {unit.code ?? unit.name}
              {" from "}
              {formatDateTime(new Date(start))}{" "}
              {end && "to " + formatDateTime(new Date(end))}
            </Text>
          )}
          {shouldDisplayStatus && (item.status || item.activation.closedAt) && (
            <Badge tone={memberRequestStatusTone(item)} size="small">
              {formatMemberRequestStatus(item)}
            </Badge>
          )}

          <Stack flex={1} direction="row" gap="xxsmall">
            <AvailabilityReportCell status="Committed" value={activated} />
            {item.activation.type === "NotUrgent" && (
              <>
                <AvailabilityReportCell
                  status="ImmediatelyAvailable"
                  value={immediatelyAvailable}
                />
                <AvailabilityReportCell status="Available" value={available} />
                <AvailabilityReportCell
                  status="Conditional"
                  value={conditional}
                />
              </>
            )}
            <AvailabilityReportCell status="Unavailable" value={unavailable} />
            <AvailabilityReportCell status="Unset" value={unset} />
          </Stack>
        </Stack>
        {isBulkSelecting ? <Checkbox checked={selected} /> : null}
      </Row>
    </RequestCard>
  );
};

const memberRequestStatusTone = ({
  status,
  availabilityStatus,
  activation: { closedAt },
}: ActivationRequestWithAvailabilityCount): BadgeTones => {
  if (closedAt) {
    return "critical";
  }
  switch (status) {
    case "AvailabilitySubmitted":
      return (
        (availabilityStatus &&
          (
            {
              ImmediatelyAvailable: "positive",
              Available: "positiveLow",
              Conditional: "caution",
              Unavailable: "critical",
            } as Record<string, BadgeTones>
          )[availabilityStatus]) ??
        "criticalMuted"
      );
    case "ActivationDeclined":
      return "critical";
    default:
      return "muted";
  }
};

const formatMemberStatus = (status: MemberStatus) => {
  switch (status) {
    case "GoingToHQ":
      return "Going to HQ";
    case "GoingToJob":
      return "Going to Job";
  }
};

const formatAvailabilityStatus = (status: AvailabilityStatus) => {
  switch (status) {
    case "ImmediatelyAvailable":
      return "Immediately Available";
    default:
      return status;
  }
};

const formatMemberRequestStatus = ({
  status,
  availabilityStatus,
  memberStatus,
  activation: { closedAt },
}: ActivationRequestWithAvailabilityCount): string => {
  if (closedAt) {
    return "Archived";
  }
  switch (status) {
    case "AvailabilityRequested":
      return "Availability Requested";
    case "AvailabilitySubmitted":
      return availabilityStatus
        ? formatAvailabilityStatus(availabilityStatus)
        : "Availability Submitted";
    case "ActivationRequested":
      return "Activation Requested";
    case "ActivationAccepted":
      return memberStatus ? formatMemberStatus(memberStatus) : "Activated";
    case "ActivationDeclined":
      return "Activation Declined";
    default:
      return status;
  }
};
