import { Breakpoint } from "@mui/material/styles";

export type ResponsiveValue<T> = T | Partial<Record<Breakpoint, T>>;

export function mapResponsiveKeyToValue<Key extends keyof Scale, Scale>(
  key: Key | Partial<Record<Breakpoint, Key>> | undefined,
  scale: Scale
): Scale[Key] | ResponsiveValue<Scale[Key]> | undefined {
  if (typeof key === "undefined") {
    return undefined;
  }

  if (typeof key === "object") {
    const updatedValue: Partial<Record<Breakpoint, Scale[Key]>> = {};
    Object.keys(key).forEach(breakpoint => {
      const currentBreakpoint = breakpoint as Breakpoint;
      const keyValue = key[currentBreakpoint];

      if (typeof keyValue !== "undefined") {
        updatedValue[currentBreakpoint] = scale[keyValue];
      }
    });

    return updatedValue;
  }

  return scale[key];
}
