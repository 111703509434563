import { captureException } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { Switch } from "~/components/ui/switch";
import { Text } from "~/components/ui/text";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";

export const MuteNotificationsSwitch = () => {
  const { isLoading: isGettingSettings, data } =
    tsr.notifications.getSettings.useQuery({
      queryKey: ["notification-settings"],
    });

  const {
    isPending: isUpdatingSettings,
    onActivationNotificationsMutedChanged,
  } = useHandleUpdateSettings();

  return (
    <Stack gap="xsmall" direction="row" align="center">
      <Text size="large" weight="medium">
        Mute notifications
      </Text>

      {isGettingSettings || isUpdatingSettings ? (
        <Spinner size={24} />
      ) : (
        <Switch
          checked={data?.body?.activationNotificationsMuted}
          onChange={(_, checked: boolean) =>
            onActivationNotificationsMutedChanged(checked)
          }
        />
      )}
    </Stack>
  );
};

const useHandleUpdateSettings = () => {
  const queryClient = useQueryClient();

  const { addToast } = useToast();

  const { mutateAsync, isPending } =
    tsr.notifications.updateSettings.useMutation();

  const onActivationNotificationsMutedChanged = useCallback(
    async (checked: boolean) => {
      try {
        const result = await mutateAsync({
          body: { activationNotificationsMuted: checked },
        });

        queryClient.setQueryData(["notification-settings"], result);

        addToast({
          tone: "info",
          title: "System notification",
          message: checked
            ? "Notifications will be muted for 12 hours. To enable, toggle mute off."
            : "Notifications have been unmuted.",
        });
      } catch (error) {
        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    []
  );

  return {
    isPending,
    onActivationNotificationsMutedChanged,
  };
};
