import { captureException } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { ActivityMember } from "@ses-mams/api-contract";

import { useToast } from "~/components/ui/toast";
import { useShowConflictConfirmationDialog } from "~/hooks/useShowConflictConfirmationDialog";
import { tsr } from "~/utils/client";

export const useConfirmAttendance = (
  activityId: string,
  activityMembers: Array<ActivityMember>,
  onConfirmSuccess: () => void
) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: addActivityMembersMutation,
    isPending: isAddingActivityMembers,
  } = tsr.activities.addActivityMembers.useMutation();

  const {
    mutateAsync: confirmAttendanceMutation,
    isPending: isConfirmAttendanceLoading,
  } = tsr.activities.confirmAttendance.useMutation();

  const { addToast } = useToast();

  const { showConflictConfirmationDialog } = useShowConflictConfirmationDialog({
    type: "activity",
  });

  const confirmAttendance = useCallback(
    async (force = false) => {
      try {
        const memberIdsToBeReinvited = activityMembers
          .filter(m => m.status === "Removed")
          .map(m => m.member.id);

        if (memberIdsToBeReinvited.length > 0) {
          await addActivityMembersMutation({
            params: {
              activityId,
            },
            body: {
              contactGroupIds: [],
              groupIds: [],
              memberIds: memberIdsToBeReinvited,
            },
          });
        }

        const result = await confirmAttendanceMutation({
          params: {
            activityId,
          },
          body: {
            activityRequestIds: activityMembers
              .filter(m => m.status !== "Removed")
              .map(m => m.id),
            force,
          },
        });

        await Promise.all([
          queryClient.setQueryData(
            ["activities", activityId, "members", "", []],
            {
              pages: [
                {
                  status: result.status,
                  body: {
                    items: result.body,
                  },
                },
              ],
              pageParams: [null],
            }
          ),
          queryClient.invalidateQueries({
            queryKey: ["activities", activityId],
            exact: true,
          }),
          queryClient.invalidateQueries({
            queryKey: ["activity-requests"],
          }),
        ]);

        onConfirmSuccess();
      } catch (error) {
        const apiError = error as any;
        if (apiError?.status === 422) {
          showConflictConfirmationDialog({
            text: apiError.body.message,
            onConfirm: () => confirmAttendance(true),
          });
          return;
        }

        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    [activityId, activityMembers, onConfirmSuccess]
  );

  return {
    confirmAttendance,
    isLoading: isConfirmAttendanceLoading || isAddingActivityMembers,
  };
};
