import { ButtonBase } from "@mui/material";
import { useCallback, useState } from "react";
import { ActivationRequestMember, Member } from "@ses-mams/api-contract";
import {
  ActivationMembersSectionKeys,
  formatEta,
  formatPreActivatedEtaInMinutes,
  getActivationMemberSectionBackground,
  getActivationMemberSectionTitle,
  getBackgroundFromActivationMember,
  useActivationMemberEtas,
  useActivationRequestMemberSelectedState,
} from "@ses-mams/react-utils";
import { ShowMemberCapabilitiesToggle } from "~/components/common/showMemberCapabilitiesToggle";
import { Button, IconButton } from "~/components/ui/button";
import { DrawerContent, DrawerHeader } from "~/components/ui/drawer";
import { InfoCircleFilledIcon, PlusOutlineIcon } from "~/components/ui/icon";
import { Stack } from "~/components/ui/stack";
import { RenderSectionHeaderParams, SectionHeader } from "~/components/ui/list";
import { Checkbox } from "~/components/ui/checkbox";
import { MemberListItem } from "~/components/common/searchMembersList";
import { MemberDetailsDrawer } from "~/components/common/memberDetails";
import { Text } from "~/components/ui/text";
import { MemberAvailabilityStatusBadge } from "~/components/common/memberStatus";
import { useHasAnyRole } from "~/context/auth";
import { tsr } from "~/utils/client";
import {
  ActivationMemberList,
  useActivationMembersData,
} from "../ActivationMemberList";
import { ManageActivationMembersFooter } from "./ManageActivationMembersFooter";

const GOOGLE_PLACES_API_KEY = import.meta.env.VITE_GOOGLE_PLACES_API_KEY;

type ManageActivationMembersProps = {
  activationId: string;
  isFutureActivation: boolean;
  isClosed: boolean;
  isDrawerOpen: boolean;
  onClose: () => void;
  onAdd: () => void;
};

export const ManageActivationMembers = ({
  activationId,
  isFutureActivation,
  onAdd,
  isClosed,
  isDrawerOpen,
  onClose,
}: ManageActivationMembersProps) => {
  const hasCoordinatorRole = useHasAnyRole(["OperationalCoordinator"]);

  const [memberDetailsId, setMemberDetailsId] = useState<string>();

  const {
    selectAllState,
    setSelectAllState,
    setSelectedMembers,
    selectedForActivation,
    selectedForDeactivation,
    clearSelectedMembers,
    selectedMemberIds,
  } = useActivationRequestMemberSelectedState();

  const { data: { body: activation } = {} } = tsr.activations.get.useQuery({
    queryKey: ["activations", activationId],
    queryData: {
      params: {
        id: activationId,
      },
    },
  });

  const { sections, isLoading, data, hasNextPage, fetchNextPage } =
    useActivationMembersData(activationId);

  const activationMemberEtas = useActivationMemberEtas({
    activation,
    members: data,
    googlePlacesApiKey: GOOGLE_PLACES_API_KEY,
    // Small optimisation to only calculate the ETAs if the drawer is open
    enabled: isDrawerOpen,
  });

  const renderSectionHeader = useCallback(
    ({ title, data }: RenderSectionHeaderParams<ActivationRequestMember>) => {
      const sectionKey = title as ActivationMembersSectionKeys;
      const background = getActivationMemberSectionBackground(sectionKey);

      const isSelectAllChecked =
        selectAllState[sectionKey] &&
        data.every(m => selectedMemberIds.has(m.member.id));

      return (
        <ButtonBase
          onClick={() => {
            setSelectedMembers(prevMembers => {
              const prevIds = new Set(prevMembers.map(m => m.id));

              const hasUnselected = data.some(m => !prevIds.has(m.id));

              setSelectAllState(prevState => ({
                ...prevState,
                [sectionKey]: hasUnselected,
              }));

              if (hasUnselected) {
                return [...new Set([...prevMembers, ...data])];
              }

              return prevMembers.filter(p => !data.some(m => m.id === p.id));
            });
          }}
          sx={{ width: "100%" }}
        >
          <SectionHeader background={background}>
            <Stack direction="row" gap="small">
              {hasCoordinatorRole && <Checkbox checked={isSelectAllChecked} />}

              {getActivationMemberSectionTitle(
                sectionKey,
                isFutureActivation,
                data.length
              )}
            </Stack>
          </SectionHeader>
        </ButtonBase>
      );
    },
    [isFutureActivation, hasCoordinatorRole, selectAllState, selectedMemberIds]
  );

  const renderItem = useCallback(
    (item: ActivationRequestMember) => {
      const isChecked = selectedMemberIds.has(item.member.id);
      const background = getBackgroundFromActivationMember(item);

      const {
        availabilityStatus,
        conditionalReason,
        member,
        memberStatus,
        status,
      } = item;

      const memberEta = activationMemberEtas.get(item.id);

      return (
        <ButtonBase
          sx={theme => ({
            backgroundColor: theme.tokens.colors.background[background],
            paddingX: theme.tokens.spacing.large,
            width: "100%",
          })}
          onClick={() => {
            if (isChecked) {
              setSelectedMembers(prev => prev.filter(m => m.id !== item.id));
              return;
            }

            setSelectedMembers(prev => [...prev, item]);
          }}
        >
          <MemberListItem
            member={member as Member}
            startAdornment={<Checkbox checked={isChecked} />}
            memberStatus={memberStatus}
            endAdornment={
              <IconButton
                size="small"
                onClick={e => {
                  e.stopPropagation();

                  setMemberDetailsId(member.id);
                }}
              >
                <InfoCircleFilledIcon tone="info" size="xsmall" />
              </IconButton>
            }
          >
            {memberEta?.eta ? (
              <Text>
                ETA: {formatEta(memberEta.eta.value, memberEta.eta.isGps)}
              </Text>
            ) : memberEta?.preActivatedEtaInMinutes ? (
              <Text>
                ETA:{" "}
                {formatPreActivatedEtaInMinutes(
                  memberEta.preActivatedEtaInMinutes
                )}
              </Text>
            ) : null}

            {availabilityStatus === "Conditional" && conditionalReason && (
              <Text>"{conditionalReason}"</Text>
            )}

            {["AvailabilitySubmitted", "ActivationRequested"].includes(
              status
            ) &&
            availabilityStatus !== "Unavailable" &&
            member.availability?.committed ? (
              <MemberAvailabilityStatusBadge
                availability={member.availability}
              />
            ) : null}
          </MemberListItem>
        </ButtonBase>
      );
    },
    [selectedMemberIds, activationMemberEtas]
  );

  return (
    <>
      <DrawerHeader onClose={onClose}>Members</DrawerHeader>

      <DrawerContent sx={{ paddingX: 0 }}>
        <Stack gap="large">
          <Stack gap="large" paddingX="large">
            {hasCoordinatorRole && !isClosed && (
              <Button
                variant="tertiary"
                startIcon={PlusOutlineIcon}
                onClick={onAdd}
              >
                Add Members
              </Button>
            )}

            <ShowMemberCapabilitiesToggle />
          </Stack>

          <ActivationMemberList
            sections={sections}
            isLoading={isLoading}
            renderItem={renderItem}
            renderSectionHeader={renderSectionHeader}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
          />
        </Stack>
      </DrawerContent>

      {hasCoordinatorRole && (
        <ManageActivationMembersFooter
          activationId={activationId}
          isClosed={isClosed}
          isFutureActivation={isFutureActivation}
          selectedForActivation={selectedForActivation}
          selectedForDeactivation={selectedForDeactivation}
          clearSelectedMembers={clearSelectedMembers}
        />
      )}

      {memberDetailsId && (
        <MemberDetailsDrawer
          open={Boolean(memberDetailsId)}
          memberId={memberDetailsId}
          onClose={() => setMemberDetailsId(undefined)}
        />
      )}
    </>
  );
};
