import { CssBaseline, ThemeProvider } from "@mui/material";
import {
  experimental_extendTheme as extendTheme,
  Experimental_CssVarsProvider as CssVarsProvider,
} from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { theme as baseTheme } from "~/components/ui/theme";
import * as Sentry from "@sentry/react";

import App from "./App";
import "./index.css";

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const STAGE = import.meta.env.VITE_STAGE;

const theme = extendTheme({
  ...baseTheme,
  cssVarPrefix: "mams",
});

Sentry.init({
  dsn: SENTRY_DSN,
  environment: STAGE,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <CssVarsProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <CssBaseline />
          <Sentry.ErrorBoundary>
            <App />
          </Sentry.ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    </CssVarsProvider>
  </React.StrictMode>
);
