const white = "#ffffff";

const grey200 = "#F9F8F8";
const grey300 = "#F2F2F3";
const grey400 = "#E7E9EC";
const grey500 = "#CACDD1";
const grey600 = "#ACB1B8";
const grey700 = "#5C6773";
const grey800 = "#4A525C";
const grey1400 = "#090A0C";

const blue300 = "#EDF3F8";
const blue400 = "#DBE7F2";
const blue500 = "#AFC9DE";
const blue800 = "#246CAC";
const blue900 = "#004786";

const yellow300 = "#FBECCF";
const yellow600 = "#F3BE57";
const yellow700 = "#F8B124";
const yellow1000 = "#B07500";

const green500 = "#CBEAD6";
const green800 = "#70B34D";
const green900 = "#3BAA64";
const green1000 = "#1A9247";
const green1100 = "#157639";

const red700 = "#F64A4A";
const red800 = "#EB1717";
const red900 = "#CA1414";

const purple300 = "#5F11C326";
const purple500 = "#5F11C3";

const info = blue800;
const positive = green1000;
const caution = yellow700;
const critical = red800;
const criticalMuted = "#FEDDDD";
const accent = blue800;
const disabled = grey800;

const fontFamily = {
  regular: "Inter-Regular",
  medium: "Inter-Medium",
  semi: "Inter-SemiBold",
};

export const tokens = {
  typography: {
    fontFamily,
  },
  border: {
    radius: {
      xsmall: 2,
      small: 4,
      medium: 6,
      large: 8,
      full: 9999,
    },
    width: {
      medium: 1,
      large: 2,
      xlarge: 4,
    },
    color: {
      neutral: grey700,
      positive: green1100,
      positiveLow: green900,
      caution: yellow1000,
      critical: red900,
      standard: grey400,
      accent,
      field: grey500,
      muted: grey200,
      disabled,
      info,
      action: blue800,
      infoMuted: blue300,
      blended: "rgba(0,0,0,0.25)",
      inverted: white,
      tableCell: grey700,
    },
  },
  colors: {
    foreground: {
      positive,
      caution,
      critical,
      criticalMuted,
      info: blue900,
      primary: grey1400,
      secondary: grey700,
      muted: grey700,
      placeholder: grey700,
      inverted: white,
      accent,
      action: blue800,
      disabled,
      accentSecondary: purple500,
    },
    background: {
      positive,
      positiveLow: green800,
      positiveMuted: green500,
      positiveSubtle: "#B3DBC2",
      caution,
      cautionLow: yellow600,
      cautionMuted: yellow300,
      cautionSubtle: "#FDE5B7",
      critical,
      criticalLow: red700,
      criticalMuted,
      criticalSubtle: "#F8B2B2",
      info,
      infoMuted: blue300,
      infoSubtle: "#B7CEE4",
      surface: white,
      surfaceMuted: grey200,
      surfaceLow: grey500,
      surfaceSubtle: "#EEEFF0",
      accent,
      accentMuted: blue400,
      neutral: grey600,
      backdrop: "rgba(9, 10, 12, 0.40)",
      card: grey400,
      tableGutter: grey500,
      disabled,
      muted: grey300,
      accentSecondaryMuted: purple300,
      accentSecondary: purple500,
    },
    backgroundInteraction: {
      primaryHover: blue900,
      primaryActive: blue800,
      secondaryHover: white,
      secondaryActive: white,
      tertiaryHover: blue400,
      tertiaryActive: blue500,
      destructiveHover: white,
      destructiveActive: white,
    },
  },
  shadow: {
    medium: {
      shadowColor: "#000000",
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 0.27,
      shadowRadius: 4.65,
      elevation: 6,
    },
  },
};

export type Tokens = typeof tokens;
