import { FormControl, useForkRef, useTheme } from "@mui/material";
import {
  TimeField as MuiTimeField,
  TimeFieldProps as MuiTimeFieldProps,
} from "@mui/x-date-pickers";
import {
  forwardRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { isValid, parse } from "date-fns";

import {
  TWENTY_FOUR_HOUR_FORMAT,
  formatTwentyFourHourTime,
} from "@ses-mams/react-utils";

import { useId } from "~/hooks/useId";
import { inputBaseStyles, Label } from "../textField";

type TimeFieldProps = Pick<
  MuiTimeFieldProps<Date>,
  "id" | "ref" | "disabled" | "onBlur"
> & {
  value: string;
  label: string;
  hideLabel?: boolean;
  errorMessage?: string;
  onChange: (time: string) => void;
};

/**
 * Time field component that takes a string in the format hh:mm and returns the latest value in the
 * same format.
 */
export const TimeField = forwardRef<HTMLInputElement, TimeFieldProps>(
  (
    {
      hideLabel,
      label,
      errorMessage,
      disabled,
      value: valueProp,
      onChange,
      ...inputProps
    },
    forwardedRef
  ) => {
    const id = useId(inputProps.id);
    const styles = useTimeFieldStyles(hideLabel);
    const inputRef = useRef<HTMLInputElement>(null);

    const forkedRef = useForkRef(forwardedRef, inputRef);

    // If parse returns null, it means the passed in valueProp is an invalid date. In that case,
    // explicitly pass an invalid date object to Material UI's TimeField so it displays whatever input
    // the user has entered so far. For instance, removing the hour or minute component should
    // keep the input as, say, hh:00 or 09:mm, without clearing it completely
    const value = useMemo(
      () =>
        valueProp
          ? (parse(valueProp, TWENTY_FOUR_HOUR_FORMAT, new Date()) ??
            new Date("Invalid date"))
          : null,
      [valueProp]
    );

    const handleChange = useCallback(
      (date: Date | null) => {
        if (!onChange) {
          return;
        }

        if (!date) {
          onChange("");
          return;
        }

        if (isValid(date)) {
          onChange(formatTwentyFourHourTime(date));
          return;
        }

        const inputValue = inputRef.current?.value as string;
        const [hh, mm] = inputValue.split(":");

        onChange(`${hh || "hh"}:${mm || "mm"}`);
      },
      [onChange]
    );

    // NOTE: by default, Material UI's TimeField component internally sets aria-invalid to true only if
    // the input is invalid (not a date). It also doesn't take an "invalid" prop, which means that custom
    // errors are not properly supported. This is a bit of a hack to ensure the input is properly flagged as
    // invalid in case of any errors
    useLayoutEffect(() => {
      inputRef.current?.setAttribute(
        "aria-invalid",
        Boolean(errorMessage).toString()
      );
    }, [errorMessage]);

    return (
      <>
        {!hideLabel && (
          <Label htmlFor={id} disabled={disabled}>
            {label}
          </Label>
        )}
        <FormControl variant="standard" fullWidth>
          <MuiTimeField
            {...inputProps}
            inputRef={forkedRef}
            value={value}
            onChange={handleChange}
            fullWidth
            id={id}
            helperText={errorMessage}
            disabled={disabled}
            hiddenLabel
            aria-label={label}
            format={TWENTY_FOUR_HOUR_FORMAT}
            sx={styles}
          />
        </FormControl>
      </>
    );
  }
);

const useTimeFieldStyles = (hideLabel?: boolean) => {
  const theme = useTheme();

  const baseStyles = inputBaseStyles(theme, hideLabel);
  const inputStyles = baseStyles["& .MuiInputBase-input"];

  return {
    ...baseStyles,
    ".MuiInputBase-root": {
      "&.MuiOutlinedInput-root": {
        paddingRight: "unset",
      },
      "&:not(.Mui-focused)": {
        '&:hover .MuiInputBase-input[aria-invalid="false"] + .MuiOutlinedInput-notchedOutline':
          {
            borderColor: inputStyles.borderColor,
          },
      },
      "&.Mui-focused": {
        ".MuiOutlinedInput-notchedOutline": {
          border: inputStyles.border,
          borderColor: inputStyles["&:focus"].borderColor,
        },
      },
    },
    ".MuiFormHelperText-root": {
      color: theme.tokens.colors.foreground.critical,
    },
    "& .MuiOutlinedInput-notchedOutline": inputStyles,
    '& .MuiInputBase-input[aria-invalid="true"] + .MuiOutlinedInput-notchedOutline':
      {
        borderColor: theme.tokens.colors.foreground.critical,
      },
  };
};
