import { CircularProgress, List, ListItem } from "@mui/material";
import React from "react";

import { Box } from "../box";
import { InfiniteScroll } from "../infiniteScroll";
import { Text } from "~/components/ui/text";

type Props<T> = {
  data: T[];
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  renderItem: (item: T) => React.ReactElement | null;
  keyExtractor: (item: T, index: number) => string | number;
  listEmptyElement?: React.ReactNode;
  listItemDivider?: boolean;
};

export const InfiniteList = <T,>({
  data,
  fetchNextPage,
  hasNextPage = false,
  renderItem,
  keyExtractor,
  listEmptyElement = null,
  listItemDivider = true,
}: Props<T>) => (
  <InfiniteScroll
    loadMore={fetchNextPage}
    hasMore={hasNextPage}
    loader={
      <Box display="flex" align="center" justify="center">
        <CircularProgress />
      </Box>
    }
    endMessage={
      data.length > 0 ? (
        <Text as="p" align="center" tone="secondary">
          No more results
        </Text>
      ) : undefined
    }
  >
    {data.length > 0 ? (
      <List disablePadding>
        {data.map((item, index) => (
          <ListItem
            key={keyExtractor(item, index)}
            disablePadding
            divider={listItemDivider}
          >
            {renderItem(item)}
          </ListItem>
        ))}
      </List>
    ) : (
      listEmptyElement
    )}
  </InfiniteScroll>
);
