import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  useTheme,
} from "@mui/material";
import { forwardRef } from "react";
import { CheckOutlineIcon } from "~/components/ui/icon";
import { Box } from "~/components/ui/box";

export type CheckboxProps = MuiCheckboxProps;

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, forwardedRef) => {
    const {
      tokens: { colors },
    } = useTheme();

    return (
      <MuiCheckbox
        {...props}
        inputRef={forwardedRef}
        disableRipple
        checkedIcon={<CheckboxPrimitive checked />}
        icon={<CheckboxPrimitive />}
        sx={{
          padding: 0,
          "&.Mui-checked": {
            color: colors.background.accent,
          },
          ":disabled": {
            cursor: "not-allowed",
            color: colors.background.disabled,
          },
        }}
      />
    );
  }
);

const CheckboxPrimitive = ({
  checked,
  disabled,
}: {
  checked?: boolean;
  disabled?: boolean;
}) => {
  return (
    <Box
      display="flex"
      align="center"
      justify="center"
      background={disabled ? "muted" : checked ? "accent" : undefined}
      border={checked && !disabled ? "accent" : "neutral"}
      borderWidth="large"
      borderRadius="small"
      height="xxsmall"
      width="xxsmall"
    >
      {checked && <CheckOutlineIcon tone="inverted" />}
    </Box>
  );
};
