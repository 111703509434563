import { ButtonBase, Menu, MenuItem, styled, useTheme } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { Stack } from "~/components/ui/stack";
import { DEFAULT_AUTH_ROUTE } from "~/context/auth/constants";

import { CurrentUserMenu } from "./CurrentUserMenu";
import { GlobalUnitSelector } from "./GlobalUnitSelector";
import { NavLink, useNavLinks } from "~/components/common/navigation";
import { MemberAvailabilityUnitSelector } from "./MemberAvailabilityUnitSelector";
import { OtherMemberAvailabilityUnitSelector } from "./OtherMemberAvailabilityUnitSelector";

import sesIconURL from "~/assets/SESIcon.png";
import { useState, useCallback } from "react";
import { useHasAnyRole } from "~/context/auth";
import React from "react";
import { SettingsFilledIcon } from "~/components/ui/icon";
import { MenuSection } from "~/components/ui/menu";

export const Header = () => {
  const location = useLocation();
  const showUnitSelector = ![
    "/admin",
    "/availability",
    "/requests",
    "/messages",
  ].some(route => location.pathname?.includes(route));

  const navLinks = useNavLinks();

  const {
    tokens: {
      border: { radius },
    },
  } = useTheme();

  return (
    <>
      <header>
        <Stack
          direction="row"
          borderBottom="standard"
          borderBottomWidth="medium"
          align="center"
          justify="space-between"
          paddingX="large"
          sx={{ height: "64px", width: "100%" }}
          columnGap={{ xs: "small", md: "medium" }}
        >
          <Stack
            direction="row"
            gap="medium"
            dividers
            dividerTone="standard"
            align="center"
            sx={{ minWidth: "300px" }}
          >
            <StyledLogoLink to={DEFAULT_AUTH_ROUTE}>
              <img
                src={sesIconURL}
                style={{ maxWidth: 55, borderRadius: radius.medium }}
              />
            </StyledLogoLink>

            {showUnitSelector && <GlobalUnitSelector />}

            {/* Member's unit selector when setting their availability */}
            {location.pathname?.includes("/availability") ? (
              location.pathname.includes("/availability/member") ? (
                <OtherMemberAvailabilityUnitSelector />
              ) : (
                <MemberAvailabilityUnitSelector />
              )
            ) : null}
          </Stack>

          <StyledNav>
            {navLinks}
            <AdmineNavLinks />
          </StyledNav>

          <CurrentUserMenu />
        </Stack>
      </header>
    </>
  );
};

const AdmineNavLinks = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const hasManageUsersRole = useHasAnyRole(["Administrator"]);
  const hasBroadcastRole = useHasAnyRole(["SystemAdministrator"]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  if (hasManageUsersRole || hasBroadcastRole) {
    return (
      <React.Fragment key="admin">
        <ButtonBase
          onClick={handleClick}
          aria-controls={open ? MENU_ID : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <NavLink key="users" icon={SettingsFilledIcon} to="/admin" noLink>
            Admin
          </NavLink>
        </ButtonBase>
        <Menu
          disableScrollLock
          anchorEl={anchorEl}
          id={MENU_ID}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        >
          <MenuSection>
            {hasManageUsersRole && (
              <MenuItem>
                <NavLink to="/admin/users">Users</NavLink>
              </MenuItem>
            )}

            {hasBroadcastRole && (
              <MenuItem>
                <NavLink to="/admin/broadcast">Broadcast</NavLink>
              </MenuItem>
            )}
          </MenuSection>
        </Menu>
      </React.Fragment>
    );
  }

  return null;
};

const StyledLogoLink = styled(RouterLink)({
  display: "flex",
  alignItems: "center",
});

const StyledNav = styled("nav")(({ theme }) => ({
  display: "none",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.tokens.spacing.xxlarge,
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const MENU_ID = "admin-menu";
