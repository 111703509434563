import { initContract } from "@ts-rest/core";
import {
  MemberSummarySchema,
  PaginationQueryParamsSchema,
  ServerDateSchema,
  getPaginationResponseSchema,
} from "./common";
import { z } from "zod";
import { OutOfAreaActivationLevelSchema } from "./outOfAreaActivation";

const c = initContract();

const AuditLogSchema = z.object({
  id: z.string(),
  details: z.string().nullable(),
  action: z.string(),
  createdAt: ServerDateSchema.nullable(),
  member: MemberSummarySchema.nullable(),
});

const AuditLogQueryShemaShape = {
  activationName: z.string().optional(),
  activationId: z.coerce.string().optional(),
  activityName: z.string().optional(),
  activityId: z.coerce.string().optional(),
  ooaaActivationName: z.string().optional(),
  ooaaActivationId: z.coerce.string().optional(),
  actions: z.array(z.string()).optional(),
  details: z.coerce.string().optional(),
  memberId: z.coerce.string().optional(),
  memberName: z.string().optional(),
  minRequestCreatedAt: z.string().datetime().optional(),
  maxRequestCreatedAt: z.string().datetime().optional(),
  unitId: z.coerce.string().optional(),
  outOfAreaActivationId: z.string().optional(),
  levelOfOoaaRequest: z.optional(OutOfAreaActivationLevelSchema),
  ooaaRequestActivatedForId: z.coerce.string().optional(),
};

export const auditContract = c.router({
  list: {
    method: "GET",
    path: "/audit-logs",
    query: PaginationQueryParamsSchema.extend(AuditLogQueryShemaShape),
    responses: {
      200: getPaginationResponseSchema(AuditLogSchema),
    },
    summary: "Get all audit logs based on parameters",
  },
  downloadCsv: {
    method: "POST",
    path: "/audit-logs/download",
    body: z.object(AuditLogQueryShemaShape),
    responses: {
      200: z.object({
        downloadUrl: z.string(),
        maxRowsExceeded: z.boolean(),
      }),
    },
  },
});

export type AuditLog = z.infer<typeof AuditLogSchema>;
