import {
  Popover as MuiPopover,
  PopoverProps as MuiPopoverProps,
} from "@mui/material";
import { usePopoverContext } from "./PopoverRoot";
import { popoverStyles } from "./popoverStyles";

export type PopoverProps = Omit<MuiPopoverProps, "open">;

export const Popover = ({
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
  sx,
  ...props
}: PopoverProps) => {
  const { open, triggerRef, onOpenChange } = usePopoverContext();

  return (
    <MuiPopover
      {...props}
      open={open}
      anchorEl={triggerRef.current}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={() => onOpenChange(false)}
      sx={[
        theme => ({
          ".MuiPaper-root": popoverStyles(theme),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};
