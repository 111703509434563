import { ButtonBase } from "@mui/material";
import { useCallback, useState } from "react";
import { ActivityMember, Member } from "@ses-mams/api-contract";
import {
  ActivityMembersSectionKeys,
  getActivityMemberSectionBackground,
  getActivityMemberSectionTitle,
  getBackgroundFromActivityMember,
  useActivityMemberSelectedState,
} from "@ses-mams/react-utils";
import { ShowMemberCapabilitiesToggle } from "~/components/common/showMemberCapabilitiesToggle";
import { Button, IconButton } from "~/components/ui/button";
import { DrawerContent, DrawerHeader } from "~/components/ui/drawer";
import { InfoCircleFilledIcon, PlusOutlineIcon } from "~/components/ui/icon";
import { Stack } from "~/components/ui/stack";
import { RenderSectionHeaderParams, SectionHeader } from "~/components/ui/list";
import { Checkbox } from "~/components/ui/checkbox";
import { MemberListItem } from "~/components/common/searchMembersList";
import { MemberDetailsDrawer } from "~/components/common/memberDetails";
import { Text } from "~/components/ui/text";
import { MemberAvailabilityStatusBadge } from "~/components/common/memberStatus";
import { useHasAnyRole } from "~/context/auth";
import { ActivityMemberList } from "../ActivityMemberList";
import { ManageActivityMembersFooter } from "./ManageActivityMembersFooter";

type ManageActivityMembersProps = {
  activityId: string;
  isClosed: boolean;
  onClose: () => void;
  onAdd: () => void;
};

export const ManageActivityMembers = ({
  activityId,
  onAdd,
  isClosed,
  onClose,
}: ManageActivityMembersProps) => {
  const hasCoordinatorRole = useHasAnyRole(["ActivityCoordinator"]);

  const [memberDetailsId, setMemberDetailsId] = useState<string>();

  const {
    selectAllState,
    setSelectAllState,
    setSelectedMembers,
    selectedForConfirmation,
    clearSelectedMembers,
    selectedMemberIds,
  } = useActivityMemberSelectedState();

  const renderSectionHeader = useCallback(
    ({ title, data }: RenderSectionHeaderParams<ActivityMember>) => {
      const sectionKey = title as ActivityMembersSectionKeys;
      const background = getActivityMemberSectionBackground(sectionKey);

      const isSelectAllChecked =
        selectAllState[sectionKey] &&
        data.every(m => selectedMemberIds.has(m.member.id));

      return (
        <ButtonBase
          onClick={() => {
            setSelectedMembers(prevMembers => {
              const prevIds = new Set(prevMembers.map(m => m.id));

              const hasUnselected = data.some(m => !prevIds.has(m.id));

              setSelectAllState(prevState => ({
                ...prevState,
                [sectionKey]: hasUnselected,
              }));

              if (hasUnselected) {
                return [...new Set([...prevMembers, ...data])];
              }

              return prevMembers.filter(p => !data.some(m => m.id === p.id));
            });
          }}
          sx={{ width: "100%" }}
        >
          <SectionHeader background={background}>
            <Stack direction="row" gap="small">
              {hasCoordinatorRole && <Checkbox checked={isSelectAllChecked} />}

              {getActivityMemberSectionTitle(sectionKey, data.length)}
            </Stack>
          </SectionHeader>
        </ButtonBase>
      );
    },
    [hasCoordinatorRole, selectAllState, selectedMemberIds]
  );

  const renderItem = useCallback(
    (item: ActivityMember) => {
      const isChecked = selectedMemberIds.has(item.member.id);
      const background = getBackgroundFromActivityMember(item);

      const { availabilityStatus, conditionalReason, member, status } = item;

      return (
        <ButtonBase
          sx={theme => ({
            backgroundColor: theme.tokens.colors.background[background],
            paddingX: theme.tokens.spacing.large,
            width: "100%",
          })}
          onClick={() => {
            if (isChecked) {
              setSelectedMembers(prev => prev.filter(m => m.id !== item.id));
              return;
            }

            setSelectedMembers(prev => [...prev, item]);
          }}
        >
          <MemberListItem
            member={member as Member}
            startAdornment={<Checkbox checked={isChecked} />}
            endAdornment={
              <IconButton
                size="small"
                onClick={e => {
                  e.stopPropagation();

                  setMemberDetailsId(member.id);
                }}
              >
                <InfoCircleFilledIcon tone="info" size="xsmall" />
              </IconButton>
            }
          >
            {availabilityStatus === "Conditional" && conditionalReason && (
              <Text>"{conditionalReason}"</Text>
            )}
            {["AvailabilitySubmitted"].includes(status) &&
            availabilityStatus !== "Unavailable" &&
            member.availability?.committed ? (
              <MemberAvailabilityStatusBadge
                availability={member.availability}
              />
            ) : null}
          </MemberListItem>
        </ButtonBase>
      );
    },
    [selectedMemberIds]
  );

  return (
    <>
      <DrawerHeader onClose={onClose}>Members</DrawerHeader>

      <DrawerContent sx={{ paddingX: 0 }}>
        <Stack gap="large">
          <Stack gap="large" paddingX="large">
            {hasCoordinatorRole && !isClosed && (
              <Button
                variant="tertiary"
                startIcon={PlusOutlineIcon}
                onClick={onAdd}
              >
                Add Members
              </Button>
            )}

            <ShowMemberCapabilitiesToggle />
          </Stack>

          <ActivityMemberList
            activityId={activityId}
            renderItem={renderItem}
            renderSectionHeader={renderSectionHeader}
          />
        </Stack>
      </DrawerContent>

      {hasCoordinatorRole && (
        <ManageActivityMembersFooter
          activityId={activityId}
          isClosed={isClosed}
          selectedForConfirmation={selectedForConfirmation}
          selectedIdsForRemoval={selectedMemberIds}
          clearSelectedMembers={clearSelectedMembers}
        />
      )}

      {memberDetailsId && (
        <MemberDetailsDrawer
          open={Boolean(memberDetailsId)}
          memberId={memberDetailsId}
          onClose={() => setMemberDetailsId(undefined)}
        />
      )}
    </>
  );
};
