import {
  OutOfAreaActivationRequestStatus,
  OutOfAreaActivationRole,
} from "@ses-mams/api-contract";
import { useCallback, useState } from "react";
import {
  FilterPopover,
  FilterPopoverFooter,
  FilterPopoverHeader,
} from "~/components/common/filterPopover";
import { SelectField, SelectItem } from "~/components/ui/selectField";
import { useHasAnyRole } from "~/context/auth";
import { SelectZonesDrawer } from "~/components/common/selectZonesList";
import { Stack } from "~/components/ui/stack";
import { ZoneListItem } from "~/components/common/zone";
import { ClusterListItem } from "~/components/common/cluster";
import { UnitListItem } from "~/components/common/unit";
import { SelectClustersDrawer } from "~/components/common/selectClustersList";
import { SelectUnitsDrawer } from "~/components/common/selectUnitsList";
import { EditListSection } from "~/components/common/editListSection";
import { useApprovalQueueFiltersContext } from "./ApprovalQueueFiltersContext";
import { useSelectRolesDrawer } from "~/components/common/selectRolesDrawer";
import { Text } from "~/components/ui/text";

export const ApprovalQueueFilters = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [isSelectZonesDrawerOpen, setIsSelectZonesDrawerOpen] = useState(false);
  const [isSelectClustersDrawerOpen, setIsSelectClustersDrawerOpen] =
    useState(false);
  const [isSelectUnitsDrawerOpen, setIsSelectUnitsDrawerOpen] = useState(false);

  const isClusterApprover = useHasAnyRole(["ClusterApprover"]);
  const isZoneApprover = useHasAnyRole(["ZoneApprover"]);

  const {
    status,
    roles,
    units,
    clusters,
    zones,
    setStatus,
    setRoles,
    setUnits,
    setClusters,
    setZones,
    getInitialFilters,
  } = useApprovalQueueFiltersContext();

  const [internalFilters, setInternalFilters] = useState({
    status,
    roles,
    units,
    clusters,
    zones,
  });

  const handleStatusChange = useCallback(
    (status: OutOfAreaActivationRequestStatus) => {
      setInternalFilters(prev => ({
        ...prev,
        status,
      }));
    },
    []
  );

  const handleRolesSelected = useCallback(
    (roles: Array<OutOfAreaActivationRole>) => {
      setInternalFilters(prev => ({ ...prev, roles }));
    },
    []
  );

  const { openDrawer: openRolesDrawer, renderDrawer: renderRolesDrawer } =
    useSelectRolesDrawer(handleRolesSelected);

  const handleClear = useCallback(() => {
    setInternalFilters(getInitialFilters());
  }, [getInitialFilters]);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      setStatus(internalFilters.status);
      setRoles(internalFilters.roles);
      setUnits(
        internalFilters.status === "UnitApprovalPending"
          ? internalFilters.units
          : []
      );
      setClusters(
        internalFilters.status === "ClusterApprovalPending"
          ? internalFilters.clusters
          : []
      );
      setZones(
        internalFilters.status === "ZoneApprovalPending"
          ? internalFilters.zones
          : []
      );

      setIsOpen(false);
    },
    [internalFilters]
  );

  const handleOpenChange = useCallback(
    (nextIsOpen: boolean) => {
      setIsOpen(nextIsOpen);

      if (!nextIsOpen) {
        setInternalFilters({
          status,
          roles,
          units,
          clusters,
          zones,
        });
      }
    },
    [status, units, clusters, zones]
  );

  return (
    <FilterPopover
      open={isOpen}
      onOpenChange={handleOpenChange}
      // Ensure that the select drawers appear on top of the popover
      sx={theme => ({ zIndex: theme.zIndex.drawer - 1 })}
    >
      <form onSubmit={handleSubmit}>
        <FilterPopoverHeader onClear={handleClear} />

        <Stack gap="large">
          <SelectField
            label="Approval Status"
            value={internalFilters.status}
            onChange={e =>
              handleStatusChange(
                e.target.value as OutOfAreaActivationRequestStatus
              )
            }
          >
            {isZoneApprover && (
              <SelectItem value="ZoneApprovalPending">
                {statusToLabel.ZoneApprovalPending}
              </SelectItem>
            )}
            {(isZoneApprover || isClusterApprover) && (
              <SelectItem value="ClusterApprovalPending">
                {statusToLabel.ClusterApprovalPending}
              </SelectItem>
            )}
            <SelectItem value="UnitApprovalPending">
              {statusToLabel.UnitApprovalPending}
            </SelectItem>
          </SelectField>

          <EditListSection
            title="Roles"
            onButtonClick={() => openRolesDrawer(internalFilters.roles)}
          >
            {!!internalFilters.roles.length && (
              <Stack align="flex-start" sx={{ width: "100%" }}>
                <Text weight="medium" align="left">
                  {internalFilters.roles.map(role => role.name).join(", ")}
                </Text>
              </Stack>
            )}
          </EditListSection>

          {internalFilters.status === "ZoneApprovalPending" && (
            <EditListSection
              title="Zones"
              onButtonClick={() => setIsSelectZonesDrawerOpen(true)}
            >
              {internalFilters.zones.map(zone => {
                return <ZoneListItem key={zone.id} zone={zone} />;
              })}
            </EditListSection>
          )}
          {internalFilters.status === "ClusterApprovalPending" && (
            <EditListSection
              title="Clusters"
              onButtonClick={() => setIsSelectClustersDrawerOpen(true)}
            >
              {internalFilters.clusters.map(cluster => {
                return <ClusterListItem key={cluster.id} cluster={cluster} />;
              })}
            </EditListSection>
          )}
          {internalFilters.status === "UnitApprovalPending" && (
            <EditListSection
              title="Units"
              onButtonClick={() => setIsSelectUnitsDrawerOpen(true)}
            >
              {internalFilters.units.map(unit => {
                return <UnitListItem key={unit.id} unit={unit} />;
              })}
            </EditListSection>
          )}
        </Stack>

        <FilterPopoverFooter onClose={() => handleOpenChange(false)} />
      </form>

      {renderRolesDrawer()}

      <SelectZonesDrawer
        open={isSelectZonesDrawerOpen}
        zones={internalFilters.zones}
        onClose={() => setIsSelectZonesDrawerOpen(false)}
        onDone={value =>
          setInternalFilters(prev => ({ ...prev, zones: value }))
        }
      />

      <SelectClustersDrawer
        open={isSelectClustersDrawerOpen}
        clusters={internalFilters.clusters}
        onClose={() => setIsSelectClustersDrawerOpen(false)}
        onDone={value =>
          setInternalFilters(prev => ({ ...prev, clusters: value }))
        }
      />

      <SelectUnitsDrawer
        open={isSelectUnitsDrawerOpen}
        units={internalFilters.units}
        onClose={() => setIsSelectUnitsDrawerOpen(false)}
        onDone={value =>
          setInternalFilters(prev => ({ ...prev, units: value }))
        }
      />
    </FilterPopover>
  );
};

const statusToLabel = {
  UnitApprovalPending: "Pending Unit Recommendation",
  ClusterApprovalPending: "Pending Cluster Recommendation",
  ZoneApprovalPending: "Pending Zone Recommendation",
} as const satisfies Partial<Record<OutOfAreaActivationRequestStatus, string>>;
