import { ButtonBase } from "@mui/material";
import { Unit } from "@ses-mams/api-contract";
import {
  getTotalCountFromPages,
  useDebouncedSearch,
} from "@ses-mams/react-utils";
import { useCallback, useMemo } from "react";
import { Box } from "~/components/ui/box";
import { InfiniteSectionList } from "~/components/ui/list";
import { SearchInput } from "~/components/ui/searchInput";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { useAuth } from "~/context/auth";
import { tsr } from "~/utils/client";
import { DEFAULT_PAGE_SIZE, getNextPageParam } from "~/utils/pagination";

type SelectUnitListProps = {
  onUnitSelected: (unit: Unit) => void;
};

export const SelectUnitList = ({ onUnitSelected }: SelectUnitListProps) => {
  const { searchValue, setSearchValue, debouncedSearchValue } =
    useDebouncedSearch("");

  const { isLoading, sections, hasNextPage, fetchNextPage } =
    useUnitListData(debouncedSearchValue);

  const renderItem = useCallback(
    (unit: Unit) => (
      <ButtonBase onClick={() => onUnitSelected(unit)}>
        <Stack flex={1} gap="xsmall" align="start" paddingY="medium">
          <Text size="xlarge" weight="medium">
            {`${unit.name}${unit.code ? ` (${unit.code})` : ""}`}
          </Text>
          {unit.address && <Text tone="secondary">{unit.address}</Text>}
        </Stack>
      </ButtonBase>
    ),
    [onUnitSelected]
  );

  return (
    <Stack flex={1} gap="large">
      <SearchInput
        placeholder="Search"
        value={searchValue}
        onValueChange={setSearchValue}
        onSubmit={e => e.stopPropagation()}
      />

      {isLoading ? (
        <Box display="flex" justify="center" sx={{ height: "100%" }}>
          <Spinner />
        </Box>
      ) : (
        <InfiniteSectionList
          sections={sections}
          keyExtractor={item => item.id}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          renderItem={renderItem}
        />
      )}
    </Stack>
  );
};

type UnitItemSectionLabel = "My Units" | "All Units";

const useUnitListData = (searchValue: string) => {
  const { member: { units: memberUnits = [] } = {} } = useAuth();

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    tsr.units.list.useInfiniteQuery({
      queryKey: ["units", searchValue],
      queryData: ({ pageParam = { skip: 0, take: DEFAULT_PAGE_SIZE } }) => ({
        query: {
          skip: pageParam.skip,
          take: pageParam.take,
          query: searchValue,
        },
      }),
      initialPageParam: { skip: 0, take: DEFAULT_PAGE_SIZE },
      getNextPageParam,
    });

  const sections = useMemo(() => {
    const sectionMap: Record<UnitItemSectionLabel, Unit[]> = {
      "My Units": [],
      "All Units": [],
    };

    (data?.pages || []).flatMap(page => {
      if (page.status !== 200) {
        return;
      }

      page.body.items.forEach(item => {
        if (memberUnits.some(({ unit }) => item.id === unit.id)) {
          sectionMap["My Units"].push(item);
        } else {
          sectionMap["All Units"].push(item);
        }
      });
    });

    return Object.keys(sectionMap).flatMap(key => {
      const typedKey = key as keyof typeof sectionMap;
      const keyedData = sectionMap[typedKey];

      if (!keyedData.length) {
        return [];
      }

      return [
        {
          title: typedKey,
          data: keyedData,
        },
      ];
    });
  }, [data?.pages, memberUnits]);

  const totalCount = getTotalCountFromPages(data?.pages);

  return {
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    sections,
    totalCount,
    fetchNextPage,
  };
};
