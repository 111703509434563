import { animated, SpringValue } from "@react-spring/web";
import { useTheme } from "@mui/material";
import { Box } from "~/components/ui/box";
import { Text } from "~/components/ui/text";
import { forwardRef } from "react";

type TimeMarkerProps = {
  containerWidth: number;
  label: string;
  x: SpringValue<number>;
  y: SpringValue<number>;
};

export const TimeMarker = forwardRef<HTMLDivElement, TimeMarkerProps>(
  ({ label, x, y, containerWidth }, forwardedRef) => {
    const {
      tokens: {
        colors: { foreground },
      },
    } = useTheme();

    // Restrict marker from overflowing on the x-axis
    const interpolatedMarkerX = x.to({
      range: [0, MARKER_WIDTH, containerWidth - MARKER_WIDTH, containerWidth],
      output: [MARKER_WIDTH / 2, 0, 0, -MARKER_WIDTH / 2],
    });

    return (
      <animated.div
        style={{
          position: "absolute",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          touchAction: "none",
          x,
          userSelect: "none",
          width: 2,
          backgroundColor: foreground.secondary,
        }}
      >
        <animated.div style={{ x: interpolatedMarkerX, y }}>
          <Box
            ref={forwardedRef}
            paddingX="small"
            background="surface"
            border="neutral"
            borderWidth="large"
            borderRadius="large"
            alignSelf="start"
            sx={{ width: MARKER_WIDTH }}
          >
            <Text size="large" weight="semi" align="center">
              {label}
            </Text>
          </Box>
        </animated.div>
      </animated.div>
    );
  }
);

const MARKER_WIDTH = 64;
