import {
  ActivityRequestStatus,
  ActivityRequestWithAvailabilityCount,
} from "@ses-mams/api-contract";
import { useMemo } from "react";

export const useActivityRequestListSections = (
  pages?: Array<{
    status: number;
    body: { items: Array<ActivityRequestWithAvailabilityCount> };
  }>,
  archived?: boolean
) => {
  return useMemo(() => {
    const sectionMap: Record<
      ActivityStatusHeading,
      ActivityRequestWithAvailabilityCount[]
    > = {
      // insertion order of these keys must match the endpoint's ORDER BY
      Committed: [],
      "Availability Requested": [],
      Available: [],
      Open: [],
      Removed: [],
      Archived: [],
    };

    (pages || []).flatMap(page => {
      if (page.status !== 200) {
        return;
      }

      if (archived) {
        sectionMap.Archived.push(...page.body.items);
        return;
      }

      page.body.items.forEach(item => {
        const statusLabel = item?.status
          ? activityHeadingMap[item?.status]
          : "Open";

        sectionMap[statusLabel].push(item);
      });
    });

    return Object.keys(sectionMap).flatMap(key => {
      const typedKey = key as keyof typeof sectionMap;
      const keyedData = sectionMap[typedKey];

      if (!keyedData.length) {
        return [];
      }

      return [
        {
          title: typedKey,
          data: keyedData,
        },
      ];
    });
  }, [pages, archived]);
};

type ActivityStatusHeading =
  | "Committed"
  | "Availability Requested"
  | "Available"
  | "Open"
  | "Removed"
  | "Archived";

const activityHeadingMap = {
  AttendanceConfirmed: "Committed",
  AvailabilityRequested: "Availability Requested",
  AvailabilitySubmitted: "Available",
  Removed: "Removed",
} as const satisfies Record<ActivityRequestStatus, ActivityStatusHeading>;
