import { useMemberAvailabilityData } from "./useOwnAvailabilityData";
import { tsr } from "~/utils/client";

export const useOtherMemberAvailabilityData = ({
  memberId,
  startOfTheWeek,
  endOfTheWeek,
  unitId,
}: {
  memberId: string;
  startOfTheWeek: Date;
  endOfTheWeek: Date;
  unitId?: string;
}) => {
  const { data: memberData, isLoading: isLoadingMember } =
    tsr.members.get.useQuery({
      queryKey: ["members", memberId],
      queryData: {
        params: { id: memberId },
      },
    });

  const {
    isLoading: isLoadingMemberAvailabilityData,
    hourlyAvailability,
    hasAvailabilitySet,
    mutate,
    isMutating,
  } = useMemberAvailabilityData({
    memberId,
    unitId,
    startOfTheWeek,
    endOfTheWeek,
  });

  return {
    isLoading: isLoadingMember || isLoadingMemberAvailabilityData,
    member: memberData?.body,
    units: memberData?.body?.units?.map(u => u.unit) ?? [],
    hourlyAvailability,
    hasAvailabilitySet,
    mutate,
    isMutating,
  };
};
