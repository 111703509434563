import { ButtonBase } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useChannelPreviewInfo, ChannelPreviewProps } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { Box } from "~/components/ui/box";
import {
  MuteOutlineIcon,
  UserProfileGroupFilledIcon,
} from "~/components/ui/icon";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

type Props = ChannelPreviewProps<DefaultStreamChatGenerics> & {
  unread?: number;
};

export const ChannelListPreview = ({
  unread,
  channel,
  setActiveChannel,
}: Props) => {
  const [, setSearchParams] = useSearchParams();

  const { displayTitle } = useChannelPreviewInfo({ channel });

  const hasUnreadMessages = Boolean(unread);

  const isMuted = channel ? channel.muteStatus()?.muted : false;

  return (
    <ButtonBase
      onClick={() => {
        setSearchParams({ channelId: channel.id ?? "" });
        setActiveChannel?.(channel);
      }}
      sx={theme => ({
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "flex-start",
        borderTopStyle: "solid",
        borderTopWidth: theme.tokens.border.width.medium,
        borderTopColor: theme.tokens.border.color.standard,
        borderRightWidth: 0,
      })}
    >
      <Stack
        display="flex"
        align="start"
        gap="medium"
        paddingX="large"
        paddingY="large"
        sx={{ width: "100%" }}
      >
        <Stack
          gap="small"
          direction="row"
          justify="space-between"
          align="center"
          sx={{ width: "100%" }}
        >
          <Stack flex={1} gap="small" direction="row" align="center">
            <UserProfileGroupFilledIcon tone="muted" size="xxsmall" />

            <Stack flex={1} align="start" justify="center">
              <Text size="xlarge" weight="medium">
                {displayTitle}
              </Text>
            </Stack>
          </Stack>

          <Stack direction="row" gap="small" align="center">
            {isMuted && <MuteOutlineIcon />}
            <Box
              background={hasUnreadMessages ? "info" : undefined}
              borderRadius="full"
              sx={{ height: 8, width: 8 }}
            />
          </Stack>
        </Stack>

        <ChannelPreviewDescription
          description={channel.data?.description as string}
        />
      </Stack>
    </ButtonBase>
  );
};

type ChannelPreviewDescriptionProps = {
  description?: string;
};

const ChannelPreviewDescription = ({
  description,
}: ChannelPreviewDescriptionProps) => {
  if (!description) {
    return null;
  }

  return (
    <Box display="flex" flex={1}>
      <Text tone="muted" size="large" align="start">
        {description}
      </Text>
    </Box>
  );
};
