import {
  Member,
  OutOfAreaActivationRequestMember,
} from "@ses-mams/api-contract";
import emptyListImageUrl from "~/assets/emptyList.png";
import { useCallback } from "react";
import { MemberListItem } from "~/components/common/searchMembersList";
import {
  InfiniteSectionList,
  RenderSectionHeaderParams,
} from "~/components/ui/list";
import { Box } from "~/components/ui/box";
import { EmptyState } from "~/components/common/emptyState";
import { useOutOfAreaActivationMembersData } from "./useOutOfAreaActivationMembersData";
import { Spinner } from "~/components/ui/spinner";

type OutOfAreaActivationMemberListProps = Pick<
  ReturnType<typeof useOutOfAreaActivationMembersData>,
  "sections" | "isLoading" | "fetchNextPage" | "hasNextPage"
> & {
  renderItem?: (member: OutOfAreaActivationRequestMember) => React.ReactElement;
  renderSectionHeader: (
    params: RenderSectionHeaderParams<OutOfAreaActivationRequestMember>
  ) => React.ReactElement;
};

export const OutOfAreaActivationMemberList = ({
  sections,
  isLoading,
  renderItem,
  renderSectionHeader,
  fetchNextPage,
  hasNextPage,
}: OutOfAreaActivationMemberListProps) => {
  const handleRenderItem = useCallback(
    (item: OutOfAreaActivationRequestMember) => {
      if (renderItem) {
        return renderItem(item);
      }

      return (
        <MemberListItem member={item.request.member as unknown as Member} />
      );
    },
    [renderItem]
  );

  return (
    <InfiniteSectionList
      sections={sections}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      renderItem={handleRenderItem}
      renderSectionHeader={renderSectionHeader}
      keyExtractor={item => item.request.id}
      listEmptyElement={
        isLoading ? (
          <Box display="flex" justify="center" padding="medium">
            <Spinner />
          </Box>
        ) : (
          <Box display="flex" justify="center" sx={{ height: "100%" }}>
            <EmptyState
              image={<img src={emptyListImageUrl} />}
              title="No members"
              description="There are no members."
            />
          </Box>
        )
      }
    />
  );
};
