import { useCallback, useMemo } from "react";
import { Link as RouterLink, createSearchParams } from "react-router-dom";
import { addHours } from "date-fns";
import { Link } from "@mui/material";
import {
  AggreggatedUnitAvailabilityPeriod,
  AvailabilityReportStatus,
} from "@ses-mams/api-contract";
import { formatTwentyFourHourOnly } from "@ses-mams/react-utils";
import { formatDateForServer } from "@ses-mams/react-utils";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { AvailabilityReportCell } from "~/components/common/availabilityReportCell";
import { Box } from "~/components/ui/box";

type AggregatedAvailabilityPeriodColumnProps = {
  period: AggreggatedUnitAvailabilityPeriod;
  unitId: string;
  groupId?: string;
  collectionId?: string;
  collectionType?: string;
  capabilityId?: string;
  memberType?: string;
};

export const AggregatedAvailabilityReportingPeriodColumn = ({
  period,
  unitId,
  groupId,
  collectionId,
  collectionType,
  capabilityId,
  memberType,
}: AggregatedAvailabilityPeriodColumnProps) => {
  const availabilityDates = useMemo(() => {
    const startDate = new Date(period.period);
    const endDate = addHours(startDate, 1);

    return {
      startDate,
      endDate,
    };
  }, [period.period]);

  const renderCell = useCallback(
    (status: AvailabilityReportStatus, value: number) => {
      return (
        <Link
          component={RouterLink}
          underline="none"
          to={{
            pathname: `/reporting/members`,
            search: `?${createSearchParams({
              startDate: formatDateForServer(availabilityDates.startDate),
              endDate: formatDateForServer(availabilityDates.endDate),
              availabilityReportStatus: status,
              groupId: groupId ?? "",
              collectionId: collectionId ?? "",
              collectionType: collectionType ?? "",
              capabilityId: capabilityId ?? "",
              memberType: memberType ?? "",
            })}`,
          }}
          // Only allow cells with a value > 0 to be actionable
          tabIndex={value ? undefined : -1}
          sx={value ? undefined : { pointerEvents: "none" }}
        >
          <AvailabilityReportCell status={status} value={value} />
        </Link>
      );
    },
    [unitId, groupId, availabilityDates]
  );

  return (
    <Stack gap="small" align="center">
      <Text tone="muted" size="standard">
        {formatTwentyFourHourOnly(new Date(period.period))}
      </Text>

      <Stack position="relative" gap="small">
        {renderCell("Committed", period.committed)}
        {renderCell("ImmediatelyAvailable", period.immediatelyAvailable)}
        {renderCell("Available", period.available)}
        {renderCell("Conditional", period.conditional)}
        {renderCell("Unavailable", period.unavailable)}
        {renderCell("Unset", period.unset)}

        {period.lowAvailability && (
          <Box
            position="absolute"
            border="critical"
            borderWidth="medium"
            borderRadius="medium"
            sx={{
              pointerEvents: "none",
              top: LOW_AVAILABILITY_OFFSET,
              left: LOW_AVAILABILITY_OFFSET,
              right: LOW_AVAILABILITY_OFFSET,
              bottom: LOW_AVAILABILITY_OFFSET,
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

const LOW_AVAILABILITY_OFFSET = -4;
