import { startOfMinute } from "date-fns";
import { z } from "zod";
import { dateAndTime } from "./dateAndTime";
import { isValid24HourTime } from "./isValid24HourTime";

export const eventDatesFormSchema = z.object({
  start: dateAndTime({
    date_required_error: "Start date is required",
    time_required_error: "Start time is required",
  }),
  end: dateAndTime({
    date_required_error: "End date is required",
    time_required_error: "End time is required",
  }),
});

export const eventOtherFieldsFormSchema = z.object({
  unit: z
    .object({
      name: z.string({
        required_error: "Unit is required",
        invalid_type_error: "Unit is required",
      }),
      id: z.string({
        required_error: "Unit is required",
        invalid_type_error: "Unit is required",
      }),
    })
    .required(),
  title: z
    .string({ required_error: "Title is required" })
    .trim()
    .min(1, "Title is required")
    .max(255, "The maximum length is 255 characters"),
  description: z
    .string({ required_error: "Description is required" })
    .trim()
    .min(1, "Description is required")
    .max(1000, "The maximum length is 1000 characters"),
  location: z
    .string({ required_error: "Location is required" })
    .min(1, "Location is required"),
  latitude: z.number(),
  longitude: z.number(),
});

export const validateDatesSchema = (
  val: z.infer<typeof eventDatesFormSchema>,
  ctx: z.RefinementCtx
) => {
  if (
    !isValid24HourTime(val.start?.time) ||
    !isValid24HourTime(val.end?.time)
  ) {
    return;
  }

  if (+startOfMinute(val.start.date) > +startOfMinute(val.end.date)) {
    ctx.addIssue({
      code: "custom",
      message: "Start time must be before or equal to the end time",
      path: ["start"],
    });
    ctx.addIssue({
      code: "custom",
      message: "End time must be after or equal to the start time",
      path: ["end"],
    });
    return;
  }

  if (val.end.date < new Date()) {
    ctx.addIssue({
      code: "custom",
      message: "End time cannot be in the past",
      path: ["end"],
    });
  }
};

export type EventFormSchema = z.infer<typeof eventDatesFormSchema> &
  z.infer<typeof eventOtherFieldsFormSchema>;
