import { addDays } from "date-fns";
import { useCallback } from "react";
import { useFieldArray } from "react-hook-form";
import { useAvailabilityBlocksForm, getNextBlock } from "@ses-mams/react-utils";

import { Stack } from "~/components/ui/stack";
import { Button } from "~/components/ui/button";
import { PlusOutlineIcon } from "~/components/ui/icon";

import { AvailabilityBlockSection } from "./AvailabilityBlockSection";

type StartEndFieldNameArray = (
  | `availabilityBlocks.${number}.end`
  | `availabilityBlocks.${number}.start`
)[];

type AvailabilityBlocksFormProps = Pick<
  ReturnType<typeof useAvailabilityBlocksForm>,
  "control" | "trigger" | "getValues"
>;

export const AvailabilityBlocksForm = ({
  control,
  trigger,
  getValues,
}: AvailabilityBlocksFormProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "availabilityBlocks",
  });

  const handleTriggerTimeValidation = useCallback(
    (index: number) => {
      const impactedFields: StartEndFieldNameArray = [
        `availabilityBlocks.${index - 1}.end`,
        `availabilityBlocks.${index}.end`,
        `availabilityBlocks.${index}.start`,
        `availabilityBlocks.${index + 1}.start`,
      ];

      const triggerFields = getValues(impactedFields)
        .map((value, index) => {
          if (value) {
            return impactedFields[index];
          }
          return undefined;
        })
        .filter(x => !!x);

      trigger(triggerFields as StartEndFieldNameArray);
    },
    [trigger, getValues]
  );

  const handleDeleteBlockSection = useCallback(
    (index: number) => {
      remove(index);
      handleTriggerTimeValidation(index - 1);
    },
    [remove, handleTriggerTimeValidation]
  );

  return (
    <Stack gap="xlarge">
      <Stack gap="xlarge">
        {fields.map((field, index) => (
          <AvailabilityBlockSection
            key={field.id}
            index={index}
            control={control}
            // User shouldn't be able to delete the final item
            onDeletePress={
              fields.length > 1
                ? () => handleDeleteBlockSection(index)
                : undefined
            }
            triggerTimeValidation={handleTriggerTimeValidation}
          />
        ))}
      </Stack>

      <Button
        variant="tertiary"
        startIcon={PlusOutlineIcon}
        onClick={() =>
          append(getNextBlock(addDays(fields[fields.length - 1].end, 1)))
        }
      >
        Add Block
      </Button>
    </Stack>
  );
};
