import { Control, Controller } from "react-hook-form";

import { Button } from "~/components/ui/button";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { TextField } from "~/components/ui/textField";
import { TimeField } from "~/components/ui/timeField";
import { MyScheduleFormSchema } from "./schema";

type ScheduleSectionProps = {
  index: number;
  control: Control<MyScheduleFormSchema>;
  triggerTimeValidation: (index: number) => void;
  onDeleteClick?: () => void;
};

export const MyScheduleSection = ({
  index,
  control,
  onDeleteClick,
  triggerTimeValidation,
}: ScheduleSectionProps) => {
  return (
    <Stack gap="medium" paddingBottom="large">
      <Stack direction="row" justify="space-between">
        <Text weight="semi">{`Section ${index + 1}`}</Text>

        {onDeleteClick && (
          <Button variant="destructive" onClick={onDeleteClick}>
            Delete
          </Button>
        )}
      </Stack>

      <Controller
        name={`schedule.${index}.name`}
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState }) => (
          <TextField
            label="Name"
            placeholder="Enter name"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            errorMessage={fieldState.error?.message}
          />
        )}
      />

      <Stack direction="row" gap="large" align="start">
        <Stack flex={1}>
          <Controller
            name={`schedule.${index}.start`}
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState }) => (
              <TimeField
                label="Start time"
                value={value}
                onChange={onChange}
                onBlur={() => {
                  onBlur();
                  triggerTimeValidation(index);
                }}
                errorMessage={fieldState.error?.message}
              />
            )}
          />
        </Stack>

        <Stack paddingTop="xxlarge">
          <Text size="large" weight="medium">
            to
          </Text>
        </Stack>

        <Stack flex={1}>
          <Controller
            name={`schedule.${index}.end`}
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState }) => (
              <TimeField
                label="End time"
                value={value}
                onChange={onChange}
                onBlur={() => {
                  onBlur();
                  triggerTimeValidation(index);
                }}
                errorMessage={fieldState.error?.message}
              />
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
