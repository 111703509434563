import { ButtonBase } from "@mui/material";
import {
  defaultOutOfAreaMembersListFilters,
  useOutOfAreaMembersListFilters,
} from "@ses-mams/react-utils";
import { useCallback, useState } from "react";
import {
  FilterPopover,
  FilterPopoverFooter,
  FilterPopoverHeader,
} from "~/components/common/filterPopover";
import { SelectOutOfAreaRolesDrawer } from "~/components/common/selectOutOfAreaRolesList";
import { Button } from "~/components/ui/button";
import { CheckboxField } from "~/components/ui/checkbox";
import { DateField } from "~/components/ui/dateField";
import { SelectField, SelectItem } from "~/components/ui/selectField";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { TextField } from "~/components/ui/textField";
import { tsr } from "~/utils/client";

export const ManageOOAAMembersFilters = () => {
  const [isPopverOpen, setIsPopoverOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { filters, setFilters } = useOutOfAreaMembersListFilters();

  const [internalFilters, setInternalFilters] = useState(filters);

  const { data: zonesData, isLoading: isLoadingZones } =
    tsr.zones.list.useQuery({
      queryKey: ["zones"],
      queryData: {
        query: {
          take: 1000,
        },
      },
    });

  const { data: clustersData, isLoading: isLoadingClusters } =
    tsr.clusters.list.useQuery({
      queryKey: ["clusters"],
      queryData: {
        query: {
          take: 1000,
        },
      },
    });

  const { data: unitsData, isLoading: isLoadingUnits } =
    tsr.units.list.useQuery({
      queryKey: ["units"],
      queryData: {
        query: {
          take: 1000,
        },
      },
    });

  const collections =
    zonesData &&
    clustersData &&
    unitsData &&
    !isLoadingZones &&
    !isLoadingClusters &&
    !isLoadingUnits
      ? [
          ...zonesData.body.items.map(({ id, name }) => ({
            id,
            name,
            level: "zone",
          })),
          ...clustersData.body.items.map(({ id, name }) => ({
            id,
            name,
            level: "cluster",
          })),
          ...unitsData.body.items.map(({ id, name }) => ({
            id,
            name,
            level: "unit",
          })),
        ]
      : [];

  const handleClear = useCallback(
    () => setInternalFilters(defaultOutOfAreaMembersListFilters),
    []
  );

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      setFilters(internalFilters);

      setIsPopoverOpen(false);
    },
    [internalFilters]
  );

  return (
    <>
      <FilterPopover open={isPopverOpen} onOpenChange={setIsPopoverOpen}>
        <form onSubmit={handleSubmit}>
          <FilterPopoverHeader onClear={handleClear} />

          <Stack gap="large">
            <CheckboxField
              checked={internalFilters.activatedStatus}
              label="Show Activated"
              onChange={(_, checked) =>
                setInternalFilters(prev => ({
                  ...prev,
                  activatedStatus: checked,
                }))
              }
            />
            <CheckboxField
              checked={internalFilters.approvedStatus}
              label="Show Supported - Recommended for Activation"
              onChange={(_, checked) =>
                setInternalFilters(prev => ({
                  ...prev,
                  approvedStatus: checked,
                }))
              }
            />

            <CheckboxField
              checked={internalFilters.rejectedStatus}
              label="Show Rejected"
              onChange={(_, checked) =>
                setInternalFilters(prev => ({
                  ...prev,
                  rejectedStatus: checked,
                }))
              }
            />

            <CheckboxField
              checked={internalFilters.zonePendingStatus}
              label="Show Zone Approval Pending"
              onChange={(_, checked) =>
                setInternalFilters(prev => ({
                  ...prev,
                  zonePendingStatus: checked,
                }))
              }
            />

            <CheckboxField
              checked={internalFilters.clusterPendingStatus}
              label="Show Cluster Approval Pending"
              onChange={(_, checked) =>
                setInternalFilters(prev => ({
                  ...prev,
                  clusterPendingStatus: checked,
                }))
              }
            />

            <CheckboxField
              checked={internalFilters.unitPendingStatus}
              label="Show Unit Approval Pending"
              onChange={(_, checked) =>
                setInternalFilters(prev => ({
                  ...prev,
                  unitPendingStatus: checked,
                }))
              }
            />

            <CheckboxField
              checked={internalFilters.awaitingStatus}
              label="Show Awaiting response"
              onChange={(_, checked) =>
                setInternalFilters(prev => ({
                  ...prev,
                  awaitingStatus: checked,
                }))
              }
            />

            <SelectField
              label="Select collection"
              value={internalFilters?.collection}
              onChange={e =>
                setInternalFilters(prev => ({
                  ...prev,
                  collection: e.target.value as string,
                }))
              }
            >
              <SelectItem>
                <Text>Clear selection</Text>
              </SelectItem>
              {collections.map(({ id, level, name }) => (
                <SelectItem key={id} value={`${id}::${level}`}>
                  {name}
                </SelectItem>
              ))}
            </SelectField>

            <TextField
              label={"Name"}
              value={internalFilters?.name}
              placeholder="Enter first or last name"
              onChange={e =>
                setInternalFilters(prev => ({
                  ...prev,
                  name: e.target.value as string,
                }))
              }
            />

            <DateField
              label="Status updated at"
              value={internalFilters.statusUpdatedAt}
              onChange={value =>
                setInternalFilters(prev => ({
                  ...prev,
                  statusUpdatedAt: value,
                }))
              }
            />

            <Stack gap="small">
              <Text size="large" weight="medium">
                Roles
              </Text>

              {internalFilters.roles.length ? (
                <ButtonBase
                  onClick={() => {
                    setIsDrawerOpen(true);
                  }}
                >
                  <Text tone="action" weight="medium" align="left">
                    {internalFilters.roles.map(role => role.name).join(",")}
                  </Text>
                </ButtonBase>
              ) : (
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsDrawerOpen(true);
                  }}
                >
                  Select roles
                </Button>
              )}
            </Stack>
          </Stack>

          <FilterPopoverFooter onClose={() => setIsPopoverOpen(false)} />
        </form>
      </FilterPopover>

      <SelectOutOfAreaRolesDrawer
        open={isDrawerOpen}
        roles={internalFilters.roles}
        onClose={() => setIsDrawerOpen(false)}
        onDone={value =>
          setInternalFilters(prev => ({ ...prev, roles: value }))
        }
        // Ensure that this drawer appears on top of the popover
        sx={theme => ({ zIndex: theme.zIndex.tooltip + 1 })}
      />
    </>
  );
};
