import { captureException } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { Button } from "~/components/ui/button";
import { useConfirmationDialog } from "~/components/ui/confirmationDialog";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";

type WithdrawButtonProps = {
  activationId: string;
  requestId: string;
};

export const WithdrawButton = ({
  activationId,
  requestId,
}: WithdrawButtonProps) => {
  const { showConfirmationDialog } = useConfirmationDialog();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } =
    tsr.outOfAreaActivationRequests.withdraw.useMutation();

  const withdraw = useCallback(async () => {
    try {
      await mutateAsync({
        params: {
          requestId,
        },
      });

      await queryClient.invalidateQueries({
        queryKey: [
          "out-of-area-activation-requests",
          "details",
          { activationId },
        ],
      });
    } catch (error) {
      captureException(error);

      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [requestId, activationId]);

  return (
    <Button
      busy={isPending}
      variant="destructive"
      onClick={() => {
        showConfirmationDialog({
          text: "Are you sure you want to withdraw from the approval and activation process?",
          onConfirm: withdraw,
        });
      }}
    >
      Withdraw
    </Button>
  );
};
