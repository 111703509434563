import { Box, BoxProps } from "~/components/ui/box";

type DrawerContentProps = Pick<BoxProps, "sx"> & {
  children: React.ReactNode;
};

export const DrawerContent = ({ sx, children }: DrawerContentProps) => {
  return (
    <Box
      display="flex"
      grow={1}
      direction="column"
      position="relative"
      paddingX="large"
      paddingY="medium"
      sx={[
        { width: { xs: "100vw", sm: 400 }, overflowY: "auto" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  );
};
