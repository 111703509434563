import { useCallback, useState } from "react";
import { Drawer, DrawerProps } from "~/components/ui/drawer";
import { ManageActivityMembers } from "./ManageActivityMembers";
import { AddActivityMembers } from "./AddActivityMembers";

type ManageActivityMembersDrawerProps = Omit<DrawerProps, "children"> & {
  activityId: string;
  activityStart: string;
  isClosed: boolean;
  unitId: string;
};

export const ManageActivityMembersDrawer = ({
  activityId,
  activityStart,
  isClosed,
  onClose,
  unitId,
  ...props
}: ManageActivityMembersDrawerProps) => {
  const [showAddActivityMembers, setShowAddActivityMembers] = useState(false);

  const handleOnClose = useCallback(() => {
    onClose();
    setShowAddActivityMembers(false);
  }, [onClose]);

  return (
    <Drawer {...props} onClose={handleOnClose}>
      {showAddActivityMembers ? (
        <AddActivityMembers
          activityId={activityId}
          activityStart={activityStart}
          onClose={handleOnClose}
          onBack={() => setShowAddActivityMembers(false)}
          unitId={unitId}
        />
      ) : (
        <ManageActivityMembers
          activityId={activityId}
          isClosed={isClosed}
          onAdd={() => setShowAddActivityMembers(true)}
          onClose={handleOnClose}
        />
      )}
    </Drawer>
  );
};
