import { ContactGroup, Group } from "@ses-mams/api-contract";
import { useCallback, useState } from "react";
import { Button } from "~/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerProps,
} from "~/components/ui/drawer";
import { SelectUnitGroupsList } from "./SelectUnitGroupsList";

type SelectUnitGroupsDrawerProps = Omit<DrawerProps, "children"> & {
  groups: Array<Group>;
  contactGroups: Array<ContactGroup>;
  unitId: string;
  onDone: (groups: Array<Group>, contactGroups: Array<ContactGroup>) => void;
};

export const SelectUnitGroupsDrawer = ({
  open,
  onClose,
  onDone,
  groups,
  contactGroups,
  unitId,
}: SelectUnitGroupsDrawerProps) => {
  const [selectedGroups, setSelectedGroups] = useState(groups);
  const [selectedContactGroups, setSelectedContactGroups] =
    useState(contactGroups);

  const handleOnClose = useCallback(() => {
    setSelectedGroups([...groups]);
    setSelectedContactGroups([...contactGroups]);

    onClose();
  }, [groups, contactGroups, onClose]);

  const handleDoneClick = useCallback(() => {
    onDone(selectedGroups, selectedContactGroups);
  }, [onDone, selectedGroups, selectedContactGroups]);

  return (
    <Drawer open={open} onClose={handleOnClose}>
      <DrawerHeader onClose={handleOnClose}>Select Groups</DrawerHeader>

      <DrawerContent>
        <SelectUnitGroupsList
          unitId={unitId}
          groups={selectedGroups}
          contactGroups={selectedContactGroups}
          setGroups={setSelectedGroups}
          setContactGroups={setSelectedContactGroups}
        />
      </DrawerContent>

      <DrawerFooter>
        <Button fullWidth onClick={handleDoneClick}>
          Done
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
