import { styled, Tab as MuiTab, TabProps as MuiTabProps } from "@mui/material";

export type TabProps = MuiTabProps;

export const Tab = (props: TabProps) => <StyledTab {...props} />;

const StyledTab = styled(MuiTab)(({ theme }) => {
  return {
    textTransform: "capitalize",
    backgroundColor: theme.tokens.colors.background.muted,
    color: theme.tokens.colors.foreground.secondary,
    borderRadius: theme.tokens.border.radius.large,
    padding: `${theme.tokens.spacing.medium}px ${theme.tokens.spacing.large}px`,
    minHeight: "unset",

    "&:hover": {
      backgroundColor: theme.tokens.colors.background.card,
    },

    "&[aria-selected=true]": {
      backgroundColor: theme.tokens.colors.background.infoMuted,
      color: theme.tokens.colors.foreground.action,
    },
  };
});
