import { Member, MemberAdminSummary } from "@ses-mams/api-contract";

import { OverrideRolesButton } from "~/components/common/searchUsersList/components/OverrideRolesButton";
import { ResetRolesButton } from "~/components/common/searchUsersList/components/ResetRolesButton";
import { Stack } from "~/components/ui/stack";
import { useAuth } from "~/context/auth";
import { Button } from "~/components/ui/button";
import { useConfirmationDialog } from "~/components/ui/confirmationDialog";
import { useCallback } from "react";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";
import { formatMemberFullName } from "@ses-mams/react-utils";

export const ActionsCell = ({ member }: { member: MemberAdminSummary }) => {
  const { member: loggedInMember } = useAuth();
  const { showConfirmationDialog } = useConfirmationDialog();
  const { addToast } = useToast();

  const { mutateAsync, isPending } =
    tsr.members.revokeDeviceSessions.useMutation();

  const handleRemoteLogout = useCallback(async () => {
    await mutateAsync({ params: { memberId: member.id } });
    addToast({
      tone: "positive",
      title: "Success",
      message: `${formatMemberFullName(member)} has been logged out.`,
    });
  }, [member.id]);

  return (
    <Stack gap="small">
      {canOverrideRoles(member, loggedInMember) && (
        <>
          {member.rolesOverride && <ResetRolesButton member={member} />}
          <OverrideRolesButton
            member={member}
            text={member.rolesOverride ? "Change overrides" : "Override roles"}
          />
        </>
      )}
      <Button
        variant="tertiary"
        onClick={() =>
          showConfirmationDialog({
            text: "Are you sure you want to log this member out?",
            onConfirm: handleRemoteLogout,
          })
        }
        busy={isPending}
      >
        Remote logout
      </Button>
    </Stack>
  );
};

const canOverrideRoles = (
  member: MemberAdminSummary,
  loggedInMember?: Member
): boolean => {
  return (
    member.id !== loggedInMember?.id &&
    // A Volunteer admin cannot override a Staff member's roles
    !(loggedInMember?.type === "Volunteer" && member.type === "Staff")
  );
};
