import { useState } from "react";
import { useDebounce } from "./useDebounce";

export const useDebouncedSearch = <T>(initialValue: T) => {
  const [searchValue, setSearchValue] = useState(initialValue);
  const debouncedSearchValue = useDebounce(searchValue);

  return {
    searchValue,
    setSearchValue,
    debouncedSearchValue,
  };
};
