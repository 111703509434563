import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { ActivationRequestMember } from "@ses-mams/api-contract";

export const useUpdateActivationMembersQueryCache = (activationId: string) => {
  const queryClient = useQueryClient();

  return useCallback(
    (items: Array<ActivationRequestMember>) => {
      return Promise.all([
        queryClient.setQueryData(
          ["activations", activationId, "members", "", []],
          {
            pages: [
              {
                status: 200,
                body: {
                  items,
                },
              },
            ],
            pageParams: [null],
          }
        ),
        queryClient.invalidateQueries({
          queryKey: ["activations", activationId],
          exact: true,
        }),
        queryClient.invalidateQueries({ queryKey: ["activation-requests"] }),
      ]);
    },
    [activationId]
  );
};
