import { Container } from "@mui/material";
import {
  useArrayState,
  useSelectableOutOfAreaRoles,
} from "@ses-mams/react-utils";
import { useSearchParams } from "react-router-dom";
import { Box } from "~/components/ui/box";
import { Spinner } from "~/components/ui/spinner";
import { SelectOutOfAreaRoleIdsList } from "~/components/common/selectOutOfAreaRolesList";
import { useOutOfAreaActivationRolesQuery } from "~/hooks/useOutOfAreaActivationRolesQuery";
import { Stack } from "~/components/ui/stack";
import { Heading } from "~/components/ui/heading";
import { Button } from "~/components/ui/button";
import { useCallback, useEffect, useMemo } from "react";
import { BackButton } from "~/components/common/navigation";
import { useMaybeRequestIdSearchParam } from "./hooks/useMaybeRequestIdSearchParam";
import { useOutOfAreaApprovalQuery } from "./hooks/useOutOfAreaApprovalQuery";
import { useOutOfAreaActivationQuery } from "../requests/hooks";

export const OOAAApprovalOverrideRolesPage = () => {
  const [searchParams] = useSearchParams();
  const requestIds = searchParams.get("requestIds") as string;

  const [selectedRoleIds, { append, remove, setValue }] = useArrayState<string>(
    []
  );

  const {
    existingRequestRoles,
    isLoading: isLoadingRoles,
    roles,
  } = useGetRolesData();

  const href = useMemo(
    () =>
      `/out-of-area-activation-approvals/availability?requestIds=${requestIds}&roleIds=${selectedRoleIds.join(
        ","
      )}`,
    [selectedRoleIds, requestIds]
  );

  useEffect(() => {
    if (!existingRequestRoles) {
      return;
    }

    setValue(existingRequestRoles.map(({ id }) => id));
  }, [existingRequestRoles]);

  if (isLoadingRoles || !roles?.length) {
    return (
      <Box display="flex" grow={1} justify="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Stack gap="xlarge">
        <BackButton />

        <Stack direction="row" align="center" justify="space-between">
          <Heading level="2">Select Role</Heading>

          <Button href={href}>Done</Button>
        </Stack>
        <SelectOutOfAreaRoleIdsList
          selectedRoleIds={selectedRoleIds}
          appendRoleId={append}
          removeRoleId={remove}
          data={roles}
        />
      </Stack>
    </Container>
  );
};

const useGetRolesData = () => {
  const [searchParams] = useSearchParams();
  const maybeRequestId = useMaybeRequestIdSearchParam();

  const { isLoading: isLoadingApproval, data: approvalData } =
    useOutOfAreaApprovalQuery(maybeRequestId);

  const existingRequestRoles = approvalData?.body?.request?.roles;

  const {
    isLoading: isLoadingRoles,
    data: roleData,
    refetch: refetchRoles,
    isRefetching: isRefetchingRoles,
  } = useOutOfAreaActivationRolesQuery();

  const activationId = searchParams.get("activationId") as string;

  // only fetching current request's roles if there is only one request id
  const shouldFetchActivationData = !!maybeRequestId && !!activationId;

  const {
    isLoading: isLoadingActivation,
    data: activationData,
    refetch: refetchActivation,
    isRefetching: isRefetchingActivation,
  } = useOutOfAreaActivationQuery({
    activationId,
    enabled: shouldFetchActivationData,
  });

  const roles = roleData?.body;

  const selectableRoles = useSelectableOutOfAreaRoles(
    roles,
    activationData?.body?.deployments
  );

  const handleRefetch = useCallback(() => {
    refetchRoles();
    refetchActivation();
  }, []);

  return {
    existingRequestRoles,
    isLoading:
      isLoadingRoles ||
      (shouldFetchActivationData && isLoadingActivation) ||
      (maybeRequestId && isLoadingApproval),
    roles: selectableRoles,
    refetch: handleRefetch,
    isRefetching:
      isRefetchingRoles ||
      (shouldFetchActivationData && isRefetchingActivation),
  };
};
