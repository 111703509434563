import { Container } from "@mui/material";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { BackButton } from "~/components/common/navigation";
import { SearchMembersList } from "~/components/common/searchMembersList";
import { Box } from "~/components/ui/box";
import { Heading } from "~/components/ui/heading";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { tsr } from "~/utils/client";

export const UnitMembersPage = () => {
  const { unitId } = useParams();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get("groupId");
  const hasGroupId = Boolean(groupId);

  const { data: unitData, isLoading: isLoadingUnit } = tsr.units.get.useQuery({
    queryKey: ["units", unitId],
    queryData: {
      params: {
        id: unitId as string,
      },
    },
  });

  const { data: groupData, isLoading: isLoadingGroup } =
    tsr.groups.get.useQuery({
      queryKey: ["groups", groupId],
      queryData: {
        params: {
          id: groupId as string,
          unitId: unitId as string,
        },
      },
      enabled: hasGroupId,
    });

  if (
    (!isLoadingUnit && !unitData?.body) ||
    (hasGroupId && !isLoadingGroup && !groupData?.body)
  ) {
    return <Navigate to="/units" />;
  }

  const title = hasGroupId
    ? (groupData?.body?.name ?? "Members")
    : "All Members";

  return (
    <Container maxWidth="sm">
      <Stack gap="medium">
        <BackButton />

        <Heading level="1">{title}</Heading>

        {isLoadingUnit || (hasGroupId && isLoadingGroup) ? (
          <Box display="flex" justify="center">
            <Spinner />
          </Box>
        ) : (
          <SearchMembersList
            unit={hasGroupId ? undefined : unitData?.body}
            group={hasGroupId ? groupData?.body : undefined}
          />
        )}
      </Stack>
    </Container>
  );
};
