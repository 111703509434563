import { Group } from "@ses-mams/api-contract";
import { Controller } from "react-hook-form";
import { Button } from "~/components/ui/button";
import { Stack } from "~/components/ui/stack";
import { HelperText, TextField } from "~/components/ui/textField";
import { Divider } from "~/components/ui/divider";
import { CheckboxField } from "~/components/ui/checkbox";
import { BackButton } from "~/components/common/navigation";
import { TrashOutlineIcon } from "~/components/ui/icon";
import { useConfirmationDialog } from "~/components/ui/confirmationDialog";
import { IntegerField } from "~/components/ui/integerField";
import { useEditGroupForm } from "./useEditGroupForm";
import { useEditGroupActions } from "./useEditGroupActions";
import { useState } from "react";
import { EditGroupCapabilityList } from "./EditGroupCapabilityList";
import { EditGroupMemberList } from "./EditGroupMemberList";
import { SelectAllMembersQuery } from "@ses-mams/react-utils";

type EditGroupFormProps = {
  group?: Group;
  unitId: string;
};

export const EditGroupForm = ({ group, unitId }: EditGroupFormProps) => {
  const [selectAllMembersQuery, setSelectAllMembersQuery] =
    useState<SelectAllMembersQuery>();

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useEditGroupForm({ initialGroup: group });

  const {
    isCreatingGroup,
    isUpdatingGroup,
    isDeletingGroup,
    handleDoneClick,
    handleDeleteClick,
  } = useEditGroupActions({
    groupId: group?.id,
    unitId,
    selectAllMembersQuery,
  });

  const { showConfirmationDialog } = useConfirmationDialog();

  const groupId = group?.id;
  const isEdit = Boolean(groupId);

  const watchUseAvailabilityRequirements = watch("useAvailabilityRequirements");

  return (
    <form onSubmit={handleSubmit(handleDoneClick)}>
      <Stack gap="xlarge">
        <Stack direction="row" justify="space-between">
          <BackButton to={`/units/${unitId}/groups`} />

          <Stack direction="row" gap="medium">
            {isEdit && (
              <Button
                variant="destructive"
                onClick={() =>
                  showConfirmationDialog({
                    text: "Please confirm that you'd like to delete this group",
                    onConfirm: handleDeleteClick,
                  })
                }
                busy={isDeletingGroup}
                startIcon={TrashOutlineIcon}
              >
                Delete Group
              </Button>
            )}
            <Button type="submit" busy={isCreatingGroup || isUpdatingGroup}>
              Done
            </Button>
          </Stack>
        </Stack>

        <Divider />

        <Stack gap="xlarge">
          <TextField
            {...register("name")}
            label="Name"
            placeholder="Enter group name"
            errorMessage={errors.name?.message}
          />

          <Controller
            name="reportable"
            control={control}
            render={({ field }) => (
              <CheckboxField
                label="Reportable"
                checked={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          />

          <Controller
            name="useAvailabilityRequirements"
            control={control}
            render={({ field }) => (
              <CheckboxField
                label="Set minimum availability requirements"
                checked={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          />

          {watchUseAvailabilityRequirements && (
            <>
              <Controller
                name="availabilityMinThreshold"
                control={control}
                render={({ field }) => (
                  <IntegerField
                    value={field.value}
                    onValueChange={v => {
                      field.onChange(v ?? null);
                    }}
                    onBlur={field.onBlur}
                    label="Minimum number of members available"
                    placeholder="Enter a number"
                    errorMessage={errors.availabilityMinThreshold?.message}
                  />
                )}
              />

              <Controller
                name="availabilityLookAheadDays"
                control={control}
                render={({ field }) => (
                  <IntegerField
                    value={field.value}
                    onValueChange={v => {
                      field.onChange(v ?? null);
                    }}
                    onBlur={field.onBlur}
                    label="Days ahead to check member availabilty"
                    placeholder="Enter a number"
                    errorMessage={errors.availabilityLookAheadDays?.message}
                  />
                )}
              />
            </>
          )}

          <EditGroupCapabilityList control={control} />

          <EditGroupMemberList
            control={control}
            setValue={setValue}
            unitId={unitId}
            groupId={groupId}
            selectAllMembersQuery={selectAllMembersQuery}
            setSelectAllMembersQuery={setSelectAllMembersQuery}
          />

          {errors.addMembers?.message && (
            <HelperText role="alert">{errors.addMembers?.message}</HelperText>
          )}
        </Stack>
      </Stack>
    </form>
  );
};
