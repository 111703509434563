import { Container } from "@mui/material";
import { Heading } from "~/components/ui/heading";
import { Stack } from "~/components/ui/stack";
import { useSelectedUnit } from "~/context/unit/SelectedUnitContextProvider";
import { AvailabilityReport } from "./components/AvailabilityReport";

export const ReportingPage = () => {
  const { selectedUnit } = useSelectedUnit();

  return (
    <Container maxWidth="xl">
      <Stack direction="column" gap="xlarge">
        <Stack direction="row" justify="space-between">
          <Heading level="1">Availability reporting</Heading>
        </Stack>

        {selectedUnit && <AvailabilityReport />}
      </Stack>
    </Container>
  );
};
