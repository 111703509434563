import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  AvailabilityReportStatusSchema,
  AvailabilitySchema,
  MemberSummarySchema,
  PaginationQueryParamsSchema,
  ServerDateSchema,
  getPaginationResponseSchema,
} from "./common";
import { MemberSchema } from "./members";

const c = initContract();

const AggregatedReportingAvailabilityPeriod = z.object({
  period: z.string(),
  immediatelyAvailable: z.number(),
  available: z.number(),
  conditional: z.number(),
  unavailable: z.number(),
  committed: z.number(),
  unset: z.number(),
  lowAvailability: z.boolean(),
});

const IndividualReportingAvailabilitySchema = z.object({
  member: MemberSummarySchema,
  availability: z.array(AvailabilitySchema),
});

const reportingContract = c.router({
  getAggregatedAvailability: {
    method: "GET",
    path: "/reporting/availability/aggregated",
    query: z.object({
      startDate: ServerDateSchema,
      endDate: ServerDateSchema,
      groupId: z.string().optional(),
      collectionId: z.coerce.string().optional(),
      collectionType: z.enum(["state", "zone", "cluster", "unit"]),
      capabilityId: z.string().optional(),
      memberType: z.enum(["Volunteer", "Staff"]).optional(),
    }),
    responses: {
      200: z.array(AggregatedReportingAvailabilityPeriod),
    },
    summary: "Get the aggregate availability of a collection",
  },
  getMembersByAvailability: {
    method: "GET",
    path: "/reporting/availability/members",
    query: PaginationQueryParamsSchema.extend({
      startDate: ServerDateSchema.optional(),
      endDate: ServerDateSchema.optional(),
      groupId: z.string().optional(),
      availabilityReportStatus: AvailabilityReportStatusSchema.optional(),
      collectionId: z.coerce.string().optional(),
      collectionType: z.enum(["state", "zone", "cluster", "unit"]),
      capabilityId: z.string().optional(),
      memberType: z.enum(["Volunteer", "Staff"]).optional(),
    }),
    responses: {
      200: getPaginationResponseSchema(MemberSchema),
    },
    summary: "Get members by availability period and status",
  },
});

export type AggreggatedReportingAvailabilityPeriod = z.infer<
  typeof AggregatedReportingAvailabilityPeriod
>;

export type IndividualReportingAvailability = z.infer<
  typeof IndividualReportingAvailabilitySchema
>;

export { reportingContract };
