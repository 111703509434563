import { endOfDay, startOfDay } from "date-fns";
import {
  formatDateForServer,
  useActivityRequestListSections,
  useDebouncedSearch,
  useRequestListFiltersContext,
  useTransformPagedDataToItems,
} from "@ses-mams/react-utils";

import { tsr } from "~/utils/client";
import { DEFAULT_PAGE_SIZE, getNextPageParam } from "~/utils/pagination";

export const useActivityRequestsData = ({ unitId }: { unitId?: string }) => {
  const { filters, unitFilters } = useRequestListFiltersContext();

  const filtersInUse = unitId ? unitFilters : filters;

  const { activationTimeRange: selectedTimeRange, archivedOnly: archived } =
    filtersInUse;

  const { searchValue, setSearchValue, debouncedSearchValue } =
    useDebouncedSearch("");

  const {
    isLoading,
    isRefetching,
    isFetchingNextPage,
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
  } = tsr.activityRequests.list.useInfiniteQuery({
    queryKey: [
      "activity-requests",
      "list",
      selectedTimeRange,
      debouncedSearchValue,
      archived,
      unitId,
    ],
    queryData: ({ pageParam = { skip: 0, take: DEFAULT_PAGE_SIZE } }) => ({
      query: {
        skip: pageParam.skip,
        take: pageParam.take,
        startDate: selectedTimeRange.start
          ? formatDateForServer(startOfDay(selectedTimeRange.start))
          : undefined,
        endDate: selectedTimeRange.end
          ? formatDateForServer(endOfDay(selectedTimeRange.end))
          : undefined,
        query: debouncedSearchValue,
        archived,
        unitId,
      },
    }),
    initialPageParam: { skip: 0, take: DEFAULT_PAGE_SIZE },
    getNextPageParam,
  });

  const sections = useActivityRequestListSections(data?.pages, archived);

  const items = useTransformPagedDataToItems(data);

  return {
    data: items,
    isLoading,
    isRefetching,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
    hasNextPage,
    filters: {
      searchValue,
      setSearchValue,
      archived,
    },
    sections,
  };
};
