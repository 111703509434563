import { captureException } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { useConfirmationDialog } from "~/components/ui/confirmationDialog";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";

type UseRemoveMembersParams = {
  activityId: string;
  confirmationText: string;
  onSuccess?: () => void;
};

export const useRemoveActivityMembers = ({
  activityId,
  confirmationText,
  onSuccess,
}: UseRemoveMembersParams) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } =
    tsr.activities.removeActivityMembers.useMutation();

  const { addToast } = useToast();

  const { showConfirmationDialog } = useConfirmationDialog();

  const removeMembers = useCallback(
    async (memberIds: Array<string>) => {
      try {
        const result = await mutateAsync({
          params: {
            activityId,
          },
          body: {
            memberIds,
          },
        });

        await Promise.all([
          queryClient.setQueryData(
            ["activities", activityId, "members", "", []],
            { pages: [result], pageParams: [null] }
          ),

          queryClient.invalidateQueries({
            queryKey: ["activities", activityId],
            exact: true,
          }),

          queryClient.invalidateQueries({
            queryKey: ["activity-requests"],
          }),
        ]);

        onSuccess?.();
      } catch (error) {
        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    [activityId, onSuccess]
  );

  const handleConfirmRemoveMembers = useCallback(
    (memberIds: Array<string>) => {
      showConfirmationDialog({
        text: confirmationText,
        onConfirm: () => removeMembers(memberIds),
      });
    },
    [confirmationText]
  );

  return {
    handleConfirmRemoveMembers,
    isPending,
  };
};
