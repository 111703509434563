import { Fragment } from "react";
import { Stack } from "~/components/ui/stack";
import { useAvailabilityReportContext } from "~/context/unit/AvailabilityReportContextProvider";
import { AggregatedAvailabilityReport } from "./AggregatedAvailabilityReport";

export const AggregatedAvailabilityReports = () => {
  const { groups } = useAvailabilityReportContext();

  return (
    <Stack dividers dividerTone="neutral">
      <AggregatedAvailabilityReport title="All Members" />

      {groups.map((group, index) => (
        <Fragment key={`aggregated-availability-report-${index}`}>
          <AggregatedAvailabilityReport title={group.name} groupId={group.id} />
        </Fragment>
      ))}
    </Stack>
  );
};
