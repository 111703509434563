import { OutOfAreaActivationRequestWithStatusCount } from "@ses-mams/api-contract";

import { Row, Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import {
  formatDateTime,
  formatOutOfAreaRequestStatus,
  getOutOfAreaActivationRequestStatusBadgeTone,
} from "@ses-mams/react-utils";
import { Badge } from "~/components/ui/badge";
import { Checkbox } from "~/components/ui/checkbox";
import { RequestCard } from "../../../shared";

type OutOfAreaActivationListItemProps = {
  item: OutOfAreaActivationRequestWithStatusCount;
  isBulkSelecting: boolean;
  selectedIds: Set<string>;
  onBulkSelect: (id: string) => void;
};

export const OutOfAreaActivationListItem = ({
  item,
  isBulkSelecting,
  selectedIds,
  onBulkSelect,
}: OutOfAreaActivationListItemProps) => {
  const { activation, start, end, status } = item;

  const isUrgent = activation.type === "Urgent";

  const isSelected = selectedIds.has(activation.id);

  return (
    <RequestCard
      to={`/requests/out-of-area-activations/${activation.id}`}
      onClick={isBulkSelecting ? () => onBulkSelect(activation.id) : undefined}
      selected={isSelected}
    >
      <Row justify="space-between" align="center" sx={{ width: "100%" }}>
        <Stack align="start" gap="medium">
          {isUrgent ? (
            <Badge size="small" tone="criticalMuted">
              Urgent
            </Badge>
          ) : null}
          <Text weight="medium" tone={isUrgent ? "critical" : "primary"}>
            {activation.title}
          </Text>

          {start && end && (
            <Text>
              {`From ${formatDateTime(new Date(start))} - ${formatDateTime(
                new Date(end)
              )}`}
            </Text>
          )}

          {status && (
            <Badge
              tone={getOutOfAreaActivationRequestStatusBadgeTone(status)}
              size="small"
            >
              {formatOutOfAreaRequestStatus(status)}
            </Badge>
          )}
        </Stack>

        {isBulkSelecting ? <Checkbox checked={isSelected} /> : null}
      </Row>
    </RequestCard>
  );
};
