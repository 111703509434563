import { CheckboxField } from "~/components/ui/checkbox";
import { Text } from "~/components/ui/text";

type SelectAllMembersToggleProps = {
  selected: boolean;
  totalCount: number;
  disabled?: boolean;
  onClick: (nextSelected: boolean) => void;
};

export const SelectAllMembersToggle = ({
  selected,
  totalCount,
  disabled,
  onClick,
}: SelectAllMembersToggleProps) => {
  return (
    <CheckboxField
      label={
        <Text
          tone="action"
          weight="medium"
        >{`Select All (${totalCount})`}</Text>
      }
      checked={selected}
      onClick={() => onClick(!selected)}
      disabled={disabled}
    />
  );
};
