import { useState } from "react";
import onboarding1 from "~/assets/availabilityOnboarding1.svg";
import onboarding2 from "~/assets/availabilityOnboarding2.svg";
import onboarding3 from "~/assets/availabilityOnboarding3.svg";
import onboarding4 from "~/assets/availabilityOnboarding4.svg";
import onboarding5 from "~/assets/availabilityOnboarding5.svg";
import { Button } from "~/components/ui/button";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { CopyRightOutlineIcon, FileOutlineIcon } from "~/components/ui/icon";
import { Box } from "~/components/ui/box";
import { Dialog, DialogContent } from "~/components/ui/dialog";

type OnboardingModalProps = {
  showModal: boolean;
  onClose: () => void;
  onDone: () => void;
};

export const OnboardingDialog = ({
  showModal,
  onClose,
  onDone,
}: OnboardingModalProps) => {
  const [step, setStep] = useState<1 | 2 | 3>(1);

  const reset = () => {
    setStep(1);
  };

  return (
    <Dialog
      open={showModal}
      onClose={() => {
        onClose();
        reset();
      }}
    >
      <DialogContent>
        {step === 1 && (
          <Stack gap="medium" align="center">
            <img src={onboarding1} />
            <Text size="large" weight="medium">
              Select available times
            </Text>
            <Text tone="secondary" align="center">
              You can tap or drag your finger across each hourly block of time
              to make a selection. To select entire days or the same time every
              day, tap the corresponding hour / day label.
            </Text>

            <Button
              variant="secondary"
              fullWidth={true}
              onClick={() => {
                setStep(2);
              }}
            >
              Next
            </Button>
          </Stack>
        )}
        {step === 2 && (
          <Stack gap="medium" align="center">
            <img src={onboarding2} />
            <Text size="large" weight="medium">
              Set availability type
            </Text>
            <Text tone="secondary" align="center">
              Your selection can be one of 4 availability types. This allows you
              to be flexible with your schedule.
            </Text>

            <Button
              variant="secondary"
              fullWidth={true}
              onClick={() => {
                setStep(3);
              }}
            >
              Next
            </Button>
          </Stack>
        )}
        {step === 3 && (
          <Stack gap="medium">
            <Text size="large" weight="medium">
              Heres a tip!
            </Text>
            <Text tone="secondary">
              You can copy your weeks availability and paste it across other
              weeks to save yourself some time.
            </Text>

            <Stack gap="medium">
              <Stack direction="row" gap="xsmall">
                <CopyRightOutlineIcon tone="action" />
                <Text tone="action" weight="medium">
                  Copy Schedule
                </Text>
              </Stack>
              <Box background="muted" borderRadius="large" position="relative">
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    translate: "translate(-50%, -50%)",
                    minHeight: 160,
                  }}
                >
                  <img src={onboarding3} />
                </Box>
              </Box>

              <Stack direction="row" gap="xsmall">
                <FileOutlineIcon tone="action" />
                <Text tone="action" weight="medium">
                  Paste Schedule
                </Text>
              </Stack>
              <Box background="muted" borderRadius="large" position="relative">
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    translate: "translate(-50%, -50%)",
                    minHeight: 160,
                  }}
                >
                  <img src={onboarding4} />
                </Box>
              </Box>
              <Box background="muted" borderRadius="large" position="relative">
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    translate: "translate(-50%, -50%)",
                    minHeight: 160,
                  }}
                >
                  <img src={onboarding5} />
                </Box>
              </Box>
            </Stack>

            <Button
              variant="secondary"
              fullWidth={true}
              onClick={() => {
                onDone();
                reset();
              }}
            >
              Next
            </Button>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};
