import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Unit } from "@ses-mams/api-contract";
import { useAuth } from "~/context/auth";

type AvailabilityUnitContextType = {
  selectedUnit?: Unit;
  setSelectedUnit: (unit: Unit) => void;
};

const AvailabilityUnitContext =
  createContext<AvailabilityUnitContextType | null>(null);

function AvailabilityUnitContextProvider(props: React.PropsWithChildren) {
  const { member } = useAuth();
  const [selectedUnit, setSelectedUnit] = useState<Unit>();

  const memberUnits = useMemo(() => {
    return member?.units.map(({ unit }) => unit) ?? [];
  }, [member]);

  useEffect(() => {
    if (!selectedUnit && memberUnits.length) {
      setSelectedUnit(memberUnits[0]);
    }
  }, [selectedUnit, memberUnits]);

  const value = useMemo(
    () => ({
      selectedUnit,
      setSelectedUnit,
    }),
    [selectedUnit, member]
  );

  return (
    <AvailabilityUnitContext.Provider value={value}>
      {props.children}
    </AvailabilityUnitContext.Provider>
  );
}

function useAvailabilityUnit() {
  const ctx = useContext(AvailabilityUnitContext);

  if (!ctx) {
    throw new Error(
      "Must be called within <AvailabilityUnitContextProvider />"
    );
  }

  return ctx;
}

export { useAvailabilityUnit, AvailabilityUnitContextProvider };
