import { Capability } from "@ses-mams/api-contract";

import { Badge } from "~/components/ui/badge";

type Props = {
  capability: Capability;
};

export const CapabilityBadge = ({ capability }: Props) => (
  <Badge tone="info" size="small">
    {capability.label}
  </Badge>
);
