import { Container } from "@mui/material";
import { BackButton } from "~/components/common/navigation";
import { Stack } from "~/components/ui/stack";
import { EditOutOfAreaActivationForm } from "./components/AddOutOfAreaActivationForm";
import { Box } from "~/components/ui/box";
import { Spinner } from "~/components/ui/spinner";
import { useParams } from "react-router-dom";
import { useOutOfAreaActivationQuery } from "../hooks";

export const EditOOAActivationPage = () => {
  const params = useParams();
  const activationId = params.activationId as string;

  const { data, isLoading } = useOutOfAreaActivationQuery({ activationId });

  return (
    <Container maxWidth="sm">
      <Stack gap="xlarge">
        <BackButton />
        {isLoading || !data?.body ? (
          <Box display="flex" align="center" justify="center">
            <Spinner />
          </Box>
        ) : (
          <EditOutOfAreaActivationForm activation={data.body} />
        )}
      </Stack>
    </Container>
  );
};
