import { addWeeks, differenceInWeeks, subWeeks } from "date-fns";
import { useCallback, useState } from "react";
import type { HourlyAvailability } from "./useEditScheduleGrid";

type Params = {
  firstDayOfTheWeek: Date;
  hourlyAvailability: HourlyAvailability;
  onPasteAvailability: (availability: HourlyAvailability) => Promise<void>;
};

export const useCopyPasteAvailability = ({
  firstDayOfTheWeek,
  hourlyAvailability,
  onPasteAvailability,
}: Params) => {
  const [copiedAvailability, setCopiedAvailability] =
    useState<HourlyAvailability | null>(null);

  const handleCopySchedule = useCallback(() => {
    setCopiedAvailability(hourlyAvailability);
  }, [hourlyAvailability]);

  const handlePasteSchedule = useCallback(async () => {
    if (!copiedAvailability) {
      return;
    }

    const firstCopiedDate = copiedAvailability[0]?.date as Date;

    const isPastingToPreviousWeek = firstDayOfTheWeek < firstCopiedDate;
    const laterDate = isPastingToPreviousWeek
      ? firstCopiedDate
      : firstDayOfTheWeek;
    const earlierDate = isPastingToPreviousWeek
      ? firstDayOfTheWeek
      : firstCopiedDate;

    const diffInWeeks = differenceInWeeks(laterDate, earlierDate);

    const currentAvailabilityMap = new Map(
      hourlyAvailability.flatMap(item =>
        item.slots.map(slot => [`${item.date}-${slot.hour}`, slot])
      )
    );

    const updatedAvailability = copiedAvailability.map(copiedDate => {
      const date = isPastingToPreviousWeek
        ? subWeeks(copiedDate.date, diffInWeeks)
        : addWeeks(copiedDate.date, diffInWeeks);

      const slots = copiedDate.slots.map(slot => {
        if (!slot.committed) {
          return slot;
        }

        // Ignore any "Committed" copied blocks
        const slotInCurrentAvailability = currentAvailabilityMap.get(
          `${date}-${slot.hour}`
        );
        return slotInCurrentAvailability ?? slot;
      });

      return {
        date,
        slots,
      };
    });

    await onPasteAvailability(updatedAvailability);
  }, [
    firstDayOfTheWeek,
    copiedAvailability,
    hourlyAvailability,
    onPasteAvailability,
  ]);

  return {
    copiedAvailability,
    handleCopySchedule,
    handlePasteSchedule,
  };
};
