import { zodResolver } from "@hookform/resolvers/zod";
import { ChannelFormSchema, channelFormSchema } from "@ses-mams/validation";
import { useForm } from "react-hook-form";

type Params = {
  initialChannel?: Partial<ChannelFormSchema>;
};

export const useChannelForm = ({ initialChannel }: Params = {}) => {
  const form = useForm<ChannelFormSchema>({
    mode: "onBlur",
    resolver: zodResolver(channelFormSchema),
    defaultValues: initialChannel,
  });

  return form;
};
