import {
  ActivityRequestStatus,
  ActivityRequestWithAvailabilityCount,
} from "@ses-mams/api-contract";

import { Row, Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { formatDateTime } from "@ses-mams/react-utils";
import { Badge } from "~/components/ui/badge";
import { BadgeTones } from "~/components/ui/badge/Badge";
import { AvailabilityReportCell } from "~/components/common/availabilityReportCell";
import { formatActivityRequestStatusLabel } from "@ses-mams/react-utils";
import { RequestCard } from "../../../shared";
import { Checkbox } from "~/components/ui/checkbox";

export const ActivityListItem = ({
  item,
  shouldDisplayStatus,
  isBulkSelecting,
  selectedIds,
  onBulkSelect,
}: {
  item: ActivityRequestWithAvailabilityCount;
  shouldDisplayStatus: boolean;
  isBulkSelecting: boolean;
  selectedIds: Set<string>;
  onBulkSelect: (id: string) => void;
}) => {
  const {
    activity: { title, unit, start, end, id, schedule },
    status,
  } = item;

  const selected = selectedIds.has(id);

  const isRecurring = schedule && !schedule?.closedAt;

  return (
    <RequestCard
      to={`/requests/activities/${id}`}
      onClick={isBulkSelecting ? () => onBulkSelect(id) : undefined}
      selected={selected}
    >
      <Row sx={{ width: "100%" }} justify="space-between" align={"center"}>
        <Stack align="start" gap="medium" flex={1}>
          <Stack
            direction="row"
            justify="space-between"
            gap="small"
            align="start"
            sx={{ width: "100%" }}
          >
            <Text size="large" weight="medium">
              {title}
            </Text>

            {!!isRecurring && <Badge tone="infoMuted">Recurring</Badge>}
          </Stack>

          <Text>
            {unit.code ?? unit.name}
            {" from "}
            {formatDateTime(new Date(start))}{" "}
            {end && "to " + formatDateTime(new Date(end))}
          </Text>

          {shouldDisplayStatus && status && (
            <Badge tone={memberRequestStatusTone(status)} size="small">
              {formatActivityRequestStatusLabel(item.status, item.archived)}
            </Badge>
          )}

          <Stack direction="row" gap="xxsmall">
            <AvailabilityReportCell
              status="Committed"
              value={item.availabilityCounts.confirmed}
            />
            <AvailabilityReportCell
              status="Available"
              value={item.availabilityCounts.available}
            />
            <AvailabilityReportCell
              status="Conditional"
              value={item.availabilityCounts.conditional}
            />
            <AvailabilityReportCell
              status="Unavailable"
              value={item.availabilityCounts.unavailable}
            />
            <AvailabilityReportCell
              status="Unset"
              value={item.availabilityCounts.unset}
            />
          </Stack>
        </Stack>
        {isBulkSelecting ? <Checkbox checked={selected} /> : null}
      </Row>
    </RequestCard>
  );
};

const memberRequestStatusTone = (status: ActivityRequestStatus): BadgeTones => {
  switch (status) {
    case "AvailabilitySubmitted":
      return "criticalMuted";
    default:
      return "muted";
  }
};
