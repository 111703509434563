import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  ApiErrorSchema,
  PaginationQueryParamsSchema,
  SearchMembersFilterSchema,
  getPaginationResponseSchema,
} from "./common";
import { MemberSchema } from "./members";

const c = initContract();

const GroupInputSchema = z.object({
  name: z.string(),
  reportable: z.boolean(),
  capabilityIds: z.array(z.string()),
  memberIds: z.array(z.string()),
  selectAllMembersQuery: SearchMembersFilterSchema.optional(),
  availabilityMinThreshold: z.number().optional(),
  availabilityLookAheadDays: z.number().optional(),
});
const GroupOutputSchema = z.object({
  id: z.string(),
  name: z.string(),
  reportable: z.boolean(),
  capabilities: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      label: z.string(),
    })
  ),
  availabilityMinThreshold: z.number().optional(),
  availabilityLookAheadDays: z.number().optional(),
});

const GroupOrderInputSchema = z.object({
  groupIds: z.array(z.string()),
});

const groupsContract = c.router({
  get: {
    method: "GET",
    path: "/units/:unitId/groups/:id",
    pathParams: z.object({
      unitId: z.string(),
      id: z.string(),
    }),
    responses: {
      200: GroupOutputSchema,
      404: ApiErrorSchema,
    },
    summary: "Get the details of a group",
  },
  list: {
    method: "GET",
    path: "/groups",
    query: PaginationQueryParamsSchema.merge(
      z.object({
        query: z.coerce.string().trim().optional(),
      })
    ),
    responses: {
      200: getPaginationResponseSchema(GroupOutputSchema),
    },
    summary: "Get all groups of a unit",
  },
  create: {
    method: "POST",
    path: "/units/:unitId/groups",
    pathParams: z.object({
      unitId: z.string(),
    }),
    body: GroupInputSchema,
    responses: {
      201: GroupOutputSchema,
      403: ApiErrorSchema,
      422: ApiErrorSchema,
    },
    summary: "Create a new group",
  },
  update: {
    method: "PATCH",
    path: "/units/:unitId/groups/:id",
    pathParams: z.object({
      unitId: z.string(),
      id: z.string(),
    }),
    body: GroupInputSchema.extend({
      /**
       * @deprecated use addMemberIds instead
       */
      memberIds: z.array(z.string()).optional(),
      // Make Below optional to support legacy versions
      addMemberIds: z.array(z.string()).optional(),
      removeMemberIds: z.array(z.string()).optional(),
    }),
    responses: {
      200: GroupOutputSchema,
      403: ApiErrorSchema,
      404: ApiErrorSchema,
      422: ApiErrorSchema,
    },
    summary: "Update a group",
  },
  delete: {
    method: "DELETE",
    path: "/units/:unitId/groups/:id",
    pathParams: z.object({
      id: z.string(),
    }),
    body: null,
    responses: {
      204: c.response(),
      403: ApiErrorSchema,
      404: ApiErrorSchema,
    },
    summary: "Delete a group",
  },
  getGroupMembers: {
    method: "GET",
    path: "/units/:unitId/groups/:id/members",
    pathParams: z.object({
      id: z.string(),
    }),
    query: PaginationQueryParamsSchema.optional(),
    responses: {
      200: getPaginationResponseSchema(MemberSchema),
    },
    summary: "Get the members of a group",
  },
  setGroupOrder: {
    method: "POST",
    path: "/units/:unitId/group-order",
    pathParams: z.object({
      unitId: z.string(),
    }),
    body: GroupOrderInputSchema,
    responses: {
      200: z.array(GroupOutputSchema),
      403: ApiErrorSchema,
      422: ApiErrorSchema,
    },
    summary: "Set the display order of the groups in a unit",
  },
});

export type GroupInput = z.infer<typeof GroupInputSchema>;
export type Group = z.infer<typeof GroupOutputSchema>;

export { groupsContract, GroupOutputSchema };
