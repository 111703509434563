import { FormLabel as MuiFormLabel, styled } from "@mui/material";

type FormLabelProps = {
  id?: string;
  children: string;
};

export const FormLabel = ({ id, children }: FormLabelProps) => {
  return <StyledMuiFormLabel id={id}>{children}</StyledMuiFormLabel>;
};

const StyledMuiFormLabel = styled(MuiFormLabel)(({ theme }) => {
  const { tokens } = theme;

  return {
    ...tokens.typography.text.standard,
    color: tokens.colors.foreground.primary,
    fontWeight: tokens.typography.fontWeight.medium,
  };
});
