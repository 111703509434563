import { OutOfAreaActivationLevel } from "@ses-mams/api-contract";
import { Outlet } from "react-router-dom";
import { Box } from "~/components/ui/box";
import { OutOfAreaBanner } from "~/components/common/outOfAreaBanner";
import { createContext, useContext, useState } from "react";

export const OutOfAreaActivationLayout = () => {
  const [level, setLevel] = useState<OutOfAreaActivationLevel>();

  return (
    <OutOfAreaActivationLevelLayoutContext.Provider value={{ setLevel }}>
      <Box
        sx={theme => ({
          marginTop: -1 * theme.tokens.spacing.large,
          marginBottom: theme.tokens.spacing.large,
          // 1600px is the maxWidth in PageLayout.tsx
          marginX: `calc(min((-100vw + 1600px) / 2, 0px) - ${theme.tokens.spacing.large}px)`,
        })}
      >
        <OutOfAreaBanner level={level} />
      </Box>

      <Outlet />
    </OutOfAreaActivationLevelLayoutContext.Provider>
  );
};

const OutOfAreaActivationLevelLayoutContext = createContext<{
  setLevel: (level?: OutOfAreaActivationLevel) => void;
}>({ setLevel: () => null });

export const useOutOfAreaActivationLevelLayoutContext = () => {
  const ctx = useContext(OutOfAreaActivationLevelLayoutContext);

  if (!ctx) {
    throw new Error("Must be called with <OutOfAreaActivationLayout />");
  }

  return ctx;
};
