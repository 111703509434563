import { Container } from "@mui/material";
import { AddActivityForm } from "./components";
import { BackButton } from "~/components/common/navigation";
import { Stack } from "~/components/ui/stack";

export const AddActivityPage = () => {
  return (
    <Container maxWidth="sm">
      <Stack gap="xlarge">
        <BackButton />
        <AddActivityForm />
      </Stack>
    </Container>
  );
};
