import { Container } from "@mui/material";
import { OutOfAreaActivationLevel } from "@ses-mams/api-contract";
import { formatOutOfAreaActivationLevel } from "@ses-mams/react-utils";
import { Badge } from "~/components/ui/badge";
import { MapOutlineIcon } from "~/components/ui/icon";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

type OutOfAreaBannerProps = {
  level?: OutOfAreaActivationLevel;
};

export const OutOfAreaBanner = ({ level }: OutOfAreaBannerProps) => {
  return (
    <Stack background="accentSecondaryMuted" direction="row">
      <Container maxWidth="lg">
        <Stack
          direction="row"
          align="center"
          justify="space-between"
          paddingX="large"
          paddingY="small"
        >
          <Stack gap="xsmall" direction="row" align="center">
            <MapOutlineIcon tone="accentSecondary" />
            <Text tone="accentSecondary" weight="semi">
              OOAA Request
            </Text>
          </Stack>

          {level && (
            <Badge tone="accentSecondary">
              {formatOutOfAreaActivationLevel(level)}
            </Badge>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};
