import { useCallback } from "react";
import { ButtonBase } from "@mui/material";
import { Capability } from "@ses-mams/api-contract";
import { useDebouncedSearch } from "@ses-mams/react-utils";
import emptyListImageUrl from "~/assets/emptyList.png";
import { SearchInput } from "~/components/ui/searchInput";
import { Stack } from "~/components/ui/stack";
import { InfiniteList } from "~/components/ui/list";
import { Checkbox } from "~/components/ui/checkbox";
import { EmptyState } from "~/components/common/emptyState";
import { CapabilityListItem } from "~/components/common/capability";
import { useCapabilityListSearch } from "./useCapabilityListSearch";
import { Text } from "~/components/ui/text";

type SelectCapabilitiesListProps = {
  capabilities: Array<Capability>;
  removeCapability: (capability: Capability) => void;
  appendCapability: (capability: Capability) => void;
  unitId?: string;
  clusterId?: string;
  zoneId?: string;
  disclaimer?: string;
};

export const SelectCapabilitiesList = ({
  capabilities,
  removeCapability,
  appendCapability,
  unitId,
  clusterId,
  zoneId,
  disclaimer,
}: SelectCapabilitiesListProps) => {
  const { searchValue, setSearchValue, debouncedSearchValue } =
    useDebouncedSearch("");

  const { data, isLoading, isRefetching, hasNextPage, fetchNextPage } =
    useCapabilityListSearch({
      searchValue: debouncedSearchValue,
      unitId,
      clusterId,
      zoneId,
    });

  const renderItem = useCallback(
    (item: Capability) => {
      const isChecked = capabilities.some(({ id }) => id === item.id);

      return (
        <ButtonBase
          role="checkbox"
          aria-checked={isChecked}
          onClick={() =>
            isChecked ? removeCapability(item) : appendCapability(item)
          }
          sx={{ width: "100%" }}
        >
          <CapabilityListItem
            startAdornment={<Checkbox checked={isChecked} />}
            capability={item}
          />
        </ButtonBase>
      );
    },
    [capabilities]
  );

  const isRefreshing = isLoading || isRefetching;

  return (
    <Stack flex={1} gap="large">
      {disclaimer && (
        <Text tone="muted" size="small">
          {disclaimer}
        </Text>
      )}
      <SearchInput
        placeholder="Search"
        value={searchValue}
        onValueChange={setSearchValue}
      />

      <InfiniteList
        data={data}
        keyExtractor={item => item.id}
        renderItem={renderItem}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        listEmptyElement={
          isRefreshing ? null : (
            <EmptyState
              image={<img src={emptyListImageUrl} />}
              title="No capabilities found"
              description="There are no capabilities associated with your search. Please try another value."
            />
          )
        }
      />
    </Stack>
  );
};
