import {
  Capability,
  MemberSummary,
  OutOfAreaActivationRequestAvailabilityBlock,
  OutOfAreaActivationRole,
  OutOfAreaActivationRoleCategory,
} from "@ses-mams/api-contract";
import {
  AVAILABILITY_REPORT_STATUS_TO_BADGE_TONE_MAP,
  formatAvailabilityStatusLabel,
  formatDate,
  formatDateTime,
  formatMemberFullName,
  formatOutOfAreaActivationRoleCategory,
  useGetGroupedOutOfAreaActivationRoles,
} from "@ses-mams/react-utils";
import { useMemo, Fragment } from "react";
import { Heading } from "~/components/ui/heading";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { Badge } from "~/components/ui/badge";
import { MemberConditionalReason } from "~/components/common/memberStatus";
import { CapabilityBadge } from "../capability";

type OutOfAreaActivationRequestHistoryDetailsProps = {
  createdAt: string;
  member: MemberSummary & {
    capabilities: Array<Capability>;
  };
  availabilityBlocks: Array<OutOfAreaActivationRequestAvailabilityBlock>;
  roles: Array<OutOfAreaActivationRole>;
  comments?: string;
};

export const OutOfAreaActivationRequestHistoryDetails = ({
  createdAt,
  member,
  availabilityBlocks,
  roles,
  comments,
}: OutOfAreaActivationRequestHistoryDetailsProps) => {
  const getGroupedOutOfAreaActivationRoles =
    useGetGroupedOutOfAreaActivationRoles();

  const rolesByCategory = useMemo(
    () => getGroupedOutOfAreaActivationRoles(roles),
    [roles]
  );

  return (
    <Stack dividers gap="large">
      <Stack gap="small">
        <Heading>{formatDateTime(new Date(createdAt), "long")}</Heading>
        <Text size="large" tone="secondary" weight="semi">
          {formatMemberFullName(member)}
        </Text>
      </Stack>
      <Stack gap="xlarge">
        <Section title="Capabilities">
          <Stack gap="small">
            {member.capabilities.map(capability => (
              <Stack
                direction="row"
                justify="space-between"
                align="center"
                key={capability.id}
              >
                <Text noWrap align="start">
                  {capability.name}
                </Text>

                <CapabilityBadge capability={capability} />
              </Stack>
            ))}
          </Stack>
        </Section>

        <Section title="Roles">
          {rolesByCategory ? (
            <Stack gap="small" paddingTop="xxsmall">
              {Object.keys(rolesByCategory).map(category => {
                const roles =
                  rolesByCategory[category as keyof typeof rolesByCategory];

                return (
                  <Stack key={`${category}`} gap="xxsmall">
                    <Text weight="medium">
                      {formatOutOfAreaActivationRoleCategory(
                        category as OutOfAreaActivationRoleCategory
                      )}
                    </Text>
                    <Text>{roles.map(({ name }) => name).join(", ")}</Text>
                  </Stack>
                );
              })}
            </Stack>
          ) : (
            <Text>No roles selected.</Text>
          )}
        </Section>
        <Section title="Dates">
          {availabilityBlocks.length ? (
            availabilityBlocks.map(block => (
              <Fragment key={block.id}>
                <Stack
                  gap="small"
                  direction="row"
                  justify="space-between"
                  align="center"
                >
                  <Text>{`${formatDate(new Date(block.start))} - ${formatDate(
                    new Date(block.end)
                  )}`}</Text>

                  {block.availabilityStatus && (
                    <Badge
                      tone={
                        AVAILABILITY_REPORT_STATUS_TO_BADGE_TONE_MAP[
                          block.availabilityStatus
                        ]
                      }
                    >
                      {formatAvailabilityStatusLabel({
                        status: block.availabilityStatus,
                        useShort: true,
                      })}
                    </Badge>
                  )}
                </Stack>

                {block.availabilityStatus === "Conditional" &&
                  block.conditionalReason && (
                    <MemberConditionalReason
                      conditionalReason={block.conditionalReason}
                    />
                  )}
              </Fragment>
            ))
          ) : (
            <Text>No availability set.</Text>
          )}
        </Section>
        {comments && (
          <Section title="Comments">
            <Text>{comments}</Text>
          </Section>
        )}
      </Stack>
    </Stack>
  );
};

type SectionProps = {
  title: string;
  children: React.ReactNode;
};

const Section = ({ title, children }: SectionProps) => {
  return (
    <Stack gap="medium">
      <Text weight="semi">{title}</Text>
      {children}
    </Stack>
  );
};
