import { useTheme } from "@mui/material";
import { isSameDay } from "date-fns";

import { Stack } from "~/components/ui/stack";
import { Box } from "~/components/ui/box";

const COLOR = "#FF0000";

type Props = {
  cellDate: Date;
  cellHours: number[];
  cellHeight: number;
};

export const DayTimeMarker = (props: Props) => {
  const { tokens } = useTheme();

  const top = getTopPosition(props);
  if (top === null) {
    return null;
  }

  return (
    <Stack
      direction="row"
      align="center"
      sx={{
        position: "absolute",
        top: top,
        left: 0,
        right: 0,
      }}
    >
      <svg width={3} height={9} viewBox="0 0 3 9" fill={COLOR}>
        <path d="M0 8.9407e-08V9L3.85714 4.5L0 8.9407e-08Z" />
      </svg>
      <Box
        sx={{
          height: 3,
          flex: 1,
          backgroundColor: COLOR,
          borderWidth: 1,
          borderColor: tokens.colors.background.surface,
          borderLeftWidth: 0,
          borderRightWidth: 0,
        }}
      />
      <svg
        width={3}
        height={9}
        viewBox="0 0 3 9"
        fill={COLOR}
        style={{
          transform: "scaleX(-1)",
        }}
      >
        <path d="M0 8.9407e-08V9L3.85714 4.5L0 8.9407e-08Z" />
      </svg>
    </Stack>
  );
};

export const getTopPosition = ({
  cellDate,
  cellHours,
  cellHeight,
}: Props): number | null => {
  const now = new Date();
  const indexOfCellHour = cellHours.indexOf(now.getHours());

  const shouldShowMarker = indexOfCellHour !== -1 && isSameDay(cellDate, now);
  if (!shouldShowMarker) {
    return null;
  }

  return (
    // 9 (marker height) / 2
    ((now.getMinutes() + indexOfCellHour * 60) * cellHeight) /
      (60 * cellHours.length) -
    4.5
  );
};
