import { initContract } from "@ts-rest/core";
import { z } from "zod";

const c = initContract();

const OutOfAreaActivationRoleCategorySchema = z.enum([
  "IMT",
  "Field",
  "Aviation",
  "StateOperationsCentre",
]);

export const OutOfAreaActivationRoleSchema = z.object({
  id: z.string(),
  name: z.string(),
  category: OutOfAreaActivationRoleCategorySchema,
});

export const outOfAreaActivationRolesContract = c.router({
  list: {
    method: "GET",
    path: "/out-of-area-activation-roles",
    responses: {
      200: z.array(OutOfAreaActivationRoleSchema),
    },
    summary: "Get all out of area activation roles",
  },
});

export type OutOfAreaActivationRole = z.infer<
  typeof OutOfAreaActivationRoleSchema
>;

export type OutOfAreaActivationRoleCategory = z.infer<
  typeof OutOfAreaActivationRoleCategorySchema
>;
