import {
  OUT_OF_AREA_ESCALATION_TIME_UNIT_OPTIONS,
  OutOfAreaActivationFormSchema,
} from "@ses-mams/validation";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Stack } from "~/components/ui/stack";
import { SelectField, SelectItem } from "~/components/ui/selectField";
import { Text } from "~/components/ui/text";
import { Box } from "~/components/ui/box";
import { Switch } from "~/components/ui/switch";
import { IntegerField } from "~/components/ui/integerField";
import { useMemo, useState } from "react";
import { HelperText } from "~/components/ui/textField";

type Props = {
  isDisabled?: boolean;
};

export const EscalationTimesForm = ({ isDisabled }: Props) => {
  const [isUnitThresholdEnabled, setIsUnitThresholdEnabled] = useState(false);

  const [isClusterThresholdEnabled, setIsClusterThresholdEnabled] =
    useState(false);

  const {
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext<OutOfAreaActivationFormSchema>();

  const watchLevel = useWatch({
    control,
    name: "level",
  });

  const showUnitEscalationThreshold = useMemo(() => {
    if (!watchLevel) {
      return true;
    }

    return !["InterUnit", "IntraCluster"].includes(watchLevel);
  }, [watchLevel]);

  const showClusterEscalationThreshold = useMemo(() => {
    if (!watchLevel) {
      return true;
    }

    return ["InterZone", "State"].includes(watchLevel);
  }, [watchLevel]);

  if (!showUnitEscalationThreshold) {
    return null;
  }

  return (
    <Stack gap="medium">
      <Text weight="medium">Escalation Times</Text>
      <Stack
        direction="row"
        gap="large"
        align="center"
        justify="space-between"
        flex={1}
      >
        <Text weight="medium">Unit</Text>
        <Stack
          direction="row"
          gap="medium"
          justify="space-between"
          align="center"
          dividers
        >
          <Stack direction="row" gap="medium">
            <Controller
              name="unitEscalationThreshold.time"
              shouldUnregister
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <IntegerField
                  label="Unit Escalation Threshold Time"
                  onValueChange={onChange}
                  value={value}
                  hideLabel={true}
                  disabled={!isUnitThresholdEnabled || isDisabled}
                  onBlur={() => {
                    if (!value) {
                      onChange(0);
                    }
                    onBlur();
                  }}
                />
              )}
            />
            <Controller
              name="unitEscalationThreshold.unit"
              shouldUnregister
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Box sx={{ minWidth: 100 }}>
                  <SelectField
                    label="Unit Escalation Threshold Unit"
                    hideLabel={true}
                    onChange={value => {
                      onChange(value);
                      trigger("unitEscalationThreshold.time");
                    }}
                    onBlur={onBlur}
                    value={value}
                    disabled={!isUnitThresholdEnabled || isDisabled}
                  >
                    {OUT_OF_AREA_ESCALATION_TIME_UNIT_OPTIONS.map(u => (
                      <SelectItem key={u} value={u} onChange={onChange}>
                        {u}
                      </SelectItem>
                    ))}
                  </SelectField>
                </Box>
              )}
            />
          </Stack>
          <Switch
            value={isUnitThresholdEnabled}
            onChange={(_, value) => {
              setIsUnitThresholdEnabled(value);

              if (value === false) {
                setValue("unitEscalationThreshold", {
                  time: undefined,
                  unit: undefined,
                });
                return;
              }
              setValue("unitEscalationThreshold.time", 30);
              setValue("unitEscalationThreshold.unit", "Minute");
            }}
            disabled={isDisabled}
          />
        </Stack>
      </Stack>

      {errors.unitEscalationThreshold?.time?.message && (
        <HelperText role="alert">
          {errors.unitEscalationThreshold.time.message}
        </HelperText>
      )}

      {showClusterEscalationThreshold && (
        <>
          <Stack
            direction="row"
            gap="large"
            align="center"
            justify="space-between"
            flex={1}
          >
            <Text weight="medium">Cluster</Text>

            <Stack
              direction="row"
              gap="medium"
              justify="space-between"
              align="center"
              dividers
            >
              <Stack direction="row" gap="medium">
                <Controller
                  name="clusterEscalationThreshold.time"
                  shouldUnregister
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IntegerField
                      label="Cluster Escalation Threshold Time"
                      onValueChange={onChange}
                      value={value}
                      hideLabel={true}
                      disabled={!isClusterThresholdEnabled || isDisabled}
                      onBlur={onBlur}
                    />
                  )}
                />
                <Controller
                  name="clusterEscalationThreshold.unit"
                  shouldUnregister
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Box sx={{ minWidth: 100 }}>
                      <SelectField
                        label="Cluster Escalation Threshold Unit"
                        hideLabel={true}
                        onChange={value => {
                          onChange(value);
                          trigger("clusterEscalationThreshold.time");
                        }}
                        onBlur={onBlur}
                        value={value}
                        disabled={!isClusterThresholdEnabled || isDisabled}
                      >
                        {OUT_OF_AREA_ESCALATION_TIME_UNIT_OPTIONS.map(u => (
                          <SelectItem key={u} value={u} onChange={onChange}>
                            {u}
                          </SelectItem>
                        ))}
                      </SelectField>
                    </Box>
                  )}
                />
              </Stack>

              <Switch
                value={isClusterThresholdEnabled}
                onChange={(_, value) => {
                  setIsClusterThresholdEnabled(value);
                  if (value === false) {
                    setValue("clusterEscalationThreshold", {
                      time: undefined,
                      unit: undefined,
                    });
                    return;
                  }
                  setValue("clusterEscalationThreshold.time", 30);
                  setValue("clusterEscalationThreshold.unit", "Minute");
                }}
                disabled={isDisabled}
              />
            </Stack>
          </Stack>
          {errors.clusterEscalationThreshold?.time?.message && (
            <HelperText role="alert">
              {errors.clusterEscalationThreshold.time.message}
            </HelperText>
          )}
        </>
      )}
    </Stack>
  );
};
