import {
  Divider as MUIDivider,
  DividerProps as MUIDividerProps,
  useTheme,
} from "@mui/material";

import { BorderTones, BorderWidths } from "@ses-mams/react-utils";

type Props = {
  tone?: BorderTones;
  direction?: keyof typeof directionToOrientation;
  width?: BorderWidths;
  sx?: MUIDividerProps["sx"];
};

export const Divider = ({
  direction = "column",
  tone = "standard",
  width = "medium",
  sx = {},
}: Props) => {
  const {
    tokens: { border },
  } = useTheme();

  const orientation = directionToOrientation[direction];

  return (
    <MUIDivider
      flexItem
      orientation={orientation}
      sx={{
        borderColor: border.color[tone],
        [orientationToBorderProperty[orientation]]: border.width[width],
        ...sx,
      }}
    />
  );
};

const directionToOrientation = {
  column: "horizontal",
  "column-reverse": "horizontal",
  row: "vertical",
  "row-reverse": "vertical",
} as const;

const orientationToBorderProperty = {
  vertical: "borderRightWidth",
  horizontal: "borderBottomWidth",
} as const;
