import {
  Alert as MuiAlert,
  AlertProps as MuiAlertProps,
  AlertTitle as MuiAlertTitle,
} from "@mui/material";
import { forwardRef } from "react";
import {
  AlertCircleFilledIcon,
  AlertTriangleFilledIcon,
  CheckCircleFilledIcon,
} from "~/components/ui/icon";

export type AlertTone = "positive" | "caution" | "critical" | "info";

export type AlertProps = {
  tone: AlertTone;
  title: string;
  elevated?: boolean;
  message?: string;
  onClose?: () => void;
  sx?: MuiAlertProps["sx"];
};

export const Alert = forwardRef<HTMLDivElement, AlertProps>(
  ({ tone, title, elevated, message, onClose, sx }, forwardedRef) => {
    return (
      <MuiAlert
        ref={forwardedRef}
        role="alert"
        variant="filled"
        severity={toneToSeverity[tone]}
        elevation={elevated ? 4 : undefined}
        onClose={onClose}
        iconMapping={{
          success: <CheckCircleFilledIcon tone="inverted" />,
          warning: <AlertCircleFilledIcon tone="inverted" />,
          error: <AlertTriangleFilledIcon tone="inverted" />,
          info: <AlertCircleFilledIcon tone="inverted" />,
        }}
        sx={[
          theme => ({ borderRadius: theme.tokens.border.radius.small }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <MuiAlertTitle>{title}</MuiAlertTitle>
        {message}
      </MuiAlert>
    );
  }
);

const toneToSeverity = {
  positive: "success",
  caution: "warning",
  critical: "error",
  info: "info",
} as const;
