import { Controller, useFormContext, useWatch } from "react-hook-form";
import { SelectField, SelectItem } from "~/components/ui/selectField";
import { OUT_OF_AREA_ACTIVATION_LEVEL_OPTIONS } from "@ses-mams/validation";
import { z } from "zod";
import { OutOfAreaActivationLevel } from "@ses-mams/api-contract";
import {
  formatOutOfAreaActivationLevel,
  useUpdateEffect,
} from "@ses-mams/react-utils";
import { AllUnitsSelector } from "~/components/common/allUnitsSelector";
import { ClusterSelector } from "~/components/common/clusterSelector";
import { ZoneSelector } from "~/components/common/zoneSelector";

export const ooaaAuditFiltersFormSchema = z.object({
  levelOfOoaaRequest: z
    .enum(OUT_OF_AREA_ACTIVATION_LEVEL_OPTIONS as [OutOfAreaActivationLevel])
    .nullable()
    .optional(),
  ooaaRequestActivatedForId: z.string().nullable().optional(),
});

export const OoaaAuditLogsFilters = () => {
  const { control, setValue } =
    useFormContext<z.infer<typeof ooaaAuditFiltersFormSchema>>();

  const activationLevelValue = useWatch({
    control,
    name: "levelOfOoaaRequest",
  });

  useUpdateEffect(() => {
    if (activationLevelValue) {
      setValue("ooaaRequestActivatedForId", undefined);
    }
  }, [activationLevelValue]);

  return (
    <>
      <Controller
        name="levelOfOoaaRequest"
        control={control}
        render={({ field: { value, onChange } }) => (
          <SelectField
            label="Level of Request (OOAA)"
            value={value}
            onChange={onChange}
          >
            {OUT_OF_AREA_ACTIVATION_LEVEL_OPTIONS.map(option => (
              <SelectItem key={option} value={option}>
                {formatOutOfAreaActivationLevel(option)}
              </SelectItem>
            ))}
          </SelectField>
        )}
      />

      {activationLevelValue && activationLevelValue !== "State" && (
        <Controller
          name="ooaaRequestActivatedForId"
          control={control}
          render={({ field: { value, onChange } }) =>
            activationLevelValue === "InterUnit" ? (
              <AllUnitsSelector
                label="Activate for"
                selectedUnitId={value ?? undefined}
                setSelectedUnit={unit => onChange(unit.id)}
                disabled={false}
              />
            ) : ["Inter-Cluster", "Intra-Cluster"].includes(
                activationLevelValue
              ) ? (
              <ClusterSelector
                label="Activate for"
                selectedClusterId={value ?? undefined}
                setSelectedCluster={cluster => onChange(cluster.id)}
              />
            ) : (
              <ZoneSelector
                label="Activate for"
                selectedZoneId={value ?? undefined}
                setSelectedZone={zone => onChange(zone.id)}
              />
            )
          }
        />
      )}
    </>
  );
};
