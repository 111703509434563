import { Container } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Heading } from "~/components/ui/heading";
import { Stack } from "~/components/ui/stack";
import { useToast } from "~/components/ui/toast";
import { useAuth } from "~/context/auth";
import { tsr } from "~/utils/client";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "~/components/ui/textField";
import { Button } from "~/components/ui/button";
import { ApiErrorResponse } from "@ses-mams/api-contract";
import { ErrorHttpStatusCode } from "@ts-rest/core";

type ApiErrorPayload = {
  status: ErrorHttpStatusCode;
  body: ApiErrorResponse;
};

const impersonateFormSchema = z.object({
  memberId: z
    .string({
      required_error: "Member ID is required.",
    })
    .trim()
    .min(1, "Member ID is required"),
});

type ImpersonateForm = z.infer<typeof impersonateFormSchema>;

export const ImpersonatePage = () => {
  const { login } = useAuth();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<ImpersonateForm>({
    resolver: zodResolver(impersonateFormSchema),
  });

  const { isPending: isMutating, mutateAsync } =
    tsr.auth.impersonate.useMutation();

  const onSubmit = async ({ memberId }: ImpersonateForm) => {
    try {
      await mutateAsync(
        {
          body: { memberId },
        },
        {
          onSuccess: response => {
            login(response.body);
            navigate(-1);
          },
        }
      );
    } catch (error) {
      const typedError = error as ApiErrorPayload | undefined;

      let message = `Failed to impersonate the Member ${memberId} due to an unexpected Error.`;

      if (typedError?.status && [400, 401, 403].includes(typedError.status)) {
        message = `Failed to impersonate the Member ${memberId}. ${typedError?.body.message}`;
      }

      addToast({
        title: "Error",
        message,
        tone: "caution",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container maxWidth="sm">
        <Stack gap="medium">
          <Heading level="1">Impersonate</Heading>
          <TextField
            {...register("memberId")}
            label="Member ID"
            placeholder="400xxx"
            errorMessage={errors.memberId?.message}
          />

          <Button type="submit" disabled={isMutating || !isValid}>
            Done
          </Button>
        </Stack>
      </Container>
    </form>
  );
};
