import { useNavigate } from "react-router-dom";
import { TrashOutlineIcon } from "~/components/ui/icon";
import { Button } from "~/components/ui/button";
import { useRemoveActivityScheduleMembers } from "../../hooks";

type Props = {
  scheduleId: string;
  memberId: string;
};

export const RemoveButton = ({ scheduleId, memberId }: Props) => {
  const navigate = useNavigate();

  const { handleConfirmRemoveMembers, isPending } =
    useRemoveActivityScheduleMembers({
      scheduleId,
      confirmationText:
        "Please confirm that you'd like to remove yourself from this recurring activity.",
      onSuccess: () => navigate("/requests?tab=activity"),
    });

  return (
    <Button
      variant="destructive"
      busy={isPending}
      startIcon={TrashOutlineIcon}
      onClick={() => handleConfirmRemoveMembers([memberId])}
    >
      Remove
    </Button>
  );
};
