import { captureException } from "@sentry/react";
import { OutOfAreaActivationRequestStatus } from "@ses-mams/api-contract";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "~/components/ui/button";
import { useConfirmationDialog } from "~/components/ui/confirmationDialog";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";

type RevertApprovalButtonProps = {
  requestId: string;
  status: OutOfAreaActivationRequestStatus;
};

export const RevertApprovalButton = ({
  requestId,
  status,
}: RevertApprovalButtonProps) => {
  const { showConfirmationDialog } = useConfirmationDialog();
  const { addToast } = useToast();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } =
    tsr.outOfAreaActivationApprovals.revert.useMutation();

  const onConfirm = useCallback(async () => {
    try {
      await mutateAsync({ params: { requestId } });

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["out-of-area-activation-approvals-v2"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["out-of-area-activation-requests"],
        }),
      ]);

      navigate("/out-of-area-activation-approvals");
    } catch (error) {
      captureException(error);

      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [requestId]);

  const label = useMemo(() => {
    switch (status) {
      case "UnitApprovalDeclined":
      case "ClusterApprovalDeclined":
      case "ZoneApprovalDeclined":
        return "Revert Decline";
      default:
        return "Revert Approval";
    }
  }, [status]);

  return (
    <Button
      busy={isPending}
      onClick={() =>
        showConfirmationDialog({
          text: "Please confirm you want to revert this action?",
          onConfirm,
        })
      }
    >
      {label}
    </Button>
  );
};
