import { useNavigate } from "react-router-dom";
import { Box } from "~/components/ui/box";
import { Button } from "~/components/ui/button";
import { ChevronLeftOutlineIcon } from "~/components/ui/icon";
import { Stack } from "~/components/ui/stack";

type BackButtonProps = {
  to?: string;
};

export const BackButton = ({ to }: BackButtonProps) => {
  const navigate = useNavigate();

  return (
    <Box alignSelf="start">
      <Button
        variant="secondary"
        // Need to fork the functions satisfy typescript.
        onClick={() => (to ? navigate(to) : navigate(-1))}
      >
        <Stack direction="row" gap="xsmall">
          <ChevronLeftOutlineIcon />
          Back
        </Stack>
      </Button>
    </Box>
  );
};
