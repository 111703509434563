import { List, ListItem } from "@mui/material";
import { Box } from "~/components/ui/box";
import { SectionHeader } from "./SectionHeader";
import { useCallback, useMemo } from "react";

export type RenderSectionHeaderParams<T> = {
  title: string;
  data: Array<T>;
};

type SectionListProps<T> = {
  sections: { title: string; data: Array<T> }[];
  renderItem: (item: T) => React.ReactElement | null;
  renderSectionHeader?: (
    params: RenderSectionHeaderParams<T>
  ) => React.ReactElement | null;
  keyExtractor: (item: T, index: number) => string | number;
  listEmptyElement?: React.ReactNode;
  listItemDivider?: boolean;
};

export const SectionList = <T,>({
  sections,
  keyExtractor,
  renderItem,
  listItemDivider,
  listEmptyElement,
  renderSectionHeader,
}: SectionListProps<T>) => {
  const handleRenderSectionHeader = useCallback(
    (params: RenderSectionHeaderParams<T>) => {
      if (renderSectionHeader) {
        return renderSectionHeader(params);
      }

      return <SectionHeader>{params.title}</SectionHeader>;
    },
    [renderSectionHeader]
  );

  const hasData = useMemo(() => sections.some(s => s.data.length), [sections]);

  return (
    <Box>
      {hasData
        ? sections.map(({ title, data }, index) => {
            if (!data.length) {
              return null;
            }

            return (
              <Box key={`${index}-${title}`}>
                {handleRenderSectionHeader({ title, data })}
                <List disablePadding>
                  {data.map((item, index) => (
                    <ListItem
                      key={keyExtractor(item, index)}
                      disablePadding
                      divider={index !== data.length - 1 && listItemDivider}
                    >
                      {renderItem(item)}
                    </ListItem>
                  ))}
                </List>
              </Box>
            );
          })
        : listEmptyElement}
    </Box>
  );
};
