import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  ApiErrorSchema,
  PaginationQueryParamsSchema,
  getPaginationResponseSchema,
} from "./common";
import { ZoneSchema } from "./zones";

const c = initContract();

export const ClusterSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional(),
  address: z.string().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  zone: ZoneSchema.optional(),
});

export const clustersContract = c.router({
  get: {
    method: "GET",
    path: "/clusters/:id",
    pathParams: z.object({
      id: z.string(),
    }),
    responses: {
      200: ClusterSchema,
      404: ApiErrorSchema,
    },
    summary: "Get the details of a cluster",
  },
  list: {
    method: "GET",
    path: "/clusters",
    query: PaginationQueryParamsSchema.merge(
      z.object({
        query: z.coerce.string().trim().optional(),
        memberClustersOnly: z.boolean().optional(),
      })
    ),
    responses: {
      200: getPaginationResponseSchema(ClusterSchema),
    },
    summary: "Get all clusters",
  },
});

export type Cluster = z.infer<typeof ClusterSchema>;
