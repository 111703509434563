import {
  Activation,
  ActivationType,
  ContactGroup,
  Group,
  Member,
} from "@ses-mams/api-contract";
import { useForm } from "react-hook-form";
import {
  activationFormSchema,
  ActivationFormSchema,
} from "@ses-mams/validation";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  formatDateForServer,
  formatSelectAllMembersQuery,
  formatTwentyFourHourTime,
  SelectAllMembersQuery,
} from "@ses-mams/react-utils";
import { useDefaultUserUnitAndAddress } from "~/components/common/eventForm";
import { addHours } from "date-fns";

type Params = {
  initialActivation?: Activation;
};
export const useActivationForm = ({ initialActivation }: Params) => {
  const defaultUserUnitAndAddress = useDefaultUserUnitAndAddress();

  const defaultStart = new Date();
  const defaultEnd = addHours(defaultStart, 12);

  return useForm<ActivationFormSchema>({
    resolver: zodResolver(activationFormSchema),
    defaultValues: initialActivation
      ? transformActivationToForm(initialActivation)
      : {
          type: "" as ActivationType, // to prevent "A component is changing an uncontrolled input to be controlled" console warning
          start: {
            date: defaultStart,
            time: formatTwentyFourHourTime(defaultStart),
          },
          end: {
            date: defaultEnd,
            time: formatTwentyFourHourTime(defaultEnd),
          },
          ...defaultUserUnitAndAddress,
        },
  });
};

const transformActivationToForm = (
  activation: Activation
): ActivationFormSchema => {
  const {
    start,
    end,
    location = "",
    latitude = 0,
    longitude = 0,
    ...data
  } = activation;

  const startDate = new Date(start);
  const endDate = new Date(end);

  return {
    ...data,
    start: {
      date: startDate,
      time: formatTwentyFourHourTime(startDate),
    },
    end: {
      date: endDate,
      time: formatTwentyFourHourTime(endDate),
    },
    location,
    latitude,
    longitude,
  };
};

export const transformActivationFormForCreate = (
  formData: ActivationFormSchema,
  members: Array<Member>,
  groups: Array<Group>,
  contactGroups: Array<ContactGroup>,
  requestAvailableMembersOnly: boolean,
  selectAllMembersQuery?: SelectAllMembersQuery
) => {
  return {
    ...transformActivationFormForUpdate(formData),
    recipientMemberIds: members.map(m => m.id),
    recipientGroupIds: groups.map(g => g.id),
    recipientContactGroupIds: contactGroups.map(cg => cg.id),
    requestAvailableMembersOnly,
    selectAllMembersQuery: formatSelectAllMembersQuery(selectAllMembersQuery),
  };
};

export const transformActivationFormForUpdate = (
  formData: ActivationFormSchema
) => {
  const { unit, start, end, ...data } = formData;

  return {
    ...data,
    unitId: unit.id,
    start: formatDateForServer(start.date),
    end: formatDateForServer(end.date),
  };
};
