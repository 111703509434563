import { useCallback, useRef, useState } from "react";
import { Property } from "csstype";
import { useSprings, animated, config } from "@react-spring/web";
import { useTheme } from "@mui/material";
import { useDrag } from "@use-gesture/react";
import { ReactDOMAttributes } from "@use-gesture/react/dist/declarations/src/types";
import { useUpdateEffect } from "@ses-mams/react-utils";
import { Box } from "~/components/ui/box";

export type DraggableRenderItemParams<T> = {
  item: T;
  bind: ReactDOMAttributes;
};

export type DraggableListProps<T> = {
  data: Array<T>;
  renderItem: ({
    item,
    bind,
  }: DraggableRenderItemParams<T>) => React.ReactElement;
  onOrderChange: (data: Array<T>) => void;
};

export const DraggableList = <T,>({
  data,
  renderItem,
  onOrderChange,
}: DraggableListProps<T>) => {
  const {
    tokens: {
      colors: { background },
    },
  } = useTheme();

  const [itemHeight, setItemHeight] = useState(88);

  const [internalData, setInternalData] = useState(data);

  const reorderAnimationFn = useReorderAnimationProps(itemHeight);

  const order = useRef(internalData.map((_, index) => index));

  const [springs, api] = useSprings(
    internalData.length,
    reorderAnimationFn(order.current)
  );

  useUpdateEffect(() => {
    const newOrder = data.map((_, index) => index);
    order.current = newOrder;

    setInternalData(data);
  }, [data]);

  const bind = useDrag(
    ({ args: [originalIndex], active, first, movement: [, y], event }) => {
      event.preventDefault();
      event.stopPropagation();

      const curIndex = order.current.indexOf(originalIndex);

      const curRow = clamp(
        Math.round((curIndex * itemHeight + y) / itemHeight),
        0,
        internalData.length - 1
      );
      const newOrder = swap(order.current, curIndex, curRow);
      const newData = swap(internalData, curIndex, curRow);

      api.start(
        reorderAnimationFn(newOrder, active, first, originalIndex, curIndex, y)
      );

      if (!active) {
        order.current = newOrder;
        onOrderChange(newData);
        setInternalData(newData);
      }
    }
  );

  return (
    <Box
      position="relative"
      display="flex"
      direction="column"
      sx={{ width: "100%" }}
    >
      {springs.map(({ zIndex, shadow, y, scale, position }, i) => {
        return (
          <animated.div
            key={i}
            ref={r => {
              if (r && i === 0) {
                setItemHeight(r.clientHeight);
              }
            }}
            style={{
              y,
              scale,
              zIndex,
              display: "flex",
              overflowX: "hidden",
              width: "100%",
              position: position.to(p => p as Property.Position),
              boxShadow: shadow.to(
                s => `${background.backdrop} 0px ${s}px ${s}px 0px`
              ),
            }}
          >
            {renderItem({ item: internalData[i], bind: bind(i) })}
          </animated.div>
        );
      })}
    </Box>
  );
};

const useReorderAnimationProps = (itemHeight: number) => {
  return useCallback(
    (
      order: number[],
      active = false,
      first = true,
      originalIndex = 0,
      curIndex = 0,
      y = 0
    ) =>
      (index: number) =>
        active && index === originalIndex
          ? {
              position: "absolute",
              y: curIndex * itemHeight + y,
              scale: 1.04,
              zIndex: 1,
              shadow: 8,
              immediate: (key: string) => first || key === "zIndex",
              config: (key: string) =>
                key === "y" ? config.stiff : config.default,
            }
          : active
            ? {
                position: "absolute",
                y: order.indexOf(index) * itemHeight,
                scale: 1,
                zIndex: 0,
                shadow: 0,
                immediate: first,
              }
            : {
                position: "relative",
                y: 0,
                scale: 1,
                zIndex: 0,
                shadow: 0,
                immediate: true,
              },
    [itemHeight]
  );
};

const clamp = (value: number, min: number, max: number) =>
  Math.max(min, Math.min(value, max));

const swap = <T,>(arr: T[], a: number, b: number): T[] => {
  const copy = [...arr];
  const [index] = copy.splice(a, 1);

  copy.splice(b, 0, index);

  return copy;
};
