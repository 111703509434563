import { OutOfAreaActivationLevel } from "@ses-mams/api-contract";
import { TimeRange } from "@ses-mams/validation";
import isEqual from "lodash/isEqual";
import { createContext, useContext, useMemo, useState } from "react";

export const DEFAULT_REQUEST_LIST_FILTERS = {
  urgentRequests: true,
  nonUrgentRequests: true,
  archivedOnly: false,
  level: null,
  activationTimeRange: {
    start: null,
    end: null,
  },
  unit: null,
  cluster: null,
  zone: null,
};

export type RequestListFilters = {
  urgentRequests: boolean;
  nonUrgentRequests: boolean;
  archivedOnly: boolean;
  activationTimeRange: TimeRange;
  level?: OutOfAreaActivationLevel | null;
  unit?: {
    name: string;
    id: string;
  } | null;
  cluster?: {
    name: string;
    id: string;
  } | null;
  zone?: {
    name: string;
    id: string;
  } | null;
};

type RequestListFiltersContextType = {
  filters: RequestListFilters;
  setFilters: React.Dispatch<React.SetStateAction<RequestListFilters>>;
  unitFilters: RequestListFilters;
  setUnitFilters: React.Dispatch<React.SetStateAction<RequestListFilters>>;
  areFiltersApplied: boolean;
  areUnitFiltersApplied: boolean;
};

const RequestListFiltersContext = createContext<RequestListFiltersContextType>({
  filters: DEFAULT_REQUEST_LIST_FILTERS,
  setFilters: () => null,
  unitFilters: DEFAULT_REQUEST_LIST_FILTERS,
  setUnitFilters: () => null,
  areFiltersApplied: false,
  areUnitFiltersApplied: false,
});

export const RequestListFiltersContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [filters, setFilters] = useState<RequestListFilters>(
    DEFAULT_REQUEST_LIST_FILTERS
  );
  const [unitFilters, setUnitFilters] = useState<RequestListFilters>(
    DEFAULT_REQUEST_LIST_FILTERS
  );

  const value = useMemo(
    () => ({
      filters,
      setFilters,
      unitFilters,
      setUnitFilters,
      areFiltersApplied: !isEqual(filters, DEFAULT_REQUEST_LIST_FILTERS),
      areUnitFiltersApplied: !isEqual(
        unitFilters,
        DEFAULT_REQUEST_LIST_FILTERS
      ),
    }),
    [filters, setFilters, unitFilters, setUnitFilters]
  );

  return (
    <RequestListFiltersContext.Provider value={value}>
      {children}
    </RequestListFiltersContext.Provider>
  );
};

export const useRequestListFiltersContext = () => {
  const ctx = useContext(RequestListFiltersContext);

  if (!ctx) {
    throw new Error(
      "Must be called within <RequestListFiltersContextProvider />"
    );
  }

  return ctx;
};
