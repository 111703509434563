import {
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
} from "@mui/material";

export const MenuItem = ({ sx, ...props }: MuiMenuItemProps) => {
  return (
    <MuiMenuItem
      {...props}
      sx={[
        theme => ({
          ...theme.tokens.typography.text.standard,
          borderRadius: theme.tokens.border.radius.xsmall,
          minHeight: "auto",
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};
