import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

type Props = {
  title: string;
  value?: string | Array<string> | null;
  renderItem?: (item: string) => React.ReactNode;
};

export const DataItem = ({ title, value, renderItem }: Props) => {
  const values = toValues(value);

  if (!values) {
    return;
  }

  return (
    <Stack gap="xsmall" flex={1}>
      <Text size="large" weight="semi">
        {title}
      </Text>
      {values.map(value => {
        if (renderItem) {
          return renderItem(value);
        }

        return (
          <Text size="large" weight="medium" key={value}>
            {value}
          </Text>
        );
      })}
    </Stack>
  );
};

const toValues = (
  value?: string | Array<string> | null
): Array<string> | undefined => {
  if (typeof value === "undefined" || value === null) {
    return undefined;
  } else if (Array.isArray(value)) {
    return value.length > 0 ? value : undefined;
  } else {
    return [value];
  }
};
