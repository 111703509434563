import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  ApiErrorSchema,
  ArrayOfNumberStringSchema,
  PaginationQueryParamsSchema,
  getPaginationResponseSchema,
} from "./common";
import {
  OutOfAreaActivationAvailabilityInputSchema,
  OutOfAreaActivationRequestHistorySchema,
} from "./outOfAreaActivationRequest";
import {
  OutOfAreaActivationRequestStatusSchema,
  OutOfAreaActivationSchema,
  OutOfAreaActivationRequestSchema,
} from "./outOfAreaActivation";

const c = initContract();

const OutOfAreaActivationApprovalSchema = z.object({
  request: OutOfAreaActivationRequestSchema,
  activation: OutOfAreaActivationSchema,
  history: OutOfAreaActivationRequestHistorySchema,
  revertable: z.boolean(),
});

export const outOfAreaActivationApprovalsContract = c.router({
  get: {
    method: "GET",
    path: "/out-of-area-activation-approvals/:requestId",
    pathParams: z.object({
      requestId: z.string(),
    }),
    responses: {
      200: OutOfAreaActivationApprovalSchema,
      404: ApiErrorSchema,
    },
    summary: "Get the details of an out-of-area activation approval",
  },
  listApprovals: {
    method: "GET",
    path: "/out-of-area-activation-approvals",
    query: PaginationQueryParamsSchema.merge(
      z.object({
        state: z.enum(["pending", "previous"]).optional().default("pending"),
        status: OutOfAreaActivationRequestStatusSchema.optional(),
        roleIds: ArrayOfNumberStringSchema.optional(),
        unitIds: ArrayOfNumberStringSchema.optional(),
        clusterIds: ArrayOfNumberStringSchema.optional(),
        zoneIds: ArrayOfNumberStringSchema.optional(),
      })
    ),
    responses: {
      200: getPaginationResponseSchema(OutOfAreaActivationApprovalSchema),
    },
    summary: "Get all out-of-area activation approvals",
  },
  approve: {
    method: "POST",
    path: "/out-of-area-activation-approvals/approve",
    responses: {
      200: z.array(OutOfAreaActivationApprovalSchema),
    },
    body: z.object({
      requestIds: z.array(z.string()),
      comments: z.string().optional(),
      overrideRoleIds: z.array(z.string()).optional(),
      overrideAvailabilityBlocks: z
        .array(OutOfAreaActivationAvailabilityInputSchema)
        .optional(),
    }),
    summary: "Bulk approve up to 100 out-of-area activation approvals",
  },
  decline: {
    method: "POST",
    path: "/out-of-area-activation-approvals/decline",
    responses: {
      200: z.array(OutOfAreaActivationApprovalSchema),
    },
    body: z.object({
      requestIds: z.array(z.string()),
      reason: z.string(),
      comments: z.string().optional(),
    }),
    summary: "Bulk decline up to 100 out-of-area activation approvals",
  },
  listDeclineReasons: {
    method: "GET",
    path: "/out-of-area-activation-approvals/decline/reasons",
    responses: {
      200: z.array(z.string()),
    },
    summary: "List decline reasons",
  },
  revert: {
    method: "POST",
    path: "/out-of-area-activation-approvals/:requestId/revert",
    pathParams: z.object({
      requestId: z.string(),
    }),
    body: null,
    responses: {
      200: OutOfAreaActivationApprovalSchema,
      403: ApiErrorSchema,
    },
    summary: "Revert the most recent approve/decline action on this request",
  },
});

export type OutOfAreaActivationApproval = z.infer<
  typeof OutOfAreaActivationApprovalSchema
>;
