import { tsr } from "~/utils/client";

export const useOutOfAreaActivationRequestHistoryDetails = (
  requestId: string
) => {
  return tsr.outOfAreaActivationRequests.getHistoryDetails.useQuery({
    queryKey: [
      "out-of-area-activation-requests",
      "details",
      { requestId },
      "history",
    ],
    queryData: {
      params: { requestId },
    },
  });
};
