import { Container } from "@mui/material";
import { useAvailabilityBlocksForm } from "@ses-mams/react-utils";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box } from "~/components/ui/box";
import { AvailabilityBlocksForm } from "~/components/common/availabilityBlocksForm";
import { Heading } from "~/components/ui/heading";
import { Stack } from "~/components/ui/stack";
import { Button } from "~/components/ui/button";
import { Spinner } from "~/components/ui/spinner";
import { BackButton } from "~/components/common/navigation";
import { useMaybeRequestIdSearchParam } from "./hooks/useMaybeRequestIdSearchParam";
import { useOutOfAreaApprovalQuery } from "./hooks/useOutOfAreaApprovalQuery";
import { DeploymentDetails } from "../requests/outOfAreaActivations/components";
import { ApproveCommentsDrawer } from "./components/OutOfAreaApproval";

export const OOAAApprovalOverrideAvailabilityPage = () => {
  const [searchParams] = useSearchParams();
  const requestIds = searchParams.get("requestIds") as string;
  const roleIds = searchParams.get("roleIds") as string;

  const maybeRequestId = useMaybeRequestIdSearchParam();

  const { isLoading: isLoadingApproval, data: approvalData } =
    useOutOfAreaApprovalQuery(maybeRequestId);

  const deployments = approvalData?.body?.activation?.deployments;
  const existingAvailabilityBlocks =
    approvalData?.body?.request?.availabilityBlocks;

  const [isApproveDrawerOpen, setIsApproveDrawerOpen] = useState(false);

  const { control, trigger, getValues, handleSubmit, watch, reset } =
    useAvailabilityBlocksForm(deployments);

  const watchAvailabilityBlocks = watch("availabilityBlocks");

  useEffect(() => {
    if (!existingAvailabilityBlocks?.length) {
      return;
    }

    reset({
      availabilityBlocks: existingAvailabilityBlocks.map(b => ({
        availabilityStatus: b.availabilityStatus || undefined,
        conditionalReason: b.conditionalReason || undefined,
        start: new Date(b.start),
        end: new Date(b.end),
      })),
    });
  }, [existingAvailabilityBlocks]);

  if (maybeRequestId && isLoadingApproval) {
    return (
      <Box display="flex" grow={1} justify="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <form onSubmit={handleSubmit(() => setIsApproveDrawerOpen(true))}>
        <Stack gap="xlarge">
          <BackButton />

          <Stack direction="row" align="center" justify="space-between">
            <Heading level="2">Availability</Heading>

            <Button type="submit">Done</Button>
          </Stack>

          <DeploymentDetails deployments={deployments} />

          <AvailabilityBlocksForm
            control={control}
            trigger={trigger}
            getValues={getValues}
          />
        </Stack>
      </form>

      <ApproveCommentsDrawer
        open={isApproveDrawerOpen}
        requestIds={requestIds.split(",")}
        roleIds={roleIds.split(",")}
        availabilityBlocks={watchAvailabilityBlocks}
        onClose={() => setIsApproveDrawerOpen(false)}
      />
    </Container>
  );
};
