import { useCallback, useMemo, useState } from "react";
import { Member } from "@ses-mams/api-contract";

export const useActivityScheduleMemberSelectedState = () => {
  const [selectedMembers, setSelectedMembers] = useState<Array<Member>>([]);

  const clearSelectedMembers = useCallback(() => {
    setSelectedMembers([]);
  }, []);

  const selectedMemberIds = useMemo(
    () => new Set(selectedMembers.map(m => m.id)),
    [selectedMembers]
  );

  return {
    setSelectedMembers,
    clearSelectedMembers,
    selectedMemberIds,
  };
};
