import { z } from "zod";
import {
  eventDatesFormSchema,
  eventOtherFieldsFormSchema,
  validateDatesSchema,
} from "./event";

export const activityFormSchema = z.intersection(
  eventOtherFieldsFormSchema,
  eventDatesFormSchema.required().superRefine(validateDatesSchema)
);

export type ActivityFormSchema = z.infer<typeof activityFormSchema>;
