import React, { useCallback, useEffect, useRef } from "react";

import { Box } from "../box";
import { Stack } from "../stack";

type InfiniteScrollProps = React.PropsWithChildren & {
  loadMore: () => void;
  hasMore: boolean;
  loader: React.ReactNode;
  endMessage?: React.ReactNode;
};

export const InfiniteScroll = ({
  loadMore,
  hasMore,
  loader,
  children,
  endMessage,
}: InfiniteScrollProps) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const observerTarget = useRef(null);

  const handleIntersect = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting && hasMore) {
        loadMore();
      }
    },
    [loadMore, hasMore]
  );

  useEffect(() => {
    observerRef.current = new IntersectionObserver(handleIntersect, {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    });

    if (observerTarget.current) {
      observerRef.current.observe(observerTarget.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [handleIntersect]);

  return (
    <Stack gap="large">
      {children}
      <Box
        ref={observerTarget}
        sx={{ ...(hasMore ? {} : { display: "none" }) }}
      >
        {hasMore && loader}
      </Box>
      {!hasMore && endMessage}
    </Stack>
  );
};
