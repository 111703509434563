import { tsr } from "~/utils/client";
import { Spinner } from "~/components/ui/spinner";
import { Autocomplete } from "~/components/ui/autocomplete";
import { useCallback, useEffect, useState } from "react";
import { Cluster } from "@ses-mams/api-contract";

type Props = {
  selectedClusterId?: string;
  setSelectedCluster: (cluster: Cluster) => void;
  label: string;
  errorMessage?: string;
  disabled?: boolean;
};

export const ClusterSelector = ({
  label,
  selectedClusterId,
  setSelectedCluster,
  errorMessage,
  disabled,
}: Props) => {
  const [internalSelectedCluster, setInternalSelectedCluster] =
    useState<Cluster | null>(null);

  const { data, isLoading } = tsr.clusters.list.useQuery({
    queryKey: ["clusters"],
    queryData: {
      query: {
        take: 1000,
      },
    },
  });

  const handleChange = useCallback(
    (event: React.SyntheticEvent, cluster: Cluster | null) => {
      if (cluster) {
        setSelectedCluster(cluster);
        setInternalSelectedCluster(cluster);
      }
    },
    []
  );

  const clusters = data?.body.items || [];

  useEffect(() => {
    if (!selectedClusterId || !clusters.length) {
      setInternalSelectedCluster(null);
      return;
    }

    if (!internalSelectedCluster) {
      const newSelectedCluster = selectedClusterId
        ? clusters.find(u => u.id === selectedClusterId)
        : clusters[0];

      setInternalSelectedCluster(newSelectedCluster as Cluster);
    }
  }, [internalSelectedCluster, selectedClusterId, clusters]);

  if (isLoading || !clusters.length) {
    return <Spinner size={24} />;
  }

  return (
    <Autocomplete
      label={label}
      placeholder="Select cluster"
      options={clusters}
      getOptionLabel={cluster =>
        `${cluster.name}${cluster.code ? ` (${cluster.code})` : ""}`
      }
      value={internalSelectedCluster}
      onChange={handleChange}
      sx={{ maxWidth: { xs: 200, sm: "unset" } }}
      errorMessage={errorMessage}
      disabled={disabled}
    />
  );
};
