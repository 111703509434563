import { Popover, PopoverContent } from "~/components/ui/popover";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

export const ChangeResponsePopover = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Popover>
      <PopoverContent>
        <Stack gap="large">
          <Text size="large" weight="medium">
            Change Reply
          </Text>
          {children}
        </Stack>
      </PopoverContent>
    </Popover>
  );
};
