import { ActivityMember } from "@ses-mams/api-contract";
import { useMemo } from "react";
import { ActivityMembersSectionKeys } from "../utils";

export const useActivityMemberListSections = (items: Array<ActivityMember>) => {
  return useMemo(() => {
    const dataMap: Record<ActivityMembersSectionKeys, ActivityMember[]> = {
      Confirmed: [],
      Available: [],
      Conditional: [],
      Unavailable: [],
      Unset: [],
      Removed: [],
    };

    items.forEach(item => {
      switch (item.status) {
        case "AttendanceConfirmed": {
          dataMap.Confirmed.push(item);
          break;
        }

        case "Removed":
          dataMap.Removed.push(item);
          break;

        case "AvailabilitySubmitted":
        case "AvailabilityRequested":
        default: {
          dataMap[item.availabilityStatus ?? "Unset"].push(item);
        }
      }
    });

    return Object.keys(dataMap).flatMap(key => {
      const typedKey = key as ActivityMembersSectionKeys;
      const sectionData = dataMap[typedKey];

      if (!sectionData.length) {
        return [];
      }

      return [
        {
          // This will be translated during rendering
          title: typedKey,
          data: sectionData,
        },
      ];
    });
  }, [items]);
};
