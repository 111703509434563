import { AvailabilityStatus } from "@ses-mams/api-contract";
import { useEffect } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerProps,
} from "~/components/ui/drawer";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "~/components/ui/button";
import { FormControl, FormControlLabel } from "@mui/material";
import { Switch } from "~/components/ui/switch";
import { AvailabilityStatusSelector } from "~/components/common/availabilitySelector";
import { ConditionalReasonField } from "./ConditionalReasonField";

const conditionalReasonFormSchema = z.object({
  conditionalReason: z
    .string({ required_error: "Conditional reason is required" })
    .trim()
    .min(1, "Conditional reason is required")
    .max(200, "The maximum length is 200 characters"),
});

const AVAILABILITY_STATUS_OPTIONS: AvailabilityStatus[] = [
  "Available",
  "Conditional",
  "ImmediatelyAvailable",
  "Unavailable",
];

const availabilityFormSchema = z
  .object({
    status: z.enum(AVAILABILITY_STATUS_OPTIONS as [AvailabilityStatus]),
    emergenciesOnly: z.boolean().optional(),
  })
  .merge(conditionalReasonFormSchema.partial())
  .superRefine((val, ctx) => {
    if (val.status === "Conditional" && !val.conditionalReason?.trim()) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Conditional Reason is required",
        path: ["conditionalReason"],
      });
    }
  });

type SetAvailabilityDrawerProps = Omit<DrawerProps, "children"> & {
  onDone: (value: {
    status: AvailabilityStatus | null;
    emergenciesOnly?: boolean;
    conditionalReason?: string;
  }) => void;
};

export type AvailabilityForm = z.infer<typeof availabilityFormSchema>;

export const SetAvailabilityDrawer = ({
  onClose,
  onDone,
  open,
}: SetAvailabilityDrawerProps) => {
  const { control, handleSubmit, formState, setValue, watch, reset } =
    useForm<AvailabilityForm>({
      resolver: zodResolver(availabilityFormSchema),
      mode: "onBlur",
      defaultValues: {
        status: "Available",
      },
    });

  const onSubmit = (values: AvailabilityForm) => {
    onDone(values);
    onClose();
  };

  useEffect(() => {
    reset();
  }, [open]);

  const watchStatus = watch("status");

  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerHeader onClose={onClose}>Availability</DrawerHeader>
      <DrawerContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap="medium" paddingBottom="large">
            <Controller
              name="status"
              control={control}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <AvailabilityStatusSelector
                    value={value}
                    onChange={status => {
                      onChange(status);
                      if (status === "ImmediatelyAvailable") {
                        setValue("emergenciesOnly", false, {
                          shouldValidate: true,
                        });
                        setValue("conditionalReason", undefined, {
                          shouldValidate: true,
                        });
                        return;
                      }
                      if (status === "Conditional") {
                        setValue("conditionalReason", "", {
                          shouldValidate: true,
                        });
                        setValue("emergenciesOnly", undefined, {
                          shouldValidate: true,
                        });
                        return;
                      }

                      setValue("conditionalReason", undefined, {
                        shouldValidate: true,
                      });
                      setValue("emergenciesOnly", undefined, {
                        shouldValidate: true,
                      });
                    }}
                    onBlur={onBlur}
                  />
                );
              }}
            />

            {watchStatus === "ImmediatelyAvailable" && (
              <Controller
                name="emergenciesOnly"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <FormControl>
                      <FormControlLabel
                        label={
                          <Stack gap="small">
                            <Text weight="medium">Emergency only</Text>
                            <Text>
                              Switch the toggle on if you only want to be
                              immediately available for emergencies only.
                            </Text>
                          </Stack>
                        }
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        control={<Switch />}
                        labelPlacement="start"
                        sx={{
                          alignItems: "flex-start",
                          marginLeft: 0,
                        }}
                      />
                    </FormControl>
                  );
                }}
              />
            )}

            {watchStatus === "Conditional" && (
              <ConditionalReasonField control={control} />
            )}
          </Stack>

          <Button disabled={!formState.isValid} type="submit" fullWidth>
            Done
          </Button>
        </form>
      </DrawerContent>
    </Drawer>
  );
};
