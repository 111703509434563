import { Zone } from "@ses-mams/api-contract";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

type ZoneListItemProps = {
  zone: Zone;
  startAdornment?: React.ReactNode;
};

export const ZoneListItem = ({ zone, startAdornment }: ZoneListItemProps) => {
  return (
    <Stack
      direction="row"
      gap="medium"
      paddingY="small"
      align="center"
      justify="space-between"
    >
      {startAdornment}
      <Stack flex={1} grow={1} gap="xsmall" align="start">
        <Text size="xlarge" weight="medium">
          {`${zone.name}${zone.code ? ` (${zone.code})` : ""}`}
        </Text>
        {zone.address && <Text tone="secondary">{zone.address}</Text>}
      </Stack>
    </Stack>
  );
};
