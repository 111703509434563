import {
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenutItemProps,
} from "@mui/material";
import { useTextStyles } from "~/components/ui/text";

type SelectItemProps = MuiMenutItemProps;

export const SelectItem = ({ sx, ...props }: SelectItemProps) => {
  const style = useTextStyles({ size: "standard" });

  return (
    <MuiMenuItem {...props} sx={[style, ...(Array.isArray(sx) ? sx : [sx])]} />
  );
};
