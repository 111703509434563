import { Container } from "@mui/material";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ActivationRequest,
  ActivationRequestMember,
} from "@ses-mams/api-contract";
import {
  ActivationMembersSectionKeys,
  formatDateTime,
  getActivationMemberSectionTitle,
  isActivatedToRequest,
  isFutureActivation,
  isNonUrgentRequestWithAvailableStatus,
} from "@ses-mams/react-utils";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { Button } from "~/components/ui/button";
import { BackButton } from "~/components/common/navigation";
import { Divider } from "~/components/ui/divider";
import { RenderSectionHeaderParams } from "~/components/ui/list";
import { useAuth, useHasAnyRole } from "~/context/auth";
import { tsr } from "~/utils/client";
import {
  InitiatorLabelValueDrawer,
  LabelValue,
  NavigateToButton,
} from "../shared";
import {
  ActivationMemberList,
  useActivationMembersData,
  ActivationRequestResponsesSection,
  BeaconJobDetailsDrawerButton,
  CloseActivationButton,
  DeactivateButton,
  ManageActivationMembersDrawer,
  SetRequestEtaDrawerButton,
} from "./components";

const BEACON_URL = import.meta.env.VITE_BEACON_URL;

export const ActivationDetailPage = () => {
  const params = useParams();
  const activationId = params.requestId as string;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const hasCoordinatorRole = useHasAnyRole(["OperationalCoordinator"]);
  const { member } = useAuth();

  const {
    data: activationRequestReponse,
    isLoading: isLoadingActivationRequest,
  } = tsr.activationRequests.get.useQuery({
    queryKey: ["activation-requests", "details", activationId],
    queryData: {
      params: {
        activationId,
      },
    },
  });

  const activationRequest = activationRequestReponse?.body;

  const futureActivation =
    !!activationRequest && isFutureActivation(activationRequest.activation);

  const renderSectionHeader = useCallback(
    (params: RenderSectionHeaderParams<ActivationRequestMember>) => (
      <Text size="standard" weight="medium" tone="muted">
        {getActivationMemberSectionTitle(
          params.title as ActivationMembersSectionKeys,
          futureActivation
        )}
      </Text>
    ),
    [futureActivation]
  );

  const canShowEta = useCanShowEta(activationRequest);

  const { sections, isLoading, hasNextPage, fetchNextPage } =
    useActivationMembersData(activationId);

  if (isLoadingActivationRequest || !activationRequest) {
    return <Spinner />;
  }

  const {
    title,
    location,
    description,
    start,
    end,
    closedAt,
    unit,
    beaconJob,
    beaconData,
    initiator,
    latitude,
    longitude,
  } = activationRequest.activation;

  const isClosed = !!closedAt;
  const hasRequest = !!activationRequest.id;
  const canDeactivate =
    hasRequest &&
    activationRequest.status === "ActivationAccepted" &&
    !isClosed;
  const canCloseActivation = hasCoordinatorRole && !isClosed;
  const isInitiator = !!member && initiator?.id === member.id;
  const canMessage = hasRequest || isInitiator;

  return (
    <>
      <Container maxWidth="md">
        <Stack gap="xlarge">
          <BackButton />
          <Stack direction="row" justify="space-between">
            <Stack>
              <Text size="xlarge" weight="semi">
                {title}
              </Text>
              <Text size="xlarge" weight="semi">
                {location}
              </Text>
            </Stack>
            {hasCoordinatorRole && (
              <Stack>
                <Button href="edit">Edit</Button>
              </Stack>
            )}
          </Stack>
          <ActivationRequestResponsesSection
            activationRequest={activationRequest}
          />
          <Divider />
          <LabelValue label="Description" value={description} />
          <Stack
            direction={{ xs: "column", md: "row" }}
            gap="xlarge"
            justify="space-between"
          >
            <LabelValue
              label="Start time"
              value={formatDateTime(new Date(start))}
            />
            <LabelValue
              label="End time"
              value={formatDateTime(new Date(end))}
            />
          </Stack>
          {initiator && <InitiatorLabelValueDrawer initiator={initiator} />}
          <Divider />
          <Stack direction={{ xs: "column", md: "row" }} gap="small">
            {canShowEta && (
              <SetRequestEtaDrawerButton
                activationRequest={activationRequest}
              />
            )}
            <NavigateToButton
              address={location}
              coordinate={{
                longitude: longitude as number,
                latitude: latitude as number,
              }}
            />
            <Button variant="tertiary" fullWidth href="map">
              Map
            </Button>
            <Button
              variant="tertiary"
              fullWidth
              onClick={() => setIsDrawerOpen(true)}
            >
              Members
            </Button>
            {canMessage && (
              <Button
                variant="tertiary"
                fullWidth
                href={`/messages?channelId=${activationId}`}
              >
                Messages
              </Button>
            )}
          </Stack>
          {beaconJob && (
            <Stack direction={{ xs: "column", md: "row" }} gap="small">
              <BeaconJobDetailsDrawerButton
                activationId={activationId}
                beaconData={beaconData}
              />
              <Button
                variant="tertiary"
                href={`${BEACON_URL}/Jobs/${activationId}`}
                target="_blank"
              >
                Open in Beacon
              </Button>
            </Stack>
          )}
          {(canDeactivate || canCloseActivation) && (
            <>
              <Divider />
              <Stack
                align={{ xs: "stretch", md: "start" }}
                direction={{ xs: "column", md: "row" }}
                gap="small"
              >
                {canDeactivate && (
                  <DeactivateButton
                    activationId={activationId}
                    activationRequestId={activationRequest.id as string}
                    isFutureActivation={futureActivation}
                  />
                )}

                {canCloseActivation && (
                  <CloseActivationButton
                    activationId={activationId}
                    isFutureActivation={futureActivation}
                  />
                )}
              </Stack>
            </>
          )}
          <Divider />
          <Stack gap="medium">
            <Text size="xlarge" weight="medium">
              Members
            </Text>
            <ActivationMemberList
              isLoading={isLoading}
              sections={sections}
              renderSectionHeader={renderSectionHeader}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
            />
          </Stack>
        </Stack>
      </Container>

      <ManageActivationMembersDrawer
        activationId={activationId}
        activationStart={start}
        activationEnd={end}
        isFutureActivation={futureActivation}
        isClosed={isClosed}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        unitId={unit.id}
      />
    </>
  );
};

const useCanShowEta = (activationRequest?: ActivationRequest) => {
  const nonUrgentRequestWithAvailableStatus =
    !!activationRequest &&
    isNonUrgentRequestWithAvailableStatus(activationRequest);

  const activatedToRequest =
    !!activationRequest && isActivatedToRequest(activationRequest);

  const canShowEta =
    !activationRequest?.activation.closedAt &&
    (activatedToRequest || nonUrgentRequestWithAvailableStatus);

  return canShowEta;
};
