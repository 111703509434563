import { useCallback, useEffect, useMemo, useState } from "react";
import { ButtonBase } from "@mui/material";
import { Group, Member, Suggestion, Unit } from "@ses-mams/api-contract";
import {
  SelectAllMembersQuery,
  useArrayState,
  useUpdateEffect,
} from "@ses-mams/react-utils";

import { Button } from "~/components/ui/button";
import { Checkbox } from "~/components/ui/checkbox";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "~/components/ui/drawer";
import { Stack } from "~/components/ui/stack";

import { ShowMemberCapabilitiesToggle } from "~/components/common/showMemberCapabilitiesToggle";
import {
  MemberListItem,
  RequiredAvailabilityWindow,
  SearchMemberInput,
  MembersList,
  useMembersListSearch,
} from "~/components/common/searchMembersList";
import { SelectAllMembersToggle } from "./SelectAllMembersToggle";

type SelectMembersDrawerProps = {
  unit?: Unit;
  group?: Group;
  requiredAvailabilityWindow?: RequiredAvailabilityWindow;
  currentAvailabilityStatusTime?: string;
  members: Array<Member>;
  showAvailabilityStatus?: boolean;
  selectAllQuery?: SelectAllMembersQuery;
  open: boolean;
  onDone: (
    members: Array<Member>,
    selectAllQuery?: SelectAllMembersQuery
  ) => void;
  onClose: () => void;
  activationId?: string;
  outOfAreaActivationId?: string;
  activityId?: string;
  activityScheduleId?: string;
};

export const SelectMembersDrawer = ({
  unit,
  group,
  requiredAvailabilityWindow,
  currentAvailabilityStatusTime,
  members,
  showAvailabilityStatus,
  selectAllQuery,
  open,
  onDone,
  onClose,
  activationId,
  outOfAreaActivationId,
  activityId,
  activityScheduleId,
}: SelectMembersDrawerProps) => {
  const [internalSelectedMembers, { append, remove, setValue }] =
    useArrayState(members);

  const [internalSelectAllQuery, setInternalSelectAllQuery] =
    useState(selectAllQuery);

  const isSelectAllChecked = Boolean(internalSelectAllQuery);

  const defaultFilterSuggestions = useMemo(
    () =>
      [
        ...(unit ? [{ id: unit.id, name: unit.name, type: "unit" }] : []),
        ...(group ? [{ id: group.id, name: group.name, type: "group" }] : []),
      ] as Suggestion[],
    [unit, group]
  );

  const exclude = {
    activationId,
    outOfAreaActivationId,
    activityId,
    activityScheduleId,
  };

  const {
    searchSuggestions,
    setSearchSuggestions,
    searchValue,
    searchParams,
    suggestions,
    setSearchValue,
    handleSearchInputKeyUp,
    isLoading,
    isRefetching,
    data,
    hasNextPage,
    fetchNextPage,
    handleSubmit,
    isEditingSearchValue,
    shouldShowList,
    totalCount,
    clearSearchValues,
  } = useMembersListSearch({
    requiredAvailabilityWindow,
    currentAvailabilityStatusTime,
    defaultFilterSuggestions,
    exclude,
  });

  useEffect(() => {
    setInternalSelectAllQuery(prev => {
      if (!prev) {
        return undefined;
      }

      return {
        ...prev,
        query: searchParams.searchValue,
        suggestions: searchParams.searchSuggestions,
      };
    });
  }, [searchParams]);

  useUpdateEffect(() => {
    setValue(members);
  }, [members]);

  useUpdateEffect(() => {
    setInternalSelectAllQuery(selectAllQuery);
  }, [selectAllQuery]);

  const handleSelectAllClick = useCallback(
    (nextSelected: boolean) => {
      setValue(prevMembers => {
        const prevIds = new Set(prevMembers.map(m => m.id));

        const hasUnselected = data.some(m => !prevIds.has(m.id));

        if (hasUnselected || nextSelected) {
          setInternalSelectAllQuery({
            query: searchParams.searchValue,
            suggestions: searchParams.searchSuggestions,
            requiredAvailabilityStart: requiredAvailabilityWindow?.start,
            requiredAvailabilityEnd: requiredAvailabilityWindow?.end,
            currentAvailabilityStatusTime,
          });
          return [...new Set([...prevMembers, ...data])] as Array<Member>;
        }

        setInternalSelectAllQuery(undefined);

        return prevMembers.filter(
          p => !data.some(m => m.id === p.id)
        ) as Array<Member>;
      });
    },
    [
      data,
      searchParams,
      requiredAvailabilityWindow,
      currentAvailabilityStatusTime,
    ]
  );

  const handleCloseClick = useCallback(() => {
    clearSearchValues();
    setSearchSuggestions(defaultFilterSuggestions);
    onClose();
  }, [onClose, defaultFilterSuggestions]);

  const handleDoneClick = useCallback(() => {
    clearSearchValues();
    setSearchSuggestions(defaultFilterSuggestions);
    onDone(
      internalSelectedMembers,
      internalSelectAllQuery
        ? { ...internalSelectAllQuery, exclude }
        : undefined
    );
  }, [
    onDone,
    exclude,
    internalSelectAllQuery,
    internalSelectedMembers,
    defaultFilterSuggestions,
  ]);

  const renderItem = useCallback(
    (item: Member) => {
      const isChecked =
        isSelectAllChecked ||
        internalSelectedMembers.some(({ id }) => id === item.id);

      return (
        <ButtonBase
          sx={{
            width: "100%",
            ":disabled": {
              pointerEvents: "auto",
              cursor: "not-allowed",
            },
          }}
          disabled={isSelectAllChecked}
          onClick={() => (isChecked ? remove(item) : append(item))}
        >
          <MemberListItem
            showAvailabilityStatus={showAvailabilityStatus}
            member={item}
            endAdornment={
              <Checkbox checked={isChecked} disabled={isSelectAllChecked} />
            }
          />
        </ButtonBase>
      );
    },
    [internalSelectedMembers, isSelectAllChecked, showAvailabilityStatus]
  );

  const hasSearchValue = useMemo(() => {
    return (
      !!searchParams?.searchValue || searchParams?.searchSuggestions.length > 0
    );
  }, [searchParams]);

  return (
    <Drawer open={open} onClose={handleCloseClick}>
      <DrawerHeader onClose={handleCloseClick}>Edit Members</DrawerHeader>

      <DrawerContent>
        <Stack direction="column" gap="large" sx={{ height: "100%" }}>
          <SearchMemberInput
            onKeyUp={handleSearchInputKeyUp}
            searchSuggestions={searchSuggestions}
            searchValue={searchValue}
            setSearchSuggestions={setSearchSuggestions}
            setSearchValue={setSearchValue}
            suggestions={suggestions}
            isEditingSearchValue={isEditingSearchValue}
            onSubmit={handleSubmit}
          />
          {shouldShowList && (
            <>
              <Stack gap="medium" align="start">
                <ShowMemberCapabilitiesToggle />

                {hasSearchValue && (!isRefetching || !isLoading) ? (
                  <SelectAllMembersToggle
                    selected={isSelectAllChecked}
                    totalCount={totalCount}
                    onClick={handleSelectAllClick}
                    disabled={!totalCount}
                  />
                ) : null}
              </Stack>
              <Stack paddingBottom="large">
                <MembersList
                  data={data}
                  searchParams={searchParams}
                  isLoading={isLoading}
                  isRefetching={isRefetching}
                  hasNextPage={hasNextPage}
                  fetchNextPage={fetchNextPage}
                  renderItem={renderItem}
                />
              </Stack>
            </>
          )}
        </Stack>
      </DrawerContent>

      <DrawerFooter>
        <Button fullWidth onClick={handleDoneClick}>
          Done
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
