import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerProps,
} from "~/components/ui/drawer";
import { ApprovalActionButtons } from "./ApprovalActionButtons";
import { Button } from "~/components/ui/button";

type ApprovalActionDrawerProps = Omit<DrawerProps, "children"> & {
  activationId?: string;
  requestIds: Array<string>;
  onSuccess: () => void;
};

export const ApprovalActionDrawer = ({
  activationId,
  requestIds,
  onSuccess,
  ...props
}: ApprovalActionDrawerProps) => {
  return (
    <Drawer {...props}>
      <DrawerHeader {...props}>Actions</DrawerHeader>

      <DrawerContent>
        <ApprovalActionButtons
          activationId={activationId}
          requestIds={requestIds}
          sx={{
            flexDirection: { md: "column" },
          }}
          onSuccess={onSuccess}
        />
      </DrawerContent>

      <DrawerFooter>
        <Button variant="secondary" onClick={props.onClose}>
          Cancel
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
