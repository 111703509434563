import { useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import {
  AvailabilityDayOfWeekSelector,
  AvailabilityWeekSelector,
} from "~/components/common/availabilitySelector";
import { Stack } from "~/components/ui/stack";
import { Button } from "~/components/ui/button";
import { Box } from "~/components/ui/box";
import { MoreVerticalFilledIcon } from "~/components/ui/icon";
import {
  AvailabilityViewMode,
  useAvailabilityReportContext,
} from "~/context/unit/AvailabilityReportContextProvider";
import { Menu, MenuItem } from "~/components/ui/menu";
import { SelectField, SelectItem } from "~/components/ui/selectField";

export const AvailabilityReportHeader = () => {
  const [optionsAnchorEl, setOptionsAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const optionsOpen = Boolean(optionsAnchorEl);

  const handleOptionsClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setOptionsAnchorEl(event.currentTarget);
    },
    []
  );

  const handleOptionsClose = useCallback(() => {
    setOptionsAnchorEl(null);
  }, []);

  const {
    selectedDay,
    setSelectedDay,
    firstDayOfTheWeek,
    lastDayOfTheWeek,
    availabilityViewMode,
    setAvailabilityViewMode,
    goToPreviousWeek,
    goToNextWeek,
    goToToday,
  } = useAvailabilityReportContext();

  const todayComponent = (
    <Button variant="secondary" onClick={goToToday} fullWidth>
      Today
    </Button>
  );

  const reportModeComponent = (
    <SelectField
      label="Report mode"
      hideLabel
      value={availabilityViewMode}
      onChange={e =>
        setAvailabilityViewMode(e.target.value as AvailabilityViewMode)
      }
      sx={{ minWidth: 120 }}
    >
      <SelectItem value={AvailabilityViewMode.AGGREGATED}>Day</SelectItem>
      <SelectItem value={AvailabilityViewMode.INDIVIDUAL}>Members</SelectItem>
    </SelectField>
  );

  return (
    <Stack dividers dividerTone="neutral">
      <Stack
        direction="row"
        justify="space-between"
        paddingX="small"
        paddingY="medium"
      >
        <AvailabilityWeekSelector
          firstDayOfTheWeek={firstDayOfTheWeek}
          lastDayOfTheWeek={lastDayOfTheWeek}
          onGoToPreviousWeek={goToPreviousWeek}
          onGoToNextWeek={goToNextWeek}
        />

        <Stack
          direction="row"
          dividers
          dividerTone="neutral"
          gap="medium"
          sx={{ display: { xs: "none", sm: "flex" } }}
        >
          <Box display="flex" justify="center">
            {todayComponent}
          </Box>

          {reportModeComponent}
        </Stack>

        <Box display={{ xs: "flex", sm: "none" }}>
          <IconButton
            aria-label="Report options"
            aria-controls={optionsOpen ? MENU_ID : undefined}
            aria-haspopup="true"
            aria-expanded={optionsOpen ? "true" : undefined}
            onClick={handleOptionsClick}
          >
            <MoreVerticalFilledIcon />
          </IconButton>

          <Menu
            anchorEl={optionsAnchorEl}
            id={MENU_ID}
            open={optionsOpen}
            onClose={handleOptionsClose}
            onClick={handleOptionsClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem>{todayComponent}</MenuItem>
            <MenuItem>{reportModeComponent}</MenuItem>
          </Menu>
        </Box>
      </Stack>

      <AvailabilityDayOfWeekSelector
        start={firstDayOfTheWeek}
        onSelect={setSelectedDay}
        selectedDate={selectedDay}
      />
    </Stack>
  );
};

const MENU_ID = "report-options-menu";
