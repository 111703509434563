import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  ApiErrorSchema,
  AvailabilityReportStatusSchema,
  AvailabilitySchema,
  getPaginationResponseSchema,
  MemberSummarySchema,
  PaginationQueryParamsSchema,
  ServerDateSchema,
  UnitSchema,
} from "./common";
import { MemberSchema } from "./members";
import { GroupOutputSchema } from "./groups";

const c = initContract();

const AggregatedUnitAvailabilityPeriod = z.object({
  period: z.string(),
  immediatelyAvailable: z.number(),
  available: z.number(),
  conditional: z.number(),
  unavailable: z.number(),
  committed: z.number(),
  unset: z.number(),
  lowAvailability: z.boolean(),
});

const IndividualUnitAvailabilitySchema = z.object({
  member: MemberSummarySchema,
  availability: z.array(AvailabilitySchema),
});

const unitsContract = c.router({
  get: {
    method: "GET",
    path: "/units/:id",
    pathParams: z.object({
      id: z.string(),
    }),
    responses: {
      200: UnitSchema,
      404: ApiErrorSchema,
    },
    summary: "Get the details of a unit",
  },
  list: {
    method: "GET",
    path: "/units",
    query: PaginationQueryParamsSchema.extend({
      query: z.coerce.string().trim().optional(),
      memberUnitsOnly: z.boolean().optional(),
    }),
    responses: {
      200: getPaginationResponseSchema(UnitSchema),
    },
    summary: "Get all units based on optional parameters",
  },
  listGroups: {
    method: "GET",
    path: "/units/:unitId/groups",
    pathParams: z.object({
      unitId: z.string(),
    }),
    query: z.object({
      reportableOnly: z.boolean().optional(),
    }),
    responses: {
      200: z.array(GroupOutputSchema),
      404: ApiErrorSchema,
    },
    summary: "Get all groups of a unit",
  },
  setContactPoint: {
    method: "PATCH",
    path: "/units/:unitId/contact",
    pathParams: z.object({
      unitId: z.string(),
    }),
    body: c.type<{
      memberId: string;
    }>(),
    responses: {
      200: UnitSchema,
      403: ApiErrorSchema,
      422: ApiErrorSchema,
    },
    summary: "Set the contact point of a unit",
  },
  getAggregatedAvailability: {
    method: "GET",
    path: "/units/:unitId/availability/aggregated",
    pathParams: z.object({
      unitId: z.string(),
    }),
    query: z.object({
      startDate: ServerDateSchema,
      endDate: ServerDateSchema,
      byPeriod: z.enum(["Daily", "Hourly"]),
      groupId: z.string().optional(),
    }),
    responses: {
      200: z.array(AggregatedUnitAvailabilityPeriod),
    },
    summary: "Get the aggregate availability of a unit",
  },
  getIndividualAvailability: {
    method: "GET",
    path: "/units/:unitId/availability/individual",
    pathParams: z.object({
      unitId: z.string(),
    }),
    query: z.object({
      startDate: ServerDateSchema,
      endDate: ServerDateSchema,
      groupId: z.string().optional(),
    }),
    responses: {
      200: z.object({
        items: z.array(IndividualUnitAvailabilitySchema),
      }),
    },
    summary: "Get the individual member availability of a unit",
  },
  getMembersByAvailability: {
    method: "GET",
    path: "/units/:unitId/members",
    pathParams: z.object({
      unitId: z.string(),
    }),
    query: PaginationQueryParamsSchema.extend({
      startDate: ServerDateSchema.optional(),
      endDate: ServerDateSchema.optional(),
      groupId: z.string().optional(),
      availabilityReportStatus: AvailabilityReportStatusSchema.optional(),
    }),
    responses: {
      200: getPaginationResponseSchema(MemberSchema),
    },
    summary: "Get members by availability period and status",
  },
});

export type AggreggatedUnitAvailabilityPeriod = z.infer<
  typeof AggregatedUnitAvailabilityPeriod
>;

export type IndividualUnitAvailability = z.infer<
  typeof IndividualUnitAvailabilitySchema
>;

export { unitsContract };
