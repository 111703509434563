import { useSearchParams } from "react-router-dom";
import {
  Channel,
  Window,
  MessageList,
  MessageInput,
  Thread,
} from "stream-chat-react";
import emptyListImageUrl from "~/assets/emptyList.png";
import { EmptyState } from "~/components/common/emptyState";
import { BackButton } from "~/components/common/navigation";
import { Box } from "~/components/ui/box";
import { Card } from "~/components/ui/card";
import { Stack } from "~/components/ui/stack";
import { ChannelHeader } from "./ChannelHeader";
import { DateSeparator } from "./DateSeparator";
import { MessageAvatar } from "./MessageAvatar";
import { Message } from "./Message";

export const ChannelMessages = () => {
  const [searchParams] = useSearchParams();
  const channelId = searchParams.get("channelId");

  return (
    <Stack
      gap="large"
      direction="column"
      grow={1}
      sx={{
        height: "100%",
        // On small devices, only show the messages if a channel is selected
        display: { xs: channelId ? "flex" : "none", md: "flex" },
      }}
    >
      <Box
        // Only show this on small devices as a way to show the channel list again
        display={{ xs: channelId ? "flex" : "none", md: "none" }}
      >
        <BackButton to="/messages" />
      </Box>

      <Card display="flex" grow={1} overflowY="hidden" sx={{ height: "100%" }}>
        {channelId ? (
          <Channel
            DateSeparator={DateSeparator}
            Avatar={MessageAvatar}
            Message={Message}
          >
            <Window>
              <ChannelHeader />
              <MessageList
                messageActions={[
                  "delete",
                  "flag",
                  "mute",
                  "pin",
                  "quote",
                  "react",
                  "reply",
                ]}
              />
              <MessageInput
                additionalTextareaProps={{ placeholder: "Write your message" }}
              />
            </Window>
            <Thread />
          </Channel>
        ) : (
          <Box flex={1} display="flex" align="center" justify="center">
            <EmptyState
              image={<img src={emptyListImageUrl} />}
              title="Messages"
              description="Select which group you want to chat in from the list on the left."
            />
          </Box>
        )}
      </Card>
    </Stack>
  );
};
