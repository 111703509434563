import { Route, Routes } from "react-router-dom";

import {
  AvailabilitySelectedWeekContextProvider,
  ComposeProviders,
  OutOfAreaMembersListFiltersContextProvider,
  RequestListFiltersContextProvider,
  ShowMemberCapabilitiesContextProvider,
} from "@ses-mams/react-utils";

import {
  AuthContextProvider,
  AuthCallback,
  AuthRequiredGuard,
} from "~/context/auth";
import { AvailabilityUnitContextProvider } from "~/context/unit/AvailabilityUnitContextProvider";
import { SelectedUnitContextProvider } from "~/context/unit/SelectedUnitContextProvider";
import { ToastContextProvider } from "~/components/ui/toast";
import {
  PageLayout,
  ChatContextLayout,
  OutOfAreaActivationLayout,
  ProtectedByRoles,
} from "~/components/common/layout";
import { ConfirmationDialogContextProvider } from "~/components/ui/confirmationDialog";
import { AppQueryClientProvider } from "~/utils/client";
import { MembersPage } from "~/pages/members";
import { SignInPage } from "~/pages/signIn";
import { UsersPage } from "~/pages/users";
import {
  UnitsPage,
  UnitMembersByAvailabilityPage,
  UnitMembersPage,
} from "~/pages/units";
import { AddGroupPage, EditGroupPage, GroupsPage } from "~/pages/groups";
import {
  ActivationDetailPage,
  ActivationMapPage,
  ActivityDetailPage,
  ActivityMapPage,
  AddActivationPage,
  AddActivityPage,
  EditActivityPage,
  AddOOAActivationPage,
  OOAADetailsPage,
  OOAARequestAvailabilityResponsePage,
  OOAARequestRolesResponsePage,
  RequestsPage,
  EditOOAActivationPage,
  OOAARequestHistoryPage,
  AmendOOAARequestRolesPage,
  AmendOOAARequestAvailabilityPage,
  AddActivitySchedulePage,
  ActivityScheduleDetailsPage,
  EditActivitySchedulePage,
} from "~/pages/requests";
import {
  ImpersonatePage,
  MySchedulePage,
  MyBroadcastMessagesPage,
} from "~/pages/profile";
import { MessagesPage } from "~/pages/messages";
import {
  NewChannelPage,
  ChannelDetailsPage,
  EditChannelMembersPage,
} from "~/pages/channels";

import "./App.css";
import { LocalizationProvider } from "./components/ui/dateTimePicker/LocalizationProvider";
import { EditActivationPage } from "~/pages/requests/activations/EditActivationPage";
import {
  OOAAApprovalDetailsPage,
  OOAAApprovalOverrideAvailabilityPage,
  OOAAApprovalOverrideRolesPage,
  OOAAApprovalQueuePage,
  ApprovalQueueFiltersContextProvider,
} from "~/pages/outOfAreaActivationApprovals";
import {
  AvailabilityPage,
  EditSchedulePage,
  EditHourlyAvailabiltyPage,
  EditOtherMemberAvailabilityPage,
  OtherMemberAvailabilityPage,
} from "./pages/availability";
import { GoogleMapsContextProvider } from "~/components/ui/map";
import { AuditPage } from "./pages/audit/AuditPage";
import { AvailabilityReportContextProvider } from "~/context/unit/AvailabilityReportContextProvider";
import { AvailabilityReportingContextProvider } from "~/context/reporting/AvailabilityReportingContextProvider";
import {
  BroadcastPage,
  AddBroadcastPage,
  ViewBroadcastPage,
} from "./pages/broadcast";
import { UnreadBroadcastMessagesContextProvider } from "./context/broadcast";
import { ReportingPage } from "./pages/reporting";
import { ReportingMembersByAvailabilityPage } from "./pages/reporting/ReportingMembersByAvailabilityPage";

function App() {
  return (
    <ComposeProviders
      providers={[
        ToastContextProvider,
        AuthContextProvider,
        AppQueryClientProvider,
        AvailabilitySelectedWeekContextProvider,
        AvailabilityUnitContextProvider,
        SelectedUnitContextProvider,
        AvailabilityReportContextProvider,
        ConfirmationDialogContextProvider,
        ShowMemberCapabilitiesContextProvider,
        RequestListFiltersContextProvider,
        ApprovalQueueFiltersContextProvider,
        OutOfAreaMembersListFiltersContextProvider,
        LocalizationProvider,
        GoogleMapsContextProvider,
        UnreadBroadcastMessagesContextProvider,
        AvailabilityReportingContextProvider,
      ]}
    >
      <Routes>
        <Route path="/" Component={SignInPage} />
        <Route path="/login/callback" Component={AuthCallback} />
        <Route Component={AuthRequiredGuard}>
          <Route Component={PageLayout}>
            <Route path="/units" Component={UnitsPage} />
            <Route path="/requests">
              <Route index Component={RequestsPage} />

              <Route path="activations">
                <Route
                  element={
                    <ProtectedByRoles
                      roles={["OperationalCoordinator"]}
                      redirectPath="/requests?tab=operational"
                    />
                  }
                >
                  <Route path="add" Component={AddActivationPage} />
                  <Route
                    path=":activationId/edit"
                    Component={EditActivationPage}
                  />
                </Route>
                <Route path=":requestId" Component={ActivationDetailPage} />
                <Route path=":activationId/map" Component={ActivationMapPage} />
              </Route>
              <Route path="activities">
                <Route
                  element={
                    <ProtectedByRoles
                      roles={["ActivityCoordinator"]}
                      redirectPath="/requests?tab=activity"
                    />
                  }
                >
                  <Route path="add" Component={AddActivityPage} />
                  <Route path=":activityId/edit" Component={EditActivityPage} />
                </Route>
                <Route path=":requestId" Component={ActivityDetailPage} />
                <Route path=":activityId/map" Component={ActivityMapPage} />
              </Route>
              <Route
                path="out-of-area-activations"
                Component={OutOfAreaActivationLayout}
              >
                <Route
                  element={
                    <ProtectedByRoles
                      roles={["OperationalCoordinator", "OOAACoordinator"]}
                      redirectPath="/requests?tab=out-of-area"
                    />
                  }
                >
                  <Route path="add" Component={AddOOAActivationPage} />
                  <Route
                    path=":activationId/edit"
                    Component={EditOOAActivationPage}
                  />
                </Route>

                <Route path=":activationId">
                  <Route index Component={OOAADetailsPage} />

                  <Route path="history" Component={OOAARequestHistoryPage} />

                  <Route path="respond">
                    <Route
                      path="roles"
                      Component={OOAARequestRolesResponsePage}
                    />
                    <Route
                      path="availability"
                      Component={OOAARequestAvailabilityResponsePage}
                    />
                  </Route>

                  <Route path="request/:requestId/amend">
                    <Route path="roles" Component={AmendOOAARequestRolesPage} />
                    <Route
                      path="availability"
                      Component={AmendOOAARequestAvailabilityPage}
                    />
                  </Route>
                </Route>
              </Route>

              <Route path="activity-schedules">
                <Route
                  element={
                    <ProtectedByRoles
                      roles={["ActivityCoordinator"]}
                      redirectPath="/requests?tab=activity"
                    />
                  }
                >
                  <Route path="add" Component={AddActivitySchedulePage} />
                  <Route
                    path=":scheduleId/edit"
                    Component={EditActivitySchedulePage}
                  />
                </Route>
                <Route
                  path=":scheduleId"
                  Component={ActivityScheduleDetailsPage}
                />
              </Route>
            </Route>
            <Route
              path="/out-of-area-activation-approvals"
              element={
                <ProtectedByRoles
                  roles={["UnitApprover", "ClusterApprover", "ZoneApprover"]}
                  redirectPath="/requests?tab=out-of-area"
                >
                  <OutOfAreaActivationLayout />
                </ProtectedByRoles>
              }
            >
              <Route index Component={OOAAApprovalQueuePage} />
              <Route path="roles" Component={OOAAApprovalOverrideRolesPage} />
              <Route
                path="availability"
                Component={OOAAApprovalOverrideAvailabilityPage}
              />
              <Route path=":requestId" Component={OOAAApprovalDetailsPage} />
            </Route>
            <Route path="/admin">
              <Route
                path="users"
                element={
                  <ProtectedByRoles roles={["Administrator"]}>
                    <UsersPage />
                  </ProtectedByRoles>
                }
              />
              <Route
                path="broadcast"
                element={<ProtectedByRoles roles={["SystemAdministrator"]} />}
              >
                <Route index Component={BroadcastPage} />
                <Route path="add" Component={AddBroadcastPage} />
                <Route path=":broadcastId" Component={ViewBroadcastPage} />
              </Route>
            </Route>
            <Route path="/audit-logs" Component={AuditPage} />
            <Route path="/units" Component={UnitsPage} />
            <Route path="/members" Component={MembersPage} />

            <Route Component={ChatContextLayout}>
              <Route path="/messages" Component={MessagesPage} />

              <Route path="/channels">
                <Route path="new" Component={NewChannelPage} />
                <Route path=":channelId">
                  <Route index Component={ChannelDetailsPage} />
                  <Route
                    path="members/edit"
                    Component={EditChannelMembersPage}
                  />
                </Route>
              </Route>
            </Route>

            <Route path="/schedule" Component={MySchedulePage} />
            <Route
              path="/broadcast-messages"
              Component={MyBroadcastMessagesPage}
            />
            <Route path="availability">
              <Route index Component={AvailabilityPage} />
              <Route path="hourly/edit" Component={EditHourlyAvailabiltyPage} />
              <Route path="schedule/edit" Component={EditSchedulePage} />
              <Route path="member/:memberId">
                <Route index Component={OtherMemberAvailabilityPage} />
                <Route
                  path="edit"
                  Component={EditOtherMemberAvailabilityPage}
                />
              </Route>
            </Route>

            <Route path="/reporting">
              <Route index Component={ReportingPage} />
              <Route path="members">
                <Route index Component={ReportingMembersByAvailabilityPage} />
              </Route>
            </Route>

            <Route path="/units/:unitId">
              <Route
                element={
                  <ProtectedByRoles
                    roles={[
                      "OperationalCoordinator",
                      "Administrator",
                      "SystemAdministrator",
                    ]}
                  />
                }
              >
                <Route path="requests" Component={RequestsPage} />
                <Route path="groups">
                  <Route index Component={GroupsPage} />
                  <Route path="add" Component={AddGroupPage} />
                  <Route path=":groupId/edit" Component={EditGroupPage} />
                </Route>
              </Route>

              <Route path="members">
                <Route index Component={UnitMembersPage} />
                <Route
                  path="availability"
                  Component={UnitMembersByAvailabilityPage}
                />
              </Route>
            </Route>

            <Route
              path="/impersonate"
              element={
                <ProtectedByRoles roles={["Impersonate"]}>
                  <ImpersonatePage />
                </ProtectedByRoles>
              }
            />
          </Route>
        </Route>
      </Routes>
    </ComposeProviders>
  );
}

export default App;
