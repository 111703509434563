import { z } from "zod";
import { ActivationType } from "@ses-mams/api-contract";
import {
  eventDatesFormSchema,
  eventOtherFieldsFormSchema,
  validateDatesSchema,
} from "./event";

export const ACTIVATION_TYPE_OPTIONS: ActivationType[] = [
  "Urgent",
  "NotUrgent",
];

export const activationFormSchema = z.intersection(
  eventOtherFieldsFormSchema.extend({
    type: z.enum(ACTIVATION_TYPE_OPTIONS as [ActivationType], {
      errorMap: () => ({ message: "Type is required" }),
    }),
  }),
  eventDatesFormSchema.required().superRefine(validateDatesSchema)
);

export type ActivationFormSchema = z.infer<typeof activationFormSchema>;
