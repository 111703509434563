import { Cluster } from "@ses-mams/api-contract";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

type ClusterListItemProps = {
  cluster: Cluster;
  startAdornment?: React.ReactNode;
};

export const ClusterListItem = ({
  cluster,
  startAdornment,
}: ClusterListItemProps) => {
  return (
    <Stack
      direction="row"
      gap="medium"
      paddingY="small"
      align="center"
      justify="space-between"
    >
      {startAdornment}
      <Stack flex={1} grow={1} gap="xsmall" align="start">
        <Text size="large" weight="medium">
          {`${cluster.name}${cluster.code ? ` (${cluster.code})` : ""}`}
        </Text>
        {cluster.address && <Text tone="secondary">{cluster.address}</Text>}
      </Stack>
    </Stack>
  );
};
