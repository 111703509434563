import { useCallback, useState } from "react";

export const useBulkSelectingState = () => {
  const [isBulkSelecting, setIsBulkSelecting] = useState(false);

  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set());

  const onSelect = useCallback(
    (id: string) => {
      setSelectedIds(prev => {
        const next = new Set(prev);

        if (next.has(id)) {
          next.delete(id);
        } else {
          next.add(id);
        }

        return next;
      });
    },
    [setSelectedIds]
  );

  const handleSetIsBulkSelecting = useCallback((value: boolean) => {
    setIsBulkSelecting(value);
    setSelectedIds(new Set());
  }, []);

  return {
    isBulkSelecting,
    setIsBulkSelecting: handleSetIsBulkSelecting,
    selectedIds,
    onSelect,
  };
};
