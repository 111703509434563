import { addDays, endOfDay, startOfDay, subDays } from "date-fns";
import { useCallback, useMemo, useState } from "react";

export const useAvailabilityWeekSelector = () => {
  const [firstDayOfTheWeek, setFirstDayOfTheWeek] = useState(
    startOfDay(new Date())
  );

  const lastDayOfTheWeek = useMemo(() => {
    return endOfDay(addDays(firstDayOfTheWeek, 6));
  }, [firstDayOfTheWeek]);

  const goToPreviousWeek = useCallback(() => {
    setFirstDayOfTheWeek(prevFirstDayOfTheWeek =>
      subDays(prevFirstDayOfTheWeek, 7)
    );
  }, []);

  const goToNextWeek = useCallback(() => {
    setFirstDayOfTheWeek(prevFirstDayOfTheWeek =>
      addDays(prevFirstDayOfTheWeek, 7)
    );
  }, []);

  const goToCurrentWeek = useCallback(() => {
    setFirstDayOfTheWeek(startOfDay(new Date()));
  }, []);

  return {
    firstDayOfTheWeek,
    lastDayOfTheWeek,
    goToPreviousWeek,
    goToNextWeek,
    goToCurrentWeek,
  };
};
