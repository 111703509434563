import { useCallback, useMemo, useState } from "react";
import { ActivationRequestMember } from "@ses-mams/api-contract";
import { ActivationMembersSectionKeys } from "../utils";

export const useActivationRequestMemberSelectedState = () => {
  const [selectAllState, setSelectAllState] = useState<
    Record<ActivationMembersSectionKeys, boolean>
  >(INITIAL_SELECT_ALL_STATE);

  const [selectedMembers, setSelectedMembers] = useState<
    Array<ActivationRequestMember>
  >([]);

  const selectedGroups = useMemo(() => {
    const selectedMemberIds: Set<string> = new Set();
    const activation: Array<ActivationRequestMember> = [];
    const deactivation: Array<ActivationRequestMember> = [];

    selectedMembers.forEach(m => {
      selectedMemberIds.add(m.member.id);

      if (m.status === "ActivationAccepted") {
        deactivation.push(m);
        return;
      }

      activation.push(m);
    });

    return {
      selectedMemberIds,
      selectedForActivation: activation,
      selectedForDeactivation: deactivation,
    };
  }, [selectedMembers]);

  const clearSelectedMembers = useCallback(() => {
    setSelectedMembers([]);
    setSelectAllState(INITIAL_SELECT_ALL_STATE);
  }, []);

  return {
    ...selectedGroups,
    selectAllState,
    setSelectAllState,
    setSelectedMembers,
    clearSelectedMembers,
  };
};

const INITIAL_SELECT_ALL_STATE = {
  Activated: false,
  Available: false,
  AwaitingConfirmation: false,
  Conditional: false,
  ImmediatelyAvailable: false,
  Unavailable: false,
  Unset: false,
  Deactivated: false,
};
