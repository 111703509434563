import { tsr } from "~/utils/client";
import { UnitSelector } from "~/components/common/unitSelector";
import { Unit } from "@ses-mams/api-contract";
import { useMemo } from "react";
import { useAuth } from "~/context/auth";

type Props = {
  selectedUnitId?: string;
  setSelectedUnit: (unit: Unit) => void;
  label?: string;
  disabled?: boolean;
  membersOnly?: boolean;
  showCategories?: boolean;
  errorMessage?: string;
};

export const AllUnitsSelector = ({
  label,
  selectedUnitId,
  setSelectedUnit,
  disabled,
  membersOnly = false,
  showCategories = false,
  errorMessage,
}: Props) => {
  const { member } = useAuth();

  const { data, isLoading } = tsr.units.list.useQuery({
    queryKey: ["units"],
    queryData: {
      query: {
        take: 1000,
        memberUnitsOnly: membersOnly,
      },
    },
  });

  const units = data?.body.items || [];

  const selectedUnit = useMemo(
    () => units.find(u => u.id === selectedUnitId),
    [units, selectedUnitId]
  );

  const memberUnits = useMemo(() => {
    return member?.units.map(({ unit }) => unit) ?? [];
  }, [member?.units]);

  return (
    <UnitSelector
      label={label}
      isLoading={isLoading}
      units={units}
      selectedUnit={selectedUnit}
      setSelectedUnit={setSelectedUnit}
      disabled={disabled}
      showCategories={showCategories}
      memberUnits={memberUnits}
      errorMessage={errorMessage}
    />
  );
};
