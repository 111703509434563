import {
  useChannelPreviewInfo,
  useChannelStateContext,
} from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { Button } from "~/components/ui/button";
import { Divider } from "~/components/ui/divider";
import { Heading } from "~/components/ui/heading";
import { UserProfileGroupFilledIcon } from "~/components/ui/icon";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

export const ChannelHeader = () => {
  const { channel } =
    useChannelStateContext<DefaultStreamChatGenerics>("ChannelHeader");

  const { displayTitle } = useChannelPreviewInfo({
    channel,
  });

  const description = channel?.data?.description as string;

  return (
    <>
      <Stack
        direction="row"
        gap="xsmall"
        justify="space-between"
        align="start"
        padding="large"
      >
        <Stack gap="xxsmall" direction="column">
          <Stack gap="small" direction="row" align="center">
            <UserProfileGroupFilledIcon size="xxsmall" />

            <Heading level="4">{displayTitle}</Heading>
          </Stack>

          {description && <Text tone="info">{description}</Text>}
        </Stack>

        <Button variant="link" href={`/channels/${channel.id}`}>
          Details
        </Button>
      </Stack>

      <Divider />
    </>
  );
};
