import { Link } from "@mui/material";

import { Suggestion } from "@ses-mams/api-contract";

import { Divider } from "~/components/ui/divider";
import { SearchInput, SearchInputProps } from "~/components/ui/searchInput";
import { Stack } from "~/components/ui/stack";
import { Text, useTextStyles } from "~/components/ui/text";

import { useMembersListSearch } from "..";
import { SuggestionChip } from "./SuggestionChip";

type Props = Pick<
  ReturnType<typeof useMembersListSearch>,
  | "searchSuggestions"
  | "searchValue"
  | "setSearchSuggestions"
  | "setSearchValue"
  | "suggestions"
  | "isEditingSearchValue"
> &
  Pick<SearchInputProps, "onKeyUp" | "onSubmit"> & {
    suggestions: Suggestion[];
    isEditingSearchValue: boolean;
  };

export const SearchMemberInput = ({
  onKeyUp,
  searchSuggestions,
  searchValue,
  setSearchSuggestions,
  setSearchValue,
  suggestions,
  isEditingSearchValue,
  onSubmit,
}: Props) => {
  const buttonLinkStyles = useTextStyles({ size: "large", tone: "action" });

  return (
    <Stack gap="medium" direction="column">
      <SearchInput
        placeholder={searchSuggestions.length === 0 ? "Search" : undefined}
        value={searchValue}
        onValueChange={setSearchValue}
        onKeyUp={onKeyUp}
        onSubmit={onSubmit}
        startAdornment={searchSuggestions.map(suggestion => (
          <SuggestionChip suggestion={suggestion} key={suggestion.id} />
        ))}
      />
      {suggestions.length > 0 && isEditingSearchValue ? (
        <Stack gap="large">
          <Text size="large" weight="medium">
            Suggestions
          </Text>

          <Stack gap="small">
            {suggestions.map((suggestion: Suggestion) => (
              <Stack
                direction="row"
                key={`suggestion-${suggestion.id}`}
                wrap={true}
              >
                <Text size="large">
                  {suggestion.type === "capability" ? "Has" : "In"}{" "}
                  {suggestion.type}
                </Text>
                &nbsp;
                <Link
                  component="button"
                  onClick={() => {
                    setSearchValue("");
                    setSearchSuggestions([
                      ...new Set([
                        ...searchSuggestions,
                        suggestion as Suggestion,
                      ]),
                    ]);
                  }}
                  sx={buttonLinkStyles}
                >
                  {`${suggestion.name}${
                    suggestion?.label ? ` (${suggestion?.label})` : ""
                  }`}
                </Link>
              </Stack>
            ))}
          </Stack>

          <Divider width="medium" tone="field" />
        </Stack>
      ) : null}
    </Stack>
  );
};
