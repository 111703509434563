import { useCallback, useEffect, useState } from "react";
import { Zone } from "@ses-mams/api-contract";
import { tsr } from "~/utils/client";
import { Spinner } from "~/components/ui/spinner";
import { Autocomplete } from "~/components/ui/autocomplete";

type Props = {
  selectedZoneId?: string;
  setSelectedZone: (zone: Zone) => void;
  label: string;
  errorMessage?: string;
  disabled?: boolean;
};

export const ZoneSelector = ({
  label,
  selectedZoneId,
  setSelectedZone,
  errorMessage,
  disabled,
}: Props) => {
  const [internalSelectedZone, setInternalSelectedZone] = useState<Zone | null>(
    null
  );

  const { data, isLoading } = tsr.zones.list.useQuery({
    queryKey: ["zones"],
    queryData: {
      query: {
        take: 1000,
      },
    },
  });

  const handleChange = useCallback(
    (event: React.SyntheticEvent, zone: Zone | null) => {
      if (zone) {
        setSelectedZone(zone);
        setInternalSelectedZone(zone);
      }
    },
    []
  );

  const zones = data?.body.items || [];

  useEffect(() => {
    if (!selectedZoneId || !zones.length) {
      setInternalSelectedZone(null);
      return;
    }

    if (!internalSelectedZone) {
      const newSelectedZone = selectedZoneId
        ? zones.find(u => u.id === selectedZoneId)
        : zones[0];

      setInternalSelectedZone(newSelectedZone as Zone);
    }
  }, [internalSelectedZone, selectedZoneId, zones]);

  if (isLoading || !zones.length) {
    return <Spinner size={24} />;
  }

  return (
    <Autocomplete
      label={label}
      placeholder="Select zone"
      options={zones}
      getOptionLabel={zone =>
        `${zone.name}${zone.code ? ` (${zone.code})` : ""}`
      }
      value={internalSelectedZone}
      onChange={handleChange}
      sx={{ maxWidth: { xs: 200, sm: "unset" } }}
      errorMessage={errorMessage}
      disabled={disabled}
    />
  );
};
