import {
  useOutOfAreaActivationMemberListSections,
  useTransformPagedDataToItems,
} from "@ses-mams/react-utils";
import { tsr } from "~/utils/client";
import { LARGE_PAGE_SIZE, getNextLargePageParam } from "~/utils/pagination";

export const useOutOfAreaActivationMembersSummariesData = (
  activationId: string,
  enabled?: boolean
) => {
  const { data, isLoading, isRefetching, refetch, fetchNextPage, hasNextPage } =
    tsr.outOfAreaActivations.getMemberSummaries.useInfiniteQuery({
      queryKey: [
        "out-of-area-activation",
        { activationId },
        "member-summaries",
      ],
      queryData: ({ pageParam }) => ({
        params: {
          activationId,
        },
        query: {
          skip: pageParam?.skip ?? 0,
          take: pageParam?.take ?? LARGE_PAGE_SIZE,
        },
      }),
      initialPageParam: { skip: 0, take: LARGE_PAGE_SIZE },
      getNextPageParam: getNextLargePageParam,
      enabled,
    });

  const items = useTransformPagedDataToItems(data);

  const sections = useOutOfAreaActivationMemberListSections(items);

  return {
    isLoading,
    isRefetching,
    refetch,
    sections,
    fetchNextPage,
    hasNextPage,
  };
};
