import { MemberSummary } from "@ses-mams/api-contract";
import { Text } from "~/components/ui/text";
import { formatMemberFullName } from "@ses-mams/react-utils";
import { Stack } from "~/components/ui/stack";
import { MemberDetailsDrawer } from "~/components/common/memberDetails/MemberDetailsDrawer";
import { useState } from "react";
import { ButtonBase } from "@mui/material";
import { Box } from "~/components/ui/box";

type Props = {
  approvers: MemberSummary[];
};

export const OrgUnitApprovers = ({ approvers }: Props) => {
  const [memberDetailsId, setMemberDetailsId] = useState<string>();

  return (
    <>
      <Stack gap="xsmall">
        {approvers.map(approver => (
          <Box key={approver.id}>
            <ButtonBase onClick={() => setMemberDetailsId(approver.id)}>
              <Text key={approver.id} tone="action">
                {formatMemberFullName(approver)}
              </Text>
            </ButtonBase>
          </Box>
        ))}
      </Stack>
      {memberDetailsId && (
        <MemberDetailsDrawer
          open={Boolean(memberDetailsId)}
          memberId={memberDetailsId}
          onClose={() => setMemberDetailsId(undefined)}
        />
      )}
    </>
  );
};
