import { Button } from "~/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogProps,
} from "~/components/ui/dialog";
import { Text } from "~/components/ui/text";

export type Props = Omit<DialogProps, "children"> & {
  onConfirm: () => void;
  numberOfSelectedMembers: number;
  isFutureActivation: boolean;
};

export const RequestActivationDialog = ({
  open,
  onClose,
  onConfirm,
  numberOfSelectedMembers,
  isFutureActivation,
}: Props) => (
  <Dialog open={open} onClose={onClose}>
    <DialogHeader onClose={onClose}>Are you sure?</DialogHeader>
    <DialogContent>
      <Text size="large">{`${
        numberOfSelectedMembers === 1
          ? "The selected member is"
          : "At least one of the selected members is"
      } already ${
        isFutureActivation
          ? "committed to a request that conflicts with this one"
          : "activated to a request"
      }. Would you still like to perform this operation?`}</Text>
    </DialogContent>
    <DialogFooter>
      <Button variant="secondary" onClick={onClose}>
        No
      </Button>
      <Button variant="destructive" onClick={onConfirm}>
        Yes
      </Button>
    </DialogFooter>
  </Dialog>
);
