import { useMutation } from "@tanstack/react-query";
import { useMemo } from "react";

type LatLng = {
  latitude: number;
  longitude: number;
};

type ComputeRoutesResponse = {
  routes?: {
    duration: string;
    distanceMeters: number;
  }[];
};

export const useGoogleComputeRoute = (apiKey: string) => {
  const mutationFn = useMemo(() => getRouteDuration(apiKey), [apiKey]);

  const { mutateAsync, ...rest } = useMutation({
    mutationFn: mutationFn,
  });

  return {
    ...rest,
    getGoogleComputedRoute: mutateAsync,
    mutate: undefined,
  };
};

const GOOGLE_COMPUTE_ROUTES_URL =
  "https://routes.googleapis.com/directions/v2:computeRoutes";

const getWaypoint = (locationType: LatLng | string) =>
  typeof locationType !== "string"
    ? {
        location: {
          latLng: {
            ...locationType,
          },
        },
      }
    : {
        address: locationType,
      };

const getRouteDuration =
  (apiKey: string) =>
  async ({
    origin,
    destination,
  }: {
    origin: LatLng | string;
    destination: LatLng | string;
  }) => {
    const result = await fetch(GOOGLE_COMPUTE_ROUTES_URL, {
      method: "post",
      body: JSON.stringify({
        origin: getWaypoint(origin),
        destination: getWaypoint(destination),
        travelMode: "DRIVE",
        routingPreference: "TRAFFIC_AWARE",
      }),
      headers: {
        "Content-Type": "application/json",
        "X-Goog-Api-Key": apiKey,
        "X-Goog-FieldMask": "routes.duration,routes.distanceMeters",
      },
    });

    if (result.status !== 200) {
      return null;
    }

    const response: ComputeRoutesResponse = await result.json();

    const [firstResult] = response.routes ?? [];

    if (!firstResult) {
      return null;
    }

    return {
      // https://developers.google.com/maps/documentation/routes/compute_route_directions#example_http_route_response_body
      // The API returns duration in seconds as a string (with a "s" suffix). Convert it to minutes as a number
      duration: Math.round(parseInt(firstResult.duration) / 60),
      // Convert distance in metres from API to kilometres
      distance: firstResult.distanceMeters / 1000,
    };
  };
