import { AvatarProps } from "stream-chat-react";
import { Box } from "~/components/ui/box";
import { UserProfileFilledIcon } from "~/components/ui/icon";
import { useAuth } from "~/context/auth";

export const MessageAvatar = ({ user }: AvatarProps) => {
  const { member } = useAuth();

  const isLoggedInUser = user?.id === member?.id;

  if (isLoggedInUser) {
    return null;
  }

  return (
    <Box
      display="flex"
      borderRadius="full"
      background="muted"
      height="small"
      width="small"
      align="center"
      justify="center"
    >
      <UserProfileFilledIcon tone="disabled" size="xxsmall" />
    </Box>
  );
};
