import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Group } from "@ses-mams/api-contract";
import { editGroupFormSchema, EditGroupFormSchema } from "@ses-mams/validation";

type Params = {
  initialGroup?: Group;
};

export const useEditGroupForm = ({ initialGroup }: Params) => {
  const form = useForm<EditGroupFormSchema>({
    resolver: zodResolver(editGroupFormSchema),
    defaultValues: initialGroup
      ? transformGroup(initialGroup)
      : { reportable: true, useAvailabilityRequirements: false },
  });

  return form;
};

const transformGroup = (group: Group): EditGroupFormSchema => ({
  ...group,
  useAvailabilityRequirements: !!group.availabilityMinThreshold,
  addMembers: [],
  removeMembers: [],
});
