import { Link } from "@mui/material";

import { formatPhoneNumber, removeWhitespace } from "@ses-mams/react-utils";

import { Text } from "~/components/ui/text";

type MemberPhoneNumberProps = {
  phoneNumber: string;
};

export const MemberPhoneNumber = ({ phoneNumber }: MemberPhoneNumberProps) => {
  const cleanedPhoneNumber = removeWhitespace(phoneNumber);
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

  return (
    <Link href={`tel:${cleanedPhoneNumber}`}>
      <Text size="large" weight="semi" tone="action">
        {formattedPhoneNumber}
      </Text>
    </Link>
  );
};
