import { formatDateTime, useParseBeaconData } from "@ses-mams/react-utils";
import { useState } from "react";
import { Button } from "~/components/ui/button";
import { Drawer, DrawerContent, DrawerHeader } from "~/components/ui/drawer";
import { Stack } from "~/components/ui/stack";
import { MemberPhoneNumber } from "~/components/common/memberDetails/MemberPhoneNumber";
import { Box } from "~/components/ui/box";
import { Badge } from "~/components/ui/badge";
import { LabelValue, LabelValueWithDescription } from "../../shared";

type BeaconJobDetailsDrawerButtonProps = {
  activationId: string;
  beaconData?: string | null;
};

export const BeaconJobDetailsDrawerButton = ({
  activationId,
  beaconData,
}: BeaconJobDetailsDrawerButtonProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const parsedData = useParseBeaconData(beaconData);

  return (
    <>
      <Button variant="tertiary" onClick={() => setIsDrawerOpen(true)}>
        Beacon Job Details
      </Button>

      <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <DrawerHeader onClose={() => setIsDrawerOpen(false)}>
          Beacon Job Details
        </DrawerHeader>

        <DrawerContent>
          {parsedData && (
            <Stack gap="large">
              <LabelValue
                label="Job"
                value={`${
                  parsedData.EntityAssignedTo
                    ? `${
                        typeof parsedData.EntityAssignedTo === "string"
                          ? parsedData.EntityAssignedTo
                          : parsedData.EntityAssignedTo.Code
                      } - `
                    : ""
                }${activationId}`}
              />

              {parsedData.JobPriorityType ? (
                typeof parsedData.JobPriorityType === "string" ? (
                  <LabelValue
                    label="Job Priority"
                    value={parsedData.JobPriorityType}
                  />
                ) : (
                  <LabelValueWithDescription
                    label="Job Priority"
                    value={parsedData.JobPriorityType.Name}
                    description={parsedData.JobPriorityType.Description}
                  />
                )
              ) : null}

              {parsedData.JobType ? (
                typeof parsedData.JobType === "string" ? (
                  <LabelValue label="Job Type" value={parsedData.JobType} />
                ) : (
                  <LabelValueWithDescription
                    label="Job Type"
                    value={parsedData.JobType.Name}
                    description={parsedData.JobType.Description}
                  />
                )
              ) : null}

              {parsedData.JobStatusType ? (
                typeof parsedData.JobStatusType === "string" ? (
                  <LabelValue
                    label="Job Status"
                    value={parsedData.JobStatusType}
                  />
                ) : (
                  <LabelValueWithDescription
                    label="Job Status"
                    value={parsedData.JobStatusType.Name}
                    description={parsedData.JobStatusType.Description}
                  />
                )
              ) : null}

              {parsedData.Address ? (
                typeof parsedData.Address === "string" ? (
                  <LabelValue label="Location" value={parsedData.Address} />
                ) : (
                  <LabelValueWithDescription
                    label="Location"
                    value={parsedData.Address.PrettyAddress}
                    description={parsedData.Address.AdditionalAddressInfo}
                  />
                )
              ) : null}

              {parsedData.SituationOnScene && (
                <LabelValue
                  label="Situation On Scene"
                  value={parsedData.SituationOnScene}
                />
              )}

              {typeof parsedData.EvacuationRequired === "boolean" &&
                parsedData.EvacuationRequired && (
                  <LabelValue label="Evacuation Required" value="Yes" />
                )}

              {Array.isArray(parsedData.FloodAssistanceJob) &&
                parsedData.FloodAssistanceJob.length > 0 && (
                  <LabelValue
                    label="Flood Assistance"
                    value={
                      <Stack
                        paddingTop="xsmall"
                        paddingLeft="medium"
                        gap="small"
                      >
                        {parsedData.FloodAssistanceJob.flatMap(item =>
                          Object.entries(item).map(
                            ([key, value]) =>
                              !!value && (
                                <LabelValue
                                  label={key}
                                  value={value as string}
                                />
                              )
                          )
                        )}
                      </Stack>
                    }
                  />
                )}

              {typeof parsedData.PeopleInundated === "number" &&
                parsedData.PeopleInundated > 0 && (
                  <LabelValue
                    label="People Inundated"
                    value={parsedData.PeopleInundated.toString()}
                  />
                )}

              {parsedData.LGA && (
                <LabelValue label="LGA" value={parsedData.LGA} />
              )}

              <LabelValue
                label="Caller Name"
                value={`${parsedData.CallerFirstName}${parsedData.CallerLastName ? ` ${parsedData.CallerLastName}` : ""}`}
              />

              {parsedData.CallerPhoneNumber && (
                <LabelValue
                  label="Caller Number"
                  value={
                    <MemberPhoneNumber
                      phoneNumber={parsedData.CallerPhoneNumber}
                    />
                  }
                />
              )}

              {parsedData.ContactCalled ? (
                <LabelValue label="Contact Called" value="Yes" />
              ) : (
                <>
                  <LabelValue
                    label="Contact Name"
                    value={`${parsedData.ContactFirstName} ${parsedData.ContactLastName}`}
                  />

                  {parsedData.ContactPhoneNumber && (
                    <LabelValue
                      label="Contact Number"
                      value={
                        <MemberPhoneNumber
                          phoneNumber={parsedData.ContactPhoneNumber}
                        />
                      }
                    />
                  )}
                </>
              )}

              {parsedData.CreatedOn && (
                <LabelValue
                  label="Created On"
                  value={formatDateTime(new Date(parsedData.CreatedOn))}
                />
              )}

              {parsedData.Tags ? (
                typeof parsedData.Tags === "string" ? (
                  <LabelValue
                    label="Tags"
                    value={
                      <Box alignSelf="start">
                        <Badge size="small" tone="info">
                          {parsedData.Tags}
                        </Badge>
                      </Box>
                    }
                  />
                ) : (
                  <LabelValue
                    label="Tags"
                    value={
                      <Stack direction="row" gap="small" wrap>
                        {parsedData.Tags.map(t => (
                          <Badge key={t.Id} size="small" tone="info">
                            {t.Name}
                          </Badge>
                        ))}
                      </Stack>
                    }
                  />
                )
              ) : null}
            </Stack>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};
