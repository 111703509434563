import { z } from "zod";

const TWENTRY_FOUR_HOUR_TIME_REGEX = /^([01][0-9]|2[0-4]):[0-5][0-9]$/;
const TIME_WITHOUT_MINUTES_REGEX = /^[0-9]*:0{2}$/;

export const scheduleItemInputSchema = z
  .object({
    name: z
      .string({ required_error: "Name is required" })
      .trim()
      .min(1, "Name is required")
      .max(255, "The maximum length is 255 characters"),
    start: z
      .string({ required_error: "Start time is required" })
      .regex(TWENTRY_FOUR_HOUR_TIME_REGEX, {
        message: "Start time must be a valid 24 hour time",
      })
      .regex(TIME_WITHOUT_MINUTES_REGEX, {
        message: "Start must not include minutes",
      }),
    end: z
      .string({ required_error: "End time is required" })
      .regex(TWENTRY_FOUR_HOUR_TIME_REGEX, {
        message: "End time must be a valid 24 hour time",
      })
      .regex(TIME_WITHOUT_MINUTES_REGEX, {
        message: "End must not include minutes",
      }),
  })
  .superRefine(({ start, end }, ctx) => {
    const startTimeNumber = getTimeNumberFrom24HourTime(start);
    const endTimeNumber = getTimeNumberFrom24HourTime(end);

    // schedule for whole day (24hr) is not supported
    if (
      startTimeNumber === 0 &&
      (endTimeNumber === 0 || endTimeNumber === 2400)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Must be less than 24 hours",
        path: ["end"],
      });
    }

    return z.never;
  })
  .refine(
    ({ start, end }) => {
      const startTimeNumber = getTimeNumberFrom24HourTime(start);
      const endTimeNumber = getTimeNumberFrom24HourTime(end);

      // allow end time to be midnight (00:00)
      if (endTimeNumber <= startTimeNumber && endTimeNumber !== 0) {
        return false;
      }

      return true;
    },
    { message: "End must be after start", path: ["end"] }
  );

export const scheduleInputSchema = z
  .array(scheduleItemInputSchema)
  .superRefine((val, ctx) => {
    // Ensure there are no overlapping schedule items
    for (let i = 0; i < val.length - 1; i += 1) {
      const current = val[i];
      const next = val[i + 1];

      if (current?.end && next?.start) {
        const currentEnd = getTimeNumberFrom24HourTime(current.end);
        const nextStart = getTimeNumberFrom24HourTime(next.start);

        if (currentEnd > nextStart) {
          // Add multiple issues for each item, so that the frontend can map the error to the item
          ctx.addIssue({
            code: "custom",
            message: "Must end before the time of the next section",
            path: [i, "end"],
          });
          ctx.addIssue({
            code: "custom",
            message: "Must start after the time of the previous section",
            path: [i + 1, "start"],
          });
        }
      }
    }
  });

const getTimeNumberFrom24HourTime = (value: string) =>
  Number(value.replaceAll(":", ""));
