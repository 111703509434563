import { startOfDay } from "date-fns";
import { z } from "zod";
import { outOfAreaLevelFormSchema } from "./outOfAreaActivation";

// Form values must be nullable & optional instead of just optional because setting
// them to "undefined" does not work when clearing the filters, i.e. resetting the form
export const timeRangeFormSchema = z
  .object({
    start: z.date().nullable().optional(),
    end: z.date().nullable().optional(),
  })
  .superRefine((val, ctx) => {
    if (val.start && val.end && startOfDay(val.start) > startOfDay(val.end)) {
      ctx.addIssue({
        code: "custom",
        message: "Start date must be before or equal to End date",
        path: ["start"],
      });
      ctx.addIssue({
        code: "custom",
        message: "End date must be after or equal to Start date",
        path: ["end"],
      });
    }
  });

export type TimeRangeFormSchema = z.infer<typeof timeRangeFormSchema>;

export type TimeRange = {
  start?: Date | null;
  end?: Date | null;
};

export const requestListFiltersSchema = outOfAreaLevelFormSchema.extend({
  urgentRequests: z.boolean().nullable().optional(),
  nonUrgentRequests: z.boolean().nullable().optional(),
  archivedOnly: z.boolean().nullable().optional(),
  activationTimeRange: timeRangeFormSchema.nullable().optional(),
});

export type RequestListFiltersSchema = z.infer<typeof requestListFiltersSchema>;
