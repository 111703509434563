import { DateSeparatorProps } from "stream-chat-react";
import { formatDistanceToNowWithinThreshold } from "@ses-mams/react-utils";
import { Box } from "~/components/ui/box";
import { Text } from "~/components/ui/text";

export const DateSeparator = ({ date }: DateSeparatorProps) => (
  <Box display="flex" justify="center" paddingY="large" sx={{ width: "100%" }}>
    <Text align="center" tone="muted">
      {formatDistanceToNowWithinThreshold(date)}
    </Text>
  </Box>
);
