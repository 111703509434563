import { tsr } from "~/utils/client";

export const useOutOfAreaActivationQuery = ({
  activationId,
  enabled = true,
}: {
  activationId?: string;
  enabled?: boolean;
}) => {
  return tsr.outOfAreaActivations.get.useQuery({
    queryKey: ["out-of-area-activation", { activationId }],
    queryData: {
      params: {
        id: activationId || "",
      },
    },
    enabled: Boolean(activationId) && enabled,
  });
};
