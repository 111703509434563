import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "./constants";

function getLocalAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

function getLocalRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

function setLocalTokens(accessToken: string, refreshToken: string) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
}

async function deleteLocalTokens() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}

export {
  getLocalAccessToken,
  getLocalRefreshToken,
  deleteLocalTokens,
  setLocalTokens,
};
