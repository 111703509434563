import {
  Accordion as MuiAccordion,
  AccordionProps as MuiAccordionProps,
  styled,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useUpdateEffect } from "@ses-mams/react-utils";
import { AccordionContextProvider } from "./AccordionContextProvider";

type AccordionProps = Pick<MuiAccordionProps, "children"> & {
  open?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
};

export const Accordion = ({
  open: openProp = true,
  onClose,
  onOpen,
  ...props
}: AccordionProps) => {
  const [open, setOpen] = useState(openProp);

  useUpdateEffect(() => {
    if (typeof openProp === "undefined") {
      return;
    }

    setOpen(openProp);
  }, [openProp]);

  const handleOpen = useCallback(() => {
    setOpen(true);

    onOpen?.();
  }, [onOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);

    onClose?.();
  }, [onClose]);

  const handleChange = useCallback(
    (e: unknown, expanded: boolean) => {
      if (expanded) {
        handleOpen?.();
      } else {
        handleClose?.();
      }
    },
    [handleOpen, handleClose]
  );

  return (
    <AccordionContextProvider value={{ open }}>
      <StyledAccordion
        {...props}
        disableGutters
        expanded={open}
        onChange={handleChange}
        TransitionProps={{ unmountOnExit: true, exit: false }}
        elevation={0}
      />
    </AccordionContextProvider>
  );
};

const StyledAccordion = styled(MuiAccordion)({
  "&:before": {
    display: "none",
  },
});
