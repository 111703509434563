import { Member } from "@ses-mams/api-contract";
import { useCallback } from "react";
import emptyListImageUrl from "~/assets/emptyList.png";
import { MemberListItem } from "~/components/common/searchMembersList";
import { InfiniteList } from "~/components/ui/list";
import { Box } from "~/components/ui/box";
import { EmptyState } from "~/components/common/emptyState";
import { useActivityScheduleMembersData } from "./useActivityScheduleMembersData";

type ActivityScheduleMemberListProps = {
  scheduleId: string;
  renderItem?: (member: Member) => React.ReactElement;
};

export const ActivityScheduleMemberList = ({
  scheduleId,
  renderItem,
}: ActivityScheduleMemberListProps) => {
  const { data, isLoading, hasNextPage, fetchNextPage } =
    useActivityScheduleMembersData(scheduleId);

  const handleRenderItem = useCallback(
    (item: Member) => {
      if (renderItem) {
        return renderItem(item);
      }

      return <MemberListItem member={item} />;
    },
    [renderItem]
  );

  return (
    <InfiniteList
      data={data}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      renderItem={handleRenderItem}
      keyExtractor={item => item.id}
      listEmptyElement={
        isLoading ? null : (
          <Box display="flex" justify="center" sx={{ height: "100%" }}>
            <EmptyState
              image={<img src={emptyListImageUrl} />}
              title="No members"
              description="There are no members."
            />
          </Box>
        )
      }
    />
  );
};
