import { ButtonBase } from "@mui/material";
import { Member } from "@ses-mams/api-contract";
import { useActivityScheduleMemberSelectedState } from "@ses-mams/react-utils";
import { useCallback, useState } from "react";
import { ShowMemberCapabilitiesToggle } from "~/components/common/showMemberCapabilitiesToggle";
import { DrawerContent, DrawerHeader } from "~/components/ui/drawer";
import { Stack } from "~/components/ui/stack";
import { MemberListItem } from "~/components/common/searchMembersList";
import { Checkbox } from "~/components/ui/checkbox";
import { IconButton } from "~/components/ui/button";
import { InfoCircleFilledIcon } from "~/components/ui/icon";
import { MemberDetailsDrawer } from "~/components/common/memberDetails";
import { ActivityScheduleMemberList } from "../ActivityScheduleMemberList";
import { ManageActivityScheduleMembersFooter } from "./ManageActivityScheduleMembersFooter";

type ManageActivityScheduleMembersProps = {
  scheduleId: string;
  isClosed: boolean;
  onClose: () => void;
  onAddMembersClick: () => void;
};

export const ManageActivityScheduleMembers = ({
  scheduleId,
  isClosed,
  onClose,
  onAddMembersClick,
}: ManageActivityScheduleMembersProps) => {
  const [memberDetailsId, setMemberDetailsId] = useState<string>();

  const { setSelectedMembers, clearSelectedMembers, selectedMemberIds } =
    useActivityScheduleMemberSelectedState();

  const renderItem = useCallback(
    (item: Member) => {
      const isChecked = selectedMemberIds.has(item.id);

      return (
        <Stack flex={1} direction="row" justify="space-between">
          <ButtonBase
            onClick={() => {
              if (isChecked) {
                setSelectedMembers(prev => prev.filter(p => p.id !== item.id));
                return;
              }

              setSelectedMembers(prev => [...prev, item]);
            }}
            sx={{ width: "100%" }}
          >
            <MemberListItem
              member={item}
              startAdornment={<Checkbox checked={isChecked} />}
            />
          </ButtonBase>

          <IconButton
            size="small"
            onClick={() => {
              setMemberDetailsId(item.id);
            }}
          >
            <InfoCircleFilledIcon tone="info" size="xsmall" />
          </IconButton>
        </Stack>
      );
    },
    [selectedMemberIds]
  );

  return (
    <>
      <DrawerHeader onClose={onClose}>Members</DrawerHeader>

      <DrawerContent>
        <Stack paddingBottom="small">
          <ShowMemberCapabilitiesToggle />
        </Stack>

        <ActivityScheduleMemberList
          scheduleId={scheduleId}
          renderItem={renderItem}
        />
      </DrawerContent>

      <ManageActivityScheduleMembersFooter
        scheduleId={scheduleId}
        isClosed={isClosed}
        selectedMemberIds={[...selectedMemberIds]}
        onSuccess={clearSelectedMembers}
        onAddMembersClick={onAddMembersClick}
      />

      {memberDetailsId && (
        <MemberDetailsDrawer
          open={Boolean(memberDetailsId)}
          memberId={memberDetailsId}
          onClose={() => setMemberDetailsId(undefined)}
        />
      )}
    </>
  );
};
