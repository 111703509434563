import { InputBase, InputBaseProps, styled } from "@mui/material";
import { inputBaseStyles } from "./inputBaseStyles";

export const Input = styled(InputBase, {
  shouldForwardProp: prop => prop !== "hideLabel",
})<Partial<InputBaseProps & { hideLabel?: boolean }>>(({
  theme,
  hideLabel,
}) => {
  const baseStyles = inputBaseStyles(theme, hideLabel);

  return {
    ...baseStyles,
    "& .MuiInputBase-input": {
      position: "relative",
      ...baseStyles["& .MuiInputBase-input"],
    },
  };
});
