import { ContactGroup, Group, Unit } from "@ses-mams/api-contract";
import { useUpdateEffect } from "@ses-mams/react-utils";
import { useCallback, useState } from "react";
import { Button, IconButton } from "~/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "~/components/ui/drawer";
import { ChevronLeftOutlineIcon } from "~/components/ui/icon";
import { Stack } from "~/components/ui/stack";
import { SelectUnitGroupsList } from "~/components/common/selectUnitGroupsList";
import { SelectUnitList } from "./SelectUnitList";

type EditUnitGroupsDrawerProps = {
  open: boolean;
  groups: Array<Group>;
  contactGroups: Array<ContactGroup>;
  onClose: () => void;
  onDone: (groups: Array<Group>, contactGroups: Array<ContactGroup>) => void;
};

export const EditUnitGroupsDrawer = ({
  open,
  groups,
  contactGroups,
  onClose,
  onDone,
}: EditUnitGroupsDrawerProps) => {
  const [selectedUnit, setSelectedUnit] = useState<Unit>();
  const [selectedGroups, setSelectedGroups] = useState(groups);
  const [selectedContactGroups, setSelectedContactGroups] =
    useState(contactGroups);

  useUpdateEffect(() => {
    if (!open) {
      setSelectedUnit(undefined);
    }
  }, [open]);

  const handleDoneClick = useCallback(() => {
    onDone(selectedGroups, selectedContactGroups);
  }, [onDone, selectedGroups, selectedContactGroups]);

  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerHeader onClose={onClose}>
        {selectedUnit ? (
          <Stack direction="row" gap="small" align="center">
            <IconButton
              size="medium"
              onClick={() => setSelectedUnit(undefined)}
            >
              <ChevronLeftOutlineIcon />
            </IconButton>

            {selectedUnit.name}
          </Stack>
        ) : (
          ""
        )}
      </DrawerHeader>

      <DrawerContent>
        {selectedUnit ? (
          <SelectUnitGroupsList
            unitId={selectedUnit.id}
            groups={selectedGroups}
            contactGroups={selectedContactGroups}
            setGroups={setSelectedGroups}
            setContactGroups={setSelectedContactGroups}
          />
        ) : (
          <SelectUnitList onUnitSelected={setSelectedUnit} />
        )}
      </DrawerContent>

      {selectedUnit && (
        <DrawerFooter>
          <Button fullWidth onClick={handleDoneClick}>
            Done
          </Button>
        </DrawerFooter>
      )}
    </Drawer>
  );
};
