import { Children } from "react";
import { Button } from "~/components/ui/button";
import { Heading } from "~/components/ui/heading";
import { PencilFilledIcon, PlusOutlineIcon } from "~/components/ui/icon";
import { Stack } from "~/components/ui/stack";

type EditListSectionProps = {
  title: string;
  onButtonClick: () => void;
  isLoading?: boolean;
  /**
   * Overrides the built in mechanism that checks the length of children to determine the dynamic button label
   */
  hasItems?: boolean;
  children: React.ReactNode;
};

export const EditListSection = ({
  title,
  onButtonClick,
  isLoading,
  hasItems,
  children,
}: EditListSectionProps) => {
  const hasChildren = hasItems ?? !!Children.toArray(children).length;

  return (
    <Stack gap="medium">
      <Heading level="4">{title}</Heading>
      {hasChildren ? (
        <Button
          type="button"
          variant="tertiary"
          startIcon={PencilFilledIcon}
          onClick={onButtonClick}
          busy={isLoading}
        >
          {`Edit ${title}`}
        </Button>
      ) : (
        <Button
          type="button"
          variant="tertiary"
          startIcon={PlusOutlineIcon}
          onClick={onButtonClick}
          busy={isLoading}
        >
          {`Add ${title}`}
        </Button>
      )}
      {!isLoading && hasChildren && <Stack>{children}</Stack>}
    </Stack>
  );
};
