import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { ApiErrorSchema } from "./common";

const c = initContract();

const NotificationSettingsSchema = z.object({
  activationNotificationsMuted: z.boolean(),
  activationNotificationsMutedUntil: z.string().datetime().optional(),
});

export const notificationsContract = c.router({
  getSettings: {
    method: "GET",
    path: "/notifications/settings",
    responses: {
      200: NotificationSettingsSchema,
      404: ApiErrorSchema,
    },
    summary: "Get notification settings",
  },
  updateSettings: {
    method: "PATCH",
    path: "/notifications/settings",
    body: z.object({
      activationNotificationsMuted: z.boolean(),
    }),
    responses: {
      200: NotificationSettingsSchema,
    },
    summary: "Update notification settings",
  },
});

export type NotificationSettings = z.infer<typeof NotificationSettingsSchema>;
