import { Zone } from "@ses-mams/api-contract";
import { useCallback, useState } from "react";
import { SelectZonesDrawer } from "~/components/common/selectZonesList";
import { EditListSection } from "~/components/common/editListSection";
import { ZoneListItem } from "~/components/common/zone";

type EditZonesListProps = {
  selectedZones: Array<Zone>;
  onChangeSelectedZones: (zones: Array<Zone>) => void;
};

export const EditZonesList = ({
  selectedZones,
  onChangeSelectedZones,
}: EditZonesListProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onDone = useCallback(
    (zones: Array<Zone>) => {
      onChangeSelectedZones(zones);
      setIsDrawerOpen(false);
    },
    [onChangeSelectedZones]
  );

  return (
    <>
      <EditListSection
        title="Zones"
        onButtonClick={() => setIsDrawerOpen(true)}
      >
        {selectedZones.map(zone => (
          <ZoneListItem key={zone.id} zone={zone} />
        ))}
      </EditListSection>

      <SelectZonesDrawer
        open={isDrawerOpen}
        zones={selectedZones}
        onClose={() => setIsDrawerOpen(false)}
        onDone={onDone}
      />
    </>
  );
};
