import usePlacesAutocomplete, { Suggestion } from "use-places-autocomplete";
import { SyntheticEvent, useCallback, useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { EventFormSchema } from "@ses-mams/validation";
import { Autocomplete } from "~/components/ui/autocomplete";
import { useGoogleMaps } from "~/components/ui/map";

export const LocationField = () => {
  const placesService = useRef<google.maps.places.PlacesService>();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<EventFormSchema>();

  const { isGoogleMapsApiLoaded } = useGoogleMaps();

  const {
    init: initPlacesAutocomplete,
    suggestions: { loading, data },
    setValue: setSearchValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      region: "au",
    },
    initOnMount: false,
    debounce: 600,
  });

  useEffect(() => {
    if (isGoogleMapsApiLoaded) {
      initPlacesAutocomplete();
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
  }, [isGoogleMapsApiLoaded]);

  const onChange = useCallback(
    async (e: SyntheticEvent, place: Suggestion | null) => {
      if (!place) {
        setValue("location", "");
        setValue("latitude", -1);
        setValue("longitude", -1);
      } else {
        setValue("location", place.description);
        placesService.current?.getDetails(
          { placeId: place.place_id },
          result => {
            const latitude = result?.geometry?.location?.lat();
            if (latitude) {
              setValue("latitude", latitude, { shouldValidate: true });
            }
            const longitude = result?.geometry?.location?.lng();
            if (longitude) {
              setValue("longitude", longitude, { shouldValidate: true });
            }
          }
        );
      }
    },
    []
  );

  return (
    <Controller
      name="location"
      control={control}
      render={({ field: { value } }) => {
        return (
          <Autocomplete
            label="Location"
            placeholder="Set location"
            options={data}
            defaultValue={{ description: value } as Suggestion}
            onChange={onChange}
            noOptionsText="No locations found"
            getOptionLabel={option => option.description}
            loading={loading}
            onSearchValueChange={val => setSearchValue(val)}
            errorMessage={errors.location?.message}
          />
        );
      }}
    />
  );
};
