import { useForm } from "react-hook-form";
import { activityFormSchema, ActivityFormSchema } from "@ses-mams/validation";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  formatDateForServer,
  formatSelectAllMembersQuery,
  formatTwentyFourHourTime,
  SelectAllMembersQuery,
} from "@ses-mams/react-utils";
import { Activity, ContactGroup, Group, Member } from "@ses-mams/api-contract";
import { useDefaultUserUnitAndAddress } from "~/components/common/eventForm";

type Params = {
  initialActivity?: Activity;
};

export const useActivityForm = ({ initialActivity }: Params) => {
  const defaultUserUnitAndAddress = useDefaultUserUnitAndAddress();

  const defaultStart = new Date();

  return useForm<ActivityFormSchema>({
    resolver: zodResolver(activityFormSchema),
    defaultValues: initialActivity
      ? transformActivityToForm(initialActivity)
      : {
          start: {
            date: defaultStart,
            time: formatTwentyFourHourTime(defaultStart),
          },
          ...defaultUserUnitAndAddress,
        },
  });
};

const transformActivityToForm = (activity: Activity): ActivityFormSchema => {
  const { address, start, end, ...data } = activity;

  const startDate = new Date(start);
  const endDate = new Date(end);

  return {
    ...data,
    start: {
      date: startDate,
      time: formatTwentyFourHourTime(startDate),
    },
    end: {
      date: endDate,
      time: formatTwentyFourHourTime(endDate),
    },
    location: address,
  };
};

export const transformActivityFormForCreate = (
  formData: ActivityFormSchema,
  members: Array<Member>,
  groups: Array<Group>,
  contactGroups: Array<ContactGroup>,
  selectAllMembersQuery?: SelectAllMembersQuery
) => {
  return {
    ...transformActivityFormForUpdate(formData),
    recipientMemberIds: members.map(m => m.id),
    recipientGroupIds: groups.map(g => g.id),
    recipientContactGroupIds: contactGroups.map(cg => cg.id),
    selectAllMembersQuery: formatSelectAllMembersQuery(selectAllMembersQuery),
  };
};

export const transformActivityFormForUpdate = (
  formData: ActivityFormSchema
) => {
  const { unit, location, start, end, ...data } = formData;

  return {
    ...data,
    unitId: unit.id,
    address: location,
    start: formatDateForServer(start.date),
    end: formatDateForServer(end.date),
  };
};
