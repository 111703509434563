import { DialogContent as MuiDialogContent } from "@mui/material";

export type DialogContentProps = {
  children: React.ReactNode;
};

export const DialogContent = ({ children }: DialogContentProps) => {
  return (
    <MuiDialogContent
      dividers
      sx={theme => ({ paddingX: theme.tokens.spacing.large })}
    >
      {children}
    </MuiDialogContent>
  );
};
