import { Chip, ChipOwnProps, useTheme } from "@mui/material";

import { BackgroundTones } from "@ses-mams/react-utils";
import { Text } from "~/components/ui/text";

export type BadgeTones = Extract<
  BackgroundTones,
  | "positive"
  | "positiveLow"
  | "positiveMuted"
  | "caution"
  | "cautionMuted"
  | "critical"
  | "criticalMuted"
  | "info"
  | "infoMuted"
  | "muted"
  | "accentSecondary"
  | "accentSecondaryMuted"
>;

type Props = {
  // TODO: map to token sizes
  size?: ChipOwnProps["size"];
  tone?: BadgeTones;
  children: string;
};
export const Badge = ({ children, size, tone }: Props) => {
  const { tokens } = useTheme();

  const toneSxProps = tone
    ? { backgroundColor: tokens.colors.background[tone] }
    : ({
        backgroundColor: tokens.colors.background.surface,
        borderColor: tokens.border.color.standard,
        borderWidth: tokens.border.width.medium,
      } as const);

  return (
    <Chip
      label={
        <Text
          size="small"
          weight="semi"
          tone={tone ? toneToForegroundTone[tone] : "secondary"}
        >
          {children}
        </Text>
      }
      size={size}
      // TODO: add appropriate padding
      sx={{
        ...toneSxProps,
        borderRadius: tokens.border.radius.small,
      }}
    />
  );
};

const toneToForegroundTone = {
  positive: "inverted",
  positiveMuted: "positive",
  positiveLow: "inverted",
  caution: "inverted",
  cautionMuted: "caution",
  critical: "inverted",
  criticalMuted: "critical",
  info: "inverted",
  infoMuted: "info",
  muted: "secondary",
  surfaceLow: "inverted",
  accentSecondary: "inverted",
  accentSecondaryMuted: "secondary",
} as const;
