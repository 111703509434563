import { Outlet } from "react-router-dom";
import { Chat, Streami18n } from "stream-chat-react";
import { Box } from "~/components/ui/box";
import { Spinner } from "~/components/ui/spinner";
import { useChatClient } from "./useChatClient";

export const ChatContextLayout = () => {
  const chatClient = useChatClient();

  if (!chatClient) {
    return (
      <Box display="flex" grow={1} justify="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <Chat client={chatClient} i18nInstance={i18nInstance}>
      <Outlet />
    </Chat>
  );
};

const i18nInstance = new Streami18n({
  language: "en",
  translationsForLanguage: {
    Pin: "Mark as important",
    Unpin: "Unmark as important",
  },
});
