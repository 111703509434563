import { Activation } from "@ses-mams/api-contract";
import { FormProvider } from "react-hook-form";
import { ActivationForm } from "./ActivationForm";
import {
  transformActivationFormForUpdate,
  useActivationForm,
} from "../../hooks";
import { useCallback } from "react";
import { ActivationFormSchema } from "@ses-mams/validation";
import { useNavigate } from "react-router-dom";
import { tsr } from "~/utils/client";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
  activation: Activation;
};
export const EditActivationForm = ({ activation }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const formMethods = useActivationForm({ initialActivation: activation });

  const { mutateAsync } = tsr.activations.update.useMutation();

  const onSubmit = useCallback(
    async (formData: ActivationFormSchema) => {
      const updatedActivation = await mutateAsync({
        body: transformActivationFormForUpdate(formData),
        params: {
          id: activation.id,
        },
      });

      await Promise.all([
        queryClient.setQueryData(
          ["activations", activation.id],
          updatedActivation
        ),
        queryClient.invalidateQueries({
          queryKey: ["activation-requests"],
        }),
      ]);

      navigate(`/requests/activations/${activation.id}`);
    },
    [activation.id]
  );

  return (
    <FormProvider {...formMethods}>
      <ActivationForm isEditing={true} onSubmit={onSubmit} />
    </FormProvider>
  );
};
