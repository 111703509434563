import { useCallback, useState } from "react";

type ObjectWithId = {
  id: string;
} & Record<string, unknown>;

export const useArrayState = <T extends string | number | ObjectWithId>(
  initialValue: Array<T>
) => {
  const [value, setValue] = useState(initialValue);

  const append = useCallback((v: T) => setValue(prev => [...prev, v]), []);

  const remove = useCallback((v: T) => {
    if (typeof v === "object") {
      // Assume objects will have an id
      setValue(prev => prev.filter(p => (p as ObjectWithId).id !== v.id));
      return;
    }

    setValue(prev => prev.filter(p => p !== v));
  }, []);

  const clear = useCallback(() => setValue([]), []);

  return [value, { append, remove, clear, setValue }] as const;
};
