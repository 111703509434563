import { memo, useMemo, useState } from "react";
import { setHours } from "date-fns";
import {
  AvailabilityReportStatus,
  IndividualUnitAvailability,
  Member,
} from "@ses-mams/api-contract";
import {
  AVAILABILITY_REPORT_STATUS_TO_BACKGROUND_TONE_MAP,
  formatDateForServer,
  formatMemberFullName,
  statusToBackgroundColor,
} from "@ses-mams/react-utils";
import { Box } from "~/components/ui/box";
import { useAvailabilityReportContext } from "~/context/unit/AvailabilityReportContextProvider";
import { Stack } from "~/components/ui/stack";
import { ButtonBase, useTheme } from "@mui/material";
import { Text } from "~/components/ui/text";
import { MemberDetailsDrawer } from "~/components/common/memberDetails";

type IndividualDayAvailabilityRowProps = {
  item: IndividualUnitAvailability;
};

export const IndividualDayAvailabilityRow = memo(
  ({ item: { availability, member } }: IndividualDayAvailabilityRowProps) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const { selectedDay } = useAvailabilityReportContext();

    const mappedAvailabilityStatuses = useMemo(() => {
      const availabilityByStartMap = new Map(
        availability.map(item => [item.start, item])
      );
      return [...Array(24).keys()].map(i => {
        const start = formatDateForServer(setHours(selectedDay, i));

        const availability = availabilityByStartMap.get(start);

        if (availability?.committed) {
          return "Committed";
        }

        return availability?.status ?? "Unset";
      });
    }, [availability, selectedDay]);

    return (
      <>
        <Stack position="relative" direction="row" paddingBottom="small">
          {mappedAvailabilityStatuses.map((status, index) => (
            <AvailabilityCell
              status={status}
              key={`${member.id}-availability-hour-${index}`}
            />
          ))}

          <ButtonBase
            onClick={() => setIsDrawerOpen(true)}
            sx={({ tokens }) => ({
              display: "flex",
              position: "absolute",
              alignSelf: "center",
              backgroundColor: tokens.colors.background.backdrop,
              borderRadius: tokens.border.radius.small,
              paddingX: tokens.spacing.xsmall,
              left: tokens.spacing.xsmall,
            })}
          >
            <Text size="small" tone="inverted" weight="medium">
              {formatMemberFullName(member as Member)}
            </Text>
          </ButtonBase>
        </Stack>

        <MemberDetailsDrawer
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          memberId={member.id}
        />
      </>
    );
  }
);

type AvailabilityCellProps = {
  status: AvailabilityReportStatus;
};

const AvailabilityCell = ({ status }: AvailabilityCellProps) => {
  const tone = AVAILABILITY_REPORT_STATUS_TO_BACKGROUND_TONE_MAP[status];

  const {
    tokens: {
      border: {
        radius: { small },
      },
    },
  } = useTheme();

  return (
    <Box
      borderRightWidth="large"
      background={tone}
      sx={{
        width: 54,
        height: 26,
        borderRightColor: statusToBackgroundColor[status],
        ":first-of-type": {
          borderTopLeftRadius: small,
          borderBottomLeftRadius: small,
        },
        ":last-of-type": {
          borderRightWidth: 0,
          borderTopRightRadius: small,
          borderBottomRightRadius: small,
        },
      }}
    />
  );
};
