import { differenceInWeeks } from "date-fns";

const NOTIFICATIONS_PROMPT_SKIPPING_DATE_KEY =
  "notificationsPromptSkippingDate";

export const getHasRecentlySkippedNotificationsPrompt = (): boolean => {
  const skippingDateItem = Number(
    localStorage.getItem(NOTIFICATIONS_PROMPT_SKIPPING_DATE_KEY)
  );
  if (!skippingDateItem || Number.isNaN(skippingDateItem)) {
    return false;
  }

  return differenceInWeeks(new Date(skippingDateItem), new Date()) < 2;
};

export const setHasRecentlySkippedNotificationsPrompt = () =>
  localStorage.setItem(
    NOTIFICATIONS_PROMPT_SKIPPING_DATE_KEY,
    Date.now().toString()
  );
