import { Stack } from "~/components/ui/stack";
import { ActivationStatusSelector } from "./ActivationStatusSelector";
import { Text } from "~/components/ui/text";
import { Box } from "~/components/ui/box";
import { ActivationRequest, MemberStatus } from "@ses-mams/api-contract";

export const ActivationRequestResponse = ({
  activationRequest,
}: {
  activationRequest: ActivationRequest;
}) => {
  return (
    <Stack gap="small">
      <Stack gap="large">
        <ActivationStatusSelector
          activationRequest={activationRequest}
          selectedStatus={activationStatus(activationRequest)}
        />

        {activationRequest.conditionalReason ? (
          <Stack gap="xsmall" flex={1}>
            <Text size="large" weight="semi">
              {"Condition"}
            </Text>

            <Box background="cautionMuted" padding="large" borderRadius="large">
              <Text size="large" weight="medium">
                {`"${activationRequest.conditionalReason}"`}
              </Text>
            </Box>
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
};

const activationStatus = (
  activationRequest: ActivationRequest
): MemberStatus | "Unavailable" | null => {
  if (activationRequest.status === "ActivationRequested") {
    return null;
  }
  if (activationRequest.status === "ActivationDeclined") {
    return "Unavailable";
  }
  return activationRequest.memberStatus ?? null;
};
