import { Stack } from "../stack";

type DialogFooterProps = {
  children: React.ReactNode;
};

export const DialogFooter = (props: DialogFooterProps) => {
  return (
    <Stack
      {...props}
      direction="row"
      gap="small"
      justify="flex-end"
      paddingX="large"
      paddingY="small"
    />
  );
};
