import { Container } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Heading } from "~/components/ui/heading";
import { Row, Stack } from "~/components/ui/stack";
import { Tab, Tabs } from "~/components/ui/tab";
import { ApprovalsList } from "./components/ApprovalsList";
import { BackButton } from "~/components/common/navigation";
import { Box } from "~/components/ui/box";
import { Spinner } from "~/components/ui/spinner";
import { ApprovalQueueFilters } from "./components/ApprovalQueueFilters";
import { useApprovalQueueFiltersContext } from "./components/ApprovalQueueFilters/ApprovalQueueFiltersContext";

const tabs = {
  PENDING: "pending",
  PREVIOUS: "previous",
} as const;

export const OOAAApprovalQueuePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab") ?? tabs.PENDING;

  const { isLoadingInitialFilters } = useApprovalQueueFiltersContext();

  if (isLoadingInitialFilters) {
    return (
      <Box display="flex" grow={1} justify="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Stack gap="xlarge">
        <BackButton to="/requests?tab=out-of-area" />

        <Heading level="1">Approval Queue</Heading>

        <Row justify="space-between" rowGap="medium">
          <Tabs
            value={selectedTab}
            onChange={(_, v) => setSearchParams({ tab: v })}
          >
            <Tab value={tabs.PENDING} label="Pending" />
            <Tab value={tabs.PREVIOUS} label="Previous" />
          </Tabs>

          {selectedTab === tabs.PENDING && <ApprovalQueueFilters />}
        </Row>

        <ApprovalsList
          state={selectedTab as (typeof tabs)[keyof typeof tabs]}
        />
      </Stack>
    </Container>
  );
};
