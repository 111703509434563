import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { ConfirmationDialog } from "./ConfirmationDialog";

export type ConfirmationDialogParams = {
  title?: string;
  text: string;
  onConfirm: () => void;
};

type ConfirmationDialogContextType = {
  showConfirmationDialog: (params: ConfirmationDialogParams) => void;
};

const ConfirmationDialogContext = createContext<ConfirmationDialogContextType>({
  showConfirmationDialog: () => null,
});

export const ConfirmationDialogContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [params, setParams] = useState<ConfirmationDialogParams>();

  const handleOnConfirm = useCallback(() => {
    params?.onConfirm();
    setParams(undefined);
  }, [params]);

  const value = useMemo(
    () => ({
      showConfirmationDialog: setParams,
    }),
    [setParams]
  );

  return (
    <ConfirmationDialogContext.Provider value={value}>
      {children}
      <ConfirmationDialog
        open={!!params}
        onClose={() => setParams(undefined)}
        title={params?.title}
        text={params?.text || ""}
        onConfirm={handleOnConfirm}
      />
    </ConfirmationDialogContext.Provider>
  );
};

export const useConfirmationDialog = () => {
  const ctx = useContext(ConfirmationDialogContext);

  if (!ctx) {
    throw new Error(
      "Must be called within <ConfirmationDialogContextProvider />"
    );
  }

  return ctx;
};
