import { useTheme } from "@mui/material";
import { AvailabilityReportStatus } from "@ses-mams/api-contract";
import { BackgroundTones } from "@ses-mams/react-utils";
import { Box } from "~/components/ui/box";
import { Text } from "~/components/ui/text";

type AvailabilityReportCellProps = {
  value: number;
  status: AvailabilityReportStatus;
};

export const AvailabilityReportCell = ({
  value,
  status,
}: AvailabilityReportCellProps) => {
  const {
    tokens: { sizing },
  } = useTheme();
  return (
    <Box
      display="flex"
      background={backgrounds[status]}
      borderRadius="small"
      align="center"
      justify="center"
      sx={{
        minHeight: sizing.xsmall,
        minWidth: sizing.xsmall,
      }}
    >
      <Text tone="inverted" weight="semi">
        {value}
      </Text>
    </Box>
  );
};

const backgrounds = {
  ImmediatelyAvailable: "positive",
  Available: "positiveLow",
  Conditional: "caution",
  Unavailable: "criticalLow",
  Committed: "info",
  Unset: "neutral",
} as const satisfies Record<AvailabilityReportStatus, BackgroundTones>;
