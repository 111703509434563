import { Chip as MUIChip } from "@mui/material";
import React from "react";

import { BackgroundTones, BorderTones } from "@ses-mams/react-utils";

import { Stack } from "../stack";

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  border?: BorderTones;
  background?: BackgroundTones;
};

export const Chip = ({
  children,
  onClick,
  border = "standard",
  background = "surface",
}: Props) => (
  <MUIChip
    label={
      <Stack direction="row" gap="xsmall" align="center">
        {children}
      </Stack>
    }
    variant="outlined"
    clickable={!!onClick}
    onClick={onClick}
    // TODO: add appropriate padding
    sx={({ tokens }) => ({
      backgroundColor: tokens.colors.background[background],
      borderColor: tokens.border.color[border],
      borderWidth: tokens.border.width.medium,
      borderRadius: tokens.border.radius.medium,
    })}
  />
);
