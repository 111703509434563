import { useCallback, useEffect, useRef } from "react";
import {
  deleteToken,
  getToken,
  onMessage,
  Messaging,
} from "firebase/messaging";

import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";
import {
  VAPID_KEY,
  getFirebaseMessaging,
  initializeFirebase,
} from "~/utils/firebase";

initializeFirebase();

export const useFirebaseNotifications = () => {
  const messaging = useRef<Messaging | null>(getFirebaseMessaging()).current;

  const { addToast } = useToast();

  useEffect(() => {
    if (!messaging || Notification.permission !== "granted") {
      return;
    }

    const subscription = onMessage(messaging, payload => {
      if (!payload.data) {
        return;
      }

      addToast({
        tone: "info",
        title: payload.data.title ?? document.title,
        message: payload.data.message,
        persistent: true,
      });
    });

    return () => {
      subscription();
    };
  }, []);

  const registerNotificationToken = useCallback(async () => {
    if (!messaging || Notification.permission !== "granted") {
      return;
    }

    // Internally, getToken also registers the firebase-messaging-sw.js service worker
    // the first time it gets executed (it does not happen any earlier than that).
    // However, at times it fails because the worker is not yet ready by the time it tries
    // to create a subscription. Below is a workaround to keep retrying for a while until
    // the operation succeeds and the token is generated
    let attempts = 0;
    while (attempts < 5) {
      try {
        const fcmToken = await getToken(messaging, { vapidKey: VAPID_KEY });
        await tsr.devices.register.mutate({
          // Platform is set to Android so SNS uses FCM as the application platform
          body: { deviceToken: fcmToken, platform: "android" },
        });

        return;
      } catch (e) {
        attempts++;
      }
    }
  }, []);

  const deleteNotificationToken = useCallback(async () => {
    if (!messaging || Notification.permission !== "granted") {
      return;
    }

    try {
      const deletedToken = await getToken(messaging, { vapidKey: VAPID_KEY });
      await deleteToken(messaging);
      return deletedToken;
    } catch (e) {
      // Firebase throws an error in case the current token is not valid anymore -
      // ignore not to interrupt the user flow
      return undefined;
    }
  }, []);

  return { registerNotificationToken, deleteNotificationToken };
};
