import { z } from "zod";

export const outOfAreaActivationApprovalFormSchema = z.object({
  comments: z.string().trim().optional(),
});

export type OutOfAreaActivationApprovalFormSchema = z.infer<
  typeof outOfAreaActivationApprovalFormSchema
>;

export const outOfAreaActivationDeclineFormSchema = z
  .object({
    reason: z.string(),
    comments: z.string().trim().optional(),
  })
  .refine(
    ({ reason, comments }) => {
      if (reason === "Other" && !comments) {
        return false;
      }
      return true;
    },
    {
      message: "Comments are required",
      path: ["comments"],
    }
  );

export type OutOfAreaActivationDeclineFormSchema = z.infer<
  typeof outOfAreaActivationDeclineFormSchema
>;
