import {
  ActivationRequestMember,
  AvailabilityStatus,
} from "@ses-mams/api-contract";
import { formatAvailabilityStatusLabel } from "./format";
import { BackgroundTones } from "../ui";

export type ActivationMembersSectionKeys =
  | AvailabilityStatus
  | "AwaitingConfirmation"
  | "Activated"
  | "Unset"
  | "Deactivated";

export const getActivationMemberSectionTitle = (
  key: ActivationMembersSectionKeys,
  isFutureActivation: boolean,
  count?: number
) => {
  const title = getTitle(key, isFutureActivation);

  if (typeof count === "undefined") {
    return title;
  }

  return `${title} (${count})`;
};

const getTitle = (
  key: ActivationMembersSectionKeys,
  isFutureActivation: boolean
) => {
  switch (key) {
    case "AwaitingConfirmation":
      return "Awaiting Confirmation";

    case "Activated":
      return isFutureActivation ? "Committed" : "Activated";

    case "Unset":
    case "Deactivated":
      return key;

    default:
      return formatAvailabilityStatusLabel({ status: key });
  }
};

export const getBackgroundFromActivationMember = (
  activationMember: ActivationRequestMember
): BackgroundTones => {
  const { availabilityStatus, status } = activationMember;

  if (status === "ActivationAccepted") {
    return "infoSubtle";
  }

  if (status === "ActivationRequested") {
    return "surfaceSubtle";
  }

  if (["ActivationDeclined", "Deactivated"].includes(status)) {
    return "criticalSubtle";
  }

  if (availabilityStatus === "ImmediatelyAvailable") {
    return "positiveSubtle";
  }

  if (availabilityStatus === "Available") {
    return "positiveSubtle";
  }

  if (availabilityStatus === "Conditional") {
    return "cautionSubtle";
  }

  if (availabilityStatus === "Unavailable") {
    return "criticalSubtle";
  }

  return "surfaceSubtle";
};

export const getActivationMemberSectionBackground = (
  key: ActivationMembersSectionKeys
) =>
  (
    ({
      AwaitingConfirmation: "surfaceLow",
      Activated: "info",
      ImmediatelyAvailable: "positive",
      Available: "positiveLow",
      Conditional: "caution",
      Unavailable: "critical",
      Unset: "surfaceLow",
      Deactivated: "critical",
    }) as const satisfies Record<ActivationMembersSectionKeys, BackgroundTones>
  )[key];
