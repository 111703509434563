import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerProps,
} from "~/components/ui/drawer";
import { MemberDetails } from "./MemberDetails";

type MemberDetailsDrawerProps = Omit<DrawerProps, "children"> & {
  memberId: string;
};

export const MemberDetailsDrawer = ({
  memberId,
  onClose,
  ...rest
}: MemberDetailsDrawerProps) => {
  return (
    <Drawer {...rest} onClose={onClose}>
      <DrawerHeader onClose={onClose}>Member Profile</DrawerHeader>
      <DrawerContent>
        <MemberDetails memberId={memberId} />
      </DrawerContent>
    </Drawer>
  );
};
