import { Zone } from "@ses-mams/api-contract";
import { useArrayState } from "@ses-mams/react-utils";
import { useCallback, useEffect } from "react";
import { Button } from "~/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerProps,
} from "~/components/ui/drawer";
import { SelectZonesList } from "./SelectZonesList";

type SelectZonesDrawerProps = Omit<DrawerProps, "children"> & {
  zones: Array<Zone>;
  onDone: (zones: Array<Zone>) => void;
};

export const SelectZonesDrawer = ({
  open,
  onClose,
  onDone,
  zones,
}: SelectZonesDrawerProps) => {
  const [selectedZones, { append, remove, setValue }] = useArrayState(zones);

  useEffect(() => {
    setValue(zones);
  }, [zones]);

  const handleOnClose = useCallback(() => {
    setValue([...zones]);

    onClose();
  }, [zones, onClose]);

  const handleDoneClick = useCallback(() => {
    onDone(selectedZones);
    onClose();
  }, [onDone, selectedZones]);

  return (
    <Drawer open={open} onClose={handleOnClose}>
      <DrawerHeader onClose={handleOnClose}>Select Zones</DrawerHeader>

      <DrawerContent>
        <SelectZonesList
          zones={selectedZones}
          appendZone={append}
          removeZone={remove}
        />
      </DrawerContent>

      <DrawerFooter>
        <Button fullWidth onClick={handleDoneClick}>
          Done
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
