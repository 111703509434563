import { ButtonBase } from "@mui/material";
import {
  formatDayOfMonth,
  formatDayOfWeek,
  HourlyAvailability,
  ScheduleAvailability,
} from "@ses-mams/react-utils";
import { memo, useLayoutEffect, useRef } from "react";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { useGridCellHeight } from "../../context/gridCellHeightContext";

type AvailabilityGridHeaderProps = {
  availability: HourlyAvailability | ScheduleAvailability;
  onDatePress?: (date: Date) => void;
};

export const AvailabilityGridHeader = ({
  availability,
  onDatePress,
}: AvailabilityGridHeaderProps) => {
  const gridHeaderRef = useRef<HTMLDivElement>(null);

  const { setHourCellHeight } = useGridCellHeight();

  useLayoutEffect(() => {
    const handleResize = () => {
      if (gridHeaderRef.current?.offsetHeight) {
        setHourCellHeight(gridHeaderRef.current?.offsetHeight);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div ref={gridHeaderRef}>
      <Stack direction="row">
        {availability.map(({ date }) => {
          return (
            <AvailabilityGridHeaderDate
              date={date}
              key={`grid-header-${date.toISOString()}`}
              onDatePress={onDatePress}
            />
          );
        })}
      </Stack>
    </div>
  );
};

export const AvailabilityGridHeaderDate = memo(
  ({
    date,
    onDatePress,
  }: {
    date: Date;
    onDatePress?: (date: Date) => void;
  }) => {
    const renderHeaderDate = (isAction = false) => {
      return (
        <Stack
          flex={1}
          align="center"
          justify="center"
          border="tableCell"
          background={"tableGutter"}
          columnGap="small"
          borderBottomWidth="medium"
          borderLeftWidth="medium"
          paddingX="small"
          paddingY={{ xs: "small", md: "medium" }}
          direction={{
            xs: "column",
            md: "row",
          }}
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Text
            weight="semi"
            tone={isAction ? "action" : "primary"}
            align="center"
          >
            {formatDayOfMonth(date)}
          </Text>
          <Text tone={isAction ? "action" : "primary"} align="center">
            {formatDayOfWeek(date)}
          </Text>
        </Stack>
      );
    };

    if (onDatePress) {
      return (
        <ButtonBase
          onClick={() => onDatePress(date)}
          sx={{ width: "100%", height: "100%" }}
        >
          {renderHeaderDate(true)}
        </ButtonBase>
      );
    }

    return renderHeaderDate();
  }
);
