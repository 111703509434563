import { ActivationForm } from "./ActivationForm";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { ContactGroup, Group, Member } from "@ses-mams/api-contract";
import {
  transformActivationFormForCreate,
  useActivationForm,
} from "../../hooks";
import { CheckboxField } from "~/components/ui/checkbox";
import { EditMemberList } from "~/components/common/editMemberList";
import { ActivationFormSchema } from "@ses-mams/validation";
import { tsr } from "~/utils/client";
import { FormProvider } from "react-hook-form";
import {
  SelectAllMembersQuery,
  formatDateForServer,
} from "@ses-mams/react-utils";
import { EditGroupList } from "~/components/common/editGroupList";

export const AddActivationForm = () => {
  const navigate = useNavigate();

  const [members, setMembers] = useState<Array<Member>>([]);
  const [groups, setGroups] = useState<Array<Group>>([]);
  const [contactGroups, setContactGroups] = useState<Array<ContactGroup>>([]);
  const [requestAvailableMembersOnly, setRequestAvailableMembersOnly] =
    useState(true);
  const [selectAllMembersQuery, setSelectAllMembersQuery] =
    useState<SelectAllMembersQuery>();

  const formMethods = useActivationForm({});
  const { watch } = formMethods;

  const selectedUnit = watch("unit");
  const watchStartDate = watch("start").date;
  const watchEndDate = watch("end").date;

  const { mutateAsync } = tsr.activations.create.useMutation();

  const onSubmit = useCallback(
    async (formData: ActivationFormSchema) => {
      await mutateAsync({
        body: transformActivationFormForCreate(
          formData,
          members,
          groups,
          contactGroups,
          requestAvailableMembersOnly,
          selectAllMembersQuery
        ),
      });

      navigate("/requests?tab=operational");
    },
    [members, groups, contactGroups, requestAvailableMembersOnly]
  );

  const handleRequestAvailableMembersOnlyClick = useCallback(() => {
    setRequestAvailableMembersOnly(prev => !prev);
  }, [setRequestAvailableMembersOnly]);

  const formattedStart = useMemo(
    () => formatDateForServer(watchStartDate),
    [watchStartDate]
  );
  const formattedEnd = useMemo(
    () => formatDateForServer(watchEndDate),
    [watchEndDate]
  );

  const handleChangeSelectedGroups = useCallback(
    (nextGroups: Array<Group>, nextContactGroups: Array<ContactGroup>) => {
      setGroups(nextGroups);
      setContactGroups(nextContactGroups);
    },
    []
  );

  return (
    <FormProvider {...formMethods}>
      <ActivationForm isEditing={false} onSubmit={onSubmit}>
        <CheckboxField
          label="Only send to available members"
          checked={requestAvailableMembersOnly}
          onClick={handleRequestAvailableMembersOnlyClick}
        />
        <EditGroupList
          selectedGroups={groups}
          selectedContactGroups={contactGroups}
          unitId={selectedUnit.id}
          onChangeSelectedGroups={handleChangeSelectedGroups}
        />
        <EditMemberList
          showAvailabilityStatus
          selectedMembers={members}
          onChangeSelectedMembers={setMembers}
          isLoadingMembers={false}
          unitId={selectedUnit.id}
          currentAvailabilityStatusTime={formattedStart}
          requiredAvailabilityWindow={
            requestAvailableMembersOnly
              ? {
                  start: formattedStart,
                  end: formattedEnd,
                }
              : undefined
          }
          selectAllMembersQuery={selectAllMembersQuery}
          setSelectAllMembersQuery={setSelectAllMembersQuery}
        />
      </ActivationForm>
    </FormProvider>
  );
};
