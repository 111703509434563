export function mapKeyToValue<Key extends keyof Scale, Scale>(
  key: Key | undefined,
  scale: Scale
) {
  if (typeof key === "undefined") {
    return undefined;
  }

  return scale[key];
}
