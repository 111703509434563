import { useParams, Navigate } from "react-router-dom";
import { Container } from "@mui/material";
import { tsr } from "~/utils/client";
import { Spinner } from "~/components/ui/spinner";
import { Box } from "~/components/ui/box";
import { EditGroupForm } from "./components/EditGroupForm";

export const EditGroupPage = () => {
  const params = useParams();
  const groupId = params.groupId as string;
  const unitId = params.unitId as string;

  const { isLoading, data } = tsr.groups.get.useQuery({
    queryKey: ["groups", groupId],
    queryData: {
      params: {
        id: groupId,
        unitId,
      },
    },
    enabled: Boolean(groupId),
  });

  if (!isLoading && !data?.body) {
    return <Navigate to="/units" />;
  }

  return (
    <Container maxWidth="sm">
      {isLoading ? (
        <Box display="flex" align="center" justify="center">
          <Spinner />
        </Box>
      ) : (
        <EditGroupForm unitId={unitId} group={data?.body} />
      )}
    </Container>
  );
};
