import {
  Member,
  OutOfAreaActivationRequest,
  OutOfAreaActivationSummary,
} from "@ses-mams/api-contract";
import { useMemo } from "react";
import { useArraysIntersect } from "./useArraysIntersect";

type Params = {
  member?: Member;
  request?: OutOfAreaActivationRequest;
  activation?: OutOfAreaActivationSummary;
};

export const useCanAmendOutOfAreaActivationRequestResponse = ({
  member,
  request,
  activation,
}: Params) => {
  const hasAmendPermission = useArraysIntersect(
    [
      "OperationalCoordinator",
      "OOAACoordinator",
      "Administrator",
      "SystemAdministrator",
      "UnitApprover",
    ],
    member?.roles ?? []
  );

  return useMemo(() => {
    if (!request || !member || !activation) {
      return false;
    }

    if (request.blocked || activation.closedAt) {
      return false;
    }

    if (hasAmendPermission || member.id === request.member.id) {
      return !["Activated", "Deactivated"].includes(request.status);
    }

    return false;
  }, [activation, request, member, hasAmendPermission]);
};
