import { useQueryClient } from "@tanstack/react-query";
import { captureException } from "@sentry/react";
import { useCallback, useMemo } from "react";
import { useChatContext } from "stream-chat-react";
import { useNavigate } from "react-router-dom";
import { useToast } from "~/components/ui/toast";
import { useAuth, useHasAnyRole } from "~/context/auth";
import { tsr } from "~/utils/client";

export const useChannelMutations = () => {
  const { member } = useAuth();
  const { channel } = useChatContext();
  const { addToast } = useToast();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const hasCommandRole = useHasAnyRole([
    "OperationalCoordinator",
    "ActivityCoordinator",
  ]);

  const { mutateAsync: disableChannelMutation } =
    tsr.channels.disableChannel.useMutation();

  const deleteChannel = useCallback(async () => {
    if (!channel?.id) {
      return;
    }

    try {
      await disableChannelMutation({ params: { channelId: channel.id } });

      navigate("/messages");
    } catch (error) {
      captureException(error);

      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [channel, member?.id]);

  const { mutateAsync: removeChannelMembers, isPending: isRemovingMember } =
    tsr.chat.removeChannelMembers.useMutation();

  const leaveChannel = useCallback(async () => {
    if (!channel?.id || !member?.id) {
      return;
    }

    try {
      await removeChannelMembers({
        params: {
          channelId: channel.id,
        },
        body: {
          memberIds: [member.id],
        },
      });

      navigate("/messages");
    } catch (error) {
      captureException(error);

      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [channel?.id, member?.id]);

  const capabilities = useMemo(() => {
    const ownCapabilities = new Set(
      (channel?.data?.own_capabilities ?? []) as Array<string>
    );

    return {
      canDeleteChannel: ownCapabilities.has("delete-channel"),
      canUpdateMembers: ownCapabilities.has("update-channel-members"),
      canLeaveChannel: ownCapabilities.has("leave-channel"),
    };
  }, [channel?.data?.own_capabilities]);

  const isChannelCreator = useMemo(() => {
    const channelCreatedByData = channel?.data?.created_by as Record<
      string,
      unknown
    >;

    return member?.id && member.id === channelCreatedByData?.id;
  }, [channel?.data?.created_by, member?.id]);

  const canUpdateMembers =
    (hasCommandRole || isChannelCreator) && capabilities.canUpdateMembers;

  const removeMember = useCallback(
    async (memberId: string) => {
      if (!channel?.id) {
        return;
      }

      try {
        await removeChannelMembers({
          params: {
            channelId: channel.id,
          },
          body: {
            memberIds: [memberId],
          },
        });

        queryClient.invalidateQueries({
          queryKey: ["channel", channel.id, "members"],
        });
      } catch (error) {
        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    [channel?.id]
  );

  return {
    deleteChannel,
    leaveChannel,
    capabilities,
    canUpdateMembers,
    removeMember,
    isRemovingMember,
  };
};
