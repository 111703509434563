import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { tsRestFetchApi, ErrorHttpStatusCode } from "@ts-rest/core";
import { initTsrReactQuery } from "@ts-rest/react-query/v5";
import React, { useRef } from "react";

import { contract, ApiErrorResponse } from "@ses-mams/api-contract";

import { getLocalAccessToken, useAuth } from "~/context/auth";

export const tsr = initTsrReactQuery(contract, {
  baseUrl: import.meta.env.VITE_API_BASE_URL,
  baseHeaders: {},
  api: async args => {
    const accessToken = getLocalAccessToken();

    return tsRestFetchApi({
      ...args,
      headers: {
        ...args.headers,
        Authorization: accessToken ? `Bearer ${accessToken}` : "",
        // TODO: sort out cors config for this header
        // "x-source": "web",
      },
    });
  },
});

type InitAppQueryClientParams = {
  refresh: () => Promise<boolean>;
  logout: () => void;
};

type ApiErrorPayload = {
  status: ErrorHttpStatusCode;
  body: ApiErrorResponse;
};

const useInitAppQueryClient = ({
  refresh,
  logout,
}: InitAppQueryClientParams) => {
  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry(failureCount, error) {
            if (isApiError(error) && [401, 403, 404].includes(error.status)) {
              return false;
            }

            return failureCount <= 1;
          },
          refetchOnWindowFocus: false,
        },
      },
      queryCache: new QueryCache({
        onError: async (error, query) => {
          if (isApiError(error)) {
            if (error.status === 401) {
              try {
                if (error.body?.code === "revoked_token") {
                  // Token refresh will not succeed and the user must log in again
                  logout();
                  return;
                }

                const success = await refresh();

                if (success) {
                  await queryClient.refetchQueries({
                    queryKey: query.queryKey,
                  });
                }
              } catch {
                logout();
              }
            }
          }
        },
      }),
    })
  ).current;

  return queryClient;
};

export const AppQueryClientProvider = ({
  children,
}: React.PropsWithChildren) => {
  const { logout, refresh } = useAuth();
  const queryClient = useInitAppQueryClient({ logout, refresh });

  return (
    <QueryClientProvider client={queryClient}>
      <tsr.ReactQueryProvider>{children}</tsr.ReactQueryProvider>
    </QueryClientProvider>
  );
};

function isApiError(error: unknown): error is ApiErrorPayload {
  return (
    typeof error === "object" &&
    error !== null &&
    "status" in error &&
    "body" in error
  );
}
