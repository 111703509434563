import { useCallback, useState } from "react";
import { captureException } from "@sentry/react";

import { ActivationRequestMember } from "@ses-mams/api-contract";
import { useUpdateActivationMembersQueryCache } from "@ses-mams/react-utils";

import { useToast } from "~/components/ui/toast";
import { useShowConflictConfirmationDialog } from "~/hooks/useShowConflictConfirmationDialog";
import { tsr } from "~/utils/client";

export const useActivateMutations = (
  activationId: string,
  activationMembers: Array<ActivationRequestMember>,
  onActivateSuccess: () => void
) => {
  const { addToast } = useToast();
  const updateQueryCache = useUpdateActivationMembersQueryCache(activationId);
  const { showConflictConfirmationDialog } = useShowConflictConfirmationDialog({
    type: "activation",
  });

  // Hijack control of loading state to avoid flashes of stale state inbetween request finishing and cache updating
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: addActivationMembersMutation } =
    tsr.activations.addActivationMembers.useMutation();

  const { mutateAsync: requestActivationMutation } =
    tsr.activations.requestActivation.useMutation();

  const { mutateAsync: activateMutation } =
    tsr.activations.activate.useMutation();

  const activate = useCallback(
    async (force = false) => {
      try {
        setIsLoading(true);
        // Perform the mutations sequentially so that we can be guaranteed the cache update is correct.

        // If the members have been requested to activate, then confirm activation.
        // Perform this first because it could present a confirmation modal.
        const requestIdsToBeActivated = activationMembers
          .filter(m => m.status === "ActivationRequested")
          .map(m => m.id);

        if (requestIdsToBeActivated.length > 0) {
          await activateMutation({
            params: {
              activationId,
            },
            body: {
              activationRequestIds: requestIdsToBeActivated,
              force,
            },
          });
        }

        // Re-request any deactivated members
        const memberIdsToBeReactivated = activationMembers
          .filter(m => m.status === "Deactivated")
          .map(m => m.member.id);

        if (memberIdsToBeReactivated.length > 0) {
          await addActivationMembersMutation({
            params: {
              activationId,
            },
            body: {
              contactGroupIds: [],
              groupIds: [],
              memberIds: memberIdsToBeReactivated,
            },
          });
        }

        // If the members haven't been requested to activate, then request them.
        const result = await requestActivationMutation({
          params: {
            activationId,
          },
          body: {
            activationRequestIds: activationMembers
              .filter(m =>
                ["AvailabilityRequested", "AvailabilitySubmitted"].includes(
                  m.status
                )
              )
              .map(m => m.id),
          },
        });

        await updateQueryCache(result.body);

        onActivateSuccess();
      } catch (error) {
        const apiError = error as any;
        if (apiError?.status === 422) {
          showConflictConfirmationDialog({
            text: apiError.body.message,
            onConfirm: () => activate(true),
          });
          return;
        }

        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [activationId, activationMembers, onActivateSuccess]
  );

  return {
    activate,
    isLoading,
  };
};
