import { ReactNode } from "react";
import { GoogleMap, GoogleMapProps } from "@react-google-maps/api";

export type MapProps = Pick<GoogleMapProps, "center"> & {
  children?: ReactNode;
};

export const Map = ({ center = DEFAULT_CENTER, children }: MapProps) => {
  return (
    <GoogleMap
      options={{ streetViewControl: false, mapTypeControl: false }}
      mapContainerStyle={{
        height: "100%",
      }}
      center={center}
      zoom={11}
    >
      {children}
    </GoogleMap>
  );
};

const DEFAULT_CENTER = {
  lat: -33.9,
  lng: 151.12,
};
