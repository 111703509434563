import { Container } from "@mui/material";
import { Heading } from "~/components/ui/heading";
import { Row, Stack } from "~/components/ui/stack";
import { tsr } from "~/utils/client";
import {
  getNextPageParam as baseGetNextPageParam,
  formatDateTime,
  PaginatedBody,
  PaginatedPage,
} from "@ses-mams/react-utils";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import {
  AuditLogsFilters,
  FiltersFormSchema,
} from "./components/AuditLogsFilters";
import { Button, IconButton } from "~/components/ui/button";
import { ArrowRefreshOutlineIcon } from "~/components/ui/icon";
import { endOfDay, startOfDay } from "date-fns";
import { useToast } from "~/components/ui/toast";
import { AuditDetailsDrawer } from "./components/AuditDetailsDrawer";
import { AuditLog } from "@ses-mams/api-contract";
import { formatUserName } from "./components/AuditDetails";

const AUDIT_PAGE_SIZE = 50;

export const getNextPageParam = <
  T,
  TBody extends PaginatedBody<T>,
  TPage extends PaginatedPage<TBody>,
>(
  lastPage: TPage,
  allPages: TPage[]
) => baseGetNextPageParam(lastPage, allPages, AUDIT_PAGE_SIZE);

const columns: GridColDef<AuditLog>[] = [
  {
    field: "action",
    headerName: "Action",
    width: 250,
    sortable: false,
  },
  {
    field: "details",
    headerName: "Details of the change",
    width: 300,
    sortable: false,
  },
  {
    field: "createdAt",
    headerName: "Date",
    valueGetter: (_, row) =>
      row.createdAt ? formatDateTime(new Date(row.createdAt)) : "",
    width: 150,
    sortable: false,
  },
  {
    field: "memberId",
    headerName: "Member ID",
    valueGetter: (_, row) => row.member?.id,
    width: 150,
    sortable: false,
  },
  {
    field: "memberName",
    headerName: "User",
    valueGetter: (_, row) => formatUserName(row.member),
    width: 150,
    sortable: false,
  },
];

export const AuditPage = () => {
  const { addToast } = useToast();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });

  const [filters, setFilters] = useState<FiltersFormSchema>();

  const serverQuery = useMemo(
    () => ({
      unitId: filters?.unit?.id,
      minRequestCreatedAt: filters?.minRequestCreatedAt
        ? startOfDay(filters?.minRequestCreatedAt).toISOString()
        : undefined,
      maxRequestCreatedAt: filters?.maxRequestCreatedAt
        ? endOfDay(filters?.maxRequestCreatedAt).toISOString()
        : undefined,
      actions: filters?.actions,
      activationId: filters?.activationId,
      activationName: filters?.activationName,
      activityId: filters?.activityId,
      activityName: filters?.activityName,
      ooaaActivationId: filters?.ooaaActivationId,
      ooaaActivationName: filters?.ooaaActivationName,
      details: filters?.details,
      memberId: filters?.memberId,
      memberName: filters?.memberName,
      levelOfOoaaRequest: filters?.levelOfOoaaRequest ?? undefined,
      ooaaRequestActivatedForId:
        filters?.ooaaRequestActivatedForId ?? undefined,
    }),
    [filters]
  );

  const { data, isLoading, refetch, isRefetching } = tsr.audit.list.useQuery({
    queryKey: [
      "audit",
      paginationModel.page,
      paginationModel.pageSize,
      filters?.unit?.id,
      filters?.minRequestCreatedAt,
      filters?.maxRequestCreatedAt,
      filters?.actions,
      filters?.activationId,
      filters?.activationName,
      filters?.activityId,
      filters?.activityName,
      filters?.ooaaActivationId,
      filters?.ooaaActivationName,
      filters?.details,
      filters?.memberId,
      filters?.memberName,
      filters?.levelOfOoaaRequest,
      filters?.ooaaRequestActivatedForId,
    ],
    queryData: {
      query: {
        skip: paginationModel.page * paginationModel.pageSize,
        take: paginationModel.pageSize,
        ...serverQuery,
      },
    },
  });

  const { mutateAsync: downloadCsv, isPending: isDownloadCsvLoading } =
    tsr.audit.downloadCsv.useMutation();

  const [selectedAuditLog, setSelectedAuditLog] = useState<AuditLog | null>(
    null
  );

  const handleSearch = useCallback(async (data: FiltersFormSchema) => {
    setPaginationModel(prev => ({
      ...prev,
      page: 0,
    }));

    setFilters(data);
  }, []);

  const handleDownloadCsv = useCallback(async () => {
    const {
      body: { downloadUrl, maxRowsExceeded },
    } = await downloadCsv({
      body: serverQuery,
    });

    if (maxRowsExceeded) {
      addToast({
        tone: "caution",
        title: "Too many results",
        message:
          "Your filters result in too many rows. Only the first 10,000 will be downloaded.",
      });
    }

    window.location.href = downloadUrl;
  }, [serverQuery]);

  return (
    <>
      <Container>
        <Stack gap="medium">
          <Heading level="1">Audit Logs</Heading>

          <Row align="center" justify="space-between" gap="medium">
            <Stack direction="row" gap="small">
              <AuditLogsFilters onSetFilters={handleSearch} />
              <Button
                variant="tertiary"
                onClick={handleDownloadCsv}
                busy={isDownloadCsvLoading}
              >
                Download CSV
              </Button>
            </Stack>

            <IconButton
              aria-label="Refresh page"
              onClick={() => {
                refetch();
              }}
              size="small"
            >
              <ArrowRefreshOutlineIcon />
            </IconButton>
          </Row>

          <Stack sx={{ width: "100%", minHeight: { xs: 500, md: 200 } }}>
            <DataGrid
              rows={data?.body.items ?? []}
              columns={columns}
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={model => setPaginationModel(model)}
              rowCount={data?.body?.totalCount ?? 0}
              disableColumnMenu
              rowSelection={false}
              loading={isLoading || isRefetching}
              onRowClick={params => setSelectedAuditLog(params.row)}
            />
          </Stack>
        </Stack>
      </Container>
      <AuditDetailsDrawer
        open={!!selectedAuditLog}
        onClose={() => setSelectedAuditLog(null)}
        auditLog={selectedAuditLog}
      />
    </>
  );
};
