import { HourlyAvailability } from "@ses-mams/react-utils";
import { Stack } from "~/components/ui/stack";
import { AvailabilityStatus } from "@ses-mams/api-contract";
import { useState, useCallback, useEffect, useRef, memo } from "react";
import keyBy from "lodash.keyby";
import merge from "lodash.merge";
import { AvailabilityHourlyGrid } from "./AvailabilityHourlyGrid";
import { addDays } from "date-fns";
import { AvailabilityHourlyGuide } from "./AvailabilityHourlyGuide";
import { useCellSelectionStatusChangedEvent } from "../../hooks/useCellSelectionStatusChangedEvent";
import { Spinner } from "~/components/ui/spinner";

type EditAvailabilityHourlyGridProps = {
  availability: HourlyAvailability;
  onAvailabilityEdited: (editedAvailability: HourlyAvailability) => void;
  firstDayOfTheWeek: Date;
  lastDayOfTheWeek: Date;
  selectedValue?: {
    status: AvailabilityStatus | null;
    emergenciesOnly?: boolean;
    conditionalReason?: string;
  };
  onCellSelectionStatusChanged: (hasSelectedCells: boolean) => void;
};

export const EditAvailabilityHourlyGrid = memo(
  ({
    availability,
    onAvailabilityEdited,
    firstDayOfTheWeek,
    lastDayOfTheWeek,
    selectedValue,
    onCellSelectionStatusChanged,
  }: EditAvailabilityHourlyGridProps) => {
    const [selectedCells, setSelectedCells] = useState<
      { date: Date; hour: number }[]
    >([]);

    const availabilityGridData = useRef<HourlyAvailability>([]);
    const [liveEditableData, setLiveEditableData] = useState([...availability]);

    useCellSelectionStatusChangedEvent(
      selectedCells.length,
      onCellSelectionStatusChanged
    );

    useEffect(() => {
      if (availability.length === 0) {
        return;
      }

      setLiveEditableData(prevLiveData => {
        const mergedData = merge(
          {},
          keyBy(availability, "date"),
          keyBy(prevLiveData, "date")
        );

        const newLiveData = Object.values(mergedData);

        availabilityGridData.current = newLiveData.filter(
          ({ date }) =>
            date >= availability[0].date &&
            date <= availability[availability.length - 1].date
        );

        return newLiveData;
      });
    }, [availability]);

    useEffect(() => {
      if (!selectedValue?.status) {
        return;
      }

      const editedAvailability: HourlyAvailability = [];
      for (const selectedCell of selectedCells) {
        const liveDataCell = liveEditableData
          .find(({ date }) => +date === +selectedCell.date)
          ?.slots.find(({ hour }) => hour === selectedCell.hour);

        if (!liveDataCell) {
          continue;
        }

        liveDataCell.status = selectedValue.status;
        if (selectedValue.status === "ImmediatelyAvailable") {
          liveDataCell.emergenciesOnly = selectedValue.emergenciesOnly ?? null;
        } else if (selectedValue.status === "Conditional") {
          liveDataCell.conditionalReason =
            selectedValue.conditionalReason ?? null;
        }

        let editedEntry = editedAvailability.find(
          ({ date }) => +date === +selectedCell.date
        );
        if (!editedEntry) {
          editedEntry = {
            date: selectedCell.date,
            slots: [],
          };

          editedAvailability.push(editedEntry);
        }

        editedEntry.slots.push(liveDataCell);
      }

      setSelectedCells([]);
      onAvailabilityEdited?.(editedAvailability);
    }, [selectedValue, liveEditableData]);

    const handleCellPress = useCallback((date: Date, hour: number) => {
      setSelectedCells(prevSelectedCells => {
        if (
          prevSelectedCells.some(
            prevSelectedCell =>
              +prevSelectedCell.date === +date && prevSelectedCell.hour === hour
          )
        ) {
          return prevSelectedCells.filter(
            prevSelectedCell =>
              !(
                +prevSelectedCell.date === +date &&
                prevSelectedCell.hour === hour
              )
          );
        }

        return [
          {
            date,
            hour,
          },
          ...prevSelectedCells,
        ];
      });
    }, []);

    const handleDatePress = useCallback((date: Date) => {
      setSelectedCells(prevSelectedCells => {
        const selectedCellCountBeforeFiltering = prevSelectedCells.length;

        const selectedCellsWithoutSelectedDate = prevSelectedCells.filter(
          prevSelectedCell => +prevSelectedCell.date !== +date
        );

        const selectedCellCountAfterFiltering =
          selectedCellsWithoutSelectedDate.length;

        if (
          selectedCellCountBeforeFiltering - selectedCellCountAfterFiltering ===
          24
        ) {
          return selectedCellsWithoutSelectedDate;
        }

        return [
          ...selectedCellsWithoutSelectedDate,
          ...[...Array(24)].map((_, index) => ({
            date,
            hour: index,
          })),
        ];
      });
    }, []);

    const handleHourPress = useCallback(
      (hour: number) => {
        setSelectedCells(prevSelectedCells => {
          const selectedCellCountBeforeFiltering = prevSelectedCells.length;

          const selectedCellsWithoutSelectedHour = prevSelectedCells.filter(
            prevSelectedCell =>
              !(
                prevSelectedCell.hour === hour &&
                prevSelectedCell.date >= firstDayOfTheWeek &&
                prevSelectedCell.date <= lastDayOfTheWeek
              )
          );

          const selectedCellCountAfterFiltering =
            selectedCellsWithoutSelectedHour.length;

          if (
            selectedCellCountBeforeFiltering -
              selectedCellCountAfterFiltering ===
            7
          ) {
            return selectedCellsWithoutSelectedHour;
          }

          const newlySelectedCells: typeof prevSelectedCells = [];

          let currentCalculatedDate = firstDayOfTheWeek;
          while (currentCalculatedDate <= lastDayOfTheWeek) {
            newlySelectedCells.push({
              date: currentCalculatedDate,
              hour,
            });

            currentCalculatedDate = addDays(currentCalculatedDate, 1);
          }

          return [...selectedCellsWithoutSelectedHour, ...newlySelectedCells];
        });
      },
      [firstDayOfTheWeek, lastDayOfTheWeek]
    );

    if (!availabilityGridData.current?.length) {
      return <Spinner />;
    }

    return (
      <Stack direction="row" overflowX="auto">
        <AvailabilityHourlyGuide onHourPress={handleHourPress} />
        <AvailabilityHourlyGrid
          availability={availabilityGridData.current}
          onCellPress={handleCellPress}
          onDatePress={handleDatePress}
          selectedCells={selectedCells}
        />
      </Stack>
    );
  }
);
