import {
  ActivityAvailabilityStatus,
  ActivityMember,
} from "@ses-mams/api-contract";
import { BackgroundTones } from "../ui";

export type ActivityMembersSectionKeys =
  | ActivityAvailabilityStatus
  | "Confirmed"
  | "Unset"
  | "Removed";

export const getActivityMemberSectionTitle = (
  key: ActivityMembersSectionKeys,
  count?: number
) => {
  // it just happens that the enum value matches the label
  if (typeof count === "undefined") {
    return key;
  }

  return `${key} (${count})`;
};

export const getBackgroundFromActivityMember = (
  activityMember: ActivityMember
): BackgroundTones => {
  const { availabilityStatus, status } = activityMember;

  if (status === "AttendanceConfirmed") {
    return "infoSubtle";
  }

  if (status === "Removed") {
    return "criticalSubtle";
  }

  if (availabilityStatus === "Available") {
    return "positiveSubtle";
  }

  if (availabilityStatus === "Conditional") {
    return "cautionSubtle";
  }

  if (availabilityStatus === "Unavailable") {
    return "criticalSubtle";
  }

  return "surfaceSubtle";
};

export const getActivityMemberSectionBackground = (
  key: ActivityMembersSectionKeys
) =>
  (
    ({
      Confirmed: "info",
      Available: "positiveLow",
      Conditional: "caution",
      Unavailable: "critical",
      Unset: "surfaceLow",
      Removed: "critical",
    }) as const satisfies Record<ActivityMembersSectionKeys, BackgroundTones>
  )[key];
