import {
  OutOfAreaActivationRequestStatus,
  OutOfAreaActivationRequestWithStatusCount,
} from "@ses-mams/api-contract";
import { useMemo } from "react";

export const useOutOfAreaActivationRequestListSections = (
  pages?: Array<{
    status: number;
    body: { items: Array<OutOfAreaActivationRequestWithStatusCount> };
  }>,
  archived?: boolean
) => {
  return useMemo(() => {
    const sectionMap: Record<
      OutOfAreaActivationStatusHeading,
      Array<OutOfAreaActivationRequestWithStatusCount>
    > = {
      // insertion order of these keys must match the endpoint's ORDER BY
      Activated: [],
      "Supported-Recommended for Activation": [],
      "Awaiting Approval": [],
      "Awaiting Response": [],
      Open: [],
      Rejected: [],
      Withdrawn: [],
      Deactivated: [],
      Archived: [],
    };

    (pages || []).flatMap(page => {
      if (page.status !== 200) {
        return;
      }

      if (archived) {
        // Only showing archived activations
        sectionMap.Archived.push(...page.body.items);
        return;
      }

      page.body.items.forEach(item => {
        sectionMap[item.status ? mapStatusToSection[item.status] : "Open"].push(
          item
        );
      });
    });

    return Object.keys(sectionMap).flatMap(key => {
      const typedKey = key as keyof typeof sectionMap;
      const keyedData = sectionMap[typedKey];

      if (!keyedData.length) {
        return [];
      }

      return [
        {
          title: typedKey,
          data: keyedData,
        },
      ];
    });
  }, [pages, archived]);
};

type OutOfAreaActivationStatusHeading =
  | "Activated"
  | "Supported-Recommended for Activation"
  | "Awaiting Approval"
  | "Awaiting Response"
  | "Open"
  | "Rejected"
  | "Withdrawn"
  | "Deactivated"
  | "Archived";

const mapStatusToSection = {
  AvailabilityRequested: "Awaiting Response",
  UnitApprovalPending: "Awaiting Approval",
  UnitApprovalDeclined: "Rejected",
  ClusterApprovalPending: "Awaiting Approval",
  ClusterApprovalDeclined: "Rejected",
  ZoneApprovalPending: "Awaiting Approval",
  ZoneApprovalDeclined: "Rejected",
  ActivationPending: "Supported-Recommended for Activation",
  Activated: "Activated",
  Deactivated: "Deactivated",
  Withdrawn: "Withdrawn",
} as const satisfies Record<
  OutOfAreaActivationRequestStatus,
  OutOfAreaActivationStatusHeading
>;
