import {
  Cluster,
  OutOfAreaActivationRequestStatus,
  OutOfAreaActivationRole,
  Unit,
  Zone,
} from "@ses-mams/api-contract";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHasAnyRole } from "~/context/auth";
import { tsr } from "~/utils/client";

type FiltersShape = {
  status: OutOfAreaActivationRequestStatus;
  roles: Array<OutOfAreaActivationRole>;
  units: Array<Unit>;
  clusters: Array<Cluster>;
  zones: Array<Zone>;
};

type ApprovalQueueFiltersContextType = {
  isLoadingInitialFilters: boolean;
  status: FiltersShape["status"];
  roles: FiltersShape["roles"];
  units: FiltersShape["units"];
  clusters: FiltersShape["clusters"];
  zones: FiltersShape["zones"];
  setStatus: (status: FiltersShape["status"]) => void;
  setRoles: React.Dispatch<React.SetStateAction<FiltersShape["roles"]>>;
  setUnits: React.Dispatch<React.SetStateAction<FiltersShape["units"]>>;
  setClusters: React.Dispatch<React.SetStateAction<FiltersShape["clusters"]>>;
  setZones: React.Dispatch<React.SetStateAction<FiltersShape["zones"]>>;
  getInitialFilters: () => FiltersShape;
};

const ApprovalQueueFiltersContext =
  createContext<ApprovalQueueFiltersContextType>({
    isLoadingInitialFilters: false,
    status: "UnitApprovalPending",
    roles: [],
    units: [],
    clusters: [],
    zones: [],
    setStatus: () => null,
    setRoles: () => null,
    setUnits: () => null,
    setClusters: () => null,
    setZones: () => null,
    getInitialFilters: () => ({
      status: "UnitApprovalPending",
      roles: [],
      units: [],
      clusters: [],
      zones: [],
    }),
  });

export const ApprovalQueueFiltersContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [isLoadingInitialFilters, setIsLoadingInitialFilters] = useState(true);
  const [status, setStatus] = useState<OutOfAreaActivationRequestStatus>(
    "UnitApprovalPending"
  );
  const [roles, setRoles] = useState<FiltersShape["roles"]>([]);
  const [units, setUnits] = useState<FiltersShape["units"]>([]);
  const [clusters, setClusters] = useState<FiltersShape["clusters"]>([]);
  const [zones, setZones] = useState<FiltersShape["zones"]>([]);

  const isUnitApprover = useHasAnyRole(["UnitApprover"]);
  const isClusterApprover = useHasAnyRole(["ClusterApprover"]);
  const isZoneApprover = useHasAnyRole(["ZoneApprover"]);

  const { data: unitsData } = tsr.units.list.useQuery({
    queryKey: ["units", { memberUnitsOnly: true }],
    queryData: {
      query: {
        memberUnitsOnly: true,
      },
    },
    enabled: isUnitApprover,
  });

  const { data: clustersData } = tsr.clusters.list.useQuery({
    queryKey: ["clusters", { memberClustersOnly: true }],
    queryData: {
      query: {
        memberClustersOnly: true,
      },
    },
    enabled: isClusterApprover,
  });

  const { data: zonesData } = tsr.zones.list.useQuery({
    queryKey: ["zones", { memberZonesOnly: true }],
    queryData: {
      query: {
        memberZonesOnly: true,
      },
    },
    enabled: isZoneApprover,
  });

  const getInitialFilters = useCallback((): FiltersShape => {
    if (isZoneApprover) {
      return {
        status: "ZoneApprovalPending",
        roles: [],
        units: [],
        clusters: [],
        zones: zonesData?.body.items ?? [],
      };
    }

    if (isClusterApprover) {
      return {
        status: "ClusterApprovalPending",
        roles: [],
        units: [],
        clusters: clustersData?.body.items ?? [],
        zones: [],
      };
    }

    return {
      status: "UnitApprovalPending",
      roles: [],
      units: unitsData?.body.items ?? [],
      clusters: [],
      zones: [],
    };
  }, [
    isUnitApprover,
    isClusterApprover,
    isZoneApprover,
    unitsData,
    clustersData,
    zonesData,
  ]);

  const value = useMemo(
    () => ({
      isLoadingInitialFilters,
      status,
      setStatus,
      roles,
      setRoles,
      units,
      setUnits,
      clusters,
      setClusters,
      zones,
      setZones,
      getInitialFilters,
    }),
    [
      isLoadingInitialFilters,
      status,
      roles,
      units,
      clusters,
      zones,
      getInitialFilters,
    ]
  );

  useEffect(() => {
    if (
      !isLoadingInitialFilters ||
      !(
        unitsData?.body?.items ||
        clustersData?.body?.items ||
        zonesData?.body?.items
      )
    ) {
      return;
    }

    // Set the filters to an initial state
    const initial = getInitialFilters();

    setStatus(initial.status);
    setRoles(initial.roles);
    setUnits(initial.units);
    setClusters(initial.clusters);
    setZones(initial.zones);

    setIsLoadingInitialFilters(false);
    // Intentionally leave unitsData, clustersData, zonesData out of dependency array as they are a dependency of getInitialFilters
  }, [isLoadingInitialFilters, getInitialFilters]);

  return (
    <ApprovalQueueFiltersContext.Provider value={value}>
      {children}
    </ApprovalQueueFiltersContext.Provider>
  );
};

export const useApprovalQueueFiltersContext = () => {
  const ctx = useContext(ApprovalQueueFiltersContext);

  if (!ctx) {
    throw new Error(
      "Must be called within <ApprovalQueueFiltersContextProvider />"
    );
  }

  return ctx;
};
