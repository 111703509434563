import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  ApiErrorSchema,
  PaginationQueryParamsSchema,
  getPaginationResponseSchema,
} from "./common";

const c = initContract();

export const ZoneSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional(),
  address: z.string().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
});

export const zonesContract = c.router({
  get: {
    method: "GET",
    path: "/zones/:id",
    pathParams: z.object({
      id: z.string(),
    }),
    responses: {
      200: ZoneSchema,
      404: ApiErrorSchema,
    },
    summary: "Get the details of a zone",
  },
  list: {
    method: "GET",
    path: "/zones",
    query: PaginationQueryParamsSchema.merge(
      z.object({
        query: z.coerce.string().trim().optional(),
        memberZonesOnly: z.boolean().optional(),
      })
    ),
    responses: {
      200: getPaginationResponseSchema(ZoneSchema),
    },
    summary: "Get all zones",
  },
});

export type Zone = z.infer<typeof ZoneSchema>;
