import { Container, Link } from "@mui/material";

import { Box } from "~/components/ui/box";
import { Stack } from "~/components/ui/stack";
import { useAuth } from "~/context/auth";

import mapImgUrl from "./map.png";
import { Button } from "~/components/ui/button";
import { Heading } from "~/components/ui/heading";
import { Text } from "~/components/ui/text";
import { PRIVACY_POLICY_URL } from "~/utils/constants";

import sesIconURL from "~/assets/SESIcon.png";
import { TurnOnNotificationsPrompt } from "./components/TurnOnNotificationsPrompt";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Spinner } from "~/components/ui/spinner";

export const SignInPage = () => {
  const { oktaAuth, member, isLoading } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (member && !isLoading) {
      navigate("/units", { replace: true });
    }
  }, [member, isLoading]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        align="center"
        justify="center"
        sx={{ minHeight: "100vh" }}
      >
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <Stack
        display="flex"
        direction="row"
        gap="xxsmall"
        flex={1}
        justify="center"
        sx={{
          minHeight: "100vh",
        }}
      >
        <Box flex={1} padding="small" alignSelf="stretch" display="flex">
          <Container
            maxWidth="xs"
            sx={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Stack gap="small" flex={1} justify="center">
              <Stack gap="xsmall">
                <img src={sesIconURL} style={{ maxWidth: 60 }} />

                <Stack gap="xxsmall">
                  {/* This is a once-off font-size */}
                  <Heading level="1" sx={{ fontSize: 40 }}>
                    Log in to your account
                  </Heading>
                  <Text tone="secondary" size="large">
                    Click the button below to log in with your SES User
                    Credentials.
                  </Text>
                </Stack>
                <Button
                  onClick={() =>
                    oktaAuth.signInWithRedirect({ prompt: "login" })
                  }
                >
                  Login
                </Button>
              </Stack>

              <Stack direction="row" justify="space-between">
                <Text weight="medium" tone="secondary">
                  Having trouble logging in?
                </Text>

                <Link
                  variant="caption"
                  href="https://www.ses.nsw.gov.au/resources-folder/member-self-help/"
                  underline="none"
                  target="_blank"
                >
                  Get help
                </Link>
              </Stack>
            </Stack>
            <Stack justify="end">
              <Text weight="medium" tone="secondary" as="p" align="center">
                By continuing you agree to and have read our{" "}
                <Link
                  variant="body2"
                  href={PRIVACY_POLICY_URL}
                  underline="none"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
                .
              </Text>
            </Stack>
          </Container>
        </Box>

        <Box
          flex={1}
          display={{ xs: "none", md: "flex" }}
          overflow="hidden"
          justify="end"
          sx={{
            height: "100vh",
          }}
        >
          <img src={mapImgUrl} />
        </Box>
      </Stack>
      <TurnOnNotificationsPrompt />
    </>
  );
};
