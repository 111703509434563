import { capitalize } from "@mui/material";
import {
  AvailabilityDayOfWeekSelector,
  AvailabilityWeekSelector,
} from "~/components/common/availabilitySelector";
import { Row, Stack } from "~/components/ui/stack";
import { Button } from "~/components/ui/button";
import { useAvailabilityReportingContext } from "~/context/reporting/AvailabilityReportingContextProvider";
import { SelectField, SelectItem } from "~/components/ui/selectField";
import { Text } from "~/components/ui/text";
import { tsr } from "~/utils/client";

export const AvailabilityReportHeader = () => {
  const { data: zonesData, isLoading: isLoadingZones } =
    tsr.zones.list.useQuery({
      queryKey: ["zones"],
      queryData: {
        query: {
          take: 1000,
        },
      },
    });

  const { data: clustersData, isLoading: isLoadingClusters } =
    tsr.clusters.list.useQuery({
      queryKey: ["clusters"],
      queryData: {
        query: {
          take: 1000,
        },
      },
    });

  const { data: unitsData, isLoading: isLoadingUnits } =
    tsr.units.list.useQuery({
      queryKey: ["all-units"],
      queryData: {
        query: {
          take: 1000,
        },
      },
    });

  const { data: capabilitiesData } = tsr.capabilities.list.useQuery({
    queryKey: ["capabilities"],
    queryData: {
      query: {
        take: 1000,
      },
    },
  });

  const capabilites = capabilitiesData?.body.items ?? [];

  const collections =
    zonesData &&
    clustersData &&
    unitsData &&
    !isLoadingZones &&
    !isLoadingClusters &&
    !isLoadingUnits
      ? [
          { id: null, name: "Statewide", level: "state" },
          ...zonesData.body.items
            .filter(({ name }) => name !== "State HQ")
            .map(({ id, name }) => ({
              id,
              name,
              level: "zone",
            })),
          ...clustersData.body.items.map(({ id, name }) => ({
            id,
            name,
            level: "cluster",
          })),
          ...unitsData.body.items.map(({ id, name }) => ({
            id,
            name,
            level: "unit",
          })),
        ]
      : [];

  const {
    selectedDay,
    setSelectedDay,
    firstDayOfTheWeek,
    lastDayOfTheWeek,
    selectedCollection,
    setSelectedCollection,
    selectedCapability,
    setSelectedCapability,
    selectedMemberType,
    setSelectedMemberType,
    goToPreviousWeek,
    goToNextWeek,
    goToToday,
  } = useAvailabilityReportingContext();

  return (
    <Stack dividers dividerTone="neutral">
      <Stack
        direction="row"
        gap="medium"
        justify="space-between"
        paddingX="small"
        paddingY="medium"
      >
        <AvailabilityWeekSelector
          firstDayOfTheWeek={firstDayOfTheWeek}
          lastDayOfTheWeek={lastDayOfTheWeek}
          onGoToPreviousWeek={goToPreviousWeek}
          onGoToNextWeek={goToNextWeek}
        />

        <div>
          <Button variant="tertiary" onClick={goToToday} fullWidth>
            Today
          </Button>
        </div>
      </Stack>

      <Stack
        direction={{ xs: "column", md: "row" }}
        gap="medium"
        padding="large"
      >
        {collections.length ? (
          <SelectField
            label="Select Collection"
            value={selectedCollection}
            onChange={e => setSelectedCollection(e.target.value as string)}
            sx={{ minWidth: 180 }}
          >
            {collections.map(collection => (
              <SelectItem
                key={`${collection.level}-${collection.id}`}
                value={`${collection.id || ""}::${collection.level}`}
              >
                <Row
                  justify="space-between"
                  align="center"
                  gap="small"
                  sx={{ width: "100%" }}
                >
                  <Text weight="medium">{collection.name}</Text>
                  <Text tone="secondary">{`${capitalize(collection.level)}`}</Text>
                </Row>
              </SelectItem>
            ))}
          </SelectField>
        ) : null}

        <SelectField
          label="Filter Capability"
          value={selectedCapability}
          onChange={e => setSelectedCapability(e.target.value as string)}
          sx={{ minWidth: 120 }}
        >
          <SelectItem>
            <Text tone="action">Clear selection</Text>
          </SelectItem>
          {capabilites.map(capability => (
            <SelectItem key={capability.id} value={capability.id}>
              <Text weight="medium">{capability.name}</Text>
            </SelectItem>
          ))}
        </SelectField>

        <SelectField
          label="Filter Member type"
          value={selectedMemberType}
          onChange={e => setSelectedMemberType(e.target.value as string)}
          sx={{ minWidth: 120 }}
        >
          <SelectItem value="Any">
            <Text weight="medium">Any</Text>
          </SelectItem>
          <SelectItem value="Volunteer">
            <Text weight="medium">Volunteer</Text>
          </SelectItem>
          <SelectItem value="Staff">
            <Text weight="medium">Staff</Text>
          </SelectItem>
        </SelectField>
      </Stack>

      <AvailabilityDayOfWeekSelector
        start={firstDayOfTheWeek}
        onSelect={setSelectedDay}
        selectedDate={selectedDay}
      />
    </Stack>
  );
};
