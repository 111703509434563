import { SelectAllMembersQuery } from "@ses-mams/react-utils";
import { ChannelFormSchema } from "@ses-mams/validation";
import { Control, useFieldArray } from "react-hook-form";
import { EditMemberList } from "~/components/common/editMemberList";
import { useSelectedUnit } from "~/context/unit/SelectedUnitContextProvider";

type EditChannelMembersListProps = {
  control: Control<ChannelFormSchema>;
  selectAllMembersQuery?: SelectAllMembersQuery;
  setSelectAllMembersQuery: React.Dispatch<
    React.SetStateAction<SelectAllMembersQuery | undefined>
  >;
};

export const EditChannelMembersList = ({
  control,
  selectAllMembersQuery,
  setSelectAllMembersQuery,
}: EditChannelMembersListProps) => {
  const { fields, replace } = useFieldArray({
    control,
    name: "members",
    keyName: "fieldId",
  });

  const { selectedUnit } = useSelectedUnit();

  return (
    <EditMemberList
      selectedMembers={fields}
      onChangeSelectedMembers={replace}
      unitId={selectedUnit?.id as string}
      selectAllMembersQuery={selectAllMembersQuery}
      setSelectAllMembersQuery={setSelectAllMembersQuery}
    />
  );
};
