import { Link } from "@mui/material";
import { useMemo } from "react";
import {
  Link as RouterLink,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { OrgUnitApprovers } from "./OrgUnitApprovers";
import { MemberSummary } from "@ses-mams/api-contract";

type MemberUnitListItemProps = {
  id: string;
  name: string;
  rank?: string | null;
  approvers: MemberSummary[];
};

export const MemberUnitListItem = ({
  id,
  name,
  rank,
  approvers,
}: MemberUnitListItemProps) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get("groupId");
  const hasGroupId = Boolean(groupId);

  const targetUnitPath = `/units/${id}/members`;
  const isTargetEqualToCurrentPath = pathname === targetUnitPath && !hasGroupId;

  // Do not use a link if the target URL is the same as the current page
  // as react-router does not support client-side navigation to the same
  // route without either fully reloading the document or using hacks
  const unitNameElement = useMemo(
    () =>
      isTargetEqualToCurrentPath ? (
        <Text size="large" weight="medium">
          {name}
        </Text>
      ) : (
        <Link
          component={RouterLink}
          underline="none"
          to={`/units/${id}/members`}
        >
          <Stack direction="row" justify="space-between">
            <Text tone="action" size="large" weight="medium">
              {name}
            </Text>
          </Stack>
        </Link>
      ),
    [targetUnitPath, isTargetEqualToCurrentPath]
  );

  return (
    <>
      <Stack gap="small" paddingY="xsmall">
        {unitNameElement}
        {rank && (
          <Text size="large" weight="regular">
            {rank}
          </Text>
        )}
      </Stack>
      <OrgUnitApprovers approvers={approvers} />
    </>
  );
};
