import { EyeFilledIcon, PencilFilledIcon } from "~/components/ui/icon";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

export const OtherMemberAvailabilityLayout = ({
  children,
  isEdit,
}: {
  children: React.ReactNode;
  isEdit?: boolean;
}) => {
  const Icon = isEdit ? PencilFilledIcon : EyeFilledIcon;

  return (
    <Stack gap="medium">
      <Stack
        direction="row"
        gap="small"
        align="center"
        background="infoMuted"
        padding="small"
        sx={{ marginX: -16, marginTop: -16 }}
      >
        <Icon tone="action" />
        <Text tone="action" weight="semi">
          You are {isEdit ? "editing" : "viewing"} another member
        </Text>
      </Stack>
      {children}
    </Stack>
  );
};
