import { useCallback, useState } from "react";
import { Member } from "@ses-mams/api-contract";
import { SelectAllMembersQuery } from "@ses-mams/react-utils";
import {
  SelectMembersDrawer,
  SelectedMembersList,
} from "~/components/common/selectMembers";
import { Box } from "~/components/ui/box";
import { Spinner } from "~/components/ui/spinner";
import { tsr } from "~/utils/client";
import { RequiredAvailabilityWindow } from "~/components/common/searchMembersList";
import { EditListSection } from "~/components/common/editListSection";

type EditMemberListProps = {
  selectedMembers: Array<Member>;
  onChangeSelectedMembers: (members: Array<Member>) => void;
  isLoadingMembers?: boolean;
  unitId?: string;
  requiredAvailabilityWindow?: RequiredAvailabilityWindow;
  currentAvailabilityStatusTime?: string;
  showAvailabilityStatus?: boolean;
  selectAllMembersQuery?: SelectAllMembersQuery;
  setSelectAllMembersQuery?: React.Dispatch<
    React.SetStateAction<SelectAllMembersQuery | undefined>
  >;
  renderItem?: (member: Member) => React.ReactElement;
  activationId?: string;
  outOfAreaActivationId?: string;
  activityId?: string;
  activityScheduleId?: string;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
};

export const EditMemberList = ({
  selectedMembers,
  onChangeSelectedMembers,
  isLoadingMembers,
  unitId,
  requiredAvailabilityWindow,
  currentAvailabilityStatusTime,
  showAvailabilityStatus,
  selectAllMembersQuery,
  setSelectAllMembersQuery,
  renderItem,
  activationId,
  outOfAreaActivationId,
  activityId,
  activityScheduleId,
  hasNextPage,
  fetchNextPage,
}: EditMemberListProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onDone = useCallback(
    (members: Array<Member>, selectAllQuery?: SelectAllMembersQuery) => {
      onChangeSelectedMembers(members);
      setSelectAllMembersQuery?.(selectAllQuery);

      setIsDrawerOpen(false);
    },
    [onChangeSelectedMembers]
  );

  const {
    data: unitData,
    isLoading: _isLoading,
    isRefetching,
  } = tsr.units.get.useQuery({
    queryKey: ["units", unitId],
    queryData: {
      params: {
        id: unitId ?? "",
      },
    },
    enabled: !!unitId,
  });

  const isLoading = _isLoading || isRefetching;

  if (isLoading) {
    return (
      <Box display="flex" justify="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <EditListSection
        title="Members"
        isLoading={isLoadingMembers}
        onButtonClick={() => setIsDrawerOpen(true)}
        hasItems={!!(selectedMembers.length || selectAllMembersQuery)}
      >
        <SelectedMembersList
          members={selectedMembers}
          showAvailabilityStatus={showAvailabilityStatus}
          selectAllMembersQuery={selectAllMembersQuery}
          renderItem={renderItem}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
        />
      </EditListSection>

      <SelectMembersDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onDone={onDone}
        members={selectedMembers}
        unit={unitData?.body}
        requiredAvailabilityWindow={requiredAvailabilityWindow}
        currentAvailabilityStatusTime={currentAvailabilityStatusTime}
        showAvailabilityStatus={showAvailabilityStatus}
        activationId={activationId}
        outOfAreaActivationId={outOfAreaActivationId}
        activityId={activityId}
        activityScheduleId={activityScheduleId}
      />
    </>
  );
};
