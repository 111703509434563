import {
  useActivationMemberListSections,
  useTransformPagedDataToItems,
} from "@ses-mams/react-utils";

import { tsr } from "~/utils/client";
import { LARGE_PAGE_SIZE, getNextLargePageParam } from "~/utils/pagination";

export const useActivationMembersData = (activationId: string) => {
  const { isLoading, isRefetching, data, refetch, hasNextPage, fetchNextPage } =
    tsr.activations.getActivationMembers.useInfiniteQuery({
      // Adding placeholder keys ("", []) for search query keys once search is re-introduced
      queryKey: ["activations", activationId, "members", "", []],
      queryData: ({ pageParam }) => ({
        query: {
          skip: pageParam?.skip ?? 0,
          take: pageParam?.take ?? LARGE_PAGE_SIZE,
        },
        params: {
          activationId,
        },
      }),
      initialPageParam: { skip: 0, take: LARGE_PAGE_SIZE },
      getNextPageParam: getNextLargePageParam,
    });

  const items = useTransformPagedDataToItems(data);

  const sections = useActivationMemberListSections(items);

  return {
    sections,
    isLoading,
    isRefetching,
    refetch,
    data: items,
    hasNextPage,
    fetchNextPage,
  };
};
