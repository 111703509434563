import { MemberSummary } from "@ses-mams/api-contract";
import { formatMemberFullName } from "@ses-mams/react-utils";
import { useState } from "react";
import { Button } from "~/components/ui/button";
import { MemberDetailsDrawer } from "~/components/common/memberDetails";
import { LabelValue } from "./LabelValue";
import { Stack } from "~/components/ui/stack";

type InitiatorLabelValueDrawerProps = {
  initiator: MemberSummary;
};

export const InitiatorLabelValueDrawer = ({
  initiator,
}: InitiatorLabelValueDrawerProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <LabelValue
        label="Initiator"
        value={
          <Stack align="start">
            <Button variant="link" onClick={() => setIsDrawerOpen(true)}>
              {formatMemberFullName(initiator)}
            </Button>
          </Stack>
        }
      />

      <MemberDetailsDrawer
        open={isDrawerOpen}
        memberId={initiator.id}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
};
