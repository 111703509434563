import { ButtonBase } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { TextField } from "~/components/ui/textField";
import { Stack } from "~/components/ui/stack";
import { useAuth } from "~/context/auth";
import { tsr } from "~/utils/client";
import type { AvailabilityForm } from "./SetAvailabilityDrawer";
import { Text } from "~/components/ui/text";

type ConditionalReasonFieldProps = {
  control: Control<AvailabilityForm>;
};

export const ConditionalReasonField = ({
  control,
}: ConditionalReasonFieldProps) => {
  const { member } = useAuth();

  const { data } = tsr.members.getConditionalReasonSuggestions.useQuery({
    queryKey: ["/members/:memberId/availability/reasons", member?.id],
    queryData: {
      params: {
        memberId: member?.id as string,
      },
    },
  });

  return (
    <Controller
      name="conditionalReason"
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState }) => (
        <Stack>
          <TextField
            label="Conditional reason"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            errorMessage={fieldState.error?.message}
          />

          {data?.body?.length ? (
            <Stack paddingTop="large" gap="small" align="start">
              {data.body.map(suggestion => (
                <ButtonBase
                  onClick={() => {
                    onChange(suggestion);
                    onBlur();
                  }}
                >
                  <Text tone="action" weight="medium" align="start">
                    {suggestion}
                  </Text>
                </ButtonBase>
              ))}
            </Stack>
          ) : null}
        </Stack>
      )}
    />
  );
};
