import { Theme } from "@mui/material";

export const popoverStyles = (theme: Theme) => {
  const { tokens } = theme;

  return {
    padding: tokens.spacing.large,
    borderStyle: "solid",
    borderWidth: tokens.border.width.medium,
    borderColor: tokens.border.color.standard,
    borderRadius: tokens.border.radius.large,
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 3px 11px -3px rgba(0, 0, 0, 0.14)",
  };
};
