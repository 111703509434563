import { Container, Divider } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";

import { Button } from "~/components/ui/button";
import { Heading } from "~/components/ui/heading";
import { Row, Stack } from "~/components/ui/stack";
import { Tab, Tabs } from "~/components/ui/tab";
import { useHasAnyRole } from "~/context/auth";
import {
  Popover,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
} from "~/components/ui/popover";
import { Text } from "~/components/ui/text";
import { Spinner } from "~/components/ui/spinner";
import { useSelectedUnit } from "~/context/unit/SelectedUnitContextProvider";
import { tsr } from "~/utils/client";
import { MuteNotificationsSwitch, RequestFilters } from "./shared";
import { OperationalList } from "./activations";
import { ActivityList } from "./activities";
import { OutOfAreaActivationList } from "./outOfAreaActivations";

const tabs = {
  OPERATIONAL: "operational",
  ACTIVITY: "activity",
  OOAA: "out-of-area",
};

export const RequestsPage = () => {
  // unitId will only be provided when coming from the Units page
  const unitId = useParams().unitId;
  const { isLoading: isLoadingUnitTitle, title } =
    useSelectedUnitBehaviour(unitId);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab") ?? tabs.OPERATIONAL;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const canCreateOperationalRequest = useHasAnyRole(["OperationalCoordinator"]);
  const canCreateActivity = useHasAnyRole(["ActivityCoordinator"]);
  const canCreateOoaActivation = useHasAnyRole([
    "OperationalCoordinator",
    "OOAACoordinator",
  ]);

  const canViewApprovalQueue =
    useHasAnyRole(["UnitApprover", "ClusterApprover", "ZoneApprover"]) &&
    selectedTab === tabs.OOAA;

  const { addHref, canCreate, timeLabel } = useMemo(() => {
    switch (selectedTab) {
      case tabs.OPERATIONAL:
        return {
          addHref: "/requests/activations/add",
          canCreate: canCreateOperationalRequest,
          timeLabel: "Activation time",
        };
      case tabs.ACTIVITY:
        return {
          addHref: "/requests/activities/add",
          canCreate: canCreateActivity,
          timeLabel: "Activity time",
        };
      case tabs.OOAA:
        return {
          addHref: "/requests/out-of-area-activations/add",
          canCreate: canCreateOoaActivation,
          timeLabel: "Activation time",
        };

      default:
        return {};
    }
  }, [
    selectedTab,
    canCreateOperationalRequest,
    canCreateOoaActivation,
    canCreateActivity,
  ]);

  if (isLoadingUnitTitle) {
    return (
      <Stack align="center">
        <Spinner />
      </Stack>
    );
  }

  return (
    <Container maxWidth="md">
      <Stack gap="xlarge">
        <Row justify="space-between" gap="small">
          <Heading level="1">{title ?? "My Requests"}</Heading>

          {canViewApprovalQueue && (
            <Button variant="link" href="/out-of-area-activation-approvals">
              My Approval Queue
            </Button>
          )}
        </Row>

        <Row justify="space-between" wrap rowGap="medium">
          <Tabs
            value={selectedTab}
            onChange={(_, v) => setSearchParams({ tab: v })}
          >
            <Tab value={tabs.OPERATIONAL} label="Operational" />
            <Tab value={tabs.ACTIVITY} label="Activity" />
            {!unitId ? <Tab value={tabs.OOAA} label="OOAA" /> : null}
          </Tabs>
          <Row gap="medium" align="center">
            <MuteNotificationsSwitch />
            <Divider orientation="vertical" />

            {canCreate && (
              <>
                {selectedTab === tabs.ACTIVITY ? (
                  <PopoverRoot
                    open={isPopoverOpen}
                    onOpenChange={setIsPopoverOpen}
                  >
                    <PopoverTrigger>
                      <Button variant="primary">Create</Button>
                    </PopoverTrigger>
                    <Popover>
                      <PopoverContent>
                        <Stack gap="xlarge">
                          <Button variant="tertiary" href={addHref}>
                            Create Activity
                          </Button>
                          <Stack gap="small">
                            <Text>
                              A Recurring Activity allows myAvailability to
                              create activities automatically at a defined time
                              interval.
                            </Text>
                            <Button
                              variant="tertiary"
                              href="/requests/activity-schedules/add"
                            >
                              Create Recurring Activity
                            </Button>
                          </Stack>
                        </Stack>
                      </PopoverContent>
                    </Popover>
                  </PopoverRoot>
                ) : (
                  <Button variant="primary" href={addHref}>
                    Create
                  </Button>
                )}
              </>
            )}
            <RequestFilters
              isFilteringOOAARequests={selectedTab === tabs.OOAA}
              isFilteringUnitRequests={Boolean(unitId)}
              timeLabel={timeLabel ?? "Activation time"}
            />
          </Row>
        </Row>
        {selectedTab === tabs.OPERATIONAL ? (
          <OperationalList unitId={unitId} />
        ) : selectedTab === tabs.ACTIVITY ? (
          <ActivityList unitId={unitId} />
        ) : (
          <OutOfAreaActivationList />
        )}
      </Stack>
    </Container>
  );
};

const useSelectedUnitBehaviour = (unitId = "") => {
  const hasUnitId = Boolean(unitId);

  const { selectedUnit } = useSelectedUnit();
  const navigate = useNavigate();
  const hasOcRole = useHasAnyRole(["OperationalCoordinator"]);

  const { data, isLoading } = tsr.units.get.useQuery({
    queryKey: ["units", unitId],
    queryData: {
      params: {
        id: unitId,
      },
    },
    enabled: hasUnitId,
  });

  useEffect(() => {
    if (!hasUnitId || !selectedUnit || !hasOcRole) {
      return;
    }

    // Keep the visible unit requests in sync with the globally selected unit
    navigate(`/units/${selectedUnit.id}/requests`);
  }, [hasUnitId, selectedUnit, hasOcRole]);

  return {
    isLoading: hasUnitId && isLoading,
    title: data?.body?.name ? `${data?.body?.name} Requests` : undefined,
  };
};
