import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import { Security, useOktaAuth } from "@okta/okta-react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { AuthResponse, Member } from "@ses-mams/api-contract";

import { tsr } from "~/utils/client";

import { DEFAULT_AUTH_ROUTE, oktaAuth } from "./constants";
import {
  deleteLocalTokens,
  getLocalAccessToken,
  getLocalRefreshToken,
  setLocalTokens,
} from "./localTokenUtils";
import { useFirebaseNotifications } from "./useFirebaseNotifications";
import { captureException } from "@sentry/react";

type AuthContextType = {
  login: (
    authResponse: AuthResponse,
    shouldRegisterNotificationToken?: boolean
  ) => Promise<void>;
  logout: () => void;
  refresh: () => Promise<boolean>;
  member?: Member;
  isLoading?: boolean;
};

const AuthContext = createContext<AuthContextType>({
  logout: () => null,
  login: async () => undefined,
  refresh: async () => false,
});

function AuthContextProvider(props: React.PropsWithChildren) {
  const [isLoading, setIsLoading] = useState(true);
  const [member, setMember] = useState<Member>();

  const isRefreshingRef = useRef(false);

  const navigate = useNavigate();

  const { registerNotificationToken, deleteNotificationToken } =
    useFirebaseNotifications();

  const restoreOriginalUri = useCallback(
    (_oktaAuth: OktaAuth, originalUri: string) => {
      navigate(
        toRelativeUrl(originalUri || DEFAULT_AUTH_ROUTE, window.location.origin)
      );
    },
    []
  );

  const logout = useCallback(async () => {
    const accessToken = getLocalAccessToken();
    const refreshToken = getLocalRefreshToken();

    const deletedNotificationToken = await deleteNotificationToken();

    try {
      await tsr.auth.logout.mutate({
        body: {
          blacklistTokens: [refreshToken, accessToken].filter(
            e => !!e
          ) as Array<string>,
          deviceToken: deletedNotificationToken,
        },
      });
      await oktaAuth.closeSession();
    } catch (error) {
      captureException(error);
    } finally {
      // ensure user is logged out and local tokens are deleted to prevent frontend access issues
      deleteLocalTokens();
      setMember(undefined);
      navigate("/");
    }
  }, []);

  const login = useCallback(
    async (
      authResponse: AuthResponse,
      shouldRegisterNotificationToken = false
    ) => {
      const { accessToken, refreshToken, member } = authResponse;

      setLocalTokens(accessToken, refreshToken);

      if (shouldRegisterNotificationToken) {
        await registerNotificationToken();
      }

      setMember(member);
    },
    []
  );

  const refresh = useCallback(async () => {
    try {
      const refreshToken = getLocalRefreshToken();

      if (!refreshToken) {
        return false;
      }

      if (isRefreshingRef.current) {
        return false;
      }

      isRefreshingRef.current = true;

      const response = await tsr.auth.refresh.mutate({
        body: { refreshToken },
      });

      if (response.status === 200) {
        await login(response.body);

        return true;
      }

      throw REFRESH_FAILED_ERROR();
      // eslint-disable-next-line no-useless-catch
    } catch (error) {
      throw error;
    } finally {
      isRefreshingRef.current = false;
    }
  }, []);

  useEffect(() => {
    // React.StrictMode: Ensure a double render doesn't effect this logic
    const controller = new AbortController();

    (async () => {
      try {
        const accessToken = getLocalAccessToken();
        if (!accessToken) {
          return;
        }

        const result = await tsr.members.me.query({
          fetchOptions: {
            signal: controller.signal,
          },
        });

        switch (result.status) {
          case 200: {
            // Valid tokens
            setMember(result.body);
            break;
          }

          case 401: {
            // Access Token has expired, therefore attempt to refresh.
            await refresh();
            break;
          }

          default:
            break;
        }
      } catch {
        // Empty catch to avoid warning.
        // Any error here will be treated as the user being logged out.
      } finally {
        setIsLoading(false);
      }
    })();

    return () => {
      controller.abort();
    };
  }, []);

  const value = useMemo(
    () => ({
      isLoading,
      member,
      logout,
      login,
      refresh,
    }),
    [isLoading, member, logout, login, refresh]
  );

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AuthContext.Provider value={value}>
        {props.children}
      </AuthContext.Provider>
    </Security>
  );
}

function useAuth() {
  const ctx = useContext(AuthContext);
  const { oktaAuth } = useOktaAuth();

  const memoCtx = useMemo(
    () => ({
      ...ctx,
      oktaAuth,
    }),
    [ctx, oktaAuth]
  );

  if (!ctx) {
    throw new Error("Must be called within <AuthContextProvider />");
  }

  return memoCtx;
}

export { useAuth, AuthContextProvider };

const REFRESH_FAILED_ERROR = () => new Error("refresh_token_failed");
