import { Stack, StackProps } from "~/components/ui/stack";

type CardProps = StackProps;

export const Card = ({ border = "neutral", ...props }: CardProps) => {
  return (
    <Stack
      borderRadius="medium"
      border={border}
      borderWidth="medium"
      {...props}
    />
  );
};
