import { Capability } from "@ses-mams/api-contract";

import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

import { CapabilityBadge } from "./CapabilityBadge";

type CapabilityListItemProps = {
  startAdornment?: React.ReactNode;
  capability: Capability;
};

export const CapabilityListItem = ({
  capability,
  startAdornment,
}: CapabilityListItemProps) => {
  return (
    <Stack
      gap="small"
      direction="row"
      align="center"
      justify="space-between"
      paddingY="medium"
      sx={{ width: "100%" }}
    >
      <Stack
        flex={1}
        direction="row"
        gap="medium"
        align="center"
        overflow="hidden"
      >
        {startAdornment}

        <Stack flex={1} overflow="hidden">
          <Text weight="medium" size="large" noWrap align="start">
            {capability.name}
          </Text>
        </Stack>
      </Stack>

      <CapabilityBadge capability={capability} />
    </Stack>
  );
};
