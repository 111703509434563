import { Stack } from "~/components/ui/stack";
import { Divider } from "~/components/ui/divider";
import { Button } from "~/components/ui/button";
import { Controller, useFormContext } from "react-hook-form";
import {
  ACTIVATION_TYPE_OPTIONS,
  ActivationFormSchema,
} from "@ses-mams/validation";
import { EventForm } from "~/components/common/eventForm";
import { SelectField, SelectItem } from "~/components/ui/selectField";
import { formatActivationType } from "@ses-mams/react-utils";
import { UnitField } from "../../shared";
import { Heading } from "~/components/ui/heading";

type Props = {
  isEditing: boolean;
  onSubmit: (formData: ActivationFormSchema) => void;
  children?: React.ReactNode;
};

export const ActivationForm = ({ isEditing, onSubmit, children }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext<ActivationFormSchema>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="xlarge">
        <Stack direction="row" justify="space-between" paddingBottom="xlarge">
          <Heading level="1">{isEditing ? "Edit" : "New"} Request</Heading>
          <Button type="submit" busy={isSubmitting}>
            Done
          </Button>
        </Stack>
        <Divider />
        <Stack direction="row" gap="medium">
          <UnitField label="Activate for Unit" disabled={isEditing} />
          <Controller
            name="type"
            control={control}
            render={({ field: { value, onChange }, fieldState }) => (
              <SelectField
                disabled={isEditing}
                label="Type"
                placeholder="Select type"
                value={value}
                errorMessage={fieldState.error?.message}
              >
                {ACTIVATION_TYPE_OPTIONS.map(type => (
                  <SelectItem
                    key={type}
                    value={type}
                    onClick={() => onChange(type)}
                  >
                    {formatActivationType(type)}
                  </SelectItem>
                ))}
              </SelectField>
            )}
          />
        </Stack>
        <EventForm type="Activation" />
        {children}
      </Stack>
    </form>
  );
};
