import Link from "@mui/material/Link";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
} from "react-router-dom";
import React from "react";
import { Stack } from "~/components/ui/stack";
import {
  CalendarFilledIcon,
  GridFilledIcon,
  IconProps,
  AlertTriangleFilledIcon,
  MessageTypingFilledIcon,
} from "~/components/ui/icon";
import { Text } from "~/components/ui/text";

type NavLinkProps = RouterLinkProps & {
  icon?: React.ComponentType<IconProps>;
  noLink?: boolean;
};

export const NavLink = ({ icon: Icon, children, ...props }: NavLinkProps) => {
  const { pathname } = useLocation();
  const to = (props.to || "").toString();
  const toWithoutQueryString = to.split("?")[0];
  const isActive = pathname.startsWith(toWithoutQueryString);

  const tone = isActive ? "action" : "muted";

  const label = (
    <Stack direction="row" gap="small" align="center">
      {Icon && <Icon tone={tone} size="xxsmall" />}

      <Text tone={tone} weight="medium">
        {children}
      </Text>
    </Stack>
  );

  return props.noLink ? (
    label
  ) : (
    <Link {...props} component={RouterLink} underline="none">
      {label}
    </Link>
  );
};

export const useNavLinks = () => {
  return [
    <NavLink key="units" icon={GridFilledIcon} to="/units">
      Unit
    </NavLink>,
    <NavLink key="availability" icon={CalendarFilledIcon} to="/availability">
      Availability
    </NavLink>,
    <NavLink
      key="requests"
      icon={AlertTriangleFilledIcon}
      to="/requests?tab=operational"
    >
      Requests
    </NavLink>,
    <NavLink key="messages" icon={MessageTypingFilledIcon} to="/messages">
      Messages
    </NavLink>,
  ];
};
