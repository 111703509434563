import { useCallback, useMemo } from "react";
import { Toaster, toast } from "sonner";

import { Alert, AlertProps } from "~/components/ui/alert";
import { ToastContext, ToastOptions } from "./context";

export type ToastProps = Omit<AlertProps, "onClose"> &
  (
    | {
        duration?: number;
        persistent: never;
      }
    | {
        duration: never;
        persistent?: boolean;
      }
  );

export const ToastContextProvider = ({ children }: React.PropsWithChildren) => {
  const addToast = useCallback(
    ({ duration = 6000, id, persistent = false, ...rest }: ToastOptions) => {
      const toastId = toast.custom(
        t => (
          <Alert
            {...rest}
            elevated
            onClose={() => toast.dismiss(t)}
            sx={{
              // This variable comes from sonner
              width: "var(--width)",
            }}
          />
        ),
        { duration: persistent ? Infinity : duration, dismissible: false }
      );

      return toastId;
    },
    []
  );

  const ctx = useMemo(() => ({ addToast, removeToast: toast.dismiss }), []);

  return (
    <ToastContext.Provider value={ctx}>
      {children}
      <Toaster
        expand
        pauseWhenPageIsHidden
        position="top-center"
        visibleToasts={4}
      />
    </ToastContext.Provider>
  );
};
