import { ActivationRequestMember, Member } from "@ses-mams/api-contract";
import emptyListImageUrl from "~/assets/emptyList.png";
import { useCallback } from "react";
import { MemberListItem } from "~/components/common/searchMembersList";
import {
  InfiniteSectionList,
  RenderSectionHeaderParams,
} from "~/components/ui/list";
import { useActivationMembersData } from "./useActivationMembersData";
import { Box } from "~/components/ui/box";
import { EmptyState } from "~/components/common/emptyState";

type ActivationMemberListProps = Pick<
  ReturnType<typeof useActivationMembersData>,
  "sections" | "isLoading"
> & {
  renderItem?: (member: ActivationRequestMember) => React.ReactElement;
  renderSectionHeader: (
    params: RenderSectionHeaderParams<ActivationRequestMember>
  ) => React.ReactElement;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
};

export const ActivationMemberList = ({
  sections,
  isLoading,
  renderItem,
  renderSectionHeader,
  hasNextPage,
  fetchNextPage,
}: ActivationMemberListProps) => {
  const handleRenderItem = useCallback(
    (item: ActivationRequestMember) => {
      if (renderItem) {
        return renderItem(item);
      }

      return <MemberListItem member={item.member as Member} />;
    },
    [renderItem]
  );

  return (
    <InfiniteSectionList
      sections={sections}
      keyExtractor={item => item.id}
      renderItem={handleRenderItem}
      renderSectionHeader={renderSectionHeader}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      listEmptyElement={
        isLoading ? null : (
          <Box display="flex" justify="center" sx={{ height: "100%" }}>
            <EmptyState
              image={<img src={emptyListImageUrl} />}
              title="No members"
              description="There are no members."
            />
          </Box>
        )
      }
    />
  );
};
