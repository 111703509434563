import { SvgIcon, Theme, useTheme } from "@mui/material";
import { ForegroundTones } from "@ses-mams/react-utils";

export type IconProps = {
  size?: keyof Theme["tokens"]["sizing"];
  tone?: ForegroundTones;
};

export function createFilledIcon(children: React.ReactNode, name: string) {
  const Icon = ({ size = "xsmall", tone = "primary" }: IconProps) => {
    const {
      tokens: {
        sizing,
        colors: { foreground },
      },
    } = useTheme();
    const resolvedSize = sizing[size];

    return (
      <SvgIcon sx={{ height: resolvedSize, width: resolvedSize }}>
        <svg
          fill={foreground[tone]}
          fillRule="evenodd"
          clipRule="evenodd"
          viewBox="0 0 24 24"
        >
          {children}
        </svg>
      </SvgIcon>
    );
  };

  Icon.displayName = name;

  return Icon;
}

export function createOutlineIcon(children: React.ReactNode, name: string) {
  const Icon = ({ size = "xsmall", tone = "primary" }: IconProps) => {
    const {
      tokens: {
        sizing,
        colors: { foreground },
      },
    } = useTheme();
    const resolvedSize = sizing[size];

    return (
      <SvgIcon sx={{ height: resolvedSize, width: resolvedSize }}>
        <svg
          fill="transparent"
          stroke={foreground[tone]}
          strokeWidth={2}
          strokeLinejoin="round"
          strokeLinecap="round"
          viewBox="0 0 24 24"
        >
          {children}
        </svg>
      </SvgIcon>
    );
  };

  Icon.displayName = name;

  return Icon;
}
