import { Container } from "@mui/material";
import {
  useArrayState,
  useCanAmendOutOfAreaActivationRequestResponse,
  useSelectableOutOfAreaRoles,
} from "@ses-mams/react-utils";
import { useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { Box } from "~/components/ui/box";
import { Spinner } from "~/components/ui/spinner";
import { SelectOutOfAreaRoleIdsList } from "~/components/common/selectOutOfAreaRolesList";
import { useOutOfAreaActivationRolesQuery } from "~/hooks/useOutOfAreaActivationRolesQuery";
import { Stack } from "~/components/ui/stack";
import { Heading } from "~/components/ui/heading";
import { Button } from "~/components/ui/button";
import { BackButton } from "~/components/common/navigation";
import { Protected } from "~/components/common/layout";
import { useAuth } from "~/context/auth";
import {
  useOutOfAreaActivationRequestDetails,
  useOutOfAreaActivationRequestHistoryDetails,
} from "../../hooks";

export const AmendOOAARequestRolesPage = () => {
  const activationId = useParams().activationId as string;
  const requestId = useParams().requestId as string;

  const [selectedRoleIds, { append, remove, setValue }] = useArrayState<string>(
    []
  );

  const { isLoading: isLoadingRequestDetails, data: requestDetailsData } =
    useOutOfAreaActivationRequestDetails(activationId);

  const { isLoading: isLoadingHistoryDetails, data: historyData } =
    useOutOfAreaActivationRequestHistoryDetails(requestId);

  const { isLoading: isLoadingRoles, data: roleData } =
    useOutOfAreaActivationRolesQuery();

  const roles = roleData?.body;

  const activation = requestDetailsData?.body?.activation;
  const deployments = activation?.deployments;

  const request = historyData?.body?.request;
  const existingRequestRoles = request?.roles;

  const { member } = useAuth();
  const canAmend = useCanAmendOutOfAreaActivationRequestResponse({
    member,
    activation,
    request,
  });

  useEffect(() => {
    if (!existingRequestRoles) {
      return;
    }

    setValue(existingRequestRoles.map(({ id }) => id));
  }, [existingRequestRoles]);

  const selectableRoles = useSelectableOutOfAreaRoles(roles, deployments);

  const href = useMemo(
    () =>
      `/requests/out-of-area-activations/${activationId}/request/${requestId}/amend/availability?roleIds=${selectedRoleIds.join(
        ","
      )}`,
    [selectedRoleIds]
  );

  const isLoading =
    isLoadingRequestDetails || isLoadingRoles || isLoadingHistoryDetails;

  return (
    <Protected
      shouldRedirect={!isLoading && !canAmend}
      redirectPath={`/requests/out-of-area-activations/${activationId}/history?requestId=${requestId}`}
    >
      {isLoading ? (
        <Box display="flex" grow={1} justify="center">
          <Spinner />
        </Box>
      ) : (
        <Container maxWidth="sm">
          <Stack gap="xlarge">
            <BackButton />

            <Stack direction="row" align="center" justify="space-between">
              <Heading level="2">Select a role</Heading>

              <Button href={href}>Done</Button>
            </Stack>
            <SelectOutOfAreaRoleIdsList
              selectedRoleIds={selectedRoleIds}
              appendRoleId={append}
              removeRoleId={remove}
              data={selectableRoles}
            />
          </Stack>
        </Container>
      )}
    </Protected>
  );
};
