export type LatitudeLongitude = {
  latitude?: number | null;
  longitude?: number | null;
};

export const toLatLng = ({
  latitude: lat,
  longitude: lng,
}: LatitudeLongitude) =>
  lat && lng
    ? {
        lat,
        lng,
      }
    : undefined;
