import { Row, Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { useMutateActivationAvailabilityStatus } from "../../hooks";
import { useCallback, useState } from "react";
import { ActivationRequest, AvailabilityStatus } from "@ses-mams/api-contract";
import { Spinner } from "~/components/ui/spinner";
import { MemberConditionalReason } from "~/components/common/memberStatus";
import { PopoverRoot, PopoverTrigger } from "~/components/ui/popover";
import {
  AvailabilityStatusButton,
  AvailabilityStatusResponseButtons,
  ChangeResponsePopover,
} from "../../shared";
import { ActivationRequestResponse } from "./ActivationRequestResponse";

export const ActivationRequestResponsesSection = ({
  activationRequest,
}: {
  activationRequest: ActivationRequest;
}) => {
  const { mutateAvailabilityStatus, isLoading } =
    useMutateActivationAvailabilityStatus();
  const [popoverOpen, setPopoverOpen] = useState(false);

  const requestId = activationRequest.id || "";

  const handleStatusSelected = useCallback(
    async (status: AvailabilityStatus, conditionalReason?: string) => {
      await mutateAvailabilityStatus({
        requestId,
        status,
        conditionalReason,
        force: false,
      });

      setPopoverOpen(false);
    },
    [mutateAvailabilityStatus, requestId]
  );

  if (!activationRequest.status || activationRequest.status === "Deactivated") {
    return null;
  }

  const statusButtons = (
    <AvailabilityStatusResponseButtons
      onStatusSelected={handleStatusSelected}
    />
  );

  return (
    <>
      {["AvailabilityRequested", "AvailabilitySubmitted"].includes(
        activationRequest.status
      ) ? (
        isLoading ? (
          <Row align="center" justify="center">
            <Spinner />
          </Row>
        ) : (
          <Row justify={"space-between"} align={"center"}>
            {activationRequest.availabilityStatus ? (
              <PopoverRoot open={popoverOpen} onOpenChange={setPopoverOpen}>
                <Stack gap="xsmall">
                  <Text size="large" weight="medium">
                    Your Reply
                  </Text>
                  <MemberConditionalReason
                    conditionalReason={activationRequest.conditionalReason}
                  />
                </Stack>
                <PopoverTrigger>
                  <AvailabilityStatusButton
                    status={activationRequest?.availabilityStatus}
                    isSelected
                  />
                </PopoverTrigger>
                <ChangeResponsePopover>{statusButtons}</ChangeResponsePopover>
              </PopoverRoot>
            ) : (
              <Stack
                direction={{ xs: "column", md: "row" }}
                gap="small"
                sx={{ width: "100%" }}
              >
                {statusButtons}
              </Stack>
            )}
          </Row>
        )
      ) : (
        <ActivationRequestResponse activationRequest={activationRequest} />
      )}
    </>
  );
};
