import { AuditLog } from "@ses-mams/api-contract";
import { formatDateTime } from "@ses-mams/react-utils";

import { Stack } from "~/components/ui/stack";
import { DataItem } from "~/components/common/dataItem";

type Props = {
  auditLog: AuditLog;
};

export const AuditDetails = ({ auditLog }: Props) => (
  <Stack gap="large">
    <DataItem title="Action" value={auditLog.action} />
    <DataItem
      title="Details of the change"
      value={auditLog.details?.split(/\n/)}
    />
    <DataItem
      title="Date"
      value={
        auditLog.createdAt ? formatDateTime(new Date(auditLog.createdAt)) : null
      }
    />
    <DataItem title="Member ID" value={auditLog.member?.id} />
    <DataItem title="User" value={formatUserName(auditLog.member)} />
  </Stack>
);

export const formatUserName = (member: AuditLog["member"] | null): string =>
  member ? `${member.firstName ?? ""} ${member.lastName ?? ""}` : "SYSTEM";
