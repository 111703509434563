import { captureException } from "@sentry/react";
import { useUpdateActivationMembersQueryCache } from "@ses-mams/react-utils";
import { useCallback } from "react";
import { useConfirmationDialog } from "~/components/ui/confirmationDialog";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";

type UseDeactivateMembersParams = {
  activationId: string;
  confirmationText: string;
  onSuccess?: () => void;
};

export const useDeactivateMembers = ({
  activationId,
  confirmationText,
  onSuccess,
}: UseDeactivateMembersParams) => {
  const { mutateAsync, isPending } = tsr.activations.deactivate.useMutation();

  const updateQueryCache = useUpdateActivationMembersQueryCache(activationId);

  const { showConfirmationDialog } = useConfirmationDialog();
  const { addToast } = useToast();

  const deactivateMembers = useCallback(
    async (activationRequestIds: Array<string>) => {
      try {
        const result = await mutateAsync({
          params: {
            activationId,
          },
          body: {
            activationRequestIds,
          },
        });

        await updateQueryCache(result.body);

        onSuccess?.();
      } catch (error) {
        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    [activationId, onSuccess]
  );

  const handleConfirmDeactivateMembers = useCallback(
    (activationRequestIds: Array<string>) => {
      showConfirmationDialog({
        text: confirmationText,
        onConfirm: () => deactivateMembers(activationRequestIds),
      });
    },
    [confirmationText]
  );

  return {
    handleConfirmDeactivateMembers,
    isPending,
  };
};
