import { useEffect, useRef } from "react";
import { Virtuoso } from "react-virtuoso";
import { useSpring } from "@react-spring/web";
import { useGesture } from "@use-gesture/react";
import { useIndividualAvailabilityReport } from "@ses-mams/react-utils";
import { Spinner } from "~/components/ui/spinner";
import { Box } from "~/components/ui/box";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { useElementSize } from "~/hooks/useResizeObserver";
import { tsr } from "~/utils/client";
import { useAvailabilityReportContext } from "~/context/unit/AvailabilityReportContextProvider";
import { AvailabilityReportAccordion } from "../AvailabilityReportAccordion";
import { IndividualAvailabilityReportHeader } from "./IndividualAvailabilityReportHeader";
import { IndividualDayAvailabilityRow } from "./IndividualDayAvailabilityRow";
import { TimeMarker } from "./TimeMarker";

type IndividualAvailabilityReportProps = {
  title: string;
  groupId?: string;
};

export const IndividualAvailabilityReport = (
  props: IndividualAvailabilityReportProps
) => {
  return (
    <AvailabilityReportAccordion {...props}>
      <IndividualAvailabilityReportView {...props} />
    </AvailabilityReportAccordion>
  );
};

// Split into a separate component so that components and hooks are unmounted when the accordion is closed
const IndividualAvailabilityReportView = ({
  groupId,
  title,
}: IndividualAvailabilityReportProps) => {
  const markerRef = useRef<HTMLDivElement>(null);
  const { ref: containerRef, width: containerWidth } =
    useElementSize<HTMLDivElement>();

  const { endDate, startDate, unitId } = useAvailabilityReportContext();

  const { data, isLoading } = tsr.units.getIndividualAvailability.useQuery({
    queryKey: [
      "units",
      unitId,
      "availability",
      "individual",
      startDate,
      endDate,
      groupId,
    ],
    queryData: {
      params: {
        unitId,
      },
      query: {
        startDate,
        endDate,
        groupId,
      },
    },
  });

  const {
    markerHours,
    setMarkerHours,
    markerLabel,
    sortedItems,
    setSortedItems,
    getSortedItemsByAvailabilityAtHour,
  } = useIndividualAvailabilityReport(data?.body?.items);

  useEffect(() => {
    if (!containerWidth) {
      return;
    }
    const initialX = containerWidth * ((markerHours + 0.5) / 24);
    api.set(() => ({ x: initialX, y: 0 }));
  }, [containerWidth]);

  const [{ x, y }, api] = useSpring(() => ({ x: 0, y: 0 }));

  const bind = useGesture(
    {
      onDrag: ({ xy: [x, y], down }) => {
        const container = containerRef?.current;
        const marker = markerRef?.current;

        if (!container || !marker || !down) {
          return;
        }

        const containerRect = container.getBoundingClientRect();
        const markerRect = marker.getBoundingClientRect();

        const deltaX = x - containerRect.left;
        const deltaY = y - containerRect.top;

        const minX = 0;
        const minY = -markerRect.height;

        const maxX = containerRect.width;
        const maxY = containerRect.height - markerRect.height;

        const valX = clamp(deltaX, minX, maxX);
        const valY = clamp(deltaY, minY, maxY);

        const hour = Math.floor(
          clamp(valX, 1, maxX - 1) / (containerRect.width / 24)
        );
        setMarkerHours(hour);

        api.start({
          x: valX,
          y: valY,
        });
      },
      onDragEnd: () => {
        setSortedItems(prev =>
          getSortedItemsByAvailabilityAtHour(prev, markerHours)
        );
      },
    },
    {
      drag: {
        filterTaps: true,
      },
    }
  );

  if (isLoading) {
    return (
      <Box display="flex" justify="center" paddingY="xlarge" grow={1}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Stack
      gap="medium"
      paddingX="medium"
      paddingBottom="large"
      overflow="hidden"
      sx={{ width: "100%" }}
    >
      <IndividualAvailabilityReportHeader />

      {/* https://use-gesture.netlify.app/docs/extras/#touch-action */}
      <div {...bind()} style={{ touchAction: "none" }}>
        <Stack ref={containerRef} position="relative" gap="small">
          <Virtuoso
            useWindowScroll
            computeItemKey={index =>
              `individual-${title}-${sortedItems[index].member.id}`
            }
            itemContent={(_, item) => (
              <IndividualDayAvailabilityRow item={item} />
            )}
            data={sortedItems}
          />

          {sortedItems.length ? (
            <TimeMarker
              ref={markerRef}
              containerWidth={containerWidth}
              label={markerLabel}
              x={x}
              y={y}
            />
          ) : (
            <Text tone="secondary" align="center" weight="medium">
              No Members
            </Text>
          )}
        </Stack>
      </div>
    </Stack>
  );
};

const clamp = (value: number, min: number, max: number) =>
  Math.max(min, Math.min(value, max));
