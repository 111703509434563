import { FormControl, FormGroup } from "@mui/material";
import { FormLabel } from "~/components/ui/form";
import { Stack } from "~/components/ui//stack";
import { HelperText } from "~/components/ui/textField";
import { CheckboxFieldProps } from "./CheckboxField";
import { useCallback } from "react";
import { CheckboxGroupContext } from "./CheckboxGroupContext";

type ChildType = React.ReactElement<CheckboxFieldProps>;

type CheckboxGroupFieldProps<T> = {
  label: string;
  errorMessage?: string;
  disabled?: boolean;
  value?: Array<T>;
  onValueChange: (value: Array<T>) => void;
  children: ChildType | Array<ChildType>;
};

export const CheckboxGroupField = <T extends string | number>({
  label,
  errorMessage,
  disabled,
  value = [],
  onValueChange,
  children,
}: CheckboxGroupFieldProps<T>) => {
  const handleOnValueChange = useCallback(
    (v: Array<string | number>) => onValueChange(v as Array<T>),
    [onValueChange]
  );

  return (
    <CheckboxGroupContext.Provider
      value={{ disabled, value, onValueChange: handleOnValueChange }}
    >
      <FormControl>
        <Stack gap="small">
          <FormLabel>{label}</FormLabel>
          <FormGroup aria-disabled={disabled}>
            <Stack gap="medium">{children}</Stack>
          </FormGroup>
          {errorMessage && <HelperText role="alert">{errorMessage}</HelperText>}
        </Stack>
      </FormControl>
    </CheckboxGroupContext.Provider>
  );
};
