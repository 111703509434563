import { styled } from "@mui/material";
import { BackgroundTones } from "@ses-mams/react-utils";
import { forwardRef } from "react";
import { Button, ButtonProps } from "~/components/ui/button";

type ResponseButtonProps = Omit<ButtonProps, "variant"> & {
  background: BackgroundTones;
};

export const ResponseButton = forwardRef<
  HTMLButtonElement,
  ResponseButtonProps
>(({ background, children, ...props }, forwardedRef) => {
  return (
    <StyledButton {...props} ref={forwardedRef} background={background}>
      {children}
    </StyledButton>
  );
});

const StyledButton = styled(Button)<{ background: BackgroundTones }>(
  ({ background, theme }) => ({
    backgroundColor: theme.tokens.colors.background[background],
    padding: `${theme.tokens.spacing.small}px ${theme.tokens.spacing.xlarge}px`,
    "&:hover": {
      backgroundColor: theme.tokens.colors.background[background],
    },
  })
);
