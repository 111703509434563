import { OutOfAreaActivationDeployment } from "@ses-mams/api-contract";
import { formatDateTime } from "@ses-mams/react-utils";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

type DeploymentDetailsProps = {
  deployments?: Array<OutOfAreaActivationDeployment>;
};

export const DeploymentDetails = ({ deployments }: DeploymentDetailsProps) => {
  if (!deployments?.length) {
    return null;
  }

  return (
    <Stack gap="small">
      <Text weight="medium">Deployments:</Text>
      {deployments.map((deployment, index) => {
        return (
          <Text key={deployment.id} tone="secondary">
            {`${index + 1}. ${formatDateTime(
              new Date(deployment.start)
            )} - ${formatDateTime(new Date(deployment.end))}`}
          </Text>
        );
      })}
    </Stack>
  );
};
