import { Box } from "~/components/ui/box";
import { Text } from "~/components/ui/text";
import { useGridCellHeight } from "../../context/gridCellHeightContext";
import { ButtonBase } from "@mui/material";
import { Stack } from "~/components/ui/stack";
import { ForegroundTones } from "@ses-mams/react-utils";
import { ScheduleItem } from "@ses-mams/api-contract";

type ScheduleGuideProps = {
  onClick?: (id: string) => void;
  schedule: ScheduleItem[];
};

const nameAndLabel = (
  name: string,
  start: string,
  end: string,
  tone: ForegroundTones = "primary"
) => (
  <Stack sx={{ writingMode: "tb-rl", transform: "rotate(-180deg)" }}>
    <Text align="center" weight="medium" tone={tone}>
      {name}
    </Text>
    <Text align="center" weight="medium" tone={tone}>
      {start} - {end}
    </Text>
  </Stack>
);

export const ScheduleGuide = ({ schedule, onClick }: ScheduleGuideProps) => {
  const { scheduleCellHeight, hourCellHeight } = useGridCellHeight();
  return (
    <Stack sx={{ height: "100%", minWidth: 48 }} background={"tableGutter"}>
      <Box
        sx={{
          minHeight: hourCellHeight,
        }}
        borderBottomWidth="medium"
        border="tableCell"
      ></Box>
      {schedule.map(({ name, start, end, id }) => (
        <Box
          key={name}
          display="flex"
          align="center"
          justify="center"
          padding="xsmall"
          sx={{
            minHeight: scheduleCellHeight,
            height: "100%",
          }}
          borderBottomWidth="medium"
          border="tableCell"
        >
          {onClick ? (
            <ButtonBase onClick={() => onClick(id)} sx={{ height: "100%" }}>
              {nameAndLabel(name, start, end, "action")}
            </ButtonBase>
          ) : (
            nameAndLabel(name, start, end)
          )}
        </Box>
      ))}
    </Stack>
  );
};
