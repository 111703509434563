import { ContactGroup, Group } from "@ses-mams/api-contract";
import { useCallback, useState } from "react";
import { GroupListItem } from "~/components/common/group";
import { SelectUnitGroupsDrawer } from "~/components/common/selectUnitGroupsList";
import { ContactGroupListItem } from "~/components/common/contactGroup";
import { EditListSection } from "~/components/common/editListSection";

type EditGroupListProps = {
  selectedGroups: Array<Group>;
  selectedContactGroups: Array<ContactGroup>;
  isLoading?: boolean;
  onChangeSelectedGroups: (
    groups: Array<Group>,
    contactGroups: Array<ContactGroup>
  ) => void;
  unitId: string;
};

export const EditGroupList = ({
  selectedGroups,
  selectedContactGroups,
  isLoading,
  onChangeSelectedGroups,
  unitId,
}: EditGroupListProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onDone = useCallback(
    (groups: Array<Group>, contactGroups: Array<ContactGroup>) => {
      onChangeSelectedGroups(groups, contactGroups);
      setIsDrawerOpen(false);
    },
    [onChangeSelectedGroups]
  );

  return (
    <>
      <EditListSection
        title="Groups"
        isLoading={isLoading}
        onButtonClick={() => setIsDrawerOpen(true)}
      >
        {selectedGroups.map(group => (
          <GroupListItem key={group.id} group={group} />
        ))}
        {selectedContactGroups.map(contactGroup => (
          <ContactGroupListItem
            key={contactGroup.id}
            contactGroup={contactGroup}
          />
        ))}
      </EditListSection>

      <SelectUnitGroupsDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onDone={onDone}
        groups={selectedGroups}
        contactGroups={selectedContactGroups}
        unitId={unitId}
      />
    </>
  );
};
