import { useCallback, useState } from "react";
import { SelectAllMembersQuery } from "@ses-mams/react-utils";
import { ChannelFormSchema } from "@ses-mams/validation";
import { BackButton } from "~/components/common/navigation";
import { Stack } from "~/components/ui/stack";
import { TextField } from "~/components/ui/textField";
import { Text } from "~/components/ui/text";
import { Button } from "~/components/ui/button";
import { useChannelForm } from "../../hooks/useChannelForm";
import { EditChannelMembersList } from "./EditChannelMembersList";
import { EditChannelGroupsList } from "./EditChannelGroupsList";

type EditChannelFormProps = {
  busy?: boolean;
  initialChannel?: Partial<ChannelFormSchema>;
  onSubmit: (
    data: ChannelFormSchema,
    selectAllMembersQuery?: SelectAllMembersQuery
  ) => void;
};

export const EditChannelForm = ({
  busy,
  initialChannel,
  onSubmit,
}: EditChannelFormProps) => {
  const [selectAllMembersQuery, setSelectAllMembersQuery] =
    useState<SelectAllMembersQuery>();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useChannelForm({ initialChannel });

  const internalOnSubmit = useCallback(
    (data: ChannelFormSchema) => onSubmit(data, selectAllMembersQuery),
    [onSubmit, selectAllMembersQuery]
  );

  return (
    <Stack gap="xlarge" direction="column">
      <BackButton to="/messages" />

      <form onSubmit={handleSubmit(internalOnSubmit)}>
        <Stack gap="xlarge" direction="column">
          {!initialChannel && (
            <TextField
              {...register("name")}
              label="Channel name"
              placeholder="Enter channel name"
              errorMessage={errors.name?.message}
            />
          )}

          <EditChannelGroupsList control={control} />

          <EditChannelMembersList
            control={control}
            selectAllMembersQuery={selectAllMembersQuery}
            setSelectAllMembersQuery={setSelectAllMembersQuery}
          />

          {errors.members?.message && (
            <Text tone="critical">{errors.members.message}</Text>
          )}

          <Stack align="end">
            <Button type="submit" busy={busy}>
              Done
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};
