import { AvailabilityStatus, MemberStatus } from "@ses-mams/api-contract";
import { BackgroundTones, BorderTones } from "@ses-mams/react-utils";

const STATUS_TO_BACKGROUND_TONE_MAP = {
  ImmediatelyAvailable: "positive",
  Available: "positiveLow",
  Conditional: "caution",
  Unavailable: "critical",
  GoingToHQ: "positive",
  GoingToJob: "positive",
} as const satisfies Record<AvailabilityStatus & MemberStatus, BackgroundTones>;

const STATUS_TO_BORDER_TONE_MAP = {
  ImmediatelyAvailable: "positive",
  Available: "positiveLow",
  Conditional: "caution",
  Unavailable: "critical",
  GoingToHQ: "positive",
  GoingToJob: "positive",
} as const satisfies Record<AvailabilityStatus & MemberStatus, BorderTones>;

const availabilitySlotStyle = ({
  status,
  committed,
}: {
  status?: AvailabilityStatus | MemberStatus | null;
  committed?: boolean;
}): {
  backgroundTone?: BackgroundTones;
  borderTone?: BorderTones;
} => {
  if (committed) {
    return {
      backgroundTone: "info",
      borderTone: "info",
    };
  }

  if (status) {
    return {
      backgroundTone: STATUS_TO_BACKGROUND_TONE_MAP[status],
      borderTone: STATUS_TO_BORDER_TONE_MAP[status],
    };
  }

  return {
    backgroundTone: "surface",
    borderTone: "neutral",
  };
};

export { availabilitySlotStyle };
