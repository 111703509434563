import { Unit } from "@ses-mams/api-contract";
import { useEffect, useMemo, useState } from "react";
import { UnitSelector } from "../unitSelector";
import { useParams, useSearchParams } from "react-router-dom";
import { tsr } from "~/utils/client";

export const OtherMemberAvailabilityUnitSelector = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const memberId = params.memberId as string;
  const unitId = searchParams.get("unitId") ?? undefined;

  const { data: memberData, isLoading: isLoadingMember } =
    tsr.members.get.useQuery({
      queryKey: ["members", memberId],
      queryData: {
        params: { id: memberId },
      },
    });

  const [selectedUnit, setSelectedUnit] = useState<Unit>();

  const memberUnits = useMemo(() => {
    return memberData?.body?.units?.map((u: { unit: Unit }) => u.unit) ?? [];
  }, [memberData?.body?.units]);

  // set default selected unit id
  useEffect(() => {
    if (!selectedUnit && memberUnits.length > 0) {
      const defaultSelectedUnit =
        memberUnits.find(unit => unit.id === unitId) || memberUnits[0];
      setSelectedUnit(defaultSelectedUnit);

      setSearchParams(prevSearchParams => ({
        ...Object.fromEntries(prevSearchParams),
        unitId: defaultSelectedUnit.id ?? "",
      }));
    }
  }, [selectedUnit, memberUnits, unitId]);

  return (
    <UnitSelector
      isLoading={!selectedUnit || isLoadingMember}
      units={memberUnits}
      selectedUnit={selectedUnit}
      setSelectedUnit={unit => {
        setSelectedUnit(unit);
        setSearchParams(prevSearchParams => {
          return {
            ...Object.fromEntries(prevSearchParams),
            unitId: unit.id,
          };
        });
      }}
    />
  );
};
