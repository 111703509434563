import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { activationContract } from "./activation";
import { activationRequestContract } from "./activationRequest";
import { activityContract } from "./activity";
import { activityRequestContract } from "./activityRequest";
import { activityScheduleContract } from "./activitySchedule";
import { adminContract } from "./admin";
import { auditContract } from "./audit";
import { authContract } from "./auth";
import { capabilitiesContract } from "./capability";
import { channelsSchema } from "./channels";
import { chatContract } from "./chat";
import { clustersContract } from "./clusters";
import { contactGroupsContract } from "./contactGroups";
import { devicesContract } from "./devices";
import { groupsContract } from "./groups";
import { healthContract } from "./health";
import { membersContract } from "./members";
import { notificationsContract } from "./notifications";
import { outOfAreaActivationApprovalsContract } from "./outOfAreaActivationApprovals";
import { outOfAreaActivationRequestContract } from "./outOfAreaActivationRequest";
import { outOfAreaActivationRolesContract } from "./outOfAreaActivationRole";
import { scheduleContract } from "./schedule";
import { syncContract } from "./sync";
import { unitsContract } from "./units";
import { zonesContract } from "./zones";
import { outOfAreaActivationContract } from "./outOfAreaActivation";
import { broadcastsContract } from "./broadcasts";
import { broadcastAcknowledgementsContract } from "./broadcastAcknowlegements";
import { reportingContract } from "./reporting";

const c = initContract();

export const contract = c.router(
  {
    activations: activationContract,
    activationRequests: activationRequestContract,
    activities: activityContract,
    activityRequests: activityRequestContract,
    activitySchedules: activityScheduleContract,
    admin: adminContract,
    audit: auditContract,
    auth: authContract,
    capabilities: capabilitiesContract,
    chat: chatContract,
    channels: channelsSchema,
    clusters: clustersContract,
    contactGroups: contactGroupsContract,
    devices: devicesContract,
    groups: groupsContract,
    health: healthContract,
    members: membersContract,
    notifications: notificationsContract,
    outOfAreaActivations: outOfAreaActivationContract,
    outOfAreaActivationApprovals: outOfAreaActivationApprovalsContract,
    outOfAreaActivationRequests: outOfAreaActivationRequestContract,
    outOfAreaActivationRoles: outOfAreaActivationRolesContract,
    sync: syncContract,
    schedule: scheduleContract,
    units: unitsContract,
    zones: zonesContract,
    broadcasts: broadcastsContract,
    broadcastAcknowledgements: broadcastAcknowledgementsContract,
    reporting: reportingContract,
  },
  {
    baseHeaders: z.object({
      authorization: z.string().optional(),
      "x-app-version": z.string().optional(),
      "x-source": z.string().optional(),
    }),
  }
);

export {
  SearchMembersFilterSchema,
  ServerDateSchema,
  AvailabilityStatusSchema,
} from "./common";
export { ContactGroupSchema } from "./contactGroups";
export { GroupOutputSchema } from "./groups";
export { MemberSchema } from "./members";
export { OutOfAreaActivationAvailabilityStatusSchema } from "./outOfAreaActivation";

export type {
  ActivationRequestMember,
  ActivationPointsOfInterest,
} from "./activation";
export type {
  ActivationRequest,
  ActivationRequestDetails,
  ActivationRequestStatus,
  Activation,
  ActivationSummary,
  ActivationType,
  ActivationRequestWithAvailabilityCount,
} from "./activationRequest";
export type { ActivityMember } from "./activity";
export type {
  Activity,
  ActivitySummary,
  ActivityAvailabilityStatus,
  ActivityRequest,
  ActivityRequestDetails,
  ActivityRequestWithAvailabilityCount,
  ActivityRequestStatus,
} from "./activityRequest";
export type {
  ActivitySchedule,
  ActivityScheduleSummary,
} from "./activitySchedule";
export type { AuthResponse } from "./auth";
export type { MemberAdminSummary } from "./admin";
export type { AuditLog } from "./audit";
export type { ChannelSettings } from "./channels";
export type { Cluster } from "./clusters";
export type {
  ApiErrorResponse,
  ApiResponse,
  Availability,
  AvailabilityStatus,
  AvailabilityReportStatus,
  Capability,
  Suggestion,
  MemberStatus,
  MemberSummary,
  MemberType,
  PaginationQueryParams,
  SearchMembersFilter,
  Unit,
} from "./common";
export type { ContactGroup } from "./contactGroups";
export type { DevicePlatform } from "./devices";
export type { Group, GroupInput } from "./groups";
export type {
  Role,
  Member,
  AvailabilityInput,
  MemberOrgHierarchy,
  MemberBroadcastMessage,
} from "./members";
export type { NotificationSettings } from "./notifications";
export type { OutOfAreaActivationApproval } from "./outOfAreaActivationApprovals";
export type {
  OutOfAreaActivationRoleCategory,
  OutOfAreaActivationRole,
} from "./outOfAreaActivationRole";
export type {
  OutOfAreaActivation,
  OutOfAreaActivationLevel,
  OutOfAreaType,
  OutOfAreaEscalationTimeUnit,
  OutOfAreaActivationDeployment,
  OutOfAreaActivationSummary,
  OutOfAreaActivationRequestMember,
  OutOfAreaActivationRequestMemberSummary,
  OutOfAreaActivationRequestPreApprovedMember,
  OutOfAreaActivationRequestStatus,
  OutOfAreaActivationRequestAvailabilityBlock,
} from "./outOfAreaActivation";
export type {
  OutOfAreaActivationRequest,
  OutOfAreaActivationRequestDetails,
  OutOfAreaActivationAvailabilityStatus,
  OutOfAreaActivationRequestHistory,
  OutOfAreaActivationRequestHistoryDetails,
  OutOfAreaActivationRequestWithStatusCount,
} from "./outOfAreaActivationRequest";
export type { ScheduleItem } from "./schedule";
export type {
  AggreggatedUnitAvailabilityPeriod,
  IndividualUnitAvailability,
} from "./units";
export type { Zone } from "./zones";
export type { Broadcast } from "./broadcasts";
