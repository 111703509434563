import { LatitudeLongitude, toLatLng } from "~/utils/latLng";
import { MarkerF, OverlayViewF } from "@react-google-maps/api";
import eventPinImage from "~/assets/pins/event.png";
import selectedEventPinImage from "~/assets/pins/event-selected.png";
import venuePinImage from "~/assets/pins/venue.png";
import selectedVenuePinImage from "~/assets/pins/venue-selected.png";
import memberPinImage from "~/assets/pins/member.png";
import selectedMemberPinImage from "~/assets/pins/member-selected.png";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { useMemo } from "react";
import { ActivationPointsOfInterest } from "@ses-mams/api-contract";
import { SelectedMemberDetails } from "./SelectedMemberDetails";

type PinMarkerType = "event" | "venue" | "member";

type Props = {
  type: PinMarkerType;
  coords: LatitudeLongitude;
  address?: string | null;
  member?: ActivationPointsOfInterest["members"][0];
  selected: boolean;
  setSelected: () => void;
};

export const PinMarker = ({
  type,
  coords,
  address,
  member,
  selected,
  setSelected,
}: Props) => {
  const latLng = toLatLng(coords);
  const icon = useMarkerIconProps(type, selected);
  return latLng ? (
    <>
      <MarkerF
        position={latLng}
        options={{
          icon,
        }}
        onClick={() => setSelected()}
      />
      {selected && (
        <OverlayViewF
          mapPaneName="floatPane"
          position={latLng}
          getPixelPositionOffset={width => ({
            x: -(width / 2),
            y: type === "member" ? -180 : -135,
          })}
        >
          <Stack
            background="surface"
            borderRadius="large"
            padding="medium"
            sx={{ boxShadow: "0px 2px 8px -3px #00000033;" }}
          >
            {type === "member" ? (
              <>{member && <SelectedMemberDetails member={member} />}</>
            ) : (
              <Stack gap="xxsmall">
                <Text size="large" weight="semi">
                  {type === "event" ? "Location" : "Headquarters"}
                </Text>
                <Text>{address}</Text>
              </Stack>
            )}
          </Stack>
        </OverlayViewF>
      )}
    </>
  ) : null;
};

const useMarkerIconProps = (type: PinMarkerType, selected: boolean) =>
  useMemo(() => {
    switch (type) {
      case "event":
        return selected
          ? {
              url: selectedEventPinImage,
              anchor: new google.maps.Point(22, 46),
            }
          : {
              url: eventPinImage,
            };
      case "venue":
        return selected
          ? {
              url: selectedVenuePinImage,
              anchor: new google.maps.Point(22, 46),
            }
          : {
              url: venuePinImage,
            };
      case "member":
        return selected
          ? {
              url: selectedMemberPinImage,
              anchor: new google.maps.Point(18, 34),
            }
          : {
              url: memberPinImage,
            };
    }
  }, [type, selected]);
