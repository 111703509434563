import {
  Tabs as MUITabs,
  TabsProps as MuiTabsProps,
  styled,
} from "@mui/material";
import { TabProps } from "./Tab";

type ChildType = React.ReactElement<TabProps> | null;

type TabsProps = MuiTabsProps & {
  children: ChildType | ChildType[];
};

export const Tabs = ({ children, ...props }: TabsProps) => (
  <StyledTabs {...props}>{children}</StyledTabs>
);

const StyledTabs = styled(MUITabs)(({ theme }) => {
  return {
    minHeight: "unset",

    ".MuiTabs-flexContainer": {
      gap: theme.tokens.spacing.small,
    },
    ".MuiTabs-indicator": {
      display: "none",
    },
  };
});
