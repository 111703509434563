import { useMessageContext } from "stream-chat-react";
import { AlertTriangleFilledIcon } from "~/components/ui/icon";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

export const ImportantMessageIndicator = () => {
  const { isMyMessage } = useMessageContext();
  return (
    <Stack
      gap="xsmall"
      direction="row"
      align="center"
      justify={isMyMessage() ? "end" : "start"}
    >
      <AlertTriangleFilledIcon tone="critical" size="xxsmall" />
      <Text weight="medium" tone="critical">
        Important
      </Text>
    </Stack>
  );
};
