import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  ApiErrorSchema,
  PaginationQueryParamsSchema,
  ServerDateSchema,
} from "./common";

const c = initContract();

const visibilityOptions = [
  "ShowInApp",
  "ShowInWeb",
  "ShowInAppAndWeb",
] as const;

const timingOptions = ["SendNow", "SendLater"] as const;

const statusOptions = ["Draft", "Scheduled", "Current", "Complete"] as const;

const BroadcastMessageCreateOrUpdateInputSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
  visibility: z.enum(visibilityOptions).optional(),
  timing: z.enum(timingOptions).optional(),
  start: ServerDateSchema.optional(),
  end: ServerDateSchema.optional(),
  draft: z.boolean(),
});

export const BroadcastMessageSummarySchema = z.object({
  id: z.string(),
  title: z.string().optional(),
  description: z.string().optional(),
  visibility: z.enum(visibilityOptions).optional(),
  timing: z.enum(timingOptions).optional(),
  start: ServerDateSchema.optional(),
  end: ServerDateSchema.optional(),
  createdAt: z.date(),
});
export const BroadcastMessageSchema = BroadcastMessageSummarySchema.extend({
  draft: z.boolean(),
  status: z.enum(statusOptions),
});

export const broadcastsContract = c.router({
  create: {
    method: "POST",
    path: "/broadcasts",
    body: BroadcastMessageCreateOrUpdateInputSchema,
    responses: {
      200: BroadcastMessageSchema,
      403: ApiErrorSchema,
    },
    summary: "Save a broadcast message, in either draft or complete form",
  },
  update: {
    method: "PATCH",
    path: "/broadcasts/:id",
    body: BroadcastMessageCreateOrUpdateInputSchema,
    responses: {
      200: BroadcastMessageSchema,
      400: ApiErrorSchema,
      403: ApiErrorSchema,
      404: ApiErrorSchema,
    },
    summary: "Edit a broadcast message before it goes live",
  },
  close: {
    method: "PATCH",
    path: "/broadcasts/:id/close",
    pathParams: z.object({
      id: z.string(),
    }),
    body: null,
    responses: {
      200: BroadcastMessageSchema,
      400: ApiErrorSchema,
      403: ApiErrorSchema,
      404: ApiErrorSchema,
    },
    summary: "End a live broadcast message manually",
  },
  delete: {
    method: "DELETE",
    path: "/broadcasts/:id",
    pathParams: z.object({
      id: z.string(),
    }),
    body: null,
    responses: {
      200: null,
      400: ApiErrorSchema,
      403: ApiErrorSchema,
      404: ApiErrorSchema,
    },
    summary: "Delete a broadcast message before it goes live",
  },
  get: {
    method: "GET",
    path: "/broadcasts/:id",
    pathParams: z.object({
      id: z.string(),
    }),
    responses: {
      200: BroadcastMessageSchema,
      404: ApiErrorSchema,
    },
    summary: "Get the details of a broadcasts",
  },
  list: {
    method: "GET",
    path: "/broadcasts/",
    query: PaginationQueryParamsSchema,
    responses: {
      200: z.object({
        items: z.array(BroadcastMessageSchema),
      }),
      404: ApiErrorSchema,
    },
    summary: "Get a list of a broadcasts",
  },
});

export type Broadcast = z.infer<typeof BroadcastMessageSchema>;
