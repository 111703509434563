import { AvailabilityStatus } from "@ses-mams/api-contract";

export const statusToBackgroundColor: Record<
  AvailabilityStatus | "Committed" | "Unset",
  string
> = {
  ImmediatelyAvailable: "#167C3C",
  Available: "#5F9841",
  Conditional: "#D9AD3E",
  Unavailable: "#D13F3F",
  Unset: "#E7E9EC",
  Committed: "#1F5C92",
};
