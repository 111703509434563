import { addMinutes } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Activation, ActivationRequestMember } from "@ses-mams/api-contract";
import {
  getPreActivatedEtaInMinutes,
  isFutureActivation as isFutureActivationFn,
} from "../utils";
import { useGoogleComputeRoute } from "./useGoogleComputeRoute";

export type ActivationMemberEta = {
  activationRequestId: string;
  eta: {
    value: Date;
    isGps: boolean;
  } | null;
  preActivatedEtaInMinutes: number | null;
};

type UseActivationMemberEtasParams = {
  activation?: Activation;
  members: Array<ActivationRequestMember>;
  googlePlacesApiKey: string;
  enabled?: boolean;
};

export const useActivationMemberEtas = ({
  activation,
  members,
  googlePlacesApiKey,
  enabled = true,
}: UseActivationMemberEtasParams) => {
  const isFutureActivation = useMemo(() => {
    if (!activation) {
      return false;
    }

    return isFutureActivationFn(activation);
  }, [activation]);

  const { getGoogleComputedRoute } = useGoogleComputeRoute(googlePlacesApiKey);

  const [memberEtas, setMemberEtas] = useState<
    Map<string, ActivationMemberEta>
  >(new Map());

  const getMemberGpsEta = useCallback(
    async (request: ActivationRequestMember) => {
      if (!activation) {
        return {
          activationRequestId: request.id,
          eta: null,
          preActivatedEtaInMinutes: null,
        };
      }

      const route = await getGoogleComputedRoute({
        origin: {
          latitude: request.latitude as number,
          longitude: request.longitude as number,
        },
        destination: {
          latitude: activation.latitude as number,
          longitude: activation.longitude as number,
        },
      });

      return {
        activationRequestId: request.id,
        eta: route
          ? {
              value: addMinutes(new Date(), route.duration),
              isGps: true,
            }
          : null,
        preActivatedEtaInMinutes: null,
      };
    },
    [activation, getGoogleComputedRoute]
  );

  const activationMembers = useMemo(
    () => members.filter(s => s.eta || (s.latitude && s.longitude)),
    [members]
  );

  useEffect(() => {
    if (!activation || !activationMembers.length || !enabled) {
      return;
    }

    const etaPromises = activationMembers.map(m => {
      const preActivatedEtaInMinutes = getPreActivatedEtaInMinutes({
        availabilityStatus: m.availabilityStatus,
        eta: m.eta,
        status: m.status,
        activation,
      });

      if (preActivatedEtaInMinutes) {
        return Promise.resolve<ActivationMemberEta>({
          activationRequestId: m.id,
          preActivatedEtaInMinutes,
          eta: null,
        });
      }

      if (isFutureActivation) {
        return {
          activationRequestId: m.id,
          eta: null,
          preActivatedEtaInMinutes: null,
        };
      }

      if (m.eta) {
        return Promise.resolve<ActivationMemberEta>({
          activationRequestId: m.id,
          eta: {
            value: new Date(m.eta),
            isGps: false,
          },
          preActivatedEtaInMinutes: null,
        });
      }

      return getMemberGpsEta(m);
    });

    (async () => {
      const etaResults = await Promise.all(etaPromises);

      setMemberEtas(
        new Map(
          etaResults
            .filter(
              ({ eta, preActivatedEtaInMinutes }) =>
                eta !== null || preActivatedEtaInMinutes !== null
            )
            .map(result => [result.activationRequestId, result])
        )
      );
    })();
  }, [activationMembers, activation, enabled]);

  return memberEtas;
};
