import { OutOfAreaActivationRole } from "@ses-mams/api-contract";
import isEqual from "lodash.isequal";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export type OutOfAreaMembersListFilters = {
  activatedStatus: boolean;
  approvedStatus: boolean;
  rejectedStatus: boolean;
  zonePendingStatus: boolean;
  clusterPendingStatus: boolean;
  unitPendingStatus: boolean;
  awaitingStatus: boolean;
  collection?: string;
  name?: string;
  statusUpdatedAt?: Date | null;
  roles: OutOfAreaActivationRole[];
};

export type OutOfAreaMembersListFiltersContextType = {
  filters: OutOfAreaMembersListFilters;
  setFilters: React.Dispatch<React.SetStateAction<OutOfAreaMembersListFilters>>;
  resetFilters: () => void;
  hasFilters: boolean;
};

export const defaultOutOfAreaMembersListFilters: OutOfAreaMembersListFilters = {
  activatedStatus: true,
  approvedStatus: true,
  rejectedStatus: true,
  zonePendingStatus: true,
  clusterPendingStatus: true,
  unitPendingStatus: true,
  awaitingStatus: true,
  statusUpdatedAt: null,
  roles: [],
};

const OutOfAreaMembersListFiltersContext =
  createContext<OutOfAreaMembersListFiltersContextType | null>(null);

export const OutOfAreaMembersListFiltersContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [filters, setFilters] = useState<OutOfAreaMembersListFilters>(
    defaultOutOfAreaMembersListFilters
  );

  const resetFilters = useCallback(() => {
    setFilters(defaultOutOfAreaMembersListFilters);
  }, [setFilters]);

  const value = useMemo(() => {
    return {
      filters,
      setFilters,
      resetFilters,
      hasFilters: !isEqual(filters, defaultOutOfAreaMembersListFilters),
    };
  }, [filters, setFilters, resetFilters]);

  return (
    <OutOfAreaMembersListFiltersContext.Provider value={value}>
      {children}
    </OutOfAreaMembersListFiltersContext.Provider>
  );
};

export const useOutOfAreaMembersListFilters = () => {
  const context = useContext(OutOfAreaMembersListFiltersContext);
  if (!context) {
    throw new Error(
      "useOutOfAreaMembersListFilters must be used within <OutOfAreaMembersListFiltersContextProvider />s"
    );
  }
  return context;
};
