import { zodResolver } from "@hookform/resolvers/zod";
import { useUpdateEffect } from "@ses-mams/react-utils";
import {
  CloseOutOfAreaActivationFormSchema,
  closeOutOfAreaActivationFormSchema,
} from "@ses-mams/validation";
import { Controller, useForm } from "react-hook-form";
import { Box } from "~/components/ui/box";
import { Button } from "~/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerProps,
} from "~/components/ui/drawer";
import { RadioGroupField, RadioGroupFieldItem } from "~/components/ui/radio";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { TextField } from "~/components/ui/textField";
import { tsr } from "~/utils/client";

type OutOfAreaActivationCloseReasonDrawerProps = Omit<
  DrawerProps,
  "children"
> & {
  busy?: boolean;
  onSubmit: (reason: string) => void;
};

export const OutOfAreaActivationCloseReasonDrawer = ({
  busy,
  open,
  onClose,
  onSubmit,
}: OutOfAreaActivationCloseReasonDrawerProps) => {
  const { control, formState, register, reset, handleSubmit, watch } =
    useForm<CloseOutOfAreaActivationFormSchema>({
      resolver: zodResolver(closeOutOfAreaActivationFormSchema),
      mode: "onBlur",
      defaultValues: {
        reason: "Other",
      },
    });

  const { data, isLoading: isLoadingReasons } =
    tsr.outOfAreaActivations.listCloseReasons.useQuery({
      queryKey: ["out-of-area-activations", "close-reasons"],
    });

  useUpdateEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  const watchReason = watch("reason");

  return (
    <Drawer open={open} onClose={onClose}>
      {isLoadingReasons ? (
        <Box>
          <Spinner />
        </Box>
      ) : (
        <>
          <DrawerHeader onClose={onClose}>Reason for closing</DrawerHeader>
          <form
            onSubmit={handleSubmit(form =>
              onSubmit(form.freeTextReason || form.reason)
            )}
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <DrawerContent>
              <Stack gap="large">
                <Controller
                  name="reason"
                  control={control}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <RadioGroupField
                      label="Reason"
                      hideLabel
                      value={value}
                      onChange={onChange}
                      errorMessage={fieldState.error?.message}
                    >
                      {(data?.body ?? []).map(reason => (
                        <RadioGroupFieldItem
                          key={reason}
                          label={reason}
                          value={reason}
                        />
                      ))}
                    </RadioGroupField>
                  )}
                />

                {watchReason === "Other" && (
                  <TextField
                    {...register("freeTextReason", { shouldUnregister: true })}
                    label="Reason"
                    hideLabel
                    multiline
                    rows={4}
                    placeholder="Enter reason"
                    errorMessage={formState.errors?.freeTextReason?.message}
                  />
                )}
              </Stack>
            </DrawerContent>

            <DrawerFooter>
              <Button type="submit" fullWidth busy={busy}>
                Done
              </Button>
            </DrawerFooter>
          </form>
        </>
      )}
    </Drawer>
  );
};
