import { useCallback, useState } from "react";
import { useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/ButtonBase";

import { formatMemberFullName } from "@ses-mams/react-utils";

import { Stack } from "~/components/ui/stack";
import { useAuth, useHasAnyRole } from "~/context/auth";
import { Divider } from "~/components/ui/divider";
import {
  CalendarFilledIcon,
  ChevronDownOutlineIcon,
  EyeFilledIcon,
  LogoutFilledIcon,
  MessageTypingFilledIcon,
  SettingsFilledIcon,
  ShieldFilledIcon,
  UserProfileFilledIcon,
  UserProfileGroupFilledIcon,
} from "~/components/ui/icon";
import { MemberDetailsDrawer } from "~/components/common/memberDetails";
import { NavLink } from "~/components/common/navigation";
import { Box } from "~/components/ui/box";
import { Text } from "~/components/ui/text";
import { Menu, MenuItem, MenuSection } from "~/components/ui/menu";
import { PRIVACY_POLICY_URL } from "~/utils/constants";
import { useNavLinks } from "~/components/common/navigation";

export const CurrentUserMenu = () => {
  const [isMemberInformationDrawerOpen, setIsMemberInformationDrawerOpen] =
    useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const { member, logout } = useAuth();

  const hasImpersonateRole = useHasAnyRole(["Impersonate"]);

  const navLinks = useNavLinks();

  const {
    tokens: { colors },
  } = useTheme();

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  if (!member) {
    return null;
  }

  const memberName = formatMemberFullName(member);

  return (
    <>
      <Button
        onClick={handleClick}
        aria-controls={open ? MENU_ID : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Stack direction="row" gap="medium" marginLeft="medium" align="center">
          <Avatar
            sx={{
              width: 30,
              height: 30,
              background: colors.background.infoMuted,
            }}
          >
            <Text weight="semi" tone="info">
              {member.firstName.charAt(0)}
            </Text>
          </Avatar>

          <Text weight="medium" display={{ xs: "none", md: "flex" }}>
            {memberName}
          </Text>

          <ChevronDownOutlineIcon />
        </Stack>
      </Button>
      <Menu
        // Prevents padding being added to the body when opened on small screens
        disableScrollLock
        anchorEl={anchorEl}
        id={MENU_ID}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuSection>
          <MenuItem sx={{ pointerEvents: "none" }}>
            <Text>{memberName}</Text>
          </MenuItem>
        </MenuSection>

        <Divider />

        <Box display={{ xs: "flex", md: "none" }} direction="column">
          <MenuSection>
            {navLinks.map(item => (
              <MenuItem key={item.key}>{item}</MenuItem>
            ))}
          </MenuSection>

          <Divider />

          <AdminMenuSection />
        </Box>

        <MenuSection>
          <MenuItem onClick={() => setIsMemberInformationDrawerOpen(true)}>
            <Stack direction="row" gap="small" align="center">
              <UserProfileFilledIcon tone="muted" size="xxsmall" />
              <Text tone="muted" weight="medium">
                Member Information
              </Text>
            </Stack>
          </MenuItem>

          <MenuItem>
            <NavLink icon={CalendarFilledIcon} to="/schedule">
              My Schedule
            </NavLink>
          </MenuItem>

          <MenuItem>
            <NavLink icon={MessageTypingFilledIcon} to="/broadcast-messages">
              Broadcast Messages
            </NavLink>
          </MenuItem>

          <MenuItem>
            <NavLink icon={ShieldFilledIcon} to="/audit-logs">
              Audit Logs
            </NavLink>
          </MenuItem>

          <MenuItem>
            <NavLink icon={CalendarFilledIcon} to="/reporting">
              Availability reporting
            </NavLink>
          </MenuItem>

          {hasImpersonateRole && (
            <MenuItem>
              <NavLink icon={UserProfileGroupFilledIcon} to="/impersonate">
                Impersonate
              </NavLink>
            </MenuItem>
          )}

          <MenuItem>
            <NavLink
              icon={EyeFilledIcon}
              to={PRIVACY_POLICY_URL}
              target="_blank"
            >
              Privacy Policy
            </NavLink>
          </MenuItem>
        </MenuSection>

        <Divider />

        <MenuSection>
          <MenuItem onClick={logout}>
            <Stack direction="row" gap="small" align="center">
              <LogoutFilledIcon tone="critical" size="xxsmall" />
              <Text weight="medium" tone="critical">
                Logout
              </Text>
            </Stack>
          </MenuItem>
        </MenuSection>
      </Menu>

      {member?.id && (
        <MemberDetailsDrawer
          open={isMemberInformationDrawerOpen}
          memberId={member.id}
          onClose={() => setIsMemberInformationDrawerOpen(false)}
        />
      )}
    </>
  );
};

const AdminMenuSection = () => {
  const hasManageUsersRole = useHasAnyRole(["Administrator"]);
  const hasBroadcastRole = useHasAnyRole(["SystemAdministrator"]);

  if (hasManageUsersRole || hasBroadcastRole) {
    return (
      <>
        <MenuSection>
          <Box paddingLeft="large" paddingY="xsmall">
            <Text tone="muted" weight="medium">
              Admin
            </Text>
          </Box>

          {hasManageUsersRole ? (
            <MenuItem>
              <NavLink
                key="admin-users"
                icon={SettingsFilledIcon}
                to="/admin/users"
              >
                Users
              </NavLink>
            </MenuItem>
          ) : null}
          {hasBroadcastRole ? (
            <MenuItem>
              <NavLink
                key="admin-broadcast"
                icon={SettingsFilledIcon}
                to="/admin/broadcast"
              >
                Broadcast
              </NavLink>
            </MenuItem>
          ) : null}
        </MenuSection>
        <Divider />
      </>
    );
  }

  return null;
};

const MENU_ID = "account-menu";
