import { z } from "zod";

export const etaFormSchema = z.object({
  minutes: z
    .string()
    .regex(
      /^(\s*|[1-9][0-9]*)$/,
      "ETA must be a value in minutes or left empty"
    )
    .optional(),
});

export type EtaFormSchema = z.infer<typeof etaFormSchema>;
