import { z } from "zod";
import { MemberSchema } from "@ses-mams/api-contract";

export const editGroupFormSchema = z
  .object({
    name: z
      .string({ required_error: "Name is required" })
      .trim()
      .min(1, "Name is required"),
    reportable: z.boolean(),
    capabilities: z.array(
      z.object({
        id: z.string(),
        label: z.string(),
        name: z.string(),
      })
    ),
    addMembers: z.array(MemberSchema),
    removeMembers: z.array(MemberSchema),
    numberOfMembers: z.number().optional(),
    useAvailabilityRequirements: z.boolean(),
    availabilityMinThreshold: z.number().optional(),
    availabilityLookAheadDays: z.number().optional(),
  })
  .refine(
    data =>
      data.capabilities.length ||
      data.addMembers.length ||
      data.numberOfMembers,
    {
      path: ["addMembers"],
      message: "Select at least 1 capability or member",
    }
  )
  .superRefine((data, context) => {
    if (data.useAvailabilityRequirements) {
      if (typeof data.availabilityMinThreshold === "undefined") {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Minimum number of members is required",
          path: ["availabilityMinThreshold"],
        });
      }
      if (typeof data.availabilityLookAheadDays === "undefined") {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Number of days ahead is required",
          path: ["availabilityLookAheadDays"],
        });
      }
    }
  });

export type EditGroupFormSchema = z.infer<typeof editGroupFormSchema>;
