import { OutOfAreaActivationRole } from "@ses-mams/api-contract";
import { useArrayState } from "@ses-mams/react-utils";
import { useCallback } from "react";
import { Button } from "~/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerProps,
} from "~/components/ui/drawer";
import { SelectOutOfAreaRolesList } from "./SelectOutOfAreaRolesList";

type SelectOutOfAreaRolesDrawerProps = Omit<DrawerProps, "children"> & {
  roles: Array<OutOfAreaActivationRole>;
  onDone: (roles: Array<OutOfAreaActivationRole>) => void;
};

export const SelectOutOfAreaRolesDrawer = ({
  open,
  onClose,
  onDone,
  roles,
  sx,
}: SelectOutOfAreaRolesDrawerProps) => {
  const [selectedRoles, { append, remove, setValue }] = useArrayState(roles);

  const handleOnClose = useCallback(() => {
    setValue([...roles]);

    onClose();
  }, [roles, onClose]);

  const handleDoneClick = useCallback(() => {
    onDone(selectedRoles);
    onClose();
  }, [onDone, selectedRoles]);

  return (
    <Drawer open={open} onClose={handleOnClose} sx={sx}>
      <DrawerHeader onClose={handleOnClose}>Select Roles</DrawerHeader>

      <DrawerContent>
        <SelectOutOfAreaRolesList
          roles={selectedRoles}
          appendRole={append}
          removeRole={remove}
        />
      </DrawerContent>

      <DrawerFooter>
        <Button fullWidth onClick={handleDoneClick}>
          Done
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
