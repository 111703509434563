import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  ActivationSchema,
  ActivationTypeSchema,
  ActivationRequestDetailsSchema,
  ActivationSummarySchema,
} from "./activationRequest";
import {
  ApiErrorSchema,
  CapabilitySchema,
  ServerDateSchema,
  MemberSummarySchema,
  UnitSchema,
  MemberStatusSchema,
  SearchMembersFilterSchema,
  PaginationQueryParamsSchema,
  getPaginationResponseSchema,
  AvailabilitySchema,
} from "./common";

const c = initContract();

const ActivationCreateInputSchema = z.object({
  title: z.string(),
  description: z.string(),
  unitId: z.string(),
  type: ActivationTypeSchema,
  location: z.string().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  start: ServerDateSchema,
  end: ServerDateSchema,
  recipientGroupIds: z.array(z.string()).optional(),
  recipientContactGroupIds: z.array(z.string()).optional(),
  recipientMemberIds: z.array(z.string()).optional(),
  recipientUnitIds: z.array(z.string()).optional(),
  requestAvailableMembersOnly: z.boolean().optional(),
  selectAllMembersQuery: SearchMembersFilterSchema.optional(),
});

const ActivationUpdateInputSchema = ActivationCreateInputSchema.omit({
  unitId: true,
  type: true,
  recipientGroupIds: true,
  recipientContactGroupIds: true,
  recipientMemberIds: true,
  recipientUnitIds: true,
});

const ActivationRequestMemberSchema = ActivationRequestDetailsSchema.extend({
  member: MemberSummarySchema.extend({
    capabilities: z.array(CapabilitySchema),
    availability: AvailabilitySchema.nullish(),
  }),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  locationLastUpdatedAt: z.string().datetime().optional(),
  activatedToRequest: z.boolean(),
  activatedToAnotherRequest: z.boolean(),
});

const ActivationPointsOfInterestSchema = z.object({
  unit: UnitSchema.pick({
    address: true,
    latitude: true,
    longitude: true,
  }),
  activation: ActivationSchema.pick({
    location: true,
    latitude: true,
    longitude: true,
  }),
  members: z.array(
    MemberSummarySchema.extend({
      capabilities: z.array(CapabilitySchema),
      latitude: z.number(),
      longitude: z.number(),
      locationLastUpdatedAt: z.string().datetime(),
      eta: ServerDateSchema.nullable(),
    })
  ),
});

export const activationContract = c.router({
  get: {
    method: "GET",
    path: "/activations/:id",
    pathParams: z.object({
      id: z.string(),
    }),
    responses: {
      200: ActivationSchema,
      404: ApiErrorSchema,
    },
    summary: "Get the details of an activation",
  },
  create: {
    method: "POST",
    path: "/activations",
    body: ActivationCreateInputSchema,
    responses: {
      200: ActivationSummarySchema,
      403: ApiErrorSchema,
    },
    summary: "Create an Activation with any associated Activation Requests",
  },
  update: {
    method: "PATCH",
    path: "/activations/:id",
    pathParams: z.object({
      id: z.string(),
    }),
    body: ActivationUpdateInputSchema,
    responses: {
      200: ActivationSummarySchema,
      403: ApiErrorSchema,
      404: ApiErrorSchema,
    },
    summary: "Update the details of an activation",
  },
  close: {
    method: "DELETE",
    path: "/activations/:id",
    pathParams: z.object({
      id: z.string(),
    }),
    body: null,
    responses: {
      200: ActivationSummarySchema,
      403: ApiErrorSchema,
      404: ApiErrorSchema,
    },
    summary: "Close an activation",
  },
  closeMany: {
    method: "DELETE",
    path: "/activations",
    body: z.object({
      activationIds: z.array(z.string()),
    }),
    responses: {
      200: null,
    },
    summary: "Close multiple activations",
  },
  getActivationMembers: {
    method: "GET",
    path: "/activations/:activationId/members",
    query: PaginationQueryParamsSchema.extend({
      capabilityIds: z.array(z.string()).optional(),
      query: z.coerce.string().trim().optional(),
    }),
    responses: {
      200: getPaginationResponseSchema(ActivationRequestMemberSchema),
    },
    summary: "Get the members of an activation",
  },
  addActivationMembers: {
    method: "POST",
    path: "/activations/:activationId/members",
    pathParams: z.object({
      activationId: z.string(),
    }),
    responses: {
      200: z.object({
        items: z.array(ActivationRequestMemberSchema),
      }),
      403: ApiErrorSchema,
    },
    body: z.object({
      groupIds: z.array(z.string()),
      contactGroupIds: z.array(z.string()),
      memberIds: z.array(z.string()),
      requestAvailableMembersOnly: z.boolean().optional(),
      selectAllMembersQuery: SearchMembersFilterSchema.optional(),
    }),
    summary: "Add members to an activation",
  },
  requestActivation: {
    method: "POST",
    path: "/activations/:activationId/request-activation",
    responses: {
      200: z.array(ActivationRequestMemberSchema),
      403: ApiErrorSchema,
    },
    body: z.object({
      activationRequestIds: z.array(z.string()),
    }),
    summary: "Request activation for an activation",
  },
  activate: {
    method: "POST",
    path: "/activations/:activationId/activate",
    responses: {
      200: z.array(ActivationRequestMemberSchema),
      403: ApiErrorSchema,
      422: ApiErrorSchema,
    },
    body: z.object({
      status: MemberStatusSchema.optional().default("GoingToHQ"),
      activationRequestIds: z
        .array(z.string())
        .max(
          100,
          "Cannot activate more than 100 requests in a single operation."
        ),
      force: z.boolean().optional(),
    }),
    summary: "Bulk action to confirm activation for activation requests",
  },
  deactivate: {
    method: "POST",
    path: "/activations/:activationId/deactivate",
    responses: {
      200: z.array(ActivationRequestMemberSchema),
      403: ApiErrorSchema,
    },
    body: z.object({
      activationRequestIds: z.array(z.string()),
    }),
    summary: "Deactivate activation requests",
  },
  getActivationPointsOfInterest: {
    method: "GET",
    path: "/activations/:activationId/points-of-interest",
    pathParams: z.object({
      activationId: z.string(),
    }),
    responses: {
      200: ActivationPointsOfInterestSchema,
      404: ApiErrorSchema,
    },
    summary: "Get points of interest of an activation",
  },
});

export type ActivationRequestMember = z.infer<
  typeof ActivationRequestMemberSchema
>;

export type ActivationPointsOfInterest = z.infer<
  typeof ActivationPointsOfInterestSchema
>;
