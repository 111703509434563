import { styled, Theme } from "@mui/material";
import { Link } from "react-router-dom";
import { theme } from "~/components/ui/theme";

const styles = ({ theme }: { theme: Theme }) => ({
  textDecoration: "none",
  display: "flex",
  flex: 1,
  border: `2px solid ${theme.tokens.colors.background.muted}`,
  borderRadius: "8px",
  boxShadow: "0px 0.5px 0.5px rgba(0, 0, 0, 0.1)",
  padding: "12px",
  cursor: "pointer",
});

const RequestCardLink = styled(Link)(styles);
const RequestCardNonLink = styled("div")(styles);

export const RequestCard = (props: {
  to: string;
  onClick?: () => void;
  children: React.ReactNode;
  selected?: boolean;
}) =>
  props.onClick ? (
    <RequestCardNonLink
      {...props}
      sx={{
        border: `2px solid ${
          props.selected
            ? theme.tokens.colors.background.info
            : theme.tokens.colors.background.muted
        }`,
      }}
    />
  ) : (
    <RequestCardLink {...props} />
  );
