import { Button } from "~/components/ui/button";
import { Row } from "~/components/ui/stack";

type FilterPopoverFooterProps = {
  onClose: () => void;
};

export const FilterPopoverFooter = ({ onClose }: FilterPopoverFooterProps) => {
  return (
    <Row
      align="center"
      gap="small"
      paddingTop="large"
      marginTop="large"
      borderTop="standard"
      borderTopWidth="medium"
    >
      <Button variant="secondary" onClick={onClose} fullWidth>
        Close
      </Button>
      <Button type="submit" variant="primary" fullWidth>
        Apply
      </Button>
    </Row>
  );
};
