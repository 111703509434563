import { useState } from "react";
import noAvailabilitySet from "~/assets/noAvailabilitySet.svg";
import { Button } from "~/components/ui/button";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { OnboardingDialog } from "./OnboardingDialog";

type SetHourlyAvailabilityGuideProps = {
  onDone: () => void;
};

export const SetHourlyAvailabilityGuide = ({
  onDone,
}: SetHourlyAvailabilityGuideProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Stack justify="center" align="center" grow={1} padding="medium">
        <Stack gap="medium" align="center" sx={{ maxWidth: 250 }}>
          <img src={noAvailabilitySet} />
          <Text size="large" weight="medium">
            No Availability set
          </Text>
          <Text tone="secondary" align="center">
            You haven’t set your ability yet. To get started, Tap the button
            below to learn how to set your weekly availability
          </Text>

          <Button
            onClick={() => {
              setShowModal(true);
            }}
          >
            Set availability
          </Button>
        </Stack>
      </Stack>
      <OnboardingDialog
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        onDone={() => {
          setShowModal(false);
          onDone();
        }}
      />
    </>
  );
};
