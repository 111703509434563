import { z } from "zod";
import { isValid24HourTime } from "./isValid24HourTime";
import {
  OutOfAreaActivationLevel,
  OutOfAreaActivationRoleCategory,
  OutOfAreaEscalationTimeUnit,
} from "@ses-mams/api-contract";
import { dateAndTime } from "./dateAndTime";

export const OutOfAreaTypeSchema = z.enum(["Urgent", "NotUrgent"]);

export type OutOfAreaType = z.infer<typeof OutOfAreaTypeSchema>;

export const OUT_OF_AREA_ACTIVATION_LEVEL_OPTIONS: OutOfAreaActivationLevel[] =
  [
    "State",
    "InterZone",
    "IntraZone",
    "InterCluster",
    "IntraCluster",
    "InterUnit",
  ];

export const OC_RESTRICTED_OUT_OF_AREA_ACTIVATION_LEVEL_OPTIONS: OutOfAreaActivationLevel[] =
  ["InterCluster", "IntraCluster", "InterUnit"];

export const OUT_OF_AREA_TYPE_OPTIONS = ["Urgent", "NotUrgent"];

export const OUT_OF_AREA_ESCALATION_TIME_UNIT_OPTIONS: OutOfAreaEscalationTimeUnit[] =
  ["Minute", "Hour", "Day", "Week"];

export const OUT_OF_AREA_ACTIVATION_ROLE_CATEGORY_OPTIONS: OutOfAreaActivationRoleCategory[] =
  ["IMT", "Field", "Aviation", "StateOperationsCentre"];

// Have this as a function rather than a constant so that the start/end dates are more accurate to the current time
export const getEmptyDeploymentItem = () => ({
  location: "",
  address: "",
  latitude: 0,
  longitude: 0,
  start: {
    date: new Date(),
    time: "",
  },
  end: {
    date: new Date(),
    time: "",
  },
  roles: [],
});

const deploymentSchema = z
  .object({
    // manual text input
    location: z.string().optional(),
    // address from google search
    address: z.string().optional(),
    latitude: z.number().optional(),
    longitude: z.number().optional(),
    description: z
      .string()
      .trim()
      .max(1000, "The maximum length is 1000 characters")
      .optional(),
    start: dateAndTime({
      date_required_error: "Start date is required",
      time_required_error: "Start time is required",
    }),
    end: dateAndTime({
      date_required_error: "End date is required",
      time_required_error: "End time is required",
    }),
    roles: z
      .array(
        z.object({
          id: z.string(),
          name: z.string(),
          category: z.enum(
            OUT_OF_AREA_ACTIVATION_ROLE_CATEGORY_OPTIONS as [
              OutOfAreaActivationRoleCategory,
            ]
          ),
        })
      )
      .optional(),
  })
  .refine(
    ({ location, address }) => {
      return !!location?.length || !!address?.length;
    },
    {
      message: "Location or address is required",
      path: ["address"],
    }
  )
  .superRefine(({ start, end }, ctx) => {
    if (
      !start?.date ||
      !end?.date ||
      !isValid24HourTime(start?.time) ||
      !isValid24HourTime(end?.time)
    ) {
      return;
    }

    if (start.date >= end.date) {
      ctx.addIssue({
        code: "custom",
        message: "Start time must be before end time",
        path: ["start"],
      });
      ctx.addIssue({
        code: "custom",
        message: "End time must be after start time",
        path: ["end"],
      });
      return;
    }

    if (start.date < new Date()) {
      ctx.addIssue({
        code: "custom",
        message: "Start time cannot be in the past",
        path: ["start"],
      });
    }

    if (end.date < new Date()) {
      ctx.addIssue({
        code: "custom",
        message: "End time cannot be in the past",
        path: ["end"],
      });
    }

    return true;
  });

export const outOfAreaLevelFormSchema = z.object({
  level: z
    .enum(OUT_OF_AREA_ACTIVATION_LEVEL_OPTIONS as [OutOfAreaActivationLevel])
    .nullish(),
  unit: z
    .object({
      name: z.string(),
      id: z.string(),
    })
    .nullish(),
  cluster: z
    .object({
      name: z.string(),
      id: z.string(),
    })
    .nullish(),
  zone: z
    .object({
      name: z.string(),
      id: z.string(),
    })
    .nullish(),
});

export const MINUTES_IN_AN_HOUR = 60;
export const MINUTES_IN_A_DAY = 24 * MINUTES_IN_AN_HOUR;
export const MINUTES_IN_A_WEEK = MINUTES_IN_A_DAY * 7;

export const convertThresholdToMinutes = ({
  threshold,
  unit,
}: {
  threshold?: number;
  unit?: OutOfAreaEscalationTimeUnit;
}) => {
  if (typeof threshold !== "number" || !unit) {
    return undefined;
  }
  switch (unit) {
    case "Minute":
      return threshold;
    case "Hour":
      return threshold * MINUTES_IN_AN_HOUR;
    case "Day":
      return threshold * MINUTES_IN_A_DAY;
    case "Week":
      return threshold * MINUTES_IN_A_WEEK;
  }
};

export const outOfAreaFormSchema = z.intersection(
  outOfAreaLevelFormSchema.superRefine(
    ({ level, unit, cluster, zone }, ctx) => {
      if (!level) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Activation level is required",
          path: ["level"],
        });
      }

      if (level === "InterUnit" && !unit?.id) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Unit is required",
          path: ["unit"],
        });
      }

      if (
        ["InterCluster", "IntraCluster"].includes(level ?? "") &&
        !cluster?.id
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Cluster is required",
          path: ["cluster"],
        });
      }

      if (["InterZone", "IntraZone"].includes(level ?? "") && !zone?.id) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Zone is required",
          path: ["zone"],
        });
      }
    }
  ),
  z.object({
    type: z.enum(OUT_OF_AREA_TYPE_OPTIONS as [OutOfAreaType], {
      errorMap: () => ({ message: "Type is required" }),
    }),
    title: z
      .string({ required_error: "Title is required" })
      .trim()
      .min(1, "Title is required")
      .max(255, "The maximum length is 255 characters"),
    description: z
      .string({ required_error: "Description is required" })
      .trim()
      .min(1, "Description is required")
      .max(1000, "The maximum length is 1000 characters"),
    response: dateAndTime({
      date_required_error: "Response date is required",
      time_required_error: "Response time is required",
    }),
    unitEscalationThreshold: z
      .object({
        time: z.number().optional(),
        unit: z
          .enum(
            OUT_OF_AREA_ESCALATION_TIME_UNIT_OPTIONS as [
              OutOfAreaEscalationTimeUnit,
            ]
          )
          .optional(),
      })
      .nullable()
      .optional()
      .refine(
        value => {
          const minutes = convertThresholdToMinutes({
            threshold: value?.time,
            unit: value?.unit,
          });

          if (typeof minutes === "number" && minutes < 30) {
            return false;
          }

          return true;
        },
        {
          path: ["time"],
          message: "Escalation time can not be less than 30 minutes.",
        }
      ),
    clusterEscalationThreshold: z
      .object({
        time: z.number().optional(),
        unit: z
          .enum(
            OUT_OF_AREA_ESCALATION_TIME_UNIT_OPTIONS as [
              OutOfAreaEscalationTimeUnit,
            ]
          )
          .optional(),
      })
      .nullable()
      .optional()
      .refine(
        value => {
          const minutes = convertThresholdToMinutes({
            threshold: value?.time,
            unit: value?.unit,
          });

          if (typeof minutes === "number" && minutes < 30) {
            return false;
          }

          return true;
        },
        {
          path: ["time"],
          message: "Escalation time can not be less than 30 minutes.",
        }
      ),
    deployments: z
      .array(deploymentSchema)
      .min(1, "At least one deployment is required."),
  })
);

export type OutOfAreaLevelFormSchema = z.infer<typeof outOfAreaLevelFormSchema>;

export type OutOfAreaActivationFormSchema = z.infer<typeof outOfAreaFormSchema>;

export const closeOutOfAreaActivationFormSchema = z
  .object({
    reason: z.string(),
    freeTextReason: z.string().trim().optional(),
  })
  .refine(
    ({ reason, freeTextReason }) => {
      if (reason === "Other" && !freeTextReason) {
        return false;
      }
      return true;
    },
    {
      message: "Reason is required",
      path: ["freeTextReason"],
    }
  );

export type CloseOutOfAreaActivationFormSchema = z.infer<
  typeof closeOutOfAreaActivationFormSchema
>;
