import {
  Availability,
  AvailabilityInput,
  ScheduleItem,
} from "@ses-mams/api-contract";
import {
  formatDateForServer,
  HourlyAvailability,
  ScheduleAvailability,
} from "@ses-mams/react-utils";
import { addDays, addMilliseconds, setHours } from "date-fns";
import { useCallback, useMemo } from "react";
import { useToast } from "~/components/ui/toast";
import { useAuth } from "~/context/auth";
import { tsr } from "~/utils/client";
import { captureException } from "@sentry/react";

type UseMemberAvailabilityDataParams = {
  memberId: string;
  unitId?: string;
  startOfTheWeek: Date;
  endOfTheWeek: Date;
  schedule?: ScheduleItem[];
};

export const useMemberAvailabilityData = ({
  memberId,
  unitId,
  startOfTheWeek,
  endOfTheWeek,
  schedule,
}: UseMemberAvailabilityDataParams) => {
  const { addToast } = useToast();

  const {
    isLoading: isAvailabilityLoading,
    isRefetching,
    data: availabilityData,
    refetch,
  } = tsr.members.getAvailability.useQuery({
    queryKey: [
      "/members/:memberId/units/:unitId/availability",
      memberId,
      unitId,
      startOfTheWeek,
      endOfTheWeek,
    ],
    queryData: {
      query: {
        startDate: formatDateForServer(startOfTheWeek),
        endDate: formatDateForServer(addMilliseconds(endOfTheWeek, 1)),
      },
      params: {
        memberId,
        unitId: unitId as string,
      },
    },
    enabled: Boolean(unitId),
  });

  const { isPending: isMutating, mutateAsync } =
    tsr.members.setAvailabilityV2.useMutation();

  const hourlyAvailability = useHourlyAvailability(
    availabilityData?.body.availability,
    startOfTheWeek,
    endOfTheWeek
  );

  const scheduleAvailability = useScheduleAvailability(
    hourlyAvailability,
    schedule
  );

  const mutate = useCallback(
    async (data: HourlyAvailability) => {
      if (data.length === 0) {
        return;
      }

      const payload: AvailabilityInput[] = [];

      for (const date of data) {
        for (const slot of date.slots) {
          payload.push({
            start: formatDateForServer(setHours(date.date, slot.hour)),
            end: formatDateForServer(setHours(date.date, slot.hour + 1)),
            status: slot.status,
            committed: slot.committed,
            emergenciesOnly:
              slot.status === "ImmediatelyAvailable"
                ? slot.emergenciesOnly
                : null,
            conditionalReason:
              slot.status === "Conditional" ? slot.conditionalReason : null,
          });
        }
      }

      try {
        await mutateAsync({
          params: {
            memberId,
            unitId: unitId as string,
          },
          body: { blocks: payload },
        });
      } catch (error) {
        captureException(error);
        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    [unitId]
  );

  return {
    isLoading: isAvailabilityLoading || isRefetching,
    isMutating,
    hasAvailabilitySet: (availabilityData?.body.totalCount ?? 0) > 0,
    hourlyAvailability,
    scheduleAvailability,
    schedule: schedule ?? [],
    refetch,
    mutate,
  };
};

const useHourlyAvailability = (
  availability: Availability[] | undefined,
  startOfTheWeek: Date,
  endOfTheWeek: Date
) => {
  const hourlyAvailability: HourlyAvailability = useMemo(() => {
    if (!availability) {
      return [];
    }

    const availabilityMap = new Map(
      availability.map(item => [item.start, item])
    );

    const calculatedAvailability: HourlyAvailability = [];
    let currentCalculatedDate = startOfTheWeek;

    while (currentCalculatedDate <= endOfTheWeek) {
      const formattedDate = formatDateForServer(
        currentCalculatedDate
      ).substring(0, 11);

      calculatedAvailability.push({
        date: currentCalculatedDate,
        slots: [...Array(24)].map((_, index) => {
          const slot = availabilityMap.get(
            `${formattedDate}${index.toString().padStart(2, "0")}:00`
          );
          return {
            hour: index,
            status: slot?.status ?? null,
            conditionalReason: slot?.conditionalReason ?? null,
            emergenciesOnly: slot?.emergenciesOnly ?? null,
            committed: slot?.committed ?? false,
          };
        }),
      });

      currentCalculatedDate = addDays(currentCalculatedDate, 1);
    }

    return calculatedAvailability;
  }, [availability, startOfTheWeek, endOfTheWeek]);

  return hourlyAvailability;
};

const useScheduleAvailability = (
  hourlyAvailability: HourlyAvailability,
  schedule: ScheduleItem[] | undefined
) => {
  const scheduleAvailability: ScheduleAvailability = useMemo(() => {
    if (!schedule) {
      return [];
    }

    const calculatedAvailability: ScheduleAvailability = [];

    for (const date of hourlyAvailability) {
      for (const slot of date.slots) {
        const slotScheduleItem = schedule.find(item => {
          const startHour = Number(item.start.substring(0, 2));
          let endHour = Number(item.end.substring(0, 2));
          if (endHour === 0) {
            endHour = 24;
          }

          return slot.hour >= startHour && slot.hour < endHour;
        });

        if (!slotScheduleItem) {
          continue;
        }

        let entry = calculatedAvailability.find(t => t.date === date.date);
        if (!entry) {
          entry = {
            date: date.date,
            scheduleItems: [],
          };

          calculatedAvailability.push(entry);
        }

        let scheduleItem = entry.scheduleItems.find(
          ({ scheduleItem }) => scheduleItem.id === slotScheduleItem.id
        );
        if (!scheduleItem) {
          scheduleItem = {
            scheduleItem: slotScheduleItem,
            slots: [],
          };

          entry.scheduleItems.push(scheduleItem);
        }

        scheduleItem.slots.push({
          hour: slot.hour,
          status: slot.status,
          conditionalReason: slot.conditionalReason,
          emergenciesOnly: slot.emergenciesOnly,
          committed: slot.committed,
        });
      }
    }

    return calculatedAvailability;
  }, [hourlyAvailability, schedule]);

  return scheduleAvailability;
};

export const useOwnAvailabilityData = ({
  startOfTheWeek,
  endOfTheWeek,
  unitId,
}: {
  startOfTheWeek: Date;
  endOfTheWeek: Date;
  unitId?: string;
}) => {
  const { member } = useAuth();

  const { isLoading: isScheduleLoading, data: scheduleData } =
    tsr.schedule.get.useQuery({ queryKey: ["schedule"] });

  const { isLoading, ...rest } = useMemberAvailabilityData({
    memberId: member?.id as string,
    unitId,
    startOfTheWeek,
    endOfTheWeek,
    schedule: scheduleData?.body,
  });

  return {
    ...rest,
    isLoading: isLoading || isScheduleLoading,
  };
};
