import {
  OutOfAreaActivationRoleCategory,
  OutOfAreaActivationRole,
} from "@ses-mams/api-contract";
import {
  formatOutOfAreaActivationRoleCategory,
  useArrayState,
  useGetGroupedOutOfAreaActivationRoles,
} from "@ses-mams/react-utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "~/components/ui/drawer";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { Tabs, Tab } from "~/components/ui/tab";
import { ButtonBase } from "@mui/material";
import { Text } from "~/components/ui/text";
import { Checkbox } from "~/components/ui/checkbox";
import { Button } from "~/components/ui/button";
import { useOutOfAreaActivationRolesQuery } from "~/hooks/useOutOfAreaActivationRolesQuery";

type SelectRolesDrawerProps = {
  roles: OutOfAreaActivationRole[];
  open: boolean;
  onDone: (roles: Array<OutOfAreaActivationRole>) => void;
  onClose: () => void;
};

const TABS: OutOfAreaActivationRoleCategory[] = [
  "IMT",
  "Field",
  "Aviation",
  "StateOperationsCentre",
];

export const SelectRolesDrawer = ({
  open,
  onDone,
  onClose,
  roles,
}: SelectRolesDrawerProps) => {
  const [selectedTab, setSelectedTab] =
    useState<OutOfAreaActivationRoleCategory>("IMT");

  const [internalSelectedRoles, { append, remove, clear, setValue }] =
    useArrayState(roles);

  const { data, isLoading } = useOutOfAreaActivationRolesQuery();

  const getGroupedOutOfAreaActivationRoles =
    useGetGroupedOutOfAreaActivationRoles();

  const rolesByCategory = useMemo(
    () => getGroupedOutOfAreaActivationRoles(data?.body),
    [data?.body]
  );

  const reset = useCallback(() => {
    clear();
    setSelectedTab("IMT");
  }, []);

  const handleCloseClick = useCallback(() => {
    onClose();
    reset();
  }, [onClose]);

  const handleDoneClick = useCallback(() => {
    onDone(internalSelectedRoles);
    reset();
  }, [onDone, internalSelectedRoles]);

  useEffect(() => {
    setValue(roles);
  }, [roles]);

  return (
    <Drawer open={open} onClose={handleCloseClick}>
      <DrawerHeader onClose={handleCloseClick}>Select Roles</DrawerHeader>
      <DrawerContent>
        <Stack direction="column" gap="large" sx={{ height: "100%" }}>
          <Stack gap="medium">
            <Tabs
              value={selectedTab}
              onChange={(_, value) => setSelectedTab(value)}
              variant="scrollable"
              scrollButtons="auto"
            >
              {TABS.map(t => (
                <Tab
                  label={formatOutOfAreaActivationRoleCategory(t)}
                  value={t}
                  key={t}
                />
              ))}
            </Tabs>

            {isLoading ? (
              <Spinner />
            ) : data?.body.length ? (
              <Stack gap="medium">
                {(
                  rolesByCategory?.[
                    selectedTab as OutOfAreaActivationRoleCategory
                  ] ?? []
                ).map(role => {
                  const isChecked = internalSelectedRoles.some(
                    r => r.id === role.id
                  );

                  return (
                    <ButtonBase
                      key={role.id}
                      sx={{
                        width: "100%",
                        ":disabled": {
                          pointerEvents: "auto",
                          cursor: "not-allowed",
                        },
                      }}
                      onClick={() => (isChecked ? remove(role) : append(role))}
                    >
                      <Stack
                        flex={1}
                        direction="row"
                        justify="space-between"
                        gap="small"
                        paddingY="large"
                      >
                        <Text size="standard">{role.name}</Text>
                        <Checkbox checked={isChecked} />
                      </Stack>
                    </ButtonBase>
                  );
                })}
              </Stack>
            ) : (
              <Text>No roles found</Text>
            )}
          </Stack>
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Button fullWidth onClick={handleDoneClick}>
          Done
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
