import { Fragment } from "react";
import { Stack } from "~/components/ui/stack";
import { useAvailabilityReportContext } from "~/context/unit/AvailabilityReportContextProvider";
import { IndividualAvailabilityReport } from "./IndividualAvailabilityReport";

export const IndividualAvailabilityReports = () => {
  const { groups } = useAvailabilityReportContext();

  return (
    <Stack dividers dividerTone="neutral">
      <IndividualAvailabilityReport title="All Members" />

      {groups.map((group, index) => (
        <Fragment key={`individual-availability-report-${index}`}>
          <IndividualAvailabilityReport title={group.name} groupId={group.id} />
        </Fragment>
      ))}
    </Stack>
  );
};
