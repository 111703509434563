import { Menu as MuiMenu, MenuProps as MuiMenuProps } from "@mui/material";

export const Menu = ({ sx, ...props }: MuiMenuProps) => {
  return (
    <MuiMenu
      {...props}
      sx={[
        theme => ({
          ".MuiPaper-root": {
            borderStyle: "solid",
            borderColor: theme.tokens.border.color.standard,
            borderWidth: theme.tokens.border.width.medium,
            borderRadius: theme.tokens.border.radius.large,
          },
          ".MuiMenu-list": {
            padding: 0,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};
