import { useTransformPagedDataToItems } from "@ses-mams/react-utils";
import { tsr } from "~/utils/client";
import { DEFAULT_PAGE_SIZE, getNextPageParam } from "~/utils/pagination";

export const useZoneListSearch = (searchValue: string) => {
  const {
    data,
    isLoading,
    isRefetching,
    refetch,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = tsr.zones.list.useInfiniteQuery({
    queryKey: ["zones-search", searchValue],
    queryData: ({ pageParam }) => ({
      query: {
        skip: pageParam?.skip ?? 0,
        take: pageParam?.take ?? DEFAULT_PAGE_SIZE,
        query: searchValue,
      },
    }),
    initialPageParam: { skip: 0, take: DEFAULT_PAGE_SIZE },
    getNextPageParam,
  });

  const items = useTransformPagedDataToItems(data);

  return {
    data: items,
    isLoading,
    isRefetching,
    refetch,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
};
