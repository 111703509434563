import { InputLabel, styled } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

type LabelProps = {
  id?: string;
  htmlFor?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  children: string;
};

export const Label = ({
  id,
  htmlFor,
  disabled,
  hideLabel,
  children,
}: LabelProps) => {
  return (
    <StyledInputLabel
      id={id}
      shrink
      htmlFor={htmlFor}
      disabled={disabled}
      sx={hideLabel ? visuallyHidden : undefined}
    >
      {children}
    </StyledInputLabel>
  );
};

const StyledInputLabel = styled(InputLabel)(({ theme }) => {
  const { tokens } = theme;

  return {
    ...tokens.typography.text.xlarge,
    color: tokens.colors.foreground.primary,
    fontWeight: tokens.typography.fontWeight.medium,
  };
});
