import { useUpdateEffect } from "@ses-mams/react-utils";
import { useRef } from "react";

export const useCellSelectionStatusChangedEvent = (
  numberOfCellsSelected: number,
  onCellSelectionStatusChanged: (hasSelectedCells: boolean) => void
) => {
  const hasSelectedCells = useRef(false);

  useUpdateEffect(() => {
    const newHasSelectedCells = numberOfCellsSelected > 0;
    if (hasSelectedCells.current !== newHasSelectedCells) {
      onCellSelectionStatusChanged(newHasSelectedCells);
      hasSelectedCells.current = newHasSelectedCells;
    }
  }, [numberOfCellsSelected, onCellSelectionStatusChanged]);
};
