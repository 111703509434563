import { Suggestion } from "@ses-mams/api-contract";

type SearchFilterOptions = {
  capabilityIds: string[];
  unitIds: string[];
  clusterIds: string[];
  zoneIds: string[];
  groupIds: string[];
  contactGroupIds: string[];
};

export const formatSuggestionsToSearchFilters = (suggestions: Suggestion[]) => {
  const filterOptions: SearchFilterOptions = {
    capabilityIds: [],
    unitIds: [],
    clusterIds: [],
    zoneIds: [],
    groupIds: [],
    contactGroupIds: [],
  };

  const suggestionTypeToFilterMap: {
    [type: string]: keyof SearchFilterOptions;
  } = {
    "beacon group": "contactGroupIds",
    capability: "capabilityIds",
    cluster: "clusterIds",
    group: "groupIds",
    unit: "unitIds",
    zone: "zoneIds",
  };

  suggestions.forEach(suggestion => {
    const property = suggestionTypeToFilterMap[suggestion.type];
    if (property) {
      filterOptions[property].push(suggestion.id);
    }
  });

  return filterOptions;
};
