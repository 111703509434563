import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  useTheme,
} from "@mui/material";
import { AvailabilityStatus } from "@ses-mams/api-contract";
import {
  formatAvailabilityStatusLabel,
  statusToBackgroundColor,
} from "@ses-mams/react-utils";
import { Box } from "~/components/ui/box";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

const DEFAULT_AVAILABILITY_STATUSES: Array<AvailabilityStatus> = [
  "ImmediatelyAvailable",
  "Available",
  "Conditional",
  "Unavailable",
];

type ArrayContent<T> = T extends Array<infer R> ? R : T;

type AvailabilityStatusSelectorProps<T extends Array<AvailabilityStatus>> = {
  label?: string;
  options?: T;
  value?: ArrayContent<T> | null;
  onChange: (status: ArrayContent<T>) => void;
  onBlur?: () => void;
};

export const AvailabilityStatusSelector = <
  T extends Array<AvailabilityStatus>,
>({
  label = "Type",
  options: _options,
  value,
  onChange,
  onBlur,
}: AvailabilityStatusSelectorProps<T>) => {
  const options = _options ?? DEFAULT_AVAILABILITY_STATUSES;

  const {
    tokens: { spacing, border },
  } = useTheme();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newStatus: string
  ) => {
    onChange(newStatus as ArrayContent<T>);
  };

  return (
    <FormControl>
      <FormLabel id="availability-status-selector">{label}</FormLabel>
      <RadioGroup
        aria-labelledby="availability-status-selector"
        name="availability-status-selector-radio-group"
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
      >
        {options.map(option => {
          const isChecked = option === value;

          return (
            <FormControlLabel
              key={option}
              value={option}
              control={
                <Radio
                  sx={{
                    display: "none",
                  }}
                />
              }
              label={
                <Stack
                  direction="row"
                  gap="small"
                  align="center"
                  justify="center"
                >
                  <Box
                    borderRadius="full"
                    sx={{
                      width: 12,
                      height: 12,
                      background: statusToBackgroundColor[option],
                    }}
                  />

                  <Text weight="medium" size="large" tone="secondary">
                    {formatAvailabilityStatusLabel({
                      status: option,
                      useShort: true,
                    })}
                  </Text>
                </Stack>
              }
              sx={{
                borderWidth: isChecked ? 2 : 1,
                borderColor: isChecked
                  ? border.color.action
                  : border.color.standard,
                borderStyle: "solid",
                borderRadius: border.radius.large,
                marginTop: spacing.medium,
                marginX: 0,
                padding: spacing.large,
              }}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
