import * as React from "react";

import { createFilledIcon } from "~/components/ui/icon/createIcon";

export const VisibilityOff = createFilledIcon(
  <>
    <path d="M11.8828 9.5H12C12.6771 9.5 13.263 9.7474 13.7578 10.2422C14.2526 10.737 14.5 11.3229 14.5 12V12.1562L11.8828 9.5ZM8.28906 10.1641C7.97656 10.7891 7.82031 11.401 7.82031 12C7.82031 13.1458 8.22396 14.1354 9.03125 14.9688C9.86458 15.776 10.8542 16.1797 12 16.1797C12.599 16.1797 13.2109 16.0234 13.8359 15.7109L12.5469 14.4219C12.3385 14.474 12.1562 14.5 12 14.5C11.3229 14.5 10.737 14.2526 10.2422 13.7578C9.7474 13.263 9.5 12.6771 9.5 12C9.5 11.8438 9.52604 11.6615 9.57812 11.4531L8.28906 10.1641ZM3.67969 5.55469L4.73438 4.5L19.5 19.2656L18.4453 20.3203C18.3151 20.1901 17.8984 19.7865 17.1953 19.1094C16.5182 18.4323 15.9974 17.9115 15.6328 17.5469C14.513 18.0156 13.3021 18.25 12 18.25C9.94271 18.25 8.08073 17.6771 6.41406 16.5312C4.7474 15.3854 3.54948 13.875 2.82031 12C3.10677 11.3229 3.54948 10.5807 4.14844 9.77344C4.77344 8.9401 5.3724 8.30208 5.94531 7.85938C5.63281 7.54688 5.1901 7.10417 4.61719 6.53125C4.07031 5.95833 3.75781 5.63281 3.67969 5.55469ZM12 7.82031C11.4792 7.82031 10.9714 7.92448 10.4766 8.13281L8.67969 6.33594C9.69531 5.94531 10.8021 5.75 12 5.75C14.0573 5.75 15.9062 6.32292 17.5469 7.46875C19.2135 8.61458 20.4115 10.125 21.1406 12C20.5156 13.5365 19.5651 14.8516 18.2891 15.9453L15.8672 13.5234C16.0755 13.0286 16.1797 12.5208 16.1797 12C16.1797 10.8542 15.763 9.8776 14.9297 9.07031C14.1224 8.23698 13.1458 7.82031 12 7.82031Z" />
  </>,
  "VisibilityOff"
);
