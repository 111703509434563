import { formatMemberFullName } from "@ses-mams/react-utils";

import { Heading } from "~/components/ui/heading";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { tsr } from "~/utils/client";
import { DataItem } from "../dataItem";
import { MemberDetailsSection } from "./MemberDetailsSection";
import { EmptyState } from "../emptyState";
import { CapabilityListItem } from "../capability";
import { MemberPhoneNumber } from "./MemberPhoneNumber";
import { Button } from "~/components/ui/button";
import { useAuth } from "~/context/auth";
import { useMemo } from "react";
import { useSendMessageToMember } from "../chat";
import { OrgHierarchy } from "./OrgHierarchy";

type Props = {
  memberId: string;
};

export const MemberDetails = ({ memberId }: Props) => {
  const { member: currentMember } = useAuth();

  const { sendMessage, isLoadingMessageChannel } = useSendMessageToMember();

  const isLoggedInMember = useMemo(
    () => currentMember?.id === memberId,
    [currentMember]
  );

  const availabilityRoute = useMemo(
    () =>
      isLoggedInMember ? "/availability" : `/availability/member/${memberId}`,
    [isLoggedInMember]
  );

  const { data } = tsr.members.get.useQuery({
    queryKey: ["members", memberId],
    queryData: {
      params: { id: memberId },
    },
  });

  if (!data?.body) {
    return <Spinner />;
  }

  const member = data.body;

  return (
    <Stack gap="large" dividers>
      <Stack gap="large">
        <Heading level="2">{formatMemberFullName(member)}</Heading>

        <DataItem title="Preferred name" value={member.preferredName} />

        <DataItem
          title={`Contact number${member.phoneNumbers.length > 1 ? "s" : ""}`}
          value={member.phoneNumbers.map(({ number }) => number)}
          renderItem={number => (
            <MemberPhoneNumber phoneNumber={number} key={number} />
          )}
        />

        <DataItem title="Member number" value={member.id} />

        <DataItem
          title={`Role${member.roles.length > 1 ? "s" : ""}`}
          value={member.roles}
        />

        {!isLoggedInMember && currentMember && (
          <Button
            variant="tertiary"
            busy={isLoadingMessageChannel}
            onClick={() => {
              sendMessage(memberId);
            }}
          >
            Send message
          </Button>
        )}
      </Stack>

      <MemberDetailsSection title="Capabilities">
        {member.capabilities.length > 0 ? (
          member.capabilities.map(capability => (
            <CapabilityListItem capability={capability} key={capability.id} />
          ))
        ) : (
          <EmptyState
            title="No capabilities"
            description="This member has no assigned capabilities."
          />
        )}
      </MemberDetailsSection>

      <OrgHierarchy memberId={memberId} />

      <Button variant="secondary" href={availabilityRoute}>
        Availability
      </Button>
    </Stack>
  );
};
