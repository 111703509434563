import { z } from "zod";

export const conditionalReasonFormSchema = z.object({
  conditionalReason: z
    .string({ required_error: "Conditional reason is required" })
    .trim()
    .min(1, "Conditional reason is required")
    .max(200, "The maximum length is 200 characters"),
});

export type ConditionalReasonFormSchema = z.infer<
  typeof conditionalReasonFormSchema
>;
