import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  ApiErrorSchema,
  PaginationQueryParamsSchema,
  ServerDateSchema,
  MemberSummarySchema,
  UnitSummarySchema,
} from "./common";
import { ActivityScheduleSummarySchema } from "./activitySchedule";

const c = initContract();

export const ActivitySummarySchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  unit: UnitSummarySchema,
  address: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  start: ServerDateSchema,
  end: ServerDateSchema,
  scheduleId: z.string().nullable(),
});

export const ActivitySchema = ActivitySummarySchema.extend({
  initiator: MemberSummarySchema,
  schedule: ActivityScheduleSummarySchema.nullable(),
});

const ActivityAvailabilityStatusSchema = z.enum([
  "Available",
  "Conditional",
  "Unavailable",
]);

const ActivityRequestStatusSchema = z.enum([
  "AvailabilityRequested",
  "AvailabilitySubmitted",
  "AttendanceConfirmed",
  "Removed",
]);

export const ActivityRequestDetailsSchema = z.object({
  id: z.string(),
  availabilityStatus: ActivityAvailabilityStatusSchema.nullable(),
  conditionalReason: z.string().nullable(),
  status: ActivityRequestStatusSchema,
});

export const ActivityRequestSchema = z.object({
  request: ActivityRequestDetailsSchema.nullable(),
  activity: ActivitySchema,
  archived: z.boolean(),
  canRespond: z.boolean(),
});

const ActivityRequestWithAvailabilityCountSchema = z.object({
  id: z.string().nullable(),
  activity: ActivitySummarySchema.extend({
    schedule: ActivityScheduleSummarySchema.nullable(),
  }),
  archived: z.boolean(),
  status: ActivityRequestStatusSchema.nullable(),
  availabilityCounts: z.object({
    confirmed: z.number(),
    available: z.number(),
    unavailable: z.number(),
    conditional: z.number(),
    unset: z.number(),
  }),
});

const AvailabilityStatusUpdateRequestSchema = z.object({
  availabilityStatus: ActivityAvailabilityStatusSchema.nullish(),
  conditionalReason: z.string().nullish(),
  force: z.boolean().optional(),
});

export const activityRequestContract = c.router({
  get: {
    method: "GET",
    path: "/activity-requests/:activityId",
    pathParams: z.object({
      activityId: z.string(),
    }),
    responses: {
      200: ActivityRequestSchema,
      404: ApiErrorSchema,
      422: ApiErrorSchema,
    },
    summary:
      "Get the details of an Activity. If the current member has been added to the activity, return the details of the corresponding ActivityRequest",
  },
  list: {
    method: "GET",
    path: "/activity-requests",
    query: PaginationQueryParamsSchema.extend({
      startDate: ServerDateSchema.optional(),
      endDate: ServerDateSchema.optional(),
      query: z.coerce.string().trim().optional(),
      archived: z.boolean().optional(),
      unitId: z.coerce.string().optional(),
    }),
    responses: {
      200: z.object({
        items: z.array(ActivityRequestWithAvailabilityCountSchema),
      }),
    },
    summary: "Find/List activity requests with availability counts",
  },
  updateAvailabilityStatus: {
    method: "POST",
    path: "/activity-requests/:activityRequestId/availabilityStatus",
    pathParams: z.object({
      activityRequestId: z.string(),
    }),
    body: AvailabilityStatusUpdateRequestSchema,
    responses: {
      200: ActivityRequestSchema,
      400: ApiErrorSchema,
    },
    summary: "Update the availability status of an activity request",
  },
});

export type Activity = z.infer<typeof ActivitySchema>;
export type ActivitySummary = z.infer<typeof ActivitySummarySchema>;
export type ActivityRequest = z.infer<typeof ActivityRequestSchema>;

export type ActivityRequestWithAvailabilityCount = z.infer<
  typeof ActivityRequestWithAvailabilityCountSchema
>;

export type ActivityRequestStatus = z.infer<typeof ActivityRequestStatusSchema>;

export type ActivityAvailabilityStatus = z.infer<
  typeof ActivityAvailabilityStatusSchema
>;

export type ActivityRequestDetails = z.infer<
  typeof ActivityRequestDetailsSchema
>;
