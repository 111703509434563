import { ActivityForm } from "./ActivityForm";
import { useCallback, useState } from "react";
import { ContactGroup, Group, Member } from "@ses-mams/api-contract";
import { ActivityFormSchema } from "@ses-mams/validation";
import { transformActivityFormForCreate, useActivityForm } from "../../hooks";
import { tsr } from "~/utils/client";
import { useNavigate } from "react-router-dom";
import { EditMemberList } from "~/components/common/editMemberList";
import { FormProvider } from "react-hook-form";
import { SelectAllMembersQuery } from "@ses-mams/react-utils";
import { EditGroupList } from "~/components/common/editGroupList";

export const AddActivityForm = () => {
  const navigate = useNavigate();

  const [members, setMembers] = useState<Array<Member>>([]);
  const [groups, setGroups] = useState<Array<Group>>([]);
  const [contactGroups, setContactGroups] = useState<Array<ContactGroup>>([]);
  const [selectAllMembersQuery, setSelectAllMembersQuery] =
    useState<SelectAllMembersQuery>();

  const formMethods = useActivityForm({});
  const { watch } = formMethods;

  const selectedUnit = watch("unit");

  const { mutateAsync } = tsr.activities.create.useMutation();

  const onSubmit = useCallback(
    async (formData: ActivityFormSchema) => {
      await mutateAsync({
        body: transformActivityFormForCreate(
          formData,
          members,
          groups,
          contactGroups,
          selectAllMembersQuery
        ),
      });

      navigate("/requests?tab=activity");
    },
    [members, groups, contactGroups]
  );

  const handleChangeSelectedGroups = useCallback(
    (nextGroups: Array<Group>, nextContactGroups: Array<ContactGroup>) => {
      setGroups(nextGroups);
      setContactGroups(nextContactGroups);
    },
    []
  );

  return (
    <FormProvider {...formMethods}>
      <ActivityForm isEditing={false} onSubmit={onSubmit}>
        <EditGroupList
          selectedGroups={groups}
          selectedContactGroups={contactGroups}
          unitId={selectedUnit.id}
          onChangeSelectedGroups={handleChangeSelectedGroups}
        />
        <EditMemberList
          showAvailabilityStatus
          selectedMembers={members}
          onChangeSelectedMembers={setMembers}
          isLoadingMembers={false}
          unitId={selectedUnit.id}
          selectAllMembersQuery={selectAllMembersQuery}
          setSelectAllMembersQuery={setSelectAllMembersQuery}
        />
      </ActivityForm>
    </FormProvider>
  );
};
