import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  ApiErrorSchema,
  CapabilitySchema,
  getPaginationResponseSchema,
  MemberSummarySchema,
  PaginationQueryParamsSchema,
} from "./common";
import { RoleSchema } from "./members";

const c = initContract();

const MemberAdminSummarySchema = MemberSummarySchema.extend({
  isActive: z.boolean(),
  capabilities: z.array(CapabilitySchema),
  syncedRoles: z.array(RoleSchema),
  rolesOverride: z.array(RoleSchema).nullable(),
});

const adminContract = c.router({
  listMembers: {
    method: "GET",
    path: "/admin/members",
    query: PaginationQueryParamsSchema.merge(
      z.object({
        query: z.coerce.string().trim().optional(),
      })
    ),
    responses: {
      200: getPaginationResponseSchema(MemberAdminSummarySchema),
      403: ApiErrorSchema,
    },
    summary: "Get all members based on optional parameters",
  },
  overrideRoles: {
    method: "PATCH",
    path: `/admin/members/:memberId/override-roles`,
    body: z.object({
      roles: z.array(RoleSchema).nullable(),
    }),
    responses: {
      200: MemberAdminSummarySchema,
      403: ApiErrorSchema,
      404: ApiErrorSchema,
    },
    summary: "Set or clear the overridden roles for a member",
  },
});

export type MemberAdminSummary = z.infer<typeof MemberAdminSummarySchema>;

export { adminContract };
