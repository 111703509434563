import { useTransformPagedDataToItems } from "@ses-mams/react-utils";
import { tsr } from "~/utils/client";
import { DEFAULT_PAGE_SIZE, getNextPageParam } from "~/utils/pagination";

export const useActivityScheduleMembersData = (scheduleId: string) => {
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
    tsr.activitySchedules.getMembers.useInfiniteQuery({
      queryKey: ["activity-schedules", scheduleId, "members"],
      queryData: ({ pageParam }) => ({
        params: {
          activityScheduleId: scheduleId,
        },
        query: {
          skip: pageParam?.skip ?? 0,
          take: pageParam?.take ?? DEFAULT_PAGE_SIZE,
        },
      }),
      initialPageParam: { skip: 0, take: DEFAULT_PAGE_SIZE },
      getNextPageParam,
    });

  const items = useTransformPagedDataToItems(data);

  return {
    isLoading,
    data: items,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  };
};
