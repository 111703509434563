import { Group } from "@ses-mams/api-contract";
import { useArrayState } from "@ses-mams/react-utils";
import { useCallback } from "react";
import { Button } from "~/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerProps,
} from "~/components/ui/drawer";
import { SelectGroupsList } from "./SelectGroupsList";

type SelectGroupsDrawerProps = Omit<DrawerProps, "children"> & {
  groups: Array<Group>;
  onDone: (groups: Array<Group>) => void;
};

export const SelectGroupsDrawer = ({
  open,
  onClose,
  onDone,
  groups,
}: SelectGroupsDrawerProps) => {
  const [selectedGroups, { append, remove, setValue }] = useArrayState(groups);

  const handleOnClose = useCallback(() => {
    setValue([...groups]);

    onClose();
  }, [groups, onClose]);

  const handleDoneClick = useCallback(() => {
    onDone(selectedGroups);
    onClose();
  }, [onDone, selectedGroups]);

  return (
    <Drawer open={open} onClose={handleOnClose}>
      <DrawerHeader onClose={handleOnClose}>Select Groups</DrawerHeader>

      <DrawerContent>
        <SelectGroupsList
          groups={selectedGroups}
          appendGroup={append}
          removeGroup={remove}
        />
      </DrawerContent>

      <DrawerFooter>
        <Button fullWidth onClick={handleDoneClick}>
          Done
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
