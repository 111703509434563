import { useCallback } from "react";
import { ActivityMember, Member } from "@ses-mams/api-contract";
import emptyListImageUrl from "~/assets/emptyList.png";
import { MemberListItem } from "~/components/common/searchMembersList";
import {
  InfiniteSectionList,
  RenderSectionHeaderParams,
} from "~/components/ui/list";
import { Box } from "~/components/ui/box";
import { EmptyState } from "~/components/common/emptyState";
import { useActivityMembersData } from "./useActivityMembersData";

type ActivityMemberListProps = {
  activityId: string;
  renderItem?: (member: ActivityMember) => React.ReactElement;
  renderSectionHeader: (
    params: RenderSectionHeaderParams<ActivityMember>
  ) => React.ReactElement;
};

export const ActivityMemberList = ({
  activityId,
  renderItem,
  renderSectionHeader,
}: ActivityMemberListProps) => {
  const { sections, isLoading, isRefetching, hasNextPage, fetchNextPage } =
    useActivityMembersData(activityId);

  const handleRenderItem = useCallback(
    (item: ActivityMember) => {
      if (renderItem) {
        return renderItem(item);
      }

      return <MemberListItem member={item.member as Member} />;
    },
    [renderItem]
  );

  const isRefreshing = isLoading || isRefetching;

  return (
    <InfiniteSectionList
      sections={sections}
      renderItem={handleRenderItem}
      renderSectionHeader={renderSectionHeader}
      keyExtractor={item => item.id}
      listEmptyElement={
        isRefreshing ? null : (
          <Box display="flex" justify="center" sx={{ height: "100%" }}>
            <EmptyState
              image={<img src={emptyListImageUrl} />}
              title="No members"
              description="There are no members."
            />
          </Box>
        )
      }
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
    />
  );
};
