import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { BackButton } from "~/components/common/navigation";
import { Stack } from "~/components/ui/stack";
import { Box } from "~/components/ui/box";
import { Spinner } from "~/components/ui/spinner";
import { EditActivityScheduleForm } from "./components";
import { useGetActivityScheduleQuery } from "../hooks";

export const EditActivitySchedulePage = () => {
  const scheduleId = useParams().scheduleId as string;

  const { data, isLoading } = useGetActivityScheduleQuery(scheduleId);

  const activitySchedule = data?.body;

  return (
    <Container maxWidth="sm">
      <Stack gap="xlarge">
        <BackButton />

        {isLoading || !activitySchedule ? (
          <Box display="flex" grow={1} justify="center">
            <Spinner />
          </Box>
        ) : (
          <EditActivityScheduleForm activitySchedule={activitySchedule} />
        )}
      </Stack>
    </Container>
  );
};
