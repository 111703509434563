import { forwardRef, useCallback } from "react";
import { TextField, TextFieldProps } from "~/components/ui/textField";

export type IntegerFieldProps = Omit<TextFieldProps, "onChange" | "value"> & {
  value?: number;
  onValueChange: (value?: number) => void;
};

export const IntegerField = forwardRef<HTMLInputElement, IntegerFieldProps>(
  ({ onValueChange, value, ...props }, forwardedRef) => {
    const handleChangeText = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value.replace(/[^0-9]/g, "");

        onValueChange(val ? parseInt(val, 10) : undefined);
      },
      [onValueChange]
    );

    return (
      <TextField
        ref={forwardedRef}
        {...props}
        value={typeof value === "number" ? value.toString() : ""}
        onChange={handleChangeText}
      />
    );
  }
);
