import { endOfDay, startOfDay } from "date-fns";
import { Control, Controller, useWatch } from "react-hook-form";
import type { AvailabilityBlocksFormSchema } from "@ses-mams/validation";

import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { Button } from "~/components/ui/button";
import { DateField } from "~/components/ui/dateField";
import { HelperText, TextField } from "~/components/ui/textField";
import { AvailabilityStatusSelector } from "~/components/common/availabilitySelector";

type AvailabilityBlockSectionProps = {
  index: number;
  control: Control<AvailabilityBlocksFormSchema>;
  triggerTimeValidation: (index: number) => void;
  onDeletePress?: () => void;
};

export const AvailabilityBlockSection = ({
  index,
  control,
  onDeletePress,
  triggerTimeValidation,
}: AvailabilityBlockSectionProps) => {
  const watchAvailabilityStatus = useWatch({
    control,
    name: `availabilityBlocks.${index}.availabilityStatus`,
  });

  return (
    <Stack gap="medium">
      <Stack direction="row" justify="space-between">
        <Text size="large" weight="semi" tone="secondary">
          {`Block ${index + 1}`}
        </Text>

        {onDeletePress && (
          <Button variant="destructive" onClick={onDeletePress}>
            Delete
          </Button>
        )}
      </Stack>

      <Stack direction="row" gap="large" align="start">
        <Stack flex={1}>
          <Controller
            name={`availabilityBlocks.${index}.start`}
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState }) => (
              <DateField
                label="Start Date"
                value={value}
                onChange={v => onChange(v ? startOfDay(v) : v)}
                onBlur={() => {
                  onBlur();
                  triggerTimeValidation(index);
                }}
                errorMessage={fieldState.error?.message}
              />
            )}
          />
        </Stack>

        <Stack flex={1}>
          <Controller
            name={`availabilityBlocks.${index}.end`}
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState }) => (
              <DateField
                label="End Date"
                value={value}
                onChange={v => onChange(v ? endOfDay(v) : v)}
                onBlur={() => {
                  onBlur();
                  triggerTimeValidation(index);
                }}
                errorMessage={fieldState.error?.message}
              />
            )}
          />
        </Stack>
      </Stack>

      <Controller
        name={`availabilityBlocks.${index}.availabilityStatus`}
        control={control}
        render={({ field: { onChange, value }, fieldState }) => (
          <Stack gap="xxsmall">
            <AvailabilityStatusSelector
              label="Availability status"
              value={value ?? null}
              onChange={onChange}
              options={["Available", "Conditional", "Unavailable"]}
            />

            {fieldState.error?.message ? (
              <HelperText>{fieldState.error.message}</HelperText>
            ) : null}
          </Stack>
        )}
      />

      {watchAvailabilityStatus === "Conditional" && (
        <Controller
          name={`availabilityBlocks.${index}.conditionalReason`}
          control={control}
          rules={{ required: "Conditional reason is required" }}
          render={({ field: { onChange, onBlur, value }, fieldState }) => (
            <TextField
              multiline
              rows={3}
              label="Conditional reason"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              errorMessage={fieldState.error?.message}
            />
          )}
        />
      )}
    </Stack>
  );
};
