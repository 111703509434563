export const getHoursAndMinutesFromTimeString = (time: string) => {
  // Split the input into hours and minutes
  const [hours, minutes] = time.split(":");

  // Convert hours and minutes to integers
  const hoursInt = parseInt(hours ?? "", 10);
  const minutesInt = parseInt(minutes ?? "", 10);

  if (
    hoursInt >= 24 ||
    minutesInt >= 60 ||
    isNaN(hoursInt) ||
    isNaN(minutesInt)
  ) {
    return {
      hours: 0,
      minutes: 0,
    };
  }

  return {
    hours: hoursInt,
    minutes: minutesInt,
  };
};
