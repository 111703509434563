import { ContactGroup, Group } from "@ses-mams/api-contract";
import { ChannelFormSchema } from "@ses-mams/validation";
import { useCallback, useState } from "react";
import { Control, useFieldArray } from "react-hook-form";
import { GroupListItem } from "~/components/common/group";
import { ContactGroupListItem } from "~/components/common/contactGroup";
import { EditListSection } from "~/components/common/editListSection";
import { EditUnitGroupsDrawer } from "./EditUnitGroupsDrawer";

type EditChannelGroupsListProps = {
  control: Control<ChannelFormSchema>;
};

export const EditChannelGroupsList = ({
  control,
}: EditChannelGroupsListProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { fields: groupFields, replace: replaceGroups } = useFieldArray({
    control,
    name: "groups",
    keyName: "fieldId",
  });

  const { fields: contactGroupFields, replace: replaceContactGroups } =
    useFieldArray({
      control,
      name: "contactGroups",
      keyName: "fieldId",
    });

  const handleDoneClick = useCallback(
    (groups: Array<Group>, contactGroups: Array<ContactGroup>) => {
      replaceGroups(groups);
      replaceContactGroups(contactGroups);

      setIsDrawerOpen(false);
    },
    []
  );

  return (
    <>
      <EditListSection
        title="Groups"
        onButtonClick={() => setIsDrawerOpen(true)}
      >
        {groupFields.map(group => (
          <GroupListItem key={group.id} group={group} />
        ))}
        {contactGroupFields.map(contactGroup => (
          <ContactGroupListItem
            key={contactGroup.id}
            contactGroup={contactGroup}
          />
        ))}
      </EditListSection>

      <EditUnitGroupsDrawer
        open={isDrawerOpen}
        groups={groupFields}
        contactGroups={contactGroupFields}
        onClose={() => setIsDrawerOpen(false)}
        onDone={handleDoneClick}
      />
    </>
  );
};
