import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { SelectField, SelectItem } from "~/components/ui/selectField";
import { OutOfAreaLevelFormSchema } from "@ses-mams/validation";
import { Stack } from "~/components/ui/stack";
import {
  formatOutOfAreaActivationLevel,
  useUpdateEffect,
} from "@ses-mams/react-utils";
import { AllUnitsSelector } from "~/components/common/allUnitsSelector";
import { ClusterSelector } from "~/components/common/clusterSelector";
import { ZoneSelector } from "~/components/common/zoneSelector";
import { OutOfAreaActivationLevel } from "@ses-mams/api-contract";

type OutOfAreaActivationLevelFormProps<T extends OutOfAreaLevelFormSchema> = {
  control: Control<T>;
  setValue: UseFormSetValue<T>;
  disabled?: boolean;
  levelOptions: OutOfAreaActivationLevel[];
};

export const OutOfAreaActivationLevelForm = <
  T extends OutOfAreaLevelFormSchema,
>({
  control: _control,
  setValue: _setValue,
  disabled,
  levelOptions,
}: OutOfAreaActivationLevelFormProps<T>) => {
  const control = _control as Control<OutOfAreaLevelFormSchema>;
  const setValue = _setValue as UseFormSetValue<OutOfAreaLevelFormSchema>;

  const watchLevel = useWatch({
    control,
    name: "level",
  });

  useUpdateEffect(() => {
    // reset unit, cluster and zone value if activation level is updated
    setValue("unit", undefined);
    setValue("cluster", undefined);
    setValue("zone", undefined);
  }, [watchLevel]);

  return (
    <Stack gap="xlarge">
      <Controller
        name="level"
        control={control}
        render={({ field: { value, onChange }, fieldState }) => (
          <SelectField
            label="Level of request"
            value={value}
            onChange={onChange}
            errorMessage={fieldState.error?.message}
            disabled={disabled}
          >
            {levelOptions.map(level => (
              <SelectItem key={level} value={level}>
                {formatOutOfAreaActivationLevel(level)}
              </SelectItem>
            ))}
          </SelectField>
        )}
      />

      {watchLevel === "InterUnit" && (
        <Controller
          name="unit"
          control={control}
          render={({ field: { value, onChange }, fieldState }) => (
            <AllUnitsSelector
              label="Activate for"
              selectedUnitId={value?.id}
              setSelectedUnit={onChange}
              errorMessage={fieldState.error?.message}
              disabled={disabled}
            />
          )}
        />
      )}

      {["InterCluster", "IntraCluster"].includes(watchLevel ?? "") && (
        <Controller
          name="cluster"
          control={control}
          render={({ field: { value, onChange }, fieldState }) => (
            <ClusterSelector
              label="Activate for"
              selectedClusterId={value?.id}
              setSelectedCluster={onChange}
              errorMessage={fieldState.error?.message}
              disabled={disabled}
            />
          )}
        />
      )}

      {["InterZone", "IntraZone"].includes(watchLevel ?? "") && (
        <Controller
          name="zone"
          control={control}
          render={({ field: { value, onChange }, fieldState }) => (
            <ZoneSelector
              label="Activate for"
              selectedZoneId={value?.id}
              setSelectedZone={onChange}
              errorMessage={fieldState.error?.message}
              disabled={disabled}
            />
          )}
        />
      )}
    </Stack>
  );
};
