import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import { tsr } from "~/utils/client";
import { useAuth } from "../auth";

type UnreadBroadcastMessagesContextType = {
  unreadMessageCount: number;
  isLoading: boolean;
};

const UnreadBroadcastMessagesContext =
  createContext<UnreadBroadcastMessagesContextType | null>(null);

export const UnreadBroadcastMessagesContextProvider = ({
  children,
}: PropsWithChildren) => {
  const { member } = useAuth();

  const { data, isLoading } =
    tsr.members.getUnacknowledgedBroadcastMessageCount.useQuery({
      queryKey: ["unacknowledged-member-broadcasts", { memberId: member?.id }],
      queryData: {
        query: {
          platform: "web",
        },
      },
      // 5 minutes
      refetchInterval: 300000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: true,
      enabled: !!member,
    });

  return (
    <UnreadBroadcastMessagesContext.Provider
      value={useMemo(
        () => ({
          unreadMessageCount: data?.body?.totalCount ?? 0,
          isLoading,
        }),
        [data?.body, isLoading]
      )}
    >
      {children}
    </UnreadBroadcastMessagesContext.Provider>
  );
};

export const useUnreadBroadcastMessages = () => {
  const context = useContext(UnreadBroadcastMessagesContext);

  if (!context) {
    throw new Error(
      "useUnreadBroadcastMessages must be wrapped with <UnreadBroadcastMessagesContextProvider />"
    );
  }

  return context;
};
