import { Controller, useFormContext } from "react-hook-form";
import { EventFormSchema } from "@ses-mams/validation";
import { AllUnitsSelector } from "~/components/common/allUnitsSelector";

type Props = {
  label: string;
  disabled?: boolean;
};

export const UnitField = ({ label, disabled }: Props) => {
  const { control } = useFormContext<EventFormSchema>();

  return (
    <Controller
      name="unit"
      control={control}
      render={({ field: { value, onChange } }) => (
        <AllUnitsSelector
          label={label}
          selectedUnitId={value.id}
          setSelectedUnit={onChange}
          disabled={disabled}
        />
      )}
    />
  );
};
