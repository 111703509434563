import {
  getNextPageParam as baseGetNextPageParam,
  PaginatedBody,
  PaginatedPage,
} from "@ses-mams/react-utils";

export const DEFAULT_PAGE_SIZE = 20;

export const LARGE_PAGE_SIZE = 100;

export const getNextPageParam = <
  T,
  TBody extends PaginatedBody<T>,
  TPage extends PaginatedPage<TBody>,
>(
  lastPage: TPage,
  allPages: TPage[]
) => baseGetNextPageParam(lastPage, allPages, DEFAULT_PAGE_SIZE);

export const getNextLargePageParam = <
  T,
  TBody extends PaginatedBody<T>,
  TPage extends PaginatedPage<TBody>,
>(
  lastPage: TPage,
  allPages: TPage[]
) => baseGetNextPageParam(lastPage, allPages, LARGE_PAGE_SIZE);
