import { forwardRef } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  useTheme,
} from "@mui/material";
import { ForegroundTones } from "@ses-mams/react-utils";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { IconProps } from "~/components/ui/icon";

type ButtonSize = "standard" | "small";

export type ButtonProps = Pick<
  MuiButtonProps,
  "children" | "disabled" | "href" | "onClick" | "type" | "fullWidth" | "rel"
> &
  Pick<RouterLinkProps, "target"> & {
    busy?: boolean;
    variant?: "primary" | "secondary" | "tertiary" | "link" | "destructive";
    startIcon?: React.ComponentType<IconProps>;
    size?: ButtonSize;
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = "primary",
      disabled,
      children,
      busy,
      startIcon: StartIcon,
      size = "standard",
      ...props
    },
    forwardedRef
  ) => {
    const foregroundTone = variantToForeground[variant];
    const variantStyleProps = useButtonStyles(variant, foregroundTone);

    return (
      <MuiButton
        {...props}
        {...variantStyleProps}
        ref={forwardedRef}
        LinkComponent={RouterLinkForwarder}
        disableElevation
        disableRipple
        disabled={disabled || busy}
        aria-busy={busy}
        size={size === "standard" ? "medium" : "small"}
      >
        {busy && <Spinner size={18} sx={{ position: "absolute" }} />}

        <Stack
          direction="row"
          align="center"
          gap="small"
          sx={busy ? { opacity: 0 } : undefined}
        >
          {StartIcon && <StartIcon size="xxsmall" tone={foregroundTone} />}

          {children}
        </Stack>
      </MuiButton>
    );
  }
);

const useButtonStyles = (
  variant: ButtonProps["variant"],
  foregroundTone: ForegroundTones
): MuiButtonProps => {
  const {
    tokens: {
      colors: { background, backgroundInteraction, foreground },
      spacing,
      border,
    },
  } = useTheme();

  const commonSxProps = {
    outline: "2px solid transparent",
    outlineOffset: "2px",
    borderStyle: "solid",
    borderRadius: border.radius.large,
    borderWidth: border.width.medium,
    textTransform: "unset",
    color: foreground[foregroundTone],

    ":focus-visible": {
      outlineColor: border.color.action,
    },
  } as const;

  switch (variant) {
    case "primary":
      return {
        variant: "contained",
        sx: {
          ...commonSxProps,
          backgroundColor: background.info,
          borderColor: "transparent",
          ":hover": {
            backgroundColor: backgroundInteraction.primaryHover,
          },
          ":active": {
            backgroundColor: backgroundInteraction.primaryActive,
          },
        },
      };

    case "secondary":
      return {
        variant: "outlined",
        sx: {
          ...commonSxProps,
          borderColor: border.color.standard,
          ":hover": {
            borderColor: border.color.field,
            backgroundColor: backgroundInteraction.secondaryHover,
          },
          ":active": {
            borderColor: border.color.action,
            backgroundColor: backgroundInteraction.secondaryActive,
          },
        },
      };

    case "tertiary":
      return {
        variant: "contained",
        sx: {
          ...commonSxProps,
          borderColor: "transparent",
          backgroundColor: background.infoMuted,
          ":hover": {
            backgroundColor: backgroundInteraction.tertiaryHover,
          },
          ":active": {
            backgroundColor: backgroundInteraction.tertiaryActive,
          },
        },
      };

    case "link":
      return {
        variant: "contained",
        sx: {
          ...commonSxProps,
          backgroundColor: background.surface,
          borderColor: "transparent",
          ":hover": {
            backgroundColor: backgroundInteraction.tertiaryHover,
          },
          ":active": {
            backgroundColor: backgroundInteraction.tertiaryActive,
          },
          "&.MuiButtonBase-root": {
            padding: spacing.xxsmall,
          },
          ":disabled": {
            backgroundColor: "transparent",
          },
        },
      };

    case "destructive":
    default:
      return {
        variant: "outlined",
        sx: {
          ...commonSxProps,
          borderColor: border.color.standard,
          ":hover": {
            borderColor: border.color.field,
            backgroundColor: backgroundInteraction.destructiveHover,
          },
          ":active": {
            borderColor: border.color.critical,
            backgroundColor: backgroundInteraction.destructiveActive,
          },
        },
      };
  }
};

const variantToForeground = {
  primary: "inverted",
  secondary: "primary",
  tertiary: "action",
  destructive: "critical",
  link: "action",
} as const;

const RouterLinkForwarder = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => <RouterLink {...props} ref={ref} to={props.href} />);
