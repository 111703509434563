import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import { ActivityMember } from "@ses-mams/api-contract";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { tsr } from "~/utils/client";
import { Button } from "~/components/ui/button";
import { BackButton } from "~/components/common/navigation";
import { RenderSectionHeaderParams } from "~/components/ui/list";
import { useAuth, useHasAnyRole } from "~/context/auth";
import {
  InitiatorLabelValueDrawer,
  LabelValue,
  NavigateToButton,
} from "../shared";
import {
  ActivityMemberList,
  ActivityRequestResponsesSection,
  ArchiveActivityButton,
  ManageActivityMembersDrawer,
  RemoveButton,
} from "./components";
import { formatDateTime } from "@ses-mams/react-utils";

export const ActivityDetailPage = () => {
  const params = useParams();
  const activityId = params.requestId as string;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const hasCoordinatorRole = useHasAnyRole(["ActivityCoordinator"]);
  const { member } = useAuth();

  const {
    data: activityRequestReponse,
    isLoading: isLoadingActivationRequest,
  } = tsr.activityRequests.get.useQuery({
    queryKey: ["activity-requests", "details", activityId],
    queryData: {
      params: {
        activityId,
      },
    },
  });

  const activityRequest = activityRequestReponse?.body || null;

  const renderSectionHeader = useCallback(
    ({ title }: RenderSectionHeaderParams<ActivityMember>) => (
      <Text size="standard" weight="medium" tone="muted">
        {title}
      </Text>
    ),
    []
  );

  if (isLoadingActivationRequest || !activityRequest) {
    return <Spinner />;
  }

  const {
    title,
    address,
    description,
    start,
    end,
    unit,
    initiator,
    longitude,
    latitude,
    scheduleId,
    schedule,
  } = activityRequest.activity;
  const isArchived = activityRequest.archived;
  const isInitiator = !!member && initiator.id === member.id;
  const canMessage = !!activityRequest.request?.id || isInitiator;
  const canRemove =
    !!activityRequest.request?.id &&
    activityRequest.request.status !== "Removed" &&
    !isArchived;
  const canArchive = hasCoordinatorRole && !isArchived;

  const showViewRecurringInfo = scheduleId && schedule && !schedule?.closedAt;

  return (
    <>
      <Container maxWidth="md">
        <Stack gap="xlarge">
          <BackButton />

          <Stack direction="row" justify="space-between">
            <Stack>
              <Text size="xlarge" weight="semi">
                {title}
              </Text>
              <Text size="xlarge" weight="semi">
                {address}
              </Text>
            </Stack>
            {hasCoordinatorRole && (
              <Stack>
                <Button href="edit">Edit</Button>
              </Stack>
            )}
          </Stack>

          <Stack dividers gap="xlarge">
            <ActivityRequestResponsesSection
              activityRequest={activityRequest}
            />

            <Stack gap="xlarge">
              <LabelValue label="Description" value={description} />
              <Stack
                direction={{ xs: "column", md: "row" }}
                gap="xlarge"
                justify="space-between"
              >
                <LabelValue
                  label="Start time"
                  value={formatDateTime(new Date(start))}
                />
                <LabelValue
                  label="End time"
                  value={formatDateTime(new Date(end))}
                />
              </Stack>
              {initiator && <InitiatorLabelValueDrawer initiator={initiator} />}
            </Stack>

            <Stack direction={{ xs: "column", md: "row" }} gap="small">
              <NavigateToButton
                address={address}
                coordinate={{
                  longitude: longitude as number,
                  latitude: latitude as number,
                }}
              />
              <Button variant="tertiary" fullWidth href="map">
                Map
              </Button>
              <Button
                variant="tertiary"
                fullWidth
                onClick={() => setIsDrawerOpen(true)}
              >
                Members
              </Button>
              {canMessage && (
                <Button
                  variant="tertiary"
                  fullWidth
                  href={`/messages?channelId=${activityId}`}
                >
                  Messages
                </Button>
              )}
            </Stack>

            {showViewRecurringInfo && (
              <Stack alignSelf={{ sm: "unset", md: "start" }}>
                <Button
                  variant="tertiary"
                  fullWidth
                  href={`/requests/activity-schedules/${scheduleId}`}
                >
                  View Recurring Information
                </Button>
              </Stack>
            )}

            {(canRemove || canArchive) && (
              <Stack
                align={{ xs: "stretch", md: "start" }}
                direction={{ xs: "column", md: "row" }}
                gap="small"
              >
                {canRemove && (
                  <RemoveButton
                    activityId={activityId}
                    memberId={member?.id as string}
                  />
                )}

                {canArchive && (
                  <ArchiveActivityButton activityId={activityId} />
                )}
              </Stack>
            )}

            <Stack gap="medium">
              <Text size="xlarge" weight="medium">
                Members
              </Text>
              <ActivityMemberList
                activityId={activityId}
                renderSectionHeader={renderSectionHeader}
              />
            </Stack>
          </Stack>
        </Stack>
      </Container>

      <ManageActivityMembersDrawer
        activityId={activityId}
        activityStart={start}
        isClosed={isArchived}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        unitId={unit.id}
      />
    </>
  );
};
