import { useNavigate } from "react-router-dom";
import { useDeactivateMembers } from "../../hooks";
import { Button } from "~/components/ui/button";
import { TrashOutlineIcon } from "~/components/ui/icon";

type DeactivateButtonProps = {
  activationId: string;
  activationRequestId: string;
  isFutureActivation: boolean;
};

export const DeactivateButton = ({
  activationId,
  activationRequestId,
  isFutureActivation,
}: DeactivateButtonProps) => {
  const navigate = useNavigate();

  const { handleConfirmDeactivateMembers, isPending } = useDeactivateMembers({
    activationId,
    confirmationText: `Please confirm that you would like to ${
      isFutureActivation
        ? "cancel your commitment to"
        : "deactivate yourself from"
    } this request?`,
    onSuccess: () => navigate("/requests"),
  });

  return (
    <Button
      variant="destructive"
      busy={isPending}
      startIcon={TrashOutlineIcon}
      onClick={() => handleConfirmDeactivateMembers([activationRequestId])}
    >
      {isFutureActivation ? "Uncommit" : "Deactivate"}
    </Button>
  );
};
