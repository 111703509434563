import { InputBase, styled, InputBaseProps, useTheme } from "@mui/material";
import { forwardRef, useCallback } from "react";

import { Stack } from "~/components/ui/stack";
import { SearchOutlineIcon } from "~/components/ui/icon";

export type SearchInputProps = Pick<
  InputBaseProps,
  | "placeholder"
  | "value"
  | "ref"
  | "disabled"
  | "onKeyUp"
  | "onFocus"
  | "onBlur"
  | "startAdornment"
> & {
  onValueChange: (value: string) => void;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
};

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (
    { placeholder = "Search", onValueChange, onSubmit, ...props },
    forwardedRef
  ) => {
    const {
      tokens: {
        border: { color },
      },
    } = useTheme();

    const onChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) =>
        onValueChange(event.target.value),
      [onValueChange]
    );

    return (
      <form
        noValidate
        onSubmit={e => {
          e.preventDefault();
          onSubmit?.(e);
        }}
      >
        <Stack
          direction="row"
          background="surfaceMuted"
          borderRadius="large"
          borderWidth="large"
          border="muted"
          align="center"
          paddingX="medium"
          gap="small"
          flex={1}
          sx={{
            ":focus-within": {
              borderColor: color.accent,
            },
          }}
        >
          <SearchOutlineIcon tone="muted" size="xxsmall" />

          <StyledInputBase
            {...props}
            fullWidth
            ref={forwardedRef}
            placeholder={placeholder}
            onChange={onChange}
            role="search"
          />
        </Stack>
      </form>
    );
  }
);

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  flexFlow: "wrap",
  "& .MuiInputBase-input": {
    color: theme.tokens.colors.foreground.primary,
    flex: "1 0 100px",
    padding: theme.tokens.spacing.small,
    width: "100%",
  },
}));
