import { Member } from "@ses-mams/api-contract";
import { formatMemberFullName } from "@ses-mams/react-utils";
import { captureException } from "@sentry/react";
import { useState, useEffect, useCallback } from "react";
import { StreamChat } from "stream-chat";
import { useAuth } from "~/context/auth";
import { tsr } from "~/utils/client";

const STREAM_CHAT_API_KEY = import.meta.env.VITE_STREAM_CHAT_API_KEY;

/**
 * Reference: https://github.com/GetStream/website-react-examples/blob/master/social-messenger-ts/src/hooks/useConnectUser.ts
 * This hook should only be used once per application. All other times, the client should be accessed via useChatContext.
 */

export const useChatClient = (): StreamChat | undefined => {
  const member = useAuth().member as Member;
  const [chatClient, setChatClient] = useState<StreamChat>();

  const handleChatConnection = useCallback(
    async (_chatClient: StreamChat, _member: Member) => {
      try {
        const response = await tsr.chat.token.mutate();

        if (response.status !== 200) {
          throw response;
        }

        return _chatClient.connectUser(
          {
            id: _member.id,
            name: formatMemberFullName(_member),
          },
          response.body.token
        );
      } catch (error) {
        captureException(error);
      }
    },
    []
  );

  useEffect(() => {
    const _chatClient = new StreamChat(STREAM_CHAT_API_KEY, {
      enableWSFallback: true,
    });

    // Under some circumstances, a "connectUser" operation might be interrupted
    // (fast user switching, react strict-mode in dev). With this flag, we control
    // whether a "disconnectUser" operation has been requested before we
    // provide a new StreamChat instance to the consumers of this hook.
    let didUserConnectInterrupt = false;

    const connectUser = handleChatConnection(_chatClient, member)
      .catch(e => {
        console.error(`Failed to connect user`, e);
        captureException(e);
      })
      .then(() => {
        if (!didUserConnectInterrupt) {
          setChatClient(_chatClient);
        }
      });

    return () => {
      didUserConnectInterrupt = true;
      // there might be a pending "connectUser" operation, wait for it to finish
      // before executing the "disconnectUser" in order to prevent race-conditions.
      connectUser.then(() => {
        setChatClient(undefined);
        _chatClient.disconnectUser().catch(e => {
          console.error(`Failed to disconnect user`, e);
        });
      });
    };
  }, [member]);

  return chatClient;
};
