import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useCallback, useState } from "react";

import { MemberAdminSummary, Role } from "@ses-mams/api-contract";
import { formatMemberFullName } from "@ses-mams/react-utils";

import { RolesList } from "~/components/common/searchUsersList/components/RolesList";
import { useOverrideRolesOnMember } from "~/components/common/searchUsersList/hooks";
import { Stack } from "~/components/ui/stack";
import { isDev } from "~/utils/env";
import { Button } from "~/components/ui/button";

const ALL_ROLES: Role[] = [
  "OperationalCoordinator",
  "ActivityCoordinator",
  "OOAACoordinator",
  "UnitApprover",
  "ClusterApprover",
  "ZoneApprover",
  "Administrator",
  "SystemAdministrator",
  ...(isDev ? ["Impersonate" as Role] : []), // Impersonate role is not to be granted in UAT or prod
];

type Props = {
  text: string;
  member: MemberAdminSummary;
};

export const OverrideRolesButton = ({ text, member }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [roles, setRoles] = useState<Role[]>(member.rolesOverride ?? []);

  const { overrideRoles, isPending } = useOverrideRolesOnMember();

  const handleClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const handleSave = useCallback(async () => {
    await overrideRoles(member.id, roles);
    handleClose();
  }, [roles]);

  const handleChange = useCallback((event: SelectChangeEvent<Role[]>) => {
    const {
      target: { value },
    } = event;
    setRoles(value as Role[]);
  }, []);

  return (
    <>
      <Button variant="tertiary" onClick={() => setDialogOpen(true)}>
        {text}
      </Button>
      <Dialog open={dialogOpen} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Override roles</DialogTitle>
        <DialogContent>
          <Stack gap="large">
            <Stack>
              <InputLabel>Member</InputLabel>
              {formatMemberFullName(member)}
            </Stack>
            <Stack gap="xsmall" sx={{ width: "180px" }}>
              <InputLabel>Synced roles</InputLabel>
              {member.syncedRoles.length ? (
                <RolesList roles={member.syncedRoles} direction="row" />
              ) : (
                "None"
              )}
            </Stack>
            <FormControl variant="standard">
              <InputLabel>Override roles</InputLabel>
              <Select
                multiple
                value={roles}
                onChange={handleChange}
                renderValue={selected => (
                  <RolesList roles={selected} direction="row" active />
                )}
              >
                {ALL_ROLES.map(role => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="tertiary" onClick={handleSave} busy={isPending}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
