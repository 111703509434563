import { Container } from "@mui/material";
import { Stack } from "~/components/ui/stack";
import { useParams } from "react-router-dom";
import { Box } from "~/components/ui/box";
import { Spinner } from "~/components/ui/spinner";
import { tsr } from "~/utils/client";
import { BackButton } from "~/components/common/navigation";
import { EditActivationForm } from "./components";

export const EditActivationPage = () => {
  const params = useParams();
  const activationId = params.activationId as string;

  const { data, isLoading } = tsr.activations.get.useQuery({
    queryKey: ["activations", activationId],
    queryData: {
      params: {
        id: activationId,
      },
    },
  });

  return (
    <Container maxWidth="sm">
      <Stack gap="xlarge">
        <BackButton />
        {isLoading || !data?.body ? (
          <Box display="flex" align="center" justify="center">
            <Spinner />
          </Box>
        ) : (
          <EditActivationForm activation={data.body} />
        )}
      </Stack>
    </Container>
  );
};
