import { useMemo } from "react";
import { Spinner } from "~/components/ui/spinner";
import { Row, Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { Box } from "~/components/ui/box";
import { AlertCircleFilledIcon } from "~/components/ui/icon";
import { tsr } from "~/utils/client";
import { useAvailabilityReportingContext } from "~/context/reporting/AvailabilityReportingContextProvider";
import { AggregatedAvailabilityReportingPeriodColumn } from "./AggregatedAvailabilityReportingPeriodColumn";

type AggregatedAvailabilityReportProps = {
  groupId?: string;
};

export const AggregatedAvailabilityReport = (
  props: AggregatedAvailabilityReportProps
) => {
  return (
    <Row>
      <AggregatedReportView {...props} />
    </Row>
  );
};

// Split into a separate component so that components and hooks are unmounted when the accordion is closed
const AggregatedReportView = ({
  groupId,
}: AggregatedAvailabilityReportProps) => {
  const {
    endDate,
    startDate,
    unitId,
    selectedCollection,
    selectedCapability,
    selectedMemberType,
  } = useAvailabilityReportingContext();

  const [_collectionId, collectionType] = selectedCollection.split("::") as [
    string,
    "unit" | "zone" | "cluster" | "state",
  ];

  const collectionId = _collectionId.toString();

  const memberType =
    selectedMemberType === "Any"
      ? undefined
      : (selectedMemberType as "Volunteer" | "Staff");

  const { data, isLoading } = tsr.reporting.getAggregatedAvailability.useQuery({
    queryKey: [
      "reporting-availability",
      collectionId,
      collectionType,
      startDate,
      endDate,
      selectedCapability,
      selectedMemberType,
    ],
    queryData: {
      query: {
        startDate,
        endDate,
        groupId,
        collectionId,
        collectionType,
        capabilityId: selectedCapability,
        memberType,
      },
    },
  });

  const hasAtLeastOneLowAvailabilityPeriod = useMemo(
    () => (data?.body || []).some(({ lowAvailability }) => lowAvailability),
    [data?.body]
  );

  if (isLoading) {
    return (
      <Box display="flex" justify="center" paddingY="xlarge" grow={1}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Stack
      gap="medium"
      direction="column"
      overflowX="auto"
      paddingTop={"large"}
      sx={{ margin: "0 auto" }}
    >
      {hasAtLeastOneLowAvailabilityPeriod ? (
        <Stack
          direction="row"
          justify="end"
          gap="xsmall"
          align="center"
          paddingRight="xlarge"
        >
          <AlertCircleFilledIcon tone="critical" size="xxsmall" />
          <Text tone="critical">Availability Low</Text>
        </Stack>
      ) : null}

      <Stack
        direction="row"
        gap="large"
        paddingX="xlarge"
        overflowX="auto"
        paddingBottom="xlarge"
      >
        {data?.body?.map(item => (
          <AggregatedAvailabilityReportingPeriodColumn
            key={`aggregated-${item.period}`}
            period={item}
            unitId={unitId}
            groupId={groupId}
            collectionId={collectionId}
            collectionType={collectionType}
            capabilityId={selectedCapability}
            memberType={memberType}
          />
        ))}
      </Stack>
    </Stack>
  );
};
