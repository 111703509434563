import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "~/components/ui/toast";
import { useAuth } from "~/context/auth";
import { tsr } from "~/utils/client";
import { captureException } from "@sentry/react";

export const useSendMessageToMember = () => {
  const { member: currentMember } = useAuth();

  const { mutateAsync } = tsr.chat.createChannel.useMutation();

  const navigate = useNavigate();

  const [isLoadingMessageChannel, setIsLoadingMessageChannel] = useState(false);

  const { addToast } = useToast();

  const sendMessage = useCallback(
    async (memberId: string) => {
      if (!currentMember) {
        return;
      }

      setIsLoadingMessageChannel(true);

      try {
        const channelData = await mutateAsync({
          body: {
            memberIds: [currentMember.id, memberId],
            contactGroupIds: [],
            groupIds: [],
            unique: true,
          },
        });

        navigate(`/messages?channelId=${channelData.body.channelId}`);
      } catch (error) {
        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    [currentMember]
  );

  return {
    sendMessage,
    isLoadingMessageChannel,
  };
};
