import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

type LabelValueProps = {
  label: string;
  value: string | React.ReactNode;
};

export const LabelValue = ({ label, value }: LabelValueProps) => {
  const valueComponent =
    typeof value === "string" ? <Text weight="medium">{value}</Text> : value;

  return (
    <Stack>
      <Text weight="medium" tone="muted">
        {label}
      </Text>

      {valueComponent}
    </Stack>
  );
};

export const LabelValueWithDescription = ({
  label,
  value,
  description,
}: React.ComponentProps<typeof LabelValue> & {
  description?: string | null;
}) => {
  return (
    <LabelValue
      label={label}
      value={
        <>
          <Text size="large" weight="medium">
            {value}
          </Text>
          {description && <Text tone="secondary">{description}</Text>}
        </>
      }
    />
  );
};
