import { memo, useRef } from "react";

import { availabilitySlotStyle, AvailabilitySlot } from "@ses-mams/react-utils";
import { Box } from "~/components/ui/box";
import { CellIndicator } from "../common/CellIndicator";
import { useGridCellHeight } from "../../context/gridCellHeightContext";
import { ButtonBase } from "@mui/material";
import { Stack } from "~/components/ui/stack";
import { DayTimeMarker } from "../common/DayTimeMarker";

type ScheduleGridCellProps = {
  date: Date;
  slots: Array<AvailabilitySlot>;
  isSelected: boolean;
  onCellClick?: (date: Date, scheduleItemId: string) => void;
  scheduleItemId: string;
};

const getBackgroundColor = (slot: AvailabilitySlot) =>
  availabilitySlotStyle({
    committed: slot.committed,
    status: slot.status,
  }).backgroundTone;

export const ScheduleGridCell = memo(
  ({
    date,
    slots,
    isSelected,
    onCellClick,
    scheduleItemId,
  }: ScheduleGridCellProps) => {
    const { scheduleCellHeight } = useGridCellHeight();
    const outerBoxRef = useRef<HTMLDivElement>(null);

    return (
      <Box
        border="tableCell"
        borderBottomWidth="medium"
        borderLeftWidth="medium"
        basis={1}
        grow={1}
        shrink={0}
        padding="xsmall"
        position="relative"
        ref={outerBoxRef}
        sx={{
          height: "100%",
          minHeight: scheduleCellHeight,
          aspectRatio: {
            xs: 1,
            md: "unset",
          },
        }}
      >
        {onCellClick ? (
          <ButtonBase
            onClick={() => onCellClick(date, scheduleItemId)}
            sx={{ width: "100%", height: "100%" }}
          >
            {isSelected ? (
              <Box
                borderRadius="xsmall"
                background={"accentMuted"}
                sx={{ width: "100%", height: "100%" }}
                border={isSelected ? "info" : undefined}
                borderWidth="large"
              >
                <Box
                  display="flex"
                  justify="center"
                  align="center"
                  sx={{ height: "100%" }}
                >
                  <CellIndicator selected={true} />
                </Box>
              </Box>
            ) : (
              <>
                <Stack
                  justify={"center"}
                  align={"center"}
                  sx={{
                    position: "absolute",
                  }}
                >
                  <CellIndicator selected={isSelected} tone="neutral" />
                </Stack>
                <Box
                  borderRadius="xsmall"
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  border={isSelected ? "info" : undefined}
                  borderWidth="large"
                >
                  {slots.map(slot => (
                    <Box
                      display="flex"
                      justify="center"
                      align="center"
                      background={getBackgroundColor(slot)}
                      sx={{ height: "100%" }}
                    ></Box>
                  ))}
                </Box>
              </>
            )}
          </ButtonBase>
        ) : (
          <>
            <Box
              borderRadius="xsmall"
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {slots.map(slot => (
                <Box
                  key={slot.hour}
                  background={getBackgroundColor(slot)}
                  sx={{ height: "100%" }}
                />
              ))}
            </Box>
            <DayTimeMarker
              cellDate={date}
              cellHours={slots.map(({ hour }) => hour)}
              cellHeight={
                outerBoxRef.current?.offsetHeight ?? scheduleCellHeight
              }
            />
          </>
        )}
      </Box>
    );
  }
);
