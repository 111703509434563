import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  ApiErrorSchema,
  MemberStatusSchema,
  MemberSummarySchema,
  PaginationQueryParamsSchema,
  ServerDateSchema,
  UnitSummarySchema,
  AvailabilityStatusSchema,
} from "./common";

const c = initContract();

const ActivationRequestStatusSchema = z.enum([
  "AvailabilityRequested",
  "AvailabilitySubmitted",
  "ActivationRequested",
  "ActivationAccepted",
  "ActivationDeclined",
  "Deactivated",
]);

export const ActivationTypeSchema = z.enum(["Urgent", "NotUrgent"]);

export const ActivationSummarySchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  type: ActivationTypeSchema,
  unit: UnitSummarySchema,
  location: z.string().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  start: ServerDateSchema,
  end: ServerDateSchema,
  closedAt: z.string().datetime().optional(),
  beaconJob: z.boolean(),
  beaconData: z.string().nullish(),
  beaconJobPriority: z.string().nullish(),
});

export const ActivationSchema = ActivationSummarySchema.extend({
  unit: UnitSummarySchema,
  initiator: MemberSummarySchema.nullable(),
});

// TODO What is this for?
export const ActivationRequestDetailsSchema = z.object({
  id: z.string(),
  availabilityStatus: AvailabilityStatusSchema.nullable(),
  conditionalReason: z.string().nullable(),
  status: ActivationRequestStatusSchema,
  memberStatus: MemberStatusSchema.nullable().optional(),
  eta: ServerDateSchema.nullable(),
});

export const ActivationRequestSchema = z.object({
  id: z.string().nullish(),
  activation: ActivationSchema,
  availabilityStatus: AvailabilityStatusSchema.nullish(),
  conditionalReason: z.string().nullish(),
  status: ActivationRequestStatusSchema.nullish(),
  memberStatus: MemberStatusSchema.nullish(),
  eta: ServerDateSchema.nullish(),
});

const ActivationRequestWithAvailabilityCountSchema = z.object({
  id: z.string().nullable(),
  activation: ActivationSummarySchema,
  availabilityStatus: AvailabilityStatusSchema.nullable(),
  conditionalReason: z.string().nullable(),
  status: ActivationRequestStatusSchema,
  memberStatus: MemberStatusSchema.nullable().optional(),
  eta: ServerDateSchema.nullable(),
  availabilityCounts: z.object({
    activated: z.number(),
    immediatelyAvailable: z.number(),
    available: z.number(),
    unavailable: z.number(),
    conditional: z.number(),
    unset: z.number(),
  }),
});

const AvailabilityStatusUpdateRequestSchema = z.object({
  availabilityStatus: AvailabilityStatusSchema.nullish(),
  conditionalReason: z.string().nullish(),
  force: z.boolean().optional(),
});

const ActivationStatusUpdateRequestSchema = z.object({
  status: MemberStatusSchema.nullable(),
  deviceToken: z.string().optional(),
  force: z.boolean().optional(),
});

export const activationRequestContract = c.router({
  get: {
    method: "GET",
    path: "/activation-requests/:activationId",
    pathParams: z.object({
      activationId: z.string(),
    }),
    responses: {
      200: ActivationRequestSchema,
      404: ApiErrorSchema,
    },
    summary:
      "Get the details of an Activation. If the current member has been added to the activation, return the details of the corresponding ActivationRequest",
  },
  list: {
    method: "GET",
    path: "/activation-requests",
    query: PaginationQueryParamsSchema.extend({
      types: z.array(ActivationTypeSchema).optional(),
      startDate: ServerDateSchema.optional(),
      endDate: ServerDateSchema.optional(),
      archived: z.boolean().optional(),
      query: z.coerce.string().optional(),
      unitId: z.coerce.string().optional(),
    }),
    responses: {
      200: z.object({
        items: z.array(ActivationRequestWithAvailabilityCountSchema),
      }),
    },
    summary: "Find/List Activation Requests with Availability Counts",
  },
  updateAvailabilityStatus: {
    method: "POST",
    path: "/activation-requests/:activationRequestId/availabilityStatus",
    pathParams: z.object({
      activationRequestId: z.string(),
    }),
    body: AvailabilityStatusUpdateRequestSchema,
    responses: {
      200: ActivationRequestSchema,
      400: ApiErrorSchema,
    },
    summary: "Update the availability status of an activation request",
  },
  updateActivationStatus: {
    method: "POST",
    path: "/activation-requests/:activationRequestId/activationStatus",
    pathParams: z.object({
      activationRequestId: z.string(),
    }),
    body: ActivationStatusUpdateRequestSchema,
    responses: {
      200: ActivationRequestSchema,
      400: ApiErrorSchema,
      404: ApiErrorSchema,
    },
    summary: "Update the activation status of an activation request",
  },
  updateEta: {
    method: "POST",
    path: "/activation-requests/:activationRequestId/eta",
    pathParams: z.object({
      activationRequestId: z.string(),
    }),
    body: z.object({
      minutes: z.number().nullable(),
    }),
    responses: {
      200: ActivationRequestSchema,
      400: ApiErrorSchema,
    },
    summary:
      "Update the ETA of the member to the activation location or unit HQ",
  },
});

export type Activation = z.infer<typeof ActivationSchema>;
export type ActivationSummary = z.infer<typeof ActivationSummarySchema>;
export type ActivationType = z.infer<typeof ActivationTypeSchema>;
export type ActivationRequest = z.infer<typeof ActivationRequestSchema>;

export type ActivationRequestStatus = z.infer<
  typeof ActivationRequestStatusSchema
>;

export type ActivationRequestWithAvailabilityCount = z.infer<
  typeof ActivationRequestWithAvailabilityCountSchema
>;

export type ActivationRequestDetails = z.infer<
  typeof ActivationRequestDetailsSchema
>;
