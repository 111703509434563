import { captureException } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { Button } from "~/components/ui/button";
import { DrawerFooter } from "~/components/ui/drawer";
import { useToast } from "~/components/ui/toast";
import { useConfirmationDialog } from "~/components/ui/confirmationDialog";
import { Stack } from "~/components/ui/stack";
import { useShowConflictConfirmationDialog } from "~/hooks/useShowConflictConfirmationDialog";
import { tsr } from "~/utils/client";
import { useCanEditOOAARequest } from "../../hooks/useCanEditOOAARequest";

type ManageOOAAMembersFooterProps = {
  activationId: string;
  isClosed: boolean;
  selectedActivationRequestIds: Array<string>;
  selectedDeactivationRequestIds: Array<string>;
  selectedRemovalMemberIds: Array<string>;
  selectedUnsuccessfulMemberIds: Array<string>;
  clearSelectedMembers: () => void;
};

export const ManageOOAAMembersFooter = ({
  activationId,
  isClosed,
  selectedActivationRequestIds,
  selectedDeactivationRequestIds,
  selectedRemovalMemberIds,
  selectedUnsuccessfulMemberIds,
  clearSelectedMembers,
}: ManageOOAAMembersFooterProps) => {
  const canAmend = useCanEditOOAARequest(activationId);
  const { activate, isPending: isActivatingMembers } =
    useActivateOutOfAreaMembers({
      activationId,
      selectedActivationRequestIds,
      onSuccess: clearSelectedMembers,
    });

  const handleActivatePress = useCallback(() => activate(false), [activate]);

  const { showConfirmationDialog } = useConfirmationDialog();

  const { deactivate, isPending: isDeactivatingMembers } = useDeactivateMembers(
    {
      activationId,
      selectedDeactivationRequestIds,
      onSuccess: clearSelectedMembers,
    }
  );

  const { remove, isPending: isRemovingMembers } =
    useRemoveOutOfAreaActivationMembers({
      activationId,
      selectedRemovalMemberIds,
      onSuccess: clearSelectedMembers,
    });

  const { markUnsuccesful, isPending: isMarkingUnsuccessful } =
    useMarkUnsuccessfulOutOfAreaActivationMembers({
      activationId,
      selectedUnsuccessfulMemberIds,
      onSuccess: clearSelectedMembers,
    });

  const isRemovedDisabled = isClosed || !selectedRemovalMemberIds.length;
  const isActivateDisabled = isClosed || !selectedActivationRequestIds.length;
  const isDeactivateDisabled =
    isClosed || !selectedDeactivationRequestIds.length;
  const isMarkUnsuccessfulDisabled =
    isClosed || !selectedUnsuccessfulMemberIds.length;

  if (!canAmend) {
    return null;
  }

  return (
    <DrawerFooter>
      <Stack direction="row" gap="small">
        <Button
          fullWidth
          busy={isRemovingMembers}
          disabled={isRemovedDisabled}
          onClick={() =>
            showConfirmationDialog({
              title: "Remove members",
              text: `Are you sure you want to remove ${
                selectedRemovalMemberIds.length > 1
                  ? "these members"
                  : "this member"
              }?`,
              onConfirm: remove,
            })
          }
        >
          {`Remove${
            !isRemovedDisabled ? ` (${selectedRemovalMemberIds.length})` : ""
          }`}
        </Button>

        <Button
          fullWidth
          busy={isMarkingUnsuccessful}
          disabled={isMarkUnsuccessfulDisabled}
          onClick={() =>
            showConfirmationDialog({
              title: "Send unsuccessful message",
              text: "Do you want to mark as unsuccessful and send the unsuccessful message to the selected awaiting activation members?",
              onConfirm: markUnsuccesful,
            })
          }
        >
          {`Unsuccessful${
            !isMarkUnsuccessfulDisabled
              ? ` (${selectedUnsuccessfulMemberIds.length})`
              : ""
          }`}
        </Button>
      </Stack>

      <Stack direction="row" gap="small">
        <Button
          fullWidth
          busy={isDeactivatingMembers}
          disabled={isDeactivateDisabled}
          onClick={() =>
            showConfirmationDialog({
              text: "Please confirm that you would like to deactivate these members",
              onConfirm: deactivate,
            })
          }
        >
          {`Deactivate${
            !isDeactivateDisabled
              ? ` (${selectedDeactivationRequestIds.length})`
              : ""
          }`}
        </Button>

        <Button
          fullWidth
          busy={isActivatingMembers}
          disabled={isActivateDisabled}
          onClick={handleActivatePress}
        >
          {`Activate${
            !isActivateDisabled
              ? ` (${selectedActivationRequestIds.length})`
              : ""
          }`}
        </Button>
      </Stack>
    </DrawerFooter>
  );
};

type UseActivateOutOfAreaMembersParams = {
  activationId: string;
  selectedActivationRequestIds: Array<string>;
  onSuccess: () => void;
};

const useActivateOutOfAreaMembers = ({
  activationId,
  selectedActivationRequestIds,
  onSuccess,
}: UseActivateOutOfAreaMembersParams) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } =
    tsr.outOfAreaActivations.activate.useMutation();

  const { addToast } = useToast();

  const { showConflictConfirmationDialog } = useShowConflictConfirmationDialog({
    type: "activation",
  });

  const activate = useCallback(
    async (force = false) => {
      try {
        const result = await mutateAsync({
          params: {
            activationId,
          },
          body: {
            requestIds: selectedActivationRequestIds,
            force,
          },
        });

        queryClient.setQueriesData(
          {
            queryKey: [
              "out-of-area-activation",
              { activationId },
              "members-v2",
            ],
          },
          {
            pages: [
              {
                status: result.status,
                body: {
                  items: result.body,
                },
              },
            ],
            pageParams: [null],
          }
        );

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: [
              "out-of-area-activation-requests",
              "details",
              { activationId },
            ],
            exact: true,
          }),
          queryClient.invalidateQueries({
            queryKey: ["out-of-area-activation-requests", "list"],
          }),
        ]);

        onSuccess();
      } catch (error) {
        const apiError = error as any;
        if (apiError?.status === 422) {
          showConflictConfirmationDialog({
            text: apiError.body.message,
            onConfirm: () => activate(true),
          });
          return;
        }

        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    [activationId, selectedActivationRequestIds, onSuccess]
  );

  return {
    activate,
    isPending,
  };
};

type UseRemoveOutOfAreaActivationMembersParams = {
  activationId: string;
  selectedRemovalMemberIds: Array<string>;
  onSuccess: () => void;
};

const useRemoveOutOfAreaActivationMembers = ({
  activationId,
  selectedRemovalMemberIds,
  onSuccess,
}: UseRemoveOutOfAreaActivationMembersParams) => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const { mutateAsync, isPending } =
    tsr.outOfAreaActivations.removeMembers.useMutation();

  const remove = useCallback(async () => {
    try {
      const result = await mutateAsync({
        params: {
          activationId,
        },
        body: {
          memberIds: selectedRemovalMemberIds,
        },
      });

      queryClient.setQueriesData(
        {
          queryKey: ["out-of-area-activation", { activationId }, "members-v2"],
        },
        { pages: [result], pageParams: [null] }
      );

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [
            "out-of-area-activation-requests",
            "details",
            { activationId },
          ],
          exact: true,
        }),
        queryClient.invalidateQueries({
          queryKey: ["out-of-area-activation-requests", "list"],
        }),
      ]);

      onSuccess();
    } catch (error) {
      captureException(error);

      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [activationId, selectedRemovalMemberIds, mutateAsync, onSuccess]);

  return {
    remove,
    isPending,
  };
};

type UseMarkUnsuccessfulOutOfAreaActivationMembersParams = {
  activationId: string;
  selectedUnsuccessfulMemberIds: Array<string>;
  onSuccess: () => void;
};

const useMarkUnsuccessfulOutOfAreaActivationMembers = ({
  activationId,
  selectedUnsuccessfulMemberIds,
  onSuccess,
}: UseMarkUnsuccessfulOutOfAreaActivationMembersParams) => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const { mutateAsync, isPending } =
    tsr.outOfAreaActivations.markUnsuccessful.useMutation();

  const markUnsuccesful = useCallback(async () => {
    try {
      const result = await mutateAsync({
        params: {
          activationId,
        },
        body: {
          memberIds: selectedUnsuccessfulMemberIds,
        },
      });

      await Promise.all([
        queryClient.setQueriesData(
          {
            queryKey: [
              "out-of-area-activation",
              { activationId },
              "members-v2",
            ],
          },
          { pages: [result] }
        ),
        queryClient.invalidateQueries({
          queryKey: [
            "out-of-area-activation-requests",
            "details",
            { activationId },
          ],
          exact: true,
        }),
        queryClient.invalidateQueries({
          queryKey: ["out-of-area-activation-requests", "list"],
        }),
      ]);

      onSuccess();
    } catch (error) {
      captureException(error);

      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [activationId, selectedUnsuccessfulMemberIds, mutateAsync, onSuccess]);

  return {
    markUnsuccesful,
    isPending,
  };
};

type UseDeactivateMembersParams = {
  activationId: string;
  selectedDeactivationRequestIds: Array<string>;
  onSuccess: () => void;
};

const useDeactivateMembers = ({
  activationId,
  selectedDeactivationRequestIds,
  onSuccess,
}: UseDeactivateMembersParams) => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const { mutateAsync, isPending } =
    tsr.outOfAreaActivations.deactivate.useMutation();

  const deactivate = useCallback(async () => {
    try {
      const result = await mutateAsync({
        params: {
          activationId,
        },
        body: {
          requestIds: selectedDeactivationRequestIds,
        },
      });

      queryClient.setQueriesData(
        {
          queryKey: ["out-of-area-activation", { activationId }, "members-v2"],
        },
        {
          pages: [
            {
              status: result.status,
              body: {
                items: result.body,
              },
            },
          ],
          pageParams: [null],
        }
      );

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [
            "out-of-area-activation-requests",
            "details",
            { activationId },
          ],
          exact: true,
        }),
        queryClient.invalidateQueries({
          queryKey: ["out-of-area-activation-requests", "list"],
        }),
      ]);

      onSuccess();
    } catch (error) {
      captureException(error);

      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [activationId, selectedDeactivationRequestIds, mutateAsync, onSuccess]);

  return {
    deactivate,
    isPending,
  };
};
