import { ActivationRequestMember } from "@ses-mams/api-contract";
import { useMemo } from "react";
import { ActivationMembersSectionKeys } from "../utils";

export const useActivationMemberListSections = (
  items: Array<ActivationRequestMember>
) => {
  return useMemo(() => {
    const dataMap: Record<
      ActivationMembersSectionKeys,
      ActivationRequestMember[]
    > = {
      Activated: [],
      AwaitingConfirmation: [],
      ImmediatelyAvailable: [],
      Available: [],
      Conditional: [],
      Unavailable: [],
      Unset: [],
      Deactivated: [],
    };

    items.forEach(item => {
      switch (item.status) {
        case "ActivationRequested":
          dataMap.AwaitingConfirmation.push(item);
          break;

        case "ActivationAccepted": {
          dataMap.Activated.push(item);
          break;
        }

        case "ActivationDeclined": {
          dataMap.Unavailable.push(item);
          break;
        }

        case "Deactivated":
          dataMap.Deactivated.push(item);
          break;

        case "AvailabilitySubmitted":
        case "AvailabilityRequested":
        default: {
          dataMap[item.availabilityStatus ?? "Unset"].push(item);
        }
      }
    });

    return Object.keys(dataMap).flatMap(key => {
      const typedKey = key as ActivationMembersSectionKeys;
      const sectionData = dataMap[typedKey];

      if (!sectionData.length) {
        return [];
      }

      return [
        {
          // This will be translated during rendering
          title: typedKey,
          data: sectionData,
        },
      ];
    });
  }, [items]);
};
