import { Box } from "~/components/ui/box";
import { Text } from "~/components/ui/text";
import { useGridCellHeight } from "../../context/gridCellHeightContext";
import { ButtonBase } from "@mui/material";

const HOURS: string[] = [...Array(25).keys()].map((_, index) =>
  index === 0 ? "0" : (index - 1).toString().padStart(2, "0")
);

type AvailabilityHourlyGuideProps = {
  onHourPress?: (hour: number) => void;
};

export const AvailabilityHourlyGuide = ({
  onHourPress,
}: AvailabilityHourlyGuideProps) => {
  const { hourCellHeight } = useGridCellHeight();

  if (onHourPress) {
    return (
      <Box direction="column">
        {HOURS.map((hour, index) => (
          <Box
            key={hour}
            display="flex"
            align="center"
            justify="center"
            sx={{ height: hourCellHeight }}
            background={"tableGutter"}
            borderBottomWidth="medium"
            border="tableCell"
          >
            <ButtonBase
              sx={theme => ({
                display: "flex",
                width: "100%",
                height: "100%",
                px: theme.tokens.spacing.medium,
              })}
              onClick={
                index !== 0 ? () => onHourPress(Number(hour)) : undefined
              }
            >
              {index > 0 && (
                <Text align="center" weight="medium" tone="action">
                  {hour}
                </Text>
              )}
            </ButtonBase>
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box>
      {HOURS.map((hour, index) => (
        <Box
          key={hour}
          display="flex"
          align="center"
          justify="center"
          paddingY="xsmall"
          paddingX="medium"
          sx={{ height: hourCellHeight }}
          background={"tableGutter"}
          borderBottomWidth={"medium"}
          border={"tableCell"}
        >
          {index > 0 && (
            <Text align="center" weight="medium">
              {hour}
            </Text>
          )}
        </Box>
      ))}
    </Box>
  );
};
