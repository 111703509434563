import { ActivityForm } from "./ActivityForm";
import { useNavigate } from "react-router-dom";
import { transformActivityFormForUpdate, useActivityForm } from "../../hooks";
import { tsr } from "~/utils/client";
import { FormProvider } from "react-hook-form";
import { useCallback } from "react";
import { ActivityFormSchema } from "@ses-mams/validation";
import { Activity } from "@ses-mams/api-contract";

type Props = {
  activity: Activity;
};

export const EditActivityForm = ({ activity }: Props) => {
  const navigate = useNavigate();

  const formMethods = useActivityForm({ initialActivity: activity });

  const { mutateAsync } = tsr.activities.update.useMutation();

  const onSubmit = useCallback(async (formData: ActivityFormSchema) => {
    await mutateAsync({
      body: transformActivityFormForUpdate(formData),
      params: {
        id: activity.id,
      },
    });

    navigate(`/requests/activities/${activity.id}`);
  }, []);

  return (
    <FormProvider {...formMethods}>
      <ActivityForm isEditing={true} onSubmit={onSubmit} />
    </FormProvider>
  );
};
