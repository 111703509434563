import { Box, BoxProps } from "~/components/ui/box";
import { Heading } from "~/components/ui/heading";

type SectionHeaderProps = Pick<BoxProps, "background"> & {
  children: React.ReactElement | string;
};

export const SectionHeader = ({
  children,
  background = "surfaceMuted",
}: SectionHeaderProps) => {
  return (
    <Box
      background={background}
      padding="large"
      borderBottom="standard"
      borderBottomWidth="medium"
      borderTop="standard"
      borderTopWidth="medium"
      sx={{ width: "100%" }}
    >
      <Heading level="4">{children}</Heading>
    </Box>
  );
};
