import { BackButton } from "~/components/common/navigation";
import { useParams } from "react-router-dom";
import { Stack } from "~/components/ui/stack";
import { Map } from "~/components/ui/map";
import { Box } from "~/components/ui/box";
import { tsr } from "~/utils/client";
import { toLatLng } from "~/utils/latLng";
import { PinMarker } from "../shared";
import { useState } from "react";

export const ActivityMapPage = () => {
  const params = useParams();
  const activityId = params.activityId as string;

  const [selectedPin, setSelectedPin] = useState<string>();

  const { data } = tsr.activities.get.useQuery({
    queryKey: ["activities", activityId],
    queryData: {
      params: {
        id: activityId,
      },
    },
  });

  const activity = data?.body;

  return (
    <Stack sx={{ height: "100%" }} gap="large">
      <BackButton />
      <Box flex={1}>
        <Map center={activity && toLatLng(activity)}>
          {activity && (
            <>
              <PinMarker
                type="event"
                coords={activity}
                address={activity.address}
                selected={selectedPin === "event"}
                setSelected={() => setSelectedPin("event")}
              />
              <PinMarker
                type="venue"
                coords={activity.unit}
                address={activity.unit.address}
                selected={selectedPin === "venue"}
                setSelected={() => setSelectedPin("venue")}
              />
            </>
          )}
        </Map>
      </Box>
    </Stack>
  );
};
