import { createContext, useContext } from "react";

export type AccordionContextType = {
  open: boolean;
};

const AccordionContext = createContext<AccordionContextType>({
  open: false,
});

export const AccordionContextProvider = AccordionContext.Provider;

export const useAccordionContext = () => useContext(AccordionContext);
