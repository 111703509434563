import { Container, ContainerProps } from "@mui/material";

type PopoverContentProps = ContainerProps;

export const PopoverContent = ({
  maxWidth = "xs",
  ...props
}: PopoverContentProps) => {
  return <Container maxWidth={maxWidth} {...props} />;
};
