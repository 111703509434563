import { Container } from "@mui/material";
import {
  useArrayState,
  useSelectableOutOfAreaRoles,
} from "@ses-mams/react-utils";
import { useParams } from "react-router-dom";
import { Box } from "~/components/ui/box";
import { Spinner } from "~/components/ui/spinner";
import { SelectOutOfAreaRoleIdsList } from "~/components/common/selectOutOfAreaRolesList";
import { useOutOfAreaActivationRolesQuery } from "~/hooks/useOutOfAreaActivationRolesQuery";
import { useOutOfAreaActivationRequestDetails } from "../../hooks";
import { Stack } from "~/components/ui/stack";
import { Heading } from "~/components/ui/heading";
import { Button } from "~/components/ui/button";
import { useMemo } from "react";
import { BackButton } from "~/components/common/navigation";

export const OOAARequestRolesResponsePage = () => {
  const activationId = useParams().activationId as string;

  const [selectedRoleIds, { append, remove }] = useArrayState<string>([]);

  const { isLoading: isLoadingRequestDetails, data: requestDetailsData } =
    useOutOfAreaActivationRequestDetails(activationId);

  const { isLoading: isLoadingRoles, data: roleData } =
    useOutOfAreaActivationRolesQuery();

  const roles = roleData?.body;
  const outOfAreaActivationRequest = requestDetailsData?.body;
  const deployments = outOfAreaActivationRequest?.activation?.deployments;

  const selectableRoles = useSelectableOutOfAreaRoles(roles, deployments);

  const href = useMemo(
    () =>
      `/requests/out-of-area-activations/${activationId}/respond/availability?roleIds=${selectedRoleIds.join(
        ","
      )}`,
    [selectedRoleIds]
  );

  if (
    isLoadingRequestDetails ||
    isLoadingRoles ||
    !outOfAreaActivationRequest ||
    !roles
  ) {
    return (
      <Box display="flex" grow={1} justify="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Stack gap="xlarge">
        <BackButton />

        <Stack direction="row" align="center" justify="space-between">
          <Heading level="2">Select a role</Heading>

          <Button href={href}>Done</Button>
        </Stack>
        <SelectOutOfAreaRoleIdsList
          selectedRoleIds={selectedRoleIds}
          appendRoleId={append}
          removeRoleId={remove}
          data={selectableRoles}
        />
      </Stack>
    </Container>
  );
};
