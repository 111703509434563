import { FormControl } from "@mui/material";
import { HelperText, Label } from "~/components/ui/textField";
import { DateTimePicker } from "~/components/ui/dateTimePicker";
import { Stack } from "~/components/ui/stack";
import { useId } from "~/hooks/useId";

type DateFieldProps = {
  id?: string;
  label: string;
  disabled?: boolean;
  errorMessage?: string;
  value?: Date | null;
  onChange: (value: Date | null) => void;
  onBlur?: () => void;
};

export const DateField = ({
  id: _id,
  label,
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
}: DateFieldProps) => {
  const id = useId(_id);
  const helperTextId = useId();
  const isInvalid = Boolean(errorMessage);

  return (
    <Stack>
      <Label htmlFor={id} disabled={disabled}>
        {label}
      </Label>
      <FormControl variant="standard" fullWidth error={isInvalid}>
        <DateTimePicker
          aria-labelledby={id}
          value={value}
          onChange={onChange}
          onClose={onBlur}
          format="MMM d, yyyy"
          mode="date"
        />
        {errorMessage && (
          <HelperText role="alert" id={helperTextId}>
            {errorMessage}
          </HelperText>
        )}
      </FormControl>
    </Stack>
  );
};
