import {
  FormControl,
  Autocomplete as MUIAutocomplete,
  styled,
} from "@mui/material";
import { HelperText, Input, Label } from "~/components/ui/textField";
import { useId } from "~/hooks/useId";
import { AutocompleteProps } from "@mui/material/Autocomplete/Autocomplete";

type Props<T> = Omit<
  AutocompleteProps<T, false, false, false>,
  "renderInput"
> & {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: string;
  onSearchValueChange?: (value: string) => void;
};

export const Autocomplete = <T extends object>({
  label,
  placeholder,
  disabled,
  errorMessage,
  onSearchValueChange,
  ...props
}: Props<T>) => {
  const id = useId();
  const helperTextId = useId();

  return (
    <FormControl variant="standard" fullWidth>
      {label && (
        <Label htmlFor={id} disabled={disabled}>
          {label}
        </Label>
      )}
      <StyledAutocomplete
        {...props}
        disabled={disabled}
        renderInput={({ InputLabelProps, InputProps, ...params }) => (
          <Input
            {...params}
            ref={InputProps.ref}
            placeholder={placeholder}
            onChange={e => onSearchValueChange?.(e.target.value)}
          />
        )}
      />
      {errorMessage && (
        <HelperText id={helperTextId} role="alert">
          {errorMessage}
        </HelperText>
      )}
    </FormControl>
  );
};

const StyledAutocomplete = styled(MUIAutocomplete)(({ theme }) => {
  return {
    "label + &": {
      marginTop: theme.tokens.spacing.xlarge,
    },
  };
}) as typeof MUIAutocomplete;
