import { IconButton } from "@mui/material";
import { Stack } from "~/components/ui/stack";
import { XOutlineIcon } from "~/components/ui/icon";
import { Heading } from "~/components/ui/heading";

type DialogHeaderProps = {
  onClose: () => void;
  children: string;
};

export const DialogHeader = ({ children, onClose }: DialogHeaderProps) => {
  return (
    <Stack
      direction="row"
      justify="space-between"
      align="center"
      paddingX="large"
      paddingY="small"
    >
      <Heading level="3">{children}</Heading>

      <IconButton aria-label="Close" onClick={onClose}>
        <XOutlineIcon />
      </IconButton>
    </Stack>
  );
};
