import { Stack } from "~/components/ui/stack";
import { HourlyAvailability } from "@ses-mams/react-utils";
import { AvailabilityHourlyGrid } from "./AvailabilityHourlyGrid";
import { AvailabilityHourlyGuide } from "./AvailabilityHourlyGuide";
import { SetHourlyAvailabilityGuide } from "./SetHourlyAvailabilityGuide";
import { useState } from "react";
import { Box } from "~/components/ui/box";
import { NoAvailabilityLayout } from "../common/NoAvailabilityLayout";

type ViewAvailabilityHourlyGridProps = {
  availability: HourlyAvailability;
  hasAvailabilitySet?: boolean;
};

export const ViewAvailabilityHourlyGrid = ({
  availability,
  hasAvailabilitySet,
}: ViewAvailabilityHourlyGridProps) => {
  const [showEmptyAvailability, setShowEmptyAvailability] =
    useState(!hasAvailabilitySet);

  if (showEmptyAvailability) {
    return (
      <NoAvailabilityLayout>
        <Box
          borderBottom="standard"
          borderBottomWidth="medium"
          flex={1}
          display="flex"
          align="center"
        >
          <SetHourlyAvailabilityGuide
            onDone={() => {
              setShowEmptyAvailability(false);
            }}
          />
        </Box>
      </NoAvailabilityLayout>
    );
  }

  return (
    <Stack direction="row" overflowX="auto">
      <AvailabilityHourlyGuide />
      <AvailabilityHourlyGrid availability={availability} />
    </Stack>
  );
};
