import { Radio as MuiRadio, RadioProps as MuiRadioProps } from "@mui/material";
import { useTextStyles } from "../text";

type RadioProps = MuiRadioProps;

export const Radio = ({ sx, ...props }: RadioProps) => {
  const style = useTextStyles({ size: "standard" });

  return (
    <MuiRadio {...props} sx={[style, ...(Array.isArray(sx) ? sx : [sx])]} />
  );
};
