import OktaAuth from "@okta/okta-auth-js";

export const oktaAuth = new OktaAuth({
  clientId: import.meta.env.VITE_OAUTH_CLIENT_ID,
  issuer: "https://login.ses.nsw.gov.au/oauth2/default",
  redirectUri: window.location.origin + "/login/callback",
  scopes: ["openid", "profile"],
  pkce: true,
});

export const ACCESS_TOKEN_KEY = "accessToken";

export const REFRESH_TOKEN_KEY = "refreshToken";

export const DEFAULT_AUTH_ROUTE = "/units";
