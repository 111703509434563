import { captureException } from "@sentry/react";
import { ContactGroup, Group, Member } from "@ses-mams/api-contract";
import {
  SelectAllMembersQuery,
  formatSelectAllMembersQuery,
} from "@ses-mams/react-utils";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { EditMemberList } from "~/components/common/editMemberList";
import { SelectUnitGroupsList } from "~/components/common/selectUnitGroupsList";
import { Button, IconButton } from "~/components/ui/button";
import { CheckboxField } from "~/components/ui/checkbox";
import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "~/components/ui/drawer";
import { ChevronLeftOutlineIcon } from "~/components/ui/icon";
import { Stack } from "~/components/ui/stack";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";

export type AddActivationMembersProps = {
  activationId: string;
  activationStart: string;
  activationEnd: string;
  onBack: () => void;
  onClose: () => void;
  unitId: string;
};

export const AddActivationMembers = ({
  activationId,
  activationStart,
  activationEnd,
  onBack,
  onClose,
  unitId,
}: AddActivationMembersProps) => {
  const [requestAvailableMembersOnly, setRequestAvailableMembersOnly] =
    useState(true);
  const [members, setMembers] = useState<Array<Member>>([]);
  const [groups, setGroups] = useState<Array<Group>>([]);
  const [contactGroups, setContactGroups] = useState<Array<ContactGroup>>([]);
  const [selectAllMembersQuery, setSelectAllMembersQuery] =
    useState<SelectAllMembersQuery>();

  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const { mutateAsync, isPending: isAddingMembers } =
    tsr.activations.addActivationMembers.useMutation();

  const onDone = useCallback(async () => {
    try {
      const updatedMemberList = await mutateAsync({
        body: {
          groupIds: groups.map(({ id }) => id),
          contactGroupIds: contactGroups.map(({ id }) => id),
          memberIds: members.map(({ id }) => id),
          requestAvailableMembersOnly,
          selectAllMembersQuery: formatSelectAllMembersQuery(
            selectAllMembersQuery
          ),
        },
        params: {
          activationId,
        },
      });

      queryClient.setQueryData(
        ["activations", activationId, "members", "", []],
        { pages: [updatedMemberList], pageParams: [null] }
      );

      queryClient.invalidateQueries({
        queryKey: ["activation-requests"],
      });

      onBack();
    } catch (error) {
      captureException(error);

      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [
    requestAvailableMembersOnly,
    members,
    groups,
    contactGroups,
    selectAllMembersQuery,
    onBack,
  ]);

  return (
    <>
      <DrawerHeader onClose={onClose}>
        <Stack direction="row" gap="small" align="center">
          <IconButton size="medium" onClick={onBack}>
            <ChevronLeftOutlineIcon />
          </IconButton>
          Add Members
        </Stack>
      </DrawerHeader>
      <DrawerContent>
        <Stack gap="large">
          <CheckboxField
            label="Only send to available members"
            checked={requestAvailableMembersOnly}
            onClick={() => setRequestAvailableMembersOnly(prev => !prev)}
          />

          <SelectUnitGroupsList
            groups={groups}
            contactGroups={contactGroups}
            setGroups={setGroups}
            setContactGroups={setContactGroups}
            unitId={unitId}
          />

          <EditMemberList
            showAvailabilityStatus
            selectedMembers={members}
            onChangeSelectedMembers={setMembers}
            selectAllMembersQuery={selectAllMembersQuery}
            setSelectAllMembersQuery={setSelectAllMembersQuery}
            unitId={unitId}
            currentAvailabilityStatusTime={activationStart}
            requiredAvailabilityWindow={
              requestAvailableMembersOnly
                ? {
                    start: activationStart,
                    end: activationEnd,
                  }
                : undefined
            }
            activationId={activationId}
          />
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Button busy={isAddingMembers} onClick={onDone}>
          Done
        </Button>
      </DrawerFooter>
    </>
  );
};
