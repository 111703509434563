import { Breakpoint, Dialog as MuiDialog } from "@mui/material";

export type DialogProps = {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
  maxWidth?: Breakpoint;
  fullWidth?: boolean;
};

export const Dialog = ({
  open,
  onClose,
  children,
  fullWidth,
  maxWidth = "xs",
}: DialogProps) => {
  return (
    <MuiDialog
      fullWidth={fullWidth}
      open={open}
      maxWidth={maxWidth}
      onClose={onClose}
      PaperProps={{
        sx: theme => ({ borderRadius: theme.tokens.border.radius.xsmall }),
      }}
    >
      {children}
    </MuiDialog>
  );
};
