import { Container } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import {
  formatDateForServer,
  useAvailabilityBlocksForm,
  useCanAmendOutOfAreaActivationRequestResponse,
} from "@ses-mams/react-utils";
import { AvailabilityBlocksFormSchema } from "@ses-mams/validation";
import { captureException } from "@sentry/react";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Box } from "~/components/ui/box";
import { AvailabilityBlocksForm } from "~/components/common/availabilityBlocksForm";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";
import { Heading } from "~/components/ui/heading";
import { Stack } from "~/components/ui/stack";
import { Button } from "~/components/ui/button";
import { Spinner } from "~/components/ui/spinner";
import { BackButton } from "~/components/common/navigation";
import { useConfirmationDialog } from "~/components/ui/confirmationDialog";
import { Protected } from "~/components/common/layout";
import { useAuth } from "~/context/auth";
import {
  useOutOfAreaActivationRequestDetails,
  useOutOfAreaActivationRequestHistoryDetails,
} from "../../hooks";
import { DeploymentDetails } from "../components";

export const AmendOOAARequestAvailabilityPage = () => {
  const activationId = useParams().activationId as string;
  const requestId = useParams().requestId as string;
  const [searchParams] = useSearchParams();
  const roleIds = searchParams.get("roleIds") as string;

  const { isLoading: isLoadingRequestDetails, data: requestDetailsData } =
    useOutOfAreaActivationRequestDetails(activationId);

  const { isLoading: isLoadingHistoryDetails, data: historyData } =
    useOutOfAreaActivationRequestHistoryDetails(requestId);

  const outOfAreaActivationRequest = requestDetailsData?.body;
  const activation = outOfAreaActivationRequest?.activation;
  const deployments = activation?.deployments;

  const request = historyData?.body?.request;
  const existingAvailabilityBlocks = request?.availabilityBlocks;
  const existingRequestRoles = request?.roles ?? [];

  const { control, trigger, getValues, handleSubmit, reset, formState } =
    useAvailabilityBlocksForm(deployments);

  const { isPending: isAmending, amend } = useAmendOutOfAreaActivationRequest({
    isVolunteer: request?.member.type === "Volunteer",
    activationId,
    roleIds,
    requestId,
  });

  const { member } = useAuth();
  const canAmend = useCanAmendOutOfAreaActivationRequestResponse({
    member,
    activation,
    request,
  });

  useEffect(() => {
    if (!deployments?.length || !existingAvailabilityBlocks?.length) {
      return;
    }

    reset({
      availabilityBlocks: (existingAvailabilityBlocks ?? deployments).map(
        d => ({
          availabilityStatus: d.availabilityStatus || undefined,
          conditionalReason: d.conditionalReason || undefined,
          start: new Date(d.start),
          end: new Date(d.end),
        })
      ),
    });
  }, [deployments, existingAvailabilityBlocks]);

  const hasChangedRolesOrAvailability = useMemo(() => {
    const roleIdsSet = new Set(roleIds ? roleIds.split(",") : []);

    const isRolesDirty =
      existingRequestRoles.length !== roleIdsSet.size ||
      existingRequestRoles.some(role => !roleIdsSet.has(role.id));

    return isRolesDirty || formState.isDirty;
  }, [formState.isDirty, existingRequestRoles, roleIds]);

  const isLoading = isLoadingRequestDetails || isLoadingHistoryDetails;

  return (
    <Protected
      shouldRedirect={!isLoading && !canAmend}
      redirectPath={`/requests/out-of-area-activations/${activationId}/history?requestId=${requestId}`}
    >
      {isLoading ? (
        <Box display="flex" grow={1} justify="center">
          <Spinner />
        </Box>
      ) : (
        <Container maxWidth="md">
          <form onSubmit={handleSubmit(d => amend(d))}>
            <Stack gap="xlarge">
              <BackButton />

              <Stack direction="row" align="center" justify="space-between">
                <Heading level="2">Availability</Heading>

                <Button
                  type="submit"
                  busy={isAmending}
                  disabled={!hasChangedRolesOrAvailability}
                >
                  Done
                </Button>
              </Stack>

              <DeploymentDetails deployments={deployments} />

              <AvailabilityBlocksForm
                control={control}
                trigger={trigger}
                getValues={getValues}
              />
            </Stack>
          </form>
        </Container>
      )}
    </Protected>
  );
};

type Params = {
  isVolunteer?: boolean;
  activationId: string;
  requestId: string;
  roleIds: string;
};

const useAmendOutOfAreaActivationRequest = ({
  isVolunteer,
  activationId,
  requestId,
  roleIds,
}: Params) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { addToast } = useToast();
  const { showConfirmationDialog } = useConfirmationDialog();

  const { isPending, mutateAsync } =
    tsr.outOfAreaActivationRequests.amend.useMutation();

  const amend = useCallback(
    async (form: AvailabilityBlocksFormSchema, force?: boolean) => {
      if (isVolunteer && !force) {
        showConfirmationDialog({
          title: "Confirmation",
          text: "Amending availability would reset any previous approvals. Do you want to continue?",
          onConfirm: async () => {
            await amend(form, true);
          },
        });
        return;
      }

      try {
        await mutateAsync({
          params: {
            requestId,
          },
          body: {
            roleIds: roleIds ? roleIds.split(",") : undefined,
            availabilityBlocks: form.availabilityBlocks.map(block => ({
              ...block,
              start: formatDateForServer(block.start),
              end: formatDateForServer(block.end),
            })),
          },
        });

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: [
              "out-of-area-activation",
              { activationId },
              "members-v2",
            ],
          }),
          queryClient.invalidateQueries({
            queryKey: [
              "out-of-area-activation-requests",
              "details",
              { requestId },
            ],
          }),
          queryClient.invalidateQueries({
            queryKey: ["out-of-area-activation-requests", "list"],
          }),
        ]);

        navigate(
          `/requests/out-of-area-activations/${activationId}/history?requestId=${requestId}`
        );
      } catch (error) {
        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    [mutateAsync, activationId, requestId, roleIds, isVolunteer]
  );

  return {
    isPending,
    amend,
  };
};
