import { z } from "zod";
import { isValid24HourTime } from "./isValid24HourTime";

type DateAndTimeOptions = {
  date_required_error?: string;
  time_required_error?: string;
};

export const dateAndTime = (options?: DateAndTimeOptions) =>
  z.object(
    {
      date: z.date({ required_error: options?.date_required_error }),
      time: z
        .string({ required_error: options?.time_required_error })
        .refine(value => isValid24HourTime(value), {
          message: "Time must be a valid 24 hour time",
        }),
    },
    { required_error: options?.date_required_error }
  );
