import { useCallback, useState } from "react";
import { captureException } from "@sentry/react";
import { useBulkSelectingState } from "@ses-mams/react-utils";
import { useQueryClient } from "@tanstack/react-query";
import { useToast } from "~/components/ui/toast";
import { OutOfAreaActivationCloseReasonDrawer } from "~/components/common/outOfAreaActivationCloseReasonDrawer/OutOfAreaActivationCloseReasonDrawer";
import { useConfirmationDialog } from "~/components/ui/confirmationDialog";
import { tsr } from "~/utils/client";
import { useOutOfAreaActivationListData } from "./useOutOfAreaActivationListData";
import { OutOfAreaActivationListItem } from "./OutOfAreaActivationListItem";
import { RequestList } from "../../../shared";

export const OutOfAreaActivationList = () => {
  const { isBulkSelecting, setIsBulkSelecting, onSelect, selectedIds } =
    useBulkSelectingState();

  const { showConfirmationDialog } = useConfirmationDialog();

  const {
    handleBulkClose,
    isPending,
    isCloseReasonDrawerOpen,
    setIsCloseReasonDrawerOpen,
  } = useBulkCloseOOAA({
    selectedIds,
    setIsBulkSelecting,
  });

  return (
    <>
      <RequestList
        skipConfirmation
        dataHook={useOutOfAreaActivationListData}
        ListItemComponent={OutOfAreaActivationListItem}
        onConfirm={() => setIsCloseReasonDrawerOpen(true)}
        isClosing={isPending}
        isBulkSelecting={isBulkSelecting}
        setIsBulkSelecting={setIsBulkSelecting}
        selectedIds={selectedIds}
        onBulkSelect={onSelect}
        bulkCloseRoles={["OperationalCoordinator", "OOAACoordinator"]}
      />

      <OutOfAreaActivationCloseReasonDrawer
        open={isCloseReasonDrawerOpen}
        onClose={() => setIsCloseReasonDrawerOpen(false)}
        onSubmit={reason =>
          showConfirmationDialog({
            text: "You will close and archive all selected requests and all members will be deactivated.",
            onConfirm: () => handleBulkClose(reason),
          })
        }
      />
    </>
  );
};

const useBulkCloseOOAA = ({
  selectedIds,
  setIsBulkSelecting,
}: Pick<
  ReturnType<typeof useBulkSelectingState>,
  "selectedIds" | "setIsBulkSelecting"
>) => {
  const [isCloseReasonDrawerOpen, setIsCloseReasonDrawerOpen] = useState(false);

  const queryClient = useQueryClient();

  const { addToast } = useToast();

  const { mutateAsync, isPending } =
    tsr.outOfAreaActivations.closeMany.useMutation();

  const handleBulkClose = useCallback(
    async (reason: string) => {
      try {
        await mutateAsync({
          body: {
            activationIds: [...selectedIds],
            reason,
          },
        });

        await queryClient.invalidateQueries({
          queryKey: ["out-of-area-activation-requests", "list"],
        });

        setIsBulkSelecting(false);
        setIsCloseReasonDrawerOpen(false);
      } catch (error) {
        captureException(error);

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    [selectedIds, setIsBulkSelecting]
  );

  return {
    isPending,
    handleBulkClose,
    isCloseReasonDrawerOpen,
    setIsCloseReasonDrawerOpen,
  };
};
