import { memo } from "react";

import { availabilitySlotStyle, AvailabilitySlot } from "@ses-mams/react-utils";
import { Box } from "~/components/ui/box";
import { CellIndicator } from "../common/CellIndicator";
import { useGridCellHeight } from "../../context/gridCellHeightContext";
import { ButtonBase } from "@mui/material";

type AvailabilityHourlyGridCellProps = {
  date: Date;
  slot: AvailabilitySlot;
  isSelected: boolean;
  onCellPress?: (date: Date, hour: number) => void;
};

export const AvailabilityHourlyGridCell = memo(
  ({
    date,
    slot,
    isSelected,
    onCellPress,
  }: AvailabilityHourlyGridCellProps) => {
    const { borderTone, backgroundTone } = availabilitySlotStyle({
      committed: slot.committed,
      status: slot.status,
    });

    const { hourCellHeight } = useGridCellHeight();

    if (!onCellPress) {
      return (
        <Box
          border="neutral"
          borderBottomWidth="medium"
          borderLeftWidth="medium"
          basis={1}
          grow={1}
          shrink={0}
          padding="xsmall"
          sx={{
            height: hourCellHeight,
          }}
        >
          <Box
            borderRadius="xsmall"
            background={backgroundTone}
            sx={{ width: "100%", height: "100%" }}
          />
        </Box>
      );
    }

    const backgroundColor = isSelected ? "accentMuted" : backgroundTone;

    const isDisabled = slot.committed;

    return (
      <Box
        border="neutral"
        borderBottomWidth="medium"
        borderLeftWidth="medium"
        basis={1}
        grow={1}
        shrink={0}
        padding="xsmall"
        sx={{
          height: hourCellHeight,
        }}
      >
        <ButtonBase
          disabled={isDisabled}
          onClick={() => onCellPress(date, slot.hour)}
          sx={{ width: "100%", height: "100%" }}
        >
          <Box
            borderRadius="xsmall"
            background={backgroundColor}
            sx={{ width: "100%", height: "100%" }}
            border={isSelected ? "info" : undefined}
            borderWidth="large"
          >
            <Box
              display="flex"
              justify="center"
              align="center"
              sx={{ height: "100%" }}
            >
              <CellIndicator
                disabled={isDisabled}
                selected={isSelected}
                tone={borderTone}
              />
            </Box>
          </Box>
        </ButtonBase>
      </Box>
    );
  }
);
