import { Group } from "@ses-mams/api-contract";
import { useCallback, useState } from "react";
import { SelectGroupsDrawer } from "~/components/common/selectGroupsList";
import { EditListSection } from "~/components/common/editListSection";
import { GroupListItem } from "~/components/common/group";

type EditGroupsListProps = {
  selectedGroups: Array<Group>;
  onChangeSelectedGroups: (groups: Array<Group>) => void;
};

export const EditGroupsList = ({
  selectedGroups,
  onChangeSelectedGroups,
}: EditGroupsListProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onDone = useCallback(
    (groups: Array<Group>) => {
      onChangeSelectedGroups(groups);
      setIsDrawerOpen(false);
    },
    [onChangeSelectedGroups]
  );

  return (
    <>
      <EditListSection
        title="Groups"
        onButtonClick={() => setIsDrawerOpen(true)}
      >
        {selectedGroups.map(group => (
          <GroupListItem key={group.id} group={group} />
        ))}
      </EditListSection>

      <SelectGroupsDrawer
        open={isDrawerOpen}
        groups={selectedGroups}
        onClose={() => setIsDrawerOpen(false)}
        onDone={onDone}
      />
    </>
  );
};
