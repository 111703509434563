import { useCallback, useState } from "react";
import { Drawer, DrawerProps } from "~/components/ui/drawer";
import { AddOOAAMembers } from "./AddOOAAMembers";
import { ManageOOAAMembers } from "./ManageOOAAMembers";

type ManageOOAAMembersDrawerProps = Omit<DrawerProps, "children"> & {
  isClosed: boolean;
  activationId: string;
};

export const ManageOOAAMembersDrawer = ({
  activationId,
  isClosed,
  onClose,
  open,
  ...props
}: ManageOOAAMembersDrawerProps) => {
  const [showAddOOAAMembers, setShowAddOOAAMembers] = useState(false);

  const handleOnClose = useCallback(() => {
    onClose();
    setShowAddOOAAMembers(false);
  }, [onClose]);

  return (
    <Drawer {...props} open={open} onClose={handleOnClose}>
      {showAddOOAAMembers ? (
        <AddOOAAMembers
          onClose={handleOnClose}
          onBack={() => {
            setShowAddOOAAMembers(false);
          }}
          activationId={activationId}
        />
      ) : (
        <ManageOOAAMembers
          onAdd={() => {
            setShowAddOOAAMembers(true);
          }}
          isDrawerOpen={open}
          isClosed={isClosed}
          activationId={activationId}
          onClose={handleOnClose}
        />
      )}
    </Drawer>
  );
};
