import {
  useActivityMemberListSections,
  useTransformPagedDataToItems,
} from "@ses-mams/react-utils";

import { tsr } from "~/utils/client";
import { getNextLargePageParam, LARGE_PAGE_SIZE } from "~/utils/pagination";

export const useActivityMembersData = (activityId: string) => {
  const {
    isLoading,
    isRefetching,
    data,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = tsr.activities.getActivityMembers.useInfiniteQuery({
    queryKey: ["activities", activityId, "members", "", []],
    queryData: ({ pageParam }) => ({
      query: {
        skip: pageParam?.skip ?? 0,
        take: pageParam?.take ?? LARGE_PAGE_SIZE,
      },
      params: {
        activityId,
      },
    }),
    initialPageParam: { skip: 0, take: LARGE_PAGE_SIZE },
    getNextPageParam: getNextLargePageParam,
  });

  const items = useTransformPagedDataToItems(data);

  const sections = useActivityMemberListSections(items);

  return {
    sections,
    isLoading,
    isRefetching,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  };
};
