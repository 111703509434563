import { ActivityMember } from "@ses-mams/api-contract";
import { useCallback } from "react";
import { Button } from "~/components/ui/button";
import { DrawerFooter } from "~/components/ui/drawer";
import { Stack } from "~/components/ui/stack";
import { useRemoveActivityMembers, useConfirmAttendance } from "../../../hooks";

type ManageActivityMembersFooterProps = {
  activityId: string;
  isClosed: boolean;
  selectedForConfirmation: Array<ActivityMember>;
  selectedIdsForRemoval: Set<string>;
  clearSelectedMembers: () => void;
};

export const ManageActivityMembersFooter = ({
  activityId,
  isClosed,
  selectedForConfirmation,
  selectedIdsForRemoval,
  clearSelectedMembers,
}: ManageActivityMembersFooterProps) => {
  const { confirmAttendance, isLoading } = useConfirmAttendance(
    activityId,
    selectedForConfirmation,
    clearSelectedMembers
  );

  const handleConfirmClick = useCallback(async () => {
    if (selectedForConfirmation.length > 0) {
      await confirmAttendance();
    }
  }, [selectedForConfirmation, confirmAttendance]);

  const { handleConfirmRemoveMembers, isPending: isRemovingMembers } =
    useRemoveActivityMembers({
      activityId,
      confirmationText:
        "Please confirm that you'd like to remove these members.",
      onSuccess: clearSelectedMembers,
    });

  const handleRemoveClick = useCallback(() => {
    if (!selectedIdsForRemoval.size) {
      return;
    }

    handleConfirmRemoveMembers([...selectedIdsForRemoval]);
  }, [selectedIdsForRemoval]);

  const removeLabel = `Remove${
    !isClosed && selectedIdsForRemoval.size > 0
      ? ` (${selectedIdsForRemoval.size})`
      : ""
  }`;
  const confirmLabel = `Confirm${
    !isClosed && selectedForConfirmation.length
      ? ` (${selectedForConfirmation.length})`
      : ""
  }`;

  return (
    <>
      <DrawerFooter>
        <Stack direction="row" gap="medium">
          <Button
            fullWidth
            variant="destructive"
            busy={isRemovingMembers}
            disabled={isClosed || !selectedIdsForRemoval.size}
            onClick={handleRemoveClick}
          >
            {removeLabel}
          </Button>
          <Button
            fullWidth
            busy={isLoading}
            disabled={isClosed || !selectedForConfirmation.length}
            onClick={handleConfirmClick}
          >
            {confirmLabel}
          </Button>
        </Stack>
      </DrawerFooter>
    </>
  );
};
