import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { ActivityAvailabilityStatus } from "@ses-mams/api-contract";
import { useToast } from "~/components/ui/toast";
import { useShowConflictConfirmationDialog } from "~/hooks/useShowConflictConfirmationDialog";
import { tsr } from "~/utils/client";

type MutateActivityAvailabilityStatusInput = {
  requestId: string;
  status: ActivityAvailabilityStatus;
  conditionalReason?: string;
  force?: boolean;
};

export const useMutateActivityAvailabilityStatus = () => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  // Hijack control of loading state to avoid flashes of stale state inbetween request finishing and cache updating
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync } =
    tsr.activityRequests.updateAvailabilityStatus.useMutation();

  const { showConflictConfirmationDialog } = useShowConflictConfirmationDialog({
    type: "activity",
  });

  const mutateAvailabilityStatus = useCallback(
    async ({
      requestId,
      status,
      conditionalReason,
      force = false,
    }: MutateActivityAvailabilityStatusInput) => {
      try {
        setIsLoading(true);

        const updatedActivityRequest = await mutateAsync({
          params: {
            activityRequestId: requestId,
          },
          body: {
            availabilityStatus: status,
            conditionalReason,
            force,
          },
        });

        await Promise.all([
          queryClient.setQueryData(
            ["activity-requests", "details", requestId],
            updatedActivityRequest
          ),

          queryClient.invalidateQueries({
            queryKey: ["activity-requests", "list"],
          }),
          queryClient.invalidateQueries({
            queryKey: ["activity-requests", "details"],
          }),
        ]);
      } catch (error) {
        const apiError = error as any;
        if (apiError?.status === 422) {
          showConflictConfirmationDialog({
            onConfirm: () =>
              mutateAvailabilityStatus({
                requestId,
                status,
                conditionalReason,
                force: true,
              }),
          });
          return;
        }

        if (apiError?.status === 400) {
          addToast({
            tone: "critical",
            title: "Sorry, something went wrong",
            message: apiError?.body?.message,
          });

          queryClient.invalidateQueries({
            queryKey: ["activity-requests", "list"],
            exact: true,
          });

          return;
        }

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return { isLoading, mutateAvailabilityStatus };
};
