import { useCallback } from "react";
import { ButtonBase } from "@mui/material";
import { Unit } from "@ses-mams/api-contract";
import { useDebouncedSearch } from "@ses-mams/react-utils";
import emptyListImageUrl from "~/assets/emptyList.png";
import { SearchInput } from "~/components/ui/searchInput";
import { Stack } from "~/components/ui/stack";
import { InfiniteList } from "~/components/ui/list";
import { Checkbox } from "~/components/ui/checkbox";
import { EmptyState } from "~/components/common/emptyState";
import { UnitListItem } from "~/components/common/unit";
import { useUnitListSearch } from "./useUnitListSearch";

type SearchUnitsListProps = {
  units: Array<Unit>;
  removeUnit: (unit: Unit) => void;
  appendUnit: (unit: Unit) => void;
};

export const SelectUnitsList = ({
  units,
  removeUnit,
  appendUnit,
}: SearchUnitsListProps) => {
  const { searchValue, setSearchValue, debouncedSearchValue } =
    useDebouncedSearch("");

  const { data, isLoading, isRefetching, hasNextPage, fetchNextPage } =
    useUnitListSearch(debouncedSearchValue);

  const renderItem = useCallback(
    (item: Unit) => {
      const isChecked = units.some(({ id }) => id === item.id);

      return (
        <ButtonBase
          role="checkbox"
          aria-checked={isChecked}
          onClick={() => (isChecked ? removeUnit(item) : appendUnit(item))}
        >
          <UnitListItem
            startAdornment={<Checkbox checked={isChecked} />}
            unit={item}
          />
        </ButtonBase>
      );
    },
    [units]
  );

  const isRefreshing = isLoading || isRefetching;

  return (
    <Stack flex={1} gap="large">
      <SearchInput
        placeholder="Search"
        value={searchValue}
        onValueChange={setSearchValue}
      />

      <InfiniteList
        data={data}
        keyExtractor={item => item.id}
        renderItem={renderItem}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        listEmptyElement={
          isRefreshing ? null : (
            <EmptyState
              image={<img src={emptyListImageUrl} />}
              title="No units found"
              description="There are no units associated with your search. Please try another value."
            />
          )
        }
      />
    </Stack>
  );
};
