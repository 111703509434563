import { AvailabilityStatus, MemberStatus } from "@ses-mams/api-contract";
import {
  BackgroundTones,
  availabilitySlotStyle,
  formatAvailabilityStatusLabel,
  formatMemberStatusLabel,
} from "@ses-mams/react-utils";
import { forwardRef } from "react";
import { ResponseButton } from "~/components/common/responseButton";
import { ChevronDownOutlineIcon } from "~/components/ui/icon";
import { Text } from "~/components/ui/text";

type AvailabilityStatusButtonProps = {
  status: AvailabilityStatus | MemberStatus;
  isSelected?: boolean;
  onClick?: () => void;
};

export const AvailabilityStatusButton = forwardRef<
  HTMLButtonElement,
  AvailabilityStatusButtonProps
>(({ status, isSelected, onClick }, forwardedRef) => {
  const { backgroundTone } = availabilitySlotStyle({
    status,
    committed: false,
  });

  const availabilityLabel = formatAvailabilityStatusLabel({
    status,
    useShort: true,
  });

  const label =
    availabilityLabel !== "Unset"
      ? availabilityLabel
      : formatMemberStatusLabel(status as MemberStatus);

  return (
    <ResponseButton
      fullWidth={!isSelected}
      ref={forwardedRef}
      background={backgroundTone as BackgroundTones}
      onClick={onClick}
    >
      <Text size="large" weight="medium" tone="inverted">
        {label}
      </Text>

      {isSelected && <ChevronDownOutlineIcon tone="inverted" />}
    </ResponseButton>
  );
});
