import { CircularProgress, Paper, TableContainer } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useState } from "react";

import { MemberAdminSummary } from "@ses-mams/api-contract";
import {
  formatMemberFullName,
  useDebouncedSearch,
} from "@ses-mams/react-utils";

import { Box } from "~/components/ui/box";
import { SearchInput } from "~/components/ui/searchInput";
import { Stack } from "~/components/ui/stack";

import { ActionsCell } from "./components/ActionsCell";
import { RolesList } from "./components/RolesList";
import { useMemberPermissionsListData } from "./hooks";

const columns: GridColDef<MemberAdminSummary>[] = [
  {
    field: "name",
    headerName: "Name",
    sortable: false,
    flex: 2,
    valueGetter: (_, row) => formatMemberFullName(row),
  },
  {
    field: "syncedRoles",
    headerName: "Synced roles",
    sortable: false,
    renderCell: params =>
      params.row.syncedRoles.length ? (
        <RolesList
          roles={params.row.syncedRoles}
          active={!params.row.rolesOverride}
          direction="column"
        />
      ) : (
        "None"
      ),
    flex: 2,
  },
  {
    field: "rolesOverride",
    headerName: "Override roles",
    sortable: false,
    renderCell: params =>
      params.row.rolesOverride &&
      (params.row.rolesOverride.length ? (
        <RolesList roles={params.row.rolesOverride} active direction="column" />
      ) : (
        "Set to None"
      )),
    flex: 2,
  },
  {
    field: "isActive",
    headerName: "Enabled",
    sortable: false,
    flex: 1,
    valueGetter: (_, row) => (row.isActive ? "Yes" : "No"),
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    flex: 1.5,
    renderCell: params => <ActionsCell member={params.row} />,
  },
];

export const SearchUsersList = () => {
  const { searchValue, setSearchValue, debouncedSearchValue } =
    useDebouncedSearch("");

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });

  const { isLoading, isRefetching, data, totalCount } =
    useMemberPermissionsListData({
      searchValue: debouncedSearchValue,
      skip: paginationModel.page * paginationModel.pageSize,
      take: paginationModel.pageSize,
    });

  return (
    <Stack direction="column" sx={{ height: "100%" }}>
      <Box marginBottom="large">
        <h2>Admin - Users</h2>
        <SearchInput
          placeholder="Search"
          value={searchValue}
          onValueChange={setSearchValue}
        />
      </Box>
      {isLoading || isRefetching ? (
        <Stack justify="center" align="center" sx={{ height: "100%" }}>
          <CircularProgress />
        </Stack>
      ) : (
        <TableContainer component={Paper}>
          <DataGrid
            rows={data}
            columns={columns}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={model => setPaginationModel(model)}
            rowCount={totalCount}
            disableColumnMenu
            rowSelection={false}
            rowHeight={100}
            getRowHeight={() => "auto"}
            sx={{
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "16px",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
          />
        </TableContainer>
      )}
    </Stack>
  );
};
