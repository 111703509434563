import { OC_RESTRICTED_OUT_OF_AREA_ACTIVATION_LEVEL_OPTIONS } from "@ses-mams/validation";
import { useHasAnyRole } from "~/context/auth";
import { useOutOfAreaActivationRequestDetails } from "../../hooks";

export const useCanEditOOAARequest = (activationId: string) => {
  const hasOperationCoordinatorRole = useHasAnyRole(["OperationalCoordinator"]);
  const hasOOAACoordinatorRole = useHasAnyRole(["OOAACoordinator"]);

  const { data } = useOutOfAreaActivationRequestDetails(activationId);

  const outOfAreaActivationRequest = data?.body;

  const activation = outOfAreaActivationRequest?.activation;

  return !!(
    hasOOAACoordinatorRole ||
    (hasOperationCoordinatorRole &&
      activation &&
      OC_RESTRICTED_OUT_OF_AREA_ACTIVATION_LEVEL_OPTIONS.includes(
        activation.level
      ))
  );
};
