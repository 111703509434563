import { memo } from "react";
import { Box } from "~/components/ui/box";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

export const IndividualAvailabilityReportHeader = memo(() => {
  return (
    <Stack direction="row" sx={{ width: "100%", userSelect: "none" }}>
      {[...Array(24).keys()].map(v => (
        <Box
          flex={1}
          key={`hour-axis-${v}`}
          display="flex"
          align="center"
          justify="center"
        >
          <Text tone="muted" size="xsmall" weight="medium">
            {v}
          </Text>
        </Box>
      ))}
    </Stack>
  );
});
