import { Group } from "@ses-mams/api-contract";
import { Stack } from "~/components/ui/stack";
import { CapabilityBadge } from "../capability";
import { Text } from "~/components/ui/text";

type GroupListItemProps = {
  group: Group;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
};

export const GroupListItem = ({
  group,
  startAdornment,
  endAdornment,
}: GroupListItemProps) => {
  return (
    <Stack
      background="surface"
      flex={1}
      direction="row"
      justify="space-between"
      align="center"
    >
      <Stack direction="row" gap="medium">
        {startAdornment}

        <Stack gap="small" align="start" paddingY="large">
          <Text size="large" weight="medium">
            {group.name}
          </Text>
          <Stack direction="row" gap="small" wrap>
            {group.capabilities.map(capability => (
              <CapabilityBadge
                capability={capability}
                key={`${group.id}-${capability.id}`}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>

      {endAdornment}
    </Stack>
  );
};
