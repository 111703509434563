import { Button } from "~/components/ui/button";
import { Stack } from "~/components/ui/stack";
import { Box } from "~/components/ui/box";
import { useOwnAvailabilityData } from "./hooks/useOwnAvailabilityData";
import { useCallback, useState } from "react";
import emptyScheduleImageUrl from "~/assets/emptySchedule.png";
import { Heading } from "~/components/ui/heading";
import { GridCellHeightContextProvider } from "./context/gridCellHeightContext";
import {
  HourlyAvailability,
  useAvailabilitySelectedWeek,
  useCopyPasteAvailability,
} from "@ses-mams/react-utils";
import { Hidden } from "@mui/material";
import { Divider } from "~/components/ui/divider";
import { ViewAvailabilityHourlyGrid } from "./components/hourly/ViewAvailabilityHourlyGrid";
import { NoAvailabilityLayout } from "./components/common/NoAvailabilityLayout";
import { Spinner } from "~/components/ui/spinner";
import { ViewScheduleGrid } from "./components/schedule/";
import { Tab, Tabs } from "~/components/ui/tab";
import { Link, useSearchParams } from "react-router-dom";
import { EmptyState } from "~/components/common/emptyState";
import { Dialog, DialogContent } from "~/components/ui/dialog";
import { AvailabilityWeekSelector } from "~/components/common/availabilitySelector";
import { Text } from "~/components/ui/text";
import { useAvailabilityUnit } from "~/context/unit/AvailabilityUnitContextProvider";

export const AvailabilityPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const viewType = searchParams.get("tab") ?? "hourly";

  const { selectedUnit } = useAvailabilityUnit();

  const {
    firstDayOfTheWeek,
    lastDayOfTheWeek,
    goToToday,
    goToNextWeek,
    goToPreviousWeek,
  } = useAvailabilitySelectedWeek();

  const {
    isLoading,
    hourlyAvailability,
    schedule,
    hasAvailabilitySet,
    scheduleAvailability,
    mutate,
    refetch,
  } = useOwnAvailabilityData({
    startOfTheWeek: firstDayOfTheWeek,
    endOfTheWeek: lastDayOfTheWeek,
    unitId: selectedUnit?.id,
  });

  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);

  const onPasteAvailability = useCallback(
    async (updatedAvailability: HourlyAvailability) => {
      await mutate(updatedAvailability);
      await refetch();
    },
    [mutate]
  );

  const { copiedAvailability, handleCopySchedule, handlePasteSchedule } =
    useCopyPasteAvailability({
      firstDayOfTheWeek,
      hourlyAvailability,
      onPasteAvailability,
    });

  return (
    <Stack direction="column" gap="medium" sx={{ height: "100%" }}>
      <Stack direction="row" justify="space-between" align="center">
        <Heading level="1">Availability</Heading>

        <Button variant="primary" href={`/availability/${viewType}/edit`}>
          Edit
        </Button>
      </Stack>

      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Stack
          direction={{
            xs: "column-reverse",
            md: "row",
          }}
          gap="medium"
          justify="space-between"
          align="center"
          padding="medium"
          sx={{
            width: "100%",
          }}
        >
          <Stack
            gap="medium"
            justify="space-between"
            grow={1}
            direction={{
              xs: "column",
              md: "row",
            }}
            sx={{ width: "100%" }}
          >
            <AvailabilityWeekSelector
              firstDayOfTheWeek={firstDayOfTheWeek}
              lastDayOfTheWeek={lastDayOfTheWeek}
              onGoToPreviousWeek={goToPreviousWeek}
              onGoToNextWeek={goToNextWeek}
            />

            <Button variant="secondary" onClick={goToToday}>
              Today
            </Button>
          </Stack>

          <Hidden mdDown={true}>
            <Divider direction="row" tone="neutral" />
          </Hidden>

          <Stack
            direction="row"
            gap="medium"
            sx={{
              width: { xs: "100%", md: "unset" },
            }}
          >
            <Tabs
              value={viewType}
              onChange={(_, v) => setSearchParams({ tab: v })}
              sx={{
                width: { xs: "100%", md: "unset" },
              }}
            >
              <Tab value="hourly" label="Hourly" sx={{ flexGrow: 1 }} />
              <Tab value="schedule" label="Schedule" sx={{ flexGrow: 1 }} />
            </Tabs>
          </Stack>
        </Stack>

        {isLoading && (
          <NoAvailabilityLayout>
            <Box display="flex" justify="center" align="center" flex={1}>
              <Spinner />
            </Box>
          </NoAvailabilityLayout>
        )}

        {!isLoading && viewType === "hourly" && (
          <GridCellHeightContextProvider
            numberOfScheduleItems={schedule.length}
          >
            <ViewAvailabilityHourlyGrid
              availability={hourlyAvailability}
              hasAvailabilitySet={hasAvailabilitySet}
            />
          </GridCellHeightContextProvider>
        )}
        <Dialog
          open={scheduleDialogOpen}
          onClose={() => setScheduleDialogOpen(false)}
        >
          <DialogContent>
            <EmptyState
              image={<img src={emptyScheduleImageUrl} />}
              title="Add your schedule"
              description='To make it easier for you to submit your availability, you can designate time ranges such as "work" from 9:00 - 19:00 and then select that instead of manually submitting hourly availability.'
              action={
                <Link to="/schedule" style={{ width: "100%" }}>
                  <Button fullWidth variant="secondary">
                    Next
                  </Button>
                </Link>
              }
            />
          </DialogContent>
        </Dialog>

        {!isLoading && viewType === "schedule" ? (
          !schedule.length ? (
            <Stack
              justify="center"
              sx={{
                height: "100%",
              }}
            >
              <EmptyState
                image={<img src={emptyScheduleImageUrl} />}
                title="No schedule set"
                description={
                  <Stack>
                    <Text tone="secondary">
                      You haven't created your schedule yet.
                    </Text>
                    <Text tone="secondary">
                      Tap the button below to get started.
                    </Text>
                  </Stack>
                }
                action={
                  <Button onClick={() => setScheduleDialogOpen(true)}>
                    Set schedule
                  </Button>
                }
              />
            </Stack>
          ) : (
            <GridCellHeightContextProvider numberOfScheduleItems={0}>
              <ViewScheduleGrid
                availability={scheduleAvailability}
                schedule={schedule}
              />
            </GridCellHeightContextProvider>
          )
        ) : null}

        <Stack direction="row" gap="medium" padding="medium">
          <Button variant="link" onClick={handleCopySchedule} fullWidth>
            Copy
          </Button>
          <Button
            variant="link"
            disabled={!copiedAvailability}
            onClick={handlePasteSchedule}
            fullWidth
          >
            Paste
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};
