import { Box } from "~/components/ui/box";
import { AvailabilityHourlyGridCell } from "./AvailabilityHourlyGridCell";
import { AvailabilityGridHeader } from "../common/AvailabilityGridHeader";
import { HourlyAvailability } from "@ses-mams/react-utils";
import { Stack } from "~/components/ui/stack";

type AvailabilityHourlyGridProps = {
  availability: HourlyAvailability;
  onAvailabilityEdited?: (editedAvailability: HourlyAvailability) => void;
  onDatePress?: (date: Date) => void;
  onCellPress?: (date: Date, hour: number) => void;
  selectedCells?: { date: Date; hour: number }[];
};

export const AvailabilityHourlyGrid = ({
  availability,
  onCellPress,
  onDatePress,
  selectedCells,
}: AvailabilityHourlyGridProps) => {
  return (
    <Stack flex={1} direction="column">
      <AvailabilityGridHeader
        availability={availability}
        onDatePress={onDatePress}
      />
      <Stack direction="row">
        {availability.map(({ date, slots }) => {
          return (
            <Box key={date.toISOString()} flex={1}>
              {slots.map(slot => {
                const isSelected =
                  selectedCells?.some(
                    selectedCell =>
                      +selectedCell.date === +date &&
                      selectedCell.hour === slot.hour
                  ) ?? false;

                return (
                  <AvailabilityHourlyGridCell
                    key={`${date.toISOString()}-${slot.hour}`}
                    isSelected={isSelected}
                    onCellPress={onCellPress}
                    slot={slot}
                    date={date}
                  />
                );
              })}
            </Box>
          );
        })}
      </Stack>
    </Stack>
  );
};
