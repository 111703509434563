import { ButtonBase } from "@mui/material";
import {
  AVAILABILITY_REPORT_STATUS_TO_BADGE_TONE_MAP,
  OutOfAreaActivationMembersSectionKeys,
  formatAvailabilityStatusLabel,
  formatDate,
  formatDateTime,
  formatMemberFullName,
  formatOutOfAreaActivationRoleCategory,
  useGetCanViewOutOfAreaActivationRequestHistory,
  useGetGroupedOutOfAreaActivationRoles,
  useOutOfAreaActivationSelectedState,
  useUpdateEffect,
} from "@ses-mams/react-utils";
import {
  OutOfAreaActivationRequestMember,
  OutOfAreaActivationRoleCategory,
} from "@ses-mams/api-contract";
import { Fragment, useCallback, useState } from "react";
import { RenderSectionHeaderParams, SectionHeader } from "~/components/ui/list";
import { Stack } from "~/components/ui/stack";
import { Checkbox } from "~/components/ui/checkbox";
import { Text } from "~/components/ui/text";
import { useAuth } from "~/context/auth";
import { DrawerContent, DrawerHeader } from "~/components/ui/drawer";
import {
  OutOfAreaActivationMemberList,
  useOutOfAreaActivationMembersData,
} from "../OutOfAreaActivationMemberList";
import { ManageOOAAMembersFooter } from "./ManageOOAAMembersFooter";
import { ManageOOAAMembersFilters } from "./ManageOOAAMembersFilters";
import { Button } from "~/components/ui/button";
import { Badge } from "~/components/ui/badge";
import { MemberConditionalReason } from "~/components/common/memberStatus";
import { PreApprovedOOAAMembersDrawer } from "./PreApprovedOOAAMembersDrawer";
import { MemberDetailsDrawer } from "~/components/common/memberDetails";
import { IconButton } from "~/components/ui/button";
import { InfoCircleFilledIcon, PlusOutlineIcon } from "~/components/ui/icon";
import { OOAARequestHistoryDrawer } from "../OOAARequestHistoryDrawer";
import { useCanEditOOAARequest } from "../../hooks/useCanEditOOAARequest";

type ManageOOAAMembersProps = {
  isClosed: boolean;
  activationId: string;
  isDrawerOpen: boolean;
  onClose: () => void;
  onAdd?: () => void;
};

export const ManageOOAAMembers = ({
  isDrawerOpen,
  isClosed,
  activationId,
  onClose,
  onAdd,
}: ManageOOAAMembersProps) => {
  const [memberDetailsId, setMemberDetailsId] = useState<string>();
  const [memberHistoryRequestId, setMemberHistoryRequestId] =
    useState<string>();

  const [isPreApprovedMembersDrawerOpen, setIsPreApprovedMembersDrawerOpen] =
    useState(false);

  const canAmend = useCanEditOOAARequest(activationId);

  const {
    sections,
    isLoading: isLoadingMembers,
    fetchNextPage,
    hasNextPage,
  } = useOutOfAreaActivationMembersData(activationId, isDrawerOpen);

  const {
    selectedRequestIds,
    selectedActivationRequestIds,
    selectedDeactivationRequestIds,
    selectedRemovalMemberIds,
    selectedUnsuccessfulMemberIds,
    selectAllState,
    setSelected,
    clearSelected,
    setSelectAllState,
  } = useOutOfAreaActivationSelectedState();

  const { member } = useAuth();
  const { getCanViewOutOfAreaActivationRequestHistory } =
    useGetCanViewOutOfAreaActivationRequestHistory(member);

  useUpdateEffect(() => {
    if (!isDrawerOpen) {
      clearSelected();
    }
  }, [isDrawerOpen]);

  const renderSectionHeader = useCallback(
    ({
      title,
      data,
    }: RenderSectionHeaderParams<OutOfAreaActivationRequestMember>) => {
      const typedKey = title as OutOfAreaActivationMembersSectionKeys;

      const isSelectAllChecked =
        selectAllState[typedKey] &&
        data.every(m => selectedRequestIds.has(m.request.id));

      return (
        <ButtonBase
          disabled={!canAmend}
          onClick={() => {
            setSelected(prev => {
              const prevIds = new Set(prev.map(m => m.request.id));

              const hasUnselected = data.some(m => !prevIds.has(m.request.id));

              setSelectAllState(prevState => ({
                ...prevState,
                [typedKey]: hasUnselected,
              }));

              if (hasUnselected) {
                return [...prev, ...data];
              }

              const dataRequestIds = new Set(data.map(d => d.request.id));

              return prev.filter(p => !dataRequestIds.has(p.request.id));
            });
          }}
          sx={{ width: "100%" }}
        >
          <SectionHeader>
            <Stack direction="row" gap="small">
              {canAmend && <Checkbox checked={isSelectAllChecked} />}

              {title}
            </Stack>
          </SectionHeader>
        </ButtonBase>
      );
    },
    [canAmend, selectAllState, selectedRequestIds]
  );

  const getGroupedOutOfAreaActivationRoles =
    useGetGroupedOutOfAreaActivationRoles();

  const renderItem = useCallback(
    (item: OutOfAreaActivationRequestMember) => {
      const { request, statusUpdatedAt } = item;
      const { member, availabilityBlocks, roles } = request;

      const isChecked = selectedRequestIds.has(request.id);

      const canViewHistory =
        getCanViewOutOfAreaActivationRequestHistory(request);

      const rolesByCategory = getGroupedOutOfAreaActivationRoles(roles);

      return (
        <Stack
          flex={1}
          direction="row"
          gap="xxsmall"
          align="center"
          paddingX="large"
        >
          <ButtonBase
            disabled={!canAmend}
            sx={{
              width: "100%",
            }}
            onClick={() => {
              if (isChecked) {
                setSelected(prev =>
                  prev.filter(p => p.request.id !== request.id)
                );
                return;
              }

              setSelected(prev => [...prev, item]);
            }}
          >
            <Stack flex={1} direction="row" gap="small">
              {canAmend ? <Checkbox checked={isChecked} /> : null}

              <Stack flex={1} gap="small" align="start" paddingY="large">
                {request.unsuccessful ? (
                  <Badge tone="cautionMuted" size="small">
                    Unsuccessful
                  </Badge>
                ) : null}

                <Text align="start" tone="info" weight="semi">
                  {formatMemberFullName(member)}
                </Text>
                <Text align="start" tone="secondary">
                  {formatDateTime(new Date(statusUpdatedAt), "long")}
                </Text>

                {member.units.length ? (
                  <Stack wrap gap="medium" direction="row">
                    {member.units.map(unit => (
                      <Text
                        key={`${member.id}-${unit.id}`}
                        align="start"
                        size="small"
                        weight="medium"
                        tone="secondary"
                      >{`${unit.name}${unit.code ? ` (${unit.code})` : ""}`}</Text>
                    ))}
                  </Stack>
                ) : null}

                {rolesByCategory && (
                  <Stack gap="small">
                    {Object.keys(rolesByCategory).map(category => {
                      const roles =
                        rolesByCategory[
                          category as keyof typeof rolesByCategory
                        ];

                      return (
                        <Stack
                          flex={1}
                          key={`${member.id}-${category}`}
                          gap="xxsmall"
                        >
                          <Text align="start" weight="medium">
                            {formatOutOfAreaActivationRoleCategory(
                              category as OutOfAreaActivationRoleCategory
                            )}
                          </Text>
                          <Text
                            align="start"
                            tone="secondary"
                            size="small"
                            weight="medium"
                          >
                            {roles.map(({ name }) => name).join(", ")}
                          </Text>
                        </Stack>
                      );
                    })}
                  </Stack>
                )}

                {availabilityBlocks.map(block => (
                  <Fragment key={block.id}>
                    <Stack
                      gap="small"
                      direction="row"
                      justify="space-between"
                      align="center"
                      sx={{ width: "100%" }}
                    >
                      <Text
                        align="start"
                        size="small"
                      >{`${formatDate(new Date(block.start))} - ${formatDate(
                        new Date(block.end)
                      )}`}</Text>

                      {block.availabilityStatus && (
                        <Badge
                          tone={
                            AVAILABILITY_REPORT_STATUS_TO_BADGE_TONE_MAP[
                              block.availabilityStatus
                            ]
                          }
                        >
                          {formatAvailabilityStatusLabel({
                            status: block.availabilityStatus,
                            useShort: true,
                          })}
                        </Badge>
                      )}
                    </Stack>

                    {block.availabilityStatus === "Conditional" &&
                      block.conditionalReason && (
                        <MemberConditionalReason
                          conditionalReason={block.conditionalReason}
                        />
                      )}
                  </Fragment>
                ))}
              </Stack>
            </Stack>
          </ButtonBase>

          <Stack gap="medium" align="center">
            {canViewHistory && (
              <Button
                size="small"
                variant="link"
                onClick={() => setMemberHistoryRequestId(request.id)}
              >
                History
              </Button>
            )}

            <IconButton
              size="small"
              onClick={e => {
                e.stopPropagation();

                setMemberDetailsId(member.id);
              }}
            >
              <InfoCircleFilledIcon tone="info" size="xsmall" />
            </IconButton>
          </Stack>
        </Stack>
      );
    },
    [
      canAmend,
      selectedRequestIds,
      getCanViewOutOfAreaActivationRequestHistory,
      getGroupedOutOfAreaActivationRoles,
    ]
  );

  return (
    <>
      <DrawerHeader onClose={onClose}>Members</DrawerHeader>

      <DrawerContent sx={{ paddingX: 0 }}>
        {!isLoadingMembers && (
          <Stack gap="medium" paddingX="large">
            {canAmend && (
              <Button
                variant="tertiary"
                startIcon={PlusOutlineIcon}
                onClick={onAdd}
              >
                Add Members
              </Button>
            )}

            <Stack
              direction="row-reverse"
              justify="space-between"
              gap="xsmall"
              paddingBottom="medium"
            >
              {canAmend && (
                <Button
                  variant="link"
                  onClick={() => setIsPreApprovedMembersDrawerOpen(true)}
                >
                  Pre-approved members
                </Button>
              )}
              <ManageOOAAMembersFilters />
            </Stack>
          </Stack>
        )}
        <OutOfAreaActivationMemberList
          isLoading={isLoadingMembers}
          sections={sections}
          renderItem={renderItem}
          renderSectionHeader={renderSectionHeader}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
        />
      </DrawerContent>

      <ManageOOAAMembersFooter
        activationId={activationId}
        selectedActivationRequestIds={selectedActivationRequestIds}
        selectedDeactivationRequestIds={selectedDeactivationRequestIds}
        selectedRemovalMemberIds={selectedRemovalMemberIds}
        selectedUnsuccessfulMemberIds={selectedUnsuccessfulMemberIds}
        isClosed={isClosed}
        clearSelectedMembers={clearSelected}
      />

      <PreApprovedOOAAMembersDrawer
        activationId={activationId}
        open={isPreApprovedMembersDrawerOpen}
        onClose={() => setIsPreApprovedMembersDrawerOpen(false)}
      />

      {memberDetailsId && (
        <MemberDetailsDrawer
          open={Boolean(memberDetailsId)}
          memberId={memberDetailsId}
          onClose={() => setMemberDetailsId(undefined)}
        />
      )}

      {memberHistoryRequestId && (
        <OOAARequestHistoryDrawer
          open={Boolean(memberHistoryRequestId)}
          requestId={memberHistoryRequestId}
          onClose={() => setMemberHistoryRequestId(undefined)}
        />
      )}
    </>
  );
};
