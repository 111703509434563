import { Capability } from "@ses-mams/api-contract";
import { useCallback, useState } from "react";
import { SelectCapabilitiesDrawer } from "~/components/common/selectCapabilitiesList";
import { EditListSection } from "~/components/common/editListSection";
import { CapabilityListItem } from "~/components/common/capability";

type EditCapabilitiesListProps = {
  selectedCapabilities: Array<Capability>;
  onChangeSelectedCapabilities: (capabilities: Array<Capability>) => void;
  unitId?: string;
  clusterId?: string;
  zoneId?: string;
  disclaimer?: string;
};

export const EditCapabilitiesList = ({
  selectedCapabilities,
  onChangeSelectedCapabilities,
  unitId,
  clusterId,
  zoneId,
  disclaimer,
}: EditCapabilitiesListProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onDone = useCallback(
    (capabilities: Array<Capability>) => {
      onChangeSelectedCapabilities(capabilities);
      setIsDrawerOpen(false);
    },
    [onChangeSelectedCapabilities]
  );

  return (
    <>
      <EditListSection
        title="Capabilities"
        onButtonClick={() => setIsDrawerOpen(true)}
      >
        {selectedCapabilities.map(capability => (
          <CapabilityListItem key={capability.id} capability={capability} />
        ))}
      </EditListSection>

      <SelectCapabilitiesDrawer
        open={isDrawerOpen}
        capabilities={selectedCapabilities}
        onClose={() => setIsDrawerOpen(false)}
        onDone={onDone}
        unitId={unitId}
        clusterId={clusterId}
        zoneId={zoneId}
        disclaimer={disclaimer}
      />
    </>
  );
};
