import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

type ShowMemberCapabilitiesContextType = {
  showMemberCapabilities: boolean;
  setShowMemberCapabilities: Dispatch<SetStateAction<boolean>>;
};

const ShowMemberCapabilitiesContext =
  createContext<ShowMemberCapabilitiesContextType | null>(null);

export const ShowMemberCapabilitiesContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [showMemberCapabilities, setShowMemberCapabilities] = useState(false);

  const value = useMemo(
    () => ({
      showMemberCapabilities,
      setShowMemberCapabilities,
    }),
    [showMemberCapabilities, setShowMemberCapabilities]
  );

  return (
    <ShowMemberCapabilitiesContext.Provider value={value}>
      {children}
    </ShowMemberCapabilitiesContext.Provider>
  );
};

export const useShowMemberCapabilitiesContext = () => {
  const ctx = useContext(ShowMemberCapabilitiesContext);

  if (!ctx) {
    throw new Error(
      "Must be called within <ShowMemberCapabilitiesContextProvider />"
    );
  }

  return ctx;
};
