import { Container } from "@mui/material";
import {
  useCanAmendOutOfAreaActivationRequestResponse,
  useGetCanViewOutOfAreaActivationRequestHistory,
} from "@ses-mams/react-utils";
import { useParams, useSearchParams } from "react-router-dom";
import { BackButton } from "~/components/common/navigation";
import { Stack } from "~/components/ui/stack";
import { Box } from "~/components/ui/box";
import { Spinner } from "~/components/ui/spinner";
import { Heading } from "~/components/ui/heading";
import { Button } from "~/components/ui/button";
import { Protected } from "~/components/common/layout";
import { useAuth } from "~/context/auth";
import {
  useOutOfAreaActivationQuery,
  useOutOfAreaActivationRequestHistoryDetails,
} from "../hooks";
import { OOAARequestHistory } from "./components/OOAARequestHistory";

export const OOAARequestHistoryPage = () => {
  const activationId = useParams().activationId as string;
  const [searchParams] = useSearchParams();
  const requestId = searchParams.get("requestId") as string;

  const { isLoading: isLoadingActivation, data: activationData } =
    useOutOfAreaActivationQuery({ activationId });

  const { isLoading: isLoadingHistory, data: historyData } =
    useOutOfAreaActivationRequestHistoryDetails(requestId);

  const activation = activationData?.body;
  const history = historyData?.body?.history;
  const request = historyData?.body?.request;

  const { member } = useAuth();
  const { getCanViewOutOfAreaActivationRequestHistory } =
    useGetCanViewOutOfAreaActivationRequestHistory(member);

  const canAmend = useCanAmendOutOfAreaActivationRequestResponse({
    member,
    activation,
    request,
  });

  const hasDeploymentRoles =
    activation && activation?.deployments?.flatMap(d => d.roles)?.length > 0;

  const isLoading = isLoadingHistory || isLoadingActivation;

  return (
    <Protected
      shouldRedirect={
        !isLoading && !getCanViewOutOfAreaActivationRequestHistory(request)
      }
      redirectPath={`/requests/out-of-area-activations/${activationId}`}
    >
      {isLoading || !activation || !history || !request ? (
        <Box display="flex" grow={1} justify="center">
          <Spinner />
        </Box>
      ) : (
        <Container maxWidth="sm">
          <Stack gap="xlarge">
            <BackButton />

            <Stack direction="row" justify="space-between">
              <Heading level="2">History</Heading>

              {canAmend && request && (
                <Button
                  href={`/requests/out-of-area-activations/${activationId}/request/${
                    request.id
                  }/amend/${hasDeploymentRoles ? "roles" : "availability"}`}
                >
                  Amend
                </Button>
              )}
            </Stack>

            <OOAARequestHistory history={history} request={request} />
          </Stack>
        </Container>
      )}
    </Protected>
  );
};
