import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ChannelList as StreamChatList } from "stream-chat-react";
import { ChannelFilters } from "stream-chat";
import { Unit } from "@ses-mams/api-contract";
import { useDebouncedSearch } from "@ses-mams/react-utils";
import { Button } from "~/components/ui/button";
import { Heading } from "~/components/ui/heading";
import { FilterOutlineIcon } from "~/components/ui/icon";
import { SearchInput } from "~/components/ui/searchInput";
import { Stack } from "~/components/ui/stack";
import { Box } from "~/components/ui/box";
import { PopoverRoot, PopoverTrigger } from "~/components/ui/popover";
import { useAuth } from "~/context/auth";
import { ChannelListPreview } from "./ChannelListPreview";
import { ChannelFilterPopover } from "./ChannelFilterPopover";

export const ChannelList = () => {
  const { member } = useAuth();

  const [searchParams] = useSearchParams();
  const channelId = searchParams.get("channelId");

  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);
  const [filteredUnits, setFilteredUnits] = useState<Array<Unit>>([]);

  const { searchValue, setSearchValue, debouncedSearchValue } =
    useDebouncedSearch("");

  const channelListFilters = useMemo(() => {
    const andFilters: NonNullable<ChannelFilters["$and"]> = [
      { members: { $in: [member?.id ?? ""] } },
    ];

    if (debouncedSearchValue) {
      andFilters.push({
        $or: [
          {
            name: { $autocomplete: debouncedSearchValue },
          },
          {
            "member.user.name": { $autocomplete: debouncedSearchValue },
          },
        ],
      });
    }

    if (filteredUnits.length) {
      if (filteredUnits.length > 1) {
        andFilters.push({
          // @ts-ignore
          $or: filteredUnits.map(u => ({
            description: { $autocomplete: u.name },
          })),
        });
      } else {
        andFilters.push({
          description: { $autocomplete: filteredUnits[0].name },
        });
      }
    }

    return {
      $and: andFilters,
    } as ChannelFilters;
  }, [member, debouncedSearchValue, filteredUnits]);

  return (
    <Box
      overflowY="hidden"
      // On small devices, hide the list if a channel is selected
      display={{ xs: channelId ? "none" : "flex", md: "flex" }}
      sx={{
        height: "100%",
        width: "100%",
        maxWidth: { xs: undefined, md: "440px" },
        minWidth: { xs: undefined, md: "440px" },
      }}
    >
      <Stack gap="large" sx={{ width: "100%" }}>
        <Stack direction="row" align="center" justify="space-between">
          <Heading level="4">All Channels</Heading>

          <PopoverRoot
            open={isFilterPopoverOpen}
            onOpenChange={setIsFilterPopoverOpen}
          >
            <PopoverTrigger>
              <Button variant="secondary" startIcon={FilterOutlineIcon}>
                Filter
              </Button>
            </PopoverTrigger>

            <ChannelFilterPopover
              setOpen={setIsFilterPopoverOpen}
              selectedUnits={filteredUnits}
              onApplyClick={setFilteredUnits}
            />
          </PopoverRoot>
        </Stack>

        <SearchInput value={searchValue} onValueChange={setSearchValue} />

        <StreamChatList
          customActiveChannel={channelId ?? undefined}
          filters={channelListFilters}
          Preview={ChannelListPreview}
        />
      </Stack>
    </Box>
  );
};
