import { FormProvider, useForm } from "react-hook-form";
import { OutOfAreaActivationForm } from "./OutOfAreaActivationForm";
import { OutOfAreaActivation } from "@ses-mams/api-contract";
import {
  OutOfAreaActivationFormSchema,
  outOfAreaFormSchema,
} from "@ses-mams/validation";
import { zodResolver } from "@hookform/resolvers/zod";
import { transformOoaaActivationToForm } from "@ses-mams/react-utils";

type Props = {
  activation: OutOfAreaActivation;
};

export const EditOutOfAreaActivationForm = ({ activation }: Props) => {
  const formMethods = useForm<OutOfAreaActivationFormSchema>({
    resolver: zodResolver(outOfAreaFormSchema),
    defaultValues: transformOoaaActivationToForm(activation),
  });

  return (
    <FormProvider {...formMethods}>
      <OutOfAreaActivationForm activation={activation} />
    </FormProvider>
  );
};
