import { AvailabilityStatus, ScheduleItem } from "@ses-mams/api-contract";
import { useCallback, useState, useEffect, useRef } from "react";
import { addDays } from "date-fns";
import keyBy from "lodash.keyby";
import merge from "lodash.merge";

export type AvailabilitySlot = {
  hour: number;
  status: AvailabilityStatus | null;
  committed: boolean;
  conditionalReason: string | null;
  emergenciesOnly: boolean | null;
};

export type ScheduleAvailability = {
  date: Date;
  scheduleItems: {
    scheduleItem: ScheduleItem;
    slots: AvailabilitySlot[];
  }[];
}[];

export type HourlyAvailability = {
  date: Date;
  slots: AvailabilitySlot[];
}[];

export function useEditScheduleGrid({
  schedule,
  firstDayOfTheWeek,
  lastDayOfTheWeek,
  availability,
  onAvailabilityEdited,
  selectedValue,
}: {
  schedule: ScheduleItem[];
  firstDayOfTheWeek: Date;
  lastDayOfTheWeek: Date;
  availability: ScheduleAvailability;
  onAvailabilityEdited: (editedAvailability: HourlyAvailability) => void;
  selectedValue: {
    status: AvailabilityStatus | null;
    emergenciesOnly?: boolean;
    conditionalReason?: string;
  } | null;
}) {
  const [selectedCells, setSelectedCells] = useState<
    { date: Date; scheduleItemId: string }[]
  >([]);

  const availabilityGridData = useRef<ScheduleAvailability>([]);
  const [liveEditableData, setLiveEditableData] = useState([...availability]);

  useEffect(() => {
    if (availability.length === 0) {
      return;
    }

    setLiveEditableData(prevLiveData => {
      const mergedData = merge(
        {},
        keyBy(availability, "date"),
        keyBy(prevLiveData, "date")
      );

      const newLiveData = Object.values(mergedData);

      availabilityGridData.current = newLiveData.filter(
        ({ date }) =>
          //@ts-ignore
          date >= availability[0].date && //@ts-ignore
          date <= availability[availability.length - 1].date
      );

      return newLiveData;
    });
  }, [availability]);

  useEffect(() => {
    if (!selectedValue?.status) {
      return;
    }

    const editedAvailability: HourlyAvailability = [];

    for (const selectedCell of selectedCells) {
      const liveDataCell = liveEditableData
        .find(({ date }) => +date === +selectedCell.date)
        ?.scheduleItems.find(
          ({ scheduleItem }) => scheduleItem.id === selectedCell.scheduleItemId
        );

      if (!liveDataCell) {
        continue;
      }

      liveDataCell.slots.forEach(slot => {
        slot.status = selectedValue.status as AvailabilityStatus;

        if (selectedValue.status === "ImmediatelyAvailable") {
          slot.emergenciesOnly = selectedValue.emergenciesOnly ?? null;
        } else if (selectedValue.status === "Conditional") {
          slot.conditionalReason = selectedValue.conditionalReason ?? null;
        }
      });

      let editedEntry = editedAvailability.find(
        ({ date }) => +date === +selectedCell.date
      );
      if (!editedEntry) {
        editedEntry = {
          date: selectedCell.date,
          slots: [],
        };

        editedAvailability.push(editedEntry);
      }

      editedEntry.slots.push(...liveDataCell.slots);
    }

    setSelectedCells([]);
    onAvailabilityEdited(editedAvailability);
  }, [selectedValue, liveEditableData]);
  const handleDatePress = useCallback(
    (date: Date) => {
      setSelectedCells(prevSelectedCells => {
        const selectedCellCountBeforeFiltering = prevSelectedCells.length;

        const selectedCellsWithoutSelectedDate = prevSelectedCells.filter(
          prevSelectedCell => +prevSelectedCell.date !== +date
        );

        const selectedCellCountAfterFiltering =
          selectedCellsWithoutSelectedDate.length;

        if (
          selectedCellCountBeforeFiltering - selectedCellCountAfterFiltering ===
          schedule.length
        ) {
          return selectedCellsWithoutSelectedDate;
        }

        return [
          ...selectedCellsWithoutSelectedDate,
          ...schedule.map(({ id }) => ({
            date,
            scheduleItemId: id,
          })),
        ];
      });
    },
    [schedule]
  );

  const handleScheduleItemPress = useCallback(
    (scheduleItemId: string) => {
      setSelectedCells(prevSelectedCells => {
        const selectedCellCountBeforeFiltering = prevSelectedCells.length;

        const selectedCellsWithoutSelectedHour = prevSelectedCells.filter(
          prevSelectedCell =>
            !(
              prevSelectedCell.scheduleItemId === scheduleItemId &&
              prevSelectedCell.date >= firstDayOfTheWeek &&
              prevSelectedCell.date <= lastDayOfTheWeek
            )
        );

        const selectedCellCountAfterFiltering =
          selectedCellsWithoutSelectedHour.length;

        if (
          selectedCellCountBeforeFiltering - selectedCellCountAfterFiltering ===
          7
        ) {
          return selectedCellsWithoutSelectedHour;
        }

        const newlySelectedCells: typeof prevSelectedCells = [];

        let currentCalculatedDate = firstDayOfTheWeek;
        while (currentCalculatedDate <= lastDayOfTheWeek) {
          newlySelectedCells.push({
            date: currentCalculatedDate,
            scheduleItemId,
          });

          currentCalculatedDate = addDays(currentCalculatedDate, 1);
        }

        return [...selectedCellsWithoutSelectedHour, ...newlySelectedCells];
      });
    },
    [firstDayOfTheWeek, lastDayOfTheWeek]
  );

  const handleCellPress = useCallback((date: Date, scheduleItemId: string) => {
    setSelectedCells(prevSelectedCells => {
      if (
        prevSelectedCells.some(
          prevSelectedCell =>
            +prevSelectedCell.date === +date &&
            prevSelectedCell.scheduleItemId === scheduleItemId
        )
      ) {
        return prevSelectedCells.filter(
          prevSelectedCell =>
            !(
              +prevSelectedCell.date === +date &&
              prevSelectedCell.scheduleItemId === scheduleItemId
            )
        );
      }

      return [
        {
          date,
          scheduleItemId,
        },
        ...prevSelectedCells,
      ];
    });
  }, []);

  return {
    selectedCells,
    setSelectedCells,
    handleCellPress,
    handleDatePress,
    handleScheduleItemPress,
    liveEditableData,
    setLiveEditableData,
    availabilityGridData,
  };
}
