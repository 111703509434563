import { Capability } from "@ses-mams/api-contract";
import { useArrayState } from "@ses-mams/react-utils";
import { useCallback } from "react";
import { Button } from "~/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerProps,
} from "~/components/ui/drawer";
import { SelectCapabilitiesList } from "./SelectCapabilitiesList";

type SelectCapabilitiesDrawerProps = Omit<DrawerProps, "children"> & {
  capabilities: Array<Capability>;
  onDone: (capabilities: Array<Capability>) => void;
  unitId?: string;
  clusterId?: string;
  zoneId?: string;
  disclaimer?: string;
};

export const SelectCapabilitiesDrawer = ({
  open,
  onClose,
  onDone,
  capabilities,
  unitId,
  clusterId,
  zoneId,
  disclaimer,
}: SelectCapabilitiesDrawerProps) => {
  const [selectedCapabilities, { append, remove, setValue }] =
    useArrayState(capabilities);

  const handleOnClose = useCallback(() => {
    setValue([...capabilities]);

    onClose();
  }, [capabilities, onClose]);

  const handleDoneClick = useCallback(() => {
    onDone(selectedCapabilities);
    onClose();
  }, [onDone, selectedCapabilities]);

  return (
    <Drawer open={open} onClose={handleOnClose}>
      <DrawerHeader onClose={handleOnClose}>Select Capabilities</DrawerHeader>

      <DrawerContent>
        <SelectCapabilitiesList
          capabilities={selectedCapabilities}
          appendCapability={append}
          removeCapability={remove}
          unitId={unitId}
          clusterId={clusterId}
          zoneId={zoneId}
          disclaimer={disclaimer}
        />
      </DrawerContent>

      <DrawerFooter>
        <Button fullWidth onClick={handleDoneClick}>
          Done
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
