import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export const MIN_SCHEDULE_CELL_HEIGHT = 50;

type GridCellHeightContextType = {
  hourCellHeight: number;
  scheduleCellHeight: number;
  setHourCellHeight: (cellHeight: number) => void;
  setScheduleCellHeight: (gridHeight: number) => void;
};

const GridCellHeightContext = createContext<GridCellHeightContextType>({
  hourCellHeight: 0,
  scheduleCellHeight: 0,
  setHourCellHeight: () => null,
  setScheduleCellHeight: () => null,
});

const minScheduleRowHeightPx = 132;

export const GridCellHeightContextProvider = ({
  children,
  numberOfScheduleItems,
}: {
  children: React.ReactNode;
  numberOfScheduleItems: number;
}) => {
  const [hourCellHeight, setHourCellHeight] = useState(0);
  const [scheduleCellHeight, setScheduleCellHeightState] = useState(
    minScheduleRowHeightPx
  );

  const setScheduleCellHeight = useCallback(
    (gridHeight: number) => {
      const proratedHeight = gridHeight / (numberOfScheduleItems || 1);
      setScheduleCellHeightState(
        Math.max(MIN_SCHEDULE_CELL_HEIGHT, proratedHeight)
      );
    },
    [numberOfScheduleItems]
  );

  const value = useMemo(
    () => ({
      hourCellHeight,
      scheduleCellHeight,
      setHourCellHeight,
      setScheduleCellHeight,
    }),
    [
      hourCellHeight,
      scheduleCellHeight,
      setHourCellHeight,
      setScheduleCellHeight,
    ]
  );

  return (
    <GridCellHeightContext.Provider value={value}>
      {children}
    </GridCellHeightContext.Provider>
  );
};

export const useGridCellHeight = () => {
  const ctx = useContext(GridCellHeightContext);

  if (!ctx) {
    throw new Error("Must be called within <GridCellHeightContextProvider />");
  }

  return ctx;
};
