import { useCallback, useState } from "react";
import { Drawer, DrawerProps } from "~/components/ui/drawer";
import { ManageActivityScheduleMembers } from "./ManageActivityScheduleMembers";
import { AddActivityScheduleMembers } from "./AddActivityScheduleMembers";

type ManageActivityScheduleMembersDrawerProps = Omit<
  DrawerProps,
  "children"
> & {
  scheduleId: string;
  isClosed: boolean;
  unitId: string;
};

export const ManageActivityScheduleMembersDrawer = ({
  scheduleId,
  isClosed,
  onClose,
  unitId,
  ...props
}: ManageActivityScheduleMembersDrawerProps) => {
  const [showAddMembers, setShowAddMembers] = useState(false);

  const handleOnClose = useCallback(() => {
    onClose();
    setShowAddMembers(false);
  }, [onClose]);

  return (
    <Drawer {...props} onClose={handleOnClose}>
      {showAddMembers ? (
        <AddActivityScheduleMembers
          scheduleId={scheduleId}
          onClose={handleOnClose}
          onBack={() => setShowAddMembers(false)}
          unitId={unitId}
        />
      ) : (
        <ManageActivityScheduleMembers
          scheduleId={scheduleId}
          isClosed={isClosed}
          onClose={handleOnClose}
          onAddMembersClick={() => setShowAddMembers(true)}
        />
      )}
    </Drawer>
  );
};
