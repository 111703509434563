import { Theme } from "@mui/material";

export const inputBaseStyles = (theme: Theme, hideLabel?: boolean) => {
  const { tokens, transitions } = theme;

  return {
    "label + &": {
      marginTop: hideLabel ? undefined : tokens.spacing.xlarge,
    },
    "& .MuiInputBase-input": {
      border: `${tokens.border.width.medium}px solid`,
      borderColor: tokens.border.color.field,
      borderRadius: tokens.border.radius.large,
      padding: `${tokens.spacing.small}px ${tokens.spacing.medium}px`,
      transition: transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      "&:focus": {
        borderColor: tokens.border.color.accent,
      },
    },
  };
};
