import {
  FormControl,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  useTheme,
} from "@mui/material";
import { forwardRef } from "react";
import { HelperText, Input, Label } from "~/components/ui/textField";
import { useId } from "~/hooks/useId";

type SelectFieldProps = Pick<
  MuiSelectProps,
  | "id"
  | "defaultValue"
  | "value"
  | "disabled"
  | "placeholder"
  | "onChange"
  | "onBlur"
  | "sx"
  | "children"
  | "multiple"
> & {
  label: string;
  hideLabel?: boolean;
  errorMessage?: string;
};

export const SelectField = forwardRef<HTMLInputElement, SelectFieldProps>(
  (
    {
      hideLabel,
      label,
      errorMessage,
      disabled,
      sx,
      value = "",
      ...selectProps
    },
    forwardedRef
  ) => {
    const id = useId(selectProps.id);
    const helperTextId = useId();
    const isInvalid = Boolean(errorMessage);

    const {
      tokens: { typography },
    } = useTheme();

    return (
      <FormControl variant="standard" fullWidth>
        <Label htmlFor={id} disabled={disabled} hideLabel={hideLabel}>
          {label}
        </Label>
        <MuiSelect
          {...selectProps}
          variant="outlined"
          ref={forwardedRef}
          fullWidth
          value={value}
          id={id}
          error={isInvalid}
          disabled={disabled}
          aria-describedby={isInvalid ? helperTextId : undefined}
          input={<Input hideLabel={hideLabel} />}
          sx={{
            ...sx,
            fontWeight: typography.fontWeight.medium,
          }}
        />
        {errorMessage && (
          <HelperText id={helperTextId} role="alert">
            {errorMessage}
          </HelperText>
        )}
      </FormControl>
    );
  }
);
