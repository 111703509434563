import {
  createContext,
  createRef,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

type PopoverRootProps = Partial<
  Pick<PopoverContextType, "open" | "onOpenChange">
> & {
  children: React.ReactNode;
};

export const PopoverRoot = ({
  open: openProp,
  onOpenChange,
  children,
}: PopoverRootProps) => {
  const [openState, setOpenState] = useState(!!openProp);

  const open = typeof openProp === "undefined" ? openState : openProp;

  const triggerRef = useRef<HTMLDivElement>(null);

  const handleOpenChange = useCallback(
    (value: boolean) => {
      onOpenChange?.(value);
      setOpenState(value);
    },
    [onOpenChange]
  );

  const handleOpenToggle = useCallback(() => {
    handleOpenChange(!open);
  }, [handleOpenChange, open]);

  return (
    <PopoverContext.Provider
      value={{
        triggerRef,
        open,
        onOpenChange: handleOpenChange,
        onOpenToggle: handleOpenToggle,
      }}
    >
      {children}
    </PopoverContext.Provider>
  );
};

type PopoverContextType = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onOpenToggle: () => void;
  triggerRef: React.RefObject<HTMLDivElement>;
};

const PopoverContext = createContext<PopoverContextType>({
  triggerRef: createRef(),
  open: false,
  onOpenChange: () => null,
  onOpenToggle: () => null,
});

export const usePopoverContext = () => {
  const ctx = useContext(PopoverContext);

  if (!ctx) {
    throw new Error("Must be called within <PopoverRoot />");
  }

  return ctx;
};
