import usePlacesAutocomplete, { Suggestion } from "use-places-autocomplete";
import {
  OutOfAreaActivationFormSchema,
  getEmptyDeploymentItem,
} from "@ses-mams/validation";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Autocomplete } from "~/components/ui/autocomplete";
import { Button } from "~/components/ui/button";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { HelperText, TextField } from "~/components/ui/textField";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useGoogleMaps } from "~/components/ui/map";
import { DateTimeField } from "~/components/ui/dateTimeField";
import { useSelectRolesDrawer } from "~/components/common/selectRolesDrawer";
import { OutOfAreaActivationRole } from "@ses-mams/api-contract";
import { ButtonBase } from "@mui/material";
import { PlusOutlineIcon } from "~/components/ui/icon";

export const DeploymentForm = () => {
  const {
    register,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<OutOfAreaActivationFormSchema>();

  const { fields, remove, append } = useFieldArray({
    control,
    name: "deployments",
  });

  const placesService = useRef<google.maps.places.PlacesService>();

  const { isGoogleMapsApiLoaded } = useGoogleMaps();

  const [selectedDeploymentIndex, setSelectedDeploymentIndex] = useState<
    number | null
  >(null);

  const handleRolesSelected = useCallback(
    (roles: Array<OutOfAreaActivationRole>) => {
      if (selectedDeploymentIndex !== null) {
        setValue(`deployments.${selectedDeploymentIndex}.roles`, roles);
      }
    },
    [setValue, selectedDeploymentIndex]
  );

  const { openDrawer, renderDrawer } =
    useSelectRolesDrawer(handleRolesSelected);

  // Controlled Field Array https://react-hook-form.com/docs/usefieldarray
  // so that fields are updated after selecting roles
  const watchDeploymentFieldArray = watch("deployments");
  const controlledDeploymentFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchDeploymentFieldArray[index],
    };
  });

  const {
    init: initPlacesAutocomplete,
    suggestions: { loading, data },
    setValue: setSearchValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      region: "au",
    },
    initOnMount: false,
    debounce: 600,
  });

  useEffect(() => {
    if (isGoogleMapsApiLoaded) {
      initPlacesAutocomplete();
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
  }, [isGoogleMapsApiLoaded]);

  const deploymentsError =
    errors?.deployments?.message ?? errors?.deployments?.root?.message;

  return (
    <Stack gap="medium">
      <Text size="large" weight="semi">
        Deployments
      </Text>

      {deploymentsError && (
        <HelperText role="alert">{deploymentsError}</HelperText>
      )}

      <Stack gap="large" dividers>
        {controlledDeploymentFields.map((item, index) => {
          return (
            <Stack key={item.id} gap="small">
              <Stack direction="row" justify="space-between" align="center">
                <Text tone="muted" size="large" weight="semi">{`Deployment ${
                  index + 1
                }`}</Text>

                <Button variant="destructive" onClick={() => remove(index)}>
                  Delete
                </Button>
              </Stack>

              <Stack gap="xlarge">
                <TextField
                  {...register(`deployments.${index}.location`)}
                  label="Location name"
                  placeholder="Enter location name"
                  errorMessage={errors.deployments?.[index]?.location?.message}
                />

                <Controller
                  name={`deployments.${index}.address`}
                  control={control}
                  render={({ field: { value }, fieldState }) => {
                    return (
                      <Autocomplete
                        label="Address"
                        placeholder="Select address"
                        options={data}
                        defaultValue={{ description: value } as Suggestion}
                        onChange={(
                          e: SyntheticEvent,
                          place: Suggestion | null
                        ) => {
                          if (!place) {
                            setValue(`deployments.${index}.address`, "");
                            setValue(`deployments.${index}.latitude`, -1);
                            setValue(`deployments.${index}.longitude`, -1);
                          } else {
                            setValue(
                              `deployments.${index}.address`,
                              place.description
                            );
                            placesService.current?.getDetails(
                              { placeId: place.place_id },
                              result => {
                                const latitude =
                                  result?.geometry?.location?.lat();
                                if (latitude) {
                                  setValue(
                                    `deployments.${index}.latitude`,
                                    latitude,
                                    {
                                      shouldValidate: true,
                                    }
                                  );
                                }
                                const longitude =
                                  result?.geometry?.location?.lng();
                                if (longitude) {
                                  setValue(
                                    `deployments.${index}.longitude`,
                                    longitude,
                                    {
                                      shouldValidate: true,
                                    }
                                  );
                                }
                              }
                            );
                          }
                        }}
                        noOptionsText="No locations found"
                        getOptionLabel={option => option.description}
                        loading={loading}
                        onSearchValueChange={val => setSearchValue(val)}
                        errorMessage={fieldState.error?.message}
                      />
                    );
                  }}
                />

                <TextField
                  {...register(`deployments.${index}.description`)}
                  label="Description"
                  placeholder="Enter description"
                  multiline
                  rows={3}
                  errorMessage={
                    errors.deployments?.[index]?.description?.message
                  }
                />

                <Controller
                  name={`deployments.${index}.start`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DateTimeField
                      label="Activation Start"
                      value={field.value}
                      onValueChange={field.onChange}
                      onBlur={field.onBlur}
                      errorMessage={
                        fieldState.error?.message ||
                        errors?.deployments?.[index]?.start?.date?.message ||
                        errors?.deployments?.[index]?.start?.time?.message
                      }
                    />
                  )}
                />

                <Controller
                  name={`deployments.${index}.end`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DateTimeField
                      label="Activation End"
                      value={field.value}
                      onValueChange={field.onChange}
                      onBlur={field.onBlur}
                      errorMessage={
                        fieldState.error?.message ||
                        errors?.deployments?.[index]?.end?.date?.message ||
                        errors?.deployments?.[index]?.end?.time?.message
                      }
                    />
                  )}
                />

                {item.roles && item.roles?.length > 0 ? (
                  <Stack gap="medium">
                    <Text size="standard" weight="medium">
                      Roles
                    </Text>
                    <ButtonBase
                      onClick={() => {
                        setSelectedDeploymentIndex(index);
                        openDrawer(item.roles ?? []);
                      }}
                    >
                      <Stack align="flex-start" sx={{ width: "100%" }}>
                        <Text tone="action" weight="medium" align="left">
                          {item.roles.map(role => role.name).join(", ")}
                        </Text>
                      </Stack>
                    </ButtonBase>
                  </Stack>
                ) : (
                  <Button
                    variant="tertiary"
                    startIcon={PlusOutlineIcon}
                    onClick={() => {
                      setSelectedDeploymentIndex(index);
                      openDrawer([]);
                    }}
                  >
                    Add Roles
                  </Button>
                )}
              </Stack>
            </Stack>
          );
        })}

        <Button
          variant="tertiary"
          startIcon={PlusOutlineIcon}
          onClick={() => {
            append(getEmptyDeploymentItem());
          }}
        >
          Add Deployment
        </Button>
      </Stack>

      {renderDrawer()}
    </Stack>
  );
};
