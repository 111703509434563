import { ButtonBase } from "@mui/material";
import { formatDayOfMonth, formatDayOfWeek } from "@ses-mams/react-utils";
import { addDays, isSameDay } from "date-fns";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

type AvailabilityDayOfWeekSelectorProps = Pick<
  DayOfWeekButtonProps,
  "onSelect"
> & {
  start: Date;
  selectedDate: Date;
};

export const AvailabilityDayOfWeekSelector = ({
  start,
  selectedDate,
  onSelect,
}: AvailabilityDayOfWeekSelectorProps) => {
  return (
    <Stack direction="row" align="center" dividers dividerTone="neutral">
      {[...Array(7).keys()].map(i => {
        const date = addDays(start, i);

        return (
          <DayOfWeekButton
            key={date.toISOString()}
            date={date}
            selected={isSameDay(date, selectedDate)}
            onSelect={onSelect}
          />
        );
      })}
    </Stack>
  );
};

type DayOfWeekButtonProps = {
  date: Date;
  onSelect: (date: Date) => void;
  selected?: boolean;
};

const DayOfWeekButton = ({
  date,
  selected,
  onSelect,
}: DayOfWeekButtonProps) => {
  return (
    <ButtonBase
      aria-selected={selected}
      onClick={() => onSelect(date)}
      sx={theme => ({
        width: "100%",
        backgroundColor: selected
          ? theme.tokens.colors.background.info
          : undefined,
      })}
    >
      <Stack paddingY="small">
        <Text
          size="large"
          weight="semi"
          tone={selected ? "inverted" : "primary"}
        >
          {formatDayOfMonth(date)}
        </Text>
        <Text
          size="small"
          weight="medium"
          tone={selected ? "inverted" : "secondary"}
        >
          {formatDayOfWeek(date)}
        </Text>
      </Stack>
    </ButtonBase>
  );
};
