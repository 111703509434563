import { Role } from "@ses-mams/api-contract";
import { Navigate, Outlet } from "react-router-dom";
import { useHasAnyRole } from "~/context/auth";
import { DEFAULT_AUTH_ROUTE } from "~/context/auth/constants";

type ProtectedByRolesProps = Pick<
  ProtectedProps,
  "redirectPath" | "children"
> & {
  roles: Array<Role>;
};

export const ProtectedByRoles = ({
  roles,
  ...props
}: ProtectedByRolesProps) => {
  const doesNotHaveRoles = !useHasAnyRole(roles);

  return <Protected {...props} shouldRedirect={doesNotHaveRoles} />;
};

type ProtectedProps = {
  shouldRedirect: boolean;
  redirectPath?: string;
  children?: React.ReactNode;
};

export const Protected = ({
  shouldRedirect,
  redirectPath = DEFAULT_AUTH_ROUTE,
  children,
}: ProtectedProps) => {
  if (shouldRedirect) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ?? <Outlet />;
};
