import { Container } from "@mui/material";
import {
  AvailabilityReportStatus,
  AvailabilityStatus,
  Member,
} from "@ses-mams/api-contract";
import {
  formatAvailabilityStatusLabel,
  useTransformPagedDataToItems,
} from "@ses-mams/react-utils";
import { useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { BackButton } from "~/components/common/navigation";
import { MemberListItemDetailsButton } from "~/components/common/searchMembersList";
import { Heading } from "~/components/ui/heading";
import { InfiniteList } from "~/components/ui/list";
import { Stack } from "~/components/ui/stack";
import { tsr } from "~/utils/client";
import { DEFAULT_PAGE_SIZE, getNextPageParam } from "~/utils/pagination";

export const UnitMembersByAvailabilityPage = () => {
  const { unitId } = useParams();
  const [searchParams] = useSearchParams();
  const availabilityReportStatus = searchParams.get(
    "availabilityReportStatus"
  ) as AvailabilityReportStatus;
  const startDate = searchParams.get("startDate") as string;
  const endDate = searchParams.get("endDate") as string;
  const groupId = searchParams.get("groupId") ?? undefined;

  const { data, hasNextPage, fetchNextPage } =
    tsr.units.getMembersByAvailability.useInfiniteQuery({
      queryKey: [
        "units",
        unitId,
        "availability",
        availabilityReportStatus,
        startDate,
        endDate,
        groupId,
      ],
      queryData: ({ pageParam = { skip: 0, take: DEFAULT_PAGE_SIZE } }) => ({
        query: {
          startDate,
          endDate,
          availabilityReportStatus,
          groupId,
          skip: pageParam.skip,
          take: pageParam.take,
        },
        params: {
          unitId: unitId as string,
        },
      }),
      initialPageParam: { skip: 0, take: DEFAULT_PAGE_SIZE },
      getNextPageParam,
    });

  const renderItem = useCallback((item: Member) => {
    return <MemberListItemDetailsButton member={item} showAvailabilityStatus />;
  }, []);

  const items = useTransformPagedDataToItems(data);

  return (
    <Container maxWidth="sm">
      <Stack gap="medium">
        <BackButton />

        <Heading level="1">
          {availabilityReportStatus === "Committed"
            ? availabilityReportStatus
            : formatAvailabilityStatusLabel({
                status: availabilityReportStatus as AvailabilityStatus,
              })}
        </Heading>

        <InfiniteList
          data={items}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          renderItem={renderItem}
          keyExtractor={item => item.id}
        />
      </Stack>
    </Container>
  );
};
