import {
  format,
  formatDistanceToNowStrict,
  isBefore,
  set,
  subDays,
} from "date-fns";
import {
  ActivationType,
  AvailabilityStatus,
  MemberSummary,
  ActivityRequestStatus,
  MemberStatus,
  OutOfAreaActivationRequestStatus,
  OutOfAreaActivationLevel,
  OutOfAreaActivationRoleCategory,
} from "@ses-mams/api-contract";
import { getHoursAndMinutesFromTimeString } from "@ses-mams/validation";

export function formatMemberFullName(member: MemberSummary) {
  return [
    member.firstName,
    member.preferredName ? `(${member.preferredName})` : undefined,
    member.lastName,
    member.type === "Staff" ? "(Staff)" : undefined,
  ]
    .filter(x => !!x)
    .join(" ");
}

export const formatActivationType = (type: ActivationType): string => {
  switch (type) {
    case "Urgent":
      return "Urgent";
    case "NotUrgent":
      return "Not urgent";
  }
};

export function formatTwentyFourHourOnly(date: Date) {
  return format(date, "HH");
}

export const TWENTY_FOUR_HOUR_FORMAT = "HH:mm";

export function formatTwentyFourHourTime(date: Date) {
  return format(date, TWENTY_FOUR_HOUR_FORMAT);
}

export function formatDayOfWeek(date: Date, short?: boolean) {
  return format(date, short ? "EEEEEE" : "E");
}

export function formatDayOfMonth(date: Date) {
  return format(date, "d");
}

export function formatDateForServer(date: Date) {
  return format(date, "yyyy-MM-dd'T'HH:mm");
}

export function formatDate(date: Date) {
  return format(date, "MMM d, uuuu");
}

export function formatDateTime(
  date: Date,
  mode: "standard" | "long" = "standard"
) {
  const f = mode === "standard" ? "dd/MM/yy HH:mm" : "dd MMM yyyy - HH:mm";

  return format(date, f);
}

export function formatMemberStatusLabel(status: MemberStatus | "Unavailable") {
  switch (status) {
    case "GoingToJob":
      return "Going to Location";
    case "GoingToHQ":
      return "Going to HQ";
    default:
      return "Unavailable";
  }
}

export function formatDistanceToNowWithinThreshold(
  date: Date,
  daysThreshold = 1
) {
  if (isBefore(date, subDays(new Date(), daysThreshold))) {
    return format(date, "E, dd MMMM, HH:mm");
  }

  return formatTimeDistanceToNow(date);
}

export function formatTimeDistanceToNow(date: Date) {
  return formatDistanceToNowStrict(date, { addSuffix: true });
}

export const formatEta = (eta: Date, isGps = false): string =>
  `${formatTwentyFourHourTime(eta)}${isGps ? " (GPS)" : ""}`;

export const formatPreActivatedEtaInMinutes = (eta: number): string =>
  `${eta} minutes`;

export const combineDateTimeValues = ({
  date,
  time,
}: {
  date: Date;
  time: string;
}) => {
  const formattedTime = getHoursAndMinutesFromTimeString(time || "");

  return set(date, {
    hours: formattedTime.hours,
    minutes: formattedTime.minutes,
  });
};

export function formatAvailabilityStatusLabel({
  status,
  emergenciesOnly,
  committed = false,
  useShort = false,
}: {
  status?: AvailabilityStatus | MemberStatus;
  committed?: boolean;
  emergenciesOnly?: boolean;
  useShort?: boolean;
}) {
  if (committed) {
    return "Committed";
  }

  switch (status) {
    case "ImmediatelyAvailable": {
      return `${useShort ? "Immediate" : "Immediately Available"}${
        emergenciesOnly ? ": Emergencies only" : ""
      }`;
    }
    case "Conditional":
      return useShort ? status : "Conditionally Available";
    case "Available":
    case "Unavailable":
      return status;
    default:
      return "Unset";
  }
}

export function removeWhitespace(value: string) {
  return value.replace(/\s/g, "");
}

export function formatPhoneNumber(value: string) {
  const cleanedValue = removeWhitespace(value);

  return [
    cleanedValue.substring(0, 4),
    cleanedValue.substring(4, 7),
    cleanedValue.substring(7),
  ].join(" ");
}

export type ActivityRequestStatusLabel =
  | "Open"
  | "Confirmed"
  | "Responded"
  | "Invited"
  | "Removed"
  | "Archived";

export const formatActivityRequestStatusLabel = (
  status: ActivityRequestStatus | null,
  archived: boolean
): ActivityRequestStatusLabel => {
  if (!status && !archived) {
    return "Open";
  }

  if (archived) {
    return "Archived";
  }

  switch (status) {
    case "AttendanceConfirmed":
      return "Confirmed";

    case "AvailabilitySubmitted":
      return "Responded";

    case "Removed":
      return "Removed";

    case "AvailabilityRequested":
    default:
      return "Invited";
  }
};

export const formatOutOfAreaRequestStatus = (
  status: OutOfAreaActivationRequestStatus,
  archived?: boolean
): string => {
  if (archived) {
    return "Archived";
  }

  switch (status) {
    case "ActivationPending":
      return "Supported-Recommended for Activation";
    case "AvailabilityRequested":
      return "Availabilty Requested";
    case "ClusterApprovalDeclined":
      return "Cluster Approval Rejected";
    case "UnitApprovalDeclined":
      return "Unit Approval Rejected";
    case "ZoneApprovalDeclined":
      return "Zone Approval Rejected";
    case "ClusterApprovalPending":
      return "Pending Cluster Approval";
    case "UnitApprovalPending":
      return "Pending Unit Approval";
    case "ZoneApprovalPending":
      return "Pending Zone Approval";
    default:
      return status ?? "";
  }
};

export const formatOutOfAreaActivationLevel = (
  level: OutOfAreaActivationLevel
) => {
  switch (level) {
    case "InterCluster":
      return "Inter-Cluster";
    case "IntraCluster":
      return "Intra-Cluster";
    case "InterZone":
      return "Inter-Zone";
    case "IntraZone":
      return "Intra-Zone";
    case "InterUnit":
      return "Inter-Unit";
    default:
      return level;
  }
};

export const formatOutOfAreaActivationRoleCategory = (
  category: OutOfAreaActivationRoleCategory
) => {
  switch (category) {
    case "StateOperationsCentre":
      return "State Operations Centre";
    default:
      return category;
  }
};
