import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  ActivitySchema,
  ActivityRequestDetailsSchema,
} from "./activityRequest";
import {
  ApiErrorSchema,
  AvailabilitySchema,
  CapabilitySchema,
  MemberSummarySchema,
  PaginationQueryParamsSchema,
  SearchMembersFilterSchema,
  ServerDateSchema,
  getPaginationResponseSchema,
} from "./common";

const c = initContract();

const CreateActivityInputSchema = z.object({
  title: z.string(),
  description: z.string(),
  unitId: z.string(),
  address: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  start: ServerDateSchema,
  end: ServerDateSchema,
  recipientGroupIds: z.array(z.string()).optional(),
  recipientContactGroupIds: z.array(z.string()).optional(),
  recipientMemberIds: z.array(z.string()).optional(),
  recipientUnitIds: z.array(z.string()).optional(),
  selectAllMembersQuery: SearchMembersFilterSchema.optional(),
});

const UpdateActivityInputSchema = z.object({
  title: z.string(),
  description: z.string(),
  address: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  start: ServerDateSchema,
  end: ServerDateSchema,
});

const ActivityMemberSchema = ActivityRequestDetailsSchema.extend({
  member: MemberSummarySchema.extend({
    capabilities: z.array(CapabilitySchema),
    availability: AvailabilitySchema.nullish(),
  }),
});

export const activityContract = c.router({
  create: {
    method: "POST",
    path: "/activities",
    body: CreateActivityInputSchema,
    responses: {
      200: ActivitySchema,
      403: ApiErrorSchema,
    },
    summary: "Create an Activity with any associated Activity Requests",
  },
  update: {
    method: "PATCH",
    path: "/activities/:id",
    pathParams: z.object({
      id: z.string(),
    }),
    body: UpdateActivityInputSchema,
    responses: {
      200: ActivitySchema,
      403: ApiErrorSchema,
    },
    summary: "Update activity details",
  },
  get: {
    method: "GET",
    path: "/activities/:id",
    pathParams: z.object({
      id: z.string(),
    }),
    responses: {
      200: ActivitySchema,
      404: ApiErrorSchema,
    },
    summary: "Get the details of an activity",
  },
  close: {
    method: "DELETE",
    path: "/activities/:id",
    pathParams: z.object({
      id: z.string(),
    }),
    body: null,
    responses: {
      200: ActivitySchema,
      403: ApiErrorSchema,
      404: ApiErrorSchema,
    },
    summary: "Close an activity",
  },
  closeMany: {
    method: "DELETE",
    path: "/activities",
    body: z.object({
      activityIds: z.array(z.string()),
    }),
    responses: {
      200: null,
    },
    summary: "Close multiple activities",
  },
  addActivityMembers: {
    method: "POST",
    path: "/activities/:activityId/members",
    pathParams: z.object({
      activityId: z.string(),
    }),
    responses: {
      200: z.object({
        items: z.array(ActivityMemberSchema),
      }),
      403: ApiErrorSchema,
    },
    body: z.object({
      groupIds: z.array(z.string()),
      contactGroupIds: z.array(z.string()),
      memberIds: z.array(z.string()),
      selectAllMembersQuery: SearchMembersFilterSchema.optional(),
    }),
    summary: "Add members to an activity",
  },
  removeActivityMembers: {
    method: "POST",
    path: "/activities/:activityId/membersRemove",
    pathParams: z.object({
      activityId: z.string(),
    }),
    responses: {
      200: z.object({
        items: z.array(ActivityMemberSchema),
      }),
      403: ApiErrorSchema,
    },
    body: z.object({
      memberIds: z.array(z.string()),
    }),
    summary: "Remove members from an activity",
  },
  getActivityMembers: {
    method: "GET",
    path: "/activities/:activityId/members",
    query: PaginationQueryParamsSchema.extend({
      capabilityIds: z.array(z.string()).optional(),
      query: z.coerce.string().trim().optional(),
    }),
    responses: {
      200: getPaginationResponseSchema(ActivityMemberSchema),
    },
    summary: "Get the members of an activity",
  },
  confirmAttendance: {
    method: "POST",
    path: "/activities/:activityId/confirm-attendance",
    responses: {
      200: z.array(ActivityMemberSchema),
      422: ApiErrorSchema,
    },
    body: z.object({
      activityRequestIds: z
        .array(z.string())
        .max(
          100,
          "Cannot confirm more than 100 requests in a single operation."
        ),
      force: z.boolean().optional(),
    }),
    summary: "Confirm the attendance of members to an activity",
  },
});

export type ActivityMember = z.infer<typeof ActivityMemberSchema>;
