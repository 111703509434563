import { Button } from "~/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogProps,
} from "~/components/ui/dialog";
import { Text } from "~/components/ui/text";
import { type ConfirmationDialogParams } from "./ConfirmationDialogContextProvider";

export type ConfirmationDialogProps = Omit<DialogProps, "children"> &
  ConfirmationDialogParams;

export const ConfirmationDialog = ({
  open,
  onClose,
  title = "Are you sure?",
  text,
  onConfirm,
}: ConfirmationDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader onClose={onClose}>{title}</DialogHeader>
      <DialogContent>
        <Text size="large">{text}</Text>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="destructive" onClick={onConfirm}>
          Confirm
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
