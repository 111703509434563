import { Container } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { OutOfAreaActivationRoleCategory } from "@ses-mams/api-contract";
import {
  convertMinutesToThreshold,
  formatActivationType,
  formatDateTime,
  formatOutOfAreaActivationRoleCategory,
  useGetGroupedOutOfAreaActivationRoles,
} from "@ses-mams/react-utils";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { Button } from "~/components/ui/button";
import { BackButton } from "~/components/common/navigation";
import { Box } from "~/components/ui/box";
import { useOutOfAreaActivationLevelLayoutContext } from "~/components/common/layout";
import { Badge } from "~/components/ui/badge";
import { OutOfAreaActivationRequestStatusBadge } from "~/components/common/outOfAreaActivationRequestStatusBadge";
import { useHasAnyRole } from "~/context/auth";
import { InitiatorLabelValueDrawer, LabelValue } from "../shared";
import { useOutOfAreaActivationRequestDetails } from "../hooks";
import {
  CloseButton,
  DownloadMembersCsvButton,
  ManageOOAAMembersDrawer,
  OutOfAreaActivationMemberSummaryList,
  WithdrawButton,
  useOutOfAreaActivationMembersSummariesData,
} from "./components";
import { useCanEditOOAARequest } from "./hooks/useCanEditOOAARequest";

export const OOAADetailsPage = () => {
  const activationId = useParams().activationId as string;

  const canAmend = useCanEditOOAARequest(activationId);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const hasOperationCoordinatorRole = useHasAnyRole(["OperationalCoordinator"]);
  const hasOOAACoordinatorRole = useHasAnyRole(["OOAACoordinator"]);

  const { isLoading: isLoadingRequestDetails, data } =
    useOutOfAreaActivationRequestDetails(activationId);

  const outOfAreaActivationRequest = data?.body;

  const {
    sections,
    isLoading: isLoadingMembers,
    fetchNextPage,
    hasNextPage,
  } = useOutOfAreaActivationMembersSummariesData(activationId);

  const getGroupedOutOfAreaActivationRoles =
    useGetGroupedOutOfAreaActivationRoles();

  // Show the level badge in the banner of the page layout
  const { setLevel } = useOutOfAreaActivationLevelLayoutContext();

  useEffect(() => {
    setLevel(outOfAreaActivationRequest?.activation?.level);
  }, [outOfAreaActivationRequest?.activation?.level]);

  useEffect(() => {
    return () => setLevel(undefined);
  }, []);

  const activation = outOfAreaActivationRequest?.activation;

  const formattedEscalation = useMemo(() => {
    const unitThreshold = convertMinutesToThreshold(
      activation?.unitEscalationThreshold
    );
    const clusterThreshold = convertMinutesToThreshold(
      activation?.clusterEscalationThreshold
    );

    return {
      unitTime: unitThreshold.time
        ? `${unitThreshold.time} ${unitThreshold.unit}${unitThreshold.time > 1 ? "s" : ""}`
        : undefined,
      clusterTime: clusterThreshold.time
        ? `${clusterThreshold.time} ${clusterThreshold.unit}${clusterThreshold.time > 1 ? "s" : ""}`
        : undefined,
    };
  }, [
    activation?.unitEscalationThreshold,
    activation?.clusterEscalationThreshold,
  ]);

  if (isLoadingRequestDetails || !outOfAreaActivationRequest || !activation) {
    return (
      <Box display="flex" grow={1} justify="center">
        <Spinner />
      </Box>
    );
  }

  const { canRespond, canWithdraw, request } = outOfAreaActivationRequest;

  const isClosed = !!activation.closedAt;

  const hasDeploymentRoles =
    activation?.deployments?.flatMap(d => d.roles)?.length > 0;

  const activateFor = activation.unit ?? activation.cluster ?? activation.zone;

  return (
    <>
      <Container maxWidth="md">
        <Stack gap="xlarge">
          <Stack
            direction="row"
            gap="medium"
            align="center"
            justify="space-between"
          >
            <BackButton to="/requests?tab=out-of-area" />
            {canAmend && (
              <Button href="edit" disabled={isClosed}>
                Edit
              </Button>
            )}
          </Stack>
          <Stack gap="xlarge" dividers>
            <Stack
              gap="medium"
              direction="row"
              justify="space-between"
              align="center"
            >
              <Text size="xlarge" weight="semi">
                {activation.title}
              </Text>

              {isClosed ? (
                <Badge tone="critical">Archived</Badge>
              ) : (
                <Badge tone="positive">Active</Badge>
              )}
            </Stack>
            {canRespond && (
              <Button
                variant="primary"
                href={
                  hasDeploymentRoles ? "respond/roles" : "respond/availability"
                }
              >
                Respond
              </Button>
            )}
            {request && (
              <Stack gap="small" align="start">
                <Text tone="secondary" weight="medium">
                  Status
                </Text>

                <OutOfAreaActivationRequestStatusBadge
                  status={request.status}
                />

                <Button variant="link" href={`history?requestId=${request.id}`}>
                  View History
                </Button>
              </Stack>
            )}
            <LabelValue
              label="Deployments"
              value={
                <Stack paddingTop="medium" gap="xlarge">
                  {activation.deployments.map((deployment, index) => {
                    const rolesByCategory = getGroupedOutOfAreaActivationRoles(
                      deployment.roles
                    );

                    return (
                      <Stack gap="small" key={`deployment-${index}`}>
                        <Text size="large" weight="medium">{`Deployment ${
                          index + 1
                        }`}</Text>
                        <Stack gap="medium">
                          {deployment.location && (
                            <LabelValue
                              label="Location"
                              value={deployment.location}
                            />
                          )}
                          {deployment.address && (
                            <LabelValue
                              label="Address"
                              value={deployment.address}
                            />
                          )}
                          {deployment.description && (
                            <LabelValue
                              label="Description"
                              value={deployment.description}
                            />
                          )}
                          <Stack direction="row" gap="xlarge">
                            <LabelValue
                              label="Start time"
                              value={formatDateTime(new Date(deployment.start))}
                            />
                            <LabelValue
                              label="End time"
                              value={formatDateTime(new Date(deployment.end))}
                            />
                          </Stack>
                          <LabelValue
                            label="Roles"
                            value={
                              rolesByCategory ? (
                                <Stack gap="small" paddingTop="small">
                                  {Object.keys(rolesByCategory).map(
                                    category => {
                                      const roles =
                                        rolesByCategory[
                                          category as keyof typeof rolesByCategory
                                        ];

                                      return (
                                        <Stack
                                          key={`${deployment.start}-${category}`}
                                          gap="xxsmall"
                                        >
                                          <Text weight="medium">
                                            {formatOutOfAreaActivationRoleCategory(
                                              category as OutOfAreaActivationRoleCategory
                                            )}
                                          </Text>
                                          <Text>
                                            {roles
                                              .map(({ name }) => name)
                                              .join(", ")}
                                          </Text>
                                        </Stack>
                                      );
                                    }
                                  )}
                                </Stack>
                              ) : (
                                "No roles selected."
                              )
                            }
                          />
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
              }
            />

            <Stack gap="xlarge">
              <LabelValue
                label="Type"
                value={formatActivationType(activation.type)}
              />
              {activateFor && (
                <LabelValue label="Activate for" value={activateFor.name} />
              )}
              <LabelValue label="Description" value={activation.description} />
              <InitiatorLabelValueDrawer initiator={activation.initiator} />
              <LabelValue
                label="Member responses with approvals to be received by"
                value={formatDateTime(new Date(activation.replyByDate))}
              />
              {formattedEscalation.unitTime && (
                <LabelValue
                  label="Unit Escalation Time"
                  value={formattedEscalation.unitTime}
                />
              )}
              {formattedEscalation.clusterTime && (
                <LabelValue
                  label="Cluster Escalation Time"
                  value={formattedEscalation.clusterTime}
                />
              )}
            </Stack>

            {(hasOperationCoordinatorRole || hasOOAACoordinatorRole) && (
              <Stack gap="medium" direction="row">
                <DownloadMembersCsvButton
                  activationId={activationId}
                  status="approved"
                />
                <DownloadMembersCsvButton
                  activationId={activationId}
                  status="activated"
                />
              </Stack>
            )}

            {(canAmend || canWithdraw) && (
              <Stack gap="medium" direction="row">
                {canWithdraw && request?.id && (
                  <WithdrawButton
                    activationId={activationId}
                    requestId={request.id}
                  />
                )}

                {!isClosed && canAmend && (
                  <CloseButton activationId={activationId} />
                )}
              </Stack>
            )}
            <Stack gap="xlarge">
              <Button
                variant="tertiary"
                fullWidth
                onClick={() => setIsDrawerOpen(true)}
                disabled={isLoadingMembers}
              >
                Members
              </Button>
              <Stack gap="medium">
                <Text size="xlarge" weight="medium">
                  Members
                </Text>
                <OutOfAreaActivationMemberSummaryList
                  sections={sections}
                  isLoading={isLoadingMembers}
                  fetchNextPage={fetchNextPage}
                  hasNextPage={hasNextPage}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Container>

      <ManageOOAAMembersDrawer
        activationId={activationId}
        isClosed={isClosed}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
};
