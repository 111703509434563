import { useMemo } from "react";
import { Spinner } from "~/components/ui/spinner";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { Box } from "~/components/ui/box";
import { AlertCircleFilledIcon } from "~/components/ui/icon";
import { tsr } from "~/utils/client";
import { useAvailabilityReportContext } from "~/context/unit/AvailabilityReportContextProvider";
import { AvailabilityReportAccordion } from "../AvailabilityReportAccordion";
import { AggregatedAvailabilityPeriodColumn } from "~/components/common/aggregatedAvailabilityPeriodColumn";

type AggregatedAvailabilityReportProps = {
  title: string;
  groupId?: string;
};

export const AggregatedAvailabilityReport = (
  props: AggregatedAvailabilityReportProps
) => {
  return (
    <AvailabilityReportAccordion {...props}>
      <AggregatedReportView {...props} />
    </AvailabilityReportAccordion>
  );
};

// Split into a separate component so that components and hooks are unmounted when the accordion is closed
const AggregatedReportView = ({
  title,
  groupId,
}: AggregatedAvailabilityReportProps) => {
  const { endDate, startDate, unitId } = useAvailabilityReportContext();

  const { data, isLoading } = tsr.units.getAggregatedAvailability.useQuery({
    queryKey: [
      "units",
      unitId,
      "availability",
      "aggregated",
      startDate,
      endDate,
      groupId,
    ],
    queryData: {
      params: {
        unitId,
      },
      query: {
        byPeriod: "Hourly",
        startDate,
        endDate,
        groupId,
      },
    },
  });

  const hasAtLeastOneLowAvailabilityPeriod = useMemo(
    () => (data?.body || []).some(({ lowAvailability }) => lowAvailability),
    [data?.body]
  );

  if (isLoading) {
    return (
      <Box display="flex" justify="center" paddingY="xlarge" grow={1}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Stack
      gap="medium"
      direction="column"
      overflowX="auto"
      sx={{ margin: "0 auto" }}
    >
      {hasAtLeastOneLowAvailabilityPeriod ? (
        <Stack
          direction="row"
          justify="end"
          gap="xsmall"
          align="center"
          paddingRight="xlarge"
        >
          <AlertCircleFilledIcon tone="critical" size="xxsmall" />
          <Text tone="critical">Availability Low</Text>
        </Stack>
      ) : null}

      <Stack
        direction="row"
        gap="large"
        paddingX="xlarge"
        overflowX="auto"
        paddingBottom="xlarge"
      >
        {data?.body?.map(item => (
          <AggregatedAvailabilityPeriodColumn
            key={`aggregated-${title}-${item.period}`}
            period={item}
            unitId={unitId}
            groupId={groupId}
          />
        ))}
      </Stack>
    </Stack>
  );
};
