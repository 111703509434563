import { initContract } from "@ts-rest/core";
import { z } from "zod";

const c = initContract();

const ScheduleItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  start: z.string(),
  end: z.string(),
});

const SetScheduleInputSchema = z.array(ScheduleItemSchema.omit({ id: true }));

export const scheduleContract = c.router({
  get: {
    method: "GET",
    path: "/schedule",
    responses: {
      200: z.array(ScheduleItemSchema),
    },
    summary: "Get the current schedule",
  },
  set: {
    method: "POST",
    path: "/schedule",
    body: SetScheduleInputSchema,
    responses: {
      200: z.array(ScheduleItemSchema),
    },
    summary: "Set the current schedule",
  },
});

export type ScheduleItem = z.infer<typeof ScheduleItemSchema>;
