import { Container } from "@mui/material";

import { SearchMembersList } from "~/components/common/searchMembersList";
import { Heading } from "~/components/ui/heading";
import { Stack } from "~/components/ui/stack";
import { useSelectedUnit } from "~/context/unit/SelectedUnitContextProvider";

export const MembersPage = () => {
  const { selectedUnit } = useSelectedUnit();

  return (
    <Container maxWidth="sm">
      <Stack gap="medium">
        <Heading level="1">Members</Heading>
        <SearchMembersList unit={selectedUnit} />
      </Stack>
    </Container>
  );
};
