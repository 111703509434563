import { Container } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { Box } from "~/components/ui/box";
import { Spinner } from "~/components/ui/spinner";
import { OutOfAreaActivationRequestHistoryDetails } from "~/components/common/outOfAreaActivationRequestHistoryDetails";
import { Stack } from "~/components/ui/stack";
import { BackButton } from "~/components/common/navigation";
import { ApprovalActionButtons } from "./components/OutOfAreaApproval";
import { RevertApprovalButton } from "./components/RevertApprovalButton";
import { useOutOfAreaApprovalQuery } from "./hooks/useOutOfAreaApprovalQuery";

export const OOAAApprovalDetailsPage = () => {
  const requestId = useParams().requestId as string;
  const [searchParams] = useSearchParams();
  const state = searchParams.get("state");

  const { isLoading, data } = useOutOfAreaApprovalQuery(requestId);

  const approval = data?.body;

  const isPending = useMemo(() => {
    if (!approval?.request || state !== "pending") {
      return false;
    }

    return [
      "UnitApprovalPending",
      "ClusterApprovalPending",
      "ZoneApprovalPending",
    ].includes(approval.request.status);
  }, [approval?.request, state]);

  if (isLoading || !approval) {
    return (
      <Box display="flex" grow={1} justify="center">
        <Spinner />
      </Box>
    );
  }

  const { request, revertable, history } = approval;

  const isRevertable = revertable && state === "previous";

  const historyDetailsProps =
    state === "pending"
      ? {
          roles: request.roles,
          availabilityBlocks: request.availabilityBlocks,
        }
      : {
          roles: history.roles,
          availabilityBlocks: history.availabilityBlocks,
          comments: history.comments,
        };

  return (
    <Container maxWidth="md">
      <Stack gap="xlarge">
        <BackButton />

        <OutOfAreaActivationRequestHistoryDetails
          {...historyDetailsProps}
          createdAt={history.createdAt}
          member={request.member}
        />

        {isPending && (
          <ApprovalActionButtons
            requestIds={[request.id]}
            activationId={data?.body?.activation?.id}
          />
        )}

        {isRevertable && (
          <RevertApprovalButton
            requestId={request.id}
            status={request.status}
          />
        )}
      </Stack>
    </Container>
  );
};
