import { Cluster } from "@ses-mams/api-contract";
import { useCallback, useState } from "react";
import { SelectClustersDrawer } from "~/components/common/selectClustersList";
import { EditListSection } from "~/components/common/editListSection";
import { ClusterListItem } from "~/components/common/cluster";

type EditClustersListProps = {
  selectedClusters: Array<Cluster>;
  onChangeSelectedClusters: (clusters: Array<Cluster>) => void;
};

export const EditClustersList = ({
  selectedClusters,
  onChangeSelectedClusters,
}: EditClustersListProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onDone = useCallback(
    (clusters: Array<Cluster>) => {
      onChangeSelectedClusters(clusters);
      setIsDrawerOpen(false);
    },
    [onChangeSelectedClusters]
  );

  return (
    <>
      <EditListSection
        title="Clusters"
        onButtonClick={() => setIsDrawerOpen(true)}
      >
        {selectedClusters.map(cluster => (
          <ClusterListItem key={cluster.id} cluster={cluster} />
        ))}
      </EditListSection>

      <SelectClustersDrawer
        open={isDrawerOpen}
        clusters={selectedClusters}
        onClose={() => setIsDrawerOpen(false)}
        onDone={onDone}
      />
    </>
  );
};
