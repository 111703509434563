import { Card } from "~/components/ui/card";
import { Box } from "~/components/ui/box";
import { Spinner } from "~/components/ui/spinner";
import { AvailabilityReportHeader } from "./AvailabilityReportHeader";
import { AggregatedAvailabilityReports } from "./AggregatedAvailabilityReport";
import {
  AvailabilityViewMode,
  useAvailabilityReportContext,
} from "~/context/unit/AvailabilityReportContextProvider";
import { IndividualAvailabilityReports } from "./IndividualAvailabilityReport";

export const AvailabilityReport = () => {
  const { availabilityViewMode, isLoadingGroups } =
    useAvailabilityReportContext();

  if (isLoadingGroups) {
    return (
      <Box display="flex" justify="center" paddingY="xlarge">
        <Spinner />
      </Box>
    );
  }

  return (
    <Card dividers dividerTone="neutral">
      <AvailabilityReportHeader />

      {isLoadingGroups ? (
        <Box display="flex" justify="center" paddingY="xlarge">
          <Spinner />
        </Box>
      ) : availabilityViewMode === AvailabilityViewMode.AGGREGATED ? (
        <AggregatedAvailabilityReports />
      ) : (
        <IndividualAvailabilityReports />
      )}
    </Card>
  );
};
