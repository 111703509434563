import { useCallback } from "react";

import { Role } from "@ses-mams/api-contract";
import {
  InfiniteList,
  InfiniteSectionList,
  RenderSectionHeaderParams,
} from "~/components/ui/list";

import emptyListImageUrl from "~/assets/emptyList.png";
import { Button } from "~/components/ui/button";
import { Text } from "~/components/ui/text";
import { Box } from "~/components/ui/box";
import { Row, Stack } from "~/components/ui/stack";
import { SearchInput } from "~/components/ui/searchInput";
import { Spinner } from "~/components/ui/spinner";
import { EmptyState } from "~/components/common/emptyState";
import {
  ActivationRequestWithAvailabilityCount,
  ActivityRequestWithAvailabilityCount,
  OutOfAreaActivationRequestWithStatusCount,
} from "@ses-mams/api-contract";
import { useActivationRequestsData } from "../activations";
import { useActivityRequestsData } from "../activities";
import { useOutOfAreaActivationListData } from "../outOfAreaActivations";
import { useHasAnyRole } from "~/context/auth";
import { useConfirmationDialog } from "~/components/ui/confirmationDialog";

type RequestListProps = {
  unitId?: string;
  dataHook:
    | typeof useActivationRequestsData
    | typeof useActivityRequestsData
    | typeof useOutOfAreaActivationListData;
  ListItemComponent: any;
  onConfirm?: () => void;
  isClosing?: boolean;
  isBulkSelecting?: boolean;
  setIsBulkSelecting?: (bool: boolean) => void;
  selectedIds?: Set<string>;
  onBulkSelect: (id: string) => void;
  bulkCloseRoles?: Array<Role>;
  skipConfirmation?: boolean;
};

type Item =
  | ActivationRequestWithAvailabilityCount
  | ActivityRequestWithAvailabilityCount
  | OutOfAreaActivationRequestWithStatusCount;

export const RequestList = ({
  unitId,
  dataHook,
  ListItemComponent,
  onConfirm = () => null,
  isClosing,
  isBulkSelecting,
  setIsBulkSelecting,
  selectedIds,
  onBulkSelect,
  bulkCloseRoles = [],
  skipConfirmation,
}: RequestListProps) => {
  const { isLoading, fetchNextPage, hasNextPage, sections, data, filters } =
    dataHook({
      unitId,
    });

  const { showConfirmationDialog } = useConfirmationDialog();

  const canBulkClose =
    useHasAnyRole([...bulkCloseRoles, "Administrator"]) && !filters?.archived;

  const isDisplayingUnitRequests = !!unitId;

  const renderSectionHeader = useCallback(
    ({ title }: RenderSectionHeaderParams<Item>) => {
      return (
        <Box
          background="surface"
          position="sticky"
          paddingY="small"
          sx={{ top: 0, zIndex: 10 }}
        >
          <Text weight="medium" size="large">
            {title}
          </Text>
        </Box>
      );
    },
    []
  );

  const renderItem = useCallback(
    (item: Item) => (
      <Box display="flex" paddingBottom="medium" sx={{ width: "100%" }}>
        <ListItemComponent
          item={item}
          shouldDisplayStatus={!isDisplayingUnitRequests}
          isBulkSelecting={canBulkClose && isBulkSelecting}
          selectedIds={selectedIds}
          onBulkSelect={onBulkSelect}
        />
      </Box>
    ),
    [
      isDisplayingUnitRequests,
      isBulkSelecting,
      canBulkClose,
      selectedIds,
      onBulkSelect,
    ]
  );

  const keyExtractor = useCallback(
    (item: Item) =>
      (item as ActivationRequestWithAvailabilityCount).activation?.id ??
      (item as ActivityRequestWithAvailabilityCount).activity?.id,
    []
  );

  const listComponent = isDisplayingUnitRequests ? (
    <InfiniteList
      data={data}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      renderItem={renderItem}
      listItemDivider={false}
      listEmptyElement={
        isLoading ? null : (
          <Box display="flex" justify="center" sx={{ height: "100%" }}>
            <EmptyState
              image={<img src={emptyListImageUrl} />}
              title="No requests"
              description="There are no requests associated with your search. Please try another value."
            />
          </Box>
        )
      }
      keyExtractor={keyExtractor}
    />
  ) : (
    <InfiniteSectionList
      sections={sections}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      renderItem={renderItem}
      renderSectionHeader={renderSectionHeader}
      listItemDivider={false}
      listEmptyElement={
        isLoading ? null : (
          <Box display="flex" justify="center" sx={{ height: "100%" }}>
            <EmptyState
              image={<img src={emptyListImageUrl} />}
              title="No requests"
              description="There are no requests associated with your search. Please try another value."
            />
          </Box>
        )
      }
      keyExtractor={keyExtractor}
    />
  );

  return (
    <Stack gap="medium">
      {canBulkClose && isBulkSelecting ? (
        <Row
          borderTop="standard"
          borderTopWidth="medium"
          paddingY={"small"}
          justify={"center"}
          position={"fixed"}
          zIndex={"1"}
          background="surface"
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100vw",
          }}
        >
          <Row gap={"medium"} sx={{ width: "600px" }}>
            <Button
              fullWidth
              variant="secondary"
              onClick={() => setIsBulkSelecting && setIsBulkSelecting(false)}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              variant="primary"
              onClick={() => {
                skipConfirmation
                  ? onConfirm()
                  : showConfirmationDialog({
                      text: `You will close and archive all selected requests and all members will be deactivated.`,
                      onConfirm,
                    });
              }}
              disabled={isClosing || !selectedIds?.size}
            >
              {`Close(${selectedIds?.size})`}
            </Button>
          </Row>
        </Row>
      ) : null}
      <SearchInput
        placeholder="Search by title or location"
        value={filters.searchValue || ""}
        onValueChange={filters.setSearchValue}
      />
      {canBulkClose ? (
        <Row justify="end">
          <Button
            variant="link"
            onClick={() => setIsBulkSelecting && setIsBulkSelecting(true)}
            disabled={isBulkSelecting}
          >
            Bulk Close Requests
          </Button>
        </Row>
      ) : null}

      {isLoading ? (
        <Stack align="center">
          <Spinner />
        </Stack>
      ) : (
        listComponent
      )}
    </Stack>
  );
};
