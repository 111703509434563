import { useCallback } from "react";
import { ButtonBase } from "@mui/material";
import { Zone } from "@ses-mams/api-contract";
import { useDebouncedSearch } from "@ses-mams/react-utils";
import emptyListImageUrl from "~/assets/emptyList.png";
import { SearchInput } from "~/components/ui/searchInput";
import { Stack } from "~/components/ui/stack";
import { InfiniteList } from "~/components/ui/list";
import { Checkbox } from "~/components/ui/checkbox";
import { EmptyState } from "~/components/common/emptyState";
import { ZoneListItem } from "~/components/common/zone";
import { useZoneListSearch } from "./useZoneListSearch";

type SearchZonesListProps = {
  zones: Array<Zone>;
  removeZone: (zone: Zone) => void;
  appendZone: (zone: Zone) => void;
};

export const SelectZonesList = ({
  zones,
  removeZone,
  appendZone,
}: SearchZonesListProps) => {
  const { searchValue, setSearchValue, debouncedSearchValue } =
    useDebouncedSearch("");

  const { data, isLoading, isRefetching, hasNextPage, fetchNextPage } =
    useZoneListSearch(debouncedSearchValue);

  const renderItem = useCallback(
    (item: Zone) => {
      const isChecked = zones.some(({ id }) => id === item.id);

      return (
        <ButtonBase
          role="checkbox"
          aria-checked={isChecked}
          onClick={() => (isChecked ? removeZone(item) : appendZone(item))}
        >
          <ZoneListItem
            startAdornment={<Checkbox checked={isChecked} />}
            zone={item}
          />
        </ButtonBase>
      );
    },
    [zones]
  );

  const isRefreshing = isLoading || isRefetching;

  return (
    <Stack flex={1} gap="large">
      <SearchInput
        placeholder="Search"
        value={searchValue}
        onValueChange={setSearchValue}
      />

      <InfiniteList
        data={data}
        keyExtractor={item => item.id}
        renderItem={renderItem}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        listEmptyElement={
          isRefreshing ? null : (
            <EmptyState
              image={<img src={emptyListImageUrl} />}
              title="No zones found"
              description="There are no zones associated with your search. Please try another value."
            />
          )
        }
      />
    </Stack>
  );
};
