import { startOfDay } from "date-fns";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { captureException } from "@sentry/react";
import { ActivitySchedule } from "@ses-mams/api-contract";
import {
  convertActivityScheduleReminderToField,
  formatDateForServer,
} from "@ses-mams/react-utils";
import {
  EditActivityScheduleFormSchema,
  convertDurationToMinutes,
  getEditActivityScheduleFormSchema,
} from "@ses-mams/validation";
import { EventForm } from "~/components/common/eventForm";
import { Stack } from "~/components/ui/stack";
import { Divider } from "~/components/ui/divider";
import { Button } from "~/components/ui/button";
import { Heading } from "~/components/ui/heading";
import { useToast } from "~/components/ui/toast";
import { useConfirmationDialog } from "~/components/ui/confirmationDialog";
import { tsr } from "~/utils/client";
import { useQueryClient } from "@tanstack/react-query";
import { UnitField } from "../../shared";
import { ActivityScheduleEndFields } from "./ActivityScheduleEndFields";
import { RemindMembersField } from "./RemindMembersField";

type EditActivityScheduleFormProps = {
  activitySchedule: ActivitySchedule;
};

export const EditActivityScheduleForm = ({
  activitySchedule,
}: EditActivityScheduleFormProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const scheduleId = activitySchedule.id;

  const { addToast } = useToast();
  const { showConfirmationDialog } = useConfirmationDialog();

  const formMethods = useForm<EditActivityScheduleFormSchema>({
    resolver: zodResolver(
      getEditActivityScheduleFormSchema(
        activitySchedule.scheduleEndDate
          ? new Date(activitySchedule.scheduleEndDate)
          : undefined,
        activitySchedule.scheduleEndCount
      )
    ),
    mode: "onBlur",
    defaultValues: {
      ...(activitySchedule ?? {}),
      location: activitySchedule.address,
      schedule: {
        endMode: activitySchedule.scheduleEndDate ? "On Date" : "After",
        endCount: activitySchedule.scheduleEndCount,
        endDate: activitySchedule.scheduleEndDate
          ? new Date(activitySchedule.scheduleEndDate)
          : undefined,
        reminderDurationBeforeStart: convertActivityScheduleReminderToField(
          activitySchedule.scheduleReminderDuration
        ),
      },
    },
  });

  const { mutateAsync, isPending: isUpdating } =
    tsr.activitySchedules.update.useMutation();

  const onSubmit = useCallback(
    async ({
      schedule,
      location,
      ...formData
    }: EditActivityScheduleFormSchema) => {
      showConfirmationDialog({
        text: "Please confirm your amendments on the activity",
        onConfirm: async () => {
          try {
            const result = await mutateAsync({
              params: {
                id: scheduleId,
              },
              body: {
                ...formData,
                address: location,
                scheduleEndCount: schedule.endCount ?? undefined,
                scheduleEndDate: schedule.endDate
                  ? formatDateForServer(startOfDay(schedule.endDate))
                  : undefined,
                scheduleReminderDuration:
                  convertDurationToMinutes({
                    value: schedule.reminderDurationBeforeStart?.value,
                    unit: schedule.reminderDurationBeforeStart?.unit,
                  }) ?? null,
              },
            });

            queryClient.setQueryData(
              ["activity-schedules", scheduleId],
              result
            );

            navigate(-1);
          } catch (error) {
            captureException(error);

            addToast({
              tone: "critical",
              title: "Sorry, something went wrong",
              message: "Please try again",
            });
          }
        },
      });
    },
    [scheduleId]
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Stack gap="xlarge" paddingBottom="xxlarge">
          <Stack direction="row" justify="space-between">
            <Heading level="1">Edit Schedule</Heading>
            <Button type="submit" busy={isUpdating}>
              Done
            </Button>
          </Stack>
          <Divider />

          <UnitField disabled label="Activity for Unit" />

          <EventForm showDates={false} type="Activity" />

          <ActivityScheduleEndFields isEdit control={formMethods.control} />

          <RemindMembersField
            isEdit
            control={formMethods.control}
            setValue={formMethods.setValue}
          />
        </Stack>
      </form>
    </FormProvider>
  );
};
