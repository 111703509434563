import { tokens as defaultTokens } from "@ses-mams/react-utils";

const fontWeight = {
  regular: 400,
  medium: 500,
  semi: 600,
};

// TODO: add typography tokens
export const tokens = {
  ...defaultTokens,
  spacing: {
    xxsmall: 2,
    xsmall: 4,
    small: 8,
    medium: 12,
    large: 16,
    xlarge: 24,
    xxlarge: 36,
  },
  sizing: {
    xxsmall: 18,
    xsmall: 24,
    small: 32,
    medium: 44,
    large: 56,
  },
  typography: {
    fontWeight,
    heading: {
      "1": {
        fontSize: 30,
        fontWeight: fontWeight.semi,
      },
      "2": {
        fontSize: 24,
        fontWeight: fontWeight.semi,
      },
      "3": {
        fontSize: 18,
        fontWeight: fontWeight.semi,
      },
      "4": {
        fontSize: 16,
        fontWeight: fontWeight.semi,
      },
      "5": {
        fontSize: 14,
        fontWeight: fontWeight.semi,
      },
    },
    text: {
      xsmall: {
        fontSize: 10,
      },
      small: {
        fontSize: 12,
      },
      standard: {
        fontSize: 14,
      },
      large: {
        fontSize: 16,
      },
      xlarge: {
        fontSize: 18,
      },
    },
  },
};

export type Tokens = typeof tokens;
