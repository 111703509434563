import { cloneElement, isValidElement } from "react";
import { usePopoverContext } from "./PopoverRoot";

type PopoverCloseProps = {
  children: React.ReactNode;
};

export const PopoverClose = ({ children }: PopoverCloseProps) => {
  const { onOpenChange } = usePopoverContext();

  if (isValidElement(children)) {
    return cloneElement(children, {
      ...children.props,
      onClick: (e: unknown) => {
        onOpenChange(false);
        children.props.onClick?.(e);
      },
    });
  }

  return null;
};
