import { Box } from "~/components/ui/box";
import { Text } from "~/components/ui/text";

type Props = {
  conditionalReason?: string | null;
};

export const MemberConditionalReason = ({ conditionalReason }: Props) => {
  if (!conditionalReason) {
    return null;
  }

  return (
    <Box background="cautionMuted" padding="small" borderRadius="medium">
      <Text size="large" weight="medium">
        {`"${conditionalReason}"`}
      </Text>
    </Box>
  );
};
