import { Unit } from "@ses-mams/api-contract";

import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

type UnitListItemProps = {
  unit: Unit;
  startAdornment?: React.ReactNode;
};

export const UnitListItem = ({ unit, startAdornment }: UnitListItemProps) => {
  return (
    <Stack
      direction="row"
      gap="medium"
      paddingY="small"
      align="center"
      justify="space-between"
    >
      {startAdornment}
      <Stack flex={1} grow={1} gap="xsmall" align="start">
        <Text size="xlarge" weight="medium">
          {`${unit.name}${unit.code ? ` (${unit.code})` : ""}`}
        </Text>
        {unit.address && <Text tone="secondary">{unit.address}</Text>}
      </Stack>
    </Stack>
  );
};
