import { useCallback, useState } from "react";
import { Link as RouterLink, createSearchParams } from "react-router-dom";
import { Link } from "@mui/material";
import { Text } from "~/components/ui/text";
import {
  Accordion,
  AccordionContent,
  AccordionTrigger,
} from "~/components/ui/accordion";
import { useAvailabilityReportContext } from "~/context/unit/AvailabilityReportContextProvider";

type AvailabilityReportAccordionProps = {
  title: string;
  groupId?: string;
  children: React.ReactNode;
};

export const AvailabilityReportAccordion = ({
  title,
  groupId,
  children,
}: AvailabilityReportAccordionProps) => {
  const expandedStateLocalStorageKey = `AVAILABILITY_REPORT_GROUP_${
    groupId ?? "ALL_MEMBERS"
  }_EXPANDED`;

  const [isOpen, setIsOpen] = useState(
    !!localStorage.getItem(expandedStateLocalStorageKey)
  );
  const { unitId } = useAvailabilityReportContext();

  const setOpen = useCallback((open: boolean) => {
    setIsOpen(open);
    open
      ? localStorage.setItem(expandedStateLocalStorageKey, "true")
      : localStorage.removeItem(expandedStateLocalStorageKey);
  }, []);

  return (
    <Accordion
      open={isOpen}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <AccordionTrigger>
        <Link
          component={RouterLink}
          underline="none"
          onClick={e => e.stopPropagation()}
          to={{
            pathname: `/units/${unitId}/members`,
            search: `?${createSearchParams({
              groupId: groupId ?? "",
            })}`,
          }}
        >
          <Text tone="info" weight="semi">
            {title}
          </Text>
        </Link>
      </AccordionTrigger>
      <AccordionContent sx={{ display: "flex" }}>{children}</AccordionContent>
    </Accordion>
  );
};
