import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useAvailabilityWeekSelector } from "../useAvailabilityWeekSelector";

type AvailabilitySelectedWeekContextType = {
  firstDayOfTheWeek: Date;
  lastDayOfTheWeek: Date;
  goToPreviousWeek: () => void;
  goToNextWeek: () => void;
  goToToday: () => void;
};

const AvailabilitySelectedWeekContext =
  createContext<AvailabilitySelectedWeekContextType>({
    firstDayOfTheWeek: new Date(),
    lastDayOfTheWeek: new Date(),
    goToPreviousWeek: () => null,
    goToNextWeek: () => null,
    goToToday: () => null,
  });

export const AvailabilitySelectedWeekContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const {
    firstDayOfTheWeek,
    lastDayOfTheWeek,
    goToPreviousWeek,
    goToNextWeek,
    goToCurrentWeek,
  } = useAvailabilityWeekSelector();

  const goToToday = useCallback(() => {
    goToCurrentWeek();
  }, []);

  const value = useMemo(
    () => ({
      firstDayOfTheWeek,
      lastDayOfTheWeek,
      goToPreviousWeek,
      goToNextWeek,
      goToToday,
    }),
    [
      firstDayOfTheWeek,
      lastDayOfTheWeek,
      goToPreviousWeek,
      goToNextWeek,
      goToToday,
    ]
  );

  return (
    <AvailabilitySelectedWeekContext.Provider value={value}>
      {children}
    </AvailabilitySelectedWeekContext.Provider>
  );
};

export const useAvailabilitySelectedWeek = () => {
  const ctx = useContext(AvailabilitySelectedWeekContext);

  if (!ctx) {
    throw new Error(
      "Must be called within <AvailabilitySelectedWeekContextProvider />"
    );
  }

  return ctx;
};
