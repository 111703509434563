import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  ApiErrorSchema,
  PaginationQueryParamsSchema,
  SearchMembersFilterSchema,
  getPaginationResponseSchema,
} from "./common";
import { MemberSchema } from "./members";

const c = initContract();

const ChannelParticipantsSchema = z.object({
  memberIds: z
    .array(z.coerce.string())
    .max(100, "Can only add up to 100 individual members at a time"),
  groupIds: z.array(z.coerce.string()),
  contactGroupIds: z.array(z.coerce.string()),
  selectAllMembersQuery: SearchMembersFilterSchema.optional(),
});

const CreateChannelInputSchema = ChannelParticipantsSchema.extend({
  name: z.string().optional(),
  unique: z.boolean().optional(),
  selectAllMembersQuery: SearchMembersFilterSchema.optional(),
});

export const chatContract = c.router({
  token: {
    method: "POST",
    path: "/chat/token",
    body: null,
    responses: {
      200: z.object({
        token: z.string(),
      }),
    },
    summary: "Create authentication token for chat",
  },
  createChannel: {
    method: "POST",
    path: "/chat/channel",
    body: CreateChannelInputSchema,
    responses: {
      200: z.object({
        channelId: z.string(),
      }),
    },
    summary: "Create a message channel",
  },
  getChannelMembers: {
    method: "GET",
    path: "/chat/channel/:channelId/members",
    query: PaginationQueryParamsSchema,
    responses: {
      200: getPaginationResponseSchema(MemberSchema),
      404: ApiErrorSchema,
    },
    summary: "Get the members of a channel",
  },
  addChannelMembers: {
    method: "POST",
    path: "/chat/channel/:channelId/members",
    body: ChannelParticipantsSchema,
    responses: {
      200: null,
      400: ApiErrorSchema,
    },
    summary: "Add members to a channel",
  },
  removeChannelMembers: {
    method: "DELETE",
    path: "/chat/channel/:channelId/members",
    body: z.object({
      memberIds: z
        .array(z.string())
        .max(100, "Can only remove up to 100 members at a time"),
    }),
    responses: {
      200: null,
      400: ApiErrorSchema,
    },
    summary: "Remove a member from a channel",
  },
});
