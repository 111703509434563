import { Theme, Typography, useTheme, TypographyProps } from "@mui/material";
import { ForegroundTones } from "@ses-mams/react-utils";

export type HeadingProps = Pick<TypographyProps, "sx"> & {
  align?: TypographyProps["textAlign"];
  level?: keyof Theme["tokens"]["typography"]["heading"];
  tone?: ForegroundTones;
  children: React.ReactNode;
};

export const Heading = ({
  align,
  tone = "primary",
  level = "1",
  sx,
  ...props
}: HeadingProps) => {
  const {
    tokens: {
      colors: { foreground },
      typography: { heading },
    },
  } = useTheme();

  return (
    <Typography
      {...props}
      component={levelToDefaultElement[level]}
      textAlign={align}
      color={foreground[tone]}
      sx={{ ...heading[level], ...sx }}
    />
  );
};

const levelToDefaultElement = {
  "1": "h1",
  "2": "h2",
  "3": "h3",
  "4": "h4",
  "5": "h5",
} as const;
