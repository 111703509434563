import { initContract } from "@ts-rest/core";
import { z } from "zod";

import { ApiErrorSchema } from "./common";
import { MemberSchema } from "./members";

const c = initContract();

const AuthResponseSchema = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
  member: MemberSchema,
});

const authContract = c.router({
  login: {
    method: "POST",
    path: "/auth/login",
    body: z.object({
      code: z.string(),
      codeVerifier: z.string(),
      redirectUri: z.string(),
      deviceToken: z.string().nullish(),
    }),
    responses: {
      200: AuthResponseSchema,
      400: ApiErrorSchema,
      403: ApiErrorSchema,
      500: ApiErrorSchema,
    },
    summary: "Attempt to log user in",
  },
  logout: {
    method: "POST",
    path: "/auth/logout",
    body: z.object({
      deviceToken: z.string().nullish(),
      blacklistTokens: z.array(z.string()).optional(),
    }),
    responses: {
      200: null,
    },
    summary: "Log the user out",
  },
  refresh: {
    method: "POST",
    path: "/auth/refresh",
    body: z.object({
      refreshToken: z.string(),
    }),
    responses: {
      200: AuthResponseSchema,
      401: ApiErrorSchema,
      403: ApiErrorSchema,
    },
    summary: "Attempt to refresh tokens",
  },
  impersonate: {
    method: "POST",
    path: "/auth/impersonate",
    body: z.object({
      memberId: z.string(),
      deviceToken: z.string().nullish(),
    }),
    responses: {
      200: AuthResponseSchema,
      400: ApiErrorSchema,
      401: ApiErrorSchema,
      403: ApiErrorSchema,
    },
    summary: "Impersonate Member",
  },
});

export type AuthResponse = z.infer<typeof AuthResponseSchema>;

export { authContract };
