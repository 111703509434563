import { ButtonBase } from "@mui/material";
import { OutOfAreaActivationApproval } from "@ses-mams/api-contract";
import {
  formatDateTime,
  formatMemberFullName,
  formatOutOfAreaActivationLevel,
  useArrayState,
  useTransformPagedDataToItems,
} from "@ses-mams/react-utils";
import { useCallback, useMemo, useState } from "react";
import { Link, createSearchParams } from "react-router-dom";
import { Badge } from "~/components/ui/badge";
import { Button } from "~/components/ui/button";
import { Checkbox } from "~/components/ui/checkbox";
import { InfiniteList } from "~/components/ui/list";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { EmptyState } from "~/components/common/emptyState";
import { tsr } from "~/utils/client";
import { DEFAULT_PAGE_SIZE, getNextPageParam } from "~/utils/pagination";
import { ApprovalActionDrawer } from "./OutOfAreaApproval";
import { useApprovalQueueFiltersContext } from "./ApprovalQueueFilters";

type ApprovalState = "pending" | "previous";

type ApprovalsListProps = {
  state: ApprovalState;
};

export const ApprovalsList = ({ state }: ApprovalsListProps) => {
  const [selectedIds, { append, remove, clear, setValue }] =
    useArrayState<string>([]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const isShowingPending = state === "pending";

  const { data, hasNextPage, fetchNextPage } = useApprovalListData(state);

  const renderItem = useCallback(
    ({
      request,
      activation,
      history: { createdAt, comments },
    }: OutOfAreaActivationApproval) => {
      const isChecked = isShowingPending && selectedIds.includes(request.id);
      return (
        <Stack
          flex={1}
          direction="row"
          gap="large"
          align="start"
          paddingY="medium"
        >
          <Link
            to={{
              pathname: request.id,
              search: `?${createSearchParams({ state })}`,
            }}
            style={{ color: "inherit", textDecoration: "none", width: "100%" }}
          >
            <Stack direction="row" justify="space-between" gap="small">
              <Stack flex={1} gap="medium">
                <Text size="large" tone="info" weight="medium">
                  {formatMemberFullName(request.member)}
                </Text>

                <Stack gap="xsmall">
                  <Text>{activation.title}</Text>
                  <Text tone="secondary">
                    {formatDateTime(new Date(createdAt), "long")}
                  </Text>

                  {!!comments && (
                    <Text tone="secondary">
                      Previous approval comment: {comments}
                    </Text>
                  )}
                </Stack>
              </Stack>

              <Stack direction="row" gap="medium">
                <Badge size="medium" tone="muted">
                  {formatOutOfAreaActivationLevel(activation.level)}
                </Badge>
              </Stack>
            </Stack>
          </Link>

          {isShowingPending && (
            <ButtonBase
              role="checkbox"
              onClick={() =>
                isChecked ? remove(request.id) : append(request.id)
              }
            >
              <Checkbox checked={isChecked} />
            </ButtonBase>
          )}
        </Stack>
      );
    },
    [isShowingPending, selectedIds, state]
  );

  const items = useTransformPagedDataToItems(data);

  const isSelectAllChecked = useMemo(
    () =>
      !!selectedIds.length &&
      items.every(i => selectedIds.includes(i.request.id)),
    [items, selectedIds]
  );

  const onSelectAllClick = useCallback(() => {
    if (isSelectAllChecked) {
      clear();
      return;
    }

    setValue(items.map(i => i.request.id));
  }, [isSelectAllChecked, items]);

  const onSuccess = useCallback(() => {
    setValue([]);
    setIsDrawerOpen(false);
  }, []);

  const selectedActivationId = useMemo(() => {
    if (selectedIds.length && items.length) {
      return items.find(item => item.request.id === selectedIds[0])?.activation
        ?.id;
    }
    return undefined;
  }, [selectedIds, items]);

  return (
    <>
      {isShowingPending && (
        <Stack align="center" direction="row" justify="space-between">
          <SelectAllApplicationsToggle
            checked={isSelectAllChecked}
            onClick={onSelectAllClick}
          />

          <Button
            disabled={!selectedIds.length}
            onClick={() => setIsDrawerOpen(true)}
          >
            Next
          </Button>
        </Stack>
      )}

      <InfiniteList
        keyExtractor={item => item.request.id}
        data={items}
        renderItem={renderItem}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        listEmptyElement={
          <EmptyState
            title="No approvals"
            description={
              isShowingPending
                ? "There are no pending approvals."
                : "There are no previous approvals."
            }
          />
        }
      />

      <ApprovalActionDrawer
        activationId={selectedActivationId}
        requestIds={selectedIds}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onSuccess={onSuccess}
      />
    </>
  );
};

type SelectAllApplicationsToggleProps = {
  checked: boolean;
  onClick: () => void;
};

const SelectAllApplicationsToggle = ({
  checked,
  onClick,
}: SelectAllApplicationsToggleProps) => {
  return (
    <ButtonBase role="checkbox" onClick={onClick}>
      <Stack direction="row" gap="small" paddingY="medium">
        <Text weight="medium">Select All Responses</Text>

        <Checkbox checked={checked} />
      </Stack>
    </ButtonBase>
  );
};

const useApprovalListData = (state: ApprovalState) => {
  const { status, roles, units, clusters, zones } =
    useApprovalQueueFiltersContext();

  const { roleIds, unitIds, clusterIds, zoneIds } = useMemo(
    () => ({
      roleIds: roles.map(r => r.id),
      unitIds: units.map(u => u.id),
      clusterIds: clusters.map(c => c.id),
      zoneIds: zones.map(z => z.id),
    }),
    [roles, units, clusters, zones]
  );

  return tsr.outOfAreaActivationApprovals.listApprovals.useInfiniteQuery({
    queryKey: [
      "out-of-area-activation-approvals-v2",
      { state, status, roleIds, unitIds, clusterIds, zoneIds },
    ],
    queryData: ({ pageParam }) => ({
      query: {
        state,
        status,
        roleIds,
        unitIds,
        clusterIds,
        zoneIds,
        skip: pageParam?.skip ?? 0,
        take: pageParam?.take ?? DEFAULT_PAGE_SIZE,
      },
    }),
    initialPageParam: { skip: 0, take: DEFAULT_PAGE_SIZE },
    getNextPageParam,
  });
};
