import { useCallback, useRef, useState } from "react";
import { HourlyAvailability } from "@ses-mams/react-utils";
import keyBy from "lodash.keyby";
import merge from "lodash.merge";
import mergeWith from "lodash.mergewith";

export const useEditAvailability = () => {
  const availabilityToUpdate = useRef<HourlyAvailability>([]);

  const [hasSelectedCells, setHasSelectedCells] = useState(false);

  const handleEditAvailability = useCallback(
    (editedAvailability: HourlyAvailability) => {
      availabilityToUpdate.current = mergeEditedAvailability(
        availabilityToUpdate.current,
        editedAvailability
      );
    },
    []
  );

  const handleCellSelectionStatusChanged = useCallback(
    (hasSelected: boolean) => {
      setHasSelectedCells(hasSelected);
    },
    []
  );

  return {
    availabilityToUpdate,
    hasSelectedCells,
    handleEditAvailability,
    handleCellSelectionStatusChanged,
  };
};

const mergeEditedAvailability = (
  currentEditedData: HourlyAvailability,
  newEditedData: HourlyAvailability
): HourlyAvailability => {
  const mergedEditedData = mergeWith(
    {},
    keyBy(currentEditedData, "date"),
    keyBy(newEditedData, "date"),
    (value, srcValue) => {
      if (Array.isArray(value) && Array.isArray(srcValue)) {
        const mergedSlotData = merge(
          keyBy(value, "hour"),
          keyBy(srcValue, "hour")
        );

        return Object.values(mergedSlotData);
      }
    }
  );

  return Object.values(mergedEditedData);
};
