import { initContract } from "@ts-rest/core";
import { z } from "zod";
import { ApiErrorSchema } from "./common";

const ChannelSettingsSchema = z.object({
  channelId: z.string(),
  memberId: z.string(),
  muteChannel: z.boolean(),
  muteChannelExpiry: z.string().datetime().nullish(),
  muteMentions: z.boolean(),
  muteMentionsExpiry: z.string().datetime().nullish(),
});

const c = initContract();

const channelsSchema = c.router({
  disableChannel: {
    method: "DELETE",
    path: "/channels/:channelId",
    pathParams: z.object({
      channelId: z.string(),
    }),
    body: null,
    responses: {
      200: null,
      404: ApiErrorSchema,
    },
    summary: "Disable a channel",
  },
  updateChannelSettings: {
    method: "POST",
    path: "/channels/:channelId/settings",
    pathParams: z.object({
      channelId: z.string(),
    }),
    body: z.object({
      muteChannel: z.boolean().optional(),
      muteChannelExpiry: z.string().datetime().nullish(),
      muteMentions: z.boolean().optional(),
      muteMentionsExpiry: z.string().datetime().nullish(),
    }),
    responses: {
      200: ChannelSettingsSchema,
    },
    summary: "Update channel settings for current member",
  },
  getChannelSettings: {
    method: "GET",
    path: "/channels/:channelId/settings",
    pathParams: z.object({
      channelId: z.string(),
    }),
    responses: {
      200: ChannelSettingsSchema,
    },
    summary: "Get channel settings",
  },
});

export type ChannelSettings = z.infer<typeof ChannelSettingsSchema>;

export { channelsSchema };
