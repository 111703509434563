import { useCallback, useState } from "react";
import { ButtonBase } from "@mui/material";
import {
  OutOfAreaActivationRequestHistory,
  OutOfAreaActivationRequest,
} from "@ses-mams/api-contract";
import { formatDateTime, formatMemberFullName } from "@ses-mams/react-utils";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import { EmptyState } from "~/components/common/emptyState";
import { OutOfAreaActivationRequestStatusBadge } from "~/components/common/outOfAreaActivationRequestStatusBadge";
import { Drawer, DrawerContent, DrawerHeader } from "~/components/ui/drawer";
import { OutOfAreaActivationRequestHistoryDetails } from "~/components/common/outOfAreaActivationRequestHistoryDetails";

type OOAARequestHistoryProps = {
  request: OutOfAreaActivationRequest;
  history: Array<OutOfAreaActivationRequestHistory>;
};

export const OOAARequestHistory = ({
  history,
  request,
}: OOAARequestHistoryProps) => {
  const [historyItem, setHistoryItem] =
    useState<OutOfAreaActivationRequestHistory>();

  const clearHistoryItem = useCallback(() => setHistoryItem(undefined), []);

  return (
    <>
      <Stack gap="xlarge">
        <Stack direction="row" align="center" justify="space-between">
          <Text size="large" weight="semi">
            Current Status
          </Text>

          {request?.status && (
            <OutOfAreaActivationRequestStatusBadge status={request.status} />
          )}
        </Stack>

        {history.length ? (
          history.map(item => (
            <ButtonBase key={item.id} onClick={() => setHistoryItem(item)}>
              <Stack flex={1} gap="small" align="start">
                <Stack
                  direction="row"
                  align="center"
                  justify="space-between"
                  sx={{ width: "100%" }}
                >
                  <Text tone="accent" weight="semi">
                    {item.action}
                    {item.commander
                      ? ` by ${formatMemberFullName(item.commander)}`
                      : ""}
                  </Text>

                  {item.level && (
                    <Text tone="secondary" weight="semi">
                      {item.level}
                    </Text>
                  )}
                </Stack>

                <Text>{formatDateTime(new Date(item.createdAt), "long")}</Text>
              </Stack>
            </ButtonBase>
          ))
        ) : (
          <EmptyState
            title="No History"
            description="There is no history to show"
          />
        )}
      </Stack>

      <Drawer open={!!historyItem} onClose={clearHistoryItem}>
        <DrawerHeader onClose={clearHistoryItem}>
          {historyItem?.action ?? ""}
        </DrawerHeader>

        <DrawerContent>
          {historyItem && (
            <OutOfAreaActivationRequestHistoryDetails
              createdAt={historyItem.createdAt}
              member={request.member}
              roles={historyItem.roles}
              availabilityBlocks={historyItem.availabilityBlocks}
              comments={historyItem.comments}
            />
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};
