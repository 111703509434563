import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  ApiErrorSchema,
  ApiMessageSchema,
  AvailabilitySchema,
  AvailabilityStatusSchema,
  CapabilitySchema,
  getPaginationResponseSchema,
  MemberStatusSchema,
  MemberSummarySchema,
  PaginationQueryParamsSchema,
  SearchMembersFilterSchema,
  ServerDateSchema,
  SuggestionSchema,
  UnitSchema,
} from "./common";
import { BroadcastMessageSummarySchema } from "./broadcasts";

const c = initContract();

export const RoleSchema = z.enum([
  "OperationalCoordinator",
  "ActivityCoordinator",
  "OOAACoordinator",
  "UnitApprover",
  "ClusterApprover",
  "ZoneApprover",
  "Administrator",
  "SystemAdministrator",
  "Impersonate",
]);

const MemberSchema = MemberSummarySchema.extend({
  isActive: z.boolean(),
  roles: z.array(RoleSchema),
  capabilities: z.array(CapabilitySchema),
  availability: AvailabilitySchema.nullish(),
  phoneNumbers: z.array(
    z.object({
      type: z.string(),
      number: z.string(),
    })
  ),
  units: z.array(
    z.object({
      unit: UnitSchema,
    })
  ),
});

const AvailabilityInputSchema = z.object({
  start: ServerDateSchema,
  end: ServerDateSchema,
  status: AvailabilityStatusSchema.nullable(),
  committed: z.boolean(),
  conditionalReason: z.string().nullable(),
  emergenciesOnly: z.boolean().nullable(),
});

const AvailabilityOutputSchema = z.object({
  availability: z.array(AvailabilitySchema),
  totalCount: z.number(),
});

const OrgUnitHierarchySchema = z.object({
  id: z.string(),
  name: z.string(),
  approvers: z.array(MemberSummarySchema),
});

const UnitHiearchySchema = OrgUnitHierarchySchema.extend({
  rank: z.string().nullish(),
});

const MemberHierarchySchema = z.object({
  zones: z.array(
    OrgUnitHierarchySchema.extend({
      clusters: z.array(
        OrgUnitHierarchySchema.extend({
          units: z.array(UnitHiearchySchema),
        })
      ),
    })
  ),
  standaloneUnits: z.array(UnitHiearchySchema),
});

export const MemberBroadcastMessageSchema =
  BroadcastMessageSummarySchema.extend({
    acknowledged: z.boolean(),
  });

const membersContract = c.router({
  me: {
    method: "GET",
    path: "/members/me",
    responses: {
      200: MemberSchema,
      404: ApiErrorSchema,
    },
    summary: "Get the details of the current member",
  },
  get: {
    method: "GET",
    path: "/members/:id",
    pathParams: z.object({
      id: z.string(),
    }),
    responses: {
      200: MemberSchema,
      404: ApiErrorSchema,
    },
    summary: "Get the details of a member",
  },
  search: {
    method: "GET",
    path: "/membersSearch",
    query: PaginationQueryParamsSchema.merge(SearchMembersFilterSchema),
    responses: {
      200: getPaginationResponseSchema(MemberSchema),
    },
    summary: "Get all members based on a filtered search",
  },
  getStatus: {
    method: "GET",
    path: "/memberStatus",
    responses: {
      200: z.object({
        status: MemberStatusSchema.nullable(),
      }),
    },
    summary: "Get the status of the currently logged in member",
  },
  getAvailability: {
    method: "GET",
    path: "/members/:memberId/units/:unitId/availability",
    pathParams: z.object({
      memberId: z.string(),
      unitId: z.string(),
    }),
    query: z.object({
      startDate: ServerDateSchema,
      endDate: ServerDateSchema,
    }),
    responses: {
      200: AvailabilityOutputSchema,
      404: ApiErrorSchema,
    },
    summary: "Get the availability of a member for a given unit",
  },
  setAvailability: {
    method: "POST",
    path: "/members/:memberId/units/:unitId/availability",
    pathParams: z.object({
      memberId: z.string(),
      unitId: z.string(),
    }),
    body: z.array(AvailabilityInputSchema),
    responses: {
      200: ApiMessageSchema,
      403: ApiErrorSchema,
    },
    summary: "Set the availability of a member for a given unit",
  },
  setAvailabilityV2: {
    method: "POST",
    path: "/members/:memberId/units/:unitId/availabilityV2",
    pathParams: z.object({
      memberId: z.string(),
      unitId: z.string(),
    }),
    body: z.object({
      blocks: z.array(AvailabilityInputSchema),
      createdAt: z.string().optional(),
    }),
    responses: {
      200: ApiMessageSchema,
      403: ApiErrorSchema,
    },
    summary: "Set the availability of a member for a given unit",
  },
  getConditionalReasonSuggestions: {
    method: "GET",
    path: "/members/:memberId/availability/reasons",
    pathParams: z.object({
      memberId: z.string(),
    }),
    responses: {
      200: z.array(z.string()),
    },
    summary: "Get suggestions of availability conditional reasons",
  },
  revokeDeviceSessions: {
    method: "POST",
    path: "/members/:memberId/devices/revoke",
    pathParams: z.object({
      memberId: z.string(),
    }),
    body: null,
    responses: {
      200: null,
      403: ApiErrorSchema,
      404: ApiErrorSchema,
    },
    summary:
      "Revoke all device sessions registered for a member and force them to log in again on each device",
  },
  listSuggestions: {
    method: "GET",
    path: "/memberSuggestions",
    query: z.object({
      query: z.coerce.string().trim(),
      excludeCapabilityIds: z.array(z.coerce.string()).optional(),
      excludeSuggestionTypes: z.array(z.string()).optional(),
    }),
    responses: {
      200: z.array(SuggestionSchema),
    },
    summary:
      "Get member search suggestions of their units, zones, clusters and capabilities",
  },
  getOrgHierarchy: {
    method: "GET",
    path: "/members/:memberId/orgHierarchy",
    pathParams: z.object({
      memberId: z.string(),
    }),
    responses: {
      200: MemberHierarchySchema,
    },
    summary: "Get the org hierarchy of a member",
  },
  getBroadcastMessages: {
    method: "GET",
    path: "/broadcast-messages",
    query: PaginationQueryParamsSchema.extend({
      platform: z.enum(["web", "app"]),
      showUnacknowledgeMessagesOnly: z.boolean().optional(),
    }),
    responses: {
      200: getPaginationResponseSchema(MemberBroadcastMessageSchema),
    },
    summary: "GEt member broadcast messages",
  },
  getUnacknowledgedBroadcastMessageCount: {
    method: "GET",
    path: "/broadcast-messages/unacknowledged-count",
    query: z.object({
      platform: z.enum(["web", "app"]),
    }),
    responses: {
      200: z.object({
        totalCount: z.number(),
      }),
    },
    summary: "Get member broadcast messages",
  },
});

export type Member = z.infer<typeof MemberSchema>;
export type AvailabilityInput = z.infer<typeof AvailabilityInputSchema>;
export type Role = z.infer<typeof RoleSchema>;
export type AvailabilityStatus = z.infer<typeof AvailabilityStatusSchema>;
export type MemberOrgHierarchy = z.infer<typeof MemberHierarchySchema>;
export type MemberBroadcastMessage = z.infer<
  typeof MemberBroadcastMessageSchema
>;

export { membersContract, MemberSchema };
