import { initContract } from "@ts-rest/core";
import { z } from "zod";

const c = initContract();

const DevicePlatformSchema = z.enum(["android", "ios"]);

export const devicesContract = c.router({
  register: {
    method: "POST",
    path: "/devices",
    body: z.object({
      deviceToken: z.string(),
      platform: DevicePlatformSchema,
    }),
    responses: {
      204: null,
    },
    summary: "Register device for push notifications and/or location tracking.",
  },
  updateLocation: {
    method: "POST",
    path: "/devices/location",
    body: z.object({
      deviceToken: z.string(),
      latitude: z.number(),
      longitude: z.number(),
    }),
    responses: {
      200: null,
    },
    summary: "Update the location of a device.",
  },
  deleteLocation: {
    method: "DELETE",
    path: "/devices/location",
    body: z.object({
      deviceToken: z.string(),
    }),
    responses: {
      200: null,
    },
    summary: "Delete all location data of a device.",
  },
  updateGPSTracking: {
    method: "PATCH",
    path: "/devices/gps-tracking",
    body: z.object({
      enabled: z.boolean(),
    }),
    responses: {
      200: null,
    },
    summary: "Log GPS tracking settings",
  },
});

export type DevicePlatform = z.infer<typeof DevicePlatformSchema>;
