import React, { useRef, useState, useLayoutEffect } from "react";
import { Box } from "~/components/ui/box";

export const NoAvailabilityLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const gridContentHeightRef = useRef<HTMLDivElement>(null);
  const [gridHeight, setGridHeight] = useState(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (gridContentHeightRef.current?.offsetTop) {
        setGridHeight(gridContentHeightRef.current?.offsetTop);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      display="flex"
      direction="column"
      ref={gridContentHeightRef}
      grow={1}
      sx={{ minHeight: `calc(100vh - ${gridHeight + 16}px)`, height: "100%" }}
    >
      {children}
    </Box>
  );
};
