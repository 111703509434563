import { TextField } from "~/components/ui/textField";
import { LocationField } from "~/components/common/eventForm/LocationField";
import { Controller, useFormContext } from "react-hook-form";
import { EventFormSchema } from "@ses-mams/validation";
import { DateTimeField } from "~/components/ui/dateTimeField";

type Props = {
  type: "Activity" | "Activation";
  showDates?: boolean;
};

export const EventForm = ({ type, showDates = true }: Props) => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<EventFormSchema>();

  return (
    <>
      <TextField
        {...register("title")}
        label="Title"
        placeholder="Enter title"
        errorMessage={errors.title?.message}
      />
      <TextField
        {...register("description")}
        label="Description"
        placeholder="Enter description"
        errorMessage={errors.description?.message}
        multiline
        rows={4}
      />

      <LocationField />

      {showDates && (
        <>
          <Controller
            name="start"
            control={control}
            render={({ field, fieldState }) => (
              <DateTimeField
                label={`${type} Start Time`}
                value={field.value}
                onValueChange={field.onChange}
                onBlur={field.onBlur}
                errorMessage={
                  fieldState.error?.message ||
                  errors?.start?.date?.message ||
                  errors?.start?.time?.message
                }
              />
            )}
          />
          <Controller
            name="end"
            control={control}
            render={({ field, fieldState }) => (
              <DateTimeField
                label={`${type} End Time`}
                value={field.value}
                onValueChange={field.onChange}
                onBlur={field.onBlur}
                errorMessage={
                  fieldState.error?.message ||
                  errors?.end?.date?.message ||
                  errors?.end?.time?.message
                }
              />
            )}
          />
        </>
      )}
    </>
  );
};
