import { BoxProps, useTheme } from "@mui/material";
import { SystemCssProperties } from "@mui/system";

import {
  BackgroundTones,
  BorderRadii,
  BorderTones,
  BorderWidths,
  Shadows,
  mapKeyToValue,
} from "@ses-mams/react-utils";

import { Sizings, Spacings } from "../theme";
import { Tokens } from "../theme/tokens";
import { ResponsiveValue, mapResponsiveKeyToValue } from "../theme/utils";

export type MarginProps = {
  margin?: Spacings;
  marginTop?: Spacings;
  marginRight?: Spacings;
  marginBottom?: Spacings;
  marginLeft?: Spacings;
  marginX?: Spacings;
  marginY?: Spacings;
};

export type PaddingProps = {
  padding?: ResponsiveValue<Spacings>;
  paddingTop?: ResponsiveValue<Spacings>;
  paddingRight?: ResponsiveValue<Spacings>;
  paddingBottom?: ResponsiveValue<Spacings>;
  paddingLeft?: ResponsiveValue<Spacings>;
  paddingX?: ResponsiveValue<Spacings>;
  paddingY?: ResponsiveValue<Spacings>;
};

export type FlexProps = {
  align?: BoxProps["alignItems"];
  alignSelf?: BoxProps["alignSelf"];
  direction?: ResponsiveValue<
    Extract<
      BoxProps["flexDirection"],
      "row" | "row-reverse" | "column" | "column-reverse"
    >
  >;
  justify?: BoxProps["justifyContent"];
  wrap?: boolean;
  flex?: BoxProps["flex"];
  basis?: BoxProps["flexBasis"];
  grow?: BoxProps["flexGrow"];
  shrink?: BoxProps["flexShrink"];
};

type PositionProps = {
  position?: BoxProps["position"];
  top?: Spacings;
  right?: Spacings;
  bottom?: Spacings;
  left?: Spacings;
};

type BorderProps = {
  border?: BorderTones;
  borderTop?: BorderTones;
  borderRight?: BorderTones;
  borderBottom?: BorderTones;
  borderLeft?: BorderTones;
  borderRadius?: BorderRadii;
  borderTopRightRadius?: BorderRadii;
  borderBottomRightRadius?: BorderRadii;
  borderBottomLeftRadius?: BorderRadii;
  borderTopLeftRadius?: BorderRadii;
  borderWidth?: BorderWidths;
  borderTopWidth?: BorderWidths;
  borderRightWidth?: BorderWidths;
  borderBottomWidth?: BorderWidths;
  borderLeftWidth?: BorderWidths;
};

type OtherProps = {
  background?: BackgroundTones;
  opacity?: SystemCssProperties["opacity"];
  overflow?: BoxProps["overflow"];
  overflowX?: SystemCssProperties["overflowX"];
  overflowY?: SystemCssProperties["overflowY"];
  height?: Sizings;
  width?: Sizings;
  shadow?: Shadows;
  zIndex?: BoxProps["zIndex"];
};

export type BoxStyleProps = MarginProps &
  PaddingProps &
  FlexProps &
  PositionProps &
  BorderProps &
  OtherProps;

export const useBoxStyles = ({
  align,
  alignSelf,
  background,
  basis,
  border,
  borderBottom,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderBottomWidth,
  borderLeft,
  borderLeftWidth,
  borderRadius,
  borderRight,
  borderRightWidth,
  borderTop,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderTopWidth,
  borderWidth,
  bottom,
  direction,
  flex,
  grow,
  height,
  justify,
  left,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY,
  opacity,
  overflow,
  overflowX,
  overflowY,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY,
  position,
  right,
  shadow,
  shrink,
  top,
  width,
  wrap,
  zIndex,
}: BoxStyleProps) => {
  const { tokens } = useTheme();
  const borderProps = useBorderProps(
    {
      border,
      borderBottom,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      borderBottomWidth,
      borderLeft,
      borderLeftWidth,
      borderRadius,
      borderRight,
      borderRightWidth,
      borderTop,
      borderTopLeftRadius,
      borderTopRightRadius,
      borderTopWidth,
      borderWidth,
    },
    tokens
  );
  const marginProps = useMarginProps(
    {
      margin,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      marginX,
      marginY,
    },
    tokens
  );
  const paddingProps = usePaddingProps(
    {
      padding,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingX,
      paddingY,
    },
    tokens
  );
  const positionProps = usePositionProps(
    { bottom, left, position, right, top },
    tokens
  );
  const shadowStyles = mapKeyToValue(shadow, tokens.shadow);

  return {
    ...borderProps,
    ...marginProps,
    ...paddingProps,
    ...positionProps,
    ...shadowStyles,
    alignItems: align,
    alignSelf,
    backgroundColor: mapKeyToValue(background, tokens.colors.background),
    height: mapKeyToValue(height, tokens.sizing),
    width: mapKeyToValue(width, tokens.sizing),
    justifyContent: justify,
    flexWrap: (wrap ? "wrap" : "nowrap") as BoxProps["flexWrap"],
    flexDirection: direction,
    flexBasis: basis,
    flexGrow: grow,
    flexShrink: shrink,
    flex,
    opacity,
    overflow,
    overflowX,
    overflowY,
    zIndex,
  };
};

const usePositionProps = (
  { bottom, left, position, right, top }: PositionProps,
  { spacing }: Tokens
) => {
  return {
    position,
    top: mapKeyToValue(top, spacing),
    bottom: mapKeyToValue(bottom, spacing),
    right: mapKeyToValue(right, spacing),
    left: mapKeyToValue(left, spacing),
  };
};

const useMarginProps = (
  {
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    marginX,
    marginY,
  }: MarginProps,
  { spacing }: Tokens
) => {
  const mt = marginTop || marginY || margin;
  const mb = marginBottom || marginY || margin;
  const mr = marginRight || marginX || margin;
  const ml = marginLeft || marginX || margin;

  return {
    marginTop: mapKeyToValue(mt, spacing),
    marginBottom: mapKeyToValue(mb, spacing),
    marginRight: mapKeyToValue(mr, spacing),
    marginLeft: mapKeyToValue(ml, spacing),
  };
};

export const usePaddingProps = (
  {
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingX,
    paddingY,
  }: PaddingProps,
  { spacing }: Tokens
) => {
  const pt = paddingTop || paddingY || padding;
  const pb = paddingBottom || paddingY || padding;
  const pr = paddingRight || paddingX || padding;
  const pl = paddingLeft || paddingX || padding;

  return {
    paddingTop: mapResponsiveKeyToValue(pt, spacing),
    paddingBottom: mapResponsiveKeyToValue(pb, spacing),
    paddingRight: mapResponsiveKeyToValue(pr, spacing),
    paddingLeft: mapResponsiveKeyToValue(pl, spacing),
  };
};

const useBorderProps = (
  {
    border,
    borderBottom,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderBottomWidth,
    borderLeft,
    borderLeftWidth,
    borderRadius,
    borderRight,
    borderRightWidth,
    borderTop,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderTopWidth,
    borderWidth,
  }: BorderProps,
  { border: borderTheme }: Tokens
) => {
  const bblRadius = borderBottomLeftRadius || borderRadius;
  const bbrRadius = borderBottomRightRadius || borderRadius;
  const btlRadius = borderTopLeftRadius || borderRadius;
  const btrRadius = borderTopRightRadius || borderRadius;

  const borderRadiusStyles = {
    borderBottomLeftRadius: mapKeyToValue(bblRadius, borderTheme.radius),
    borderBottomRightRadius: mapKeyToValue(bbrRadius, borderTheme.radius),
    borderTopLeftRadius: mapKeyToValue(btlRadius, borderTheme.radius),
    borderTopRightRadius: mapKeyToValue(btrRadius, borderTheme.radius),
  };

  const btColor = borderTop || border;
  const bbColor = borderBottom || border;
  const brColor = borderRight || border;
  const blColor = borderLeft || border;

  const btWidth = borderTopWidth || borderWidth;
  const bbWidth = borderBottomWidth || borderWidth;
  const brWidth = borderRightWidth || borderWidth;
  const blWidth = borderLeftWidth || borderWidth;

  const btStyle = btWidth ? ("solid" as const) : undefined;
  const bbStyle = bbWidth ? ("solid" as const) : undefined;
  const brStyle = brWidth ? ("solid" as const) : undefined;
  const blStyle = blWidth ? ("solid" as const) : undefined;

  return {
    borderTopColor: mapKeyToValue(btColor, borderTheme.color) || "transparent",
    borderBottomColor:
      mapKeyToValue(bbColor, borderTheme.color) || "transparent",
    borderRightColor:
      mapKeyToValue(brColor, borderTheme.color) || "transparent",
    borderLeftColor: mapKeyToValue(blColor, borderTheme.color) || "transparent",
    borderTopWidth: mapKeyToValue(btWidth, borderTheme.width),
    borderBottomWidth: mapKeyToValue(bbWidth, borderTheme.width),
    borderRightWidth: mapKeyToValue(brWidth, borderTheme.width),
    borderLeftWidth: mapKeyToValue(blWidth, borderTheme.width),
    borderTopStyle: btStyle,
    borderBottomStyle: bbStyle,
    borderRightStyle: brStyle,
    borderLeftStyle: blStyle,
    ...borderRadiusStyles,
  };
};
