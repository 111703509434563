import { initContract } from "@ts-rest/core";
import { z } from "zod";

import {
  CapabilitySchema,
  PaginationQueryParamsSchema,
  getPaginationResponseSchema,
} from "./common";

const c = initContract();

export const capabilitiesContract = c.router({
  list: {
    method: "GET",
    path: "/capabilities",
    query: PaginationQueryParamsSchema.extend({
      query: z.string().optional(),
      unitId: z.coerce.string().optional(),
      clusterId: z.coerce.string().optional(),
      zoneId: z.coerce.string().optional(),
    }),
    responses: {
      200: getPaginationResponseSchema(CapabilitySchema),
    },
    summary: "Get all capabilities based on parameters",
  },
});
