import * as React from "react";

import { createFilledIcon } from "~/components/ui/icon/createIcon";

export const Visibility = createFilledIcon(
  <>
    <path d="M10.2422 10.2422C10.737 9.7474 11.3229 9.5 12 9.5C12.6771 9.5 13.263 9.7474 13.7578 10.2422C14.2526 10.737 14.5 11.3229 14.5 12C14.5 12.6771 14.2526 13.263 13.7578 13.7578C13.263 14.2526 12.6771 14.5 12 14.5C11.3229 14.5 10.737 14.2526 10.2422 13.7578C9.7474 13.263 9.5 12.6771 9.5 12C9.5 11.3229 9.7474 10.737 10.2422 10.2422ZM9.03125 14.9688C9.86458 15.776 10.8542 16.1797 12 16.1797C13.1458 16.1797 14.1224 15.776 14.9297 14.9688C15.763 14.1354 16.1797 13.1458 16.1797 12C16.1797 10.8542 15.763 9.8776 14.9297 9.07031C14.1224 8.23698 13.1458 7.82031 12 7.82031C10.8542 7.82031 9.86458 8.23698 9.03125 9.07031C8.22396 9.8776 7.82031 10.8542 7.82031 12C7.82031 13.1458 8.22396 14.1354 9.03125 14.9688ZM6.41406 7.46875C8.08073 6.32292 9.94271 5.75 12 5.75C14.0573 5.75 15.9193 6.32292 17.5859 7.46875C19.2526 8.61458 20.4505 10.125 21.1797 12C20.4505 13.875 19.2526 15.3854 17.5859 16.5312C15.9193 17.6771 14.0573 18.25 12 18.25C9.94271 18.25 8.08073 17.6771 6.41406 16.5312C4.7474 15.3854 3.54948 13.875 2.82031 12C3.54948 10.125 4.7474 8.61458 6.41406 7.46875Z" />
  </>,
  "Visibility"
);
