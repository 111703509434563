import { useParams, useSearchParams } from "react-router-dom";
import { PencilFilledIcon } from "~/components/ui/icon";
import { Stack } from "~/components/ui/stack";
import { useOtherMemberAvailabilityData } from "./hooks/useOtherMemberAvailabilityData";
import {
  formatMemberFullName,
  useAvailabilitySelectedWeek,
} from "@ses-mams/react-utils";
import { AvailabilityWeekSelector } from "~/components/common/availabilitySelector";
import { Heading } from "~/components/ui/heading";
import { Spinner } from "~/components/ui/spinner";
import { NoAvailabilityLayout } from "./components/common/NoAvailabilityLayout";
import { ViewAvailabilityHourlyGrid } from "./components/hourly/ViewAvailabilityHourlyGrid";
import { GridCellHeightContextProvider } from "./context/gridCellHeightContext";
import { Button } from "~/components/ui/button";
import { Box } from "~/components/ui/box";
import { OtherMemberAvailabilityLayout } from "./components/common/OtherMemberAvailabilityLayout";
import { useHasAnyRole } from "~/context/auth";

export const OtherMemberAvailabilityPage = () => {
  const params = useParams();
  const memberId = params.memberId as string;

  const [searchParams] = useSearchParams();
  const unitId = searchParams.get("unitId") ?? undefined;

  const canEdit = useHasAnyRole([
    "OperationalCoordinator",
    "ActivityCoordinator",
  ]);

  const {
    firstDayOfTheWeek,
    lastDayOfTheWeek,
    goToToday,
    goToNextWeek,
    goToPreviousWeek,
  } = useAvailabilitySelectedWeek();

  const { member, isLoading, hasAvailabilitySet, hourlyAvailability } =
    useOtherMemberAvailabilityData({
      memberId,
      startOfTheWeek: firstDayOfTheWeek,
      endOfTheWeek: lastDayOfTheWeek,
      unitId,
    });

  return (
    <OtherMemberAvailabilityLayout>
      <Stack direction="column" gap="medium" sx={{ height: "100%" }}>
        <Stack direction="row" justify="space-between" align="center">
          {!!member && (
            <Heading level="1">{formatMemberFullName(member)}</Heading>
          )}

          {canEdit && (
            <Button
              variant="link"
              startIcon={PencilFilledIcon}
              href={`/availability/member/${member?.id}/edit?unitId=${unitId}`}
            >
              Edit
            </Button>
          )}
        </Stack>

        <Box border="neutral" borderWidth="medium" borderRadius="medium">
          <Stack
            direction={{
              xs: "column-reverse",
              md: "row",
            }}
            gap="medium"
            justify="space-between"
            align="center"
            padding="medium"
            border="neutral"
            borderBottomWidth="medium"
            sx={{
              width: "100%",
            }}
          >
            <Stack
              gap="medium"
              justify="space-between"
              grow={1}
              direction={{
                xs: "column",
                md: "row",
              }}
              sx={{ width: "100%" }}
            >
              <AvailabilityWeekSelector
                firstDayOfTheWeek={firstDayOfTheWeek}
                lastDayOfTheWeek={lastDayOfTheWeek}
                onGoToPreviousWeek={goToPreviousWeek}
                onGoToNextWeek={goToNextWeek}
              />

              <Button variant="secondary" onClick={goToToday}>
                Today
              </Button>
            </Stack>
          </Stack>

          {isLoading && (
            <NoAvailabilityLayout>
              <Box display="flex" justify="center" align="center" flex={1}>
                <Spinner />
              </Box>
            </NoAvailabilityLayout>
          )}

          {!isLoading && (
            <GridCellHeightContextProvider numberOfScheduleItems={0}>
              <ViewAvailabilityHourlyGrid
                availability={hourlyAvailability}
                hasAvailabilitySet={hasAvailabilitySet}
              />
            </GridCellHeightContextProvider>
          )}
        </Box>
      </Stack>
    </OtherMemberAvailabilityLayout>
  );
};
