import { useCallback } from "react";
import { ButtonBase } from "@mui/material";
import { Group } from "@ses-mams/api-contract";
import { useDebouncedSearch } from "@ses-mams/react-utils";
import emptyListImageUrl from "~/assets/emptyList.png";
import { SearchInput } from "~/components/ui/searchInput";
import { Stack } from "~/components/ui/stack";
import { InfiniteList } from "~/components/ui/list";
import { Checkbox } from "~/components/ui/checkbox";
import { EmptyState } from "~/components/common/emptyState";
import { GroupListItem } from "~/components/common/group";
import { useGroupListSearch } from "./useGroupListSearch";

type SearchGroupsListProps = {
  groups: Array<Group>;
  removeGroup: (group: Group) => void;
  appendGroup: (group: Group) => void;
};

export const SelectGroupsList = ({
  groups,
  removeGroup,
  appendGroup,
}: SearchGroupsListProps) => {
  const { searchValue, setSearchValue, debouncedSearchValue } =
    useDebouncedSearch("");

  const { data, isLoading, isRefetching, hasNextPage, fetchNextPage } =
    useGroupListSearch(debouncedSearchValue);

  const renderItem = useCallback(
    (item: Group) => {
      const isChecked = groups.some(({ id }) => id === item.id);

      return (
        <ButtonBase
          role="checkbox"
          aria-checked={isChecked}
          onClick={() => (isChecked ? removeGroup(item) : appendGroup(item))}
        >
          <GroupListItem
            startAdornment={<Checkbox checked={isChecked} />}
            group={item}
          />
        </ButtonBase>
      );
    },
    [groups]
  );

  const isRefreshing = isLoading || isRefetching;

  return (
    <Stack flex={1} gap="large">
      <SearchInput
        placeholder="Search"
        value={searchValue}
        onValueChange={setSearchValue}
      />

      <InfiniteList
        data={data}
        keyExtractor={item => item.id}
        renderItem={renderItem}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        listEmptyElement={
          isRefreshing ? null : (
            <EmptyState
              image={<img src={emptyListImageUrl} />}
              title="No groups found"
              description="There are no groups associated with your search. Please try another value."
            />
          )
        }
      />
    </Stack>
  );
};
