import { OutOfAreaActivationRole } from "@ses-mams/api-contract";
import { useCallback, useState } from "react";
import { SelectRolesDrawer } from "~/components/common/selectRolesDrawer/SelectRolesDrawer";

export const useSelectRolesDrawer = (
  onRolesSelected: (roles: Array<OutOfAreaActivationRole>) => void
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<
    Array<OutOfAreaActivationRole>
  >([]);

  const openDrawer = useCallback((roles: Array<OutOfAreaActivationRole>) => {
    setSelectedRoles(roles);
    setIsOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSelectRoles = useCallback(
    (roles: Array<OutOfAreaActivationRole>) => {
      onRolesSelected(roles);
      closeDrawer();
    },
    [onRolesSelected, closeDrawer]
  );

  const renderDrawer = () => (
    <SelectRolesDrawer
      open={isOpen}
      onClose={closeDrawer}
      onDone={handleSelectRoles}
      roles={selectedRoles}
    />
  );

  return {
    openDrawer,
    renderDrawer,
  };
};
