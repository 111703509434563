import { Heading } from "~/components/ui/heading";
import { Stack } from "~/components/ui/stack";

type MemberDetailsSectionProps = {
  title: string;
  children: React.ReactNode;
};

export const MemberDetailsSection = ({
  title,
  children,
}: MemberDetailsSectionProps) => {
  return (
    <Stack gap="small">
      <Heading level="3">{title}</Heading>
      <Stack>{children}</Stack>
    </Stack>
  );
};
