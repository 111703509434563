import { useCallback, useState } from "react";
import { Drawer, DrawerProps } from "~/components/ui/drawer";
import { ManageActivationMembers } from "./ManageActivationMembers";
import { AddActivationMembers } from "./AddActivationMembers";

type ManageActivationMembersDrawerProps = Omit<DrawerProps, "children"> & {
  activationId: string;
  activationStart: string;
  activationEnd: string;
  isFutureActivation: boolean;
  isClosed: boolean;
  unitId: string;
};

export const ManageActivationMembersDrawer = ({
  activationId,
  activationStart,
  activationEnd,
  isFutureActivation,
  isClosed,
  onClose,
  unitId,
  open,
  ...props
}: ManageActivationMembersDrawerProps) => {
  const [showAddActivationMembers, setShowAddActivationMembers] =
    useState(false);

  const handleOnClose = useCallback(() => {
    onClose();
    setShowAddActivationMembers(false);
  }, [onClose]);

  return (
    <Drawer {...props} open={open} onClose={handleOnClose}>
      {showAddActivationMembers ? (
        <AddActivationMembers
          activationId={activationId}
          activationStart={activationStart}
          activationEnd={activationEnd}
          onClose={handleOnClose}
          onBack={() => setShowAddActivationMembers(false)}
          unitId={unitId}
        />
      ) : (
        <ManageActivationMembers
          activationId={activationId}
          isFutureActivation={isFutureActivation}
          isClosed={isClosed}
          isDrawerOpen={open}
          onAdd={() => setShowAddActivationMembers(true)}
          onClose={handleOnClose}
        />
      )}
    </Drawer>
  );
};
