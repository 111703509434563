import { MemberDetailsSection } from "./MemberDetailsSection";
import { tsr } from "~/utils/client";
import { Spinner } from "~/components/ui/spinner";
import { MemberUnitListItem } from "./MemberUnitListItem";
import { Box } from "~/components/ui/box";
import { Text } from "~/components/ui/text";
import { OrgUnitApprovers } from "./OrgUnitApprovers";
import { Stack } from "~/components/ui/stack";

type Props = {
  memberId: string;
};
export const OrgHierarchy = ({ memberId }: Props) => {
  const { data, isLoading } = tsr.members.getOrgHierarchy.useQuery({
    queryKey: ["memberOrgHierarchy", memberId],
    queryData: {
      params: { memberId },
    },
  });

  const hierarchy = data?.body;

  return (
    <MemberDetailsSection title="Organisation Hierarchy">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {hierarchy?.standaloneUnits.map(unit => (
            <MemberUnitListItem
              key={unit.id}
              id={unit.id}
              name={unit.name}
              approvers={unit.approvers}
            />
          ))}
          {hierarchy?.zones.map(zone => (
            <Stack key={zone.id} marginTop="xsmall" gap="xsmall">
              <Text size="large" weight="medium">
                {zone.name}
              </Text>
              <OrgUnitApprovers approvers={zone.approvers} />
              {zone.clusters.map(cluster => (
                <Box key={cluster.id} marginLeft="large" marginTop="small">
                  <Text size="large" weight="medium">
                    {cluster.name}
                  </Text>
                  <OrgUnitApprovers approvers={cluster.approvers} />
                  {cluster.units.map(unit => (
                    <Box key={unit.id} marginLeft="large" marginTop="small">
                      <MemberUnitListItem
                        id={unit.id}
                        name={unit.name}
                        approvers={unit.approvers}
                      />
                    </Box>
                  ))}
                </Box>
              ))}
            </Stack>
          ))}
        </>
      )}
    </MemberDetailsSection>
  );
};
