import { useCallback, useEffect, useMemo } from "react";
import { Control, UseFormSetValue, useFieldArray } from "react-hook-form";
import { ButtonBase } from "@mui/material";
import { Member } from "@ses-mams/api-contract";
import { SelectAllMembersQuery } from "@ses-mams/react-utils";
import { EditGroupFormSchema } from "@ses-mams/validation";
import { tsr } from "~/utils/client";
import { EditMemberList } from "~/components/common/editMemberList";
import { MemberListItem } from "~/components/common/searchMembersList";
import { RemoveCircleFilledIcon } from "~/components/ui/icon";
import { DEFAULT_PAGE_SIZE, getNextPageParam } from "~/utils/pagination";

type EditGroupMemberListProps = {
  control: Control<EditGroupFormSchema>;
  setValue: UseFormSetValue<EditGroupFormSchema>;
  unitId: string;
  groupId?: string;
  selectAllMembersQuery?: SelectAllMembersQuery;
  setSelectAllMembersQuery: React.Dispatch<
    React.SetStateAction<SelectAllMembersQuery | undefined>
  >;
};

export const EditGroupMemberList = ({
  control,
  unitId,
  groupId,
  selectAllMembersQuery,
  setSelectAllMembersQuery,
  setValue,
}: EditGroupMemberListProps) => {
  const {
    fields: addMembersFields,
    replace: addMembersReplace,
    remove: addMembersRemove,
  } = useFieldArray({
    control,
    name: "addMembers",
    keyName: "fieldId",
  });

  const {
    fields: removeMembersFields,
    append: removeMembersAppend,
    replace: removeMembersReplace,
  } = useFieldArray({
    control,
    name: "removeMembers",
    keyName: "fieldId",
  });

  const hasGroupId = Boolean(groupId);

  const { data, isLoading, hasNextPage, fetchNextPage } =
    tsr.groups.getGroupMembers.useInfiniteQuery({
      queryKey: ["groups", groupId, "members"],
      queryData: ({ pageParam }) => ({
        params: { unitId, id: groupId ?? "" },
        query: {
          skip: pageParam?.skip ?? 0,
          take: pageParam?.take ?? DEFAULT_PAGE_SIZE,
        },
      }),
      enabled: hasGroupId,
      initialPageParam: { skip: 0, take: DEFAULT_PAGE_SIZE },
      getNextPageParam,
    });
  const isLoadingMembers = isLoading && hasGroupId;

  const members = useMemo(() => {
    if (!data?.pages) {
      return addMembersFields;
    }

    const addedMembersMap = new Map(addMembersFields.map(m => [m.id, m]));
    const removedMembersMap = new Map(removeMembersFields.map(m => [m.id, m]));

    const pagedItems = (data?.pages || [])
      .flatMap(page => (page.status === 200 ? page.body.items : []))
      .filter(item => !addedMembersMap.has(item.id));

    return [...addMembersFields, ...pagedItems].filter(
      item => !removedMembersMap.has(item.id)
    );
  }, [data?.pages, addMembersFields, removeMembersFields]);

  useEffect(() => {
    setValue("numberOfMembers", members.length);
  }, [members]);

  const handleOnChangeSelectedMembers = useCallback(
    (selected: Array<Member>) => {
      addMembersReplace(selected);

      // Remove any explicitly added members from removeMembers
      const selectedMap = new Map(selected.map(m => [m.id, m]));
      removeMembersReplace(
        removeMembersFields.filter(item => !selectedMap.has(item.id))
      );
    },
    [removeMembersFields]
  );

  const renderItem = useCallback(
    (item: Member) => {
      return (
        <MemberListItem
          member={item}
          endAdornment={
            <ButtonBase
              aria-label="Remove member"
              onClick={() => {
                setSelectAllMembersQuery(prev => {
                  if (!prev) {
                    return prev;
                  }

                  // To achieve the same behaviour when Select All is triggered, we must treat it as an exclusion
                  return {
                    ...prev,
                    exclude: {
                      ...prev.exclude,
                      memberIds: [...(prev.exclude?.memberIds ?? []), item.id],
                    },
                  };
                });

                addMembersRemove(
                  addMembersFields.findIndex(m => m.id === item.id)
                );
                removeMembersAppend(item);
              }}
            >
              <RemoveCircleFilledIcon tone="critical" />
            </ButtonBase>
          }
        />
      );
    },
    [addMembersFields, removeMembersAppend]
  );

  return (
    <EditMemberList
      selectedMembers={members}
      onChangeSelectedMembers={handleOnChangeSelectedMembers}
      isLoadingMembers={isLoadingMembers}
      unitId={unitId}
      selectAllMembersQuery={selectAllMembersQuery}
      setSelectAllMembersQuery={setSelectAllMembersQuery}
      renderItem={renderItem}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
    />
  );
};
