import { Container } from "@mui/material";
import { Button } from "~/components/ui/button";
import { Heading } from "~/components/ui/heading";
import { Stack } from "~/components/ui/stack";
import { useSelectedUnit } from "~/context/unit/SelectedUnitContextProvider";
import { AvailabilityReport } from "./components/AvailabilityReport";
import { useHasAnyRole } from "~/context/auth";

export const UnitsPage = () => {
  const { selectedUnit, memberBelongsToSelectedUnit } = useSelectedUnit();
  const selectedUnitId = selectedUnit?.id;
  const isOpCoordinator = useHasAnyRole(["OperationalCoordinator"]);
  const isAdmin = useHasAnyRole(["Administrator", "SystemAdministrator"]);

  return (
    <Container maxWidth="lg">
      <Stack direction="column" gap="xlarge">
        <Stack direction="row" justify="space-between">
          <Heading level="1">Unit</Heading>

          <Stack direction="row" gap="medium">
            {(isOpCoordinator || isAdmin) && (
              <Button variant="link" href={`/units/${selectedUnitId}/requests`}>
                Unit Requests
              </Button>
            )}

            {selectedUnitId &&
              ((memberBelongsToSelectedUnit && isOpCoordinator) || isAdmin) && (
                <Button variant="link" href={`/units/${selectedUnitId}/groups`}>
                  Unit Groups
                </Button>
              )}
          </Stack>
        </Stack>

        {selectedUnit && <AvailabilityReport />}
      </Stack>
    </Container>
  );
};
