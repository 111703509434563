import { initializeApp } from "firebase/app";
import { Messaging, getMessaging } from "firebase/messaging";

const API_KEY = import.meta.env.VITE_FIREBASE_API_KEY;
const APP_ID = import.meta.env.VITE_FIREBASE_APP_ID;
export const VAPID_KEY = import.meta.env.VITE_FIREBASE_PUSH_KEY;

const isFirebaseConfiguredAndSupported = () =>
  Boolean(API_KEY && APP_ID && VAPID_KEY) && "Notification" in window;

export const initializeFirebase = async () => {
  if (!isFirebaseConfiguredAndSupported()) {
    return;
  }

  // https://firebase.google.com/docs/web/setup#config-object
  return initializeApp({
    apiKey: API_KEY,
    authDomain: "ses-mams.firebaseapp.com",
    projectId: "ses-mams",
    storageBucket: "ses-mams.appspot.com",
    messagingSenderId: "652182832521",
    appId: APP_ID,
  });
};

export const getFirebaseMessaging = (): Messaging | null => {
  return isFirebaseConfiguredAndSupported() ? getMessaging() : null;
};
