import { useMemo } from "react";

export const useParseBeaconData = (beaconData?: string | null) => {
  return useMemo(() => {
    if (!beaconData) {
      return undefined;
    }

    return JSON.parse(beaconData) as BeaconData;
  }, [beaconData]);
};

type Nullable<T> = { [K in keyof T]: T[K] | null | undefined };

type BeaconAddress = Nullable<{
  Flat: string;
  Type: string;
  Level: string;
  Street: string;
  Locality: string;
  Postcode: string;
  StreetNumber: string;
  PrettyAddress: string;
  AdditionalAddressInfo: string;
}>;

type BeaconDataItem = Nullable<{
  Id: number;
  Name: string;
  Description: string;
}>;

type BeaconData = Nullable<{
  Type: string;
  EvacuationRequired: boolean;
  JobType: BeaconDataItem | string;
  JobStatusType: BeaconDataItem | string;
  JobPriorityType: BeaconDataItem | string;
  FloodAssistanceJob: Array<Record<string, unknown>> | boolean;
  FloodAssistance: null;
  SituationOnScene: string;
  PeopleInundated: number;
  EntityAssignedTo:
    | Nullable<{ Id: number; Code: string; Name: string }>
    | string;
  CreatedOn: string;
  Address: BeaconAddress | string;
  LGA: string;
  ContactCalled: boolean;
  ContactFirstName: string;
  ContactLastName: string;
  ContactPhoneNumber: string;
  CallerLastName: string;
  CallerFirstName: string;
  CallerPhoneNumber: string;
  Tags: Array<{ Id: number; Name: string }> | string;
}>;
