import { Activation, ActivationRequest } from "@ses-mams/api-contract";
import { differenceInMinutes } from "date-fns";

type Params = {
  availabilityStatus?: ActivationRequest["availabilityStatus"];
  eta?: ActivationRequest["eta"];
  status?: ActivationRequest["status"];
  activation: {
    start: Activation["start"];
    type: Activation["type"];
  };
};

export const isFutureActivation = (activation: Params["activation"]): boolean =>
  new Date(activation.start) > new Date();

export const isActivatedToRequest = ({ status, activation }: Params): boolean =>
  status === "ActivationAccepted" && !isFutureActivation(activation);

export const isNonUrgentRequestWithAvailableStatus = ({
  availabilityStatus,
  status,
  activation,
}: Params): boolean =>
  activation.type === "NotUrgent" &&
  !!availabilityStatus &&
  availabilityStatus !== "Unavailable" &&
  !!status &&
  (["AvailabilitySubmitted", "ActivationRequested"].includes(status) ||
    (status === "ActivationAccepted" && isFutureActivation(activation)));

export const getPreActivatedEtaInMinutes = ({
  availabilityStatus,
  eta,
  status,
  activation,
}: Params): number | null => {
  if (
    eta &&
    isNonUrgentRequestWithAvailableStatus({
      availabilityStatus,
      status,
      activation,
    })
  ) {
    return differenceInMinutes(new Date(eta), new Date(activation.start), {
      roundingMethod: "ceil",
    });
  }

  return null;
};
