import { Visibility, VisibilityOff } from "~/components/ui/icon";
import {
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps as MuiAccordionSummaryProps,
} from "@mui/material";
import { useAccordionContext } from "./AccordionContextProvider";

export const AccordionTrigger = (
  props: Pick<MuiAccordionSummaryProps, "children">
) => {
  const { open } = useAccordionContext();
  const TriggerIcon = open ? VisibilityOff : Visibility;

  return (
    <MuiAccordionSummary
      {...props}
      expandIcon={<TriggerIcon tone="info" />}
      sx={theme => ({ paddingX: theme.tokens.spacing.medium })}
    />
  );
};
