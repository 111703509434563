import { useParams } from "react-router-dom";
import { Container } from "@mui/material";
import { EditGroupForm } from "./components/EditGroupForm";

export const AddGroupPage = () => {
  const unitId = useParams().unitId as string;

  return (
    <Container maxWidth="sm">
      <EditGroupForm unitId={unitId} />
    </Container>
  );
};
