import { Control, useFieldArray } from "react-hook-form";
import { EditGroupFormSchema } from "@ses-mams/validation";
import { EditCapabilitiesList } from "~/components/common/editCapabilitiesList";

type EditGroupCapabilityListProps = {
  control: Control<EditGroupFormSchema>;
};

export const EditGroupCapabilityList = ({
  control,
}: EditGroupCapabilityListProps) => {
  const { fields, replace } = useFieldArray({
    control,
    name: "capabilities",
    keyName: "fieldId",
  });

  return (
    <EditCapabilitiesList
      selectedCapabilities={fields}
      onChangeSelectedCapabilities={replace}
    />
  );
};
