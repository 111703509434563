import { useState } from "react";

import { Row, Stack } from "~/components/ui/stack";
import { Button } from "~/components/ui/button";
import { Divider } from "~/components/ui/divider";
import { Text } from "~/components/ui/text";
import { Dialog, DialogContent } from "~/components/ui/dialog";

export const ConfirmButton = ({
  text,
  title,
  description,
  confirmButtonText,
  onConfirm,
  isBusy,
  variant,
}: {
  text: string;
  title: string;
  description: string;
  confirmButtonText: string;
  onConfirm: () => void;
  isBusy?: boolean;
  variant: "primary" | "secondary" | "tertiary" | "link" | "destructive";
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClose = () => setDialogOpen(false);

  return (
    <>
      <Button variant={variant} onClick={() => setDialogOpen(true)}>
        {text}
      </Button>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogContent>
          <Stack gap="large">
            <Text size="xlarge" weight={"medium"}>
              {title}
            </Text>
            <Divider />
            <Stack>
              <Text>{description}</Text>
            </Stack>
            <Row justify={"space-between"} gap={"medium"}>
              <Button variant="secondary" onClick={handleClose} fullWidth>
                Cancel
              </Button>
              <Button
                variant={variant}
                onClick={onConfirm}
                disabled={isBusy}
                fullWidth
              >
                {confirmButtonText}
              </Button>
            </Row>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
