import { Cluster } from "@ses-mams/api-contract";
import { useArrayState } from "@ses-mams/react-utils";
import { useCallback, useEffect } from "react";
import { Button } from "~/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerProps,
} from "~/components/ui/drawer";
import { SelectClustersList } from "./SelectClustersList";

type SelectClustersDrawerProps = Omit<DrawerProps, "children"> & {
  clusters: Array<Cluster>;
  onDone: (clusters: Array<Cluster>) => void;
};

export const SelectClustersDrawer = ({
  open,
  onClose,
  onDone,
  clusters,
}: SelectClustersDrawerProps) => {
  const [selectedClusters, { append, remove, setValue }] =
    useArrayState(clusters);

  useEffect(() => {
    setValue(clusters);
  }, [clusters]);

  const handleOnClose = useCallback(() => {
    setValue([...clusters]);

    onClose();
  }, [clusters, onClose]);

  const handleDoneClick = useCallback(() => {
    onDone(selectedClusters);
    onClose();
  }, [onDone, selectedClusters]);

  return (
    <Drawer open={open} onClose={handleOnClose}>
      <DrawerHeader onClose={handleOnClose}>Select Clusters</DrawerHeader>

      <DrawerContent>
        <SelectClustersList
          clusters={selectedClusters}
          appendCluster={append}
          removeCluster={remove}
        />
      </DrawerContent>

      <DrawerFooter>
        <Button fullWidth onClick={handleDoneClick}>
          Done
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
