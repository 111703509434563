import { IconButton } from "@mui/material";
import { format } from "date-fns";
import { memo } from "react";

import {
  ChevronLeftOutlineIcon,
  ChevronRightOutlineIcon,
} from "~/components/ui/icon";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

type Props = {
  firstDayOfTheWeek: Date;
  lastDayOfTheWeek: Date;
  onGoToPreviousWeek: () => void;
  onGoToNextWeek: () => void;
};

const formatDay = (date: Date): string => format(date, "dd LLL");

export const AvailabilityWeekSelector = memo(
  ({
    firstDayOfTheWeek,
    lastDayOfTheWeek,
    onGoToPreviousWeek,
    onGoToNextWeek,
  }: Props) => (
    <Stack direction="row" gap="medium" justify="space-between" align="center">
      <IconButton aria-label="Go to previous week" onClick={onGoToPreviousWeek}>
        <ChevronLeftOutlineIcon />
      </IconButton>

      <Text size="large" weight="semi" noWrap>
        {`${formatDay(firstDayOfTheWeek)} - ${formatDay(lastDayOfTheWeek)}`}
      </Text>

      <IconButton aria-label="Go to next week" onClick={onGoToNextWeek}>
        <ChevronRightOutlineIcon />
      </IconButton>
    </Stack>
  )
);
