import { createFilledIcon } from "../createIcon";

export const GridFilledIcon = createFilledIcon(
  <>
    <path d="M2.40039 4.53324C2.40039 3.35503 3.35552 2.3999 4.53372 2.3999H8.80039C9.9786 2.3999 10.9337 3.35503 10.9337 4.53324V8.7999C10.9337 9.97811 9.9786 10.9332 8.80039 10.9332H4.53372C3.35552 10.9332 2.40039 9.97811 2.40039 8.7999V4.53324Z" />
    <path d="M2.40039 15.1999C2.40039 14.0217 3.35552 13.0666 4.53372 13.0666H8.80039C9.9786 13.0666 10.9337 14.0217 10.9337 15.1999V19.4666C10.9337 20.6448 9.9786 21.5999 8.80039 21.5999H4.53372C3.35552 21.5999 2.40039 20.6448 2.40039 19.4666V15.1999Z" />
    <path d="M13.0671 4.53324C13.0671 3.35503 14.0222 2.3999 15.2004 2.3999H19.4671C20.6453 2.3999 21.6004 3.35503 21.6004 4.53324V8.7999C21.6004 9.97811 20.6453 10.9332 19.4671 10.9332H15.2004C14.0222 10.9332 13.0671 9.97811 13.0671 8.7999V4.53324Z" />
    <path d="M13.0671 15.1999C13.0671 14.0217 14.0222 13.0666 15.2004 13.0666H19.4671C20.6453 13.0666 21.6004 14.0217 21.6004 15.1999V19.4666C21.6004 20.6448 20.6453 21.5999 19.4671 21.5999H15.2004C14.0222 21.5999 13.0671 20.6448 13.0671 19.4666V15.1999Z" />
  </>,
  "GridFilledIcon"
);
