import { Row, Stack } from "~/components/ui/stack";
import { Container } from "@mui/material";
import { Heading } from "~/components/ui/heading";
import { Button } from "~/components/ui/button";
import { PlusOutlineIcon } from "~/components/ui/icon";
import { BroadcastList } from "./components";

export const BroadcastPage = () => (
  <Container maxWidth="md">
    <Stack gap="xlarge">
      <Row justify="space-between" gap="small">
        <Heading level="1">Admin - Broadcast Messages</Heading>
      </Row>
      <Button
        type="button"
        variant="tertiary"
        startIcon={PlusOutlineIcon}
        href="add"
      >
        Create new broadcast
      </Button>
      <BroadcastList />
    </Stack>
  </Container>
);
