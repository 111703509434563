import { useCallback, useState } from "react";
import { Stack, StackProps } from "~/components/ui/stack";
import { ResponseButton } from "~/components/common/responseButton";
import { ApproveCommentsDrawer } from "./ApproveCommentsDrawer";
import { DeclineCommentsSheet } from "./DeclineCommentsDrawer";
import { useOutOfAreaActivationQuery } from "~/pages/requests/hooks";
import { useNavigate } from "react-router-dom";
import { captureException } from "@sentry/react";

type ApprovalActionButtonsProps = {
  activationId?: string;
  requestIds: Array<string>;
  sx?: StackProps["sx"];
  onSuccess?: () => void;
};

export const ApprovalActionButtons = ({
  activationId,
  requestIds,
  sx,
  onSuccess,
}: ApprovalActionButtonsProps) => {
  const navigate = useNavigate();
  const [isApproveDrawerOpen, setIsApproveDrawerOpen] = useState(false);
  const [isDeclineDrawerOpen, setIsDeclineDrawerOpen] = useState(false);

  const onApprovePress = useCallback(() => setIsApproveDrawerOpen(true), []);

  const onDeclinePress = useCallback(() => setIsDeclineDrawerOpen(true), []);

  const closeApproveDrawer = useCallback(
    () => setIsApproveDrawerOpen(false),
    []
  );
  const closeDeclineDrawer = useCallback(
    () => setIsDeclineDrawerOpen(false),
    []
  );

  const { refetch, isRefetching } = useOutOfAreaActivationQuery({
    activationId,
    enabled: false,
  });

  const handleApproveWithChanges = useCallback(async () => {
    if (requestIds.length === 1 && activationId) {
      try {
        const { data } = await refetch();

        const hasDeploymentRoles =
          data?.body && data.body.deployments.flatMap(d => d.roles)?.length > 0;

        // skip select role screen if request has no deployment roles
        if (!hasDeploymentRoles) {
          navigate(
            `/out-of-area-activation-approvals/availability?requestIds=${requestIds}&roleIds=""`
          );
          return;
        }
      } catch (err) {
        captureException(err);
      }
    }

    navigate(
      `/out-of-area-activation-approvals/roles?requestIds=${requestIds}&activationId=${activationId}`
    );
  }, [requestIds, activationId]);

  return (
    <>
      <Stack gap="medium" direction={{ xs: "column", md: "row" }} sx={sx}>
        <ResponseButton
          fullWidth
          background="positiveLow"
          onClick={onApprovePress}
          disabled={isRefetching}
        >
          Approve
        </ResponseButton>

        <ResponseButton
          fullWidth
          background="info"
          onClick={handleApproveWithChanges}
          busy={isRefetching}
        >
          Approve with changes
        </ResponseButton>

        <ResponseButton
          fullWidth
          background="critical"
          onClick={onDeclinePress}
          disabled={isRefetching}
        >
          Decline
        </ResponseButton>
      </Stack>

      <ApproveCommentsDrawer
        open={isApproveDrawerOpen}
        requestIds={requestIds}
        onClose={closeApproveDrawer}
        onSuccess={onSuccess}
      />

      <DeclineCommentsSheet
        open={isDeclineDrawerOpen}
        requestIds={requestIds}
        onClose={closeDeclineDrawer}
        onSuccess={onSuccess}
      />
    </>
  );
};
