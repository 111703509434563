import { useNavigate } from "react-router-dom";
import { Button } from "~/components/ui/button";
import { AlertCircleFilledIcon } from "~/components/ui/icon";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

import { useUnreadBroadcastMessages } from "~/context/broadcast";

export const UnreadMessagesBanner = () => {
  const navigate = useNavigate();
  const { unreadMessageCount } = useUnreadBroadcastMessages();

  if (!unreadMessageCount) {
    return null;
  }

  return (
    <Stack background="criticalMuted" direction="row">
      <Stack
        direction="row"
        align="center"
        justify="space-between"
        paddingX="large"
        paddingY="small"
      >
        <Stack gap="xsmall" direction="row" align="center">
          <AlertCircleFilledIcon tone="critical" />
          <Stack gap="medium" direction="row" align="center">
            <Text tone="critical" weight="semi">
              {`You have ${unreadMessageCount} unacknowledged messages`}
            </Text>

            <Button
              onClick={() => {
                navigate("/broadcast-messages");
              }}
              size="small"
            >
              View
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
