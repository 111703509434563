import {
  ContactGroupSchema,
  GroupOutputSchema,
  MemberSchema,
} from "@ses-mams/api-contract";
import { z } from "zod";

export const channelFormSchema = z
  .object({
    name: z
      .string({ required_error: "Name is required" })
      .min(1, "Name is required"),
    members: z.array(MemberSchema),
    groups: z.array(GroupOutputSchema),
    contactGroups: z.array(ContactGroupSchema),
  })
  .refine(
    data =>
      data.members.length || data.groups.length || data.contactGroups.length,
    {
      path: ["members"],
      message: "Select at least 1 member or group",
    }
  );

export type ChannelFormSchema = z.infer<typeof channelFormSchema>;
