export const convertActivityScheduleReminderToField = (
  reminderDuration?: number
) => {
  if (typeof reminderDuration === "undefined") {
    return undefined;
  }

  if (reminderDuration !== 0 && reminderDuration % 60 === 0) {
    return {
      value: reminderDuration / 60,
      unit: "Hour",
    } as const;
  }

  return {
    value: reminderDuration,
    unit: "Minute",
  } as const;
};
