import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  SelectAllMembersQuery,
  formatSelectAllMembersQuery,
} from "@ses-mams/react-utils";
import { EditGroupFormSchema } from "@ses-mams/validation";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";
import { captureException } from "@sentry/react";

type Params = {
  groupId?: string;
  unitId: string;
  selectAllMembersQuery?: SelectAllMembersQuery;
};

export const useEditGroupActions = ({
  groupId,
  unitId,
  selectAllMembersQuery,
}: Params) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const { mutateAsync: createGroup, isPending: isCreatingGroup } =
    tsr.groups.create.useMutation();
  const { mutateAsync: updateGroup, isPending: isUpdatingGroup } =
    tsr.groups.update.useMutation();
  const { mutateAsync: deleteGroup, isPending: isDeletingGroup } =
    tsr.groups.delete.useMutation();

  const nextRoute = `/units/${unitId}/groups`;

  const handleDoneClick = useCallback(
    async (data: EditGroupFormSchema) => {
      try {
        const body = {
          name: data.name,
          reportable: data.reportable,
          capabilityIds: data.capabilities.map(({ id }) => id),
          selectAllMembersQuery: formatSelectAllMembersQuery(
            selectAllMembersQuery
          ),
          availabilityMinThreshold: data.useAvailabilityRequirements
            ? (data.availabilityMinThreshold ?? 0)
            : undefined,
          availabilityLookAheadDays: data.useAvailabilityRequirements
            ? (data.availabilityLookAheadDays ?? 0)
            : undefined,
        };

        if (groupId) {
          await updateGroup({
            params: { id: groupId, unitId },
            body: {
              ...body,
              addMemberIds: data.addMembers.map(m => m.id),
              removeMemberIds: data.removeMembers.map(m => m.id),
            },
          });
        } else {
          await createGroup({
            params: {
              unitId,
            },
            body: {
              ...body,
              memberIds: data.addMembers.map(m => m.id),
            },
          });
        }

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ["groups", { unitId }],
          }),
          queryClient.invalidateQueries({
            queryKey: ["groups", groupId],
          }),
        ]);

        navigate(nextRoute);
      } catch (error) {
        captureException(error);
        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      }
    },
    [groupId, unitId, selectAllMembersQuery]
  );

  const handleDeleteClick = useCallback(async () => {
    try {
      if (groupId) {
        await deleteGroup({
          params: { id: groupId, unitId },
        });

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ["groups", { unitId }],
          }),
        ]);

        navigate(nextRoute);
      }
    } catch (error) {
      captureException(error);
      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [groupId]);

  return {
    isCreatingGroup,
    isUpdatingGroup,
    isDeletingGroup,
    handleDoneClick,
    handleDeleteClick,
  };
};
