import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { AvailabilityStatus } from "@ses-mams/api-contract";
import { useToast } from "~/components/ui/toast";
import { useShowConflictConfirmationDialog } from "~/hooks/useShowConflictConfirmationDialog";
import { tsr } from "~/utils/client";

type MutateActivationAvailabilityStatusInput = {
  requestId: string;
  status: AvailabilityStatus;
  conditionalReason?: string;
  force?: boolean;
};

export const useMutateActivationAvailabilityStatus = () => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  // Hijack control of loading state to avoid flashes of stale state inbetween request finishing and cache updating
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync } =
    tsr.activationRequests.updateAvailabilityStatus.useMutation();

  const { showConflictConfirmationDialog } = useShowConflictConfirmationDialog({
    type: "activation",
  });

  const mutateAvailabilityStatus = useCallback(
    async ({
      requestId,
      status,
      conditionalReason,
      force = false,
    }: MutateActivationAvailabilityStatusInput) => {
      try {
        setIsLoading(true);

        const updatedActivationRequest = await mutateAsync({
          params: {
            activationRequestId: requestId,
          },
          body: {
            availabilityStatus: status,
            conditionalReason,
            force,
          },
        });

        await Promise.all([
          queryClient.setQueryData(
            ["activation-requests", "details", requestId],
            updatedActivationRequest
          ),
          queryClient.invalidateQueries({
            queryKey: ["activation-requests", "list"],
          }),
          queryClient.invalidateQueries({
            queryKey: ["activation-requests", "details"],
          }),
          queryClient.invalidateQueries({
            queryKey: [
              "activations",
              updatedActivationRequest.body.activation.id,
              "members",
              "",
              [],
            ],
          }),
        ]);
      } catch (error) {
        const apiError = error as any;
        if (apiError?.status === 422) {
          showConflictConfirmationDialog({
            onConfirm: () => {
              mutateAvailabilityStatus({
                requestId,
                status,
                conditionalReason,
                force: true,
              });
            },
          });
          return;
        }

        if (apiError?.status === 400) {
          addToast({
            tone: "critical",
            title: "Sorry, something went wrong",
            message: apiError?.body?.message,
          });

          queryClient.invalidateQueries({
            queryKey: ["activation-requests", "list"],
          });
          queryClient.invalidateQueries({
            queryKey: ["activation-requests", "details"],
          });
          return;
        }

        addToast({
          tone: "critical",
          title: "Sorry, something went wrong",
          message: "Please try again",
        });
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return {
    isLoading,
    mutateAvailabilityStatus,
  };
};
