import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { Box } from "~/components/ui/box";
import { Spinner } from "~/components/ui/spinner";
import { tsr } from "~/utils/client";

import { useAuth } from "./AuthContextProvider";
import { DEFAULT_AUTH_ROUTE } from "./constants";

export const AuthCallback = () => {
  const { oktaAuth, login } = useAuth();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const loginMutation = tsr.auth.login.useMutation();

  useEffect(() => {
    (async () => {
      if (!code) {
        navigate("/");
        return;
      }

      try {
        const meta = await oktaAuth.idx.getTransactionMeta();

        const response = await loginMutation.mutateAsync({
          body: {
            code,
            codeVerifier: meta.codeVerifier as string,
            redirectUri: meta.redirectUri as string,
          },
        });

        if (response.status !== 200) {
          navigate("/");
          return;
        }

        await login(response.body, true);

        const redirectUri = oktaAuth.getOriginalUri();

        navigate(redirectUri || DEFAULT_AUTH_ROUTE);
      } catch {
        navigate("/");
      }
    })();
  }, [code]);

  return (
    <Box display="flex" align="center" justify="center">
      <Spinner />
    </Box>
  );
};
