import { useSelectedUnit } from "~/context/unit/SelectedUnitContextProvider";
import { AllUnitsSelector } from "~/components/common/allUnitsSelector";
import { useMemo, useEffect } from "react";
import { useAuth, useHasAnyRole } from "~/context/auth";
import { Spinner } from "~/components/ui/spinner";

export const GlobalUnitSelector = () => {
  const { selectedUnit, setSelectedUnit } = useSelectedUnit();

  const { member, isLoading } = useAuth();

  const units = member?.units ?? [];

  const hasCommandRole = useHasAnyRole([
    "OperationalCoordinator",
    "ActivityCoordinator",
    "Administrator",
    "SystemAdministrator",
  ]);

  const memberUnits = useMemo(() => {
    return units.map(({ unit }) => unit) ?? [];
  }, [units]);

  useEffect(() => {
    if (!selectedUnit && memberUnits.length) {
      setSelectedUnit(memberUnits[0]);
    }
  }, [selectedUnit, memberUnits]);

  if (isLoading || units.length <= 0) {
    return <Spinner size={24} />;
  }

  return (
    <AllUnitsSelector
      selectedUnitId={selectedUnit?.id}
      setSelectedUnit={setSelectedUnit}
      membersOnly={!hasCommandRole}
      showCategories={hasCommandRole}
    />
  );
};
