import { zodResolver } from "@hookform/resolvers/zod";
import { OutOfAreaActivationDeployment } from "@ses-mams/api-contract";
import {
  AvailabilityBlocksFormSchema,
  getAvailabilityBlocksFormSchema,
} from "@ses-mams/validation";
import { areIntervalsOverlapping, endOfDay, startOfDay } from "date-fns";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

export const mapDeploymentsToAvailabilityBlocks = (
  deployments: Array<OutOfAreaActivationDeployment>
) => {
  // Assumes deployments come sorted
  const mappedNonOverlappingBlocks: Array<{ start: Date; end: Date }> = [];

  for (let i = 0; i < deployments.length; i += 1) {
    const current = deployments[i] as OutOfAreaActivationDeployment;
    const previous = deployments[i - 1];

    const item = {
      start: startOfDay(new Date(current.start)),
      end: endOfDay(new Date(current.end)),
    };

    if (!previous) {
      mappedNonOverlappingBlocks.push(item);
      continue;
    }

    if (
      !areIntervalsOverlapping(item, {
        start: startOfDay(new Date(previous.start)),
        end: endOfDay(new Date(previous.end)),
      })
    ) {
      mappedNonOverlappingBlocks.push(item);
    }
  }

  return mappedNonOverlappingBlocks;
};

export const useAvailabilityBlocksForm = (
  deployments?: Array<OutOfAreaActivationDeployment>
) => {
  const schema = useMemo(() => {
    if (!deployments?.length) {
      return getAvailabilityBlocksFormSchema();
    }

    return getAvailabilityBlocksFormSchema(
      new Date(deployments[0]?.start as string),
      new Date(deployments[deployments.length - 1]?.end as string)
    );
  }, [deployments]);

  return useForm<AvailabilityBlocksFormSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      availabilityBlocks: deployments
        ? mapDeploymentsToAvailabilityBlocks(deployments)
        : [getNextBlock(new Date())],
    },
  });
};

export const getNextBlock = (date: Date) =>
  ({
    start: startOfDay(date),
    end: endOfDay(date),
  }) as AvailabilityBlocksFormSchema["availabilityBlocks"][0];
