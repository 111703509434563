import { Broadcast } from "@ses-mams/api-contract";
import { useMemo } from "react";

export const useBroadcastListSections = (
  pages?: Array<{
    status: number;
    body: { items: Array<Broadcast> };
  }>
) => {
  return useMemo(() => {
    const sectionMap: Record<BroadcastStatusHeading, Broadcast[]> = {
      // insertion order of these keys must match the endpoint's ORDER BY
      Draft: [],
      Scheduled: [],
      Current: [],
      Complete: [],
    };

    (pages || []).flatMap(page => {
      if (page.status !== 200) {
        return;
      }

      page.body.items.forEach(item => {
        sectionMap[item.status].push(item);
      });
    });

    return Object.keys(sectionMap).flatMap(key => {
      const typedKey = key as keyof typeof sectionMap;
      const keyedData = sectionMap[typedKey];

      if (!keyedData.length) {
        return [];
      }

      return [
        {
          title: typedKey,
          data: keyedData,
        },
      ];
    });
  }, [pages]);
};

type BroadcastStatusHeading = "Draft" | "Scheduled" | "Current" | "Complete";
