import { useCallback, useEffect, useState } from "react";
import { Container } from "@mui/material";

import { Box } from "~/components/ui/box";
import { Button, IconButton } from "~/components/ui/button";
import { XOutlineIcon } from "~/components/ui/icon";
import { popoverStyles } from "~/components/ui/popover";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";
import {
  getHasRecentlySkippedNotificationsPrompt,
  setHasRecentlySkippedNotificationsPrompt,
} from "./localNotificationsPromptUtils";

export const TurnOnNotificationsPrompt = () => {
  const [shouldDisplay, setShouldDisplay] = useState(false);

  useEffect(() => {
    // Check if browser support web notification
    if ("Notification" in window) {
      const hasRecentlySkippedNotificationsPrompt =
        getHasRecentlySkippedNotificationsPrompt();

      if (
        Notification.permission === "default" &&
        !hasRecentlySkippedNotificationsPrompt
      ) {
        setShouldDisplay(true);
      }
    }
  }, []);

  const handleAllow = useCallback(() => {
    Notification.requestPermission();
    setShouldDisplay(false);
  }, []);

  const handleDismiss = useCallback(() => {
    setHasRecentlySkippedNotificationsPrompt();
    setShouldDisplay(false);
  }, []);

  const handleClose = useCallback(() => {
    setShouldDisplay(false);
  }, []);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <Container
      maxWidth="xs"
      sx={theme => ({
        paddingLeft: {
          xs: theme.tokens.spacing.large,
          sm: 0,
        },
        paddingRight: {
          xs: theme.tokens.spacing.large,
          sm: 0,
        },
        position: "fixed",
        top: theme.tokens.spacing.large,
        left: "50%",
        transform: "translateX(-50%)",
      })}
    >
      <Box background="surface" sx={popoverStyles}>
        <Stack gap="large">
          <Stack gap="xxsmall">
            <Stack direction="row" justify="space-between" align="center">
              <Text weight="semi">Turn on browser notifications</Text>
              <IconButton size="small" aria-label="Close" onClick={handleClose}>
                <XOutlineIcon />
              </IconButton>
            </Stack>
            <Text>
              We will notify you of events such as operational requests,
              activities and availability updates.
            </Text>
          </Stack>
          <Stack direction="row-reverse" gap="small">
            <Button onClick={handleAllow}>Allow</Button>
            <Button variant="secondary" onClick={handleDismiss}>
              Dismiss
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
};
