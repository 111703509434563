import {
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
} from "@mui/material";

export type DrawerProps = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  sx?: MuiDrawerProps["sx"];
};

export const Drawer = ({ open, onClose, children, sx }: DrawerProps) => {
  return (
    <MuiDrawer anchor="right" open={open} onClose={onClose} sx={sx}>
      {children}
    </MuiDrawer>
  );
};
