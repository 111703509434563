import { ButtonBase } from "@mui/material";
import { useState } from "react";

import { MemberDetailsDrawer } from "~/components/common/memberDetails";
import { MemberListItem, MemberListItemProps } from "./MemberListItem";

export const MemberListItemDetailsButton = ({
  member,
  ...rest
}: MemberListItemProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <ButtonBase onClick={() => setIsDrawerOpen(true)} sx={{ width: "100%" }}>
        <MemberListItem member={member} {...rest} />
      </ButtonBase>
      <MemberDetailsDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        memberId={member.id}
      />
    </>
  );
};
