import { useNavigate } from "react-router-dom";
import { TrashOutlineIcon } from "~/components/ui/icon";
import { Button } from "~/components/ui/button";
import { useRemoveActivityMembers } from "../../hooks";

type Props = {
  activityId: string;
  memberId: string;
};

export const RemoveButton = ({ activityId, memberId }: Props) => {
  const navigate = useNavigate();

  const { handleConfirmRemoveMembers, isPending } = useRemoveActivityMembers({
    activityId,
    confirmationText:
      "Please confirm that you'd like to remove yourself from this activity.",
    onSuccess: () => navigate("/requests?tab=activity"),
  });

  return (
    <Button
      variant="destructive"
      busy={isPending}
      startIcon={TrashOutlineIcon}
      onClick={() => handleConfirmRemoveMembers([memberId])}
    >
      Remove
    </Button>
  );
};
