import { OutOfAreaActivationRequestMember } from "@ses-mams/api-contract";
import { OutOfAreaActivationMembersSectionKeys } from "@ses-mams/react-utils";
import { useCallback, useMemo, useState } from "react";

export const useOutOfAreaActivationSelectedState = () => {
  const [selectAllState, setSelectAllState] = useState<
    Record<OutOfAreaActivationMembersSectionKeys, boolean>
  >(INITIAL_SELECT_ALL_STATE);

  const [selected, setSelected] = useState<
    Array<OutOfAreaActivationRequestMember>
  >([]);

  const selectedGroups = useMemo(() => {
    const selectedRequestIds: Set<string> = new Set();
    const selectedActivationRequestIds: Array<string> = [];
    const selectedDeactivationRequestIds: Array<string> = [];
    const selectedRemovalMemberIds: Array<string> = [];
    const selectedUnsuccessfulMemberIds: Array<string> = [];

    selected.forEach(m => {
      const {
        id: requestId,
        status,
        unsuccessful,
        member: { id: memberId },
      } = m.request;

      selectedRequestIds.add(requestId);

      if (status === "ActivationPending") {
        selectedActivationRequestIds.push(requestId);
      }

      if (status === "Activated") {
        selectedDeactivationRequestIds.push(requestId);
      }

      if (status !== "Activated") {
        selectedRemovalMemberIds.push(memberId);
      }

      if (status === "ActivationPending" && !unsuccessful) {
        selectedUnsuccessfulMemberIds.push(memberId);
      }
    });

    return {
      selectedRequestIds,
      selectedActivationRequestIds,
      selectedDeactivationRequestIds,
      selectedRemovalMemberIds,
      selectedUnsuccessfulMemberIds,
    };
  }, [selected]);

  const clearSelected = useCallback(() => {
    setSelected([]);
    setSelectAllState(INITIAL_SELECT_ALL_STATE);
  }, []);

  return {
    ...selectedGroups,
    selectAllState,
    setSelectAllState,
    setSelected,
    clearSelected,
  };
};

const INITIAL_SELECT_ALL_STATE = {
  Activated: false,
  "Supported-Recommended for Activation": false,
  "Pending Zone Approval": false,
  "Pending Cluster Approval": false,
  "Pending Unit Approval": false,
  "Awaiting Response": false,
  Rejected: false,
  Deactivated: false,
} satisfies Record<OutOfAreaActivationMembersSectionKeys, boolean>;
