import { cloneElement, isValidElement } from "react";
import { usePopoverContext } from "./PopoverRoot";

type PopoverTriggerProps = {
  children: React.ReactNode;
};

export const PopoverTrigger = ({ children }: PopoverTriggerProps) => {
  const { triggerRef, open, onOpenToggle } = usePopoverContext();

  if (isValidElement(children)) {
    return cloneElement(children, {
      ...children.props,
      ref: triggerRef,
      "aria-haspopup": "dialog",
      "aria-expanded": open,
      onClick: (e: unknown) => {
        onOpenToggle();
        children.props.onClick?.(e);
      },
    });
  }

  return null;
};
