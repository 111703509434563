import { Unit } from "@ses-mams/api-contract";
import { useArrayState } from "@ses-mams/react-utils";
import { useCallback, useEffect } from "react";
import { Button } from "~/components/ui/button";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerProps,
} from "~/components/ui/drawer";
import { SelectUnitsList } from "./SelectUnitsList";

type SelectUnitsDrawerProps = Omit<DrawerProps, "children"> & {
  units: Array<Unit>;
  onDone: (units: Array<Unit>) => void;
};

export const SelectUnitsDrawer = ({
  open,
  onClose,
  onDone,
  units,
}: SelectUnitsDrawerProps) => {
  const [selectedUnits, { append, remove, setValue }] = useArrayState(units);

  useEffect(() => {
    setValue(units);
  }, [units]);

  const handleOnClose = useCallback(() => {
    setValue([...units]);

    onClose();
  }, [units, onClose]);

  const handleDoneClick = useCallback(() => {
    onDone(selectedUnits);
    onClose();
  }, [onDone, selectedUnits]);

  return (
    <Drawer open={open} onClose={handleOnClose}>
      <DrawerHeader onClose={handleOnClose}>Select Units</DrawerHeader>

      <DrawerContent>
        <SelectUnitsList
          units={selectedUnits}
          appendUnit={append}
          removeUnit={remove}
        />
      </DrawerContent>

      <DrawerFooter>
        <Button fullWidth onClick={handleDoneClick}>
          Done
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
