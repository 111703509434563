import { OutOfAreaActivationRequestMemberSummary } from "@ses-mams/api-contract";
import emptyListImageUrl from "~/assets/emptyList.png";
import { useCallback } from "react";
import {
  InfiniteSectionList,
  RenderSectionHeaderParams,
} from "~/components/ui/list";
import { Box } from "~/components/ui/box";
import { Text } from "~/components/ui/text";
import { EmptyState } from "~/components/common/emptyState";
import { useOutOfAreaActivationMembersSummariesData } from "./useOutOfAreaActivationMembersSummariesData";
import { Stack } from "~/components/ui/stack";
import { formatMemberFullName } from "@ses-mams/react-utils";

type OutOfAreaActivationMemberListProps = Pick<
  ReturnType<typeof useOutOfAreaActivationMembersSummariesData>,
  "sections" | "isLoading" | "fetchNextPage" | "hasNextPage"
>;

export const OutOfAreaActivationMemberSummaryList = ({
  isLoading,
  sections,
  fetchNextPage,
  hasNextPage,
}: OutOfAreaActivationMemberListProps) => {
  const handleRenderItem = useCallback(
    (item: OutOfAreaActivationRequestMemberSummary) => {
      return (
        <Stack
          flex={1}
          direction="row"
          justify="space-between"
          gap="small"
          paddingY="large"
        >
          <Text size="large" weight="medium">
            {formatMemberFullName(item.request.member)}
          </Text>
        </Stack>
      );
    },
    []
  );

  const renderSectionHeader = useCallback(
    (
      params: RenderSectionHeaderParams<OutOfAreaActivationRequestMemberSummary>
    ) => (
      <Text size="standard" weight="medium" tone="muted">
        {params.title}
      </Text>
    ),
    []
  );

  return (
    <InfiniteSectionList
      sections={sections}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      renderItem={handleRenderItem}
      renderSectionHeader={renderSectionHeader}
      keyExtractor={item => item.request.id}
      listEmptyElement={
        isLoading ? null : (
          <Box display="flex" justify="center" sx={{ height: "100%" }}>
            <EmptyState
              image={<img src={emptyListImageUrl} />}
              title="No members"
              description="There are no members."
            />
          </Box>
        )
      }
    />
  );
};
