import {
  ActivityScheduleFormSchema,
  SCHEDULE_REMINDER_TIME_UNIT_OPTIONS,
} from "@ses-mams/validation";
import { useEffect, useState } from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  useFormState,
  useWatch,
} from "react-hook-form";
import { Box } from "~/components/ui/box";
import { IntegerField } from "~/components/ui/integerField";
import { SelectField, SelectItem } from "~/components/ui/selectField";
import { Stack } from "~/components/ui/stack";
import { Switch } from "~/components/ui/switch";
import { Text } from "~/components/ui/text";
import { HelperText } from "~/components/ui/textField";

type ScheduleReminderFields = {
  schedule: Pick<
    ActivityScheduleFormSchema["schedule"],
    "reminderDurationBeforeStart"
  >;
};

type RemindMembersFieldProps<T extends ScheduleReminderFields> = {
  isEdit?: boolean;
  control: Control<T>;
  setValue: UseFormSetValue<T>;
};

export const RemindMembersField = <T extends ScheduleReminderFields>({
  isEdit,
  setValue: _setValue,
  control: _control,
}: RemindMembersFieldProps<T>) => {
  const control = _control as unknown as Control<ScheduleReminderFields>;
  const setValue =
    _setValue as unknown as UseFormSetValue<ScheduleReminderFields>;

  const [isReminderEnabled, setIsReminderEnabled] = useState(false);

  const formState = useFormState({ control });

  const watchReminderDurationValue = useWatch({
    control,
    name: "schedule.reminderDurationBeforeStart.value",
  });

  useEffect(() => {
    // This will enable the field during the edit scenario if the value exists
    if (
      isEdit &&
      typeof watchReminderDurationValue === "number" &&
      !isReminderEnabled
    ) {
      setIsReminderEnabled(true);
    }
  }, [isEdit, watchReminderDurationValue, isReminderEnabled]);

  const reminderFieldsError =
    formState.errors?.schedule?.reminderDurationBeforeStart?.unit?.message ||
    formState.errors?.schedule?.reminderDurationBeforeStart?.value?.message;

  return (
    <Stack gap="medium" flex={1}>
      <Text weight="medium">Remind members</Text>
      <Stack>
        <Stack
          direction="row"
          gap="medium"
          justify="space-between"
          align="center"
          dividers
        >
          <Stack direction="row" gap="medium" align="center">
            <Stack direction="row" gap="medium" align="start">
              <Controller
                shouldUnregister={!isEdit}
                name="schedule.reminderDurationBeforeStart.value"
                control={control}
                render={({ field }) => (
                  <IntegerField
                    hideLabel
                    label="Reminder Duration Time"
                    value={field.value ?? undefined}
                    onValueChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={!isReminderEnabled}
                  />
                )}
              />
              <Controller
                shouldUnregister={!isEdit}
                name="schedule.reminderDurationBeforeStart.unit"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Box sx={{ minWidth: 100 }}>
                    <SelectField
                      label="Unit Escalation Threshold Unit"
                      hideLabel={true}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value ?? ""}
                      disabled={!isReminderEnabled}
                    >
                      {SCHEDULE_REMINDER_TIME_UNIT_OPTIONS.map(u => (
                        <SelectItem key={u} value={u} onChange={onChange}>
                          {u}
                        </SelectItem>
                      ))}
                    </SelectField>
                  </Box>
                )}
              />
            </Stack>
            <Text align="center">before</Text>
          </Stack>
          <Switch
            checked={isReminderEnabled}
            onChange={(_, value) => {
              setIsReminderEnabled(value);

              if (value === false) {
                setValue(
                  "schedule.reminderDurationBeforeStart",
                  {
                    value: null,
                    unit: null,
                  },
                  { shouldValidate: true }
                );
                return;
              }

              setValue("schedule.reminderDurationBeforeStart.value", 30);
              setValue("schedule.reminderDurationBeforeStart.unit", "Minute");
            }}
          />
        </Stack>
        {!!reminderFieldsError && (
          <HelperText role="alert">{reminderFieldsError as string}</HelperText>
        )}
      </Stack>
    </Stack>
  );
};
