import { Button } from "~/components/ui/button";
import { Stack } from "~/components/ui/stack";
import { Box } from "~/components/ui/box";
import { useOwnAvailabilityData } from "../../hooks/useOwnAvailabilityData";
import { AvailabilityWeekSelector } from "~/components/common/availabilitySelector";
import { Heading } from "~/components/ui/heading";
import { GridCellHeightContextProvider } from "../../context/gridCellHeightContext";
import { useAvailabilitySelectedWeek } from "@ses-mams/react-utils";

import { useEditAvailability } from "../../hooks/useEditAvailability";
import { EditAvailabilityHourlyGrid } from "./EditAvailabilityHourlyGrid";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { AvailabilityStatus } from "@ses-mams/api-contract";
import { SetAvailabilityDrawer } from "../common/SetAvailabilityDrawer";
import { useToast } from "~/components/ui/toast";
import { Spinner } from "~/components/ui/spinner";
import { captureException } from "@sentry/react";
import { useAvailabilityUnit } from "~/context/unit/AvailabilityUnitContextProvider";

export const EditHourlyAvailabiltyPage = () => {
  const {
    firstDayOfTheWeek,
    lastDayOfTheWeek,
    goToNextWeek,
    goToPreviousWeek,
  } = useAvailabilitySelectedWeek();
  const [showAvailabilityDrawer, setShowAvailabiliityDrawer] = useState(false);

  const [selectedValue, setSelectedValue] = useState<{
    status: AvailabilityStatus | null;
    emergenciesOnly?: boolean;
    conditionalReason?: string;
  }>({ status: null });

  const {
    availabilityToUpdate,
    handleEditAvailability,
    hasSelectedCells,
    handleCellSelectionStatusChanged,
  } = useEditAvailability();

  const { selectedUnit } = useAvailabilityUnit();

  const { hourlyAvailability, mutate, isLoading, isMutating } =
    useOwnAvailabilityData({
      startOfTheWeek: firstDayOfTheWeek,
      endOfTheWeek: lastDayOfTheWeek,
      unitId: selectedUnit?.id,
    });

  const navigate = useNavigate();

  const { addToast } = useToast();

  const handleOnDone = useCallback(async () => {
    if (hasSelectedCells) {
      setShowAvailabiliityDrawer(true);
      return;
    }

    try {
      await mutate(availabilityToUpdate.current);
      navigate(-1);
    } catch (error) {
      captureException(error);
      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [hasSelectedCells, mutate]);

  return (
    <>
      <Stack direction="column" gap="medium" sx={{ height: "100%" }}>
        <Stack direction="row" justify="space-between" align="center">
          <Heading level="1">Availability</Heading>
          <Button onClick={handleOnDone} disabled={isMutating}>
            {hasSelectedCells ? "Next" : "Done"}
          </Button>
        </Stack>

        <Box>
          <Stack
            direction={{
              xs: "column-reverse",
              md: "row",
            }}
            gap="medium"
            justify="space-between"
            align="center"
            padding="medium"
            sx={{
              width: "100%",
            }}
          >
            <Stack
              gap="medium"
              justify="space-between"
              grow={1}
              direction={{
                xs: "column",
                md: "row",
              }}
              sx={{ width: "100%" }}
            >
              <AvailabilityWeekSelector
                lastDayOfTheWeek={lastDayOfTheWeek}
                firstDayOfTheWeek={firstDayOfTheWeek}
                onGoToNextWeek={goToNextWeek}
                onGoToPreviousWeek={goToPreviousWeek}
              />
            </Stack>
          </Stack>

          <GridCellHeightContextProvider numberOfScheduleItems={0}>
            {isLoading ? (
              <Box
                display="flex"
                justify="center"
                align="center"
                sx={{ height: "100vh", width: "100%" }}
              >
                <Spinner />
              </Box>
            ) : (
              <EditAvailabilityHourlyGrid
                availability={hourlyAvailability}
                onAvailabilityEdited={handleEditAvailability}
                firstDayOfTheWeek={firstDayOfTheWeek}
                lastDayOfTheWeek={lastDayOfTheWeek}
                selectedValue={selectedValue}
                onCellSelectionStatusChanged={handleCellSelectionStatusChanged}
              />
            )}
          </GridCellHeightContextProvider>
        </Box>
      </Stack>
      <SetAvailabilityDrawer
        open={showAvailabilityDrawer}
        onClose={() => {
          setShowAvailabiliityDrawer(false);
        }}
        onDone={value => {
          setSelectedValue(value);
        }}
      />
    </>
  );
};
