import { useAuth } from "~/context/auth";
import { useSelectedUnit } from "~/context/unit/SelectedUnitContextProvider";

export const useDefaultUserUnitAndAddress = () => {
  const userUnit = useAuth()?.member?.units?.[0]?.unit;
  const { selectedUnit } = useSelectedUnit();

  if (userUnit?.id && userUnit?.name) {
    return {
      unit: selectedUnit || {
        name: userUnit.name,
        id: userUnit.id,
      },
      location: userUnit?.address || undefined,
      latitude: userUnit?.latitude || undefined,
      longitude: userUnit?.longitude || undefined,
    };
  }

  return {};
};
