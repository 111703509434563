import { endOfDay, startOfDay } from "date-fns";
import { useMemo } from "react";

import {
  formatDateForServer,
  useActivationRequestListSections,
  useDebouncedSearch,
  useRequestListFiltersContext,
  useTransformPagedDataToItems,
} from "@ses-mams/react-utils";

import { tsr } from "~/utils/client";
import { DEFAULT_PAGE_SIZE, getNextPageParam } from "~/utils/pagination";

export const useActivationRequestsData = ({ unitId }: { unitId?: string }) => {
  const { filters, unitFilters } = useRequestListFiltersContext();

  const filtersInUse = unitId ? unitFilters : filters;

  const {
    urgentRequests,
    nonUrgentRequests,
    activationTimeRange: selectedTimeRange,
    archivedOnly: archived,
  } = filtersInUse;

  const { searchValue, setSearchValue, debouncedSearchValue } =
    useDebouncedSearch("");

  const selectedTypes = useMemo(
    () => [
      ...(urgentRequests ? ["Urgent" as const] : []),
      ...(nonUrgentRequests ? ["NotUrgent" as const] : []),
    ],
    [urgentRequests, nonUrgentRequests]
  );

  const {
    isLoading,
    isRefetching,
    isFetchingNextPage,
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
  } = tsr.activationRequests.list.useInfiniteQuery({
    queryKey: [
      "activation-requests",
      "list",
      selectedTypes,
      selectedTimeRange,
      archived,
      debouncedSearchValue,
      unitId,
    ],
    queryData: ({ pageParam = { skip: 0, take: DEFAULT_PAGE_SIZE } }) => ({
      query: {
        skip: pageParam.skip,
        take: pageParam.take,
        types: selectedTypes,
        startDate: selectedTimeRange.start
          ? formatDateForServer(startOfDay(selectedTimeRange.start))
          : undefined,
        endDate: selectedTimeRange.end
          ? formatDateForServer(endOfDay(selectedTimeRange.end))
          : undefined,
        archived,
        query: debouncedSearchValue,
        unitId,
      },
    }),
    initialPageParam: { skip: 0, take: DEFAULT_PAGE_SIZE },
    getNextPageParam,
  });

  const sections = useActivationRequestListSections(data?.pages, archived);

  const items = useTransformPagedDataToItems(data);

  return {
    sections,
    data: items,
    isLoading,
    isRefetching,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
    hasNextPage,
    filters: {
      searchValue,
      setSearchValue,
      archived,
    },
  };
};
