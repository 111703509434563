import { Container } from "@mui/material";
import { BackButton } from "~/components/common/navigation";
import { Stack } from "~/components/ui/stack";
import { AddOutOfAreaActivationForm } from "./components";

export const AddOOAActivationPage = () => {
  return (
    <Container maxWidth="sm">
      <Stack gap="xlarge">
        <BackButton />
        <AddOutOfAreaActivationForm />
      </Stack>
    </Container>
  );
};
