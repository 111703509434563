import { Stack } from "~/components/ui/stack";
import { AvailabilityStatus, ScheduleItem } from "@ses-mams/api-contract";
import { memo } from "react";
import { ScheduleGrid } from "./ScheduleGrid";
import { ScheduleGuide } from "./ScheduleGuide";
import { useCellSelectionStatusChangedEvent } from "../../hooks/useCellSelectionStatusChangedEvent";
import { Spinner } from "~/components/ui/spinner";
import {
  useEditScheduleGrid,
  HourlyAvailability,
  ScheduleAvailability,
} from "@ses-mams/react-utils";

type EditScheduleGridProps = {
  availability: ScheduleAvailability;
  schedule: ScheduleItem[];
  onAvailabilityEdited: (editedAvailability: HourlyAvailability) => void;
  firstDayOfTheWeek: Date;
  lastDayOfTheWeek: Date;
  selectedValue?: {
    status: AvailabilityStatus | null;
    emergenciesOnly?: boolean;
    conditionalReason?: string;
  };
  onCellSelectionStatusChanged: (hasSelectedCells: boolean) => void;
};

export const EditScheduleGrid = memo(
  ({
    onCellSelectionStatusChanged,
    schedule,
    selectedValue,
    ...props
  }: EditScheduleGridProps) => {
    const {
      selectedCells,
      handleCellPress,
      handleDatePress,
      handleScheduleItemPress,
      availabilityGridData,
    } = useEditScheduleGrid({
      schedule,
      selectedValue: selectedValue || null,
      ...props,
    });

    useCellSelectionStatusChangedEvent(
      selectedCells.length,
      onCellSelectionStatusChanged
    );

    if (!availabilityGridData.current?.length) {
      return <Spinner />;
    }

    return (
      <Stack direction="row" overflowX="auto" sx={{ height: "100%" }}>
        <ScheduleGuide schedule={schedule} onClick={handleScheduleItemPress} />
        <ScheduleGrid
          availability={availabilityGridData.current}
          onCellClick={handleCellPress}
          onDateClick={handleDatePress}
          selectedCells={selectedCells}
        />
      </Stack>
    );
  }
);
