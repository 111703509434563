import {
  MessageContextValue,
  MessageSimple,
  useComponentContext,
  useMessageContext,
} from "stream-chat-react";
import { Stack } from "~/components/ui/stack";
import { ImportantMessageIndicator } from "./ImportantMessageIndicator";

export const Message = (props: Partial<MessageContextValue>) => {
  const pinned = useMessageContext()?.message?.pinned;
  const { PinIndicator = ImportantMessageIndicator } = useComponentContext();

  return (
    <Stack
      gap="xsmall"
      background={pinned ? "criticalMuted" : undefined}
      borderRadius="small"
      padding={pinned ? "small" : undefined}
    >
      {pinned && <PinIndicator />}

      <MessageSimple {...props} />
    </Stack>
  );
};
