import { useMemo } from "react";

export type PaginatedBody<T> = {
  /*TODO REMOVE */ totalCount?: number;
  items: T[];
};

export type PaginatedPage<TBody> = { status: number; body: TBody };

export const getNextPageParam = <
  T,
  TBody extends PaginatedBody<T>,
  TPage extends PaginatedPage<TBody>,
>(
  lastPage: TPage,
  allPages: TPage[],
  pageSize: number
) => {
  if (lastPage.status === 200 && lastPage.body.items.length === pageSize) {
    return { take: pageSize, skip: allPages.length * pageSize };
  }
  return undefined;
};

export const getTotalCountFromPages = <
  T,
  TBody extends PaginatedBody<T>,
  TPage extends PaginatedPage<TBody>,
>(
  pages: TPage[] = []
) => pages[(pages?.length ?? 0) - 1]?.body?.totalCount ?? 0;

// This comes from @tanstack/query-core/src/types.ts. Just didn't want to install a new dependency only for this type.
type InfiniteData<T> = {
  pages: Array<T>;
  pageParams: Array<unknown>;
};

type TPage<T> = {
  status: number;
  body: {
    items: Array<T>;
  };
};

export const useTransformPagedDataToItems = <T>(
  data?: InfiniteData<TPage<T>>
) =>
  useMemo(
    () =>
      (data?.pages || []).flatMap(page =>
        page.status === 200 ? page.body.items : []
      ),
    [data?.pages]
  );
