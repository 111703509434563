import {
  Capability,
  Cluster,
  Group,
  Member,
  OutOfAreaActivation,
  OutOfAreaActivationLevel,
  OutOfAreaActivationRequestStatus,
  OutOfAreaActivationRole,
  OutOfAreaActivationRoleCategory,
  OutOfAreaEscalationTimeUnit,
  Unit,
  Zone,
} from "@ses-mams/api-contract";
import { BackgroundTones } from "../ui";
import { useCallback } from "react";
import { formatDateForServer, formatTwentyFourHourTime } from "./format";
import {
  convertThresholdToMinutes,
  MINUTES_IN_A_DAY,
  MINUTES_IN_A_WEEK,
  MINUTES_IN_AN_HOUR,
  OutOfAreaActivationFormSchema,
} from "@ses-mams/validation";
import {
  SelectAllMembersQuery,
  formatSelectAllMembersQuery,
} from "../utils/selectAllMembers";

export const getOutOfAreaActivationRequestStatusBadgeTone = (
  status: OutOfAreaActivationRequestStatus
): Extract<
  BackgroundTones,
  | "positive"
  | "positiveLow"
  | "positiveMuted"
  | "caution"
  | "cautionMuted"
  | "critical"
  | "criticalMuted"
  | "info"
  | "infoMuted"
  | "muted"
> => {
  switch (status) {
    case "Activated":
      return "positive";
    case "ActivationPending":
      return "infoMuted";
    case "ClusterApprovalDeclined":
    case "UnitApprovalDeclined":
    case "ZoneApprovalDeclined":
      return "critical";
    default:
      return "muted";
  }
};

export const transformOoaaActivationToForm = (
  activation: OutOfAreaActivation
) => ({
  ...activation,
  type: activation.type,
  activationLevel: activation.level,
  clusterId: activation.cluster?.id,
  unitId: activation.unit?.id,
  zoneId: activation.zone?.id,
  response: {
    date: new Date(activation.replyByDate),
    time: formatTwentyFourHourTime(new Date(activation.replyByDate)),
  },
  unitEscalationThreshold: activation.unitEscalationThreshold
    ? convertMinutesToThreshold(activation.unitEscalationThreshold)
    : undefined,
  clusterEscalationThreshold: activation.clusterEscalationThreshold
    ? convertMinutesToThreshold(activation.clusterEscalationThreshold)
    : undefined,
  deployments: activation.deployments.map(deployment => ({
    location: deployment.location,
    address: deployment.address,
    latitude: deployment.latitude,
    longitude: deployment.longitude,
    description: deployment.description,
    start: {
      date: new Date(deployment.start),
      time: formatTwentyFourHourTime(new Date(deployment.start)),
    },
    end: {
      date: new Date(deployment.end),
      time: formatTwentyFourHourTime(new Date(deployment.end)),
    },
    roles: deployment?.roles ?? [],
  })),
});

export const convertMinutesToThreshold = (thresholdInMinute?: number) => {
  if (typeof thresholdInMinute === "undefined") {
    return {
      time: undefined,
      unit: undefined,
    };
  }

  if (thresholdInMinute !== 0 && thresholdInMinute % MINUTES_IN_A_WEEK === 0) {
    return {
      time: thresholdInMinute / MINUTES_IN_A_WEEK,
      unit: "Week" as OutOfAreaEscalationTimeUnit,
    };
  }

  if (thresholdInMinute !== 0 && thresholdInMinute % MINUTES_IN_A_DAY === 0) {
    return {
      time: thresholdInMinute / MINUTES_IN_A_DAY,
      unit: "Day" as OutOfAreaEscalationTimeUnit,
    };
  }

  if (thresholdInMinute !== 0 && thresholdInMinute % MINUTES_IN_AN_HOUR === 0) {
    return {
      time: thresholdInMinute / MINUTES_IN_AN_HOUR,
      unit: "Hour" as OutOfAreaEscalationTimeUnit,
    };
  }

  return {
    time: thresholdInMinute,
    unit: "Minute" as OutOfAreaEscalationTimeUnit,
  };
};

type RolesByCategory = {
  [key in OutOfAreaActivationRoleCategory]: Array<OutOfAreaActivationRole>;
};

export type CreateOoaaActivationParams = {
  formData: OutOfAreaActivationFormSchema;
  zones?: Array<Zone>;
  clusters?: Array<Cluster>;
  groups?: Array<Group>;
  units?: Array<Unit>;
  capabilities?: Array<Capability>;
  members?: Array<Member>;
  selectAllMembersQuery?: SelectAllMembersQuery;
};

export const transformOoaaActivationFormForCreateOrUpdate = ({
  formData,
  zones,
  clusters,
  groups,
  units,
  capabilities,
  members,
  selectAllMembersQuery,
}: CreateOoaaActivationParams) => {
  return {
    ...transformOoaaActivationFormData(formData),
    type: formData.type,
    unitId: formData.unit?.id,
    clusterId: formData.cluster?.id,
    zoneId: formData.zone?.id,
    level: formData.level as OutOfAreaActivationLevel,
    unitEscalationThreshold: convertThresholdToMinutes({
      threshold: formData.unitEscalationThreshold?.time,
      unit: formData.unitEscalationThreshold?.unit,
    }),
    clusterEscalationThreshold: convertThresholdToMinutes({
      threshold: formData.clusterEscalationThreshold?.time,
      unit: formData.clusterEscalationThreshold?.unit,
    }),
    recipientZoneIds: zones?.map(m => m.id),
    recipientClusterIds: clusters?.map(m => m.id),
    recipientGroupIds: groups?.map(m => m.id),
    recipientUnitIds: units?.map(m => m.id),
    recipientCapabilityIds: capabilities?.map(m => m.id),
    recipientMemberIds: members?.map(m => m.id),
    selectAllMembersQuery: formatSelectAllMembersQuery(selectAllMembersQuery),
  };
};

const transformOoaaActivationFormData = (
  formData: OutOfAreaActivationFormSchema
) => {
  return {
    title: formData.title,
    description: formData.description,
    responseDate: formatDateForServer(formData.response.date),
    deployments: formData.deployments.map(deployment => {
      return {
        location: deployment.location,
        address: deployment.address,
        latitude: deployment.latitude,
        longitude: deployment.longitude,
        description: deployment.description,
        start: formatDateForServer(deployment.start.date),
        end: formatDateForServer(deployment.end.date),
        roleIds: deployment?.roles?.map(r => r.id),
      };
    }),
  };
};

export const useGetGroupedOutOfAreaActivationRoles = () => {
  return useCallback((input?: Array<OutOfAreaActivationRole>) => {
    if (!input?.length) {
      return undefined;
    }

    return input.reduce(
      (acc: RolesByCategory, item: OutOfAreaActivationRole) => {
        const categoryName = item.category as OutOfAreaActivationRoleCategory;

        if (!acc[categoryName]) {
          acc[categoryName] = [];
        }

        acc[categoryName].push(item);
        return acc;
      },
      {} as RolesByCategory
    );
  }, []);
};
