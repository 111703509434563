import { CircularProgress } from "@mui/material";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { Box } from "~/components/ui/box";

import { useAuth } from "./AuthContextProvider";

export const AuthRequiredGuard = () => {
  const { isLoading, member, oktaAuth } = useAuth();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!member) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [isLoading, member, oktaAuth]);

  if (isLoading || !member) {
    return (
      <Box display="flex" align="center" justify="center">
        <CircularProgress />
      </Box>
    );
  }

  return <Outlet />;
};
