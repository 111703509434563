import { Divider } from "~/components/ui/divider";
import { Stack } from "~/components/ui/stack";

type DrawerFooterProps = {
  children: React.ReactNode;
};

export const DrawerFooter = ({ children }: DrawerFooterProps) => {
  return (
    <Stack gap="small" paddingX="large" paddingBottom="small">
      <Divider direction="column" />

      {children}
    </Stack>
  );
};
