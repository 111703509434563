import { Availability } from "@ses-mams/api-contract";
import {
  formatAvailabilityStatusLabel,
  toAvailabilityReportStatus,
  AVAILABILITY_REPORT_STATUS_TO_BADGE_TONE_MAP,
} from "@ses-mams/react-utils";

import { Badge } from "~/components/ui/badge";

export type MemberAvailabilityStatusBadgeProps = {
  availability?: Availability | null;
};

export const MemberAvailabilityStatusBadge = ({
  availability,
}: MemberAvailabilityStatusBadgeProps) => {
  const badgeTone =
    AVAILABILITY_REPORT_STATUS_TO_BADGE_TONE_MAP[
      toAvailabilityReportStatus(availability)
    ];
  return (
    <Badge tone={badgeTone} size="small">
      {formatAvailabilityStatusLabel({
        status: availability?.status ?? undefined,
        emergenciesOnly: availability?.emergenciesOnly ?? undefined,
        committed: availability?.committed ?? false,
        useShort: true,
      })}
    </Badge>
  );
};
