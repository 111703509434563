import { captureException } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "~/components/ui/button";
import { useConfirmationDialog } from "~/components/ui/confirmationDialog";
import { TrashOutlineIcon } from "~/components/ui/icon";
import { useToast } from "~/components/ui/toast";
import { tsr } from "~/utils/client";

type CloseActivationButtonProps = {
  activationId: string;
  isFutureActivation: boolean;
};

export const CloseActivationButton = ({
  activationId,
  isFutureActivation,
}: CloseActivationButtonProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending: isClosingActivation } =
    tsr.activations.close.useMutation();

  const { showConfirmationDialog } = useConfirmationDialog();
  const { addToast } = useToast();

  const handleCloseActivation = useCallback(async () => {
    try {
      await mutateAsync({
        params: {
          id: activationId,
        },
      });

      await queryClient.invalidateQueries({
        queryKey: ["activation-requests", "list"],
      });

      navigate("/requests?tab=operational");
    } catch (error) {
      captureException(error);

      addToast({
        tone: "critical",
        title: "Sorry, something went wrong",
        message: "Please try again",
      });
    }
  }, [activationId]);

  return (
    <Button
      variant="destructive"
      busy={isClosingActivation}
      startIcon={TrashOutlineIcon}
      onClick={() =>
        showConfirmationDialog({
          text: `You will close and archive this request and all members will be ${
            isFutureActivation ? "uncommitted" : "deactivated"
          }.`,
          onConfirm: handleCloseActivation,
        })
      }
    >
      Close Request
    </Button>
  );
};
