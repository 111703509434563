import { ButtonBase } from "@mui/material";
import { OutOfAreaActivationRole } from "@ses-mams/api-contract";
import { formatOutOfAreaActivationRoleCategory } from "@ses-mams/react-utils";
import { Checkbox } from "~/components/ui/checkbox";
import { Stack } from "~/components/ui/stack";
import { Text } from "~/components/ui/text";

type SelectOutOfAreaRoleIdsListProps = {
  data: Array<OutOfAreaActivationRole>;
  selectedRoleIds: Array<string>;
  removeRoleId: (roleId: string) => void;
  appendRoleId: (roleId: string) => void;
};

export const SelectOutOfAreaRoleIdsList = ({
  data,
  selectedRoleIds,
  removeRoleId,
  appendRoleId,
}: SelectOutOfAreaRoleIdsListProps) => {
  return (
    <Stack>
      {data.map(role => {
        const isChecked = selectedRoleIds.includes(role.id);

        return (
          <ButtonBase
            key={role.id}
            role="checkbox"
            aria-label={role.name}
            aria-checked={isChecked}
            onClick={() =>
              isChecked ? removeRoleId(role.id) : appendRoleId(role.id)
            }
          >
            <Stack
              direction="row"
              paddingY="medium"
              justify="space-between"
              align="center"
              gap="medium"
              sx={{ width: "100%" }}
            >
              <Stack flex={1} gap="medium" direction="row" align="center">
                <Checkbox checked={isChecked} />

                <Text align="start" size="large" weight="medium">
                  {role.name}
                </Text>
              </Stack>

              <Text tone="secondary" weight="medium">
                {formatOutOfAreaActivationRoleCategory(role.category)}
              </Text>
            </Stack>
          </ButtonBase>
        );
      })}
    </Stack>
  );
};
