import { initContract } from "@ts-rest/core";
import { z } from "zod";
import {
  ApiErrorSchema,
  MemberSummarySchema,
  PaginationQueryParamsSchema,
  SearchMembersFilterSchema,
  ServerDateSchema,
  UnitSummarySchema,
  getPaginationResponseSchema,
} from "./common";
import { MemberSchema } from "./members";

const c = initContract();

const ScheduleRepeatPeriod = z.enum(["Daily", "Weekly", "Monthly"]);

const ScheduleDayOfWeek = z.enum([
  "MON",
  "TUE",
  "WED",
  "THU",
  "FRI",
  "SAT",
  "SUN",
]);

const CreateActivityScheduleInputSchema = z.object({
  title: z.string(),
  description: z.string(),
  unitId: z.string(),
  address: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  initialStart: ServerDateSchema,
  initialEnd: ServerDateSchema,
  recipientGroupIds: z.array(z.string()).optional(),
  recipientContactGroupIds: z.array(z.string()).optional(),
  recipientMemberIds: z.array(z.string()).optional(),
  recipientUnitIds: z.array(z.string()).optional(),
  selectAllMembersQuery: SearchMembersFilterSchema.optional(),
  schedule: z.object({
    /**
     * The repetition base period. Will determine what other possible options can be used
     */
    period: ScheduleRepeatPeriod,
    /**
     * Measure of how often the schedule should repeat given the period.
     */
    every: z.number().min(1),
    /**
     * For Weekly period, which days of the week should the schedule repeat on
     */
    daysOfWeek: z.array(ScheduleDayOfWeek).optional(),
    /**
     * For Monthly period, which day of the month should the schedule repeat on
     */
    dayOfMonth: z.number().min(1).max(31).optional(),
    /**
     * For Monthly period, the nth repetition logic.
     */
    nth: z
      .object({
        value: z.number().min(1).max(4),
        dayOfWeek: ScheduleDayOfWeek,
      })
      .optional(),

    endDate: ServerDateSchema.optional(),
    endCount: z.number().optional(),
    reminderDuration: z.number().optional(),
  }),
});

export const ActivityScheduleSummarySchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  address: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  initialStart: ServerDateSchema,
  initialEnd: ServerDateSchema,
  closedAt: z.string().datetime().optional(),
  scheduleSummary: z.string(),
  scheduleEndDate: ServerDateSchema.optional(),
  scheduleEndCount: z.number().optional(),
  scheduleReminderDuration: z.number().optional(),
});

const ActivityScheduleSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  address: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  initialStart: ServerDateSchema,
  initialEnd: ServerDateSchema,
  initiator: MemberSummarySchema,
  unit: UnitSummarySchema,
  closedAt: z.string().datetime().optional(),
  scheduleSummary: z.string(),
  scheduleEndDate: ServerDateSchema.optional(),
  scheduleEndCount: z.number().optional(),
  scheduleReminderDuration: z.number().optional(),
});

export const activityScheduleContract = c.router({
  create: {
    method: "POST",
    path: "/activity-schedules",
    body: CreateActivityScheduleInputSchema,
    responses: {
      200: ActivityScheduleSchema,
    },
    summary: "Create an activity schedule",
  },
  get: {
    method: "GET",
    path: "/activity-schedules/:id",
    pathParams: z.object({
      id: z.string(),
    }),
    responses: {
      200: ActivityScheduleSchema,
      404: ApiErrorSchema,
    },
    summary: "Get the details of an activity schedule",
  },
  update: {
    method: "PATCH",
    path: "/activity-schedules/:id",
    pathParams: z.object({
      id: z.string(),
    }),
    body: z.object({
      title: z.string(),
      description: z.string(),
      address: z.string(),
      latitude: z.number(),
      longitude: z.number(),
      scheduleEndDate: ServerDateSchema.optional(),
      scheduleEndCount: z.number().optional(),
      scheduleReminderDuration: z.number().nullable(),
    }),
    responses: {
      200: ActivityScheduleSchema,
      400: ApiErrorSchema,
      403: ApiErrorSchema,
      404: ApiErrorSchema,
    },
    summary: "Update an activity schedule",
  },
  addMembers: {
    method: "POST",
    path: "/activity-schedules/:activityScheduleId/members/add",
    pathParams: z.object({
      activityScheduleId: z.string(),
    }),
    responses: {
      200: z.object({
        items: z.array(MemberSchema),
      }),
      403: ApiErrorSchema,
      404: ApiErrorSchema,
    },
    body: z.object({
      groupIds: z.array(z.string()),
      contactGroupIds: z.array(z.string()),
      memberIds: z.array(z.string()),
      selectAllMembersQuery: SearchMembersFilterSchema.optional(),
    }),
    summary: "Add members to an activity schedule",
  },
  removeMembers: {
    method: "POST",
    path: "/activity-schedules/:activityScheduleId/members/remove",
    pathParams: z.object({
      activityScheduleId: z.string(),
    }),
    responses: {
      200: z.object({
        items: z.array(MemberSchema),
      }),
      403: ApiErrorSchema,
      404: ApiErrorSchema,
    },
    body: z.object({
      memberIds: z.array(z.string()),
    }),
    summary: "Remove members from an activity schedule",
  },
  getMembers: {
    method: "GET",
    path: "/activity-schedules/:activityScheduleId/members",
    query: PaginationQueryParamsSchema.extend({
      capabilityIds: z.array(z.string()).optional(),
      query: z.coerce.string().trim().optional(),
    }),
    responses: {
      200: getPaginationResponseSchema(MemberSchema),
    },
    summary: "Get the members of an activity schedule",
  },
  isInvited: {
    method: "GET",
    path: "/activity-schedules/:activityScheduleId/isInvited",
    pathParams: z.object({
      activityScheduleId: z.string(),
    }),
    responses: {
      200: z.object({
        invited: z.boolean(),
      }),
      404: ApiErrorSchema,
    },
    summary:
      "Indicates whether the logged in member is on an activity schedule",
  },
  close: {
    method: "DELETE",
    path: "/activity-schedules/:activityScheduleId",
    pathParams: z.object({
      activityScheduleId: z.string(),
    }),
    body: null,
    responses: {
      200: ActivityScheduleSchema,
      403: ApiErrorSchema,
      404: ApiErrorSchema,
    },
    summary: "Close an activity schedule",
  },
});

export type ActivitySchedule = z.infer<typeof ActivityScheduleSchema>;
export type ActivityScheduleSummary = z.infer<
  typeof ActivityScheduleSummarySchema
>;
